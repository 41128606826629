import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getRFQGrades, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialGradeSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules: any;
    error?: FieldError;
}

export const MaterialGradeSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    material,
    rules,
    error,
}: MaterialGradeSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);
    const availableGrades = getRFQGrades(material)?.map((grades) => grades.slice().sort());

    return (
        availableGrades.length > 0 && (
            <Select
                optGroups={availableGrades.map((gradeGroup) =>
                    gradeGroup.map((grade) => ({
                        label: t(`domainModel:material.grade.value.${grade}`),
                        value: grade,
                    }))
                )}
                placeholder={t(`uiDomain:materialInput.placeholder`)}
                name={name}
                control={control}
                rules={rules}
                error={error?.message}
            />
        )
    );
};

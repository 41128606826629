import Tippy, { TippyProps } from '@tippyjs/react/headless';

import './Tooltip.scss';
import { ReactNode } from 'react';

type Placement =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';

export enum TooltipType {
    ERROR = 'ERROR',
}

export const Tooltip = ({
    content,
    children,
    interactive,
    placement = 'bottom',
    type = TooltipType.ERROR,
    ...rest
}: {
    content: ReactNode;
    children: JSX.Element;
    interactive?: boolean;
    placement?: Placement;
    type?: TooltipType;
} & TippyProps) => (
    <Tippy
        render={(attrs) => (
            <div className={`tooltip tooltip--${type.toLowerCase()}`} tabIndex={-1} role="tooltip" {...attrs}>
                {content}
                <div className="tooltip-arrow" data-popper-arrow />
            </div>
        )}
        placement={placement}
        interactive={interactive}
        offset={[0, 6]}
        {...rest}
    >
        {children}
    </Tippy>
);

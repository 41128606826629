import { DownloadFile, DownloadFileFieldNames, ArrayOfDownloadFileFieldNames } from '@steelbuy/domain-model-types';
import {
    PackageSellerBaseModel,
    PackageSellerBaseModelConverter,
    PrimePackageSellerBaseModel,
} from './PackageSellerBaseModel';

export type PrimePackageSellerModel = PrimePackageSellerBaseModel<DownloadFile>;

export type PackageSellerModel = PackageSellerBaseModel & {
    pictures?: DownloadFile[];
    packageDocument: DownloadFile;
} & PrimePackageSellerModel;

export class PackageSellerModelConverter extends PackageSellerBaseModelConverter<PackageSellerModel> {
    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<PackageSellerModel> | ArrayOfDownloadFileFieldNames<PackageSellerModel>
    > {
        return ['pictures', 'certificates', 'packageDocument'];
    }
}

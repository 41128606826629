import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { RFQStatus } from '@steelbuy/domain-model';

export interface MyRFQsContextValue {
    scrollPosition: number;
    setScrollPosition: (position: number) => void;
    myRFQFilter: RFQStatus[];
    setMyRFQFilter: (filter: RFQStatus[]) => void;
}

const defaultValue: MyRFQsContextValue = {
    scrollPosition: 0,
    setScrollPosition: () => null,
    myRFQFilter: [],
    setMyRFQFilter: () => null,
};

const MyRFQsContext = createContext(defaultValue);

export const MyRFQsContextProvider = ({ children }: { children: ReactNode }) => {
    const [scrollPosition, setScrollPosition] = useState(defaultValue.scrollPosition);
    const [myRFQFilter, setMyRFQFilter] = useState<RFQStatus[]>([]);
    const value = useMemo(
        () => ({
            scrollPosition,
            setScrollPosition,
            myRFQFilter,
            setMyRFQFilter,
        }),
        [scrollPosition, setScrollPosition, myRFQFilter, setMyRFQFilter]
    );

    return <MyRFQsContext.Provider value={value}>{children}</MyRFQsContext.Provider>;
};

export const useMyRFQsContext = () => useContext(MyRFQsContext);

import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';
import { TrackingIndicatorLineStates } from './TrackingIndicatorLine.enums';

import './TrackingIndicatorLine.scss';

export type TrackingIndicatorLineProps = {
    state: TrackingIndicatorLineStates;
};

export const TrackingIndicatorLine = (props: TrackingIndicatorLineProps) => {
    const { state } = props;

    const trackingIndicatorLineClassMap = {
        'tracking-indicator-line': true,
        [classNameFromEnumValue(state, 'tracking-indicator-line')]: true,
    };

    return <span className={buildClassStringFromClassMap(trackingIndicatorLineClassMap)} />;
};

import { ApiDownloadFile, ApiDownloadFileMetaData, DownloadFile, DownloadFileMetaData } from './DownloadFile';
import { isApiTimestamp, isTimestamp } from './Timestamp.guards';
import { isApiUrl, isUrl } from './Url.guards';

export const isApiDownloadFileMetaData = (apiFileMetaData?: unknown): apiFileMetaData is ApiDownloadFileMetaData => {
    if ((apiFileMetaData ?? null) === null) {
        return false;
    }
    return (
        (apiFileMetaData as ApiDownloadFileMetaData).fileName !== undefined &&
        (apiFileMetaData as ApiDownloadFileMetaData).mimeType !== undefined &&
        (apiFileMetaData as ApiDownloadFileMetaData).size !== undefined &&
        isApiTimestamp((apiFileMetaData as ApiDownloadFileMetaData).lastModifiedDate)
    );
};

export const isDownloadFileMetaData = (fileMetaData?: unknown): fileMetaData is DownloadFileMetaData => {
    if ((fileMetaData ?? null) === null) {
        return false;
    }
    return (
        (fileMetaData as DownloadFileMetaData).fileName !== undefined &&
        (fileMetaData as DownloadFileMetaData).mimeType !== undefined &&
        (fileMetaData as DownloadFileMetaData).size !== undefined &&
        isTimestamp((fileMetaData as DownloadFileMetaData).lastModifiedDate)
    );
};

export const isDownloadFile = (file?: unknown): file is DownloadFile => {
    if ((file ?? null) === null) {
        return false;
    }
    return (
        (file as DownloadFile).discriminator === 'DownloadFile' &&
        isUrl((file as DownloadFile).url) &&
        isDownloadFileMetaData((file as DownloadFile).meta)
    );
};

export const isApiDownloadFile = (apiFile?: unknown): apiFile is ApiDownloadFile => {
    if ((apiFile ?? null) === null) {
        return false;
    }
    return isApiUrl((apiFile as ApiDownloadFile).url) && isApiDownloadFileMetaData((apiFile as ApiDownloadFile).meta);
};

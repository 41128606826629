import { Outlet, useNavigate } from 'react-router-dom';
import {
    ListingBuyerSearchDataProvider,
    useIsFeatureSupported,
    userHasRole,
    useUserSelf,
} from '@steelbuy/data-provider';
import { Feature, UserRole } from '@steelbuy/domain-model';
import { CheckoutContextProvider } from '../../components/checkout/CheckoutContext';
import { SearchFormDataContextProvider } from '../../components/listing-search/SearchFormDataContext';
import { SearchResultsContextProvider } from '../../components/listing-search/SearchResultsContext';
import { RoutePath } from '../../router/Routes';

export const MyCartView = () => {
    const navigate = useNavigate();
    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;

    if (!showCart) {
        navigate(RoutePath.DASHBOARD);
    }

    return (
        <ListingBuyerSearchDataProvider lazyLoad>
            <SearchFormDataContextProvider>
                <CheckoutContextProvider>
                    <SearchResultsContextProvider>
                        <Outlet />
                    </SearchResultsContextProvider>
                </CheckoutContextProvider>
            </SearchFormDataContextProvider>
        </ListingBuyerSearchDataProvider>
    );
};

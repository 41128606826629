import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ButtonTertiaryOnDarkS, IconIdentifier } from '@steelbuy/ui-primitive';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { RoutePath } from '../../../router/Routes';
import { useNavigationContext } from '../NavigationContext';

export const NavigationHeader = () => {
    const { t } = useTranslation('translation');
    const navigationContext = useNavigationContext();

    const navigationHeaderClassMap = {
        'navigation-header': true,
        'navigation-header--is-collapsed': navigationContext.isCollapsed,
    };

    const toggleCollapse = () => {
        navigationContext.setIsCollapsed(!navigationContext.isCollapsed);
    };

    const handleLogoClick = () => {
        if (navigationContext.collapseOnNavigation) {
            navigationContext.setIsCollapsed(true);
        }
    };

    const renderMenuButton = () => {
        const button = (icon: IconIdentifier) => (
            <ButtonTertiaryOnDarkS
                icon={icon}
                label={t('application.navigation.menuButtonLabel')}
                onClick={toggleCollapse}
            />
        );

        if (navigationContext.isCollapsed) {
            return button(IconIdentifier.HAMBURGER);
        }
        return button(IconIdentifier.CLOSE);
    };

    return (
        <section className={buildClassStringFromClassMap(navigationHeaderClassMap)}>
            <NavLink to={RoutePath.DASHBOARD} onClick={handleLogoClick}>
                <div className="navigation-header-img" />
            </NavLink>
            <div className="navigation-header__menu-btn">{renderMenuButton()}</div>
        </section>
    );
};

import { ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';

export type StatusModel<T> = {
    value: T;
    reason: string | undefined;
    modifiedAt: Timestamp;
};

export class StatusModelConverter<T> extends ModelConverter<StatusModel<T>> {
    override getTimestampFields(): Array<
        TimestampFieldNames<StatusModel<T>> | ArrayOfTimestampFieldNames<StatusModel<T>>
    > {
        return ['modifiedAt'];
    }
}

import { TFunction } from 'i18next';
import { AbstractMaterialTaxonomy } from './material-taxonomy/AbstractMaterialTaxonomy';
import { AluminiumTaxonomy } from './material-taxonomy/aluminium/AluminiumTaxonomy';
import { Dimensions, MaterialProperties } from './material-taxonomy/MaterialProperties';
import { MildSteelTaxonomy } from './material-taxonomy/mild-steel/MildSteelTaxonomy';
import { StainlessTaxonomy } from './material-taxonomy/stainless/StainlessTaxonomy';
import { MaterialType } from './MaterialModel.enums';
import { DimensionsWithRanges, SearchFormData } from './SearchFormData';

const aluminiumMaterial = new AluminiumTaxonomy();
const mildSteelMaterial = new MildSteelTaxonomy();
const stainlessMaterial = new StainlessTaxonomy();

const getInstance = (material: MaterialProperties): AbstractMaterialTaxonomy => {
    switch (material?.materialType) {
        case MaterialType.ALUMINIUM:
            return aluminiumMaterial;
        case MaterialType.STAINLESS_STEEL:
            return stainlessMaterial;
        default:
            return mildSteelMaterial;
    }
};

export const getShapes = (material: MaterialProperties) => getInstance(material).getShapes();

export const getProducts = (material: MaterialProperties, isPackagesSupported = false) =>
    getInstance(material).getProducts(material, isPackagesSupported);

export const getProductTypes = (material: MaterialProperties, isPrime: boolean) =>
    getInstance(material).getProductTypes(material, isPrime);

export const getDefinitions = (material: MaterialProperties) => getInstance(material).getDefinitions(material);

export const getGrades = (material: MaterialProperties) => getInstance(material).getGrades(material);

export const getSurfaces = (material: MaterialProperties) => getInstance(material).getSurfaces(material);

export const getFinishes = (material: MaterialProperties) => getInstance(material).getFinishes(material);

export const getSpecifications = (material: MaterialProperties) => getInstance(material).getSpecifications(material);

export const getCoatings = (material: MaterialProperties) => getInstance(material).getCoatings(material);

export const getCoatingThickness = (material: MaterialProperties, excludeOther?: boolean) =>
    getInstance(material).getCoatingThickness(material, excludeOther);

export const getCoatingThicknessUnit = (material: MaterialProperties) =>
    getInstance(material).getCoatingThicknessUnit(material);

export const getTempers = (material: MaterialProperties) => getInstance(material).getTempers(material);

export const getTolerances = (material: MaterialProperties, isPrime: boolean) =>
    getInstance(material).getTolerances(material, isPrime);

export const getCoatingColours = (material: MaterialProperties, excludeOther?: boolean) =>
    getInstance(material).getCoatingColours(material, excludeOther);

export const getCoatingTypes = (material: MaterialProperties) => getInstance(material).getCoatingTypes(material);

export const getCoatingCoverages = (material: MaterialProperties) =>
    getInstance(material).getCoatingCoverages(material);

export const getTradeUnit = (material: MaterialProperties) => getInstance(material).getTradeUnit(material);

export const getTradeUnitCalculation = (material: MaterialProperties) =>
    getInstance(material).getTradeUnitCalculation(material);

export const getPricingUnit = (material: MaterialProperties) => getInstance(material).getPricingUnit(material);

export const getPolishes = (material: MaterialProperties) => getInstance(material).getPolishes(material);

export const getMillFinishes = (material: MaterialProperties) => getInstance(material).getMillFinishes(material);

export const getMaterialAges = (material: MaterialProperties) => getInstance(material).getMaterialAges(material);

export const getOrigins = (material: MaterialProperties) => getInstance(material).getOrigins(material);

export const getUrgentRequestMandatoryFields = (searchFormData: SearchFormData) =>
    getInstance(searchFormData).getUrgentRequestMandatoryFields(searchFormData);

export const getTheoreticalWeight = (material: MaterialProperties, dimensions: Dimensions, numberOfItems?: number) =>
    getInstance(material).getTheoreticalWeight(material, dimensions, numberOfItems);

export const getMaxTheoreticalWeight = (
    material: MaterialProperties,
    dimensions: DimensionsWithRanges,
    numberOfItems?: number
) => getInstance(material).getMaxTheoreticalWeight(material, dimensions, numberOfItems);

export const getMinimumTargetPrice = (
    material: MaterialProperties,
    weight: number | undefined,
    numberOfItems: number | undefined
) => getInstance(material).getMinimumTargetPrice(material, weight, numberOfItems);

export const isMultiOrigin = (material: MaterialProperties) => getInstance(material).isMultiOrigin(material);

export const showNumberOfItemsForIMR = (material: MaterialProperties) =>
    getInstance(material).showNumberOfItemsForIMR(material);

export const getRFQFields = (material: MaterialProperties, t: TFunction<'domainModel', undefined>) =>
    getInstance(material).getRFQFields(material, t);

export const getRFQGrades = (material: MaterialProperties) => getInstance(material).getRFQGrades(material);

import { useMutation } from '@tanstack/react-query';
import { CartBuyerModel, CartBuyerModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../axios';

export enum CartProductType {
    LISTING = 'LISTING',
    PACKAGE = 'PACKAGE',
}
export type AddItemToCartModel = {
    productId: string;
    productType: CartProductType;
};

const modelConverter = new CartBuyerModelConverter();

const addItemToCart = async (payload: AddItemToCartModel) => {
    const { data } = await axios.post<CartBuyerModel>('/api/buyer/cart/add-to-cart', payload);
    const cartModel = modelConverter.toViewModel(data as unknown as ApiModel<CartBuyerModel>);
    return cartModel;
};

export const useAddItemToCart = () =>
    useMutation({
        mutationFn: (payload: AddItemToCartModel) => addItemToCart(payload),
    });

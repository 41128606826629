import { reportNRError } from '@steelbuy/util';
import {
    StoreError,
    NotFoundError,
    NetworkError,
    TimeoutError,
    ApiError,
    ValidationError,
    AuthenticationRequiredError,
    AuthenticationFailedError,
    AccessDeniedError,
    WebSocketError,
    SimpleError,
    DetailedError,
    CustomError,
} from './CustomError';
import { AnyErrorObject, ErrorDiscriminator, errorTypes } from './ErrorObject';

export const restoreError = (errorObject: AnyErrorObject): Error => {
    let error;
    switch (errorObject.discriminator) {
        case 'CustomError':
            error = new CustomError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'StoreError':
            error = new StoreError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'NotFoundError':
            error = new NotFoundError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'NetworkError':
            error = new NetworkError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'TimeoutError':
            error = new TimeoutError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'ApiError':
            error = new ApiError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'ValidationError':
            error = new ValidationError(
                errorObject.message,
                errorObject.status,
                errorObject.timestamp,
                errorObject.details
            );
            break;
        case 'AuthenticationRequiredError':
            error = new AuthenticationRequiredError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'AuthenticationFailedError':
            error = new AuthenticationFailedError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'AccessDeniedError':
            error = new AccessDeniedError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
        case 'WebSocketError':
            error = new WebSocketError(errorObject.message, errorObject.status, errorObject.timestamp);
            break;
    }
    error.stack = errorObject.stack;
    return error;
};

export const getNetworkErrorMessage = (message: string, method: string, uri: string) =>
    `${message}, method: ${method}, uri: ${uri}`;

export const reportRequestError = (error: unknown, method: string, uri: string) => {
    if (error instanceof Error) {
        // eslint-disable-next-line no-param-reassign
        error.message = getNetworkErrorMessage(error.message, method, uri);
        reportNRError(error);
    }
};

export const createErrorObject = (error: Error): AnyErrorObject => {
    const discriminator = errorTypes.includes(error.name as ErrorDiscriminator)
        ? (error.name as ErrorDiscriminator)
        : 'CustomError';
    const status = error instanceof SimpleError ? error.status : undefined;
    const timestamp = error instanceof SimpleError ? error.timestamp : undefined;
    const details = error instanceof DetailedError ? error.details : undefined;
    return {
        discriminator,
        name: error.name,
        message: error.message,
        stack: error.stack,
        status,
        timestamp,
        details,
    };
};

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MaterialProperties } from '@steelbuy/domain-model';
import { Card, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import './RFQTeaser.scss';

const getIndexerAndValue = (
    material: MaterialProperties,
    t: TFunction<'domainModel', undefined>
): { label: string; value: string } | null => {
    if (material.definition) {
        return {
            label: t('material.definition.name'),
            value: t(`material.definition.value.${material.definition}`),
        };
    }
    if (material.plateType) {
        return {
            label: t('material.plateType.name'),
            value: t(`material.plateType.value.${material.plateType}`),
        };
    }
    return null;
};

export const RFQTeaser = ({ material }: { material: MaterialProperties }) => {
    const { t } = useTranslation('domainModel');
    const { label, value } = getIndexerAndValue(material, t) || {};

    const materialTranslation = material.materialType && t(`material.materialType.value.${material.materialType}`);
    const productTranslation = material.product && t(`material.product.value.${material.product}`);

    return (
        <div className="rfq-teaser">
            <Card isClickable={false}>
                <div className="rfq-teaser-header">
                    {material.product && (
                        <div className="rfq-teaser-header__icon">
                            <Icon name={IconIdentifier[material.product]} />
                        </div>
                    )}

                    <div className="rfq-teaser-header__product">
                        <span className="rfq-teaser-header__product__type">
                            {material.product ? `${productTranslation}, ` : ''}
                            {material.materialType ? `${materialTranslation}` : ''}
                        </span>
                    </div>
                </div>
                <div className="rfq-teaser__attribute">
                    {label && value ? (
                        <>
                            <span className="rfq-teaser__attribute__label">{label}</span>
                            <span className="rfq-teaser__attribute__name">{value}</span>
                        </>
                    ) : null}
                </div>
            </Card>
        </div>
    );
};

import { ReactNode } from 'react';
import { FormProvider, useFieldArray, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form/dist/types/fieldArray';
import { useTranslation } from 'react-i18next';
import { Definition, getRFQFields, MaterialWithDimensions, ProductType } from '@steelbuy/domain-model';
import { rfqInputs, RFQInputs } from '../createRFQUtil';
import { RFQType } from '../Schema';

interface RFQItemGroupProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    methods: UseFormReturn<RFQType, any>;
    children(
        headings: RFQInputs,
        append: UseFieldArrayAppend<RFQType>,
        fields: FieldArrayWithId<RFQType>[],
        remove: UseFieldArrayRemove,
        update: UseFieldArrayUpdate<RFQType>
    ): ReactNode;
    material: MaterialWithDimensions;
    indexer: Definition | ProductType;
}

export const RFQItemGroup = ({ methods, children, material, indexer }: RFQItemGroupProps) => {
    const { append, remove, fields, update } = useFieldArray({
        control: methods.control,
        name: `materials.${indexer}`,
    });

    const { t } = useTranslation(['uiDomain']);

    return (
        <FormProvider {...methods}>
            {children(
                getRFQFields(material, t).reduce(
                    (acc, curr) =>
                        rfqInputs[curr.field] !== undefined
                            ? {
                                  ...acc,
                                  [curr.field]: { ...rfqInputs[curr.field], rules: curr.rules },
                              }
                            : acc,
                    {} as RFQInputs
                ),
                append,
                fields,
                remove,
                update
            )}
        </FormProvider>
    );
};

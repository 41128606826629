import { ReactNode } from 'react';

import './AppLayout.scss';

export type AppLayoutProps = {
    children: ReactNode;
};

export const AppLayout = (props: AppLayoutProps) => {
    const { children } = props;

    return <article className="app-layout">{children}</article>;
};

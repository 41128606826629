import './ValidationMessage.scss';
import { Icon, IconIdentifier } from '@steelbuy/ui-primitive';

interface ValidationMessageProps {
    text: string;
    success: boolean;
}

const validationClass = (success: boolean) => `validation-message__${success ? 'success' : 'error'}`;
const statusIcon = (success: boolean) => (success ? IconIdentifier.OK : IconIdentifier.CLOSE);

export const ValidationMessage = ({ text, success }: ValidationMessageProps) => (
    <div className={`validation-message ${validationClass(success)}`}>
        <Icon name={statusIcon(success)} />
        <p>{text}</p>
    </div>
);

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetWarehouseAddress } from '@steelbuy/data-access';
import { CardContentCheckoutAddresses } from '@steelbuy/ui-domain';
import { ButtonPrimary, ButtonSecondary, ButtonTertiaryOnLightM, Card, CardLayout } from '@steelbuy/ui-primitive';

import './WarehouseAddressSelection.scss';

interface WarehouseAddressSelectionProps {
    selectedAddressId?: string;
    onSelect: (addressId: string) => void;
    onCancel: () => void;
    onAddAddressButtonClick?: () => void;
}

export const WarehouseAddressSelection = (props: WarehouseAddressSelectionProps) => {
    const { selectedAddressId, onSelect, onCancel, onAddAddressButtonClick } = props;
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { data: addressCollectionItems } = useGetWarehouseAddress();

    const [currentSelectedAddressId, setSelectedAddressId] = useState<string | undefined>(selectedAddressId);

    useEffect(() => {
        if (selectedAddressId !== currentSelectedAddressId) {
            setSelectedAddressId(selectedAddressId);
        }
    }, [selectedAddressId]);

    return (
        <div className="warehouse-address-selection">
            <section>
                <Card isClickable={false}>
                    <CardLayout>
                        {addressCollectionItems && (
                            <CardContentCheckoutAddresses
                                addresses={addressCollectionItems}
                                selectedAddress={currentSelectedAddressId}
                                onSelectionChanged={setSelectedAddressId}
                            />
                        )}
                    </CardLayout>
                    {onAddAddressButtonClick && (
                        <section className="warehouse-address-selection__add-button">
                            <ButtonTertiaryOnLightM
                                label={t('application.warehouseAddressSelection.addNewAddress')}
                                onClick={onAddAddressButtonClick}
                            />
                        </section>
                    )}
                </Card>
            </section>

            <section className="warehouse-address-selection__buttons">
                <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={() => onCancel()} />
                <ButtonPrimary
                    label={t('application.warehouseAddressSelection.chooseAddressButtonLabel')}
                    disabled={!currentSelectedAddressId}
                    onClick={() => onSelect(currentSelectedAddressId ?? '')}
                />
            </section>
        </div>
    );
};

import { KeyPrefix, TFunction } from 'i18next';
import { MaterialProperties, Dimensions } from '../material-taxonomy/MaterialProperties';
import { MaterialType, Product, Definition, ProductType, Tolerance, Coating } from '../MaterialModel.enums';
import { isMaterialWithDefinition, isMaterialWithPlateType } from '../MaterialModel.guards';
import { getRFQFields } from '../MaterialModel.maps';
import { QuoteEntry } from '../RFQBuyerModel';

export const getCoatingThicknessValue = (material: MaterialProperties) =>
    material.coating === Coating.TWO_SIDED
        ? `${material.coatingThicknessValue}/${material.coatingThicknessValue}`
        : material.coatingThicknessValue;

export type RFQDetailsHeadingConfig = {
    literalPath?: KeyPrefix<'domainModel'>;
    measurementLiteral?: string;
    valueMapper?: (material: MaterialProperties) => string;
};

export type RFQQuoteEntryMaterial = MaterialProperties &
    Dimensions & { id?: string; weight: number; tolerance: Tolerance };

export type RFQDetailsHeading = Record<keyof RFQQuoteEntryMaterial, RFQDetailsHeadingConfig>;

export const rfqHeadings = {
    grade: {
        literalPath: 'material.grade.value',
    },
    specification: {
        literalPath: 'material.specification.value',
    },
    finish: {
        literalPath: 'material.finish.value',
    },
    width: {
        measurementLiteral: 'material.dimensions.units.millimeters',
    },
    thickness: {
        measurementLiteral: 'material.dimensions.units.millimeters',
    },
    weight: {
        measurementLiteral: 'material.tradeUnit.value.TON.label',
    },
    tolerance: {
        literalPath: 'material.tolerance.value',
    },
    length: {
        measurementLiteral: 'material.dimensions.units.millimeters',
    },
    surface: {
        literalPath: 'material.surface.value',
    },
    coating: {
        literalPath: 'material.coating.value',
    },
    coatingThicknessValue: {
        measurementLiteral: 'material.dimensions.units.gramsPerSquareMetre',
        valueMapper: getCoatingThicknessValue,
    },
} as RFQDetailsHeading;

export type QuoteEntryDetail = {
    materialType: MaterialType;
    product: Product;
    group:
        | {
              label: 'definition';
              value: Definition;
          }
        | {
              label: 'plateType';
              value: ProductType;
          };
    fieldHeadings: RFQDetailsHeading;
    materials: RFQQuoteEntryMaterial[];
};

export const quoteDetailsConvertor = (quoteEntry: QuoteEntry[]): QuoteEntryDetail[] => {
    const materialGroupIndex: Record<string, number> = {};
    return quoteEntry.reduce((acc, entry) => {
        const { material, weight, materialDimensions } = entry;
        const materials = {
            ...material,
            ...materialDimensions,
            id: entry.id,
            weight,
        };
        const fieldHeadings: RFQDetailsHeading = getRFQFields(
            material,
            (() => '') as TFunction<'domainModel', undefined>
        ).reduce(
            (headingsAcc, curr) =>
                rfqHeadings[curr.field] !== undefined
                    ? {
                          ...headingsAcc,
                          [curr.field]: { ...rfqHeadings[curr.field] },
                      }
                    : headingsAcc,
            {} as RFQDetailsHeading
        );
        if (isMaterialWithDefinition(material)) {
            if (materialGroupIndex[material.definition] === undefined) {
                materialGroupIndex[material.definition] = acc.length;
                acc.push({
                    materialType: material.materialType,
                    product: material.product,
                    group: { label: 'definition', value: material.definition },
                    materials: [materials],
                    fieldHeadings,
                });
            } else {
                acc[materialGroupIndex[material.definition]].materials.push(materials);
            }
            return acc;
        }

        if (isMaterialWithPlateType(material)) {
            if (materialGroupIndex[material.plateType] === undefined) {
                materialGroupIndex[material.plateType] = acc.length;
                acc.push({
                    materialType: material.materialType,
                    product: material.product,
                    group: { label: 'plateType', value: material.plateType },
                    materials: [materials],
                    fieldHeadings,
                });
            } else {
                acc[materialGroupIndex[material.plateType]].materials.push(materials);
            }
        }
        return acc;
    }, [] as QuoteEntryDetail[]);
};

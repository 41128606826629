import axiosClient, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AuthHandler } from '@steelbuy/auth';
import { reportRequestError } from '@steelbuy/error';
import { getBaseUrl, getTenant } from './utils/getBaseUrl';

const JSON_WEB_TOKEN_STORAGE_KEY = 'lib_auth_jwt';

export type LoginSuccessResponse = {
    accessToken: string;
    refreshToken: string;
};

const baseInstance = axiosClient.create({
    baseURL: getBaseUrl(),
    headers: {
        Accept: 'application/json',
    },
});

export { baseInstance as unauthenticatedAxios };

export const instance = axiosClient.create({
    baseURL: getBaseUrl(),
    headers: {
        Accept: 'application/json',
    },
});

const refreshToken = async (refreshTokenStr: string) => {
    const { data } = await baseInstance.post<LoginSuccessResponse>(`/api/auth/refresh-token`, {
        refreshToken: refreshTokenStr,
        tenant: getTenant(),
    });
    return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const needsUpdate = (token: any): boolean => {
    if (!token) {
        return false;
    }
    const validToTimestamp = token.accessTokenValidTo ?? 0;
    return validToTimestamp < Date.now() + 3000;
};

export const getToken = async (): Promise<string> => {
    const tokenData = localStorage.getItem(`steelbuy_${JSON_WEB_TOKEN_STORAGE_KEY}`);
    if (tokenData) {
        const tokenObj = JSON.parse(tokenData);
        if (needsUpdate(tokenObj)) {
            // TODO Look into replacing AuthHandler
            const authHandler = AuthHandler.get();
            try {
                const newToken = await refreshToken(tokenObj.refreshToken);
                authHandler.update(newToken.accessToken);
                return newToken.accessToken;
            } catch (e) {
                authHandler.unauthenticate();
            }
        }
        if (tokenObj.accessToken) {
            return tokenObj.accessToken;
        }
    }
    window.location.assign('login');
    // TODO MAKE THIS BETTER
    throw Error('Unautenticated');
};

instance.interceptors.request.use(async (axiosConfig: InternalAxiosRequestConfig) => {
    const config = axiosConfig;
    const accessToken = await getToken();
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.config) {
            reportRequestError(error, error.config.method || 'unknown method', error.config.url || 'unkown url');
        }
        return Promise.reject(error);
    }
);

export default instance;

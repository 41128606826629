import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SortCriteria, SortDirection } from '@steelbuy/api-integration';
import { ActionStatus, FetchStatus, useUserCollectionData } from '@steelbuy/data-provider';
import { BASIC_USER_ROLE, UserModel, UserRole } from '@steelbuy/domain-model';

import {
    ButtonTertiaryOnLightM,
    Card,
    CardContentAccountAttribute,
    CardContentAttributesItem,
    CardLayout,
    HeadingCardsSection,
    LoadingSpinner,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import './UserManagement.scss';

const userSortCriteria: SortCriteria<UserModel> = [
    {
        property: 'name',
        direction: SortDirection.ASCENDING,
    },
];

export const UserManagement = () => {
    const [userCreated, setUserCreated] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const userCollectionData = useUserCollectionData();
    const fetchStatus = userCollectionData.queryFetchStatus();
    const users = userCollectionData.query();

    useEffect(() => {
        if (fetchStatus === FetchStatus.IDLE) {
            userCollectionData.fetch(userSortCriteria);
        }
    }, [fetchStatus]);
    const handleLoadMore = (): void => {
        userCollectionData.fetchNext();
    };

    const hasNext = userCollectionData.hasNextPage();

    const getPermissions = (user: UserModel): (UserRole | typeof BASIC_USER_ROLE)[] => [...user.roles, BASIC_USER_ROLE];

    userCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            setUserCreated(true);
        },
        true
    );
    userCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            setUserUpdated(true);
        },
        true
    );

    const renderNotification = () => {
        if (userCreated) {
            return (
                <Notification
                    level={NotificationLevel.SUCCESS}
                    message={t('translation:application.userManagement.createUserSuccess')}
                    onClose={() => setUserCreated(false)}
                />
            );
        }
        if (userUpdated) {
            return (
                <Notification
                    level={NotificationLevel.SUCCESS}
                    message={t('translation:application.userManagement.updateUserSuccess')}
                    onClose={() => setUserUpdated(false)}
                />
            );
        }
        return null;
    };

    if (fetchStatus === FetchStatus.PENDING) {
        return <LoadingSpinner />;
    }

    const activeUserTagLabel = (user: UserModel) => {
        if (user.roles?.includes(UserRole.MANAGER)) {
            return t(`domainModel:user.permissionTitle.${UserRole.MANAGER}`);
        }
        return undefined;
    };

    return (
        <DetailsLayout className="user-management">
            {renderNotification()}
            <HeadingCardsSection>
                {users.map((user) => (
                    <Card isClickable={false} key={user.id}>
                        <CardLayout>
                            <CardContentAccountAttribute
                                buttonLabel={t('uiDomain:common.edit')}
                                onButtonClick={() =>
                                    navigate(createRouteUrl(RoutePath.ACCOUNT_EDIT_USER, ['userId', user.id]))
                                }
                                header={user.name}
                                tagLabel={user.activated ? activeUserTagLabel(user) : t('domainModel:user.deactivated')}
                                userActivated={user.activated}
                            >
                                <CardContentAttributesItem
                                    label={t('translation:application.userManagement.emailHeader')}
                                    value={user.email}
                                />
                                {user.activated && (
                                    <CardContentAttributesItem
                                        label={t('translation:application.userManagement.permissionsHeader')}
                                        value={getPermissions(user)
                                            .map((p) => t(`domainModel:user.permissionTitle.${p}`))
                                            .join(', ')}
                                    />
                                )}
                            </CardContentAccountAttribute>
                        </CardLayout>
                    </Card>
                ))}
                {hasNext && (
                    <div className="user-management__load-more">
                        <ButtonTertiaryOnLightM
                            onClick={handleLoadMore}
                            disabled={!hasNext}
                            label={t('uiDomain:commonList.loadMore')}
                            loadingStatus={
                                fetchStatus === FetchStatus.PAGING_PENDING ? LoadingStatus.PENDING : LoadingStatus.IDLE
                            }
                        />
                    </div>
                )}
            </HeadingCardsSection>
        </DetailsLayout>
    );
};

import { ButtonHTMLAttributes } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';
import { LoadingStatus } from '../../button-loading-spinner/LoadingStatus.enum';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './ButtonTertiaryOnLightM.scss';
import { LoadingSpinner } from '../../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../../loading-spinner/LoadingSpinner.enums';

export type ButtonTertiaryOnLightMProps = {
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonTertiaryOnLightM = (props: ButtonTertiaryOnLightMProps) => {
    const { label, icon, disabled = false, loadingStatus = LoadingStatus.IDLE, type = 'button', ...rest } = props;

    const buttonTertiaryOnLightMClassMap = {
        'button-tertiary-on-light-m': true,
        'button-tertiary-on-light-m__only-icon': !label,
    };

    const buttonTertiaryOnLightContentClassMap = {
        'button-tertiary-on-light-m__content': true,
        'button-tertiary-on-light-m__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-tertiary-on-light-m__content__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLoadingSpinner = () => {
        if (loadingStatus === LoadingStatus.PENDING) {
            return <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />;
        }
        return null;
    };

    return (
        <button
            type={type}
            className={buildClassStringFromClassMap(buttonTertiaryOnLightMClassMap)}
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonTertiaryOnLightContentClassMap)}>
                {renderLabel()}
                {renderIcon()}
            </span>
            {renderLoadingSpinner()}
        </button>
    );
};

/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { useAuth } from '@steelbuy/auth';

import { ListingSellerDraftModel } from '@steelbuy/domain-model';
import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { ListingSellerDraftCollectionData } from './ListingSellerDraftCollectionData';
import {
    listingSellerDraftCollectionStoreAccessors,
    ListingSellerDraftCollectionStore,
    listingSellerDraftCollectionStoreName,
} from './ListingSellerDraftCollectionSlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { createCollectionDataProviderValue } from '../../util/provider/CollectionDataProviderValue';
import { CollectionDataProviderProps } from '../../util/provider/DataProviderProps';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';

export const ListingSellerDraftCollectionDataProvider = (
    props: CollectionDataProviderProps<ListingSellerDraftModel>
) => {
    const { interceptRendering = !props.lazyLoad, pendingComponent = null } = props;

    const PendingComponent = pendingComponent;

    const authConsumer = useAuth();

    const dataProviderValue = createCollectionDataProviderValue<
        ListingSellerDraftModel,
        ListingSellerDraftCollectionStore
    >(
        listingSellerDraftCollectionStoreName,
        listingSellerDraftCollectionStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        if (dataProviderIdle && !props.lazyLoad) {
            dataProviderValue.fetch(props.sortCriteria, props.filterCriteria);
        }
    }, [dataProviderIdle, props.lazyLoad]);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent !== null) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return (
        <ListingSellerDraftCollectionData.Provider value={dataProviderValue}>
            {props.children}
        </ListingSellerDraftCollectionData.Provider>
    );
};

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInvoiceAddressCollectionData } from '@steelbuy/data-provider';
import { AddressModel, AddressType, Country } from '@steelbuy/domain-model';
import { Mutable } from '@steelbuy/domain-model-types';
import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiaryOnLightM,
    FormActionbar,
    FormItem,
    IconIdentifier,
    InputCheckbox,
    InputSelect,
    InputTextfield,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';

import './AddressForm.scss';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

type AddressFormProps = {
    onCancel: () => void;
    onPrimary: (model: Mutable<AddressModel>) => void;
    address?: AddressModel;
    onDelete?: (model: AddressModel) => void;
    error?: string;
    isReadOnly?: boolean;
};

export const AddressForm = ({ address, error, onCancel, onDelete, onPrimary, isReadOnly }: AddressFormProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const invoiceAddress = useInvoiceAddressCollectionData().query();
    const defaultCountry = invoiceAddress?.length && invoiceAddress[0].country ? invoiceAddress[0].country : Country.GB;
    const [companyName, setCompanyName] = useState<string>(address?.name ?? '');
    const [addressLine1, setAddressLine1] = useState<string>(address?.addressLine1 ?? '');
    const [addressLine2, setAddressLine2] = useState<string>(address?.addressLine2 ?? '');
    const [town, setTown] = useState<string>(address?.city ?? '');
    const [postcode, setPostcode] = useState<string>(address?.postalCode ?? '');
    const [country, setCountry] = useState<Country>(address?.country ?? Country[defaultCountry]);
    const [phone, setPhone] = useState<string>(address?.phone ?? '');
    const [isPrimary, setIsPrimary] = useState<boolean>(address?.primary ?? false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (error) {
            setIsLoading(false);
        }
    }, [error]);

    const countries = Object.values(Country)
        .map((location) => ({
            label: t(`domainModel:address.country.${location}`) as string,
            value: location,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const handlePrimaryClick = () => {
        setIsLoading(true);
        onPrimary({
            addressType: address?.addressType ?? AddressType.WAREHOUSE,
            name: companyName,
            addressLine1,
            addressLine2,
            city: town,
            postalCode: postcode,
            country,
            primary: isPrimary,
            phone,
        });
    };

    const isPrimaryButtonDisabled = () => {
        // disabled if loading
        if (isLoading) {
            return true;
        }

        // disabled if mandatory fields empty
        if (!companyName || !addressLine1 || !town || !postcode || !country) {
            return true;
        }

        // disabled if data hasn't changed
        return (
            companyName === address?.name &&
            addressLine1 === address?.addressLine1 &&
            addressLine2 === address?.addressLine2 &&
            town === address?.city &&
            postcode === address?.postalCode &&
            country === address?.country &&
            isPrimary === address?.primary &&
            phone === address?.phone
        );
    };

    const renderSaveChangesButton = () => {
        if (!address) {
            return null;
        }

        return (
            <ButtonPrimary
                label={t('uiDomain:common.saveChanges')}
                disabled={isPrimaryButtonDisabled()}
                onClick={handlePrimaryClick}
            />
        );
    };

    const renderSaveNewButton = () => {
        if (address) {
            return null;
        }

        return (
            <ButtonPrimary
                icon={IconIdentifier.PLUS}
                label={t('uiDomain:addressForm.saveAddressButtonLabel')}
                disabled={isPrimaryButtonDisabled()}
                onClick={handlePrimaryClick}
            />
        );
    };

    const renderDeleteButton = () => {
        if (!address || !onDelete || address.addressType === AddressType.INVOICE) {
            return null;
        }

        return (
            <ButtonTertiaryOnLightM
                label={t('uiDomain:common.delete')}
                icon={IconIdentifier.BIN}
                disabled={address?.primary || isLoading}
                onClick={() => {
                    if (address && onDelete) {
                        setIsLoading(true);
                        onDelete(address);
                    }
                }}
            />
        );
    };

    const renderDeleteNotification = () => {
        if (!address || !onDelete || address.addressType === AddressType.INVOICE || !address.primary) {
            return null;
        }
        return (
            <Notification
                level={NotificationLevel.INFO}
                message={t('uiDomain:addressForm.primaryAddressNotDeletable')}
            />
        );
    };

    const renderPrimaryCheckbox = () => {
        if (address?.addressType === AddressType.INVOICE) {
            return null;
        }

        return (
            <FormItem>
                <InputCheckbox
                    value={t('uiDomain:addressForm.setAsPrimary')}
                    checked={isPrimary}
                    disabled={address?.primary}
                    onChange={(checked) => setIsPrimary(checked)}
                />
            </FormItem>
        );
    };

    return (
        <div className="address-form">
            {error && <Notification level={NotificationLevel.ERROR} message={error} />}
            <form>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.companyName')}
                        onChange={(value) => setCompanyName(value)}
                        value={companyName}
                        validators={[requiredFieldValidator]}
                    />
                </FormItem>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.addressLine1')}
                        onChange={(value) => setAddressLine1(value)}
                        value={addressLine1}
                        validators={[requiredFieldValidator]}
                    />
                </FormItem>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.addressLine2')}
                        onChange={(value) => setAddressLine2(value)}
                        value={addressLine2}
                    />
                </FormItem>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.town')}
                        onChange={(value) => setTown(value)}
                        value={town}
                        validators={[requiredFieldValidator]}
                    />
                </FormItem>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.postcode')}
                        onChange={(value) => setPostcode(value)}
                        value={postcode}
                        validators={[requiredFieldValidator]}
                    />
                </FormItem>
                <FormItem>
                    <InputSelect
                        isReadonly={isReadOnly ?? true}
                        onSelect={(value) => {
                            value && setCountry(value);
                        }}
                        label={t('uiDomain:addressForm.country')}
                        options={countries}
                        value={country}
                        validators={[requiredFieldValidator]}
                    />
                </FormItem>
                <FormItem>
                    <InputTextfield
                        label={t('uiDomain:addressForm.phoneNumber')}
                        onChange={(value) => setPhone(value)}
                        value={phone}
                    />
                </FormItem>
                {renderPrimaryCheckbox()}
                <div className="address-form__notification-and-action-bar">
                    {renderDeleteNotification()}
                    <FormActionbar>
                        <ButtonSecondary
                            label={t('uiDomain:common.cancel')}
                            onClick={() => {
                                onCancel();
                            }}
                        />
                        {renderDeleteButton()}
                        {renderSaveChangesButton()}
                        {renderSaveNewButton()}
                    </FormActionbar>
                </div>
            </form>
        </div>
    );
};

// eslint-disable-next-line max-classes-per-file
import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfMonetaryAmountFieldNames,
    MonetaryAmount,
    MonetaryAmountFieldNames,
} from '@steelbuy/domain-model-types';
import { PaymentTerms } from './OrganisationModel.enums';

export type FinancialDetailsWithoutBalanceModel = {
    readonly erpCreditLimit: MonetaryAmount;
    paymentTerms: PaymentTerms;
};

export type FinancialDetailsWithBalanceModel = {
    readonly erpCreditLimit: MonetaryAmount;
    readonly erpBalance: MonetaryAmount;
    currencyCode: CurrencyCode;
    paymentTerms: PaymentTerms;
};

export type FinancialDetailsModel = FinancialDetailsWithBalanceModel | FinancialDetailsWithoutBalanceModel;

export class FinancialDetailsWithoutBalanceModelConverter extends ModelConverter<FinancialDetailsWithoutBalanceModel> {
    override getMonetaryAmountFields(): Array<
        | MonetaryAmountFieldNames<FinancialDetailsWithoutBalanceModel>
        | ArrayOfMonetaryAmountFieldNames<FinancialDetailsWithoutBalanceModel>
    > {
        return ['erpCreditLimit'];
    }
}

export class FinancialDetailsWithBalanceModelConverter extends ModelConverter<FinancialDetailsWithBalanceModel> {
    override getMonetaryAmountFields(): Array<
        | MonetaryAmountFieldNames<FinancialDetailsWithBalanceModel>
        | ArrayOfMonetaryAmountFieldNames<FinancialDetailsWithBalanceModel>
    > {
        return ['erpCreditLimit', 'erpBalance'];
    }
}

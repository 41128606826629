import { MildSteelCoatedSteel } from './MildSteelCoatedSteel';
import { MildSteelColdRolled } from './MildSteelColdRolled';
import { MildSteelElectroZinc } from './MildSteelElectroZinc';
import { MildSteelHotRolled } from './MildSteelHotRolled';
import { MildSteelHotRolledFloorplate } from './MildSteelHotRolledFloorplate';
import {
    MildSteelBoilerPressureVesselGrade,
    MildSteelHighStrengthStructuralGrade,
    MildSteelHighStrengthStructuralSpecification,
    MildSteelMediumCarbonSteelGrade,
    MildSteelMediumCarbonSteelSpecification,
    MildSteelOffshoreGrade,
    MildSteelOffshoreSpecification,
    MildSteelPlateFinish,
    MildSteelStructuralSteelGrade,
    MildSteelStructuralSteelSpecification,
    MildSteelWearResistantGrade,
} from './MildSteelPlate';
import { Definition, Product, ProductType } from '../../../MaterialModel.enums';

/**
 * Coil and sheet
 */
type AnyMildSteelHotRolledMaterialModel = {
    definition: Definition.HOT_ROLLED;
} & MildSteelHotRolled;

type AnyMildSteelHotRolledFloorplateMaterialModel = {
    definition: Definition.HOT_ROLLED_FLOORPLATE;
} & MildSteelHotRolledFloorplate;

type AnyMildSteelColdRolledMaterialModel = {
    definition: Definition.COLD_ROLLED;
} & MildSteelColdRolled;

type AnyMildSteelCoatedMaterialModel = {
    definition: Definition.COATED_STEEL;
} & MildSteelCoatedSteel;

type AnyMildSteelElectroZincMaterialModel = {
    definition: Definition.ELECTRO_ZINC;
} & MildSteelElectroZinc;

type MildSteelCoilOrSheetDefinitions =
    | AnyMildSteelHotRolledMaterialModel
    | AnyMildSteelHotRolledFloorplateMaterialModel
    | AnyMildSteelColdRolledMaterialModel
    | AnyMildSteelCoatedMaterialModel
    | AnyMildSteelElectroZincMaterialModel;

export const mildSteelCoilOrSheetDefinitions = [
    Definition.COATED_STEEL,
    Definition.COLD_ROLLED,
    Definition.ELECTRO_ZINC,
    Definition.HOT_ROLLED,
    Definition.HOT_ROLLED_FLOORPLATE,
] as const;

export type AnyMildSteelCoilMaterialModel = {
    product: Product.COIL;
} & MildSteelCoilOrSheetDefinitions;

export type AnyMildSteelSheetMaterialModel = {
    product: Product.SHEET;
} & MildSteelCoilOrSheetDefinitions;

/**
 * Plate
 */
type MildSteelPlateMaterialModelFoundation = {
    finish: MildSteelPlateFinish;
};

type AnyMildSteelWearResistantMaterialModel = {
    plateType: ProductType.WEAR_RESISTANT;
    grade: MildSteelWearResistantGrade;
};

type AnyMildSteelHighStrengthStructuralMaterialModel = {
    plateType: ProductType.HIGH_STRENGTH_STRUCTURAL;
    grade: MildSteelHighStrengthStructuralGrade;
    specification: MildSteelHighStrengthStructuralSpecification;
};

type AnyMildSteelStructuralSteelMaterialModel = {
    plateType: ProductType.STRUCTURAL_STEEL;
    grade: MildSteelStructuralSteelGrade;
    specification: MildSteelStructuralSteelSpecification;
};

type AnyMildSteelMediumCarbonSteelMaterialModel = {
    plateType: ProductType.MEDIUM_CARBON_STEEL;
    grade: MildSteelMediumCarbonSteelGrade;
    specification: MildSteelMediumCarbonSteelSpecification;
};

type AnyMildSteelOffshoreMaterialModel = {
    plateType: ProductType.OFFSHORE;
    grade: MildSteelOffshoreGrade;
    specification: MildSteelOffshoreSpecification;
};

type AnyMildSteelBoilerPressureVesselMaterialModel = {
    plateType: ProductType.BOILER_PRESSURE_VESSEL;
    grade: MildSteelBoilerPressureVesselGrade;
};

type AnyMildSteelRoadPlateMaterialModel = {
    plateType: ProductType.ROAD_PLATE;
};

type MildSteelPlateTypes =
    | AnyMildSteelWearResistantMaterialModel
    | AnyMildSteelHighStrengthStructuralMaterialModel
    | AnyMildSteelStructuralSteelMaterialModel
    | AnyMildSteelMediumCarbonSteelMaterialModel
    | AnyMildSteelOffshoreMaterialModel
    | AnyMildSteelBoilerPressureVesselMaterialModel
    | AnyMildSteelRoadPlateMaterialModel;

export type AnyMildSteelPlateMaterialModel = {
    product: Product.PLATE;
} & MildSteelPlateMaterialModelFoundation &
    MildSteelPlateTypes;

export type MildSteelFlatMaterialModel =
    | AnyMildSteelCoilMaterialModel
    | AnyMildSteelSheetMaterialModel
    | AnyMildSteelPlateMaterialModel;

import { AluminiumFlatTreadPlateGradeAndTemper } from './AluminiumFlatTreadPlate';
import {
    CoatingType,
    CoatingThicknessType,
    Grade,
    Product,
    Temper,
    CoatingCoverage,
    CoatingColourType,
} from '../../../MaterialModel.enums';

export const aluminiumGrades = [
    Grade.G_1050,
    Grade.G_1050A,
    Grade.G_1050AQ,
    Grade.G_1060,
    Grade.G_1070A,
    Grade.G_1080A,
    Grade.G_1100,
    Grade.G_1200,
    Grade.G_1350_BS_2897,
    Grade.G_2014,
    Grade.G_2014A,
    Grade.G_2017A,
    Grade.G_2024,
    Grade.G_3003,
    Grade.G_3004,
    Grade.G_3005,
    Grade.G_3103,
    Grade.G_3105_BS_4300_6,
    Grade.G_4006,
    Grade.G_4007,
    Grade.G_4015,
    Grade.G_5005_BS_4300_7,
    Grade.G_5040,
    Grade.G_5049,
    Grade.G_5050,
    Grade.G_5052,
    Grade.G_5083,
    Grade.G_5086,
    Grade.G_5154A,
    Grade.G_5182,
    Grade.G_5251,
    Grade.G_5454_BS_4300_8,
    Grade.G_5754,
    Grade.G_55HX,
    Grade.G_6060,
    Grade.G_6061,
    Grade.G_6063,
    Grade.G_6082,
    Grade.G_7020_BS_4300_14,
    Grade.G_7021,
    Grade.G_7022,
    Grade.G_7050,
    Grade.G_7075,
    Grade.G_8011A,
    Grade.J57S,
    Grade.J57SUP,
] as const;

export const aluminiumCoilAndSheetGrades = [Grade.G_1030, ...aluminiumGrades] as const;

export const aluminiumTempers = [
    Temper.F,
    Temper.O,
    Temper.H,
    Temper.H111,
    Temper.H112,
    Temper.H114,
    Temper.H115,
    Temper.H116,
    Temper.H12,
    Temper.H14,
    Temper.H15,
    Temper.H16,
    Temper.H18,
    Temper.H19,
    Temper.H22,
    Temper.H24,
    Temper.H26,
    Temper.H28,
    Temper.H32,
    Temper.H321,
    Temper.H232,
    Temper.H34,
    Temper.H343,
    Temper.H36,
    Temper.H38,
    Temper.H39,
    Temper.T1,
    Temper.T2,
    Temper.T3,
    Temper.T351,
    Temper.T352,
    Temper.T3510,
    Temper.T3511,
    Temper.T36,
    Temper.T361,
    Temper.T4,
    Temper.T42,
    Temper.T451,
    Temper.T4510,
    Temper.T4511,
    Temper.T5,
    Temper.T6,
    Temper.T62,
    Temper.T651,
    Temper.T6510,
    Temper.T6511,
    Temper.T7,
    Temper.T72,
    Temper.T73,
    Temper.T7351,
    Temper.T8,
    Temper.T81,
    Temper.T851,
    Temper.T8510,
    Temper.T8511,
    Temper.T9,
    Temper.T10,
] as const;

type AluminiumGradeAndTemper = {
    grade: typeof aluminiumGrades[number];
    temper: typeof aluminiumTempers[number];
};

type AluminiumCoilOrPlateOrTreadPlateMaterialModel = {
    product: Product.COIL | Product.PLATE | Product.TREAD;
};

type AluminiumSheetMaterialModel = {
    product: Product.SHEET;
    coatingThicknessValue: CoatingThicknessType;
    coatingColour: CoatingColourType;
    coatingType: CoatingType;
    coatingCoverage: CoatingCoverage;
};

type AluminiumTreadPlateMaterialModel = {
    product: Product.TREAD;
} & AluminiumFlatTreadPlateGradeAndTemper;

export type AluminiumFlatMaterialModel =
    | ((AluminiumCoilOrPlateOrTreadPlateMaterialModel | AluminiumSheetMaterialModel) & AluminiumGradeAndTemper)
    | AluminiumTreadPlateMaterialModel;

import { ListingSellerCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingSellerModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCollectionSlice,
    createSelectCollection,
    createSelectCollectionEntity,
    createSelectCreated,
} from '../../util/store/crud/CollectionSlice';
import { CollectionStore } from '../../util/store/crud/CollectionStore';
import { CollectionStoreAccessors } from '../../util/store/crud/CollectionStoreAccessor';
import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchAllThunk,
    createFetchNextThunk,
    createFetchOneThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const listingSellerCollectionStoreName = 'listing-seller-collection-crud';
export type ListingSellerCollectionStore = {
    [listingSellerCollectionStoreName]: CollectionStore<ListingSellerModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingSellerCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetchAll = createFetchAllThunk<ListingSellerModel, ListingSellerCollectionStore, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const fetchNext = createFetchNextThunk<ListingSellerModel, ListingSellerCollectionStore, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const fetchOne = createFetchOneThunk<ListingSellerModel, ListingSellerCollectionStore, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const createEntity = createCreateThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerCollectionStoreName,
    createApiClient
);

// Slice definition
export const listingSellerCollectionSlice = createCollectionSlice<ListingSellerModel>(
    listingSellerCollectionStoreName,
    fetchAll,
    fetchNext,
    fetchOne,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetFetchStatus, resetActionStatus } = listingSellerCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<ListingSellerModel, ListingSellerCollectionStore>(
    listingSellerCollectionStoreName
);
const selectEntity = createSelectCollectionEntity<ListingSellerModel, ListingSellerCollectionStore>(
    listingSellerCollectionStoreName
);
const selectCreated = createSelectCreated<ListingSellerModel, ListingSellerCollectionStore>(
    listingSellerCollectionStoreName
);

// Export the store accessors to be used by the data provider value
export const listingBuyerCollectionStoreAccessors = {
    fetchAll,
    fetchNext,
    fetchOne,
    createEntity,
    mutateEntity,
    deleteEntity,
    resetFetchStatus,
    resetActionStatus,
    selectCollection,
    selectEntity,
    selectCreated,
    entityService,
} as CollectionStoreAccessors<ListingSellerModel, ListingSellerCollectionStore>;

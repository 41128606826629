import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWithdrawOfferMutation } from '@steelbuy/data-access';
import { useModal } from '@steelbuy/modal-dialog';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const OfferWithdrawModal = ({
    show,
    negotiationId,
    onModalClose,
    onStatusChange,
}: {
    show: boolean;
    negotiationId: string;
    onModalClose: () => void;
    onStatusChange: (message?: string, errorMessage?: string, forceRefetch?: boolean) => void;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const modal = useModal(onModalClose);
    const { isLoading, mutate } = useWithdrawOfferMutation();
    const handleWithdrawOffer = () => {
        mutate(negotiationId, {
            onSuccess: () => {
                modal.hide();
                onStatusChange(undefined, t('application.offers.withdrawMsg'), true);
            },
            onError: () => {
                modal.hide();
                onStatusChange(undefined, t('application.offers.withdrawErrorMsg'));
            },
        });
    };

    useEffect(() => {
        if (show) {
            modal.show();
        } else {
            modal.hide();
        }
    }, [show]);
    return (
        <ConfirmCancelModel
            modalAccessor={modal}
            header={t('application.offers.withdrawModalHeader')}
            bodyImage="assets/lib-ui-primitive/images/DeleteItem.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={t('application.offers.withdraw')}
            onSubmit={handleWithdrawOffer}
            isSubmitting={isLoading}
            isDanger
            onClose={onModalClose}
        />
    );
};

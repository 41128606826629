import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getShapes, MaterialProperties, Shape } from '@steelbuy/domain-model';
import { FormItem, InputRadioButton, InputRadioButtonGroup, InputRadioButtonGroupAlign } from '@steelbuy/ui-primitive';

const disabledShapes = [Shape.LONG, Shape.TUBE_PIPE];

interface MaterialShapeSelectionProps {
    material: MaterialProperties;
    shapeSelected: (shape: Shape) => void;
}

export const MaterialShapeSelection = ({ material, shapeSelected }: MaterialShapeSelectionProps) => {
    const { t } = useTranslation('domainModel');
    const availableShapes = getShapes(material);

    useEffect(() => {
        if (material.shape === undefined || !availableShapes.includes(material.shape)) {
            shapeSelected(availableShapes[0]);
        }
    }, [material.shape, shapeSelected, availableShapes]);

    return (
        <FormItem header={t('material.shape.name')}>
            <InputRadioButtonGroup align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}>
                {availableShapes.map((shape) => (
                    <InputRadioButton
                        key={shape}
                        name="materialShape"
                        value={t(`material.shape.value.${shape}`)}
                        onChange={() => shapeSelected(shape)}
                        checked={shape === material.shape}
                        disabled={disabledShapes.includes(shape)}
                    />
                ))}
            </InputRadioButtonGroup>
        </FormItem>
    );
};

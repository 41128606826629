import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import './SubTab.scss';

export type SubTabProps<SubTabId extends string = string> = {
    children: ReactNode;
    activeSubTab: SubTabId;
    renderSubTabContent: (subTabId: SubTabId) => ReactNode;
    subTabChanged?: (subTabId: SubTabId) => void;
};

type SubTabContext<SubTabId extends string = string> = {
    getSubTabId: () => SubTabId;
    setSubTabId: (subTabId: SubTabId) => void;
};

// eslint-disable-next-line
export const SubTabContext = createContext<SubTabContext<any>>({
    getSubTabId: (): string => {
        throw new Error('Context provider missing');
    },
    setSubTabId: (subTabId: string): void => {
        throw new Error('Context provider missing');
    },
});

export const SubTab = <SubTabId extends string = string>(props: SubTabProps<SubTabId>) => {
    const { children, activeSubTab, renderSubTabContent, subTabChanged = undefined } = props;

    const [subTabId, setSubTabId] = useState<SubTabId>(activeSubTab);

    // propagate tab changes to the outside
    useEffect(() => {
        subTabChanged?.(subTabId);
    }, [subTabChanged, subTabId]);

    // handle tab changes from the outside
    useEffect(() => {
        setSubTabId(activeSubTab);
    }, [activeSubTab]);

    const contextValue = useMemo(
        () => ({
            getSubTabId: (): SubTabId => subTabId,
            setSubTabId: (tab: SubTabId): void => {
                setSubTabId(tab);
            },
        }),
        [subTabId, setSubTabId]
    );

    return (
        <SubTabContext.Provider value={contextValue}>
            <div className="sub-tab">
                {children}
                <div className="sub-tab__content">{renderSubTabContent(subTabId)}</div>
            </div>
        </SubTabContext.Provider>
    );
};

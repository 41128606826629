import { useContext, useEffect, Context } from 'react';
import { FilterCriteria, PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { ViewModel } from '@steelbuy/domain-model-types';
import { Nullable } from '@steelbuy/types';

import { CollectionDataProviderValue } from '../provider/CollectionDataProviderValue';
import { FetchStatus } from '../store/FetchStatus';

export const useCollectionData = <Model extends ViewModel>(
    v: Context<CollectionDataProviderValue<Model>>
): CollectionDataProviderValue<Model> => useContext(v);

/**
 * Adds effects to refresh the given data provider on initial render.
 * Caution: Should only be used with lazy loaded providers to avoid rendering loops!
 */
export const useRefreshingCollection = <Model extends ViewModel>(
    dataProviderValue: CollectionDataProviderValue<Model>,
    defaultSortCriteria?: Nullable<SortCriteria<Model> | PreparedSortCriteria>,
    defaultFilterCriteria?: Nullable<FilterCriteria<Model>>
): FetchStatus => {
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const sort = dataProviderValue.querySortCriteria() ?? defaultSortCriteria ?? undefined;
    const filter = dataProviderValue.queryFilterCriteria() ?? defaultFilterCriteria ?? undefined;
    const refresh = () => {
        dataProviderValue.fetch(sort, filter);
    };

    dataProviderValue.useFetchStatusEffect([FetchStatus.IDLE], refresh);

    useEffect(() => {
        if (fetchStatus === FetchStatus.SUCCESS) {
            dataProviderValue.resolveFetchStatus();
        }
    }, []);

    return fetchStatus;
};

import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { LoadingStatus } from '../../button-loading-spinner/LoadingStatus.enum';
import { Icon } from '../../icon/Icon';

import { IconIdentifier } from '../../icon/Icon.enums';

import './ButtonGhostSystemOnLightM.scss';
import { LoadingSpinner } from '../../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../../loading-spinner/LoadingSpinner.enums';

export type ButtonGhostSystemOnLightMProps = {
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonGhostSystemOnLightM = (props: ButtonGhostSystemOnLightMProps) => {
    const {
        label,
        icon,
        loadingStatus = LoadingStatus.IDLE,
        disabled = false,
        type = 'button',
        className = '',
        ...rest
    } = props;

    const buttonGhostSystemOnLightMPropsClassMap = {
        'button-ghost-system-on-light-m': true,
        'button-ghost-system-on-light-m__icon': !label,
    };

    const buttonGhostSystemOnLightMContentClassMap = {
        'button-ghost-system-on-light-m__content': true,
        'button-ghost-system-on-light-m__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-ghost-system-on-light-m__content__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLoadingSpinner = () => {
        if (loadingStatus === LoadingStatus.PENDING) {
            return <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />;
        }
        return null;
    };

    return (
        <button
            type={type}
            className={`${buildClassStringFromClassMap(buttonGhostSystemOnLightMPropsClassMap)} ${className}`}
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonGhostSystemOnLightMContentClassMap)}>
                {renderLabel()}
                {renderIcon()}
            </span>
            {renderLoadingSpinner()}
        </button>
    );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MyOffersModel, OfferListingStatus, OfferView } from '@steelbuy/domain-model';
import { ListingBuyerTeaser } from '@steelbuy/ui-domain';
import { ButtonTertiaryOnLightM, Card, DataHeader, LoadingSpinner, LoadingStatus } from '@steelbuy/ui-primitive';
import { useMyOffersContext } from './MyOffersContext';
import { RoutePath, createRouteUrl } from '../../router/Routes';
import { OffersFilter } from '../offers/OffersFilter';

interface MyOffersListProps {
    totalResults?: number;
    results: ReadonlyArray<MyOffersModel>;
    hasNext: boolean | undefined;
    handleLoadMore: () => void;
    isLoading: boolean;
    isFetchingNextPage: boolean;
}

export const MyOfferList = ({
    handleLoadMore,
    hasNext = false,
    results,
    totalResults,
    isLoading,
    isFetchingNextPage,
}: MyOffersListProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { setFilter, filter } = useMyOffersContext();

    return (
        <div className="my-offer-list">
            <DataHeader
                headingValue={
                    totalResults ? t('application.myOffers.dataHeader.numberOfOffers', { count: totalResults }) : ''
                }
            >
                <OffersFilter
                    title={t('application.offerListingsFilter.selectLabel')}
                    placeholder={t('application.offerListingsFilter.selectPlaceholder')}
                    setFilter={setFilter}
                    filter={filter}
                    offerView={OfferView.BUYER}
                />
            </DataHeader>
            {isLoading ? (
                <div className="my-offer-list__loading-container">
                    <LoadingSpinner fullHeight={false} />
                </div>
            ) : (
                !!results.length && (
                    <>
                        <div className="my-offer-list__table">
                            {results.map(
                                (myOffers): ReactNode => (
                                    <Card
                                        isClickable
                                        url={createRouteUrl(RoutePath.MY_OFFERS_DETAILS, [
                                            'listingId',
                                            myOffers.listing.id,
                                        ])}
                                        key={myOffers.id}
                                    >
                                        <ListingBuyerTeaser
                                            listing={myOffers.listing}
                                            isNew={
                                                !myOffers.isRead &&
                                                myOffers.status !== OfferListingStatus.AWAITING_SELLER
                                            }
                                            offerStatus={myOffers.status}
                                            numberOffers={myOffers.listing.totalNumberOfNegotiation}
                                            numberActiveOffers={myOffers.listing.numberOfActiveNegotiation}
                                            offerPrice={
                                                myOffers.offer[myOffers.offer.length - 1].price.priceIncludingDelivery
                                            }
                                            referenceId={myOffers.referenceId}
                                            {...myOffers.listing}
                                        />
                                    </Card>
                                )
                            )}
                        </div>

                        {totalResults !== 0 && hasNext ? (
                            <div className="my-offer-list__load-more">
                                <ButtonTertiaryOnLightM
                                    onClick={handleLoadMore}
                                    disabled={!hasNext}
                                    label={t('uiDomain:commonList.loadMore')}
                                    loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                />
                            </div>
                        ) : null}
                    </>
                )
            )}
        </div>
    );
};

import { ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { ListingStatus } from './ListingSellerModel.enums';

export type ListingSellerStatusModel = {
    value: ListingStatus;
    reason: string | undefined;
    modifiedAt: Timestamp;
};

export class ListingSellerStatusModelConverter extends ModelConverter<ListingSellerStatusModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<ListingSellerStatusModel> | ArrayOfTimestampFieldNames<ListingSellerStatusModel>
    > {
        return ['modifiedAt'];
    }
}

import { ReactNode } from 'react';

import './TabBar.scss';

export type TabBarProps = {
    children: ReactNode;
};

export const TabBar = (props: TabBarProps) => {
    const { children } = props;

    return <div className="tab-bar">{children}</div>;
};

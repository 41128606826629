import { TFunction } from 'i18next';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadFile } from '@steelbuy/domain-model-types';
import { buildClassStringFromClassMap, classNameFromEnumValue, startDownload } from '@steelbuy/util';

import { InputFileItem, FileUploadStatus } from './input-file-item/InputFileItem';
import { InputFileState } from './InputFile.enums';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { ValidationProps } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputFile.scss';

const COLLAPSED_NUMBER_OF_FILES = 10;

export const getFileSize = (totalBytes: number | undefined, t: TFunction<'uiDomain'>) => {
    if (totalBytes === undefined) {
        return '';
    }

    if (totalBytes < 1000000) {
        return t('fileInput.filesizeKB', { size: Math.floor(totalBytes / 1000) });
    }

    return t('fileInput.filesizeMB', {
        size: Math.round((totalBytes / 1000000 + Number.EPSILON) * 100) / 100,
    });
};

export type SelectedFile = {
    name: string;
    size: number;
    error?: string;
    documentId?: string;
};

export type InputFileProps = {
    label?: string;
    triggerLabel?: string;
    state?: InputFileState;
    onChange?: (files?: File[]) => void;
    helperText?: string;
    fileType?: string[];
    fileTypeHelperText?: string;
    disabled?: boolean;
    initialSelectedFileNames?: string[];
    initialSelectedFiles?: SelectedFile[];
    multiple?: boolean;
    fileStatus?: Record<string, FileUploadStatus>;
    onRemoveFile?: (filename: string) => void;
    initialFileSizes?: Record<string, number | undefined>;
    getInvalidMessage?: (file: File) => string | undefined;
    getDownloadFile?: (documentId: string) => Promise<DownloadFile>;
} & ValidationProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'multiple' | 'disabled' | 'onBlur'>;

// TODO: Add file drag&drop functionality

export const InputFile = ({
    disabled = false,
    fileType,
    forceValidation,
    helperText,
    fileTypeHelperText,
    initialSelectedFiles,
    label,
    name,
    onChange,
    required = false,
    state = InputFileState.EMPTY,
    triggerLabel = 'Browse',
    validators,
    multiple = false,
    fileStatus,
    onRemoveFile,
    getInvalidMessage,
    getDownloadFile,
    ...rest
}: InputFileProps) => {
    const { t } = useTranslation('uiDomain'); // TODO: should ui-primitive have its own translations?
    const [focus, setFocus] = useState<boolean>(false);
    const isFileStatusError =
        fileStatus && Object.values(fileStatus).includes(FileUploadStatus.Error) ? ' ' : undefined;

    const [selectedFiles, setSelectedFiles] = useState<SelectedFile[] | undefined>(initialSelectedFiles || undefined);
    const { elementRef, validationError } = useValidation<HTMLInputElement>(
        selectedFiles?.[0]?.name,
        validators,
        forceValidation,
        isFileStatusError
    );
    const [filesUploaded, setFilesUploaded] = useState<File[]>([]);
    const errorText = validationError || isFileStatusError;

    const [showExpandedView, setShowExpandedView] = useState(false);

    const handleInputFocus = () => {
        setFocus(true);
    };

    const handleInputBlur = () => {
        setFocus(false);
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!multiple) {
            const newFile = event.target.files?.[0];
            if (newFile) {
                setFilesUploaded([newFile]);
            }
            if (newFile?.name) {
                setSelectedFiles([{ name: newFile.name, size: newFile?.size, error: getInvalidMessage?.(newFile) }]);
                onChange?.([newFile]);
            }
        } else if (event.target.files?.length) {
            const uploadedFiles: File[] = [];
            const fileNames: string[] = [];
            const files: SelectedFile[] = Array.from(event.target.files).map((file) => {
                uploadedFiles.push(file);
                fileNames.push(file.name);
                return { name: file.name, size: file?.size || 0, error: getInvalidMessage?.(file) };
            });
            const Files = Array.from(event.target.files);
            setFilesUploaded((prev) => [...prev, ...Files]);
            setSelectedFiles((prev) => {
                const updatedFiles = prev?.filter(({ name: fileName }) => !fileNames.includes(fileName)) || [];
                return [...updatedFiles, ...files];
            });
            onChange?.(uploadedFiles);
        }
    };

    // class maps
    const inputFileClassmap = {
        'input-file': true,
        'input-file--disabled': disabled,
        'input-file--focus': focus,
        'input-file--error': errorText,
        [classNameFromEnumValue(state, 'input-file')]: true,
    };

    const helperTextClassMap = {
        'input-file__helper-text': true,
        'input-file__helper-text--error': errorText,
    };

    const renderFileItem = () => {
        if (!selectedFiles?.length) {
            return null;
        }

        return (
            <div className="input-file__item-wrapper">
                {selectedFiles.map((selectedFile, index) => {
                    if (!showExpandedView && index >= COLLAPSED_NUMBER_OF_FILES) {
                        return null;
                    }
                    const status = fileStatus?.[selectedFile.name] || FileUploadStatus.Success;
                    const size = getFileSize(selectedFile.size, t);
                    return (
                        <InputFileItem
                            key={`${selectedFile.name}-${selectedFile.size}`}
                            label={selectedFile.name}
                            onOpenFile={async (filename: string) => {
                                const fileOpened = filesUploaded.find((file) => file.name === filename);
                                if (fileOpened) {
                                    const fileUrl = URL.createObjectURL(fileOpened);
                                    window.open(fileUrl, '_blank');
                                } else {
                                    const fileToOpen = selectedFiles.find((file) => file.name === filename);
                                    if (fileToOpen?.documentId && getDownloadFile) {
                                        const downloadFile = await getDownloadFile(fileToOpen.documentId);
                                        startDownload(downloadFile.url.value, downloadFile?.meta.fileName);
                                    }
                                }
                            }}
                            onRemoveFile={(filename) => {
                                setFilesUploaded((prev) => prev.filter((file) => file.name !== filename));
                                onRemoveFile?.(selectedFile.name);
                                setSelectedFiles((prev) => {
                                    const updatedFiles = [...(prev || [])];
                                    updatedFiles.splice(index, 1);
                                    // onChange?.(updatedFiles);
                                    return updatedFiles;
                                });
                                // reset value to remove any errors if no files still have error
                                if (
                                    elementRef.current?.value &&
                                    (!fileStatus ||
                                        !Object.entries(fileStatus).filter(
                                            ([file, uploadStatus]) =>
                                                file !== selectedFile.name && uploadStatus === FileUploadStatus.Error
                                        ).length)
                                ) {
                                    elementRef.current.value = '';
                                }
                            }}
                            sizeAndStatus={
                                status === FileUploadStatus.Error
                                    ? size
                                    : t('fileInput.sizeAndStatus', {
                                          size,
                                          status: t(`fileInput.fileUploadStatus.${status}`),
                                      })
                            }
                            status={status}
                            errorMessage={selectedFile.error}
                        />
                    );
                })}
            </div>
        );
    };

    const renderHelperText = () => {
        const text = errorText ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const renderErrorIcon = () => {
        if (errorText === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const renderLabel = () => {
        if (!label) {
            return null;
        }

        return <span className="input-file__wrapper__label">{label}</span>;
    };

    const totalFiles = selectedFiles ? selectedFiles.length : 0;
    const uploadedCount = fileStatus
        ? Object.values(fileStatus).filter((status) => status === FileUploadStatus.Success).length
        : 0;

    return (
        <>
            <div className={buildClassStringFromClassMap(inputFileClassmap)}>
                {/* hidden input serves as indicator if file has been selected */}
                <input type="hidden" name={name} value={selectedFiles?.[0]?.name ?? ''} />
                <span className="input-file__wrapper">
                    {renderLabel()}
                    {renderErrorIcon()}
                </span>
                <label>
                    <span className="input-file__content">
                        <Icon name={IconIdentifier.UPLOAD} />
                        <span>{t('fileInput.fileInputLabel')}</span>
                        <span className="input-file__trigger">{triggerLabel}</span>
                        {fileType !== undefined && (
                            <span>{fileTypeHelperText ?? fileType.join(', ').replace(/\./g, '')}</span>
                        )}
                        <input
                            ref={elementRef}
                            className="input-file__content"
                            type="file"
                            accept={fileType ? fileType.join(',') : undefined}
                            multiple={multiple}
                            disabled={disabled}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            required={required}
                            onChange={handleChange}
                            {...rest}
                        />
                    </span>
                </label>
                {renderHelperText()}
                {totalFiles > 0 && (
                    <div className="input-file__uploaded-files">
                        {t('fileInput.filesUploaded', {
                            uploadedCount,
                            totalFiles,
                        })}
                    </div>
                )}
            </div>
            {renderFileItem()}
            {selectedFiles && selectedFiles.length > 10 && (
                <div className="input-file__show-more">
                    <ButtonTertiaryOnLightM
                        onClick={() => setShowExpandedView((prev) => !prev)}
                        label={showExpandedView ? t('fileInput.showLessLabel') : t('fileInput.showMoreLabel')}
                    />
                </div>
            )}
        </>
    );
};

import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';

export const CheckoutErrorNotification = ({ errorMessage }: { errorMessage: string }) => (
    <Notification
        level={NotificationLevel.ERROR}
        message={errorMessage}
        disableScroll
        className="required-field-error"
    />
);

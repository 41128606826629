import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getFinishes, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialFinishSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules: any;
    error?: FieldError;
}

export const MaterialFinishSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    material,
    name,
    control,
    rules,
    error,
}: MaterialFinishSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const availableFinishes = getFinishes(material)?.slice()?.sort();

    return (
        availableFinishes.length > 0 && (
            <Select
                options={availableFinishes.map((materialFinish) => ({
                    label: t(`domainModel:material.finish.value.${materialFinish}`),
                    value: materialFinish,
                }))}
                placeholder={t(`uiDomain:materialInput.placeholder`)}
                name={name}
                control={control}
                rules={rules}
                error={error?.message}
            />
        )
    );
};

import { createContext } from 'react';
import { TrackingEvent } from '../events/tracking-events';

export interface AnalyticsContextValue {
    sendEvent: (event: TrackingEvent, payload?: Record<string, unknown>) => void;
}

// noinspection JSUnusedLocalSymbols
export const AnalyticsContext = createContext<AnalyticsContextValue>({
    sendEvent: () => {},
});

import { ApiTimestamp, Timestamp } from './Timestamp';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTimestamp = (timestamp?: any): timestamp is Timestamp => {
    if ((timestamp ?? null) === null) {
        return false;
    }
    return (
        (timestamp as Timestamp).discriminator === 'Timestamp' &&
        (timestamp as Timestamp).value !== undefined &&
        typeof timestamp.value === 'string'
    );
};

export const isApiTimestamp = (apiTimestamp?: unknown): apiTimestamp is ApiTimestamp => {
    if ((apiTimestamp ?? null) === null) {
        return false;
    }
    return typeof apiTimestamp === 'string';
};

import { useEffect, useState } from 'react';
import { ActionStatus, useListingBuyerEntityData, useOrderBuyerCreateEntityData } from '@steelbuy/data-provider';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { useEffectOnce } from '@steelbuy/util';
import { CheckoutForm } from './CheckoutForm';
import { CheckoutError } from '../checkout-error/CheckoutError';
import { CheckoutSuccess } from '../checkout-success/CheckoutSuccess';

enum CheckoutStatus {
    OPEN = 'OPEN',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

interface CheckoutStatusGuardProps {
    listingDetailPath: string;
}

export const CheckoutStatusGuard = (props: CheckoutStatusGuardProps) => {
    const { listingDetailPath } = props;
    const orderBuyerCreateEntityData = useOrderBuyerCreateEntityData();
    const listingEntityData = useListingBuyerEntityData();
    const [retryCount, setRetryCount] = useState(0);

    const onRetry = () => {
        // wait for status update to fix UI flicker
        setTimeout(() => {
            setRetryCount((prev) => prev + 1);
        }, 0);
    };

    const [status, setStatus] = useState(CheckoutStatus.LOADING);

    orderBuyerCreateEntityData.useActionStatusEffect(
        [ActionStatus.IDLE],
        () => {
            if (status === CheckoutStatus.LOADING || status === CheckoutStatus.FAILED) {
                setStatus(CheckoutStatus.OPEN);
            }
        },
        false
    );

    orderBuyerCreateEntityData.useActionStatusEffect(
        [ActionStatus.CREATE_PENDING],
        () => {
            setStatus(CheckoutStatus.LOADING);
        },
        false
    );

    orderBuyerCreateEntityData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            setStatus(CheckoutStatus.SUCCESS);
            setRetryCount(0);
        },
        false
    );

    orderBuyerCreateEntityData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setStatus(CheckoutStatus.FAILED);
        },
        false
    );

    useEffect(() => {
        if (status === CheckoutStatus.SUCCESS) {
            orderBuyerCreateEntityData.resolveActionStatus();
            listingEntityData.resolveFetchStatus();
        }
    }, [status]);

    useEffectOnce(() => () => {
        orderBuyerCreateEntityData.resolveActionStatus();
    });

    switch (status) {
        case CheckoutStatus.OPEN:
            return <CheckoutForm listingDetailPath={listingDetailPath} />;
        case CheckoutStatus.LOADING:
            return <LoadingSpinner />;
        case CheckoutStatus.SUCCESS:
            return <CheckoutSuccess />;
        default:
            return (
                <CheckoutError
                    onRetry={onRetry}
                    retryCount={retryCount}
                    onBack={() => {
                        setStatus(CheckoutStatus.OPEN);
                        setRetryCount(0);
                    }}
                />
            );
    }
};

import { Country } from '../lib/AddressModel.enums';
import { DeliveryPromise, ListingMaterialAge, ListingMaterialOrigin } from '../lib/ListingBuyerModel.enums';
import {
    Coating,
    CoatingCoverage,
    CoatingType,
    Definition,
    Finish,
    Grade,
    MaterialType,
    MillFinish,
    Polish,
    PricingUnit,
    Product,
    ProductType,
    Shape,
    Specification,
    Surface,
    Temper,
    Tolerance,
    TradeUnit,
} from '../lib/MaterialModel.enums';
import { UrgentAlertFrequency } from '../lib/NotificationSettings';
import { OfferRequestBy } from '../lib/OfferModel';
import { PaymentTerms } from '../lib/OrganisationModel.enums';
import { BASIC_USER_ROLE, UserRole } from '../lib/UserModel.enums';

export default {
    address: {
        country: {
            [Country.AT]: 'Austria',
            [Country.BE]: 'Belgium',
            [Country.BG]: 'Bulgaria',
            [Country.CH]: 'Switzerland',
            [Country.CZ]: 'Czechia',
            [Country.DE]: 'Germany',
            [Country.DK]: 'Denmark',
            [Country.ES]: 'Spain',
            [Country.FI]: 'Finland',
            [Country.FR]: 'France',
            [Country.GB]: 'United Kingdom',
            [Country.HR]: 'Croatia',
            [Country.HU]: 'Hungary',
            [Country.IE]: 'Ireland',
            [Country.IT]: 'Italy',
            [Country.LU]: 'Luxembourg',
            [Country.NL]: 'Netherlands',
            [Country.NO]: 'Norway',
            [Country.PL]: 'Poland',
            [Country.PT]: 'Portugal',
            [Country.RO]: 'Romania',
            [Country.RS]: 'Serbia',
            [Country.SE]: 'Sweden',
            [Country.SK]: 'Slovakia',
            [Country.TR]: 'Turkey',
            [Country.VN]: 'Vietnam',
            [Country.TW]: 'Taiwan',
            [Country.KR]: 'South Korea',
            [Country.IN]: 'India',
            [Country.JP]: 'Japan',
        },
    },
    deliveryPromise: {
        [DeliveryPromise.WEEKS_2_3]: '2-3 weeks',
        [DeliveryPromise.DAYS_3_5]: '3-5 days',
        [DeliveryPromise.WEEKS_3_5]: '3-5 weeks',
        [DeliveryPromise.WEEKS_5_8]: '5-8 weeks',
        [DeliveryPromise.WEEKS_8_12]: '8-12 weeks',
        [DeliveryPromise.WEEKS_12_16]: '12-16 weeks',
    },
    deliveryTimeframe: {
        [DeliveryPromise.WEEKS_2_3]: '2-3 weeks',
        [DeliveryPromise.DAYS_3_5]: '3-5 working days',
        [DeliveryPromise.WEEKS_3_5]: '3-5 weeks',
        [DeliveryPromise.WEEKS_5_8]: '5-8 weeks',
        [DeliveryPromise.WEEKS_8_12]: '8-12 weeks',
        [DeliveryPromise.WEEKS_12_16]: '12-16 weeks',
    },
    material: {
        age: {
            nameShort: 'Age',
            name: 'Age of material',
            value: {
                [ListingMaterialAge.UP_TO_THREE_MONTHS]: '0-3 months',
                [ListingMaterialAge.UP_TO_SIX_MONTHS]: '4-6 months',
                [ListingMaterialAge.UP_TO_TWELVE_MONTHS]: '7-12 months',
                [ListingMaterialAge.OLDER_THAN_TWELVE_MONTHS]: 'Older than 12 months',
                [ListingMaterialAge.UNSPECIFIED]: 'Unspecified',
            },
        },
        coating: {
            name: 'Coating',
            value: {
                [Coating.Z]: 'Z',
                [Coating.AZ]: 'AZ',
                [Coating.ZM]: 'ZM',
                [Coating.ZA]: 'ZA',
                [Coating.ZF]: 'ZF',
                [Coating.AS]: 'AS',
                [Coating.ONE_SIDED]: 'One sided',
                [Coating.TWO_SIDED]: 'Two sided',
                [Coating.PROTECTIVE_COATING]: 'Protective coating',
                [Coating.UNCOATED]: 'Uncoated (standard mill finish)',
            },
        },
        coatingColour: {
            name: 'Coating colour',
            value: {
                Clear: 'Clear',
                White: 'White',
                Blue: 'Blue',
                OTHER: 'Other',
            },
        },
        coatingCoverage: {
            name: 'Coating coverage',
            value: {
                [CoatingCoverage.ONE_SIDED]: 'One sided',
                [CoatingCoverage.TWO_SIDED]: 'Two sided',
            },
        },
        coatingThickness: {
            name: 'Coating thickness',
            value: {},
        },
        coatingType: {
            name: 'Type of coating',
            value: {
                [CoatingType.FIBRE]: 'Fibre',
                [CoatingType.CO2]: 'CO2',
                [CoatingType.FIBRE_AND_CO2]: 'Fibre & CO2',
                [CoatingType.N_A]: 'N/A',
            },
        },
        finish: {
            name: 'Finish',
            value: {
                [Finish.DRY]: 'Dry',
                [Finish.OILED]: 'Oiled',
                [Finish.PICKLED_OILED]: 'Pickled & Oiled',
                [Finish.BLACK]: 'Black',
                [Finish.SHOTBLASTED]: 'Shotblasted',
                [Finish.SHOTBLASTED_PRIMER_PAINTED]: 'Shotblasted+Primer Painted',
            },
        },
        grade: {
            name: 'Grade',
            value: {
                [Grade.A516_GR60]: 'A516/GR60',
                [Grade.A516_GR65]: 'A516/GR65',
                [Grade.A516_GR70]: 'A516/GR70',
                [Grade.A537_CLASS1]: 'A537-Class1',
                [Grade.API2H_50]: 'API2H/50',
                [Grade.API2W_50]: 'API2W/50',
                [Grade.C22]: 'C22',
                [Grade.C25]: 'C25',
                [Grade.C35]: 'C35',
                [Grade.C40]: 'C40',
                [Grade.C45]: 'C45',
                [Grade.C45E]: 'C45E',
                [Grade.C50]: 'C50',
                [Grade.C50E]: 'C50E',
                [Grade.C55]: 'C55',
                [Grade.C60]: 'C60',
                [Grade.C67S]: 'C67S',
                [Grade.DC01]: 'DC01',
                [Grade.DC01EK]: 'DC01EK',
                [Grade.DC02]: 'DC02',
                [Grade.DC03]: 'DC03',
                [Grade.DC03ED]: 'DC03ED',
                [Grade.DC04]: 'DC04',
                [Grade.DC04ED]: 'DC04ED',
                [Grade.DC04EK]: 'DC04EK',
                [Grade.DC05]: 'DC05',
                [Grade.DC06]: 'DC06',
                [Grade.DC06EK]: 'DC06EK',
                [Grade.DC07]: 'DC07',
                [Grade.DD11]: 'DD11',
                [Grade.DD12]: 'DD12',
                [Grade.DD13]: 'DD13',
                [Grade.DD14]: 'DD14',
                [Grade.DX51D]: 'DX51D',
                [Grade.DX52D]: 'DX52D',
                [Grade.DX53D]: 'DX53D',
                [Grade.DX54D]: 'DX54D',
                [Grade.DX56D]: 'DX56D',
                [Grade.EN8]: 'EN8',
                [Grade.EN8D]: 'EN8D',
                [Grade.G_1030]: '1030',
                [Grade.G_1050]: '1050',
                [Grade.G_1050A]: '1050A',
                [Grade.G_1050AQ]: '1050AQ',
                [Grade.G_1060]: '1060',
                [Grade.G_1070A]: '1070A',
                [Grade.G_1080A]: '1080A',
                [Grade.G_1100]: '1100',
                [Grade.G_1200]: '1200',
                [Grade.G_1350_BS_2897]: '1350 - BS 2897',
                [Grade.G_201_14372]: '201 / 1.4372',
                [Grade.G_2014]: '2014',
                [Grade.G_2014A]: '2014A',
                [Grade.G_2017A]: '2017A',
                [Grade.G_202_14373]: '202 / 1.4373',
                [Grade.G_2024]: '2024',
                [Grade.G_2205_14462]: '2205 / 1.4462',
                [Grade.G_2507_14539]: '2507 / 1.4539',
                [Grade.G_253_SMO_14547]: '253 SMO / 1.4547',
                [Grade.G_3003]: '3003',
                [Grade.G_3004]: '3004',
                [Grade.G_3005]: '3005',
                [Grade.G_300HB]: '300HB',
                [Grade.G_301_14310]: '301 / 1.4310',
                [Grade.G_3CR12_14003]: '3CR12 / 1.4003',
                [Grade.G_301LN_14318]: '301LN / 1.4318',
                [Grade.G_302_14310]: '302 / 1.4310',
                [Grade.G_304_14301]: '304 / 1.4301',
                [Grade.G_304L_14307]: '304L / 1.4307',
                [Grade.G_304LN_14311]: '304LN / 1.4311',
                [Grade.G_305_14303]: '305 / 1.4303',
                [Grade.G_309_14828]: '309 / 1.4828',
                [Grade.G_309S_14833]: '309S / 1.4833',
                [Grade.G_3103]: '3103',
                [Grade.G_3105_BS_4300_6]: '3105 - BS 4300/6',
                [Grade.G_310S_14845]: '310S / 1.4845',
                [Grade.G_316_14401]: '316 / 1.4401',
                [Grade.G_316L_14335]: '316L / 1.4335',
                [Grade.G_316TI_14571]: '316Ti / 1.4571',
                [Grade.G_317L_14438]: '317L / 1.4438',
                [Grade.G_321_11541]: '321 / 1.1541',
                [Grade.G_347_14550]: '347 / 1.4550',
                [Grade.G_34CRMO4]: '34CrMo4',
                [Grade.G_4006]: '4006',
                [Grade.G_4007]: '4007',
                [Grade.G_400HB]: '400HB',
                [Grade.G_4015]: '4015',
                [Grade.G_409_14512]: '409 / 1.4512',
                [Grade.G_42CRMO4]: '42CrMo4',
                [Grade.G_430_14016]: '430 / 1.4016',
                [Grade.G_430TI_14520]: '430Ti / 1.4520',
                [Grade.G_434_14113]: '434 / 1.4113',
                [Grade.G_439_14510]: '439 / 1.4510',
                [Grade.G_441_14509]: '441 / 1.4509',
                [Grade.G_450HB]: '450HB',
                [Grade.G_4589_14589]: '4589 / 1.4589',
                [Grade.G_4622_14622]: '4622 / 1.4622',
                [Grade.G_490B_LT30]: '490B LT30',
                [Grade.G_490B_LT50]: '490B LT50',
                [Grade.G_5005_BS_4300_7]: '5005 - BS 4300/7',
                [Grade.G_500HB]: '500HB',
                [Grade.G_5040]: '5040',
                [Grade.G_5049]: '5049',
                [Grade.G_5050]: '5050',
                [Grade.G_5052]: '5052',
                [Grade.G_5083]: '5083',
                [Grade.G_5086]: '5086',
                [Grade.G_50CRMO4]: '50CrMo4',
                [Grade.G_5154A]: '5154A',
                [Grade.G_5182]: '5182',
                [Grade.G_51CRV4]: '51CrV4',
                [Grade.G_5251]: '5251',
                [Grade.G_5454_BS_4300_8]: '5454 - BS 4300/8',
                [Grade.G_550HB]: '550HB',
                [Grade.G_55HX]: '55HX',
                [Grade.G_5754]: '5754',
                [Grade.G_58CRV4]: '58CrV4',
                [Grade.G_600HB]: '600HB',
                [Grade.G_6060]: '6060',
                [Grade.G_6061]: '6061',
                [Grade.G_6063]: '6063',
                [Grade.G_6082]: '6082',
                [Grade.G_654_SMO_14652]: '654 SMO / 1.4652',
                [Grade.G_7020_BS_4300_14]: '7020 - BS 4300/14',
                [Grade.G_7021]: '7021',
                [Grade.G_7022]: '7022',
                [Grade.G_7050]: '7050',
                [Grade.G_7075]: '7075',
                [Grade.G_8011A]: '8011A',
                [Grade.HCT490X]: 'HCT490X',
                [Grade.HCT590X]: 'HCT590X',
                [Grade.HCT780X]: 'HCT780X',
                [Grade.HCT780XG]: 'HCT780XG',
                [Grade.HCT980X]: 'HCT980X',
                [Grade.HCT980XG]: 'HCT980XG',
                [Grade.HDT580X]: 'HDT580X',
                [Grade.HX180BD]: 'HX180BD',
                [Grade.HX180YD]: 'HX180YD',
                [Grade.HX220BD]: 'HX220BD',
                [Grade.HX220YD]: 'HX220YD',
                [Grade.HX260BD]: 'HX260BD',
                [Grade.HX260LAD]: 'HX260LAD',
                [Grade.HX260YD]: 'HX260YD',
                [Grade.HX300BD]: 'HX300BD',
                [Grade.HX300LAD]: 'HX300LAD',
                [Grade.HX340LAD]: 'HX340LAD',
                [Grade.HX380LAD]: 'HX380LAD',
                [Grade.HX420LAD]: 'HX420LAD',
                [Grade.HX460LAD]: 'HX460LAD',
                [Grade.HX500LAD]: 'HX500LAD',
                [Grade.J57S]: 'J57S',
                [Grade.J57SUP]: 'J57SUP',
                [Grade.P265GH]: 'P265GH',
                [Grade.P265S]: 'P265S',
                [Grade.P275NH]: 'P275NH',
                [Grade.P355NH]: 'P355NH',
                [Grade.P355NL1]: 'P355NL1',
                [Grade.P355NL2]: 'P355NL2',
                [Grade.S1100]: 'S1100',
                [Grade.S1400]: 'S1400',
                [Grade.S220GD]: 'S220GD',
                [Grade.S235]: 'S235',
                [Grade.S250GD]: 'S250GD',
                [Grade.S275]: 'S275',
                [Grade.S280GD]: 'S280GD',
                [Grade.S300]: 'S300',
                [Grade.S315]: 'S315',
                [Grade.S320GD]: 'S320GD',
                [Grade.S350GD]: 'S350GD',
                [Grade.S355]: 'S355',
                [Grade.S390GD]: 'S390GD',
                [Grade.S420]: 'S420',
                [Grade.S420GD]: 'S420GD',
                [Grade.S450GD]: 'S450GD',
                [Grade.S460]: 'S460',
                [Grade.S500]: 'S500',
                [Grade.S550]: 'S550',
                [Grade.S600]: 'S600',
                [Grade.S650]: 'S650',
                [Grade.S690]: 'S690',
                [Grade.S700]: 'S700',
                [Grade.S890]: 'S890',
                [Grade.S960]: 'S960',
            },
        },
        definition: {
            name: 'Definition',
            value: {
                [Definition.HOT_ROLLED]: 'Hot Rolled',
                [Definition.HOT_ROLLED_FLOORPLATE]: 'Hot Rolled Floorplate',
                [Definition.COLD_ROLLED]: 'Cold Rolled',
                [Definition.COATED_STEEL]: 'Coated Steel',
                [Definition.ELECTRO_ZINC]: 'Electro Zinc',
            },
        },
        dimensions: {
            name: 'Dimensions',
            thickness: 'Thickness',
            width: 'Width',
            length: 'Length',
            units: {
                millimeters: 'mm',
                millimetersWithValue: '{{value}}mm',
                gramsPerSquareMetre: 'g/m²',
            },
            min: 'Min',
            max: 'Max',
            addRange: 'Add Range',
            deleteRange: 'Delete Range',
            rangeNotification: 'You will only get results within this range.',
            dimensionsNotification:
                'We will also suggest alternative results that are in close proximity to your dimensions.',
        },
        packageDetails: {
            name: 'Package Details',
            packageTitle: 'Package title',
            packageDescription: 'Package description',
        },
        millFinish: {
            name: 'Mill finish',
            value: {
                [MillFinish.MF_1D_HR]: '1D (HR)',
                [MillFinish.MF_2B_CR]: '2B (CR)',
                [MillFinish.MF_2D_CR]: '2D (CR)',
                [MillFinish.MF_2H]: '2H',
                [MillFinish.MF_2R_BA_CR]: '2R / BA (CR)',
                [MillFinish.MF_2Q_CR]: '2Q (CR)',
            },
        },
        origin: {
            name: 'Origin',
            value: {
                [ListingMaterialOrigin.WESTERN_EUROPEAN]: 'Western European',
                [ListingMaterialOrigin.OTHER_EUROPEAN]: 'Other European',
                [ListingMaterialOrigin.NON_EUROPEAN]: 'Non-European',
                [ListingMaterialOrigin.UNSPECIFIED]: 'Unspecified',
            },
        },
        polish: {
            name: 'Polish',
            value: {
                [Polish.MILL_STANDARD]: 'Mill Standard',
                [Polish.BRIGHT_POLISH]: 'Bright Polish',
                [Polish.CIRCULAR_POLISH]: 'Circular Polish',
                [Polish.DULL_POLISH]: 'Dull Polish',
                [Polish.GRIT_POLISH_240]: 'Grit Polish (240)',
                [Polish.GRIT_POLISH_360]: 'Grit Polish (360)',
                [Polish.MIRROR_POLISH]: 'Mirror Polish',
                [Polish.SATIN_POLISH]: 'Satin Polish',
            },
        },
        product: {
            name: 'Product',
            value: {
                [Product.COIL]: 'Coil',
                [Product.SHEET]: 'Sheet',
                [Product.PLATE]: 'Plate',
                [Product.TREAD]: 'Tread Plate',
                [Product.PACKAGE]: 'Package',
            },
        },
        plateType: {
            name: 'Type',
            value: {
                [ProductType.WEAR_RESISTANT]: 'Wear Resistant',
                [ProductType.HIGH_STRENGTH_STRUCTURAL]: 'High Strength Structural',
                [ProductType.STRUCTURAL_STEEL]: 'Structural Steel',
                [ProductType.MEDIUM_CARBON_STEEL]: 'Medium Carbon Steel',
                [ProductType.OFFSHORE]: 'Offshore',
                [ProductType.BOILER_PRESSURE_VESSEL]: 'Boiler & Pressure Vessel',
                [ProductType.ROAD_PLATE]: 'Road Plate',
            },
        },
        pricingUnit: {
            value: {
                [PricingUnit.TON]: {
                    symbol: 't',
                    label: 'tonne',
                    quantity_zero: '0 tonnes',
                    quantity_one: '1 tonne',
                    quantity_other: '{{count}} tonnes',
                },
                [PricingUnit.KILOGRAM]: {
                    symbol: 'KG',
                    label: 'kg',
                    quantity_zero: '0 kg',
                    quantity_one: '1 kg',
                    quantity_other: '{{count}} kg',
                },
                [PricingUnit.SHEET]: {
                    symbol: 'Sheet',
                    label: 'sheet',
                    quantity_zero: '0 sheets',
                    quantity_one: '1 sheet',
                    quantity_other: '{{count}} sheets',
                },
                [PricingUnit.PLATE]: {
                    symbol: 'Plate',
                    label: 'plate',
                    quantity_zero: '0 plates',
                    quantity_one: '1 plate',
                    quantity_other: '{{count}} plates',
                },
                [PricingUnit.TREAD]: {
                    symbol: 'Tread Plate',
                    label: 'tread plate',
                    quantity_zero: '0 tread plates',
                    quantity_one: '1 tread plate',
                    quantity_other: '{{count}} tread plates',
                },
            },
        },
        quality: {
            name: 'Quality',
            value: {
                prime: 'Prime',
                nonPrime: 'Non-Prime',
            },
        },
        shape: {
            name: 'Shape',
            value: {
                [Shape.FLAT]: 'Flat',
                [Shape.LONG]: 'Long',
                [Shape.TUBE_PIPE]: 'Tube & Pipe',
            },
        },
        specification: {
            name: 'Specification',
            value: {
                [Specification.J2_PLUS_N]: 'J2+N',
                [Specification.N_A]: 'N/A',
                [Specification.J0_PLUS_N]: 'J0+N',
                [Specification.JR_PLUS_N]: 'JR+N',
                [Specification.J2_C_PLUS_N]: 'J2 C+N',
                [Specification.K2_PLUS_N]: 'K2+N',
                [Specification.PLUS_U]: '+U',
                [Specification.PLUS_A]: '+A',
                [Specification.PLUS_S]: '+S',
                [Specification.PLUS_N]: '+N',
                [Specification.K2W_PLUS_N]: 'K2W+N',
                [Specification.G1_PLUS_N]: 'G1+N',
                [Specification.G1_PLUS_M]: 'G1+M',
                [Specification.G1_PLUS_Q]: 'G1+Q',
                [Specification.G7_PLUS_N]: 'G7+N',
                [Specification.G7_PLUS_M]: 'G7+M',
                [Specification.G8_PLUS_N]: 'G8+N',
                [Specification.G8_PLUS_M]: 'G8+M',
                [Specification.G10_PLUS_N]: 'G10+N',
                [Specification.G10_PLUS_M]: 'G10+M',
                [Specification.PLUS_M]: '+M',
                [Specification.JR]: 'JR',
                [Specification.J0]: 'J0',
                [Specification.J0W]: 'J0W',
                [Specification.J0WP]: 'J0WP',
                [Specification.J2]: 'J2',
                [Specification.J2G3]: 'J2G3',
                [Specification.J2G4]: 'J2G4',
                [Specification.J2W]: 'J2W',
                [Specification.J4]: 'J4',
                [Specification.JRC]: 'JRC',
                [Specification.E295]: 'E295',
                [Specification.E335]: 'E335',
                [Specification.E360]: 'E360',
                [Specification.N]: 'N',
                [Specification.NL]: 'NL',
                [Specification.MC]: 'MC',
                [Specification.ML]: 'ML',
                [Specification.QL]: 'QL',
                [Specification.QL1]: 'QL1',
                [Specification.GH]: 'GH',
                [Specification.NH]: 'NH',
                [Specification.NL1]: 'NL1',
                [Specification.NL2]: 'NL2',
                [Specification.K2]: 'K2',
                [Specification.K2G3]: 'K2G3',
                [Specification.K2G4]: 'K2G4',
                [Specification.K2W]: 'K2W',
                [Specification.G1]: 'G1',
                [Specification.G7]: 'G7',
                [Specification.G8]: 'G8',
                [Specification.G10]: 'G10',
                [Specification.EMZ]: 'EMZ',
            },
        },
        surface: {
            name: 'Surface',
            value: {
                [Surface.A_M]: 'A-m',
                [Surface.B_M]: 'B-m',
                [Surface.MA_C]: 'MA-C',
                [Surface.MA_O]: 'MA-O',
                [Surface.MB_C]: 'MB-C',
                [Surface.MB_O]: 'MB-O',
                [Surface.MC_O]: 'MC-O',
                [Surface.A_C]: 'A-C',
                [Surface.A_O]: 'A-O',
                [Surface.A_P]: 'A-P',
                [Surface.A_PC]: 'A-PC',
                [Surface.B_O]: 'B-O',
            },
        },
        temper: {
            name: 'Temper',
            value: {
                [Temper.F]: 'F',
                [Temper.H]: 'H',
                [Temper.H111]: 'H111',
                [Temper.H112]: 'H112',
                [Temper.H114]: 'H114',
                [Temper.H115]: 'H115',
                [Temper.H116]: 'H116',
                [Temper.H12]: 'H12',
                [Temper.H14]: 'H14',
                [Temper.H15]: 'H15',
                [Temper.H16]: 'H16',
                [Temper.H18]: 'H18',
                [Temper.H19]: 'H19',
                [Temper.H22]: 'H22',
                [Temper.H232]: 'H232',
                [Temper.H24]: 'H24',
                [Temper.H26]: 'H26',
                [Temper.H28]: 'H28',
                [Temper.H32]: 'H32',
                [Temper.H321]: 'H321',
                [Temper.H34]: 'H34',
                [Temper.H343]: 'H343',
                [Temper.H36]: 'H36',
                [Temper.H38]: 'H38',
                [Temper.H39]: 'H39',
                [Temper.O]: 'O',
                [Temper.T1]: 'T1',
                [Temper.T10]: 'T10',
                [Temper.T2]: 'T2',
                [Temper.T3]: 'T3',
                [Temper.T351]: 'T351',
                [Temper.T3510]: 'T3510',
                [Temper.T3511]: 'T3511',
                [Temper.T352]: 'T352',
                [Temper.T36]: 'T36',
                [Temper.T361]: 'T361',
                [Temper.T4]: 'T4',
                [Temper.T42]: 'T42',
                [Temper.T451]: 'T451',
                [Temper.T4510]: 'T4510',
                [Temper.T4511]: 'T4511',
                [Temper.T5]: 'T5',
                [Temper.T6]: 'T6',
                [Temper.T62]: 'T62',
                [Temper.T651]: 'T651',
                [Temper.T6510]: 'T6510',
                [Temper.T6511]: 'T6511',
                [Temper.T7]: 'T7',
                [Temper.T72]: 'T72',
                [Temper.T73]: 'T73',
                [Temper.T7351]: 'T7351',
                [Temper.T8]: 'T8',
                [Temper.T81]: 'T81',
                [Temper.T851]: 'T851',
                [Temper.T8510]: 'T8510',
                [Temper.T8511]: 'T8511',
                [Temper.T9]: 'T9',
            },
        },
        tolerance: {
            name: 'Tolerance',
            value: {
                [Tolerance.EU_NORM]: 'Euro Norm',
                [Tolerance.THREE_QUARTERS_NORM]: '¾ Euro Norm',
                [Tolerance.HALF_NORM]: '½ Euro Norm',
                [Tolerance.NON_PRIME]: 'Non-Prime',
            },
        },
        tradeUnit: {
            value: {
                [TradeUnit.TON]: {
                    symbol: 't',
                    label: 'tonne',
                    quantity_zero: '0 tonnes',
                    quantity_one: '1 tonne',
                    quantity_other: '{{count}} tonnes',
                },
                [TradeUnit.KILOGRAM]: {
                    symbol: 'KG',
                    label: 'kg',
                    quantity_zero: '0 kg',
                    quantity_one: '1 kg',
                    quantity_other: '{{count}} kg',
                },
                [TradeUnit.METER]: {
                    symbol: 'M',
                    label: 'm',
                    quantity_zero: '0 m',
                    quantity_one: '1 m',
                    quantity_other: '{{count}} m',
                },
            },
        },
        perPricingUnit: 'per {{pricingUnit}}',
        perPricingUnitIncludingDelivery: 'per {{pricingUnit}}, incl. delivery',
        offerPricing: 'offered price per {{pricingUnit}}',
        multipleOfferPricing: 'offered prices per {{pricingUnit}}',
        materialType: {
            name: 'Material',
            value: {
                [MaterialType.MILD_STEEL]: 'Mild Steel',
                [MaterialType.ALUMINIUM]: 'Aluminium',
                [MaterialType.STAINLESS_STEEL]: 'Stainless Steel',
            },
        },
    },
    offers: {
        requestedBy: {
            [OfferRequestBy.BUYER]: 'Buyer',
            [OfferRequestBy.SELLER]: 'Seller',
        },
        originalPrice: 'Original price',
        statusLabel: 'Status',
    },
    pleaseSelect: 'Please select',
    organisation: {
        paymentTerms: {
            default: '60 days from date of invoice',
            [PaymentTerms.NET_30_DAYS]: '30 days',
            [PaymentTerms.NET_60_DAYS]: '60 days',
        },
    },
    notificationSettings: {
        urgentAlerts: {
            frequency: {
                value: {
                    [UrgentAlertFrequency.INSTANTLY]: 'Instantly (Recommended)',
                    [UrgentAlertFrequency.PER_6_HOURS]: 'Per 6 hours',
                    [UrgentAlertFrequency.PER_DAY]: 'Per day',
                },
            },
        },
    },
    user: {
        permissions: {
            [UserRole.ADMIN]: 'Admin',
            [BASIC_USER_ROLE]: 'Basic',
            [UserRole.MANAGER]: 'Manage',
            [UserRole.SELLER]: 'Sell',
            [UserRole.BUYER]: 'Buy',
        },
        permissionTitle: {
            [UserRole.ADMIN]: 'Admin',
            [BASIC_USER_ROLE]: 'Basic',
            [UserRole.MANAGER]: 'Manager',
            [UserRole.SELLER]: 'Seller',
            [UserRole.BUYER]: 'Buyer',
        },
        deactivated: 'Deactivated',
    },
};

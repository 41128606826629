import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useOrganisationEntityData } from '@steelbuy/data-provider';
import { PaymentTerms } from '@steelbuy/domain-model';
import { NotFoundError } from '@steelbuy/error';

import {
    ButtonPrimary,
    ButtonSecondary,
    FormActionbar,
    FormItem,
    InputRadioButton,
    InputRadioButtonGroup,
    InputRadioButtonGroupAlign,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_ORGANISATION } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountEditPaymentTerms = () => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const navigate = useNavigate();
    const organisationEntityData = useOrganisationEntityData();
    const organisationModel = organisationEntityData.query().getOrThrow(new NotFoundError());

    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms>(organisationModel.financialDetails?.paymentTerms);
    const [error, setError] = useState<boolean>(false);

    const mutationPending = organisationEntityData.queryActionStatus() === ActionStatus.MUTATE_PENDING;

    organisationEntityData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(true);
        },
        true
    );

    organisationEntityData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            navigate(ROUTE_ACCOUNT_ORGANISATION);
        },
        false
    );

    const handleSave = () => {
        organisationEntityData.mutate({
            ...organisationModel,
            financialDetails: {
                ...organisationModel.financialDetails,
                paymentTerms,
            },
        });
    };

    const isSaveButtonDisabled = () => {
        if (mutationPending) {
            return true;
        }

        if (paymentTerms === organisationModel.financialDetails?.paymentTerms) {
            return true;
        }

        if (!paymentTerms) {
            return true;
        }

        return false;
    };

    return (
        <>
            <PageHeader
                pageTitle={t('translation:application.accountEditPaymentTerms.pageTitle')}
                previousPageTitle={t('translation:application.accountEditPaymentTerms.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
            />

            <div className="account-edit-payment-terms">
                <FormLayout>
                    {error && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('translation:application.accountEditPaymentTerms.saveErrorMessage')}
                            onClose={() => setError(false)}
                            closeButton
                        />
                    )}
                    <form>
                        <FormItem header={t('translation:application.accountEditPaymentTerms.formItemHeader')}>
                            <InputRadioButtonGroup
                                align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}
                                label={t('translation:application.accountEditPaymentTerms.inputLabel')}
                            >
                                {Object.values(PaymentTerms).map((terms) => (
                                    <InputRadioButton
                                        key={terms}
                                        checked={paymentTerms === terms}
                                        value={t(`domainModel:organisation.paymentTerms.${terms}`)}
                                        onChange={() => setPaymentTerms(terms)}
                                    />
                                ))}
                            </InputRadioButtonGroup>
                        </FormItem>
                        <FormActionbar>
                            <ButtonSecondary
                                label={t('uiDomain:common.cancel')}
                                onClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
                            />
                            <ButtonPrimary
                                label={t('uiDomain:common.saveChanges')}
                                disabled={isSaveButtonDisabled()}
                                onClick={handleSave}
                            />
                        </FormActionbar>
                    </form>
                </FormLayout>
            </div>
        </>
    );
};

import { useMutation } from '@tanstack/react-query';
import axios from '../axios';

export type StartNegotiationResponse = {
    referenceId: string;
};

const mutateBuyerOffer = async ({ listingId, buyerPrice }: { listingId: string; buyerPrice: number }) => {
    const { data } = await axios.post<StartNegotiationResponse>('/api/buyer/negotiations/start-negotiation', {
        listingId,
        buyerPrice,
    });
    return data;
};
export const useBuyerOfferMutation = () =>
    useMutation({
        mutationFn: mutateBuyerOffer,
    });

const mutateSellerAcceptOffer = async ({ negotiationId }: { negotiationId: string }) => {
    const { data } = await axios.post<StartNegotiationResponse>(`/api/universal/negotiations/${negotiationId}/accept`);
    return data;
};

export const useSellerAcceptOfferMutation = () =>
    useMutation({
        mutationFn: mutateSellerAcceptOffer,
    });

const mutateSellerRejectOffer = async ({ negotiationId }: { negotiationId: string }) => {
    const { data } = await axios.post<StartNegotiationResponse>(`/api/universal/negotiations/${negotiationId}/reject`);
    return data;
};

export const useSellerRejectOfferMutation = () =>
    useMutation({
        mutationFn: mutateSellerRejectOffer,
    });

const mutateCounterOffer = async ({ negotiationId, price }: { negotiationId: string; price: number }) => {
    const { data } = await axios.post<StartNegotiationResponse>(
        `/api/universal/negotiations/${negotiationId}/counter`,
        {
            price,
        }
    );
    return data;
};

export const useCounterOfferMutation = () =>
    useMutation({
        mutationFn: mutateCounterOffer,
    });

const mutateWithdrawOffer = async (negotiationId: string) => {
    const { data } = await axios.put<StartNegotiationResponse>(`/api/universal/negotiations/${negotiationId}/withdraw`);
    return data;
};

export const useWithdrawOfferMutation = () =>
    useMutation({
        mutationFn: mutateWithdrawOffer,
    });

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exchangeDocumentIdForAccessibleLink, exchangeUrlForAccessibleLink } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { getToken } from '@steelbuy/data-access';

import { DownloadFile, isDownloadFile, isUploadFile, UploadFile } from '@steelbuy/domain-model-types';
import { ButtonTertiaryOnLightM, getFileSize, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { FileDownloadClickable } from '../file-download-clickable/FileDownloadClickable';
import './FileMultiUploadItemView.scss';

type FileMultiUploadItemViewProps = {
    files: Array<DownloadFile | UploadFile>;
    apiBaseUrl: string;
    isPicture?: boolean;
};
const VIEW_LESS_FILES = 3;
export const FileMultiUploadItemView = ({ files, apiBaseUrl, isPicture }: FileMultiUploadItemViewProps) => {
    const { t } = useTranslation('uiDomain');
    const [pictureUri, setPictureUri] = useState<string[]>([]);
    const [visibleFiles, setVisibleFiles] = useState(3);
    const handleLoadMore = () => {
        setVisibleFiles(files.length);
    };

    const handleShowLess = () => {
        setVisibleFiles(VIEW_LESS_FILES);
    };

    const getPictureUriSetter = (index: number, url: DownloadFile) => {
        setPictureUri((prev) => {
            const prevCopy = [...prev];
            prevCopy[index] = url.url.value;
            return prevCopy;
        });
    };

    useEffect(() => {
        (async () => {
            if (files.length && isPicture) {
                const token = await getToken();
                if (!token) {
                    return;
                }
                files.forEach(async (picture, index) => {
                    if (pictureUri[index]) {
                        return;
                    }
                    if (isUploadFile(picture)) {
                        const downloadFile = await exchangeDocumentIdForAccessibleLink(apiBaseUrl, picture.documentId, {
                            accessToken: token,
                        } as JsonWebToken);
                        getPictureUriSetter(index, downloadFile);
                    } else if (isDownloadFile(picture)) {
                        const downloadFile = await exchangeUrlForAccessibleLink(picture.url, {
                            accessToken: token,
                        } as JsonWebToken);
                        getPictureUriSetter(index, downloadFile);
                    }
                });
            }
        })();
    }, []);

    const renderLabel = () =>
        files.map((file, index) => {
            const size = getFileSize(file.meta.size, t);
            return (
                <div
                    className="input-file__item__inner"
                    key={file.meta.fileName}
                    style={{ display: index < visibleFiles ? 'flex' : 'none' }}
                >
                    <div className="input-file__item__inner__file">
                        {!isPicture ? (
                            <Icon name={IconIdentifier.FILE} />
                        ) : (
                            <FileDownloadClickable file={file} apiBaseUrl={apiBaseUrl}>
                                <img src={pictureUri[index] ? pictureUri[index] : ''} alt="n/a" />
                            </FileDownloadClickable>
                        )}
                        <div className="input-file__item__inner__file__label">
                            <span className="input-file__item__inner__file__label__filename">{file.meta.fileName}</span>
                            <span className="input-file__item__inner__file__label__filesize">{size}</span>
                        </div>
                    </div>
                    <FileDownloadClickable file={file} apiBaseUrl={apiBaseUrl}>
                        <ButtonTertiaryOnLightM label={t('fileInput.viewButton')} />
                    </FileDownloadClickable>
                </div>
            );
        });

    return (
        <div className="file-multi-item-view">
            <div className="file-multi-item-view__inner">{renderLabel()}</div>
            <div className="button-show-action">
                {files.length > visibleFiles && (
                    <ButtonTertiaryOnLightM label={t('fileInput.showMoreLabel')} onClick={handleLoadMore} />
                )}
                {visibleFiles > VIEW_LESS_FILES && (
                    <ButtonTertiaryOnLightM label={t('fileInput.showLessLabel')} onClick={handleShowLess} />
                )}
            </div>
        </div>
    );
};

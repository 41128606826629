import { useTranslation } from 'react-i18next';
import { useMyPackages } from '@steelbuy/data-access';
import { ListingSellerTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    FillHeightContainer,
    LoadingSpinner,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { useMyPackagesContext } from './MyPackagesContext';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';
import { MyListingEmptyState } from '../my-listings/MyListingEmptyState';
import { MyListingsFilter } from '../my-listings/MyListingsFilter';

export const MyPackagesList = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { packageFilter } = useMyPackagesContext();
    const statusFilter = packageFilter.length ? { 'status.value': packageFilter.join('|') } : undefined;
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, total } = useMyPackages({
        filterCriteria: statusFilter,
    });

    const handleLoadMore = () => {
        fetchNextPage();
    };

    return (
        <div className="my-listings-list">
            {isLoading ? (
                <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                    <LoadingSpinner fillContainerHeight />
                </FillHeightContainer>
            ) : (
                <>
                    <DataHeader
                        headingValue={t('application.myPackages.numberOfPackageListings', {
                            count: total ?? 0,
                        })}
                    >
                        <MyListingsFilter isPackage />
                    </DataHeader>
                    <div className="my-listings-list__listings">
                        {data?.map((packageListing) => (
                            <Card
                                key={packageListing.id}
                                isClickable
                                url={createRouteUrl(RoutePath.MY_PACKAGES_DETAILS, ['packageId', packageListing.id])}
                            >
                                <ListingSellerTeaser listing={packageListing} isPackage />
                            </Card>
                        ))}
                    </div>
                    {hasNextPage && (
                        <div className="my-listings-list__load-more">
                            <ButtonTertiaryOnLightM
                                onClick={handleLoadMore}
                                label={t('uiDomain:commonList.loadMore')}
                                loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                            />
                        </div>
                    )}
                </>
            )}
            {data?.length === 0 && <MyListingEmptyState />}
        </div>
    );
};

import { useParams } from 'react-router-dom';
import { AddressCollectionDataProvider, AddressEntityDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { AccountEditAddress } from '../../components/account-edit-address/AccountEditAddress';
import { AccountEditAddressNotFound } from '../../components/account-edit-address/AccountEditAddressNotFound';

export const AccountEditAddressView = () => {
    const { addressId } = useParams();

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <AccountEditAddressNotFound />;
        }

        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <AddressCollectionDataProvider lazyLoad>
                <AddressEntityDataProvider entityId={addressId ?? ''} alwaysFetchOnMount>
                    <AccountEditAddress />
                </AddressEntityDataProvider>
            </AddressCollectionDataProvider>
        </ErrorHandler>
    );
};

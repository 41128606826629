import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { BadgeVariant } from './BadgeVariant.enums';

import './BadgeState.scss';

export type BadgeStateProps = {
    label: string;
    variant: BadgeVariant;
};

export const BadgeState = (props: BadgeStateProps) => {
    const { variant, label } = props;

    const badgeStateClassMap = {
        'badge-state': true,
        [classNameFromEnumValue(variant, 'badge-state')]: true,
    };

    return (
        <div className={buildClassStringFromClassMap(badgeStateClassMap)}>
            <span className="badge-state__label">{label}</span>
        </div>
    );
};

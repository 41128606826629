import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationSettings, UrgentAlerts } from '@steelbuy/domain-model';
import axios from '../../axios';
import { NOTIFICATIONS_SETTINGS_KEY } from '../../query/notificationSettings';

const mutateUrgentAlertSettings = async ({ enabled, frequency }: UrgentAlerts) => {
    const { data } = await axios.put<NotificationSettings>('/api/seller/notification/settings', {
        urgentAlertSettings: {
            enabled,
            frequency,
        },
    });
    return data;
};
export const useMutateUrgentAlertSettings = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: mutateUrgentAlertSettings,
        onSuccess: (response) => queryClient.setQueryData([NOTIFICATIONS_SETTINGS_KEY], response),
    });
};

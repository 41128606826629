import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import {
    ListingBuyerSearchResultModel,
    ListingBuyerSearchSortCriteria,
    PackageBuyerModel,
    SearchFormData,
} from '@steelbuy/domain-model';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { ButtonTertiaryOnLightM, DataHeader, LoadingStatus } from '@steelbuy/ui-primitive';

import { SortCriteriaOption } from './SearchResults.enums';

import './SearchResults.scss';
import { SearchResultsSorting } from './SearchResultsSorting';
import { SearchResultTable } from '../search-results-table/SearchResultTable';

interface SearchResultsProps<T = ListingBuyerSearchResultModel> {
    totalResults?: number;
    results: ReadonlyArray<T>;
    sortCriteria: SortCriteria<ListingBuyerSearchResultModel> | PreparedSortCriteria;
    handleSortChange: (sortCriteria: PreparedSortCriteria<ListingBuyerSearchSortCriteria>) => void;
    pathToListingDetails: (listingId: ModelPrimaryKey) => string;
    searchFormData?: SearchFormData;
    hasNext: boolean | undefined;
    handleLoadMore: () => void;
    newListings?: number;
    onClickListing?: () => void;
    isPackage?: boolean;
    isFetchingNextPage: boolean;
    handleCheckout?: (id: string) => void;
    onClickCartButton?: (id: string) => void;
    selectedListingId?: string;
}

export const SearchResults = <T extends PackageBuyerModel | ListingBuyerSearchResultModel>({
    handleLoadMore,
    handleSortChange,
    hasNext = false,
    newListings,
    pathToListingDetails,
    results,
    searchFormData,
    sortCriteria,
    totalResults,
    onClickListing,
    isPackage,
    isFetchingNextPage,
    handleCheckout,
    onClickCartButton,
    selectedListingId,
}: SearchResultsProps<T>) => {
    const { t } = useTranslation('uiDomain');
    const analytics = useAnalytics();
    const isSortedByRelevance =
        sortCriteria?.[0]?.property?.toLowerCase() === SortCriteriaOption.RELEVANCE.toLowerCase();

    useEffect(() => {
        if (results.length && 'listing' in results[0]) {
            (results as ReadonlyArray<ListingBuyerSearchResultModel>).forEach(({ listing: { id } }) => {
                analytics.sendEvent(TrackingEvent.SEARCH_LISTING_RESULT, {
                    listing_id: id,
                });
            });
        }
    }, [results]);

    const renderDataHeader = () => {
        const renderDataHeaderWithTotalResults =
            !isSortedByRelevance || (results[0] as ListingBuyerSearchResultModel)?.accuracy >= 1 || isPackage;

        const headingValue = (total: number) =>
            renderDataHeaderWithTotalResults
                ? t('searchResults.dataHeader.totalResultsLabel', { count: total })
                : t('searchResults.dataHeader.alternativeResultsLabel');

        return (
            typeof totalResults !== 'undefined' && (
                <DataHeader headingValue={headingValue(totalResults)} showFilter={!isPackage}>
                    {!isPackage && (
                        <SearchResultsSorting
                            handleSortChange={handleSortChange}
                            sortCriteria={sortCriteria}
                            isDisabled={totalResults === 0}
                        />
                    )}
                </DataHeader>
            )
        );
    };

    const renderLoadMoreButton = () =>
        hasNext ? (
            <div className="search-results__load-more">
                <ButtonTertiaryOnLightM
                    onClick={handleLoadMore}
                    disabled={!hasNext}
                    label={t('commonList.loadMore')}
                    loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                />
            </div>
        ) : null;

    return (
        <div className="search-results">
            {renderDataHeader()}
            {isPackage ? (
                <SearchResultTable
                    results={results as ReadonlyArray<PackageBuyerModel>}
                    numberOfNewResults={newListings ?? 0}
                    searchFormData={searchFormData}
                    pathToListingDetails={pathToListingDetails}
                    isSortedByRelevance={isSortedByRelevance}
                    onClickListing={onClickListing}
                    isPackage
                    handleCheckout={handleCheckout}
                    onClickCartButton={onClickCartButton}
                    selectedListingId={selectedListingId}
                />
            ) : (
                <SearchResultTable
                    results={results as ReadonlyArray<ListingBuyerSearchResultModel>}
                    numberOfNewResults={newListings ?? 0}
                    searchFormData={searchFormData}
                    pathToListingDetails={pathToListingDetails}
                    isSortedByRelevance={isSortedByRelevance}
                    onClickListing={onClickListing}
                    handleCheckout={handleCheckout}
                    onClickCartButton={onClickCartButton}
                    selectedListingId={selectedListingId}
                />
            )}

            {totalResults !== 0 ? renderLoadMoreButton() : null}
        </div>
    );
};

import { useCallback } from 'react';
import { Feature, UserModel } from '@steelbuy/domain-model';

import { UserSelfEntityData } from './UserSelfEntityData';
import { useEntityData } from '../../util/consumer/EntityConsumerHook';

/**
 * This does only expose the user data model. The underlying data provider context supports all entity accessor methods,
 * but they are not applicable for the 'self' context.
 */
export const useUserSelf = () => useEntityData<UserModel>(UserSelfEntityData).query();

export const useUserSelfEntityData = () => useEntityData<UserModel>(UserSelfEntityData);

export const useFeatureFlag = () => {
    const user = useUserSelf().get();
    const isFeatureSupported = useCallback((feature: Feature) => user?.features?.includes(feature) || false, [user]);
    const areFeaturesSupported = useCallback(
        (features: Feature[]) => features.every((feature) => user?.features?.includes(feature) || false),
        [user]
    );
    return { isFeatureSupported, areFeaturesSupported };
};

export const useIsFeatureSupported = (feature: Feature) => {
    const { isFeatureSupported } = useFeatureFlag();
    return isFeatureSupported(feature);
};

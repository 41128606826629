import { LoadingSpinnerSize } from './LoadingSpinner.enums';
import './LoadingSpinner.scss';

export type LoadingSpinnerProps = {
    symbolSize?: LoadingSpinnerSize;
    fullHeight?: boolean;
    fillContainerHeight?: boolean;
};

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
    const { symbolSize = LoadingSpinnerSize.MEDIUM, fullHeight = true, fillContainerHeight = false } = props;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    const getSize = () => {
        switch (symbolSize) {
            case LoadingSpinnerSize.SMALL:
                return { width: '24px', height: '24px' };
            case LoadingSpinnerSize.MEDIUM:
                return { width: '56px', height: '56px' };
            case LoadingSpinnerSize.LARGE:
                return { width: '112px', height: '112px' };
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    const getHeight = () => {
        if (fillContainerHeight) {
            return {
                height: '100%',
            };
        }
        if (fullHeight) {
            return { height: '100vh' };
        }
    };

    return (
        <div className="loading-spinner" style={getHeight()}>
            <div className="loading-spinner__animation">
                <span className="loading-spinner__animation__circle">
                    <svg style={getSize()} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.865 20.58A10 10 0 1 0 3.34 7a1 1 0 1 1-1.732-1 12 12 0 1 1-.855 10.183 1 1 0 1 1 1.874-.697 10 10 0 0 0 4.238 5.094Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

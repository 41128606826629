import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfDownloadFileFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfUploadFileFieldNames,
    DownloadFile,
    DownloadFileFieldNames,
    ModelPrimaryKey,
    Timestamp,
    TimestampFieldNames,
    UploadFile,
    UploadFileFieldNames,
} from '@steelbuy/domain-model-types';
import { ListingMaterialAge, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingSellerPricingModel, ListingSellerPricingModelConverter } from './ListingSellerPricingModel';
import { AnyMaterialDimensionModelConverter } from './MaterialDimensionModel';
import { AnyMaterialModel, AnyMaterialModelConverter } from './MaterialModel';
import { TradeUnit, TradeUnitCalculation } from './MaterialModel.enums';

export type PrimeListingModelFoundation =
    | {
          prime: true;
          description?: never;
          picture?: never;
          testCertificate: UploadFile | DownloadFile;
      }
    | {
          prime: false;
          description: string;
          picture?: UploadFile | DownloadFile;
          testCertificate?: never;
      };

export type ListingSellerDraftMaterialDimensions = {
    width?: number;
    length?: number;
    thickness?: number;
};

export type AutoRenew = {
    enabled: boolean;
    expiresAt?: Timestamp;
    price?: number;
};

export type ListingDraftMutableModel = Partial<{
    pickupAddressId: ModelPrimaryKey;
    invoiceAddressId: ModelPrimaryKey;
    additionalInformation: string;
    internalSellerSku: string;
    age: ListingMaterialAge;
    origin: ListingMaterialOrigin;
    expiresAt: Timestamp;
    pricing: Partial<ListingSellerPricingModel>;
    weight: number;
    material: Partial<AnyMaterialModel>;
    materialDimensions: ListingSellerDraftMaterialDimensions;
    numberOfItems: number;
    tradeUnit: TradeUnit;
    tradeUnitCalculation: TradeUnitCalculation;
    autoRenew: boolean;
}> &
    Partial<PrimeListingModelFoundation>;

export type ListingSellerDraftModel = ListingDraftMutableModel & {
    readonly id: ModelPrimaryKey;
    readonly modifiedAt: Timestamp;
};

export class ListingSellerDraftModelConverter extends ModelConverter<ListingSellerDraftModel> {
    override getUploadFileFields(): Array<
        UploadFileFieldNames<ListingSellerDraftModel> | ArrayOfUploadFileFieldNames<ListingSellerDraftModel>
    > {
        return ['picture', 'testCertificate'];
    }

    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<ListingSellerDraftModel> | ArrayOfDownloadFileFieldNames<ListingSellerDraftModel>
    > {
        return ['picture', 'testCertificate'];
    }

    override getTimestampFields(): Array<
        TimestampFieldNames<ListingSellerDraftModel> | ArrayOfTimestampFieldNames<ListingSellerDraftModel>
    > {
        return ['modifiedAt', 'expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<ListingSellerDraftModel> {
        return {
            material: new AnyMaterialModelConverter(),
            materialDimensions: new AnyMaterialDimensionModelConverter(),
            pricing: new ListingSellerPricingModelConverter(),
        };
    }
}

import { RFQBuyerDetailsModel, RFQDetailsHeading } from '@steelbuy/domain-model';
import { entries } from '@steelbuy/util';

export const mapRFQQuoteEntryForDetails = (rfqModel: RFQBuyerDetailsModel) =>
    rfqModel.quoteEntry.map((quoteEntry) => {
        const filteredHeadings = entries(quoteEntry.fieldHeadings)
            .filter((fieldEntries) => {
                const key = fieldEntries[0];
                return (
                    quoteEntry.fieldHeadings[key] !== undefined &&
                    quoteEntry.materials.some((entryMaterials) => entryMaterials[key] !== undefined)
                );
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {} as RFQDetailsHeading);
        return {
            ...quoteEntry,
            fieldHeadings: filteredHeadings,
        };
    });

export type ValidationProps = {
    validators?: Validator[];
    forceValidation?: boolean;
};

export interface Validator {
    validate: (value: InputValue, validatableElement?: ValidatableElement) => boolean;
    message: string;
}

export type ValidatableElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export type InputValue = string | ReadonlyArray<string> | number | undefined;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const validate = (
    validators: Validator[],
    value: InputValue,
    validatableElement?: ValidatableElement,
    customError?: string
    // eslint-disable-next-line consistent-return
) => {
    if (!customError) {
        validatableElement?.setCustomValidity('');
    }
    const validatorWithError = validators.find((validator) => !validator.validate(value, validatableElement));

    if (validatorWithError) {
        validatableElement?.setCustomValidity(validatorWithError.message);
        validatableElement?.reportValidity();
        return validatorWithError.message;
    }
};

import './IdentificationAttribute.scss';

export type IdentificationAttributeProps = {
    value: string | number;
    label: string;
};

export const IdentificationAttribute = (props: IdentificationAttributeProps) => {
    const { value, label } = props;

    return (
        <div className="identification-attribute">
            <span className="identification-attribute__label"> {label}</span>
            <span className="identification-attribute__value"> {value}</span>
        </div>
    );
};

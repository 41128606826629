import { MaterialProperties } from './material-taxonomy/MaterialProperties';
import {
    Finish,
    Coating,
    CoatingThicknessType,
    Product,
    Specification,
    Surface,
    MaterialType,
    Definition,
    ProductType,
    Temper,
    CoatingType,
    CoatingCoverage,
    Polish,
    MillFinish,
    CoatingThicknessUnit,
    Grade,
} from './MaterialModel.enums';
import {
    getCoatings,
    getFinishes,
    getSpecifications,
    getSurfaces,
    getCoatingThickness,
    getCoatingColours,
    getCoatingTypes,
    getCoatingCoverages,
    getMillFinishes,
    getPolishes,
    getCoatingThicknessUnit,
    getGrades,
} from './MaterialModel.maps';

const isMaterialMildSteel = (material?: MaterialProperties) => material?.materialType === MaterialType.MILD_STEEL;

export const isMaterialWithGrade = (material?: MaterialProperties): material is { grade?: Grade } => {
    if ((material ?? null) === null || material === undefined) {
        return false;
    }
    const grades = getGrades(material);
    return grades.length > 0;
};

export const isMaterialWithDefinition = (material?: MaterialProperties): material is { definition?: Definition } => {
    if ((material ?? null) === null || !isMaterialMildSteel(material)) {
        return false;
    }
    return material?.product === Product.COIL || material?.product === Product.SHEET;
};

export const isMaterialWithPlateType = (material?: MaterialProperties): material is { plateType?: ProductType } => {
    if ((material ?? null) === null) {
        return false;
    }
    return material?.plateType !== undefined;
};

export const isMaterialWithLength = (
    material?: MaterialProperties
): material is {
    product?: Product.PLATE | Product.SHEET | Product.TREAD;
} => {
    if ((material ?? null) === null) {
        return false;
    }
    return material?.product !== Product.COIL;
};

export const isMaterialWithFinish = (material?: MaterialProperties): material is { finish?: Finish } => {
    if ((material ?? null) === null || !isMaterialMildSteel(material)) {
        return false;
    }

    if (!material?.definition && !material?.plateType) {
        return false;
    }

    return getFinishes(material).length > 0;
};

export const isMaterialWithSpecification = (
    material?: MaterialProperties
): material is { specification?: Specification } => {
    if ((material ?? null) === null || !isMaterialMildSteel(material)) {
        return false;
    }

    if (!material?.definition && !material?.plateType) {
        return false;
    }

    const specification = getSpecifications(material);
    return specification.length > 0;
};

export const isMaterialWithSurface = (material?: MaterialProperties): material is { surface?: Surface } => {
    if ((material ?? null) === null || !isMaterialMildSteel(material)) {
        return false;
    }

    if (!material?.definition) {
        return false;
    }

    const surfaces = getSurfaces(material);

    return surfaces.length > 0;
};

export const isMaterialWithCoating = (material?: MaterialProperties): material is { coating?: Coating } => {
    if ((material ?? null) === null || material === undefined) {
        return false;
    }

    if (isMaterialMildSteel(material) && !material?.definition) {
        return false;
    }

    const coatings = getCoatings(material);
    return coatings.length > 0;
};

export const isMaterialWithCoatingThickness = (
    material?: MaterialProperties
): material is { coatingThicknessValue?: CoatingThicknessType } => {
    if ((material ?? null) === null || material === undefined) {
        return false;
    }

    if (!isMaterialWithCoating(material)) {
        return false;
    }

    const thicknesses = getCoatingThickness(material);
    return thicknesses.length > 0;
};

export const isMaterialWithTemper = (material?: MaterialProperties): material is { temper?: Temper } =>
    material ? material.materialType === MaterialType.ALUMINIUM : false;

export const isMaterialWithPrime = (
    material?: MaterialProperties
): material is { materialType: MaterialType.MILD_STEEL | MaterialType.ALUMINIUM | MaterialType.STAINLESS_STEEL } => {
    if ((material ?? null) === null) {
        return false;
    }

    return true;
};

export const isMaterialWithCoatingColour = (material?: MaterialProperties): material is { coatingColour?: string } => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const colours = getCoatingColours(material);
    return colours.length > 0;
};

export const isMaterialWithCoatingType = (material?: MaterialProperties): material is { coatingType?: CoatingType } => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const coatingTypes = getCoatingTypes(material);
    return coatingTypes.length > 0;
};

export const isMaterialWithCoatingCoverage = (
    material?: MaterialProperties
): material is { coatingCoverage?: CoatingCoverage } => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const coverages = getCoatingCoverages(material);
    return coverages.length > 0;
};

export const isMaterialWithMillFinish = (material?: MaterialProperties): material is { millFinish?: MillFinish } => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const millFinishes = getMillFinishes(material);
    return millFinishes.length > 0;
};

export const isMaterialWithPolish = (material?: MaterialProperties): material is { polish?: Polish } => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const polishes = getPolishes(material);
    return polishes.length > 0;
};

export const shouldDisplayCoatingThicknessUnit = (material?: MaterialProperties): boolean => {
    if (!material || (material ?? null) === null) {
        return false;
    }

    const coatingThicknessUnit = getCoatingThicknessUnit(material);
    return coatingThicknessUnit === CoatingThicknessUnit.MICRON;
};

export const isTwoSidedMaterial = (material?: MaterialProperties): boolean => material?.coating === Coating.TWO_SIDED;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@steelbuy/modal-dialog';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const DeleteRFQItemModal = ({
    show,
    onModalClose,
    onConfirm,
}: {
    show: boolean;
    onModalClose: () => void;
    onConfirm: () => void;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const deleteRFQItemModal = useModal(onModalClose);

    useEffect(() => {
        if (show) {
            deleteRFQItemModal.show();
        } else {
            deleteRFQItemModal.hide();
        }
    }, [show]);
    return (
        <ConfirmCancelModel
            modalAccessor={deleteRFQItemModal}
            header={t('translation:application.createRFQ.deleteRFQItemConfirmation')}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Reject.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={t('uiDomain:common.delete')}
            onSubmit={onConfirm}
            isDanger
            onClose={onModalClose}
            isSubmitting={false}
        />
    );
};

import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useRerenderFromNavbar = () => {
    const location = useLocation();
    const checkForPathChange = useRef(false);
    const [rerenderCount, setRerenderCount] = useState(0);

    useEffect(() => {
        if (checkForPathChange.current && location.state?.fromNavbar) {
            setRerenderCount((oldValue) => oldValue + 1);
        }
        checkForPathChange.current = true;
    }, [location.state]);

    return rerenderCount;
};

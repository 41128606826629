import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ApiModel, ModelPrimaryKey } from '@steelbuy/domain-model-types';
import {
    FinancialDetailsModel,
    FinancialDetailsWithBalanceModelConverter,
    FinancialDetailsWithoutBalanceModelConverter,
} from './FinancialDetailsModel';
import { isFinancialDetailsWithBalanceModel } from './FinancialDetailsModel.guards';

import { OrganisationMarketSector } from './OrganisationModel.enums';

export type OrganisationModel = {
    readonly id: ModelPrimaryKey;
    readonly name: string;
    readonly email: string;
    readonly marketSector: OrganisationMarketSector;
    purchaseContactName: string;
    purchaseContactEmail: string;
    financialDetails: FinancialDetailsModel;
};

export class OrganisationModelConverter extends ModelConverter<OrganisationModel> {
    override getFieldConverterMapByModel(
        model: ApiModel<OrganisationModel> | OrganisationModel
    ): FieldConverterMap<OrganisationModel> {
        if (isFinancialDetailsWithBalanceModel(model.financialDetails)) {
            return {
                financialDetails: new FinancialDetailsWithBalanceModelConverter(),
            };
        }

        return {
            financialDetails: new FinancialDetailsWithoutBalanceModelConverter(),
        };
    }
}

import { History, Location, Transition } from 'history';
import { useContext, useRef, useEffect, useLayoutEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ModalAccessor, useModal } from '@steelbuy/modal-dialog';

export const useBlocker = (
    when: boolean,
    allowNavigation: (newLocation: Location) => boolean = () => false
): [ModalAccessor, () => void] => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
    const unblockAndRetryRef = useRef<() => void>();
    const allowNavRef = useRef<(newLocation: Location) => boolean>(allowNavigation);

    const modal = useModal();

    useLayoutEffect(() => {
        allowNavRef.current = allowNavigation;
    });

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = navigator.block((transition: Transition) => {
            if (allowNavRef.current(transition.location)) {
                unblock();
                transition.retry();
            } else {
                modal.show();
                unblockAndRetryRef.current = () => {
                    unblock();
                    transition.retry();
                };
            }
        });

        // eslint-disable-next-line consistent-return
        return unblock;
    }, [navigator.location, when]);

    return [
        modal,
        () => {
            unblockAndRetryRef.current?.();
        },
    ];
};

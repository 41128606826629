import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialProperties, MaterialWithDimensions } from '@steelbuy/domain-model';
import { Literal } from '@steelbuy/types';
import {
    ButtonColor,
    ButtonDanger,
    ButtonGhostSystemOnLightS,
    ButtonSize,
    ButtonTertiary,
    Card,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { entries } from '@steelbuy/util';
import { RFQInputs, isUniqueEntry, removeNullFields, updateNonApplicableFields } from '../../createRFQUtil';
import { useRfqFormContext } from '../../RfqFormContext';

import '../../../rfqs/RFQCommonStyles.scss';
import './RFQTable.scss';

type RowProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
    material: MaterialProperties;
    headings: RFQInputs;
    data?: MaterialWithDimensions;
    isEdit: boolean;
    onDelete?: (index: number) => void;
    index?: number;
    errors?: FieldErrors<MaterialWithDimensions>;
    onEdit?: (index: number) => void;
    onAddSimilar?: () => void;
    onEditConfirm?: () => void;
    onEditCancel?: () => void;
    isAddSimilar?: boolean;
    notificationError?: string;
    isValid?: boolean;
};

export const Row = ({
    control,
    data,
    headings,
    index,
    onDelete,
    isEdit,
    material: rowMaterial,
    errors,
    onEdit,
    onAddSimilar,
    onEditConfirm,
    onEditCancel,
    isAddSimilar,
    notificationError,
    isValid,
}: RowProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);

    return (
        <>
            <tr className={classNames('rfq-row', { 'rfq-row--error': !!notificationError })}>
                {entries(headings).map(([key, { Component, literalPath, rules, valueMapper }]) => {
                    let value: string | number | undefined = '';

                    if (data && data[key]) {
                        if (literalPath) {
                            value = t(`domainModel:${String(literalPath)}.${data[key]}` as Literal);
                        } else if (valueMapper) {
                            value = valueMapper(data);
                        } else {
                            value = data[key];
                        }
                    }

                    return (
                        <td key={key}>
                            {isEdit || isAddSimilar ? (
                                <Component
                                    material={rowMaterial}
                                    control={control}
                                    name={key}
                                    rules={rules}
                                    error={errors?.[key]}
                                />
                            ) : (
                                <span>{value}</span>
                            )}
                        </td>
                    );
                })}
                {!isEdit && onDelete && index !== undefined && (
                    <td className="rfq-row__buttons-cell">
                        <div className="rfq-row__buttons-cell__container">
                            <ButtonGhostSystemOnLightS
                                label={t('uiDomain:common.edit')}
                                onClick={() => onEdit?.(index)}
                            />
                            <ButtonTertiary
                                label={t('translation:application.createRFQ.addSimilar')}
                                size={ButtonSize.S}
                                onClick={onAddSimilar}
                            />
                            <ButtonDanger
                                label={t('uiDomain:common.delete')}
                                size={ButtonSize.S}
                                onClick={() => onDelete(index)}
                            />
                        </div>
                    </td>
                )}
                {((isEdit && onDelete && index !== undefined) || isAddSimilar) && (
                    <td className="rfq-row__buttons-cell">
                        <div
                            className={classNames(
                                'rfq-row__buttons-cell__container',
                                'rfq-row__buttons-cell__container--edit'
                            )}
                        >
                            <ButtonTertiary
                                label={t('uiDomain:common.cancel')}
                                size={ButtonSize.S}
                                onClick={onEditCancel}
                            />
                            <ButtonTertiary
                                size={ButtonSize.S}
                                color={ButtonColor.DARK}
                                label={t('uiDomain:common.confirm')}
                                onClick={onEditConfirm}
                                disabled={!isValid}
                            />
                        </div>
                    </td>
                )}
            </tr>
            {notificationError && (
                <tr className="rfq-row rfq-row-notification">
                    <td colSpan={100} className="rfq-row-notification__cell">
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={notificationError}
                            stayOpen
                            disableScroll
                        />
                    </td>
                </tr>
            )}
        </>
    );
};

interface RFQTableProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: any[];
    material: MaterialWithDimensions;
    headings: RFQInputs;
    onDelete: (rowIndex: number) => void;
    groupIndex: number;
    append: (data: MaterialWithDimensions) => void;
    update: (rowIndex: number, formValues: MaterialWithDimensions) => void;
}

const addSimilarDefaultValues = { width: '', thickness: '', weight: '' };

export const RFQTable = ({ fields, headings, material, groupIndex, append, update, onDelete }: RFQTableProps) => {
    const {
        control,
        getValues,
        formState: { errors, isValid },
        watch,
        reset,
        clearErrors,
        setValue,
    } = useForm({
        mode: 'all',
    });

    const { t } = useTranslation('translation');

    const { editRowByGroup, editRow, cancelEditRow, addSimilarRow, addSimilarGroupIndex } = useRfqFormContext();
    const editRowForGroup = editRowByGroup?.[groupIndex];
    const [error, setError] = useState('');

    useEffect(() => {
        updateNonApplicableFields(headings, { ...material, ...watch() }, clearErrors, watch, setValue, errors);
    }, [watch()]);

    if (fields.length === 0) {
        return null;
    }

    return (
        <section className="rfq-table">
            <Card isClickable={false}>
                <table className="rfq-table__table">
                    <thead>
                        <tr className="rfq-table__label">
                            {Object.entries(headings).map(([key, { measurementLiteral }]) => {
                                let measurementText = '';
                                if (measurementLiteral) {
                                    const literalText = t(`domainModel:${measurementLiteral}` as Literal);
                                    measurementText = ` (${literalText})`;
                                }
                                return (
                                    <th key={key}>
                                        {t(`application.rfqHeadingLabels.${key}` as Literal)}
                                        {measurementText ? <span>{measurementText}</span> : null}
                                    </th>
                                );
                            })}
                            <th>
                                <div>
                                    <span className="rfq-table__table__actions">
                                        {t('application.createRFQ.actions')}
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {fields.map((field: any, index: number) => (
                            <Row
                                data={field}
                                headings={headings}
                                material={{ ...material, ...watch() }}
                                control={control}
                                isEdit={editRowForGroup === index}
                                key={field.id}
                                index={index}
                                onDelete={(removeIndex: number) => {
                                    onDelete(removeIndex);
                                    if (editRowForGroup > removeIndex) {
                                        editRow(groupIndex, editRowForGroup - 1);
                                    }
                                }}
                                onEdit={(rowIndex) => {
                                    setError('');
                                    reset(field);
                                    editRow(groupIndex, rowIndex);
                                }}
                                onAddSimilar={() => {
                                    setError('');
                                    const defaultValues = {
                                        ...field,
                                        ...addSimilarDefaultValues,
                                        ...(field.length !== undefined ? { length: '' } : {}),
                                    };
                                    reset(defaultValues);
                                    addSimilarRow(groupIndex);
                                }}
                                onEditConfirm={() => {
                                    const isUnique = isUniqueEntry(getValues(), fields, index);
                                    if (isUnique) {
                                        setError('');
                                        update(index, removeNullFields(getValues()));
                                        cancelEditRow();
                                    } else {
                                        setError(t('application.addRfq.itemExists'));
                                    }
                                }}
                                onEditCancel={() => {
                                    setError('');
                                    cancelEditRow();
                                }}
                                errors={errors}
                                notificationError={editRowForGroup === index ? error : ''}
                                isValid={isValid}
                            />
                        ))}
                        {addSimilarGroupIndex === groupIndex && (
                            <Row
                                headings={headings}
                                material={{ ...material, ...watch() }}
                                control={control}
                                key={`add-similar-${groupIndex}`}
                                isEdit={false}
                                isAddSimilar
                                onEditConfirm={() => {
                                    const isUnique = isUniqueEntry(getValues(), fields);
                                    if (isUnique) {
                                        setError('');
                                        append(removeNullFields(getValues()));
                                        cancelEditRow();
                                    } else {
                                        setError(t('application.addRfq.itemExists'));
                                    }
                                }}
                                onEditCancel={() => {
                                    setError('');
                                    cancelEditRow();
                                }}
                                errors={errors}
                                notificationError={error}
                                isValid={isValid}
                            />
                        )}
                    </tbody>
                </table>
            </Card>
        </section>
    );
};

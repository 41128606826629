import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type SubTabConfig<ST = void> =
    | {
          defaultSubTab: ST;
          tabs: ST[];
      }
    | undefined;

// nether Tabs or SubTabs should contain a dash (-) as this is used to separate Tabs from SubTabs in the url path
export const useTabbedPane = <T extends string, ST = void>(
    tabIds: T[],
    defaultTab: T,
    subTabs?: Record<T, SubTabConfig<ST>>
) => {
    const location = useLocation();
    const navigate = useNavigate();
    const hash = location.hash?.substring(1) || '';
    const currentTabId = hash.split('-')[0];
    const tabIdFromUrl =
        Object.values(tabIds).find((tabId) => (subTabs ? tabId === currentTabId : tabId === hash)) ?? defaultTab;
    let subTabIdFromUrl: ST | undefined;
    const subTabConfig = subTabs?.[tabIdFromUrl];
    if (subTabConfig) {
        const hashSplitByDash = hash.split('-');
        const hashSubTab = hashSplitByDash.pop();
        const { tabs, defaultSubTab } = subTabConfig;
        subTabIdFromUrl = (tabs && Object.values(tabs).find((tabId) => tabId === hashSubTab)) ?? defaultSubTab;
    }
    const [activeTabId, setActiveTabId] = useState<T>(tabIdFromUrl);
    const [activeSubTabId, setActiveSubTabId] = useState<ST | undefined>(subTabIdFromUrl);

    // handle changes from the TabBar
    useEffect(() => {
        if (tabIdFromUrl !== activeTabId || subTabIdFromUrl !== activeSubTabId) {
            const subTabPath = subTabIdFromUrl ? `-${activeSubTabId}` : '';
            navigate(`#${activeTabId}${subTabPath}`);
        }
    }, [activeTabId, activeSubTabId]);

    // handle changes in the URL caused by history navigation or manual change
    useEffect(() => {
        setActiveTabId(tabIdFromUrl);
        setActiveSubTabId(subTabIdFromUrl);
    }, [tabIdFromUrl, subTabIdFromUrl]);

    return { activeTabId, setActiveTabId, hash, activeSubTabId, setActiveSubTabId };
};

export enum DaynameFormat {
    LONG = 'long',
    SHORT = 'short',
    NARROW = 'narrow',
}
export const formatDayname = (
    locale?: string,
    date?: Date,
    defaultValue?: string,
    format: DaynameFormat = DaynameFormat.LONG
): string =>
    date?.toLocaleString(locale ?? undefined, {
        weekday: format,
    }) ??
    defaultValue ??
    '';

export const formatTime = (locale?: string, date?: Date, includeSeconds = false, defaultValue?: string): string =>
    date?.toLocaleTimeString(locale ?? undefined, {
        hour: '2-digit',
        minute: '2-digit',
        second: includeSeconds ? '2-digit' : undefined,
        hour12: false,
    }) ??
    defaultValue ??
    '';

export const formatDate = (locale?: string, date?: Date, defaultValue?: string): string =>
    date?.toLocaleDateString(locale ?? undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }) ??
    defaultValue ??
    '';
export const formatDateOnlyMonthAndYear = (locale?: string, date?: Date, defaultValue?: string): string => {
    const formattedDate = date?.toLocaleDateString(locale ?? undefined, {
        month: 'long',
        year: 'numeric',
    });

    if (formattedDate) {
        // Split the formatted date string to get month and year
        const [month, year] = formattedDate.split(' ');
        return `${month}, ${year}`;
    }

    return defaultValue ?? '';
};

export const formatDateTime = (
    locale?: string,
    date?: Date,
    includeSeconds = false,
    defaultLocale?: string,
    defaultValue?: string
): string =>
    date
        ? `${formatDate(locale, date, defaultLocale)} ${formatTime(locale, date, includeSeconds, defaultLocale)}`
        : defaultValue ?? '';

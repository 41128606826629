import * as amplitude from '@amplitude/analytics-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
/**
 * Using unstable HistoryRoute, as it allwows to use navigation blocking (see CreateListingUnsavedChangesModal).
 * Should be baked into react-router v6 eventually.
 * https://github.com/remix-run/react-router/issues/8139
 */
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { setDataProviderBaseUrl } from '@steelbuy/data-provider';
import { ErrorHandler } from '@steelbuy/error';

import { App } from './app/App';
import './app/i18n/config';

import { RenderErrorComponent } from './app/components/generic-error/RenderErrorComponent';
import { constants } from './app/constants';
import { setupStore } from './store/store';
import './styles.scss';

const store = setupStore();
if (process.env.NODE_ENV !== 'development') {
    amplitude.init(constants.amplitudeApiKey, undefined, {
        serverZone: amplitude.Types.ServerZone.EU,
        serverUrl: 'https://api.eu.amplitude.com/2/httpapi',
    });
}

setDataProviderBaseUrl(constants.apiBaseUrl);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <StrictMode>
        <ErrorHandler errorComponent={RenderErrorComponent}>
            <Provider store={store}>
                <HistoryRouter history={createBrowserHistory({ window })}>
                    <QueryClientProvider client={queryClient}>
                        <App />
                        {import.meta.env.NODE_ENV !== 'production' && (
                            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                        )}
                    </QueryClientProvider>
                </HistoryRouter>
            </Provider>
        </ErrorHandler>
    </StrictMode>
);

import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import './LoadingSpinnerOverlay.scss';

export const LoadingSpinnerOverlay = () => (
    <div className="loading_spinner_overlay">
        <div className="loading_spinner_overlay--color">
            <LoadingSpinner />
        </div>
    </div>
);

import { ReactNode } from 'react';

import { Separator } from '../../separator/Separator';
import { SeparatorIdentifier } from '../../separator/Separator.enums';

import './ModalContentActionBar.scss';

export type ModalContentActionBarProps = {
    children: ReactNode;
};

export const ModalContentActionBar = (props: ModalContentActionBarProps) => {
    const { children } = props;

    return (
        <article className="modal-content-action-bar">
            <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
            <article className="modal-content-action-bar__actions">{children}</article>
        </article>
    );
};

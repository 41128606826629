import { Grade, Finish, Surface } from '../../../MaterialModel.enums';

export const mildSteelColdRolledGrade = [
    Grade.DC01,
    Grade.DC02,
    Grade.DC03,
    Grade.DC04,
    Grade.DC05,
    Grade.DC06,
    Grade.DC07,
    Grade.DC01EK,
    Grade.DC04EK,
    Grade.DC06EK,
    Grade.DC03ED,
    Grade.DC04ED,
    Grade.HX260LAD,
    Grade.HX300LAD,
    Grade.HX340LAD,
    Grade.HX380LAD,
    Grade.HX420LAD,
    Grade.HX180YD,
    Grade.HX220YD,
    Grade.HX260YD,
    Grade.HX180BD,
    Grade.HX220BD,
    Grade.HX260BD,
    Grade.HX300BD,
    Grade.HCT490X,
    Grade.HCT590X,
    Grade.HCT980X,
] as const;

export type MildSteelColdRolledGrade = typeof mildSteelColdRolledGrade[number];

export const mildSteelColdRolledGrades = [Grade.DC01];

export const mildSteelColdRolledFinish = [Finish.DRY, Finish.OILED] as const;

export type MildSteelColdRolledFinish = typeof mildSteelColdRolledFinish[number];

export const mildSteelColdRolledSurface = [Surface.A_M, Surface.B_M] as const;

export type MildSteelColdRolledSurface = typeof mildSteelColdRolledSurface[number];

export type MildSteelColdRolled = {
    grade: MildSteelColdRolledGrade;
    surface: MildSteelColdRolledSurface;
    finish: MildSteelColdRolledFinish;
};

export enum TrackingEvent {
    PAGE_VIEW = 'Page View',
    LOGGED_IN = 'Logged In',
    LOGGED_OUT = 'Logged Out',
    SEARCH_RESULT_CLICKED = 'Search Result Clicked',
    SEARCH_LISTING_RESULT = 'Search Listing Result',
    LISTING_AUTORENEW_TOGGLED = 'Listing Autorenew Toggle',
    LISTING_EXPIRY_UPDATE = 'Listing Expiry Update',
    LISTING_PRICE_UPDATE = 'Listing Price Update',
    CREATE_ALERT = 'Create Alert',
    CREATE_IMR_LISTING = 'Create IMR Listing',
    SUBMIT_IMR_LISTING = 'Submit IMR Listing',
}

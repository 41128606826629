export const CHOOSE_ADDRESS_RELATIVE_PATH = 'choose-address';
export const ADD_ADDRESS_RELATIVE_PATH = 'add-address';

export enum RoutePath {
    // Keep alphabetical order
    ROOT = '/',

    ACCEPTABLE_USE_POLICY = '/acceptable-use',
    ACCOUNT = '/account',
    ACCOUNT_ADD_ADDRESS = '/account/address',
    ACCOUNT_ADD_NEW_USER = '/account/edit-user',
    ACCOUNT_EDIT_ADDRESS = '/account/address/:addressId',
    ACCOUNT_EDIT_PAYMENT_TERMS = '/account/payment-terms',
    ACCOUNT_EDIT_PURCHASE_CONTACT = '/account/purchase-contact',
    ACCOUNT_EDIT_USER = '/account/edit-user/:userId',
    ACCOUNT_EDIT_EMAIL = '/account/edit-email',
    ACCOUNT_EDIT_MFA = '/account/edit-mfa',
    ACCOUNT_EMAIL_VALIDATION = '/account/email-validation/:token',
    ACCOUNT_CHANGE_PASSWORD = '/account/change-password',

    COOKIE_POLICY = '/privacy-policy/#cookies-and-further-technologies',
    CREATE_LISTING_ADD_PICKUP_ADDRESS = '/listings/create/pickup-address/add',
    CREATE_LISTING_CHOOSE_PICKUP_ADDRESS = '/listings/create/pickup-address',
    CREATE_LISTING_WIZARD = '/listings/create',
    CREATE_LISTING_SUMMARY = '/listings/create/summary',
    CREATE_LISTING_WIZARD_WITH_DRAFT = '/listings/create/:draftId/:listingType',
    CREATE_LISTING_WIZARD_OLD_URL = '/listings/create/wizard',

    CREATE_RFQ = '/rfqs/create',
    CREATE_RFQ_WIZARD = '/rfqs/create/wizard',
    CREATE_RFQ_STEP2 = '/rfqs/create/wizard/step2',
    CREATE_RFQ_STEP3 = '/rfqs/create/wizard/step3',
    CREATE_DRAFT_RFQ_WIZARD = '/rfqs/create/wizard/:draftId',
    CREATE_DRAFT_RFQ_STEP2 = '/rfqs/create/wizard/:draftId/step2',
    CREATE_DRAFT_RFQ_STEP3 = '/rfqs/create/wizard/:draftId/step3',
    CREATE_RFQ_SUMMARY = '/rfqs/create/summary',
    CREATE_RFQ_SUCCESS = '/rfqs/create/success',
    MY_RFQS_DETAILS_SUCCESS = '/rfqs/my/details/success',

    CREATE_FOO = '/foo/',
    CONTACT_DETAILS = '/contact-details',
    DASHBOARD = '/dashboard',
    FOOS = '/foos/',
    FOO = '/foo/:fooId',
    LISTING_MANAGEMENT = '/listings',
    RFQ_MANAGEMENT = '/rfqs',

    LOGIN = '/login',
    CHALLENGE_RESPOND = '/login/challenge-respond',
    FORGOT_PASSWORD = '/login/forgot-password',
    RESET_PASSWORD = '/login/reset-password',

    MANAGE_OFFERS = '/manage-offers',
    MANAGE_OFFERS_DETAILS = '/manage-offers/details/:listingId',

    MY_ALERTS = '/my-alerts',
    MY_ALERTS_CHECKOUT = '/my-alerts/:alertId/checkout',
    MY_ALERTS_CHECKOUT_ADD_ADDRESS = '/my-alerts/:alertId/checkout/add-address',
    MY_ALERTS_CHECKOUT_CHOOSE_ADDRESS = '/my-alerts/:alertId/checkout/choose-address',
    MY_ALERTS_OFFER_SUCCESS = '/my-alerts/:alertId/offer-success',
    MY_ALERTS_LISTING_DETAILS = '/my-alerts/:alertId/listing-details/:listingId',
    MY_ALERTS_SEARCH = '/my-alerts/:alertId/search',
    MY_ALERT_SETTINGS = '/my-alerts/:alertId/settings',
    IMMEDIATE_REQUEST = '/immediate-request',

    MY_LISTINGS = '/listings/my',
    MY_LISTINGS_DRAFTS = '/listings/my#drafts',
    MY_LISTINGS_DETAILS = '/listings/my/details/:listingId',

    MY_PACKAGES = '/listings/my#listings-packages',
    MY_PACKAGES_DRAFTS = '/listings/my#drafts-packages',
    MY_PACKAGES_DETAILS = '/listings/my/packages/details/:packageId',

    MY_OFFERS = '/my-offers',
    MY_OFFERS_DETAILS = '/my-offers/details/:listingId',
    MY_OFFERS_CHECKOUT = '/my-offers/details/checkout',
    MY_OFFERS_OFFER_SUCCESS = '/my-offers/offer-success',
    MY_OFFERS_CHECKOUT_ADD_ADDRESS = '/my-offers/checkout/add-address',
    MY_OFFERS_CHECKOUT_CHOOSE_ADDRESS = '/my-offers/checkout/choose-address',

    MY_ORDERS = '/orders',
    MY_ORDERS_DETAILS = '/orders/:orderId',
    MY_CART = '/cart',
    MY_CART_LISTING_DETAILS = '/cart/details/:listingId',
    MY_CART_PACKAGE_DETAILS = '/cart/packages/details/:packageId',
    MY_CART_LISTINGS_CHECKOUT = '/cart/listings/checkout',
    MY_CART_PACKAGE_CHECKOUT = '/cart/packages/checkout',
    MY_CART_CHECKOUT = '/cart/checkout',
    MY_CART_CHECKOUT_CHOOSE_ADDRESS = '/cart/checkout/choose-address',
    MY_CART_OFFER_SUCCESS = '/cart/offer-success',

    MY_RFQS = '/rfqs/my',
    MY_RFQS_DRAFTS = '/rfqs/my#drafts',
    MY_RFQS_DETAILS = '/rfqs/my/details/:rfqId',

    PRIVACY_POLICY = '/privacy-policy',

    SEARCH_LISTINGS = '/search',
    SEARCH_LISTINGS_CHECKOUT = '/search/checkout',
    SEARCH_LISTINGS_CHECKOUT_ADD_ADDRESS = '/search/checkout/add-address',
    SEARCH_LISTINGS_CHECKOUT_CHOOSE_ADDRESS = '/search/checkout/choose-address',
    SEARCH_LISTING_DETAILS = '/search/details/:listingId',
    SEARCH_LISTINGS_RESULTS = '/search/results',
    SEARCH_LISTINGS_OFFER_SUCCESS = '/search/offer-success',

    PACKAGE_SEARCH_RESULTS = '/search/packages/results',
    PACKAGE_SEARCH_CHECKOUT = '/search/packages/checkout',
    PACKAGE_SEARCH_DETAILS = '/search/packages/details/:packageId',
    PACKAGE_SEARCH_CHECKOUT_CHOOSE_ADDRESS = '/search/packages/checkout/choose-address',

    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    TERMS_BUYER = '/terms-and-conditions/#buyer',
    TERMS_SELLER = '/terms-and-conditions/#seller',
    TERMS_WEBSITE = '/terms-and-conditions/#website',

    URGENT_REQUESTS = '/immediate-requests',
    URGENT_REQUESTS_SETTING = '/immediate-requests/settings',

    UNDEFINED = '/undefined', // placeholder for navigation items to auth that aren't available yet
}

export type RouteParameter = [string, string];

export const createRouteUrl = (routePath: RoutePath, ...parameters: Array<RouteParameter>): string => {
    let url = String(routePath);
    parameters.forEach((parameter) => {
        url = url.replace(`:${parameter[0]}`, parameter[1]);
    });
    return url;
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { domainModelLiteralsEn } from '@steelbuy/domain-model';
import { uiDomainLiteralsEn } from '@steelbuy/ui-domain';
import applicationLiteral from '../../l10n/literals-en';

export const defaultNS = 'translation';

export const resources = {
    en: {
        translation: applicationLiteral,
        domainModel: domainModelLiteralsEn,
        uiDomain: uiDomainLiteralsEn,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    defaultNS,
    debug: true,
});

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCreateRFQDraft, useUpdateRFQDraft } from '@steelbuy/data-access';
import { RFQDraftModel } from '@steelbuy/domain-model';
import {
    Notification,
    NotificationLevel,
    Wizard,
    WizardBar,
    WizardBarItemStatus,
    useWizardStepsContext,
} from '@steelbuy/ui-primitive';
import { RFQ_STEPS, getCreateRFQDraftModel, getStepRoute, hasFormData, rfqValidationLookup } from './createRFQUtil';
import { useRfqFormContext } from './RfqFormContext';
import { RFQType } from './Schema';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { CreateRFQUnsavedChangesModal } from '../create-rfq-unsaved-changes/CreateRFQUnsavedChangesModal';
import { PageHeader } from '../page-header/PageHeader';
import './CreateRFQ.scss';

const validateSteps = (
    updateStepStatus: (step: string, status: WizardBarItemStatus) => void,
    activeStepIndex: number,
    formData?: Partial<RFQType>
) => {
    RFQ_STEPS.forEach((step, index) => {
        if (activeStepIndex !== index) {
            updateStepStatus(
                step,
                rfqValidationLookup[step](formData) ? WizardBarItemStatus.SUCCESS : WizardBarItemStatus.INCOMPLETE
            );
        }
    });
};
export const CreateRFQ = () => {
    const { t } = useTranslation('translation');
    const { pathname, state } = useLocation();
    const { draftId: draftIdParams } = useParams();
    const [createDraftError, setCreateDraftError] = useState('');
    const [createDraftSuccess, setCreateDraftSuccess] = useState(state?.successNotification || '');

    const navigate = useNavigate();

    const { formData, getUpdatedFormValues, draftId, setDraft, goBack, allowNavigation, onSaveDraft } =
        useRfqFormContext();

    const { setActiveStep, stepStatus, reset, updateStepStatus } = useWizardStepsContext();
    let currentStep = 1;
    if (pathname.indexOf('/step') !== -1) {
        currentStep = parseInt(pathname.split('/step')[1], 10);
    }
    if (Number.isNaN(currentStep)) {
        currentStep = 1;
    }
    const createRFQDraftMutation = useCreateRFQDraft();
    const updateRFQDraftMutation = useUpdateRFQDraft();

    const currentStepIndex = currentStep - 1;

    useEffect(() => {
        setActiveStep(RFQ_STEPS[currentStep - 1]);
    }, [currentStep]);

    useEffect(() => {
        if (!hasFormData(formData) && currentStepIndex !== 0) {
            reset();
            navigate(RoutePath.CREATE_RFQ_WIZARD);
        }
    }, []);

    useEffect(() => {
        if (draftId) {
            validateSteps(updateStepStatus, currentStepIndex, formData);
        }
    }, [draftId]);

    const handleCreateRFQDraft = () => {
        const rfqData = getUpdatedFormValues();
        const payload = getCreateRFQDraftModel(rfqData);
        const mutateOptions = {
            onSuccess: ({ id }: RFQDraftModel) => {
                setDraft(rfqData, id);
                validateSteps(updateStepStatus, currentStepIndex, rfqData);
                onSaveDraft();
                if (!draftIdParams) {
                    navigate(getStepRoute(currentStep, id), {
                        state: {
                            draftRFQ: {
                                ...payload,
                                id,
                            },
                            successNotification: t('application.createDraftRFQ.createDraftRFQSuccess'),
                        },
                    });
                } else {
                    setCreateDraftSuccess(t('application.createDraftRFQ.createDraftRFQSuccess'));
                }
            },
            onError: () => setCreateDraftError(t('application.createDraftRFQ.createDraftRFQFailed')),
        };

        if (!payload) {
            setCreateDraftError(t('application.createDraftRFQ.createDraftRFQFailed'));
            return;
        }
        setCreateDraftError('');
        setCreateDraftSuccess('');
        if (draftId) {
            updateRFQDraftMutation.mutate({ ...payload, id: draftId }, mutateOptions);
        } else {
            createRFQDraftMutation.mutate(payload, mutateOptions);
        }
    };

    const header = draftId ? t('application.createRFQ.headerEditDraft') : t('application.createRFQ.headerCreateNew');

    const pageHeaderProps = useMemo(
        () =>
            goBack?.link && goBack?.label
                ? {
                      onBackClick: () => {
                          navigate(goBack.link);
                      },
                      previousPageTitle: goBack.label,
                  }
                : {},
        [goBack?.link, goBack?.label]
    );

    return (
        <>
            <PageHeader pageTitle={header} {...pageHeaderProps} />

            <div className="create-rfq">
                <Wizard>
                    <TableLayout>
                        {createDraftError && (
                            <Notification
                                level={NotificationLevel.ERROR}
                                message={createDraftError}
                                onClose={() => setCreateDraftError('')}
                                className="create-rfq__save-draft-notification"
                                closeButton
                                stayOpen
                            />
                        )}
                        {createDraftSuccess && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={createDraftSuccess}
                                onClose={() => setCreateDraftError('')}
                                className="create-rfq__save-draft-notification"
                                closeButton
                            />
                        )}
                        {state?.rejectedRFQId && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={t('application.myRFQDetails.restartRFQSuccess')}
                                onClose={() => setCreateDraftError('')}
                                className="create-rfq__save-draft-notification"
                                closeButton
                            />
                        )}
                        <WizardBar
                            currentStepIndex={currentStepIndex}
                            items={RFQ_STEPS.map((step, index) => ({
                                label: t(`application.createRFQSteps.labels.${step}`),
                                status: stepStatus[step],
                                selected: index === currentStepIndex,
                                onClick: allowNavigation
                                    ? () => {
                                          if (index !== currentStepIndex) {
                                              getUpdatedFormValues(true);
                                              navigate(getStepRoute(index + 1, draftId));
                                          }
                                      }
                                    : undefined,
                            }))}
                        />

                        <Outlet
                            context={{
                                onSaveDraft: handleCreateRFQDraft,
                                loadingState: createRFQDraftMutation.isLoading || updateRFQDraftMutation.isLoading,
                            }}
                        />
                    </TableLayout>
                </Wizard>
                <CreateRFQUnsavedChangesModal
                    onError={() => setCreateDraftError(t('application.createDraftRFQ.createDraftRFQFailed'))}
                />
            </div>
        </>
    );
};

import { useTranslation } from 'react-i18next';
import { OrganisationEntityDataProvider, useUserSelf } from '@steelbuy/data-provider';
import { CustomError } from '@steelbuy/error';
import { AccountEditPaymentTerms } from '../../components/account-edit-payment-terms/AccountEditPaymentTerms';

export const AccountEditPaymentTermsView = () => {
    const { t } = useTranslation('translation');
    const userModel = useUserSelf().getOrThrow(new CustomError(t('error.noUserError')));

    return (
        <OrganisationEntityDataProvider entityId={userModel.organisation.id}>
            <AccountEditPaymentTerms />
        </OrganisationEntityDataProvider>
    );
};

import { Product } from './MaterialModel.enums';

export const stackableProducts: Array<Product.SHEET | Product.PLATE | Product.TREAD> = [
    Product.SHEET,
    Product.PLATE,
    Product.TREAD,
];

export const isStackableProduct = (product?: unknown): product is typeof stackableProducts[number] => {
    if (!product) {
        return false;
    }

    return stackableProducts.includes(product as typeof stackableProducts[number]);
};

import { useEffect, useState, useRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';
import { NotificationLevel } from './Notification.enums';
import { ButtonGhostSystemOnLightM } from '../button-ghost/button-ghost-system-on-light-m/ButtonGhostSystemOnLightM';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Notification.scss';

export type ToLink = {
    label: string;
    to: string;
};

export type OnClickLink = {
    label: string;
    onClick: () => void;
};

const isToLink = (link: ToLink | OnClickLink): link is ToLink => (link as ToLink)?.to !== undefined;

const DEFAULT_DISMISS_TIME = 4000;

export type NotificationProps = {
    level: NotificationLevel;
    message: string;
    link?: ToLink | OnClickLink;
    labelActionButton?: string;
    stayOpen?: boolean;
    onClickActionButton?: () => void;
    closeButton?: boolean;
    className?: string;
    onClose?: () => void;
    disableScroll?: boolean;
    dismissTimer?: number;
    icon?: IconIdentifier;
    Component?: ReactNode;
};

export const Notification = (props: NotificationProps) => {
    const {
        level,
        message,
        link,
        labelActionButton,
        stayOpen,
        onClickActionButton,
        closeButton,
        className,
        disableScroll,
        onClose,
        dismissTimer = DEFAULT_DISMISS_TIME,
        icon,
        Component,
    } = props;

    const [dismissed, setDismissed] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const handleDismiss = (): void => {
        setDismissed(true);
        onClose?.();
    };

    const scrollIntoView = () => {
        !disableScroll && ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    useEffect(() => {
        if (level === NotificationLevel.SUCCESS && !stayOpen) {
            scrollIntoView();
            const timer = setTimeout(() => handleDismiss(), dismissTimer);
            return () => clearTimeout(timer);
        }
        if (level === NotificationLevel.ERROR || disableScroll === false) {
            scrollIntoView();
        }
        return undefined;
    }, []);

    if (dismissed) {
        return null;
    }

    const notificationClassMap = {
        notification: true,
        [classNameFromEnumValue(level, 'notification')]: true,
    };

    if (className) {
        notificationClassMap[className] = true;
    }

    const renderIcon = () => {
        if (icon) {
            return <Icon name={icon} />;
        }
        switch (level) {
            case NotificationLevel.INFO:
                return <Icon name={IconIdentifier.INFO} />;

            case NotificationLevel.SUCCESS:
                return <Icon name={IconIdentifier.WORKFLOW_SUCCESS} />;

            case NotificationLevel.WARNING:
                return <Icon name={IconIdentifier.ERROR} />;

            case NotificationLevel.ERROR:
                return <Icon name={IconIdentifier.CANCEL} />;

            default:
                return <Icon name={IconIdentifier.INFO} />;
        }
    };

    const renderLink = () => {
        if (link === undefined) {
            return null;
        }
        return (
            <span className="notification__main-content__link">
                {isToLink(link) ? (
                    <Link to={link.to}>{link.label}</Link>
                ) : (
                    <Link to="" onClick={link.onClick}>
                        {link.label}
                    </Link>
                )}
            </span>
        );
    };

    const renderActionButton = () => {
        if (labelActionButton === undefined) {
            return null;
        }
        return <ButtonGhostSystemOnLightM label={labelActionButton} onClick={onClickActionButton} />;
    };

    const renderClose = () => {
        if (closeButton === undefined) {
            return null;
        }
        return <ButtonGhostSystemOnLightM icon={IconIdentifier.CLOSE} onClick={handleDismiss} />;
    };

    return (
        <article className={buildClassStringFromClassMap(notificationClassMap)} ref={ref}>
            {renderIcon()}
            <span className="notification__main-content">
                {Component !== undefined ? (
                    Component
                ) : (
                    <span className="notification__main-content__message">{message}</span>
                )}
                {renderLink()}
            </span>
            {(labelActionButton || closeButton) && (
                <span className="notification__right-side-content">
                    {renderActionButton()}
                    {renderClose()}
                </span>
            )}
        </article>
    );
};

import { FormEvent, ReactNode } from 'react';
import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { InputValue } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputRadioButtonGroup.scss';

export enum InputRadioButtonGroupAlign {
    ALIGN_VERTICAL = 'ALIGN_VERTICAL',
    ALIGN_HORIZONTAL = 'ALIGN_HORIZONTAL',
}

type InputRadioButtonGroupBaseProps = {
    align?: InputRadioButtonGroupAlign;
    label?: string;
    helperText?: string;
    error?: string;
    children: ReactNode;
    value?: string;
};

type InputRadioButtonGroupWithRequiredValidationProps = InputRadioButtonGroupBaseProps & {
    required: true;
    requiredMessage: string;
    forceValidation?: boolean;
};

type InputRadioButtonGroupWithoutValidationProps = InputRadioButtonGroupBaseProps & {
    required?: never;
    requiredMessage?: never;
    forceValidation?: never;
};

export type InputRadioButtonGroupProps =
    | InputRadioButtonGroupWithoutValidationProps
    | InputRadioButtonGroupWithRequiredValidationProps;

export const InputRadioButtonGroup = (props: InputRadioButtonGroupProps) => {
    const {
        align = null,
        label,
        helperText,
        error,
        children,
        required = false,
        requiredMessage,
        forceValidation,
        value,
    } = props;

    const validators =
        required && requiredMessage
            ? [
                  {
                      validate: (val: InputValue) => !!val,
                      message: requiredMessage,
                  },
              ]
            : undefined;

    const { validationError, setIsTouched } = useValidation<HTMLInputElement>(value, validators, forceValidation);

    const combinedError = validationError ?? error;

    const alignClass = align ? ` input-radio-button-group__items--${classNameFromEnumValue(String(align))}` : '';

    const helperTextClassMap = {
        'input-radio-button-group__helper-text': true,
        'input-radio-button-group__helper-text--error': combinedError,
    };

    const renderErrorIcon = () => {
        if (combinedError === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const renderHelperText = () => {
        const text = combinedError ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const renderWrapper = () => {
        const errorIcon = renderErrorIcon();

        if (!errorIcon && !label) {
            return null;
        }

        return (
            <span className="input-radio-button-group__wrapper">
                {label && <span className="input-radio-button-group__wrapper__label">{label}</span>}
                {errorIcon}
            </span>
        );
    };

    const handleInvalid = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <article className="input-radio-button-group">
            {renderWrapper()}
            <article
                className={`input-radio-button-group__items${alignClass}`}
                onInvalid={handleInvalid}
                onBlur={() => setIsTouched(true)}
            >
                {children}
            </article>
            {renderHelperText()}
        </article>
    );
};

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import './Card.scss';

type ClickableCardProps = {
    children: ReactNode;
    isClickable: true;
    url?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    linkState?: any;
    onClick?: () => void;
};

type NonClickableCardProps = {
    children: ReactNode;
    isClickable: boolean;
};

export type CardProps = ClickableCardProps | NonClickableCardProps;

export function Card(props: CardProps) {
    const { children, isClickable = false } = props;
    const url = 'url' in props ? props.url : undefined;
    const onClick = 'onClick' in props ? props.onClick : undefined;
    const linkState = 'linkState' in props ? props.linkState : undefined;

    const cardClassMap = {
        card: true,
        'card--clickable': isClickable,
    };

    const renderCard = () => {
        if (isClickable && url) {
            return (
                <Link className="card-link-wrap" to={url} onClick={onClick} state={linkState}>
                    <section className={buildClassStringFromClassMap(cardClassMap)}>{children}</section>
                </Link>
            );
        }
        return (
            <section className="card" onClick={onClick} aria-hidden="true">
                {children}
            </section>
        );
    };
    return renderCard();
}

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ListingBuyerEntityDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';

import { ListingDetails } from '../../../components/listing-details/ListingDetails';
import { ListingNotFound } from '../../../components/listing-details/ListingNotFound';
import { createRouteUrl, RoutePath } from '../../../router/Routes';

export const MyAlertsListingDetailView = () => {
    const { listingId = null, alertId = '' } = useParams();

    const { t } = useTranslation('translation');

    if (listingId === null) {
        throw new NotFoundError(t('application.listingNotFound.notFoundMessage'));
    }

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <ListingNotFound />;
        }
        return null;
    };

    const pathToAlertCheckout = createRouteUrl(RoutePath.MY_ALERTS_CHECKOUT, ['alertId', alertId]);
    const pathToAlertSearchResults = createRouteUrl(RoutePath.MY_ALERTS_SEARCH, ['alertId', alertId]);
    const pathToOfferSuccess = createRouteUrl(RoutePath.MY_ALERTS_OFFER_SUCCESS, ['alertId', alertId]);

    return (
        <ErrorHandler errorComponent={handleError}>
            <ListingBuyerEntityDataProvider entityId={listingId} alwaysFetchOnMount>
                <ListingDetails
                    checkoutPath={pathToAlertCheckout}
                    goBackPath={pathToAlertSearchResults}
                    offerSuccessPath={pathToOfferSuccess}
                />
            </ListingBuyerEntityDataProvider>
        </ErrorHandler>
    );
};

import { FooModel, FooModelConverter } from '@steelbuy/domain-model';
import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class FooCrudApiClient extends AbstractCrudApiClient<FooModel> {
    protected collectionControllerUri = 'foos/';

    protected paginationControllerUri = 'foos/';

    protected entityCreateUri = 'foo/{id}/';

    protected entityFetchUri = 'foo/{id}/';

    protected entityMutateUri = 'foo/{id}/';

    protected entityDeleteUri = 'foo/{id}/';

    protected entityServiceCallUri = 'foo/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new FooModelConverter();
}

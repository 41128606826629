export class Timeout {
    public static async wrap<T>(promise: Promise<T>, timeoutMilliseconds: number, error: Error): Promise<T> {
        return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject(error);
            }, timeoutMilliseconds);
            promise.then(
                (res) => {
                    clearTimeout(timeoutId);
                    resolve(res);
                },
                (err) => {
                    clearTimeout(timeoutId);
                    reject(err);
                }
            );
        });
    }
}

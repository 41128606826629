import classNames from 'classnames';
import { ReactNode } from 'react';

export const ListingTeaser = ({ children, threeColumns }: { children: ReactNode; threeColumns?: boolean }) => (
    <div
        className={classNames('listing-teaser', {
            'listing-teaser--three-columns': threeColumns,
        })}
    >
        {children}
    </div>
);

import { FieldConverterMap, ModelConverter, ModelConverterInterface } from '@steelbuy/domain-model-converter';
import {
    ArrayOfDateRangeFieldNames,
    ArrayOfDownloadFileFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfUploadFileFieldNames,
    ArrayOfUrlFieldNames,
    DateRangeFieldNames,
    DownloadFileFieldNames,
    TimestampFieldNames,
    UploadFileFieldNames,
    UrlFieldNames,
} from '@steelbuy/domain-model-types';

import { ListingBaseSellerModel } from './ListingBaseSellerModel';
import { ListingModelFoundation, ListingModelFoundationConverter } from './ListingModelFoundation';
import { ListingSellerAddressModelConverter } from './ListingSellerAddressModel';
import { ListingSellerPricingModelConverter } from './ListingSellerPricingModel';
import { ListingSellerStatusModelConverter } from './ListingSellerStatusModel';
import { NegotiationModel, NegotiationModelConverter } from './NegotiationModel';
import { UserModel, UserModelConverter } from './UserModel';

export type ListingSellerModel = ListingBaseSellerModel &
    ListingModelFoundation & {
        negotiations?: NegotiationModel[];
    };

export class ListingSellerModelConverter extends ModelConverter<ListingSellerModel> {
    override getDateRangeFields(): Array<
        DateRangeFieldNames<ListingSellerModel> | ArrayOfDateRangeFieldNames<ListingSellerModel>
    > {
        return new ListingModelFoundationConverter().getDateRangeFields();
    }

    override getUrlFields(): Array<UrlFieldNames<ListingSellerModel> | ArrayOfUrlFieldNames<ListingSellerModel>> {
        return new ListingModelFoundationConverter().getUrlFields();
    }

    override getUploadFileFields(): Array<
        UploadFileFieldNames<ListingSellerModel> | ArrayOfUploadFileFieldNames<ListingSellerModel>
    > {
        return new ListingModelFoundationConverter().getUploadFileFields();
    }

    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<ListingSellerModel> | ArrayOfDownloadFileFieldNames<ListingSellerModel>
    > {
        return new ListingModelFoundationConverter().getDownloadFileFields();
    }

    override getTimestampFields(): Array<
        TimestampFieldNames<ListingSellerModel> | ArrayOfTimestampFieldNames<ListingSellerModel>
    > {
        return new ListingModelFoundationConverter().getTimestampFields();
    }

    override getFieldConverterMapByModel(): FieldConverterMap<ListingSellerModel> {
        return {
            ...new ListingModelFoundationConverter().getFieldConverterMapByModel(),
            pricing: new ListingSellerPricingModelConverter(),
            status: new ListingSellerStatusModelConverter(),
            invoiceAddress: new ListingSellerAddressModelConverter(),
            pickupAddress: new ListingSellerAddressModelConverter(),
            seller: new UserModelConverter() as ModelConverterInterface<UserModel>,
            negotiations: new NegotiationModelConverter(),
        };
    }
}

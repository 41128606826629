import { useTranslation } from 'react-i18next';
import { MaterialType } from '@steelbuy/domain-model';
import { FormItem, InputRadioButton, InputRadioButtonGroup, InputRadioButtonGroupAlign } from '@steelbuy/ui-primitive';

interface MaterialTypeSelectionProps {
    materialType: MaterialType;
    materialTypeSelected: (materialType: MaterialType) => void;
    isStainlessFeatureEnabled: boolean;
    disabledTypes?: MaterialType[];
}

export const MaterialTypeSelection = ({
    isStainlessFeatureEnabled,
    materialType,
    materialTypeSelected,
    disabledTypes,
}: MaterialTypeSelectionProps) => {
    const { t } = useTranslation('domainModel');
    const availableMaterialTypes = Object.values(MaterialType);

    return (
        <FormItem header={t('material.materialType.name')}>
            <InputRadioButtonGroup align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}>
                {availableMaterialTypes.map((type) => (
                    <InputRadioButton
                        key={type}
                        name="materialType"
                        value={t(`material.materialType.value.${type}`)}
                        onChange={() => materialTypeSelected(type)}
                        checked={type === materialType}
                        disabled={
                            (type === MaterialType.STAINLESS_STEEL && !isStainlessFeatureEnabled) ||
                            disabledTypes?.includes(type)
                        }
                    />
                ))}
            </InputRadioButtonGroup>
        </FormItem>
    );
};

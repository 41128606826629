import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSelf } from '@steelbuy/data-provider';
import { UserRole } from '@steelbuy/domain-model';
import {
    ButtonTertiaryOnLightM,
    IconIdentifier,
    TabBarItem,
    TabbedPane,
    WizardNavigationButtons,
} from '@steelbuy/ui-primitive';
import { AccountTabId } from './Account.enum';
import { useTabbedPane } from '../../hooks/useTabbedPane';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { MyAccount } from '../my-account/MyAccount';
import { Organisation } from '../organisation/Organisation';
import { PageHeader } from '../page-header/PageHeader';
import { UserManagement } from '../user-management/UserManagement';

import './Account.scss';

const DEFAULT_TAB_ID = AccountTabId.MY_ACCOUNT;
const accountTabIds = Object.values(AccountTabId);

export const Account = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const userModel = useUserSelf().get();
    const isManager = userModel?.roles.includes(UserRole.MANAGER);
    const { activeTabId, setActiveTabId, hash } = useTabbedPane<AccountTabId>(accountTabIds, DEFAULT_TAB_ID);

    const getUserManagementTab = () =>
        isManager ? (
            <TabBarItem label={t('application.account.tabUserManagement')} tabId={AccountTabId.USER_MANAGEMENT} />
        ) : null;

    return (
        <div className="account">
            <PageHeader pageTitle={t('application.account.headerTitle')} />
            <TableLayout>
                <TabbedPane
                    activeTabId={activeTabId}
                    renderTabContent={(tabId) => {
                        switch (tabId) {
                            case AccountTabId.MY_ACCOUNT:
                                return <MyAccount />;
                            case AccountTabId.ORGANISATION:
                                return <Organisation />;
                            case AccountTabId.USER_MANAGEMENT:
                                return isManager && <UserManagement />;
                            default:
                                return <span />;
                        }
                    }}
                    tabChanged={setActiveTabId}
                >
                    <WizardNavigationButtons columns>
                        <div>
                            <TabBarItem label={t('application.account.tabMyAccount')} tabId={AccountTabId.MY_ACCOUNT} />
                            <TabBarItem
                                label={t('application.account.tabOrganisation')}
                                tabId={AccountTabId.ORGANISATION}
                            />
                            {getUserManagementTab()}
                        </div>
                        {isManager && hash === AccountTabId.USER_MANAGEMENT && (
                            <ButtonTertiaryOnLightM
                                label={t('application.account.headerButtonAddNewUser')}
                                icon={IconIdentifier.PLUS}
                                onClick={() => navigate(RoutePath.ACCOUNT_ADD_NEW_USER)}
                            />
                        )}
                    </WizardNavigationButtons>
                </TabbedPane>
            </TableLayout>
        </div>
    );
};

import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { ListingBuyerSearchResultModel, PackageBuyerModel, SearchFormData } from '@steelbuy/domain-model';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { Card, Separator, SeparatorIdentifier } from '@steelbuy/ui-primitive';
import { ListingBuyerTeaser } from '../listing-teaser/ListingBuyerTeaser';
import { calculatePropertyHighlight } from '../listing-teaser/ListingTeaserHeader';

import './SearchResultsTable.scss';

type SearchResultTableProps =
    | { isPackage?: false; results: ReadonlyArray<ListingBuyerSearchResultModel> }
    | { isPackage: true; results: ReadonlyArray<PackageBuyerModel> };

interface ListingSearchResultTableProps {
    numberOfNewResults: number;
    isSortedByRelevance: boolean;
    pathToListingDetails: (listingId: ModelPrimaryKey) => string;
    searchFormData?: SearchFormData;
    onClickListing?: () => void;
    minActiveOffersForBadge?: number;
    handleCheckout?: (id: string) => void;
    onClickCartButton?: (id: string) => void;
    selectedListingId?: string;
}

export const SearchResultTable = ({
    isSortedByRelevance,
    numberOfNewResults,
    pathToListingDetails,
    results,
    searchFormData,
    onClickListing,
    isPackage,
    minActiveOffersForBadge = 1,
    handleCheckout,
    onClickCartButton,
    selectedListingId,
}: ListingSearchResultTableProps & SearchResultTableProps) => {
    const { t } = useTranslation('uiDomain');
    const analytics = useAnalytics();

    const trackResultClick = (listingSearchResult: ListingBuyerSearchResultModel, index: number) => {
        const highlights = calculatePropertyHighlight(listingSearchResult.listing, searchFormData);
        const differentProperties = Object.keys(highlights).filter((property) => highlights[property]);

        analytics.sendEvent(TrackingEvent.SEARCH_RESULT_CLICKED, {
            listing_id: listingSearchResult.listing.id,
            rank: index + 1,
            exact_match: listingSearchResult.accuracy >= 1,
            accuracy: listingSearchResult.accuracy,
            query_deviations: differentProperties,
        });
    };

    const getGroupDivider = (index: number) => {
        if (!isSortedByRelevance) {
            return null;
        }

        if (!isPackage) {
            if (index > 0 && results[index - 1].accuracy >= 1 && results[index].accuracy < 1) {
                return (
                    <Separator
                        separatorType={SeparatorIdentifier.GROUP_ON_LIGHT}
                        header={t('searchResultsTable.alternativeResultsSeparator')}
                    />
                );
            }
            if (index === 0 && numberOfNewResults > 0) {
                return (
                    <span className="search-results-table__subheader">
                        {t('searchResultsTable.newResultsSeparator', { count: numberOfNewResults })}
                    </span>
                );
            }

            if (index > 0 && results[index - 1].isPublishedWithin24Hours && !results[index].isPublishedWithin24Hours) {
                return <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />;
            }
        }
        return null;
    };

    return (
        <div className="search-results-table">
            {!isPackage
                ? results.map((listingSearchResult, index): ReactNode => {
                      const groupDivider = getGroupDivider(index);
                      return (
                          <Fragment key={listingSearchResult.listing.id}>
                              {groupDivider !== null && groupDivider}
                              <Card
                                  isClickable
                                  onClick={() => {
                                      onClickListing?.();
                                      trackResultClick(listingSearchResult, index);
                                  }}
                                  url={pathToListingDetails(listingSearchResult.listing.id)}
                                  linkState={{ fromSearchResults: true }}
                              >
                                  <ListingBuyerTeaser
                                      listing={listingSearchResult.listing}
                                      isNew={listingSearchResult.isPublishedWithin24Hours}
                                      searchData={searchFormData}
                                      numberOffers={listingSearchResult.listing.numberOfActiveNegotiation}
                                      numberActiveOffers={listingSearchResult.listing.numberOfActiveNegotiation}
                                      minActiveOffersForBadge={minActiveOffersForBadge}
                                      {...listingSearchResult.listing}
                                      onBuyClick={() => handleCheckout?.(listingSearchResult.listing.id)}
                                      onClickCartButton={() => onClickCartButton?.(listingSearchResult.listing.id)}
                                      isLoading={selectedListingId === listingSearchResult.listing.id}
                                  />
                              </Card>
                          </Fragment>
                      );
                  })
                : results.map((packageSearchResult, index): ReactNode => {
                      const groupDivider = getGroupDivider(index);
                      return (
                          <Fragment key={packageSearchResult.id}>
                              {groupDivider !== null && groupDivider}
                              <Card
                                  isClickable
                                  onClick={() => {
                                      onClickListing?.();
                                  }}
                                  url={pathToListingDetails(packageSearchResult.id)}
                                  linkState={{ fromSearchResults: true }}
                              >
                                  <ListingBuyerTeaser
                                      listing={packageSearchResult}
                                      searchData={searchFormData}
                                      isPackage
                                      minActiveOffersForBadge={minActiveOffersForBadge}
                                      onBuyClick={() => {
                                          handleCheckout?.(packageSearchResult.id);
                                      }}
                                      onClickCartButton={() => onClickCartButton?.(packageSearchResult.id)}
                                      isLoading={selectedListingId === packageSearchResult.id}
                                  />
                              </Card>
                          </Fragment>
                      );
                  })}
        </div>
    );
};

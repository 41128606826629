import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RestError, Tenant, verifyResetPasswordToken } from '@steelbuy/api-integration';
import { ButtonCallToAction, CustomLink, FormActionbar, LoadingSpinnerOverlay } from '@steelbuy/ui-primitive';
import { ResetPasswordForm } from './ResetPasswordForm';

import './ResetPassword.scss';
import { LoginLayout } from '../login-layout/LoginLayout';

enum TokenError {
    EXPIRED,
    UNKNOWN,
}

interface ResetPasswordProps {
    tenant: Tenant;
    apiBaseUrl: string;
    loginPath: string;
    forgotPasswordPath: string;
}

export const ResetPassword = ({ tenant, apiBaseUrl, loginPath, forgotPasswordPath }: ResetPasswordProps) => {
    const { t } = useTranslation('uiDomain');
    const classPrefix = 'reset-password';
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [error, setError] = useState<TokenError | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!token) {
            navigate(loginPath);
            return;
        }
        (async () => {
            try {
                await verifyResetPasswordToken(apiBaseUrl, token);
            } catch (e) {
                const restError = e as RestError;
                if (restError?.status === 400) {
                    setError(TokenError.EXPIRED);
                } else {
                    setError(TokenError.UNKNOWN);
                }
            }
            setIsLoading(false);
        })();
    }, []);

    const onSuccess = (username: string) => {
        navigate(loginPath, {
            state: {
                defaultSuccessNotification: t('login.resetPassword.successNotification'),
                defaultUsername: username,
            },
        });
    };

    const errorLiteral =
        error === TokenError.EXPIRED
            ? 'login.resetPassword.expiredPassword.expiredTokenError'
            : 'login.resetPassword.expiredPassword.unknownTokenError';

    return (
        <LoginLayout>
            <div className={classPrefix}>
                {isLoading ? (
                    <LoadingSpinnerOverlay />
                ) : (
                    <>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {error ? (
                            <main className={`${classPrefix}__error`}>
                                <header className={`${classPrefix}__error--header`}>
                                    <div>{t(`${errorLiteral}.header`)}</div>
                                    <section className={`${classPrefix}__error--header--subheading`}>
                                        <p>{t(`${errorLiteral}.body`)}</p>
                                    </section>
                                </header>
                                <FormActionbar>
                                    <ButtonCallToAction
                                        label={t('login.resetPassword.expiredPassword.submitLabel')}
                                        onClick={() => navigate(forgotPasswordPath)}
                                    />
                                </FormActionbar>
                            </main>
                        ) : token ? (
                            <ResetPasswordForm
                                token={token}
                                onSuccess={onSuccess}
                                forgotPasswordUrl={forgotPasswordPath}
                                tenant={tenant}
                                apiBaseUrl={apiBaseUrl}
                            />
                        ) : null}
                        <div className={`${classPrefix}__link`}>
                            <CustomLink path={loginPath} isInlineLink>
                                {t('login.backToLogin')}
                            </CustomLink>
                        </div>
                    </>
                )}
            </div>
        </LoginLayout>
    );
};

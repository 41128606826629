import { Grade, Coating, Surface } from '../../../MaterialModel.enums';

export const mildSteelCoatedGrade = [
    Grade.DX51D,
    Grade.DX52D,
    Grade.DX53D,
    Grade.DX54D,
    Grade.DX56D,
    Grade.S220GD,
    Grade.S250GD,
    Grade.S280GD,
    Grade.S320GD,
    Grade.S350GD,
    Grade.S390GD,
    Grade.S420GD,
    Grade.S450GD,
    Grade.HX260LAD,
    Grade.HX300LAD,
    Grade.HX340LAD,
    Grade.HX380LAD,
    Grade.HX420LAD,
    Grade.HX460LAD,
    Grade.HX500LAD,
    Grade.HX180BD,
    Grade.HX220BD,
    Grade.HX260BD,
    Grade.HX300BD,
    Grade.HX180YD,
    Grade.HX220YD,
    Grade.HX260YD,
    Grade.HCT490X,
    Grade.HCT590X,
    Grade.HCT780X,
    Grade.HCT980X,
    Grade.HCT780XG,
] as const;

export type MildSteelCoatedGrade = typeof mildSteelCoatedGrade[number];

export const mildSteelCoatedCoating = [Coating.Z, Coating.AZ, Coating.ZM, Coating.ZA, Coating.ZF, Coating.AS] as const;

export const mildSteelCoatedPopularGrades = [Grade.DX51D];

export type MildSteelCoatedCoating = typeof mildSteelCoatedCoating[number];

export const mildSteelCoatedSurface = [Surface.MA_C, Surface.MA_O, Surface.MB_C, Surface.MB_O, Surface.MC_O] as const;

export type MildSteelCoatedSurface = typeof mildSteelCoatedSurface[number];

export const mildSteelCoatedCoatingThicknessAS = ['80', '100', '120', '150', '240', '250'] as const;

export const mildSteelCoatedCoatingThicknessAZ = ['150', '185'] as const;

export const mildSteelCoatedCoatingThicknessZ = ['100', '140', '200', '275', '450', '600'] as const;

export const mildSteelCoatedCoatingThicknessZA = ['150'] as const;

export const mildSteelCoatedCoatingThicknessZF = ['100', '120'] as const;

export const mildSteelCoatedCoatingThicknessZM = ['70', '100', '120', '140', '275', '300', '310'] as const;

type MildSteelCoatedSteelCoatingAndThickness =
    | {
          coating: Coating.Z;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessZ[number];
      }
    | {
          coating: Coating.AZ;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessAZ[number];
      }
    | {
          coating: Coating.ZM;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessZM[number];
      }
    | {
          coating: Coating.ZA;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessZA[number];
      }
    | {
          coating: Coating.ZF;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessZF[number];
      }
    | {
          coating: Coating.AS;
          coatingThicknessValue: typeof mildSteelCoatedCoatingThicknessAS[number];
      };

export type MildSteelCoatedSteel = {
    grade: MildSteelCoatedGrade;
    surface: MildSteelCoatedSurface;
} & MildSteelCoatedSteelCoatingAndThickness;

import { Outlet } from 'react-router';
import { CreateListingContextProvider } from '../../components/create-listing/CreateListingContext';
import { ManageOffersContextProvider } from '../../components/manage-offers/ManageOffersContext';

export const ManageOffersView = () => (
    <ManageOffersContextProvider>
        <CreateListingContextProvider>
            <Outlet />
        </CreateListingContextProvider>
    </ManageOffersContextProvider>
);

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { OfferPriceModelModelConverter, OfferPriceModel } from './OfferPriceModel';

export type OfferOriginalPriceModel = {
    price: OfferPriceModel;
    date: Timestamp;
};

export class OfferPriceModelConverter extends ModelConverter<OfferOriginalPriceModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<OfferOriginalPriceModel> | ArrayOfTimestampFieldNames<OfferOriginalPriceModel>
    > {
        return ['date'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<OfferOriginalPriceModel> {
        return {
            price: new OfferPriceModelModelConverter(),
        };
    }
}

import { ReactElement } from 'react';
import { AuthHandler } from './auth-handler/AuthHandler';

type AuthenticatedProps = {
    children: ReactElement;
};

export const Authenticated = ({ children }: AuthenticatedProps) => {
    const authHandler = AuthHandler.get();

    if (authHandler.isAuthenticated()) {
        return children;
    }
    return null;
};

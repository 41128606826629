import { isApiTimestamp, isTimestamp } from './Timestamp.guards';
import { ApiUploadFile, ApiUploadFileMetaData, UploadFile, UploadFileMetaData } from './UploadFile';

export const isApiUploadFileMetaData = (apiFileMetaData?: unknown): apiFileMetaData is ApiUploadFileMetaData => {
    if ((apiFileMetaData ?? null) === null) {
        return false;
    }
    return (
        (apiFileMetaData as ApiUploadFileMetaData).fileName !== undefined &&
        (apiFileMetaData as ApiUploadFileMetaData).size !== undefined &&
        isApiTimestamp((apiFileMetaData as ApiUploadFileMetaData).lastModifiedDate)
    );
};
export const isUploadFileMetaData = (fileMetaData?: unknown): fileMetaData is UploadFileMetaData => {
    if ((fileMetaData ?? null) === null) {
        return false;
    }
    return (
        (fileMetaData as UploadFileMetaData).fileName !== undefined &&
        (fileMetaData as UploadFileMetaData).size !== undefined &&
        isTimestamp((fileMetaData as UploadFileMetaData).lastModifiedDate)
    );
};

export const isUploadFile = (file?: unknown): file is UploadFile => {
    if ((file ?? null) === null) {
        return false;
    }
    return (
        (file as UploadFile).discriminator === 'UploadFile' &&
        (file as UploadFile).documentId !== undefined &&
        isUploadFileMetaData((file as UploadFile).meta)
    );
};

export const isApiUploadFile = (apiFile?: unknown): apiFile is ApiUploadFile => {
    if ((apiFile ?? null) === null) {
        return false;
    }
    return (apiFile as ApiUploadFile).documentId !== undefined;
};

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Product } from '@steelbuy/domain-model';
import { useSearchFormDataContext } from '../../components/listing-search/SearchFormDataContext';
import { PackageSearchResults } from '../../components/listing-search-results/PackageSearchResults';
import { RoutePath } from '../../router/Routes';

export const SearchPackagesResultsView = () => {
    const navigate = useNavigate();
    const searchFormData = useSearchFormDataContext().getSearchFormData();

    useEffect(() => {
        if (searchFormData === undefined || searchFormData.product !== Product.PACKAGE) {
            navigate(RoutePath.SEARCH_LISTINGS);
        }
    });

    return <PackageSearchResults />;
};

import { ReactNode } from 'react';

import './MarketingBannerContact.scss';

export type MarketingBannerContactProps = {
    header: string;
    text?: string;
    children?: ReactNode;
};

export const MarketingBannerContact = (props: MarketingBannerContactProps) => {
    const { header, children, text } = props;

    const renderContent = () => {
        if (children !== undefined) {
            return (
                <div className="marketing-banner-contact__content__body">
                    <div className="marketing-banner-contact__content__body__text">{text}</div>
                    <div className="marketing-banner-contact__content__body__children">{children}</div>
                </div>
            );
        }
        return <div className="marketing-banner-contact__content__text">{text}</div>;
    };

    return (
        <article className="marketing-banner-contact">
            <img alt="Steelbuy Logo snow" src="/assets/lib-ui-primitive/images/steelbuy_mark_snow.svg" />
            <div className="marketing-banner-contact__content">
                <header className="marketing-banner-contact__content__header">{header}</header>
                {renderContent()}
            </div>
        </article>
    );
};

import { FieldValues, FieldPath, Control, FieldError } from 'react-hook-form';
import { NumberInput } from '../primitive/number-input/NumberInput';

interface RFQNumberInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    error?: FieldError;
}

export const RFQNumberInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    rules,
    error,
}: RFQNumberInputProps<TFieldValues, TName>) => (
    <NumberInput name={name} control={control} rules={rules} error={error?.message} />
);

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Temper, getTempers, MaterialProperties } from '@steelbuy/domain-model';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialTemperSelection = ({
    forceValidation,
    material,
    required,
    temperSelected,
}: {
    material: MaterialProperties;
    temperSelected: (temper?: Temper) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const { temper } = material;

    const tempers = getTempers(material).slice().sort();

    useEffect(() => {
        if (temper !== undefined && !tempers.includes(temper)) {
            temperSelected(undefined);
        }
    }, [temper, temperSelected, tempers]);

    if (tempers.length === 0) {
        return null;
    }

    const materialSurfaceOptions = tempers.map((materialTemper) => ({
        label: t(`domainModel:material.temper.value.${materialTemper}`),
        value: materialTemper,
    }));

    return (
        <FormItem header={t('domainModel:material.temper.name')}>
            <InputSelect
                options={materialSurfaceOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={temper}
                onSelect={(value) => temperSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
                testId="temper-select"
            />
        </FormItem>
    );
};

import { LoadingStatus } from './LoadingStatus.enum';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../loading-spinner/LoadingSpinner.enums';

import './ButtonLoadingSpinner.scss';

type ButtonLoadingSpinnerProps = {
    loadingStatus: LoadingStatus;
};

export const ButtonLoadingSpinner = ({ loadingStatus }: ButtonLoadingSpinnerProps) =>
    loadingStatus === LoadingStatus.PENDING ? (
        <div className="button-loading-spinner">
            <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />
        </div>
    ) : null;

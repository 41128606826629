import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfDownloadFileFieldNames,
    ArrayOfTimestampFieldNames,
    DownloadFile,
    DownloadFileFieldNames,
    Timestamp,
    TimestampFieldNames,
} from '@steelbuy/domain-model-types';
import { Country } from './AddressModel.enums';
import { DeliveryPromise, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingBuyerPricingModel, ListingBuyerPricingModelConverter } from './ListingBuyerPricingModel';
import { MaterialType, Shape, TradeUnit } from './MaterialModel.enums';

export type PackageBuyerModel = {
    id: string;
    material: MaterialType;
    prime: boolean;
    shape: Shape;
    origin: ListingMaterialOrigin[];
    packageTitle: string;
    packageDescription?: string;
    weight: number;
    pricing: ListingBuyerPricingModel;
    description?: string;
    certificates: DownloadFile[];
    packageDocument: DownloadFile;
    expiresAt: Timestamp;
    deliveryTimeframe: DeliveryPromise;
    pictures: DownloadFile[];
    tradeUnit: TradeUnit;
    canCheckout?: boolean;
    purchasedAt?: Timestamp;
    internalSellerSku?: string;
    country?: Country;
};

export class PackageBuyerModelConverter extends ModelConverter<PackageBuyerModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<PackageBuyerModel> | ArrayOfTimestampFieldNames<PackageBuyerModel>
    > {
        return ['expiresAt'];
    }

    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<PackageBuyerModel> | ArrayOfDownloadFileFieldNames<PackageBuyerModel>
    > {
        return ['pictures', 'certificates', 'packageDocument'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<PackageBuyerModel> {
        return {
            pricing: new ListingBuyerPricingModelConverter(),
        };
    }
}

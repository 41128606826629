import { useTranslation } from 'react-i18next';
import { isStackableProduct, Product } from '@steelbuy/domain-model';

// returns '42 [sheets|plates|...]'
export function useNumberOfItems(product?: Product, items?: number) {
    const { t } = useTranslation('uiDomain');
    if (isStackableProduct(product) && items) {
        return t(`numberOfItemsLabel.valueWithLabel.${product}`, { count: items });
    }

    return undefined;
}

// returns 'Number of [sheets|plates|...]'
export function useNumberOfItemsLabel(product?: Product) {
    const { t } = useTranslation('uiDomain');

    if (isStackableProduct(product)) {
        return t(`numberOfItemsLabel.label.${product}`);
    }

    return undefined;
}

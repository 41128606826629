import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutePath } from './Routes';
import { AcceptableUseView } from '../views/AcceptableUsePolicyView';
import { ContactDetailsView } from '../views/ContactDetailsView';
import { PrivayPolicyView } from '../views/PrivacyPolicyView';
import { TermsAndConditionsView } from '../views/TermsAndConditionsView';

export const UnauthenticatedRouter = () => (
    <Routes>
        <Route path={RoutePath.CONTACT_DETAILS} element={<ContactDetailsView />} />
        <Route path={RoutePath.TERMS_AND_CONDITIONS} element={<TermsAndConditionsView />} />
        <Route path={RoutePath.PRIVACY_POLICY} element={<PrivayPolicyView />} />
        <Route path={RoutePath.ACCEPTABLE_USE_POLICY} element={<AcceptableUseView />} />
        <Route
            path="*"
            element={<Navigate to={RoutePath.LOGIN} replace state={{ path: window.location.pathname }} />}
        />
    </Routes>
);

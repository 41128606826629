import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingMaterialOrigin, MaterialProperties, getOrigins, isMultiOrigin } from '@steelbuy/domain-model';
import {
    InputCheckbox,
    FormItem,
    InputCheckboxGroup,
    InputRadioButton,
    InputRadioButtonGroup,
    InputRadioButtonGroupAlign,
    InputCheckboxGroupAlign,
} from '@steelbuy/ui-primitive';
import { RequiredFieldValidationProps } from '../validation/Validation';

export const MaterialOriginSelection = ({
    forceValidation,
    origins,
    originsSelected,
    material,
}: {
    origins?: ListingMaterialOrigin[];
    originsSelected: (origins?: ListingMaterialOrigin[]) => void;
    material: MaterialProperties;
} & RequiredFieldValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const availableOrigins = getOrigins(material);

    const isMultiSelect = isMultiOrigin(material);

    useEffect(() => {
        if (
            !availableOrigins.length &&
            (!origins || origins?.length !== 1 || origins[0] !== ListingMaterialOrigin.UNSPECIFIED)
        ) {
            originsSelected([ListingMaterialOrigin.UNSPECIFIED]);
        } else if (origins?.length && availableOrigins.length) {
            const filteredOrigins = origins.filter((origin) => availableOrigins.includes(origin));
            if (filteredOrigins.length !== origins.length) {
                originsSelected(filteredOrigins);
            }
        }
    }, [origins, originsSelected, availableOrigins]);

    useEffect(() => {
        if (!isMultiSelect && origins && origins.length > 1) {
            originsSelected([origins[0]]);
        }
    }, [isMultiSelect]);

    if (availableOrigins.length === 0) {
        return null;
    }

    return (
        <FormItem
            header={t('domainModel:material.origin.name')}
            description={t('uiDomain:materialOriginSelection.materialOriginSelection')}
        >
            {isMultiSelect ? (
                <InputCheckboxGroup
                    align={InputCheckboxGroupAlign.ALIGN_HORIZONTAL}
                    required
                    requiredMessage={t('uiDomain:validation.requiredField')}
                    forceValidation={forceValidation}
                    value={origins}
                >
                    {availableOrigins.map((availableOrigin) => (
                        <InputCheckbox
                            key={availableOrigin}
                            value={t(`domainModel:material.origin.value.${availableOrigin}`)}
                            onChange={() => {
                                if (origins?.includes(availableOrigin)) {
                                    originsSelected(origins.filter((origin) => origin !== availableOrigin));
                                } else if (!origins?.length) {
                                    originsSelected([availableOrigin]);
                                } else {
                                    originsSelected([...origins, availableOrigin]);
                                }
                            }}
                            checked={origins?.includes(availableOrigin) || false}
                            required={!origins?.length}
                        />
                    ))}
                </InputCheckboxGroup>
            ) : (
                <InputRadioButtonGroup
                    align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}
                    required
                    requiredMessage={t('uiDomain:validation.requiredField')}
                    forceValidation={forceValidation}
                    value={origins?.[0] || undefined}
                >
                    {availableOrigins.map((availableOrigin) => (
                        <InputRadioButton
                            key={availableOrigin}
                            name="materialOrigin"
                            value={t(`domainModel:material.origin.value.${availableOrigin}`)}
                            onChange={() => originsSelected([availableOrigin])}
                            checked={origins?.includes(availableOrigin) || false}
                            required
                        />
                    ))}
                </InputRadioButtonGroup>
            )}
        </FormItem>
    );
};

export enum ActionStatus {
    IDLE = 'IDLE',
    MUTATE_PENDING = 'MUTATE_PENDING',
    SERVICE_PENDING = 'SERVICE_PENDING',
    CREATE_PENDING = 'CREATE_PENDING',
    DELETE_PENDING = 'DELETE_PENDING',
    MUTATE_SUCCESS = 'MUTATE_SUCCESS',
    SERVICE_SUCCESS = 'SERVICE_SUCCESS',
    CREATE_SUCCESS = 'CREATE_SUCCESS',
    DELETE_SUCCESS = 'DELETE_SUCCESS',
    FAILED = 'FAILED',
}

import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSurfaces, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialSurfaceSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    error?: FieldError;
}

export const MaterialSurfaceSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    material,
    name,
    control,
    rules,
    error,
}: MaterialSurfaceSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const availableSurfaces = getSurfaces(material)?.slice()?.sort();

    return (
        availableSurfaces.length > 0 && (
            <Select
                options={availableSurfaces.map((surface) => ({
                    label: t(`domainModel:material.surface.value.${surface}`),
                    value: surface,
                }))}
                placeholder={t(`uiDomain:materialInput.placeholder`)}
                name={name}
                control={control}
                rules={rules}
                error={error?.message}
            />
        )
    );
};

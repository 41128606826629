import { instance as axios } from '@steelbuy/data-access';
import { startDownload } from '@steelbuy/util';

export const handleDownloadFile = async (
    downloadUrl: string,
    fileName: string,
    contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
) => {
    try {
        const response = await axios.get(downloadUrl, {
            headers: {
                'Content-Type': contentType,
            },
            responseType: 'blob',
        });
        const blob = new File([response.data], fileName);
        const url = window.URL.createObjectURL(blob);
        startDownload(url, fileName);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { DeliveryPromise } from './ListingBuyerModel.enums';

import { OrganisationModel, OrganisationModelConverter } from './OrganisationModel';
import { UserRole } from './UserModel.enums';

export enum Feature {
    IMR_TARGET_PRICE = 'IMR_TARGET_PRICE',
    PACKAGES = 'PACKAGES',
    BID_OFFER = 'BID_OFFER',
    RFQ = 'RFQ',
    PACKAGES_DRAFT = 'PACKAGES_DRAFT',
    CART = 'CART',
}

export type UserModel = {
    readonly id: ModelPrimaryKey;
    name: string;
    email: string;
    roles: UserRole[];
    readonly organisation: OrganisationModel;
    activated: boolean;
    mfaPreferences?: 'Enabled' | 'Disabled';
    features?: Feature[];
    readonly deliveryPromise: DeliveryPromise;
};

export class UserModelConverter extends ModelConverter<UserModel> {
    override getFieldConverterMapByModel(): FieldConverterMap<UserModel> {
        return {
            organisation: new OrganisationModelConverter(),
        };
    }
}

import { createRef, useEffect, useState, RefObject } from 'react';
import { handleTabKey } from './TabKey';

export interface ModalAccessor {
    visible: boolean;
    actionPending: boolean;
    setActionPending: (state: boolean) => void;
    modalRef: RefObject<HTMLDivElement>;
    show: () => void;
    hide: () => void;
}

export function useModal(onClose?: () => void): ModalAccessor {
    const [visible, setVisible] = useState<boolean>(false);
    const [actionPending, setActionPending] = useState<boolean>(false);
    const modalRef = createRef<HTMLDivElement>();
    const [previousFocusedElement, setPreviousFocusedElement] = useState<HTMLElement>();

    const focusPreviousFocusedElement = () => {
        previousFocusedElement?.focus?.();
    };

    const tabKeyPressed = (e: KeyboardEvent) => {
        if (modalRef?.current) {
            handleTabKey(e, modalRef.current);
        }
    };

    const escapeKeyPressed = () => {
        if (onClose) {
            onClose();
            return;
        }
        setVisible(false);
        focusPreviousFocusedElement();
    };

    const keyListenersMap = new Map([
        ['Escape', escapeKeyPressed],
        ['27', escapeKeyPressed],
        ['Tab', tabKeyPressed],
        ['9', tabKeyPressed],
    ]);

    useEffect(() => {
        if (!visible) {
            return;
        }

        function keyListener(e: KeyboardEvent) {
            const listener = keyListenersMap.get(e.code) ?? keyListenersMap.get(`${e.key}`);
            return listener?.(e);
        }

        document.addEventListener('keydown', keyListener);

        // eslint-disable-next-line consistent-return
        return () => document.removeEventListener('keydown', keyListener);
    }, [visible, keyListenersMap]);

    return {
        visible,
        modalRef,
        actionPending,
        setActionPending,
        show: () => {
            setVisible(true);
            const activeHtmlElement =
                document.activeElement instanceof HTMLElement ? document.activeElement : undefined;
            setPreviousFocusedElement(activeHtmlElement);
        },
        hide: () => {
            setVisible(false);
            focusPreviousFocusedElement();
        },
    };
}

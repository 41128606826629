import { SortDirection } from '@steelbuy/api-integration';
import { ListingBuyerAlertCollectionDataProvider } from '@steelbuy/data-provider';
import { MyAlertsOverview } from '../../../components/my-alerts/MyAlertsOverview';

export const MyAlertsOverviewView = () => (
    <ListingBuyerAlertCollectionDataProvider
        interceptRendering={false}
        sortCriteria={[{ property: 'createdAt', direction: SortDirection.DESCENDING }]}
    >
        <MyAlertsOverview />
    </ListingBuyerAlertCollectionDataProvider>
);

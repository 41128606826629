import { IconIdentifier } from './Icon.enums';
import icon from '../../assets/lib-ui-primitive/icons/icon.svg';
import './IconSvg.scss';

export type IconSvgProps = {
    name: IconIdentifier;
    onClick?: () => void;
};

export const IconSvg = ({ name, onClick }: IconSvgProps) => (
    <div className={`icon icon__${name}`} onClick={onClick} aria-hidden="true">
        <svg>
            <use href={`${icon}#${name}`} />
        </svg>
    </div>
);

export default IconSvg;

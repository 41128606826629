import { useSearchPackages } from '@steelbuy/data-access';
import { PackageBuyerModel } from '@steelbuy/domain-model';
import { SearchResultsLayout } from './SearchResultsLayout';
import { useSearchFormDataContext } from '../listing-search/SearchFormDataContext';

import './ListingSearchResults.scss';

export const PackageSearchResults = () => {
    const searchFormData = useSearchFormDataContext().getSearchFormData();
    const { materialType, shape } = searchFormData;
    const packageSearchData = { materialType, shape };
    const { data, hasNextPage, total, fetchNextPage, isFetchingNextPage, isLoading } = useSearchPackages({
        searchData: packageSearchData,
    });
    return (
        <SearchResultsLayout<PackageBuyerModel>
            data={data}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            isPackage
            total={total}
        />
    );
};

import { ReactNode } from 'react';

import './CardContentAttributes.scss';

export type CardContentAttributesProps = {
    header: string;
    children: ReactNode;
    hasGrid?: boolean;
};

export const CardContentAttributes = (props: CardContentAttributesProps) => {
    const { header, children, hasGrid = true } = props;

    const renderChildren = () => {
        if (!hasGrid) {
            return <section className="card-content-attributes__children-no-grid">{children}</section>;
        }
        return <section className="card-content-attributes__children">{children}</section>;
    };

    return (
        <article className="card-content-attributes">
            <header className="card-content-attributes__header">{header}</header>
            {renderChildren()}
        </article>
    );
};

import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { OfferListingStatus } from '@steelbuy/domain-model';

export interface SearchResultsContextValue {
    scrollPosition: number;
    setScrollPosition: (position: number) => void;
    filter: OfferListingStatus[];
    setFilter: (filter: OfferListingStatus[]) => void;
}

const defaultValue: SearchResultsContextValue = {
    scrollPosition: 0,
    setScrollPosition: () => null,
    filter: [],
    setFilter: () => null,
};

const MyOffersContext = createContext(defaultValue);

export const MyOffersContextProvider = ({ children }: { children: ReactNode }) => {
    const [scrollPosition, setScrollPosition] = useState(defaultValue.scrollPosition);
    const [filter, setFilter] = useState<OfferListingStatus[]>([]);
    const value = useMemo(
        () => ({
            scrollPosition,
            setScrollPosition,
            filter,
            setFilter,
        }),
        [scrollPosition, setScrollPosition, filter, setFilter]
    );

    return <MyOffersContext.Provider value={value}>{children}</MyOffersContext.Provider>;
};

export const useMyOffersContext = () => useContext(MyOffersContext);

import { RFQStatus } from '@steelbuy/domain-model';
import { BadgeSecondary, IconIdentifier } from '@steelbuy/ui-primitive';
import { getBadgeVariant } from '../status-badge-variant/StatusBadgeVariant';

export type RFQTeaserStatusProps = {
    expiryTime?: string;
    modifiedAt?: string;
    status: RFQStatus;
};

export const RFQTeaserStatus = ({ expiryTime, modifiedAt, status }: RFQTeaserStatusProps) => (
    <div className="rfq-list-teaser__status">
        {expiryTime && <BadgeSecondary icon={IconIdentifier.TIMER} label={expiryTime} />}
        <BadgeSecondary variant={getBadgeVariant(status)} label={modifiedAt} />
    </div>
);

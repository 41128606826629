import { useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const SellingTerms = () => {
    const { t } = useTranslation('translation');

    const address = (
        <span>
            <span>{t('constants.primaryAddress.line1')}, </span>
            <span>{t('constants.primaryAddress.line2')}, </span>
            <span>{t('constants.primaryAddress.line3')}, </span>
            <span>{t('constants.primaryAddress.line4')}, </span>
            <span>{t('constants.primaryAddress.line5')}</span>
        </span>
    );

    return (
        <div className="terms-box">
            <h1 id="seller">AGREED TERMS - SELLERS</h1>

            <h2 id="1-about-us">1. About Us</h2>
            <p>
                <span className="sub-section-marker">1.1</span> <strong>Company details</strong>.{' '}
                {t('constants.companyName')} (company number {t('constants.companyNumber')}) (<strong>We</strong> and{' '}
                <strong>Us</strong>) is a company registered in England and Wales and Our registered office is at{' '}
                {address}. Our VAT number is {t('constants.vatNumber')}. We operate the online marketplace{' '}
                <CustomLink path={`https://${t('constants.marketplace')}`} isInlineLink isAnchorLink>
                    {t('constants.marketplace')}
                </CustomLink>{' '}
                (<strong>Our Marketplace</strong>
                ).
            </p>
            <p>
                <span className="sub-section-marker">1.2</span> <strong>Contacting Us</strong>. To contact Us telephone
                Our customer service team at {t('constants.customerServiceTel')} or email{' '}
                <CustomLink path={`mailto:${t('constants.emailSupport')}`} isInlineLink isAnchorLink>
                    {t('constants.emailSupport')}
                </CustomLink>
                . How to give Us formal notice of any matter under the Contract is set out in clause 16.2.
            </p>

            <h2 id="2-our-contract-with-you">2. Our Contract with you</h2>
            <p>
                <span className="sub-section-marker">2.1</span> <strong>Our Contract</strong>. Note that We do not
                provide an intermediation service. These terms and conditions (<strong>Terms</strong>) apply to each
                offer for sale by you and purchase of goods by Us from you. Each such offer will, when accepted in
                accordance with clause 3.4, constitute a separate contract between you and Us (<strong>Contract</strong>
                ). No other terms are implied by trade, custom, practice or course of dealing. Once a Contract is formed
                between us these Terms will apply to all future purchases of goods from Us by you.
            </p>
            <p>
                <span className="sub-section-marker">2.2</span>{' '}
                <strong>
                    Each Contract is DDP (the UK sea port or UK inland location listed on the Offer) (Incoterms 2020)
                </strong>{' '}
                except to the extent varied by these Terms. To the extent of any inconsistency between Incoterms 2020
                and these Terms, these Terms shall take precedence.
            </p>
            <p>
                <span className="sub-section-marker">2.3</span> <strong>Entire agreement</strong>. The Contract is the
                entire agreement between us in relation to its subject matter. You acknowledge that you have not relied
                on any statement, promise or representation or assurance or warranty that is not set out in the
                Contract.
                <span className="sub-section-marker">2.4</span> <strong>Language</strong>. These Terms and the Contract
                are made only in the English language.
            </p>

            <h2 id="3-placing-an-offer-and-its-acceptance">3. Placing an Offer and its acceptance</h2>
            <p>
                <span className="sub-section-marker">3.1</span> <strong>Listing your Offer</strong>. Please follow the
                onscreen prompts to list an offer of goods for sale (<strong>Offer</strong>) on Our Marketplace. Each
                offer is an offer by you to sell the goods specified in the Offer (<strong>Goods</strong>) to Us subject
                to these Terms. By listing an Offer, you represent and warrant to Us that you are a business, acting in
                the course of your business and are not a consumer. When you list an Offer, the Offer is binding on you
                and you cannot withdraw it:
            </p>
            <ol type="a">
                <li>
                    before the date and time you specify when listing the Offer unless you receive a Processing Notice
                    from Us during that time; or
                </li>
                <li>
                    if you have received a Processing Notice from Us, before 7 days after midnight GMT on the date on
                    which you have received the Processing Notice from Us; or
                </li>
                <li>if the Offer is accepted in accordance with clause 3.4.</li>
            </ol>
            <p>
                <strong>
                    <span className="sub-section-marker">3.2</span> Correcting input errors.
                </strong>{' '}
                Our offer process allows you to check and amend any errors before submitting your Offer to Us. Please
                check the Offer carefully before confirming it. You are responsible for ensuring that your Offer is
                complete and accurate.
            </p>
            <p>
                <span className="sub-section-marker">3.3</span> <strong>Offer Processing.</strong> If We find someone to
                whom We can sell your Goods then We will send you a Processing Notice. Sending you a Processing Notice
                does not mean that We accept your Offer. There will be no binding contract between us. A Processing
                Notice means that We are attempting to process the sale of your Goods by arranging for their collection
                and transportation.
            </p>
            <p>
                <strong>
                    <span className="sub-section-marker">3.4</span> Accepting your Offer
                </strong>
                . We will confirm Our acceptance of your Offer to you by sending you an email that confirms that the
                Goods have been purchased (<strong>Purchase Order</strong>
                ). The Contract between you and Us will only be formed when We send you the Purchase Order. The fact
                that you have placed an Offer does not mean that We are obliged to (a) send you a Processing Notice; (b)
                send you a Purchase Order; or (c) that We are obliged to buy the Goods.{' '}
            </p>
            <p>
                <strong>
                    <span className="sub-section-marker">3.5</span> If We cannot accept your Offer before the date and
                    time you specify when listing the Offer
                </strong>
                . If it looks likely to Us that We will be unable to purchase the Goods for any reason, before the date
                and time You specify when listing the Offer, then before Your Offer has expired, You have three options.
                These options are:
                <ul>
                    <li>
                        Your Offer will not appear and/or be deleted from Our Marketplace on the date and time You
                        specified when listing the Offer;
                    </li>
                    <li>You can amend Your Offer (by reducing the price) and repost it as a new Offer; or</li>
                    <li>You can extend the date and time when Your Offer expires.</li>
                </ul>{' '}
                Before Your Offer has expired, We will send You an email reminding you about these three options. If We
                do not hear from You in response to Our email before the date and time when Your Offer expires, We will
                automatically extend Your Offer so that it has a further duration equal to the duration of Your original
                Offer. You must respond to Our email before the date and time your Offer expires, to tell Us if You do
                not want this to happen.
            </p>

            <h2 id="your-goods">4. Your Goods</h2>
            <p>
                <span className="sub-section-marker">4.1</span> In selling the Goods, you acknowledge and agree that We
                will be buying the Goods for immediate on-sale to a third party.
            </p>
            <p>
                <span className="sub-section-marker">4.2</span> You warrant and represent that any images of the Goods
                on Our Marketplace are, if submitted by you, true, accurate and fully illustrative.
            </p>
            <p>
                <span className="sub-section-marker">4.3</span> You warrant and represent that, in describing the Goods,
                you have made every effort to be as accurate as possible, and that the quality of the Goods and all
                sizes, weights, capacities, dimensions and measurements listed in your Offer are compliant with the
                Euronorm(s) relevant to the Goods being listed.{' '}
            </p>

            <h2 id="5-certification">5. Certification</h2>
            <p>
                <span className="sub-section-marker">5.1</span> You warrant and represent that:
            </p>
            <ol type="a">
                <li>
                    the Goods conform to any certification which is, or should be, provided with them (for example
                    certification by a steel mill pursuant to EN 10204) (“
                    <strong>Certification</strong>
                    ”);
                </li>
                <li>any Certification given in relation to the Goods is authentic;</li>
                <li>any “certified true copies” of any such Certification made and given by you is authentic;</li>
                <li>
                    the Certification trail between the steel mill manufacturing the Goods and your ownership is
                    complete, or any incompleteness is expressly stated in the Offer;
                </li>
                <li>
                    all mill markings on the Goods are, to the best of your knowledge and belief true and accurate; and
                </li>
                <li>
                    any re-markings on the Goods have, to the best of your knowledge and belief, been made by
                    stockholders or others accurately and with due authority.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">5.2</span> You shall ensure that all Certification in relation to
                the Goods is included with the Goods and/or sent by email to Us when the Goods are collected from you
                following their sale to Us.{' '}
            </p>

            <h2 id="6-return-and-refund">6. Return and refund</h2>
            <p>
                <span className="sub-section-marker">6.1</span> If the Goods are faulty or mis-described or otherwise
                supplied in breach of the Contract, We may return the Goods to you and if We do then you shall refund
                the price of the Goods in full and Our transportation and associated costs. If you disagree with Our
                assessment of the Goods, then you may refer the matter to Dispute Resolution (see clause 17). Pending
                the outcome of the Dispute Resolution you must retain the Goods We return to you and store them in
                conditions such as will maintain them in the condition in which they were returned to you. If the
                Dispute Resolution is determined in your favour, then We will collect the Goods from you and pay the
                price of the Goods less a reasonable and proper amount to compensate Us for any deterioration in the
                condition of the Goods after they were returned to you.
            </p>

            <h2 id="7-transportation-transfer-of-risk-and-title">7. Transportation, transfer of risk and title</h2>
            <p>
                <span className="sub-section-marker">7.1</span> If the Goods sold to Us are located{' '}
                <strong>outside</strong> the UK at the time this Contract is formed, then:
            </p>
            <ol type="a">
                <li>
                    within five days of the date We issue the Purchase Order to you, you must notify Us of the ship on
                    which the Goods will be transported to the United Kingdom and the date and approximate time that the
                    Goods will be unloaded from that ship at the port stated in the Offer listing;
                </li>
                <li>
                    you must place the Goods at Our disposal on the date arranged such that suitable transportation is
                    able to manoeuvre and load safely and without unreasonable delay or difficulty; and
                </li>
                <li>
                    you must obtain goods in transit insurance for the full value of the Goods which covers the period
                    beginning when the Goods are loaded at their place of origin and ending when the Goods are unloaded
                    from the ship on which the Goods will be transported to the United Kingdom. If the Goods are lost in
                    transit, then, We shall not be obliged to pay for the Goods, and you shall refund any payment that
                    has been made and issue Us with a credit note against the related invoice. If the Goods are damaged
                    in transit, then We shall not be obliged to accept delivery of the Goods but if We do accept
                    delivery then We shall only be obliged to pay a fair price for the Goods based on their condition at
                    that time.{' '}
                </li>
            </ol>

            <p>
                <span className="sub-section-marker">7.2</span> If the Goods sold to Us are already located{' '}
                <strong>inside</strong> the UK at the time this Contract is formed, then:
            </p>
            <ol type="a">
                <li>
                    the Purchase Order will state the date that the Goods will be collected from the location stated in
                    the Offer listing. Occasionally collection of the Goods by Us or an agent acting on Our behalf may
                    be affected by a Force Majeure Event. See clause 15 (Force Majeure) for Our responsibilities when
                    this happens; and
                </li>
                <li>
                    you must place the Goods at Our disposal on the date arranged such that suitable transportation is
                    able to manoeuvre and load safely and without unreasonable delay or difficulty. You will be
                    responsible for loading the Goods and shall provide suitably qualified and experienced personnel to
                    carry out loading promptly and efficiently on the arrival of Our transportation.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">7.3</span> Title to the Goods shall not pass to Us until the
                earlier of:
            </p>
            <ol type="a">
                <li>
                    when you receive payment in full (in cash or cleared funds) for the Goods, in which case title to
                    the Goods shall pass at the time of payment; and
                </li>
                <li>
                    when We resell the Goods, in which case title to the Goods shall pass to Is at the time specified in
                    clause 7.4.
                </li>
            </ol>

            <p>
                <span className="sub-section-marker">7.4</span> We may resell or use the Goods in the ordinary course of
                Our business (but not otherwise) before you receive payment for the Goods. However, if We resell the
                Goods before that time:
            </p>
            <ol type="a">
                <li>We do so as principal and not as your agent; and</li>
                <li>
                    title to the Goods shall pass from you to Us immediately before the time at which the risk to the
                    Goods passes to Our buyer.
                </li>
            </ol>

            <h2 id="8-international-delivery">8. International delivery</h2>
            <p>
                <span className="sub-section-marker">8.1</span> We only buy Goods located in the countries listed on{' '}
                <em>Appendix 1: International Destinations</em> (<strong>International Destinations</strong>
                ).
            </p>
            <p>
                <span className="sub-section-marker">8.2</span> If you offer Goods on Our Marketplace for delivery to
                one of the International Destinations, your Offer may be subject to both export duties and taxes which
                are applied when the Goods are exported and import duties and taxes which are applied when the Goods are
                imported. It is your responsibility to pay all of these duties and taxes. The Offer price listed on your
                Offer will be the price We pay for the Goods if We buy them and is inclusive of any such duties and
                taxes.
            </p>
            <p>
                <span className="sub-section-marker">8.3</span> You must comply with all applicable laws and regulations
                of the country in which the Goods you offer are located. We will not be liable or responsible if you
                break any such law.
            </p>

            <h2 id="9-price-of-goods-and-delivery-charges">9. Price of Goods and delivery charges</h2>
            <p>
                <span className="sub-section-marker">9.1</span> The prices of the Goods will be as stated in your Offer.{' '}
            </p>
            <p>
                <span className="sub-section-marker">9.2</span> The price of Goods stated in the Offer excludes VAT
                (where applicable) at the applicable current rate chargeable in the UK for the time being. However, if
                the rate of VAT changes between the date of your Offer and the date of the Purchase Order, We will
                adjust the VAT payable.{' '}
            </p>
            <p>
                <span className="sub-section-marker">9.3</span> Collection, transportation and delivery from the UK port
                or UK inland location stated in the Order shall be at Our cost.{' '}
            </p>

            <h2 id="10-how-to-pay">10. How to pay</h2>
            <p>
                <span className="sub-section-marker">10.1</span> You shall invoice Us for the Goods when you have
                dispatched the Goods and not before. Undisputed invoices shall be payable within 60 days of the end of
                the month in in which We receive the invoice.{' '}
            </p>

            <h2 id="11-your-warranty-for-the-goods">11. Your warranty for the Goods</h2>
            <p>
                <span className="sub-section-marker">11.1</span> You warrant and represent to Us that:
            </p>
            <ol type="a">
                <li>
                    the Goods comply with the laws, regulations or standards of the country in which they are located
                    when offered for sale;
                </li>
                <li>
                    the Goods have been stored and maintained in accordance with best industry practice at all times;
                    and
                </li>
                <li>
                    any and all defects, blemishes and non-conformities of the Goods have been brought to Our attention
                    in your Offer.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">11.2</span> You warrant and represent to Us that the Goods shall:
            </p>
            <ol type="a">
                <li>conform in all material respects with their description; and</li>
                <li>be of satisfactory quality (within the meaning of the Sale of Goods Act 1979)</li>
            </ol>
            <p>
                <span className="sub-section-marker">11.3</span> If:
            </p>
            <ol type="a">
                <li>
                    We give you notice in writing within a reasonable time of discovery that some or all of the Goods do
                    not comply with the warranties set out in clauses 11.1 or 11.2; and
                </li>
                <li>
                    you ask Us to do so, We will use Our reasonable endeavours to return the Goods to you at your cost,
                </li>
            </ol>
            <p>you will refund the price of the defective Goods in full.</p>
            <p>
                <span className="sub-section-marker">11.4</span> Nothing in clauses 11.1 or 11.2 shall affect the
                application of sections 13 to 15 of the Sale of Goods Act 1979 to this Contract.
            </p>
            <p>
                <span className="sub-section-marker">11.5</span> In relation to the Goods, you shall maintain product
                liability insurance in force with an insurer of good standing with a minimum insured amount of GBP
                10,000,000.
            </p>

            <h2 id="12-our-liability-your-attention-is-particularly-drawn-to-this-clause">
                12. Our liability: your attention is particularly drawn to this clause
            </h2>
            <p>
                <span className="sub-section-marker">12.1</span> References to liability in this clause 12 include every
                kind of liability arising under or in connection with the Contract including but not limited to
                liability in contract, tort (including negligence), misrepresentation, restitution or otherwise.
            </p>
            <p>
                <span className="sub-section-marker">12.2</span> Nothing in these Terms limits or excludes liability
                for:
            </p>
            <ol type="a">
                <li>death or personal injury caused by Our negligence;</li>
                <li>fraud or fraudulent misrepresentation;</li>
                <li>
                    breach of the terms implied by section 12 of the Sale of Goods Act 1979 (title and quiet
                    possession); or
                </li>
                <li>any other liability that cannot be limited or excluded by law.</li>
            </ol>
            <p>
                <span className="sub-section-marker">12.3</span> Subject to clause 12.2, We will under no circumstances
                be liable to you for:
            </p>
            <ol type="a">
                <li>any loss of profits, sales, business, or revenue;</li>
                <li>loss or corruption of data, information or software;</li>
                <li>loss of business opportunity;</li>
                <li>loss of anticipated savings;</li>
                <li>loss of goodwill; or</li>
                <li>any indirect or consequential loss.</li>
            </ol>
            <p>
                <span className="sub-section-marker">12.4</span> Subject to clause 12.2, Our total liability to you for
                all losses arising under or in connection with the Contract will in no circumstances exceed the price of
                the Goods.
            </p>

            <h2 id="13-anonymity">13. Anonymity</h2>
            <p>
                <span className="sub-section-marker">13.1</span> Our Marketplace and Our business processes are designed
                so as to maintain your anonymity with respect to other users of Our Marketplace and participants in the
                wider market for steel products. Despite this, We cannot guarantee that your anonymity will be
                maintained and therefore Wwe give no warranty and make no representations in this regard.{' '}
            </p>
            <p>
                <span className="sub-section-marker">13.2</span> You undertake that if, at any time, you are able to
                identify the person to whom We have sold the Goods We have bought from you, you will keep such
                information confidential and will not utilise such information for any purpose whatsoever.
            </p>
            <p>
                <span className="sub-section-marker">13.3</span> For the period during which the Goods are in your
                possession after they have been sold to Us, you shall label them as being owned by and consigned to
                “Steelbuy Limited”. You shall provide Us and Our agents with the facility to further label the Goods
                with ultimate consignee details when they are collected.
            </p>

            <h2 id="14-termination">14. Termination</h2>
            <p>
                <span className="sub-section-marker">14.1</span> Without limiting any of Our other rights, We may
                suspend the purchase and collection of the Goods from you, your participation on Our Marketplace or
                terminate the Contract with immediate effect by giving written notice to you if:
            </p>
            <ol type="a">
                <li>
                    you commit a material breach of any term of the Contract and (if such a breach is remediable) fail
                    to remedy that breach within 3 days of you being notified in writing to do so;
                </li>
                <li>you commit a breach of clause 13.2 or 18;</li>
                <li>
                    We have reason to believe that the Goods do not exist, do not conform to the description in the
                    Offer, or that you do not have unencumbered title to them;
                </li>
                <li>
                    you suspend, threaten to suspend, cease or threaten to cease to carry on all or a substantial part
                    of your business; or
                </li>
                <li>
                    your financial position deteriorates to such an extent that in Our reasonable opinion your
                    capability to adequately fulfil your obligations under the Contract has been placed in jeopardy.
                </li>
            </ol>

            <p>
                <span className="sub-section-marker">14.2</span> Termination of the Contract shall not affect your or
                Our rights and remedies that have accrued as at termination.
            </p>
            <p>
                <span className="sub-section-marker">14.3</span> Any provision of the Contract that expressly or by
                implication is intended to come into or continue in force on or after termination shall remain in full
                force and effect.
            </p>

            <h2 id="15-force-majeure">15. Force Majeure</h2>
            <p>
                <span className="sub-section-marker">15.1</span> Neither party will be liable or responsible for any
                failure to perform, or delay in performance of, its obligations under the Contract that is caused by any
                act or event beyond its or its agent’s reasonable control (<strong>Force Majeure Events</strong>
                ).{' '}
            </p>
            <p>
                <span className="sub-section-marker">15.2</span> If a Force Majeure Event takes place that affects the
                performance of one party’s obligations under the Contract:
            </p>
            <ol type="a">
                <li>that party will contact the other party as soon as reasonably possible to notify them; and</li>
                <li>
                    that party’s obligations under the Contract will be suspended and the time for performance of its
                    obligations will be extended for the duration of the Force Majeure Event. Where the Force Majeure
                    Event affects collection of Goods a new delivery date will be arranged for after the Force Majeure
                    Event is over.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">15.3</span> If a Force Majeure Event continues for more than 30
                days either party may cancel the Contract by notifying the other party.
            </p>

            <h2 id="16-communications-between-us">16. Communications between us</h2>
            <p>
                <span className="sub-section-marker">16.1</span> When We refer to &quot;in writing&quot; in these Terms,
                this includes email.
            </p>
            <p>
                <span className="sub-section-marker">16.2</span> Any notice or other communication given by one of us to
                the other under or in connection with the Contract must be in writing and be delivered by hand, sent by
                pre-paid first-class post or other next working day delivery service, or email.
            </p>
            <p>
                <span className="sub-section-marker">16.3</span> Any notice shall be deemed to have been received:
            </p>
            <ol type="a">
                <li>if delivered by hand, at the time the notice is left at the proper address;</li>
                <li>
                    if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the
                    second Business Day after posting; or
                </li>
                <li>
                    if sent by email, at the time of transmission, or, if this time falls outside Business Hours in the
                    place of receipt, when Business Hours resume.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">16.4</span> For the purposes of this clause, Business Day means: a
                day, other than a Saturday, Sunday or public holiday in England, when banks in London are open for
                business and Business Hours means the period from 9.00 am to 5.00 pm local on any Business Day.
            </p>
            <p>
                <span className="sub-section-marker">16.5</span> In proving the service of any notice, it will be
                sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and
                placed in the post and, in the case of an email, that such email was sent to the specified email address
                of the addressee.{' '}
            </p>
            <p>
                <span className="sub-section-marker">16.6</span> The provisions of this clause shall not apply to the
                service of any proceedings or other documents in any legal action.
            </p>

            <h2 id="17-complaints-and-dispute-resolution">17. Complaints and Dispute Resolution</h2>
            <p>
                <span className="sub-section-marker">17.1</span> The complaints and dispute resolution rules applicable
                to the Contract and these Terms (<strong>Dispute Resolution</strong>) is set out in the Website Terms
                and Conditions which can be found{' '}
                <CustomLink path={RoutePath.TERMS_WEBSITE} isInlineLink>
                    here
                </CustomLink>
                . You and We agree to abide by them save that nothing in the complaints and dispute resolution rules
                shall prevent either you or Us from applying to the courts at any time for injunctive or other equitable
                relief.
            </p>

            <h2 id="18-confidentiality">18. Confidentiality</h2>
            <p>
                <span className="sub-section-marker">18.1</span> Each party undertakes to the other that it shall not at
                any time disclose to any person any confidential information concerning the business, assets, affairs,
                customers, clients or suppliers of the other party, except as permitted by clause 18.2.
            </p>
            <p>
                <span className="sub-section-marker">18.2</span> Each party may disclose the other’s confidential
                information:
            </p>
            <ol type="a">
                <li>
                    to its employees, officers, representatives, contractors, subcontractors or advisers who need to
                    know such information for the purposes of exercising its rights or carrying out its obligations
                    under or in connection with this Contract. Each party shall ensure that its employees, officers,
                    representatives, contractors, subcontractors or advisers to whom it discloses the other party&#39;s
                    confidential information comply with this clause 18; and
                </li>
                <li>
                    as may be required by law, a court of competent jurisdiction or any governmental or regulatory
                    authority.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">18.3</span> No party shall use any other party&#39;s confidential
                information for any purpose other than to exercise its rights and perform its obligations under or in
                connection with this Contract. However, We are entitled to perpetually and free of charge use your
                confidential information, solely in anonymized and aggregated form that does not identify you, for
                statistical and research purposes, including improving and/or optimizing Our Marketplace or developing
                other products.
            </p>

            <h2 id="19-general">19. General</h2>
            <p>
                <span className="sub-section-marker">19.1</span> <strong>Assignment and transfer.</strong>
            </p>
            <ol type="a">
                <li>
                    We may assign or transfer Our rights and obligations under the Contract to another entity provided
                    that that entity is affiliated to Us at the time of the assignment.
                </li>
                <li>
                    You may only assign or transfer your rights or your obligations under the Contract to another person
                    if We agree in writing.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">19.2</span> <strong>Variation.</strong> Any variation of the
                Contract only has effect if it is in writing and processed via Our Marketplace:
            </p>
            <p>
                <span className="sub-section-marker">19.3</span> <strong>Waiver.</strong> If We do not insist that you
                perform any of your obligations under the Contract, or if We do not exercise Our rights or remedies
                against you, or if We delay in doing so, that will not mean that We have waived Our rights or remedies
                against you or that you do not have to comply with those obligations. If We do waive any rights or
                remedies, We will only do so in writing, and that will not mean that We will automatically waive any
                right or remedy related to any later default by you.
            </p>
            <p>
                <span className="sub-section-marker">19.4</span> <strong>Severance.</strong> Each paragraph of these
                Terms operates separately. If any court or relevant authority decides that any of them is unlawful or
                unenforceable, the remaining paragraphs will remain in full force and effect.
            </p>
            <p>
                <span className="sub-section-marker">19.5</span> <strong>Third party rights.</strong> The Contract is
                between you and Us. No other person has any rights to enforce any of its terms.
            </p>
            <p>
                <span className="sub-section-marker">19.6</span> <strong>Governing law and jurisdiction.</strong> This
                Contract is governed by English law and, subject to clause 17, each party irrevocably agrees to submit
                all disputes arising out of or in connection with this Contract to the exclusive jurisdiction of the
                English courts.
            </p>

            <h2 id="appendix-1-international-destinations">Appendix 1: International Destinations</h2>
            <ol className="numberedOl">
                <li>United Kingdom</li>
            </ol>
        </div>
    );
};

import { ModelConverter } from '@steelbuy/domain-model-converter';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { AluminiumMaterialModel } from './material-taxonomy/aluminium';
import { MildSteelMaterialModel } from './material-taxonomy/mild-steel';
import { StainlessMaterialModel } from './material-taxonomy/stainless';
import { MaterialType } from './MaterialModel.enums';

/**
 * Any material foundation
 */
type AnyMaterialModelFoundation = {
    readonly id: ModelPrimaryKey;
};

export type AnyMildSteelMaterialModel = {
    materialType: MaterialType.MILD_STEEL;
} & MildSteelMaterialModel;

export type AnyAluminiumMaterialModel = {
    materialType: MaterialType.ALUMINIUM;
} & AluminiumMaterialModel;

export type AnyStainlessMaterialModel = {
    materialType: MaterialType.STAINLESS_STEEL;
} & StainlessMaterialModel;

export type AnyMaterialModel = AnyMaterialModelFoundation &
    (AnyMildSteelMaterialModel | AnyAluminiumMaterialModel | AnyStainlessMaterialModel);

export class AnyMaterialModelConverter extends ModelConverter<AnyMaterialModel> {
    override getCustomFormatter() {
        return {
            coatingThicknessValue: {
                toViewFormatter: (thickness: number) => `${thickness}`,
                toApiFormatter: (thickness: string) => parseInt(thickness, 10),
            },
        };
    }
}

import { ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';

export type ListingSellerAutoRenewModel = {
    enabled: boolean;
    expiresAt: Timestamp;
    price: number;
};

export class ListingSellerAutoRenewModelConverter extends ModelConverter<ListingSellerAutoRenewModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<ListingSellerAutoRenewModel> | ArrayOfTimestampFieldNames<ListingSellerAutoRenewModel>
    > {
        return ['expiresAt'];
    }
}

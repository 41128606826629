import { ReactNode } from 'react';
import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';
import { NotificationIllustratedAlignment } from './NotificationIllustrated.enums';

import './NotificationIllustrated.scss';

export type NotificationIllustratedProps = {
    alignment: NotificationIllustratedAlignment;
    imgSrc: string;
    header?: string;
    helperText?: ReactNode;
    HelperComponent?: ReactNode;
    children?: ReactNode;
};

export const NotificationIllustrated = (props: NotificationIllustratedProps) => {
    const { alignment, imgSrc, header, helperText, children, HelperComponent } = props;

    const notificationIllustratedClassMap = {
        'notification-illustrated': true,
        [classNameFromEnumValue(alignment, 'notification-illustrated')]: true,
    };

    const renderChildren = () => {
        if (children === undefined) {
            return null;
        }
        return <article className="notification-illustrated__text-wrapper__steps">{children}</article>;
    };

    return (
        <article className={buildClassStringFromClassMap(notificationIllustratedClassMap)}>
            <img alt="notification illustration" src={imgSrc} />
            <article className="notification-illustrated__text-wrapper">
                {header !== undefined && (
                    <span className="notification-illustrated__text-wrapper__header">{header}</span>
                )}
                {helperText !== undefined && (
                    <span className="notification-illustrated__text-wrapper__helper-text">{helperText}</span>
                )}
                {HelperComponent !== undefined && (
                    <div className="notification-illustrated__text-wrapper__helper-wrapper">{HelperComponent}</div>
                )}
                {renderChildren()}
            </article>
        </article>
    );
};

import { ReactNode } from 'react';

import './MarketingBannerAdvantages.scss';

export type MarketingBannerAdvantagesProps = {
    header: string;
    text?: string;
    children?: ReactNode;
};

export const MarketingBannerAdvantages = (props: MarketingBannerAdvantagesProps) => {
    const { header, children, text } = props;

    const renderContent = () => {
        if (children !== undefined) {
            return <section className="marketing-banner-advantages__content__children">{children}</section>;
        }
        return <section className="marketing-banner-advantages__content__text">{text}</section>;
    };

    return (
        <article className="marketing-banner-advantages">
            <img alt="Steelbuy Logo snow" src="/assets/lib-ui-primitive/images/steelbuy_mark_snow.svg" />
            <div className="marketing-banner-advantages__content">
                <header className="marketing-banner-advantages__content__header">{header}</header>
                {renderContent()}
            </div>
        </article>
    );
};

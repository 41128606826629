import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCoatingThickness, isTwoSidedMaterial, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialCoatingThicknessSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    error?: FieldError;
}

export const MaterialCoatingThicknessSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    name,
    material,
    rules,
    error,
}: MaterialCoatingThicknessSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation('uiDomain');
    const coatingThickness = getCoatingThickness(material);
    const twoSidedMaterial = isTwoSidedMaterial(material);

    return (
        <Select
            options={coatingThickness.map((gradeCoatingThickness) => ({
                label: twoSidedMaterial ? `${gradeCoatingThickness}/${gradeCoatingThickness}` : gradeCoatingThickness,
                value: gradeCoatingThickness,
            }))}
            disabled={coatingThickness.length === 0}
            placeholder={t(`materialInput.placeholder`)}
            name={name}
            control={control}
            rules={{ ...(rules || {}), required: coatingThickness.length > 0 ? rules?.required : undefined }}
            error={error?.message}
        />
    );
};

import { ApiModel } from '@steelbuy/domain-model-types';

import { AnyMaterialModel } from './MaterialModel';
import {
    MaterialType,
    Definition,
    Product,
    Finish,
    ProductType,
    Shape,
    Tolerance,
    Grade,
    Surface,
    Specification,
    Coating,
} from './MaterialModel.enums';

export const hotRolledCoilMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'hotRolledCoilMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.HOT_ROLLED,
    grade: Grade.C35,
    finish: Finish.DRY,
};

export const coldRolledCoilMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'coldRolledCoilMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.COLD_ROLLED,
    grade: Grade.DC01,
    surface: Surface.A_M,
    finish: Finish.DRY,
};

export const hotRolledFloorplateCoilMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'hotRolledFloorplateCoilMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.HOT_ROLLED_FLOORPLATE,
    grade: Grade.S275,
    specification: Specification.J2_PLUS_N,
    finish: Finish.DRY,
};

export const electroZincCoilMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'electroZincCoilMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.ELECTRO_ZINC,
    grade: Grade.DC01,
    coating: Coating.TWO_SIDED,
    coatingThicknessValue: '25',
    surface: Surface.B_O,
};

export const coatedCoilMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'coatedCoilMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.COATED_STEEL,
    grade: Grade.HCT490X,
    coating: Coating.ZA,
    coatingThicknessValue: '150',
    surface: Surface.MB_C,
};

export const hotRolledSheetMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'hotRolledSheetMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.SHEET,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.HOT_ROLLED,
    grade: Grade.C35,
    finish: Finish.DRY,
};

export const coldRolledSheetMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'coldRolledSheetMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.SHEET,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.COLD_ROLLED,
    grade: Grade.DC01,
    surface: Surface.A_M,
    finish: Finish.DRY,
};

export const hotRolledFloorplateSheetMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'hotRolledFloorplateSheetMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.SHEET,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.HOT_ROLLED_FLOORPLATE,
    grade: Grade.S275,
    specification: Specification.J2_PLUS_N,
    finish: Finish.DRY,
};

export const electroZincSheetMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'electroZincSheetMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.SHEET,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.ELECTRO_ZINC,
    grade: Grade.DC01,
    coating: Coating.TWO_SIDED,
    coatingThicknessValue: '25',
    surface: Surface.B_O,
};

export const coatedSheetMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'coatedSheetMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.SHEET,
    tolerance: Tolerance.EU_NORM,
    definition: Definition.COATED_STEEL,
    grade: Grade.HCT490X,
    coating: Coating.ZA,
    coatingThicknessValue: '150',
    surface: Surface.MB_C,
};

export const wearResistantPlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'wearResistantPlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.WEAR_RESISTANT,
    grade: Grade.G_500HB,
    finish: Finish.SHOTBLASTED,
};

export const highStrengthStructuralPlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'highStrengthStructuralPlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.HIGH_STRENGTH_STRUCTURAL,
    grade: Grade.C22,
    specification: Specification.NL,
    finish: Finish.SHOTBLASTED,
};

export const structuralSteelPlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'structuralSteelPlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.STRUCTURAL_STEEL,
    grade: Grade.S355,
    specification: Specification.J2,
    finish: Finish.SHOTBLASTED,
};

export const mediumCarbonSteelPlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'mediumCarbonSteelPlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.MEDIUM_CARBON_STEEL,
    grade: Grade.C45E,
    specification: Specification.PLUS_S,
    finish: Finish.SHOTBLASTED,
};

export const offshorePlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'offshorePlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.OFFSHORE,
    grade: Grade.S420,
    specification: Specification.G8,
    finish: Finish.SHOTBLASTED,
};

export const boilerPressureVesselPlateMaterialModelMock: ApiModel<AnyMaterialModel> = {
    id: 'boilerPressureVesselPlateMaterialModelMock',
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.PLATE,
    tolerance: Tolerance.EU_NORM,
    plateType: ProductType.BOILER_PRESSURE_VESSEL,
    grade: Grade.A516_GR60,
    finish: Finish.SHOTBLASTED,
};

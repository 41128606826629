import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { ListingBuyerSearchDataProvider } from '@steelbuy/data-provider';
import { useRerenderFromNavbar } from '@steelbuy/util';
import { CheckoutContextProvider } from '../../components/checkout/CheckoutContext';
import { SearchFormDataContextProvider } from '../../components/listing-search/SearchFormDataContext';
import { SearchResultsContextProvider } from '../../components/listing-search/SearchResultsContext';

export const SearchListingsView = () => {
    const rerenderKey = useRerenderFromNavbar();

    return (
        <Fragment key={rerenderKey}>
            <ListingBuyerSearchDataProvider lazyLoad>
                <SearchFormDataContextProvider>
                    <CheckoutContextProvider>
                        <SearchResultsContextProvider>
                            <Outlet />
                        </SearchResultsContextProvider>
                    </CheckoutContextProvider>
                </SearchFormDataContextProvider>
            </ListingBuyerSearchDataProvider>
        </Fragment>
    );
};

import { useTranslation } from 'react-i18next';
import { CardContentLabelValues } from '@steelbuy/ui-primitive';
import { formatDate, formatDayname } from '@steelbuy/util';

export const CardContentQuoteDeadline = ({ deadline }: { deadline: string }) => {
    const { t } = useTranslation('translation');
    const date = new Date(Number(deadline));
    const labelValues = [
        {
            label: t('application.createRFQ.quoteDeadline.subHeader'),
            value: `${formatDayname(navigator.language, date)}, ${formatDate(navigator.language, date)}`,
        },
    ];

    return <CardContentLabelValues title={t('application.createRFQ.quoteDeadline.header')} labelValues={labelValues} />;
};

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MY_CART_DETAILS, useCartDetails, useDeleteCartEntry, useUpdateCartNotification } from '@steelbuy/data-access';
import { CartBuyerModel } from '@steelbuy/domain-model';
import { ListingBuyerTeaser, MyCartWeightType } from '@steelbuy/ui-domain';
import { Card, LoadingSpinner, toaster, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { MyCartEmptyState } from './MyCartEmptyState';
import { useLoadingSpinnerById } from '../../hooks/useLoadingSpinnerById';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { ActFastNotification } from '../act-fast-notification/ActFastNotification';
import { PageHeader } from '../page-header/PageHeader';
import './MyCart.scss';

export const MyCart = () => {
    const { t } = useTranslation('translation');
    const [showUpdatedCartNotification, setShowUpdatedCartNotification] = useState(false);
    const { data, isLoading, refetch } = useCartDetails();
    const { mutate: updateCartNotification } = useUpdateCartNotification();
    const queryClient = useQueryClient();
    const { idsMutating, addLoadingState, removeLoadingState } = useLoadingSpinnerById();
    const navigate = useNavigate();
    const checkout = () => {
        navigate(RoutePath.MY_CART_CHECKOUT);
    };

    const { mutate } = useDeleteCartEntry({
        onSuccess: (_, id) => {
            removeLoadingState(id);
            queryClient.setQueryData<CartBuyerModel>([MY_CART_DETAILS], (cache) => {
                if (!cache) {
                    return cache;
                }
                return { ...cache, cartEntries: cache.cartEntries.filter((entry) => entry.id !== id) };
            });
            refetch();
            toaster.success(t('application.cart.entrySuccessMessage'));
        },
        onError: (_, id) => {
            removeLoadingState(id);
            toaster.warn(t('application.cart.entryErrorMessage'));
        },
    });

    const handleDelete = (id: string) => {
        addLoadingState(id);
        mutate(id);
    };

    useEffect(() => {
        if (data?.errors?.code === 2001 || data?.errors?.code === 2002) {
            setShowUpdatedCartNotification(true);
            updateCartNotification({
                cartId: data.errors.cartId || data.id,
                entryIds: data?.errors.entryIds,
            });
        }
    }, [data]);

    return (
        <div className="my-cart-list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader pageTitle={t('application.myCart.header')} />
                    <TableLayout>
                        {showUpdatedCartNotification && (
                            <Notification
                                level={NotificationLevel.WARNING}
                                message={t('application.myCart.itemsExpiredOrSold')}
                                className="my-cart-list__updated-notification"
                                closeButton
                            />
                        )}
                        <ActFastNotification expiresAt={data?.cartEntries?.length ? data.expiresAt.value : undefined} />
                        {data?.cartEntries?.length ? (
                            <div className="my-cart-list__container">
                                <div className="my-cart-list__container__listing-teaser">
                                    {data.cartEntries.map((entry) => {
                                        const isPackage = entry.productType === 'PACKAGE';
                                        return (
                                            <Card
                                                key={entry.id}
                                                isClickable
                                                url={createRouteUrl(
                                                    isPackage
                                                        ? RoutePath.MY_CART_PACKAGE_DETAILS
                                                        : RoutePath.MY_CART_LISTING_DETAILS,
                                                    [isPackage ? 'packageId' : 'listingId', entry.product.id]
                                                )}
                                            >
                                                {isPackage ? (
                                                    <ListingBuyerTeaser
                                                        listing={entry.product}
                                                        isCart
                                                        isPackage
                                                        onRemoveClick={() => handleDelete(entry.id)}
                                                        isLoading={idsMutating.includes(entry.id)}
                                                    />
                                                ) : (
                                                    <ListingBuyerTeaser
                                                        listing={entry.product}
                                                        isCart
                                                        onRemoveClick={() => handleDelete(entry.id)}
                                                        isLoading={idsMutating.includes(entry.id)}
                                                    />
                                                )}
                                            </Card>
                                        );
                                    })}
                                </div>
                                <MyCartWeightType
                                    typeWithWeight={data.typeWithWeight}
                                    noOfEntries={data.cartEntries.length}
                                    totalValue={data.totalValue.value}
                                    currencyCode={data.currencyCode}
                                    navigateCheckout={checkout}
                                />
                            </div>
                        ) : (
                            <div className="my-cart-list__empty-state-container">
                                <MyCartEmptyState />
                            </div>
                        )}
                    </TableLayout>
                </>
            )}
        </div>
    );
};

import { Trans, useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { GenericError } from '../generic-error/GenericError';
import { PageHeader } from '../page-header/PageHeader';

const HelperComponent = ({
    translate,
    mailto,
}: {
    translate: 'application.checkout.error.retryMessage' | 'application.checkout.error.contactMessage';
    mailto: string;
}) => {
    const { t } = useTranslation('translation');
    return (
        <Trans
            i18nKey={translate}
            components={{
                link1: <CustomLink isInlineLink isAnchorLink path={mailto} />,
            }}
            t={t}
        />
    );
};

type CheckoutErrorLayoutProps = {
    onBack: () => void;
    handleRetry: () => void;
    retryCount: number;
    checkoutPath: string;
};

export const CheckoutErrorLayout = ({ onBack, handleRetry, retryCount, checkoutPath }: CheckoutErrorLayoutProps) => {
    const { t } = useTranslation('translation');

    const mailto = `mailto: ${t('constants.emailSupport')}`;

    return (
        <>
            <PageHeader pageTitle={t('application.checkout.error.pageTitle')} showCartIcon={false} />
            {retryCount < 3 ? (
                <GenericError
                    primaryButton={{
                        label: t('application.checkout.error.retry'),
                        onClick: handleRetry,
                    }}
                    HelperComponent={
                        <HelperComponent translate="application.checkout.error.retryMessage" mailto={mailto} />
                    }
                    showSecondary={false}
                    link={{
                        path: checkoutPath,
                        text: t('application.checkout.error.link'),
                        onClick: onBack,
                    }}
                />
            ) : (
                <GenericError
                    primaryButton={{
                        label: t('application.checkout.error.contactSupport'),
                        onClick: () => {
                            window.location.href = mailto;
                        },
                    }}
                    HelperComponent={
                        <HelperComponent translate="application.checkout.error.contactMessage" mailto={mailto} />
                    }
                    showSecondary={false}
                    link={{
                        path: checkoutPath,
                        text: t('application.checkout.error.link'),
                        onClick: onBack,
                    }}
                />
            )}
        </>
    );
};

import { RFQBuyerModelConverter, RFQBuyerModel } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MY_RFQ_KEY = 'quotes';

const modelConverter = new RFQBuyerModelConverter();
const searchListingMapFn = (data: RFQBuyerModel) =>
    modelConverter.toViewModel(data as unknown as ApiModel<RFQBuyerModel>);

export const useMyRFQs = ({ filterCriteria }: { filterCriteria?: Record<string, string> }) =>
    usePaginatedQuery<RFQBuyerModel>({
        url: '/api/buyer/quotes',
        requestOptions: {
            filter: filterCriteria,
        },
        mapFn: searchListingMapFn,
        queryKey: [MY_RFQ_KEY, filterCriteria],
        queryOptions: {
            cacheTime: 0,
        },
    });

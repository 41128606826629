import { useTranslation } from 'react-i18next';
import { ListingDetailsExpirationView } from '@steelbuy/ui-domain';
import {
    FormActionbar,
    ButtonCallToAction,
    ButtonPrimary,
    IconIdentifier,
    ButtonTertiaryOnLightM,
    ButtonSecondary,
    LoadingStatus,
} from '@steelbuy/ui-primitive';

type SellerActionCtaProps = {
    isRejected?: boolean;
    isExpired: boolean;
    detailsExpirationViewMode: ListingDetailsExpirationView;
    createSameListing: () => void;
    hasSellerRole: boolean;
    editRejectedListing?: () => Promise<void>;
    actionStatus?: LoadingStatus;
    isRenewable: boolean;
    renewExpiredListing: () => void;
    isPublishDisabled: () => boolean;
    isDirtyFields: boolean;
    resetFields: () => void;
    updateAutoRenewFields: () => Promise<void>;
    checkBoxChecked: boolean;
};

export const SellerActionCta = ({
    isRejected,
    isExpired,
    detailsExpirationViewMode,
    createSameListing,
    hasSellerRole,
    editRejectedListing,
    actionStatus,
    isRenewable,
    renewExpiredListing,
    isPublishDisabled,
    isDirtyFields,
    resetFields,
    updateAutoRenewFields,
    checkBoxChecked,
}: SellerActionCtaProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <FormActionbar>
            {!isRejected && !isExpired && detailsExpirationViewMode !== ListingDetailsExpirationView.LIVE_EDITABLE && (
                <ButtonCallToAction
                    label={t('translation:application.sellerListingDetails.createSameButtonLabel')}
                    onClick={createSameListing}
                />
            )}
            {isRejected && hasSellerRole && (
                <ButtonPrimary
                    label={t('uiDomain:common.edit')}
                    icon={IconIdentifier.EDIT}
                    onClick={editRejectedListing}
                    loadingStatus={actionStatus}
                />
            )}
            {isRenewable && (
                <>
                    <ButtonTertiaryOnLightM
                        label={t('translation:application.sellerListingDetails.createSameButtonLabel')}
                        onClick={createSameListing}
                    />
                    <ButtonCallToAction
                        label={t('translation:application.sellerListingDetails.renewButtonLabel')}
                        onClick={renewExpiredListing}
                        disabled={isPublishDisabled()}
                    />
                </>
            )}
            {detailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE && (
                <>
                    <ButtonTertiaryOnLightM
                        label={t('translation:application.sellerListingDetails.createSameButtonLabel')}
                        onClick={createSameListing}
                    />
                    <ButtonSecondary
                        disabled={!isDirtyFields}
                        label={t('uiDomain:common.cancel')}
                        onClick={resetFields}
                    />
                    <ButtonCallToAction
                        label={t('translation:application.sellerListingDetails.save')}
                        onClick={() => {
                            updateAutoRenewFields().catch(() => {
                                // failed to update
                            });
                        }}
                        disabled={!isDirtyFields || !checkBoxChecked}
                    />
                </>
            )}
        </FormActionbar>
    );
};

import { MaterialProperties } from './material-taxonomy/MaterialProperties';
import { isTwoSidedMaterial, shouldDisplayCoatingThicknessUnit } from './MaterialModel.guards';
import { getCoatingThicknessUnit } from './MaterialModel.maps';

export const getCoatingThicknessText = (material: MaterialProperties, showUnit = true) => {
    if (material.coatingThicknessValue) {
        const { coatingThicknessValue } = material;
        let coatingThickness = isTwoSidedMaterial(material)
            ? `${coatingThicknessValue}/${coatingThicknessValue}`
            : coatingThicknessValue;
        if (showUnit && shouldDisplayCoatingThicknessUnit(material)) {
            coatingThickness += getCoatingThicknessUnit(material);
        }
        return coatingThickness;
    }
    return undefined;
};

export const getCoatingThicknessLabelSuffix = (material: MaterialProperties) =>
    shouldDisplayCoatingThicknessUnit(material) ? ` (${getCoatingThicknessUnit(material)})` : '';

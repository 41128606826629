export const removeWhitespace = (string: string): string => string.replace(/\s/g, '');

export const removeNonNumericChars = (string: string): string => string.replace(/\D/g, '');

export const trimFromRight = (str: string, char: string): string => {
    let string = str;
    while (string.endsWith(char)) {
        string = string.slice(0, -1 * char.length);
    }
    return string;
};

export const trimFromLeft = (str: string, char: string): string => {
    let string = str;
    while (string.startsWith(char)) {
        string = string.slice(char.length);
    }
    return string;
};

export const camelCase = (string: string): string => {
    const stringFragments = string
        .toLowerCase()
        .split(/[\t\n\s,\-_]+/)
        .filter((fragment): boolean => fragment.length > 0)
        .map((fragment): string => fragment[0].toUpperCase() + (fragment.substring(1) ?? ''));
    const combined = stringFragments.join('');
    return (combined[0]?.toLowerCase() ?? '') + (combined.substring(1) ?? '');
};

export const isString = (value: unknown): value is string => typeof value === 'string' || value instanceof String;

export const replaceVariables = (input: string, variables?: Record<string, string>): string =>
    Object.entries(variables ?? {}).reduce((prev, cur) => prev.replace(`{${cur[0]}}`, cur[1]), input);

/**
 * Creates a random string from the charset '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/'
 * @param length The length of the string to be created.
 * @returns
 */
export const randomString = (length: number): string => {
    if (length <= 0) {
        return '';
    }

    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset.charAt(randomIndex);
    }

    return result;
};

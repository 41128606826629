export enum OrganisationMarketSector {
    SERVICE_CENTRES = 'SERVICE_CENTRES',
    STOCKISTS = 'STOCKISTS',
    HVAC = 'HVAC',
    ENCLOSURES = 'ENCLOSURES',
    SCREENS_CRUSHERS = 'SCREENS_CRUSHERS',
    YELLOW_GOODS = 'YELLOW_GOODS',
    BUCKET_ATTACHMENTS = 'BUCKET_ATTACHMENTS',
    WASTE_HANDLING = 'WASTE_HANDLING',
    VEHICLE_BODIES = 'VEHICLE_BODIES',
    TANKS_SILOS = 'TANKS_SILOS',
    LASER_CUTTERS = 'LASER_CUTTERS',
    PLOYTUNNEL = 'PLOYTUNNEL',
    AGRICULTURAL_MACHINERY = 'AGRICULTURAL_MACHINERY',
    COMMERCIAL_AGRICULTURAL_TRAILERS = 'COMMERCIAL_AGRICULTURAL_TRAILERS',
    TIPPER_BODIES = 'TIPPER_BODIES',
    SUB_CONTRACT_MANUFACTURING = 'SUB_CONTRACT_MANUFACTURING',
}

export enum PaymentTerms {
    NET_30_DAYS = 'NET_30_DAYS',
    NET_60_DAYS = 'NET_60_DAYS',
}

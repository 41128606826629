import { useTranslation } from 'react-i18next';
import { RFQStatus } from '@steelbuy/domain-model';

import { Dropdown, SelectOption } from '@steelbuy/ui-primitive';
import { useMyRFQsContext } from './MyRFQsContext';

export const FILTER_CRITERIA_ID = 'filter';
export const FILTER_PROPERTY = 'status.value';

export const MyRFQsFilter = () => {
    const { t } = useTranslation('translation');
    const { myRFQFilter, setMyRFQFilter } = useMyRFQsContext();

    const filterOptions: SelectOption[] = Object.values(RFQStatus).map((value) => ({
        label: t(`application.myRFQFilter.optionLabels.${value}`),
        value,
    }));

    const handleFilterChange = (value: string[]) => {
        setMyRFQFilter(value as RFQStatus[]);
    };

    return (
        <Dropdown
            multiselect
            title={t('application.myRFQFilter.selectLabel')}
            options={filterOptions}
            preselection={myRFQFilter}
            onChange={handleFilterChange}
            placeholder={t('application.myRFQFilter.selectPlaceholder')}
        />
    );
};

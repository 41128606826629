import { useTranslation } from 'react-i18next';
import { useUserSelf } from '@steelbuy/data-provider';
import {
    ListingSellerDraftModel,
    MaterialProperties,
    PackageSellerDraftModel,
    UserRole,
    getPricingUnit,
} from '@steelbuy/domain-model';
import { createDateFromTimestamp } from '@steelbuy/domain-model-types';
import { BadgeVariant, ButtonColor, ButtonSize, ButtonTertiary } from '@steelbuy/ui-primitive';

import { formatDateTime } from '@steelbuy/util';
import { ListingTeaser } from './ListingTeaser';

import './ListingTeaser.scss';
import { ListingTeaserContainer } from './ListingTeaserContainer';
import { ListingTeaserHeader } from './ListingTeaserHeader';
import { ListingTeaserPrice } from './ListingTeaserPrice';
import { ListingTeaserWeightAndItems } from './ListingTeaserWeightAndItems';
import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';
import { PackageTeaserHeader } from '../package-teaser/PackageTeaserHeader';

export type ListingDraftTeaserProps = {
    onDelete: (draftId: string) => void;
    onEdit: (draftId: string) => void;
} & ({ isPackage: true; draft: PackageSellerDraftModel } | { isPackage?: false; draft: ListingSellerDraftModel });

type ListingTeaserButtonOnlyProps = {
    children: React.ReactNode;
};

const ListingTeaserButtonOnly = ({ children }: ListingTeaserButtonOnlyProps) => (
    <div className="listing-teaser__price listing-teaser__price--buttons-only">
        <div className="listing-draft-teaser-buttons">{children}</div>
    </div>
);

type ListingButtonsType = {
    handleEdit: (e: React.MouseEvent) => void;
    handleDelete: (e: React.MouseEvent) => void;
};

const ListingButtons = ({ handleEdit, handleDelete }: ListingButtonsType) => {
    const { t } = useTranslation('uiDomain');

    const userData = useUserSelf().get();
    const hasDeletePermissions =
        userData?.roles?.includes(UserRole.SELLER) || userData?.roles?.includes(UserRole.MANAGER);

    return (
        <>
            <ButtonTertiary
                size={ButtonSize.S}
                color={ButtonColor.DARK}
                label={t('common.edit')}
                onClick={handleEdit}
            />
            {hasDeletePermissions && (
                <ButtonTertiary size={ButtonSize.S} label={t('common.delete')} onClick={handleDelete} />
            )}
        </>
    );
};

export const ListingDraftTeaser = (props: ListingDraftTeaserProps) => {
    const { draft, onDelete, onEdit, isPackage } = props;
    const { t } = useTranslation('uiDomain');
    const numberOfItems = useNumberOfItems(
        !isPackage ? draft.material?.product : undefined,
        !isPackage ? draft.numberOfItems : undefined
    );

    const statusDate = draft.modifiedAt
        ? formatDateTime(navigator.language, createDateFromTimestamp(draft.modifiedAt))
        : undefined;

    const pricingUnit = getPricingUnit(
        (isPackage ? { materialType: draft.material } : draft.material) as MaterialProperties
    );

    const handleDelete = (e: React.MouseEvent) => {
        e.preventDefault();
        onDelete(draft.id);
    };
    const handleEdit = (e: React.MouseEvent) => {
        e.preventDefault();
        onEdit(draft.id);
    };

    return (
        <ListingTeaser>
            {isPackage ? <PackageTeaserHeader packages={draft} /> : <ListingTeaserHeader listing={draft} />}
            <ListingTeaserContainer
                isPrime={draft.prime !== false}
                sellerMaterialId={draft.internalSellerSku}
                lastModified={t('listingDraftTeaser.lastEdited') + (statusDate ? `: ${statusDate}` : '')}
                statusLabel="Draft"
                statusBadgeVariant={BadgeVariant.INFO}
                hideStatusNonMaxSize
            />
            {draft.weight && draft.tradeUnit ? (
                <ListingTeaserWeightAndItems
                    weight={draft.weight}
                    unit={draft.tradeUnit}
                    numberOfItems={numberOfItems}
                />
            ) : (
                <div />
            )}
            {draft.pricing?.netPricePerUnit !== undefined && pricingUnit && draft.pricing.currencyCode ? (
                <ListingTeaserPrice
                    value={draft.pricing.netPricePerUnit}
                    currencyCode={draft.pricing.currencyCode}
                    unit={pricingUnit}
                >
                    <div className="listing-draft-teaser-buttons">
                        <ListingButtons handleEdit={handleEdit} handleDelete={handleDelete} />
                    </div>
                </ListingTeaserPrice>
            ) : (
                <ListingTeaserButtonOnly>
                    <ListingButtons handleEdit={handleEdit} handleDelete={handleDelete} />
                </ListingTeaserButtonOnly>
            )}
        </ListingTeaser>
    );
};

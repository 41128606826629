import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManageOfferListings } from '@steelbuy/data-access';
import { EmptyState, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { useManageOffersContext } from './ManageOffersContext';
import { ManageOfferList } from './ManageOffersList';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { GenericError } from '../generic-error/GenericError';
import { PageHeader } from '../page-header/PageHeader';
import './ManageOffers.scss';

export const ManageOffers = () => {
    const { setScrollPosition, scrollPosition, filter } = useManageOffersContext();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { data, hasNextPage, total, fetchNextPage, isFetchingNextPage, isLoading, error } = useManageOfferListings({
        filter,
    });

    useEffect(() => {
        scrollMainLayout(0, scrollPosition);
        setScrollPosition(0);
    }, []);

    return (
        <div className="manage-offers">
            <PageHeader pageTitle={t('application.manageOffers.pageHeader')} />
            {error ? (
                <GenericError />
            ) : (
                <TableLayout>
                    <Notification
                        level={NotificationLevel.INFO}
                        message={t('application.manageOffers.infoMsg')}
                        stayOpen
                    />
                    <ManageOfferList
                        results={data || []}
                        totalResults={total}
                        handleLoadMore={fetchNextPage}
                        hasNext={hasNextPage}
                        isLoading={isLoading}
                        isFetchingNextPage={isFetchingNextPage}
                    />
                    {!isLoading && !data?.length && (
                        <div className="manage-offers__empty">
                            <EmptyState
                                caption={t('uiDomain:commonList.noListCaption')}
                                imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                                description={t('application.manageOffers.noResultsText')}
                            />
                        </div>
                    )}
                </TableLayout>
            )}
        </div>
    );
};

import { useMutation } from '@tanstack/react-query';
import { CurrencyCode } from '@steelbuy/currency';
import axios from '../../axios';

type UpdateRequest = {
    autoRenew: {
        enabled: boolean;
        expiresAt?: Date;
        price?: number;
    };
};
type AutoRenewalRequest = {
    pricing: {
        netPricePerUnit?: number;
        currencyCode: CurrencyCode;
    };
    expiresAt: string;
    autoRenew: {
        enabled: boolean;
    };
};

type UpdatePackageModel =
    | {
          isRenewal?: false;
          id: string;
          packageListingRequest: UpdateRequest;
      }
    | {
          id: string;
          isRenewal: true;
          packageListingRequest: AutoRenewalRequest;
      };

const updatePackage = async (updatePackageListingRequest: UpdatePackageModel) => {
    const { data } = await axios.put<UpdatePackageModel>(
        `/api/seller/packages/${updatePackageListingRequest.id}${
            updatePackageListingRequest.isRenewal ? '/renewal' : ''
        }`,
        updatePackageListingRequest.packageListingRequest
    );
    return data;
};

export const useUpdatePackage = () =>
    useMutation({
        mutationFn: (updatePackageListingRequest: UpdatePackageModel) => updatePackage(updatePackageListingRequest),
    });

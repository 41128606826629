/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { ButtonIcon } from '../../button-icon/ButtonIcon';
import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';
import { LoadingSpinner } from '../../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../../loading-spinner/LoadingSpinner.enums';

import './InputFileItem.scss';

export enum FileUploadStatus {
    Uploading = 'Uploading',
    Success = 'Success',
    Error = 'Error',
}

export type InputFileItemProps = {
    label: string;
    status: FileUploadStatus;
    onRemoveFile: (filename: string) => void;
    onOpenFile: (filename: string) => void;
    errorMessage?: string;
    sizeAndStatus: string;
};

const UploadStatusIcon = ({ status, onRemoveFile }: { status: FileUploadStatus; onRemoveFile: () => void }) => {
    switch (status) {
        case FileUploadStatus.Error:
            return (
                <>
                    <div className="error-icon">
                        <Icon name={IconIdentifier.ERROR} />
                    </div>
                    <ButtonIcon onClick={onRemoveFile}>
                        <Icon name={IconIdentifier.CLOSE} />
                    </ButtonIcon>
                </>
            );
        case FileUploadStatus.Uploading:
            return <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />;
        default:
            return (
                <ButtonIcon onClick={onRemoveFile}>
                    <Icon name={IconIdentifier.CLOSE} />
                </ButtonIcon>
            );
    }
};

export const InputFileItem = (props: InputFileItemProps) => {
    const { label, onRemoveFile, onOpenFile, status, errorMessage, sizeAndStatus } = props;
    const isError = status === FileUploadStatus.Error;
    return (
        <div className={classNames('input-file__item', { 'input-file__item__error': isError })}>
            <div
                className={classNames('input-file__item__inner', {
                    'input-file__item__inner__error': isError,
                })}
            >
                <div
                    className="input-file__item__inner__file"
                    onClick={() => {
                        onOpenFile(label);
                    }}
                >
                    <Icon name={IconIdentifier.FILE} />
                    <div className="input-file__item__inner__file__label">
                        <span className="input-file__item__inner__file__label__filename">{label}</span>
                        <span className="input-file__item__inner__file__label__filesize">{sizeAndStatus}</span>
                    </div>
                </div>
                <span className="input-file__item__inner__actions">
                    <UploadStatusIcon status={status} onRemoveFile={() => onRemoveFile(label)} />
                </span>
            </div>
            {isError && errorMessage && <span className="input-file__item__error-text">{errorMessage}</span>}
        </div>
    );
};

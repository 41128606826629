import { useTranslation } from 'react-i18next';
import { CardListingViewRejectionReason } from '@steelbuy/ui-primitive';

export const ListingsRejectionCard = ({
    reason,
    internalSellerSku,
    subject,
}: {
    reason: string;
    internalSellerSku: string;
    subject?: string;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain', 'domainModel']);
    const contactSupportAboutRejection = () => {
        const listingSubject = t('translation:application.sellerListingDetails.rejectionReason.emailSubject', {
            sellerSku: internalSellerSku,
        });
        window.location.href = `mailto:support@steel-buy.com?subject=${encodeURIComponent(subject ?? listingSubject)}`;
    };

    return (
        <CardListingViewRejectionReason
            header={t('translation:application.sellerListingDetails.rejectionReason.title')}
            description={t('translation:application.sellerListingDetails.rejectionReason.description')}
            buttonLabel={t('translation:application.sellerListingDetails.rejectionReason.buttonLabel')}
            rejectionReason={reason}
            onButtonClick={contactSupportAboutRejection}
        />
    );
};

import { Product } from '../../../MaterialModel.enums';

export const stainlessFlatProduct = [
    Product.COIL,
    Product.SHEET,
    Product.PLATE,
    Product.TREAD,
    Product.PACKAGE,
] as const;

export type StainlessFlatProduct = typeof stainlessFlatProduct[number];

import { useFooCreateEntityData } from '@steelbuy/data-provider';
import { FooEntityCreation } from '@steelbuy/ui-domain';

export const FooCreateEntity = () => {
    const fooEntity = useFooCreateEntityData();
    const fooModel = fooEntity.query().get();

    return (
        <div>
            <h1>Create foo</h1>
            <FooEntityCreation />
        </div>
    );
};

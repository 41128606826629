import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './MarketingBannerItem.scss';

export type MarketingBannerItemProps = {
    text: string;
};

export const MarketingBannerItem = (props: MarketingBannerItemProps) => {
    const { text } = props;

    return (
        <article className="marketing-banner-item">
            <Icon name={IconIdentifier.OK} />
            <span className="marketing-banner-item__text">{text}</span>
        </article>
    );
};

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { OfferPriceModelModelConverter, OfferPriceModel } from './OfferPriceModel';

export enum OfferRequestBy {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
}

export type OfferModel = {
    requestedBy: OfferRequestBy;
    createdAt: Timestamp;
    price: OfferPriceModel;
};

export class OfferModelConverter extends ModelConverter<OfferModel> {
    override getTimestampFields(): Array<TimestampFieldNames<OfferModel> | ArrayOfTimestampFieldNames<OfferModel>> {
        return ['createdAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<OfferModel> {
        return {
            price: new OfferPriceModelModelConverter(),
        };
    }
}

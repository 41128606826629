import { useContext } from 'react';

import { CustomError } from '@steelbuy/error';

import { FormContext } from './FormContext';
import { FormContextValue } from './FormContextValue';

export const useForm = (): FormContextValue => {
    const context = useContext(FormContext);
    if (context === null) {
        throw new CustomError('No form context available');
    }
    return context;
};

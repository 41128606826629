import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, InputTextfield, IconIdentifier, InputTextfieldProps } from '@steelbuy/ui-primitive';

import './InputPassword.scss';

export const InputPassword = (props: InputTextfieldProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation('uiDomain');

    return (
        <InputTextfield
            label={t('login.passwordLabel')}
            {...props}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <span className="end-adornment-wrapper">
                    <Icon
                        name={showPassword ? IconIdentifier.EYE_CLOSE : IconIdentifier.EYE}
                        onClick={() => setShowPassword((prev) => !prev)}
                    />
                </span>
            }
        />
    );
};

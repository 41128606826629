/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { FilterCriteria, SortCriteria, SortDirection } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';

import { AddressModel, AddressType } from '@steelbuy/domain-model';
import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { WarehouseAddressCollectionData } from './WarehouseAddressCollectionData';
import {
    WarehouseAddressCollectionStore,
    warehouseAddressCollectionStoreAccessors,
    warehouseAddressCollectionStoreName,
} from './WarehouseAddressCollectionSlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { createCollectionDataProviderValue } from '../../util/provider/CollectionDataProviderValue';
import { CollectionDataProviderProps } from '../../util/provider/DataProviderProps';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';
import { useUserSelf } from '../user-self-entity/UserSelfEntityDataConsumer';

type WarehouseAddressCollectionDataProviderProps = Omit<
    CollectionDataProviderProps<AddressModel>,
    'sortCriteria' | 'filterCriteria'
>;

export const WarehouseAddressCollectionDataProvider = (props: WarehouseAddressCollectionDataProviderProps) => {
    const { lazyLoad = false, interceptRendering = !lazyLoad, pendingComponent = null } = props;

    const PendingComponent = pendingComponent;

    const authConsumer = useAuth();
    const userSelf = useUserSelf();

    const dataProviderValue = createCollectionDataProviderValue<AddressModel, WarehouseAddressCollectionStore>(
        warehouseAddressCollectionStoreName,
        warehouseAddressCollectionStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl()),
        userSelf.get()?.roles
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        const warehouseFilter: FilterCriteria<AddressModel> = [
            {
                id: 'ADDRESS_TYPE_FILTER',
                criteria: [{ property: 'addressType', value: AddressType.WAREHOUSE }],
            },
        ];

        const sort: SortCriteria<AddressModel> = [
            { property: 'primary', direction: SortDirection.DESCENDING },
            { property: 'postalCode', direction: SortDirection.ASCENDING },
        ];

        if (dataProviderIdle && !lazyLoad) {
            dataProviderValue.fetch(sort, warehouseFilter);
        }
    }, [dataProviderIdle, lazyLoad]);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent !== null) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return (
        <WarehouseAddressCollectionData.Provider value={dataProviderValue}>
            {props.children}
        </WarehouseAddressCollectionData.Provider>
    );
};

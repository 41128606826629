import { ButtonTertiaryOnLightM, ButtonTertiaryOnLightMProps } from '@steelbuy/ui-primitive';
import { handleDownloadFile } from './HandleDownloadFile';

type DownloadButtonProps = {
    downloadUrl: string;
    fileName: string;
} & ButtonTertiaryOnLightMProps;

export const DownloadButton = ({ downloadUrl, fileName, ...props }: DownloadButtonProps) => (
    <ButtonTertiaryOnLightM onClick={() => handleDownloadFile(downloadUrl, fileName)} {...props} />
);

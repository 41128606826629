import { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutateUrgentAlertSettings } from '@steelbuy/data-access';
import { useUserSelf } from '@steelbuy/data-provider';
import { NotificationSettings, UrgentAlertFrequency } from '@steelbuy/domain-model';

import {
    NotificationLevel,
    FormItem,
    InputCheckbox,
    InputSelect,
    FormActionbar,
    ButtonPrimary,
    LoadingStatus,
    Notification,
} from '@steelbuy/ui-primitive';

type UrgentRequestsSettingsFormValues = {
    notificationsEnabled: boolean;
    frequency: UrgentAlertFrequency;
};

export const UrgentRequestsSettingsForm = ({
    notificationSettings,
}: {
    notificationSettings: NotificationSettings;
}) => {
    const {
        formState: { isDirty },
        control,
        handleSubmit,
        reset,
    } = useForm<UrgentRequestsSettingsFormValues>({
        mode: 'onBlur',
        defaultValues: {
            notificationsEnabled: notificationSettings.urgentAlerts.enabled,
            frequency: notificationSettings.urgentAlerts.frequency,
        },
    });

    const [showSaveSuccessNotification, setShowSaveSuccessNotification] = useState<boolean>(false);
    const urgentAlertsMutation = useMutateUrgentAlertSettings();
    const notificationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>();
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const userModel = useUserSelf().get();
    const frequencyOptions = Object.values(UrgentAlertFrequency).map((frequency) => ({
        label: t(`domainModel:notificationSettings.urgentAlerts.frequency.value.${frequency}`),
        value: frequency,
    }));

    const clearNotificationTimeout = () => {
        if (notificationTimeoutRef.current) {
            clearTimeout(notificationTimeoutRef.current);
            notificationTimeoutRef.current = null;
        }
    };

    const resetNotificationTimer = () => {
        if (notificationTimeoutRef.current) {
            clearNotificationTimeout();
            notificationTimeoutRef.current = setTimeout(() => setShowSaveSuccessNotification(false), 4000);
        }
    };

    const onFormSubmit = handleSubmit(async ({ notificationsEnabled, frequency }) => {
        urgentAlertsMutation.mutateAsync(
            { enabled: notificationsEnabled, frequency },
            {
                onSuccess: () => {
                    resetNotificationTimer();
                    setShowSaveSuccessNotification(true);
                    // reset isDirty from useForm to disable the submit button
                    reset({
                        notificationsEnabled,
                        frequency,
                    });
                },
            }
        );
    });

    useEffect(() => {
        if (showSaveSuccessNotification) {
            clearNotificationTimeout();
            notificationTimeoutRef.current = setTimeout(() => setShowSaveSuccessNotification(false), 4000);

            return () => clearNotificationTimeout();
        }
        return undefined;
    }, [showSaveSuccessNotification, setShowSaveSuccessNotification]);

    return (
        <form onSubmit={onFormSubmit}>
            {showSaveSuccessNotification && (
                <Notification
                    level={NotificationLevel.SUCCESS}
                    message={t('translation:application.urgentRequestsSettings.changesSavedNotificationMessage')}
                    stayOpen
                />
            )}
            <FormItem header={t('translation:application.urgentRequestsSettings.mailNotificationHeader')}>
                <span className="urgent-requests-settings-notification-hint">
                    {t('translation:application.urgentRequestsSettings.mailNotificationLabel', {
                        email: userModel?.email || '',
                    })}
                </span>
                <Controller
                    name="notificationsEnabled"
                    control={control}
                    render={({ field: { onChange, onBlur } }) => (
                        <InputCheckbox
                            onChange={onChange}
                            onBlur={onBlur}
                            value={t('translation:application.urgentRequestsSettings.receiveNotifications')}
                            defaultChecked={notificationSettings?.urgentAlerts.enabled}
                            data-testid="notifications-enabled-checkbox"
                        />
                    )}
                />
            </FormItem>
            <FormItem header={t('translation:application.urgentRequestsSettings.notificationFrequencyHeader')}>
                <span className="urgent-requests-settings-notification-hint">
                    {t('translation:application.urgentRequestsSettings.notificationFrequencyLabel')}
                </span>
                <Controller
                    name="frequency"
                    control={control}
                    render={({ field: { onChange } }) => (
                        <InputSelect
                            options={frequencyOptions}
                            onSelect={onChange}
                            defaultValue={notificationSettings?.urgentAlerts.frequency}
                            data-testid="frequency-select"
                        />
                    )}
                />
            </FormItem>
            <FormActionbar>
                <ButtonPrimary
                    label={t('uiDomain:common.saveChanges')}
                    loadingStatus={LoadingStatus.IDLE}
                    type="submit"
                    disabled={!isDirty}
                />
            </FormActionbar>
        </form>
    );
};

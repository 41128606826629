import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';

import { OfferListingStatus } from './ListingSellerModel.enums';
import { OfferModel, OfferModelConverter } from './OfferModel';
import { OfferOriginalPriceModel, OfferPriceModelConverter } from './OfferOriginalPriceModel';

export type NegotiationModel = {
    id: string;
    status: OfferListingStatus;
    referenceId: string;
    originalPrice: OfferOriginalPriceModel;
    offers: OfferModel[];
    isRead: boolean;
    expiresAt: string;
};

export class NegotiationModelConverter extends ModelConverter<NegotiationModel> {
    override getFieldConverterMapByModel(): FieldConverterMap<NegotiationModel> {
        return {
            originalPrice: new OfferPriceModelConverter(),
            offers: new OfferModelConverter(),
        };
    }
}

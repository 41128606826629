import { useTranslation } from 'react-i18next';
import {
    AddressCollectionDataProvider,
    OrganisationEntityDataProvider,
    UserCollectionDataProvider,
    useUserSelf,
} from '@steelbuy/data-provider';
import { CustomError } from '@steelbuy/error';
import { Account } from '../../components/account/Account';

export const AccountView = () => {
    const { t } = useTranslation('translation');
    const userModel = useUserSelf().getOrThrow(new CustomError(t('error.noUserError')));

    return (
        <OrganisationEntityDataProvider entityId={userModel.organisation.id}>
            <AddressCollectionDataProvider>
                <UserCollectionDataProvider lazyLoad>
                    <Account />
                </UserCollectionDataProvider>
            </AddressCollectionDataProvider>
        </OrganisationEntityDataProvider>
    );
};

import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { ButtonLoadingSpinner } from '../button-loading-spinner/ButtonLoadingSpinner';
import { LoadingStatus } from '../button-loading-spinner/LoadingStatus.enum';

import './ButtonCallToAction.scss';

export type ButtonCallToActionProps = {
    label: string;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonCallToAction = (props: ButtonCallToActionProps) => {
    const { label, type = 'button', loadingStatus = LoadingStatus.IDLE, ...rest } = props;

    const buttonCallToActionClassMap = {
        'button-call-to-action': true,
        'button-call-to-action__loading': loadingStatus === LoadingStatus.PENDING,
    };

    const buttonCallToActionLabelClassMap = {
        'button-call-to-action__label': true,
        'button-call-to-action__label--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    return (
        <button type={type} className={buildClassStringFromClassMap(buttonCallToActionClassMap)} {...rest}>
            <span className={buildClassStringFromClassMap(buttonCallToActionLabelClassMap)}>{label}</span>
            <ButtonLoadingSpinner loadingStatus={loadingStatus} />
        </button>
    );
};

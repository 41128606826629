import { Navigate, useLocation } from 'react-router-dom';
import {
    AddressCollectionDataProvider,
    ListingBuyerEntityDataProvider,
    OrderBuyerCreateEntityDataProvider,
} from '@steelbuy/data-provider';
import { CheckoutStatusGuard } from '../../components/checkout/CheckouStatusGuard';
import { useCheckoutContext } from '../../components/checkout/CheckoutContext';
import { createRouteUrl, RoutePath } from '../../router/Routes';

type CheckoutFormViewProps = {
    missingListingPath?: RoutePath;
    goBackPath?: RoutePath;
};

export const CheckoutFormView = ({
    goBackPath = RoutePath.SEARCH_LISTING_DETAILS,
    missingListingPath = RoutePath.SEARCH_LISTINGS,
}: CheckoutFormViewProps) => {
    const checkoutContext = useCheckoutContext();
    const location = useLocation();

    if (!checkoutContext.listingId) {
        return <Navigate to={missingListingPath} />;
    }

    const listingDetailsPath = createRouteUrl(location.state ?? goBackPath, ['listingId', checkoutContext.listingId]);

    return (
        <AddressCollectionDataProvider>
            <ListingBuyerEntityDataProvider entityId={checkoutContext.listingId} lazyLoad>
                <OrderBuyerCreateEntityDataProvider>
                    <CheckoutStatusGuard listingDetailPath={listingDetailsPath} />
                </OrderBuyerCreateEntityDataProvider>
            </ListingBuyerEntityDataProvider>
        </AddressCollectionDataProvider>
    );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionStatus, FetchStatus, useFeatureFlag, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';
import { Feature } from '@steelbuy/domain-model';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';

import { MyAlertsEmptyState, MyAlertsList, MyAlertsQnA } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    LoadingSpinner,
    Notification,
    NotificationLevel,
    ViewHeading,
    WizardNavigationButtons,
} from '@steelbuy/ui-primitive';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';

import { useSearchResultsContext } from '../listing-search/SearchResultsContext';
import { PageHeader } from '../page-header/PageHeader';

import './MyAlertsOverview.scss';

export const MyAlertsOverview = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const location = useLocation();
    const listingAlertCollectionData = useListingBuyerAlertCollectionData();
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const { reset } = useSearchResultsContext();
    const { isFeatureSupported } = useFeatureFlag();

    const alerts = listingAlertCollectionData.query();
    const totalNewResults = alerts.reduce((prev, cur) => prev + cur.numberOfNewListings, 0);

    // refresh collection every time the component mounts
    useEffect(() => {
        listingAlertCollectionData.resolveFetchStatus();
        reset();
    }, []);

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            setShowDeleteSuccess(true);
        },
        true
    );

    const navigateToAlertSettings = (alertId: ModelPrimaryKey) =>
        navigate(createRouteUrl(RoutePath.MY_ALERT_SETTINGS, ['alertId', alertId]));

    const pathToAlertSearchResults = (alertId: ModelPrimaryKey) =>
        createRouteUrl(RoutePath.MY_ALERTS_SEARCH, ['alertId', alertId]);

    if ([FetchStatus.IDLE, FetchStatus.PENDING].includes(listingAlertCollectionData.queryFetchStatus())) {
        return <LoadingSpinner />;
    }

    return (
        <div className="my-alerts-overview">
            <PageHeader pageTitle={t('application.myAlertsOverview.pageHeader')} />
            <div className="my-alerts">
                <TableLayout>
                    <div>
                        {showDeleteSuccess && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={t('application.myAlertsOverview.deleteSuccess')}
                            />
                        )}
                        {location.state?.successNotification && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={location.state.successNotification}
                            />
                        )}
                        {alerts.length > 0 && (
                            <>
                                <WizardNavigationButtons columns>
                                    <ViewHeading
                                        value={t('uiDomain:myAlertsList.newResultsSeparatorHeader', {
                                            count: totalNewResults,
                                        })}
                                    />
                                    <ButtonTertiaryOnLightM
                                        label={t('translation:application.myAlertsOverview.pageHeaderButton')}
                                        onClick={() => navigate(RoutePath.SEARCH_LISTINGS)}
                                    />
                                </WizardNavigationButtons>
                                <MyAlertsList
                                    alerts={alerts}
                                    navigateToAlertSettings={navigateToAlertSettings}
                                    pathToAlertSearchResults={pathToAlertSearchResults}
                                    showPrice={isFeatureSupported(Feature.IMR_TARGET_PRICE)}
                                />
                            </>
                        )}
                    </div>
                    {alerts.length === 0 && (
                        <FormLayout>
                            <MyAlertsEmptyState navigateToSearch={() => navigate(RoutePath.SEARCH_LISTINGS)} />
                        </FormLayout>
                    )}
                </TableLayout>
                <FormLayout>
                    <MyAlertsQnA />
                </FormLayout>
            </div>
        </div>
    );
};

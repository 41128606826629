import { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useListingBuyerAlertCollectionData, useUserSelf, ActionStatus } from '@steelbuy/data-provider';
import { ListingBuyerAlertModel } from '@steelbuy/domain-model';

import { useModal } from '@steelbuy/modal-dialog';
import { MyAlertsDeletionModal } from '@steelbuy/ui-domain';
import {
    NotificationLevel,
    FormItem,
    InputCheckbox,
    FormActionbar,
    ButtonTertiaryOnLightM,
    IconIdentifier,
    ButtonPrimary,
    LoadingStatus,
    Notification,
} from '@steelbuy/ui-primitive';

type AlertSettingsFormValues = {
    notificationsEnabled: boolean;
};

export const MyAlertsSettingsForm = (props: { listingAlert: ListingBuyerAlertModel }) => {
    const { listingAlert } = props;
    const { t } = useTranslation(['translation', 'uiDomain']);
    const listingAlertCollectionData = useListingBuyerAlertCollectionData();
    const userModel = useUserSelf().get();
    const deleteAlertModal = useModal();
    const actionStatus = listingAlertCollectionData.queryActionStatus();
    const [showSaveSuccessNotification, setShowSaveSuccessNotification] = useState<boolean>(false);
    const notificationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>();

    const {
        formState: { isDirty },
        control,
        handleSubmit,
        reset,
    } = useForm<AlertSettingsFormValues>({
        mode: 'onBlur',
        defaultValues: {
            notificationsEnabled: listingAlert?.enabled,
        },
    });

    const onFormSubmit = handleSubmit(async ({ notificationsEnabled }) => {
        listingAlertCollectionData.mutate(listingAlert.id, {
            enabled: notificationsEnabled,
        });
    });

    const clearNotificationTimeout = () => {
        if (notificationTimeoutRef.current) {
            clearTimeout(notificationTimeoutRef.current);
            notificationTimeoutRef.current = null;
        }
    };

    const resetNotificationTimer = () => {
        if (notificationTimeoutRef.current) {
            clearNotificationTimeout();
            notificationTimeoutRef.current = setTimeout(() => setShowSaveSuccessNotification(false), 4000);
        }
    };

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            listingAlertCollectionData.resolveFetchStatus();
            setShowSaveSuccessNotification(true);
            resetNotificationTimer();
            reset({
                notificationsEnabled: listingAlert?.enabled,
            });
        },
        true
    );

    // workaround to make the success notification work multiple times
    useEffect(() => {
        if (showSaveSuccessNotification) {
            clearNotificationTimeout();
            notificationTimeoutRef.current = setTimeout(() => setShowSaveSuccessNotification(false), 4000);

            return () => clearNotificationTimeout();
        }
        return undefined;
    }, [showSaveSuccessNotification, setShowSaveSuccessNotification]);

    return (
        <form onSubmit={onFormSubmit}>
            {showSaveSuccessNotification && (
                <Notification
                    level={NotificationLevel.SUCCESS}
                    message={t('application.myAlertsSettings.changesSavedNotificationMessage')}
                    stayOpen
                />
            )}
            <FormItem header={t('application.myAlertsSettings.mailNotificationHeader')}>
                <span className="my-alerts-settings-notification-hint">
                    {t('application.myAlertsSettings.mailNotificationLabel', {
                        email: userModel?.email || '',
                    })}
                </span>
                <Controller
                    name="notificationsEnabled"
                    control={control}
                    render={({ field: { onChange, onBlur } }) => (
                        <InputCheckbox
                            onChange={onChange}
                            onBlur={onBlur}
                            value={t('application.myAlertsSettings.receiveNotifications')}
                            defaultChecked={listingAlert.enabled}
                            data-testid="notifications-enabled-checkbox"
                        />
                    )}
                />
            </FormItem>
            <FormActionbar>
                <ButtonTertiaryOnLightM
                    label={t('application.myAlertsSettings.deleteButtonLabel')}
                    icon={IconIdentifier.BIN}
                    onClick={deleteAlertModal.show}
                />
                <ButtonPrimary
                    label={t('uiDomain:common.saveChanges')}
                    loadingStatus={
                        actionStatus === ActionStatus.MUTATE_PENDING ? LoadingStatus.PENDING : LoadingStatus.IDLE
                    }
                    type="submit"
                    disabled={!isDirty}
                />
            </FormActionbar>
            <MyAlertsDeletionModal
                modalAccessor={deleteAlertModal}
                handleDelete={() => {
                    deleteAlertModal.setActionPending(true);
                    listingAlertCollectionData.delete(listingAlert.id);
                }}
            />
        </form>
    );
};

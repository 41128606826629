import { ModelConverter, FieldConverterMap, ModelConverterInterface } from '@steelbuy/domain-model-converter';
import { ApiModel, ModelPrimaryKey, Timestamp } from '@steelbuy/domain-model-types';
import { ListingBuyerModel, ListingBuyerModelConverter } from './ListingBuyerModel';
import { PackageBuyerModel, PackageBuyerModelConverter } from './PackageBuyerModel';

export const isListingCartEntry = (entry: CartEntryModel | ApiModel<CartEntryModel>) => entry.productType === 'LISTING';

export type CartEntryBaseModel = {
    readonly id: ModelPrimaryKey;
    status: string;
    createdAt: Timestamp;
};

type CartEntryListingModel = {
    product: ListingBuyerModel;
    productType: 'LISTING';
};
type CartEntryPackageModel = {
    product: PackageBuyerModel;
    productType: 'PACKAGE';
};

type CartEntryModelFoundation = CartEntryListingModel | CartEntryPackageModel;

export type CartEntryModel = CartEntryBaseModel & CartEntryModelFoundation;

export class CartyEntryModelConverter extends ModelConverter<CartEntryModel> {
    override getFieldConverterMapByModel(
        model: CartEntryModel | ApiModel<CartEntryModel>
    ): FieldConverterMap<CartEntryModel> {
        if (isListingCartEntry(model)) {
            return {
                product: new ListingBuyerModelConverter() as ModelConverterInterface<ListingBuyerModel>,
            };
        }
        return {
            product: new PackageBuyerModelConverter() as ModelConverterInterface<PackageBuyerModel>,
        };
    }
}

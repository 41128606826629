import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfMonetaryAmountFieldNames,
    MonetaryAmount,
    MonetaryAmountFieldNames,
} from '@steelbuy/domain-model-types';

export type ListingBuyerPricingModel = {
    readonly priceIncludingDelivery: MonetaryAmount;
    readonly totalIncludingVat: MonetaryAmount;
    readonly totalExcludingVat: MonetaryAmount;
    // SUGGESTION: Move vat information to the order domain and respect according business rules to determine taxes
    readonly vat: MonetaryAmount;
    readonly taxPercent: number;
    readonly currencyCode: CurrencyCode;
};

export class ListingBuyerPricingModelConverter extends ModelConverter<ListingBuyerPricingModel> {
    override getMonetaryAmountFields(): Array<
        MonetaryAmountFieldNames<ListingBuyerPricingModel> | ArrayOfMonetaryAmountFieldNames<ListingBuyerPricingModel>
    > {
        return ['priceIncludingDelivery', 'totalIncludingVat', 'totalExcludingVat', 'vat'];
    }
}

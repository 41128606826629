import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { ROUTE_ACCOUNT_ORGANISATION } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountEditAddressNotFound = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                pageTitle={t('application.accountEditAddressNotFound.pageTitle')}
                previousPageTitle={t('application.accountEditAddressNotFound.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
            />

            <TableLayout>
                <Notification
                    level={NotificationLevel.WARNING}
                    message={t('application.accountEditAddressNotFound.notFoundMessage')}
                />
            </TableLayout>
        </>
    );
};

import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { useMemo } from 'react';
import { PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { entries } from '@steelbuy/util';
import { createFilterString } from './createFilterString';
import { createSortString } from './createSortString';
import { FilterComparator, FilterCriteriaEntry, ResponseModel } from './types';
import axios from '../axios';

interface QueryOptions<Model> {
    pageSize?: number;
    offset?: number;
    filter?: Record<string, any>;
    sort?: SortCriteria<Model> | PreparedSortCriteria;
    status?: string;
}

interface UsePaginatedOptions<Model, TError = unknown> {
    queryKey: QueryKey;
    url: string;
    requestOptions: QueryOptions<Model>;
    queryOptions?: Omit<
        UseInfiniteQueryOptions<ResponseModel<Model[]>, TError>,
        'queryKey' | 'queryFn' | 'getNextPageParam'
    >;
    mapFn?: (data: Model) => Model;
}
type PaginatedDataOptions = {
    url: string;
    pageParam: number;
};

type GenericPaginatedDataOptions<ViewModel, ApiModel> = {
    url: string;
    pageParam: number;
    mapFn: (data: ApiModel) => ViewModel;
};

const DEFAULT_PAGE_SIZE = 10;

const composeFilterCriteria = <Model>(data: Record<keyof Model, string | number>) =>
    entries(data)
        .filter(([_, value]) => typeof value !== 'undefined')
        .map(
            ([property, value]): FilterCriteriaEntry<Model> => ({
                property,
                comparator: FilterComparator.EQUAL,
                value,
            })
        );

const getPaginatedData = async <ViewModel, ApiModel = ViewModel>(
    options: QueryOptions<ViewModel> & (PaginatedDataOptions | GenericPaginatedDataOptions<ViewModel, ApiModel>)
): Promise<ResponseModel<ViewModel[]>> => {
    const { pageSize = DEFAULT_PAGE_SIZE, filter, sort, pageParam = 0, url, status } = options;
    const { data } = await axios.get<ResponseModel<ApiModel[]>>(url, {
        params: {
            limit: pageSize,
            offset: pageParam * pageSize,
            ...(filter && { filter: createFilterString(composeFilterCriteria(filter)) }),
            ...(sort && { sort: createSortString(sort) }),
            ...(status && { status }),
        },
    });

    if ('mapFn' in options && typeof options.mapFn === 'function') {
        const { items } = data;
        return {
            ...data,
            items: items.map(options.mapFn),
        };
    }
    return data as unknown as ResponseModel<ViewModel[]>;
};

export const usePaginatedQuery = <Model, TError = unknown>({
    queryKey,
    queryOptions,
    requestOptions,
    url,
    mapFn,
}: UsePaginatedOptions<Model, TError>) => {
    const { data: queryData, ...rest } = useInfiniteQuery<ResponseModel<Model[]>, TError>({
        ...queryOptions,
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey,
        queryFn: ({ pageParam }) =>
            getPaginatedData<Model>({
                ...requestOptions,
                pageParam,
                url,
                mapFn,
            }),
        getNextPageParam: ({ limit, offset, total }) =>
            offset + (requestOptions?.pageSize || DEFAULT_PAGE_SIZE) < total ? offset / limit + 1 : undefined,
    });
    const { data, total } = useMemo(
        () => ({
            data: queryData?.pages.flatMap((e) => e.items),
            total: queryData?.pages?.[0]?.total,
        }),
        [queryData]
    );

    return { ...rest, data, total };
};

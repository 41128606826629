import { SortDirection } from '@steelbuy/api-integration';
import { FooCollectionDataProvider } from '@steelbuy/data-provider';
import { FooCollection } from '@steelbuy/ui-domain';

export const FooCollectionView = () => (
    <FooCollectionDataProvider
        apiMock
        sortCriteria={[
            { property: 'name', direction: SortDirection.ASCENDING },
            { property: 'dateOfBirth', direction: SortDirection.DESCENDING },
        ]}
    >
        <FooCollection />
    </FooCollectionDataProvider>
);

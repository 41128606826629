import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { MaterialProperties } from './material-taxonomy/MaterialProperties';
import { AnyMaterialDimensionModel } from './MaterialDimensionModel';
import { StatusModelConverter } from './StatusModel';

export type DraftQuoteEntry = {
    id?: string;
    material: MaterialProperties;
    materialDimensions: Partial<AnyMaterialDimensionModel>;
    weight?: number;
};

export type RFQDraftDelivery = {
    month?: number;
    year?: number;
};

export type RFQDraftModel = {
    id: string;
    delivery: RFQDraftDelivery;
    origin: ListingMaterialOrigin[];
    additionalInformation?: string;
    status: {
        value: string;
        modifiedAt: Timestamp;
    };
    expiresAt?: Timestamp;
    quoteEntry: DraftQuoteEntry[];
    missingFieldsCount: number;
};

export class RFQDraftModelConverter extends ModelConverter<RFQDraftModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<RFQDraftModel> | ArrayOfTimestampFieldNames<RFQDraftModel>
    > {
        return ['expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<RFQDraftModel> {
        return {
            status: new StatusModelConverter(),
        };
    }
}

import { ApiMonetaryAmount, MonetaryAmount } from './MonetaryAmount';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMonetaryAmount = (monetaryAmount?: any): monetaryAmount is MonetaryAmount => {
    if ((monetaryAmount ?? null) === null) {
        return false;
    }
    return (
        (monetaryAmount as MonetaryAmount).discriminator === 'MonetaryAmount' &&
        (monetaryAmount as MonetaryAmount).value !== undefined &&
        typeof monetaryAmount.value === 'number'
    );
};

export const isApiMonetaryAmount = (apiMonetaryAmount?: unknown): apiMonetaryAmount is ApiMonetaryAmount => {
    if ((apiMonetaryAmount ?? null) === null) {
        return false;
    }
    return typeof apiMonetaryAmount === 'number';
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyState, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import './ListingNotFound.scss';

export const ListingNotFound = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const handleStartNewSearch = (): void => {
        navigate(RoutePath.SEARCH_LISTINGS);
    };

    return (
        <div className="listing-not-found">
            <TableLayout>
                <Notification
                    level={NotificationLevel.WARNING}
                    message={t('application.listingNotFound.notFoundMessage')}
                    closeButton
                />
                <EmptyState
                    imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                    description={t('application.listingNotFound.newSearchMessage')}
                    button={{
                        label: t('application.listingNotFound.newSearchButton'),
                        onClick: handleStartNewSearch,
                    }}
                />
            </TableLayout>
        </div>
    );
};

import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import {
    DocumentType,
    getTheoreticalWeight,
    getTradeUnitCalculation,
    ListingSellerDraftModel,
    ListingSellerModel,
    PackageSellerDraftModel,
    PackageSellerModel,
    Product,
    TradeUnitCalculation,
} from '@steelbuy/domain-model';
import { isApiUploadFile, ModelPrimaryKey, UploadFile } from '@steelbuy/domain-model-types';
import { CustomError } from '@steelbuy/error';
import { FileUploadStatus, WizardBarItemStatus } from '@steelbuy/ui-primitive';
import {
    draftToDimensionsStepData,
    draftToMaterialStepData,
    draftToPickupAddressStepData,
    draftToWeightPriceStepData,
    listingToDimensionsStepData,
    listingToMaterialStepData,
    listingToPickupAddressStepData,
    listingToWeightPriceStepData,
    packageDraftToPickupAddressStepData,
    packageToListUploadStepData,
    packageToMaterialStepData,
} from './CreateListingContextMapper';
import {
    allSteps,
    CreateListingContextValue,
    DEFAULT_DEFINITION,
    DEFAULT_MATERIAL_TYPE,
    DEFAULT_PRIME,
    DEFAULT_PRODUCT,
    DEFAULT_SHAPE,
    DimensionsStepData,
    GoBack,
    InitialCreateListingData,
    ListUploadStepData,
    MaterialStepData,
    PickupAddressStepData,
    Steps,
    WeightPriceStepData,
} from './CreateListingContextUtil';

const CreateListingContext = createContext<CreateListingContextValue | undefined>(undefined);

const removeFile = <T extends MaterialStepData | ListUploadStepData>(
    setData: Dispatch<SetStateAction<T>>,
    stateKey: keyof T,
    filename: string,
    setUnsavedChanges: Dispatch<SetStateAction<boolean>>
) => {
    setData((prevState) => {
        let updatedFiles = prevState[stateKey] ? [...(prevState[stateKey] as UploadFile[])] : [];
        const prevSize = updatedFiles.length;
        updatedFiles = updatedFiles.filter((file) => file.meta.fileName !== filename);
        if (updatedFiles.length !== prevSize) {
            setUnsavedChanges(true);
        }
        return { ...prevState, [stateKey]: updatedFiles };
    });
};

type FileStatusMap = Record<string, FileUploadStatus>;

export const CreateListingContextProvider = ({ children }: { children: ReactNode }) => {
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    const [draftId, setDraftId] = useState<ModelPrimaryKey>();
    const [goBack, setGoBack] = useState<GoBack>(null);
    const [rejectedListingId, setRejectedListingId] = useState<ModelPrimaryKey>();
    const [materialStepData, setMaterialStepData] = useState<MaterialStepData>({
        materialType: DEFAULT_MATERIAL_TYPE,
        prime: DEFAULT_PRIME,
        shape: DEFAULT_SHAPE,
        product: DEFAULT_PRODUCT,
        definition: DEFAULT_DEFINITION,
    });
    const [listUploadStepData, setListUploadStepData] = useState<ListUploadStepData>({});
    const [dimensionsStepData, setDimensionsStepData] = useState<DimensionsStepData>({});
    const [weightPriceStepData, setWeightPriceStepData] = useState<WeightPriceStepData>({
        autoRenew: true,
    });
    const [pickupAddressStepData, setPickupAddressStepData] = useState<PickupAddressStepData>({});
    const [stepStatus, setStepStatus] = useState<Record<string, WizardBarItemStatus>>({});
    const [currentStepId, setCurrentStepId] = useState<Steps>(allSteps[0]);
    const [certificatesStatus, setCertificatesStatus] = useState<FileStatusMap>({});
    const [picturesStatus, setPicturesStatus] = useState<FileStatusMap>({});
    const [packageStatus, setPackageStatus] = useState<FileStatusMap>({});
    const handleStepStatusChange = useCallback(
        (step: string, status: WizardBarItemStatus) => {
            if (status === stepStatus[step]) {
                return;
            }

            setStepStatus({
                ...stepStatus,
                [step]: status,
            });
        },
        [stepStatus, setStepStatus]
    );
    const getStepStatus = useCallback((stepId: string) => stepStatus[stepId], [stepStatus]);

    const reset = useCallback(() => {
        setUnsavedChanges(false);
        setCurrentStepId(allSteps[0]);
        setDraftId(undefined);
        setMaterialStepData({
            materialType: DEFAULT_MATERIAL_TYPE,
            prime: DEFAULT_PRIME,
            shape: DEFAULT_SHAPE,
            product: DEFAULT_PRODUCT,
            definition: DEFAULT_DEFINITION,
        });
        setDimensionsStepData({});
        setListUploadStepData({});
        setWeightPriceStepData({ autoRenew: true });
        setPickupAddressStepData({});
        setStepStatus({});
        setCertificatesStatus({});
        setPicturesStatus({});
        setPackageStatus({});
        setGoBack(null);
    }, []);

    const createSame = useCallback(() => {
        setUnsavedChanges(true);
        setCurrentStepId(allSteps[0]);
        setDraftId(undefined);
        setMaterialStepData({
            ...materialStepData,
            sku: undefined,
            testCertificate: undefined,
            picture: undefined,
        });
    }, [materialStepData]);

    const copyFromListing = useCallback((listing: ListingSellerModel) => {
        setUnsavedChanges(true);

        setMaterialStepData({
            ...listingToMaterialStepData(listing),
            sku: undefined,
            testCertificate: undefined,
            picture: undefined,
        });

        setDimensionsStepData(listingToDimensionsStepData(listing));
        setWeightPriceStepData(listingToWeightPriceStepData(listing));
        setPickupAddressStepData(listingToPickupAddressStepData(listing));
    }, []);

    const copyFromPackage = useCallback((packageListing: PackageSellerModel) => {
        setUnsavedChanges(true);

        setMaterialStepData({
            ...packageToMaterialStepData(packageListing),
            sku: undefined,
            testCertificate: undefined,
            picture: undefined,
        });
        setListUploadStepData({
            packageTitle: packageListing.packageTitle,
            packageDescription: packageListing.packageDescription,
        });
        setWeightPriceStepData(listingToWeightPriceStepData(packageListing));
        setPickupAddressStepData({
            ...listingToPickupAddressStepData(packageListing),
            deliveryTimeframe: packageListing.deliveryTimeframe,
        });
    }, []);

    const updateWeightPriceStepData = useCallback(
        (partialData: Partial<WeightPriceStepData>, ignoreUnsavedChanges?: boolean) => {
            if (!ignoreUnsavedChanges) {
                setUnsavedChanges(true);
            }
            setWeightPriceStepData((prevState) => {
                const updatedState = {
                    ...prevState,
                    ...partialData,
                };
                if (
                    Object.hasOwn(partialData, 'numberOfItems') &&
                    partialData.numberOfItems !== prevState.numberOfItems &&
                    getTradeUnitCalculation(materialStepData) === TradeUnitCalculation.BY_ITEM
                ) {
                    const weight = getTheoreticalWeight(
                        materialStepData,
                        dimensionsStepData,
                        partialData.numberOfItems
                    );
                    updatedState.weight = weight;
                }
                return updatedState;
            });
        },
        [materialStepData, dimensionsStepData]
    );

    const updateMaterialStepData = useCallback(
        (partialData: Partial<MaterialStepData>, ignoreUnsavedChanges?: boolean) => {
            const prevTradeUnitCalculation = getTradeUnitCalculation(materialStepData);
            if (!ignoreUnsavedChanges) {
                setUnsavedChanges(true);
            }
            setMaterialStepData((prevState) => {
                const updatedState = {
                    ...prevState,
                    ...partialData,
                };

                // if toggle to prime material, remove picture data
                if (prevState.prime === false && partialData.prime) {
                    delete updatedState.picture;
                    setPicturesStatus({});
                }
                // if toggle to package, remove dimension step data
                if (partialData.product) {
                    if (prevState.product !== Product.PACKAGE && partialData.product === Product.PACKAGE) {
                        setDimensionsStepData({});
                    } else if (prevState.product === Product.PACKAGE && partialData.product !== Product.PACKAGE) {
                        setListUploadStepData({});
                    }
                }
                const newTradeUnitCalculation = getTradeUnitCalculation(updatedState);
                if (newTradeUnitCalculation !== prevTradeUnitCalculation) {
                    let weight;
                    if (newTradeUnitCalculation === TradeUnitCalculation.BY_ITEM) {
                        weight = getTheoreticalWeight(
                            updatedState,
                            dimensionsStepData,
                            weightPriceStepData?.numberOfItems || 0
                        );
                    }
                    setWeightPriceStepData({ ...weightPriceStepData, weight });
                }
                return updatedState;
            });
        },
        [materialStepData, dimensionsStepData, weightPriceStepData]
    );

    const updateListUploadStepData = useCallback((partialData: Partial<ListUploadStepData>) => {
        setUnsavedChanges(true);
        setListUploadStepData((prevState) => ({
            ...prevState,
            ...partialData,
        }));
    }, []);

    const updateDimensionsStepData = useCallback(
        (partialData: Partial<DimensionsStepData>, ignoreUnsavedChanges?: boolean) => {
            if (!ignoreUnsavedChanges) {
                setUnsavedChanges(true);
            }
            setDimensionsStepData((prevState) => {
                const updatedState = { ...prevState, ...partialData };
                const tradeUnitCalculation = getTradeUnitCalculation(materialStepData);
                if (tradeUnitCalculation === TradeUnitCalculation.BY_ITEM) {
                    const weight = getTheoreticalWeight(
                        materialStepData,
                        updatedState,
                        weightPriceStepData?.numberOfItems
                    );
                    setWeightPriceStepData({ ...weightPriceStepData, weight });
                }
                return updatedState;
            });
        },
        [materialStepData, weightPriceStepData]
    );

    const updatePickupAddressStepData = useCallback(
        (partialData: Partial<PickupAddressStepData>, ignoreUnsavedChanges?: boolean) => {
            if (!ignoreUnsavedChanges) {
                setUnsavedChanges(true);
            }

            setPickupAddressStepData((prevState) => ({
                ...prevState,
                ...partialData,
            }));
        },
        []
    );

    const setInitialFormData = useCallback((initialFormData: InitialCreateListingData) => {
        reset();
        updateMaterialStepData(initialFormData.materialStepData || {}, !!draftId);
        updateDimensionsStepData(initialFormData.dimensionsStepData || {}, !!draftId);
        updateWeightPriceStepData(initialFormData.weightPriceStepData || {}, !!draftId);
    }, []);

    const loadFromDraft = useCallback((draft: ListingSellerDraftModel) => {
        setUnsavedChanges(false);
        setMaterialStepData(draftToMaterialStepData(draft));
        setDimensionsStepData(draftToDimensionsStepData(draft));
        setWeightPriceStepData(draftToWeightPriceStepData(draft));
        setPickupAddressStepData(draftToPickupAddressStepData(draft));
        setDraftId(draft.id);
        if (isApiUploadFile(draft.testCertificate)) {
            setCertificatesStatus({ [draft.testCertificate.meta.fileName]: FileUploadStatus.Success });
        }

        if (isApiUploadFile(draft.picture)) {
            setPicturesStatus({ [draft.picture.meta.fileName]: FileUploadStatus.Success });
        }
    }, []);

    const getFileStatus = (files: UploadFile[]) => {
        const fileStatus = files.reduce((acc: FileStatusMap, file) => {
            if (isApiUploadFile(file)) {
                acc[file.meta.fileName] = FileUploadStatus.Success;
            }
            return acc;
        }, {} as FileStatusMap);
        return fileStatus;
    };
    const loadFromPackageDraft = useCallback((draft: PackageSellerDraftModel) => {
        setUnsavedChanges(false);
        setMaterialStepData(packageToMaterialStepData(draft));
        setListUploadStepData(packageToListUploadStepData(draft));
        setWeightPriceStepData(draftToWeightPriceStepData(draft));
        setPickupAddressStepData(packageDraftToPickupAddressStepData(draft));

        setDraftId(draft.id);

        if (draft?.certificates?.length) {
            setCertificatesStatus(getFileStatus(draft?.certificates));
        }
        if (isApiUploadFile(draft.packageDocument)) {
            setPackageStatus({ [draft.packageDocument.meta.fileName]: FileUploadStatus.Success });
        }

        if (draft?.pictures?.length) {
            setPicturesStatus(getFileStatus(draft?.pictures));
        }
    }, []);

    const loadFromRejectedListing = useCallback(
        (draft: ListingSellerDraftModel, rejectedId: ModelPrimaryKey) => {
            loadFromDraft(draft);
            setRejectedListingId(rejectedId);
        },
        [loadFromDraft]
    );

    const savedDraft = useCallback((draft: ListingSellerDraftModel | PackageSellerDraftModel) => {
        setDraftId(draft.id);
        setUnsavedChanges(false);
    }, []);

    const clearRejectedListing = useCallback(() => {
        setRejectedListingId(undefined);
    }, []);

    const updateFileStatus = useCallback(
        (type: DocumentType, filename: string, status?: FileUploadStatus, clearFiles?: boolean) => {
            let setStatus;
            switch (type) {
                case DocumentType.ORIGINAL:
                    setStatus = setCertificatesStatus;
                    break;
                case DocumentType.PACKAGE:
                    setStatus = setPackageStatus;
                    break;
                case DocumentType.PICTURE:
                    setStatus = setPicturesStatus;
                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.warn(`Unexpected document type : ${type}`);
                    return;
            }
            setStatus((prev) => {
                if (clearFiles) {
                    return status ? { [filename]: status } : {};
                }
                if (!status) {
                    const copyPrev = { ...prev };
                    delete copyPrev[filename];
                    // remove from step data
                    if (type === DocumentType.ORIGINAL) {
                        removeFile(setMaterialStepData, 'testCertificate', filename, setUnsavedChanges);
                    } else if (type === DocumentType.PICTURE) {
                        removeFile(setMaterialStepData, 'picture', filename, setUnsavedChanges);
                    } else if (type === DocumentType.PACKAGE) {
                        removeFile(setListUploadStepData, 'packageFile', filename, setUnsavedChanges);
                    }
                    return copyPrev;
                }
                return { ...prev, [filename]: status };
            });
        },
        [setCertificatesStatus, setPicturesStatus, setPackageStatus, setMaterialStepData, setListUploadStepData]
    );

    const addFile = useCallback((type: DocumentType, file: UploadFile, isMulti: boolean) => {
        setUnsavedChanges(true);
        setMaterialStepData((prevState) => {
            if (type === DocumentType.ORIGINAL) {
                let updatedCerts = prevState.testCertificate ? [...prevState.testCertificate] : [];
                updatedCerts = updatedCerts.filter((cert) => cert.meta.fileName !== file.meta.fileName);
                return {
                    ...prevState,
                    testCertificate: isMulti ? [...updatedCerts, file] : [file],
                };
            }

            if (type === DocumentType.PICTURE) {
                let updatedPictures = prevState.picture ? [...prevState.picture] : [];
                updatedPictures = updatedPictures.filter((picture) => picture.meta.fileName !== file.meta.fileName);
                return {
                    ...prevState,
                    picture: isMulti ? [...updatedPictures, file] : [file],
                };
            }

            return prevState;
        });
        setListUploadStepData((prevState) => {
            if (type === DocumentType.PACKAGE) {
                return {
                    ...prevState,
                    packageFile: [file],
                };
            }
            return prevState;
        });
    }, []);

    const value = useMemo(
        () => ({
            draftId,
            rejectedListingId,
            savedDraft,
            materialStepData,
            updateMaterialStepData,
            listUploadStepData,
            updateListUploadStepData,
            dimensionsStepData,
            updateDimensionsStepData,
            weightPriceStepData,
            updateWeightPriceStepData,
            pickupAddressStepData,
            updatePickupAddressStepData,
            setStepStatus: handleStepStatusChange,
            getStepStatus,
            currentStepId,
            setCurrentStepId,
            reset,
            createSame,
            setInitialFormData,
            copyFromListing,
            copyFromPackage,
            loadFromDraft,
            loadFromPackageDraft,
            loadFromRejectedListing,
            unsavedChanges,
            clearRejectedListing,
            certificatesStatus,
            updateFileStatus,
            picturesStatus,
            packageStatus,
            addFile,
            setGoBack,
            goBack,
        }),
        [
            draftId,
            rejectedListingId,
            savedDraft,
            materialStepData,
            updateMaterialStepData,
            listUploadStepData,
            updateListUploadStepData,
            dimensionsStepData,
            updateDimensionsStepData,
            weightPriceStepData,
            updateWeightPriceStepData,
            pickupAddressStepData,
            updatePickupAddressStepData,
            handleStepStatusChange,
            getStepStatus,
            currentStepId,
            setCurrentStepId,
            reset,
            createSame,
            setInitialFormData,
            copyFromListing,
            copyFromPackage,
            loadFromDraft,
            loadFromPackageDraft,
            loadFromRejectedListing,
            unsavedChanges,
            clearRejectedListing,
            certificatesStatus,
            updateFileStatus,
            picturesStatus,
            packageStatus,
            addFile,
            goBack,
        ]
    );

    return <CreateListingContext.Provider value={value}>{children}</CreateListingContext.Provider>;
};

export const useCreateListingContext = () => {
    const context = useContext(CreateListingContext);

    if (!context) {
        throw new CustomError('No provider present for CreateListingContext!');
    }

    return context;
};

import { ReactNode } from 'react';

import './FormLayout.scss';

export type FormLayoutProps = {
    children: ReactNode;
};

export const FormLayout = (props: FormLayoutProps) => {
    const { children } = props;

    return (
        <section className="form-layout">
            <div className="form-layout__inner">{children}</div>
        </section>
    );
};

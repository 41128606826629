import { OrderBuyerCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { OrderBuyerModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const orderBuyerEntityStoreName = 'order-buyer-entity';
export type OrderBuyerEntityStore = {
    [orderBuyerEntityStoreName]: EntityStore<OrderBuyerModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new OrderBuyerCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<OrderBuyerModel, OrderBuyerEntityStore, OrderBuyerCrudApiClient>(
    orderBuyerEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<OrderBuyerModel, OrderBuyerCrudApiClient>(
    orderBuyerEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<OrderBuyerModel, OrderBuyerCrudApiClient>(
    orderBuyerEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<OrderBuyerModel, OrderBuyerCrudApiClient>(
    orderBuyerEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<OrderBuyerModel, OrderBuyerCrudApiClient>(
    orderBuyerEntityStoreName,
    createApiClient
);

// Slice definition
export const orderBuyerEntitySlice = createCrudEntitySlice<OrderBuyerModel>(
    orderBuyerEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = orderBuyerEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<OrderBuyerModel, OrderBuyerEntityStore>(orderBuyerEntityStoreName);
const selectCreated = createSelectCreated<OrderBuyerModel, OrderBuyerEntityStore>(orderBuyerEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<OrderBuyerModel, OrderBuyerEntityStore>(
    orderBuyerEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const orderBuyerEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<OrderBuyerModel, OrderBuyerEntityStore>;

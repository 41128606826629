import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from '../../axios';

interface ListingViewsOptions<Model> {
    listingId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

interface ListingViewsResponse {
    numberOfViews: number;
}

const getListingViews = async (listingId: string) => {
    const { data } = await axios.get<ListingViewsResponse>(`/api/universal/listings/views/${listingId}`);
    return data;
};

export const useListingViews = ({ listingId, queryOptions }: ListingViewsOptions<ListingViewsResponse>) =>
    useQuery({ queryKey: ['listing-views', listingId], queryFn: () => getListingViews(listingId), ...queryOptions });

import './CardListingViewRejectionReason.scss';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { InputTextarea } from '../input-textarea/InputTextarea';

export type CardListingViewRejectionReasonProps = {
    header: string;
    description: string;
    buttonLabel: string;
    rejectionReason: string;
    onButtonClick: () => void;
};

export const CardListingViewRejectionReason = (props: CardListingViewRejectionReasonProps) => {
    const { header, description, buttonLabel, rejectionReason, onButtonClick } = props;

    return (
        <article className="card-listing-view-rejection-reason">
            <article className="card-listing-view-rejection-reason__content">
                <article className="card-listing-view-rejection-reason__content__text">
                    <article className="card-listing-view-rejection-reason__content__text__header">{header}</article>
                    <article className="card-listing-view-rejection-reason__content__text__description">
                        {description}
                    </article>
                </article>
                <ButtonTertiaryOnLightM label={buttonLabel} onClick={onButtonClick} />
            </article>
            <InputTextarea value={rejectionReason} readOnly />
        </article>
    );
};

import { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
    FormItem,
    InputTextarea,
    Notification,
    NotificationLevel,
    WizardBarItemStatus,
    useWizardStepsContext,
} from '@steelbuy/ui-primitive';
import { CreateRFQActionBar } from './CreateRFQActionBar';
import { RFQDeadlineSelection } from './inputs/RFQDeadlineSelection';
import { RFQDeliverySelection } from './inputs/RFQDeliverySelection';
import { RFQOriginSelection } from './inputs/RFQOriginSelection';
import { RoutePath } from '../../../router/Routes';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { RFQStep, hasFormData, isStep2Valid } from '../createRFQUtil';
import { useRfqFormContext } from '../RfqFormContext';
import { Step3Type, isStep3 } from '../Schema';

import './Step3.scss';

const MAX_LENGTH = 30000;
interface SaveDraftContext {
    onSaveDraft: () => void;
    loadingState: boolean;
}
export const Step3 = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { onSaveDraft, loadingState } = useOutletContext<SaveDraftContext>();

    const { formData, updateFormData, setUpdateValuesCallback, draftId } = useRfqFormContext();
    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
        getValues,
        trigger,
    } = useForm<Step3Type>({
        defaultValues: { ...formData, origin: isStep3(formData) ? formData.origin : [] },
        mode: 'all',
    });
    const { updateStepStatus, isStepVisited, visitedSteps } = useWizardStepsContext();

    const navigate = useNavigate();

    const clickedButton = useRef<'next' | 'back' | null>(null);

    useEffect(() => {
        if (draftId || isStepVisited(RFQStep.SHIPPING_DETAILS)) {
            trigger();
        }
    }, [draftId, visitedSteps]);

    const updateStatus = () =>
        updateStepStatus(
            RFQStep.SHIPPING_DETAILS,
            isValid ? WizardBarItemStatus.SUCCESS : WizardBarItemStatus.INCOMPLETE
        );

    useEffect(() => {
        setUpdateValuesCallback((setStepStatus) => {
            updateFormData(getValues());
            if (setStepStatus) {
                updateStatus();
            }
        });
    }, [isValid]);

    if (!hasFormData(formData)) {
        return null;
    }

    const onHandleSubmit = (data: Step3Type) => {
        updateFormData(data);
        updateStatus();
        if (clickedButton.current === 'back') {
            navigate(RoutePath.CREATE_RFQ_STEP2);
        }
        if (clickedButton.current === 'next') {
            navigate(RoutePath.CREATE_RFQ_SUMMARY);
        }
    };

    const onSubmit = handleSubmit(
        (data) => {
            onHandleSubmit(data);
        },
        () => {
            onHandleSubmit(getValues());
        }
    );

    const valid = isStep2Valid(formData) && isValid;
    let errorMessage = '';
    if (!valid) {
        errorMessage = t('translation:application.pickupAddressStep.incompleteInformationNotification');
    }

    return (
        <div className="create-rfq-step3">
            <form onSubmit={onSubmit}>
                <FormLayout>
                    <p className="create-rfq-step3__title">{t('application.createRFQ.chooseMaterialOrigin')}</p>

                    <RFQOriginSelection
                        name="origin"
                        control={control}
                        material={{ materialType: formData?.materialType }}
                        error={errors.origin?.message}
                    />

                    <RFQDeliverySelection name="delivery" control={control} error={errors.delivery} />
                    <RFQDeadlineSelection name="deadline" control={control} error={errors.deadline} />

                    <FormItem
                        header={t('application.createRFQ.additionalComments.header')}
                        description={t('application.createRFQ.additionalComments.description')}
                    >
                        <Controller
                            name="comments"
                            control={control}
                            rules={{
                                maxLength: {
                                    message: t('uiDomain:materialAdditionalComment.maxCharacterError'),
                                    value: MAX_LENGTH,
                                },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <InputTextarea
                                    label={t('uiDomain:materialAdditionalComment.label')}
                                    placeholder={t('uiDomain:materialAdditionalComment.placeholder')}
                                    onChange={onChange}
                                    value={value}
                                    error={errors.comments?.message}
                                />
                            )}
                        />
                    </FormItem>
                    {errorMessage && (
                        <Notification level={NotificationLevel.ERROR} message={errorMessage} disableScroll />
                    )}

                    <CreateRFQActionBar
                        onNext={() => {
                            clickedButton.current = 'next';
                            onSubmit();
                        }}
                        isSubmitDisabled={!valid}
                        nextLabel={t('application.createRFQ.viewSummary')}
                        onSaveDraft={onSaveDraft}
                        onBack={() => {
                            clickedButton.current = 'back';
                            onSubmit();
                        }}
                        isLoading={loadingState}
                    />
                </FormLayout>
            </form>
        </div>
    );
};

const FRIDAY_SHORT = 'Fri';
const SATURDAY_SHORT = 'Sat';
const SUNDAY_SHORT = 'Sun';
const REVIEW_TIME = 2;
const MIN_LISTING_TIME = 2;
const AMOUNT_OF_DAYS = 7;
const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

export type SelectableTime = {
    hour: number;
    minute: number;
};

export const SELECTABLE_TIMES: SelectableTime[] = [
    { hour: 6, minute: 0 },
    { hour: 8, minute: 0 },
    { hour: 10, minute: 0 },
    { hour: 12, minute: 0 },
    { hour: 14, minute: 0 },
    { hour: 16, minute: 0 },
    { hour: 18, minute: 0 },
    { hour: 20, minute: 0 },
    { hour: 23, minute: 59 },
];

function nthDayAfterAt12(date: Date, daysToAdd: number, timezoneHourDiff: number) {
    const hour = 12 + timezoneHourDiff;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysToAdd, hour);
}

/**
 * Returns the earliest expiration time for a listing after the given date.
 * Applies the following rules:
 * - Add two hours for review process
 * - Add two hours for minimal listing live time on platform
 * - Respect listing review service times (during the week, on fridays, on the weekend)
 */
export function getEarliestExpirationDate(date: Date, includeWeekends?: boolean): Date {
    const hourInLondon = Number.parseInt(
        date.toLocaleString('en', {
            hour: '2-digit',
            hour12: false,
            timeZone: 'Europe/London',
        }),
        10
    );
    const weekdayInLondon = date.toLocaleString('en', {
        weekday: 'short',
        hour12: false,
        timeZone: 'Europe/London',
    });
    const timezoneHourDiff = date.getHours() - hourInLondon;

    if (!includeWeekends) {
        if (weekdayInLondon === FRIDAY_SHORT) {
            if (hourInLondon < 13) {
                const hour = date.getHours() + REVIEW_TIME + MIN_LISTING_TIME;
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour);
            }
            return nthDayAfterAt12(date, 3, timezoneHourDiff);
        }
        if (weekdayInLondon === SATURDAY_SHORT) {
            return nthDayAfterAt12(date, 2, timezoneHourDiff);
        }
        if (weekdayInLondon === SUNDAY_SHORT) {
            return nthDayAfterAt12(date, 1, timezoneHourDiff);
        }
    }
    if (hourInLondon < 16) {
        const hour = date.getHours() + REVIEW_TIME + MIN_LISTING_TIME;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour);
    }
    return nthDayAfterAt12(date, 1, timezoneHourDiff);
}

export function getSelectableDates(earliestDate: Date) {
    const dates = [];

    for (let i = 0; i < AMOUNT_OF_DAYS; i++) {
        // drop time information. "stable" date objects needed for comparison.
        const date = new Date(earliestDate.getTime() + ONE_DAY_IN_MILLIS * i);
        dates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    return dates;
}

export function toMinutes(time: SelectableTime) {
    return time.hour * 60 + time.minute;
}

export function fromMinutes(minutes: number): SelectableTime {
    return { hour: Math.floor(minutes / 60), minute: minutes % 60 };
}

import { ReactNode } from 'react';
import './CardContentSellerSummaryExpiryTime.scss';

export type CardContentSellerSummaryExpiryTimeProps = {
    header: string;
    children: ReactNode;
};

export const CardContentSellerSummaryExpiryTime = (props: CardContentSellerSummaryExpiryTimeProps) => {
    const { header, children } = props;

    return (
        <article className="card-content-seller-summary-eypiry-time">
            <article className="card-content-seller-summary-eypiry-time__header">{header}</article>
            <article className="card-content-seller-summary-eypiry-time__children">{children}</article>
        </article>
    );
};

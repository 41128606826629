import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

export interface FilterContextValue {
    filter: string[];
    setFilter: (filter: string[]) => void;
    reset: () => void;
}

const defaultValue: FilterContextValue = {
    filter: [],
    setFilter: () => null,
    reset: () => null,
};

const FilterContext = createContext(defaultValue);

export const FilterContextProvider = ({ children }: PropsWithChildren) => {
    const [filter, setFilter] = useState<string[]>([]);

    const reset = useCallback(() => {
        setFilter([]);
    }, [setFilter]);

    const value = useMemo(
        () => ({
            filter,
            setFilter,
            reset,
        }),
        [filter, setFilter, reset]
    );

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => useContext(FilterContext);

import { ReactElement, useEffect } from 'react';
import { Nullable } from '@steelbuy/types';
import { reportNRError } from '@steelbuy/util';
import { GenericError } from './GenericError';

export const RenderErrorComponent = (error: Error): Nullable<ReactElement> => {
    const chunkLoadError = 'ChunkLoadError';
    const { name } = error;

    useEffect(() => {
        if (name !== chunkLoadError) {
            reportNRError(error);
        }
    }, []);

    if (name === chunkLoadError) {
        return null;
    }

    return <GenericError />;
};

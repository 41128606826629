import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { entries } from '@steelbuy/util';
import { WizardBarItemStatus } from '../wizard-bar/wizard-bar-item/WizardBarItemStatus';

type StepStatusLookup = Record<string, WizardBarItemStatus>;

type StepVisitationLookup = Record<string, StepVisitation>;

type WizardStepsContextValue = {
    visitedSteps: string[];
    stepStatus: StepStatusLookup;
    stepVisitation: StepVisitationLookup;
    updateStepStatus: (step: string, status: WizardBarItemStatus) => void;
    setActiveStep: (step: string) => void;
    activeStepIndex: number;
    isStepVisited: (step: string) => boolean;
    reset: () => void;
};

const WizardStepsContext = createContext<WizardStepsContextValue>({
    visitedSteps: [],
    stepStatus: {},
    stepVisitation: {},
    updateStepStatus: () => null,
    setActiveStep: () => null,
    activeStepIndex: 0,
    isStepVisited: () => false,
    reset: () => null,
});

export enum StepVisitation {
    NEVER = 'NEVER',
    ACTIVE = 'ACTIVE',
    VISITED = 'VISITED',
}

const getDefaultStepStatus = (steps: string[]) =>
    steps.reduce((acc, step) => ({ ...acc, [step]: WizardBarItemStatus.EMPTY }), {});

const getDefaultStepVisitation = (steps: string[]) =>
    steps.reduce((acc, step) => ({ ...acc, [step]: StepVisitation.NEVER }), {});

export const useWizardStepsContext = () => useContext(WizardStepsContext);

type WizardStepsContextProviderProps = {
    steps: string[];
} & PropsWithChildren;

export const WizardStepsContextProvider = ({ children, steps }: WizardStepsContextProviderProps) => {
    const [stepStatus, setStepStatus] = useState<StepStatusLookup>(getDefaultStepStatus(steps));
    const [stepVisitation, setStepVisitation] = useState<StepVisitationLookup>(getDefaultStepVisitation(steps));
    const [visitedSteps, setVisitedSteps] = useState<string[]>([]);

    const updateStepStatus = useCallback(
        (step: string, status: WizardBarItemStatus) => {
            setStepStatus((currentStatus) => ({ ...currentStatus, [step]: status }));
        },
        [setStepStatus]
    );

    const setActiveStep = useCallback(
        (step: string) => {
            const previousActiveStep = entries(stepVisitation).find(
                ([_key, visitation]) => visitation === StepVisitation.ACTIVE
            )?.[0];
            setVisitedSteps((currentVisitedSteps) => {
                if (
                    previousActiveStep &&
                    !currentVisitedSteps.includes(previousActiveStep) &&
                    previousActiveStep !== step
                ) {
                    return [...currentVisitedSteps, previousActiveStep];
                }
                return currentVisitedSteps;
            });
            setStepVisitation((currentVisitation) => {
                const updatedVisitation = entries(currentVisitation).reduce(
                    (acc, [key, visitation]) => ({
                        ...acc,
                        [key]: visitation === StepVisitation.ACTIVE ? StepVisitation.VISITED : visitation,
                    }),
                    {} as StepVisitationLookup
                );
                updatedVisitation[step] = StepVisitation.ACTIVE;
                return updatedVisitation;
            });
            updateStepStatus(step, WizardBarItemStatus.ACTUAL);
        },
        [setStepVisitation, updateStepStatus, stepVisitation]
    );

    const activeStepIndex = useMemo(
        () => Object.values(stepVisitation).findIndex((status) => status === StepVisitation.ACTIVE) || 0,
        [stepVisitation]
    );

    const isStepVisited = useCallback((step: string) => visitedSteps.includes(step), [visitedSteps]);

    const reset = useCallback(() => {
        setStepStatus(getDefaultStepStatus(steps));
        setStepVisitation(getDefaultStepVisitation(steps));
        setVisitedSteps([]);
    }, [setStepVisitation, setVisitedSteps, setStepStatus]);

    const value = useMemo(
        () => ({
            stepStatus,
            stepVisitation,
            updateStepStatus,
            setActiveStep,
            activeStepIndex,
            isStepVisited,
            reset,
            visitedSteps,
        }),
        [stepStatus, stepVisitation, updateStepStatus, setActiveStep, activeStepIndex, reset, visitedSteps]
    );

    return <WizardStepsContext.Provider value={value}>{children}</WizardStepsContext.Provider>;
};

import { useEffect } from 'react';
import { OrderModel } from '@steelbuy/data-access';
import { CheckoutIllustratedSuccess } from '@steelbuy/ui-domain';
import { CheckoutSuccessWrapper } from './CheckoutSuccessWrapper';
import { RoutePath } from '../../router/Routes';
import { APP_MAIN_LAYOUT_ID } from '../app-layout/app-main-layout/AppMainLayout';

export const CheckoutSuccessMyCart = ({ orderPlaced }: { orderPlaced: OrderModel }) => {
    useEffect(() => {
        document.getElementById(APP_MAIN_LAYOUT_ID)?.scrollTo(0, 0);
    }, []);

    return (
        <CheckoutSuccessWrapper>
            <CheckoutIllustratedSuccess orderModelCart={orderPlaced} myOrdersUrl={RoutePath.MY_ORDERS} isCart />
        </CheckoutSuccessWrapper>
    );
};

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfDateRangeFieldNames,
    ArrayOfDownloadFileFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfUploadFileFieldNames,
    ArrayOfUrlFieldNames,
    DateRangeFieldNames,
    DownloadFile,
    DownloadFileFieldNames,
    TimestampFieldNames,
    UploadFileFieldNames,
    UrlFieldNames,
} from '@steelbuy/domain-model-types';
import { Country } from './AddressModel.enums';
import { DeliveryPromise } from './ListingBuyerModel.enums';
import { ListingBuyerPricingModel, ListingBuyerPricingModelConverter } from './ListingBuyerPricingModel';

import { ListingModelFoundation, ListingModelFoundationConverter } from './ListingModelFoundation';
import { ListingStatus } from './ListingSellerModel.enums';
import { NegotiationModel, NegotiationModelConverter } from './NegotiationModel';

export type ListingBuyerModel = Readonly<ListingModelFoundation> & {
    testCertificate?: DownloadFile;
    pricing: ListingBuyerPricingModel;
    canCheckout: boolean;
    deliveryPromise: DeliveryPromise;
    negotiations?: NegotiationModel[];
    totalNumberOfNegotiation?: number;
    numberOfActiveNegotiation?: number;
    negotiationExists?: boolean;
    offerPricing?: ListingBuyerPricingModel;
    isAccepted: boolean;
    listingStatusType: ListingStatus;
    country?: Country;
};

export class ListingBuyerModelConverter extends ModelConverter<ListingBuyerModel> {
    override getDateRangeFields(): Array<
        DateRangeFieldNames<ListingBuyerModel> | ArrayOfDateRangeFieldNames<ListingBuyerModel>
    > {
        return new ListingModelFoundationConverter().getDateRangeFields();
    }

    override getUrlFields(): Array<UrlFieldNames<ListingBuyerModel> | ArrayOfUrlFieldNames<ListingBuyerModel>> {
        return new ListingModelFoundationConverter().getUrlFields();
    }

    override getUploadFileFields(): Array<
        UploadFileFieldNames<ListingBuyerModel> | ArrayOfUploadFileFieldNames<ListingBuyerModel>
    > {
        return new ListingModelFoundationConverter().getUploadFileFields();
    }

    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<ListingBuyerModel> | ArrayOfDownloadFileFieldNames<ListingBuyerModel>
    > {
        return new ListingModelFoundationConverter().getDownloadFileFields();
    }

    override getTimestampFields(): Array<
        TimestampFieldNames<ListingBuyerModel> | ArrayOfTimestampFieldNames<ListingBuyerModel>
    > {
        return new ListingModelFoundationConverter().getTimestampFields();
    }

    override getFieldConverterMapByModel(): FieldConverterMap<ListingBuyerModel> {
        return {
            ...new ListingModelFoundationConverter().getFieldConverterMapByModel(),
            pricing: new ListingBuyerPricingModelConverter(),
            negotiations: new NegotiationModelConverter(),
            offerPricing: new ListingBuyerPricingModelConverter(),
        };
    }
}

import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CartBuyerModel, CartBuyerModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import { toaster } from '@steelbuy/ui-primitive';
import axios from '../../axios';
import { getHideCartExpiryToast } from '../../utils/cart';

export const MY_CART_DETAILS = 'cart-details';

const modelConverter = new CartBuyerModelConverter();

const getCartDetails = async () => {
    const { data } = await axios.get<CartBuyerModel>(`/api/buyer/cart/fetch-cart`);
    const newData = modelConverter.toViewModel(data as unknown as ApiModel<CartBuyerModel>);
    return newData;
};

export const useCartDetails = ({ queryOptions } = { queryOptions: { enabled: true } }, triggerExpiry?: boolean) => {
    const { t } = useTranslation('translation');
    const expiresAtRef = useRef<number>(0);
    const { data, refetch, ...rest } = useQuery({
        queryKey: [MY_CART_DETAILS],
        queryFn: () => getCartDetails(),
        cacheTime: 0,
        ...queryOptions,
    });

    useEffect(() => {
        if (triggerExpiry && data?.expiresAt?.value) {
            const cartExpirationTime = new Date(data?.expiresAt?.value).getTime();
            const currentTime = Date.now();
            if (expiresAtRef.current !== cartExpirationTime) {
                expiresAtRef.current = cartExpirationTime;
                const timeUntilExpiration = cartExpirationTime - currentTime;
                if (timeUntilExpiration > 0) {
                    const timeoutId = setTimeout(() => {
                        refetch();
                        if (!getHideCartExpiryToast()) {
                            toaster.warn(t('application.cart.expiredMessage'));
                        }
                    }, timeUntilExpiration);

                    return () => clearTimeout(timeoutId);
                }
            }
        }

        return undefined;
    }, [data?.expiresAt?.value]);

    return { data, refetch, ...rest };
};

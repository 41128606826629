import { useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ButtonPrimary, ButtonPrimaryProps } from '../button-primary/ButtonPrimary';
import { DropdownMenu, DropdownMenuProp } from '../dropdown-menu/DropdownMenu';

export type ButtonPrimaryDropdownProps<T> = {
    buttonProps: ButtonPrimaryProps;
    dropdownMenuProps: DropdownMenuProp<T>;
};

export const ButtonPrimaryDropdown = <T,>({ buttonProps, dropdownMenuProps }: ButtonPrimaryDropdownProps<T>) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(dropDownRef, () => setShowDropdown(false));

    return (
        <div>
            <ButtonPrimary
                {...buttonProps}
                onClick={() => {
                    setShowDropdown(true);
                }}
            />
            <DropdownMenu<T>
                {...dropdownMenuProps}
                onSelection={(value: T) => {
                    setShowDropdown(false);
                    dropdownMenuProps.onSelection(value);
                }}
                dropdownRef={dropDownRef}
                show={showDropdown}
            />
        </div>
    );
};

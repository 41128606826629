import { useAuth } from '@steelbuy/auth';

import { FooModel } from '@steelbuy/domain-model';

import { FooCreateEntityData } from './FooCreateEntityData';
import { FooCreateEntityStore, fooCreateEntityStoreAccessors, fooCreateEntityStoreName } from './FooCreateEntitySlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { createCreateEntityDataProviderValue } from '../../util/provider/CreateEntityDataProviderValue';
import { CreateEntityDataProviderProps } from '../../util/provider/DataProviderProps';
import { getBaseUrl } from '../../util/urlUtils';

export const FooCreateEntityDataProvider = ({ apiMock = false, children }: CreateEntityDataProviderProps) => {
    const authConsumer = useAuth();

    const dataProviderValue = createCreateEntityDataProviderValue<FooModel, FooCreateEntityStore>(
        fooCreateEntityStoreName,
        fooCreateEntityStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    return <FooCreateEntityData.Provider value={dataProviderValue}>{children}</FooCreateEntityData.Provider>;
};

/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { FilterCriteria, SortCriteria, SortDirection } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';

import { AddressModel, AddressType } from '@steelbuy/domain-model';
import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { InvoiceAddressCollectionData } from './InvoiceAddressCollectionData';
import {
    InvoiceAddressCollectionStore,
    invoiceAddressCollectionStoreAccessors,
    invoiceAddressCollectionStoreName,
} from './InvoiceAddressCollectionSlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { createCollectionDataProviderValue } from '../../util/provider/CollectionDataProviderValue';
import { CollectionDataProviderProps } from '../../util/provider/DataProviderProps';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';
import { useUserSelf } from '../user-self-entity/UserSelfEntityDataConsumer';

type InvoiceAddressCollectionDataProviderProps = Omit<
    CollectionDataProviderProps<AddressModel>,
    'filterCriteria' | 'sortCriteria'
>;

export const InvoiceAddressCollectionDataProvider = (props: InvoiceAddressCollectionDataProviderProps) => {
    const { lazyLoad = false, interceptRendering = !lazyLoad, pendingComponent = null } = props;

    const PendingComponent = pendingComponent;

    const authConsumer = useAuth();
    const userSelf = useUserSelf();

    const dataProviderValue = createCollectionDataProviderValue<AddressModel, InvoiceAddressCollectionStore>(
        invoiceAddressCollectionStoreName,
        invoiceAddressCollectionStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl()),
        userSelf.get()?.roles
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        const invoiceFilter: FilterCriteria<AddressModel> = [
            {
                id: 'ADDRESS_TYPE_FILTER',
                criteria: [{ property: 'addressType', value: AddressType.INVOICE }],
            },
        ];

        const sort: SortCriteria<AddressModel> = [{ property: 'postalCode', direction: SortDirection.ASCENDING }];

        if (dataProviderIdle && !lazyLoad) {
            dataProviderValue.fetch(sort, invoiceFilter);
        }
    }, [dataProviderIdle, lazyLoad]);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent !== null) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return (
        <InvoiceAddressCollectionData.Provider value={dataProviderValue}>
            {props.children}
        </InvoiceAddressCollectionData.Provider>
    );
};

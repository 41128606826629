import { useTranslation } from 'react-i18next';
import { ListingMaterialOrigin } from '@steelbuy/domain-model';
import { CardContentLabelValues } from '@steelbuy/ui-primitive';

export const CardContentOrigins = ({ origin }: { origin: ListingMaterialOrigin[] }) => {
    const { t } = useTranslation(['translation', 'domainModel']);

    const labelValues = [
        {
            label: t('application.createRFQ.preferredOrigin'),
            value: origin.map((value) => t(`domainModel:material.origin.value.${value}`)).join(', '),
        },
    ];

    return (
        <CardContentLabelValues title={t('application.createRFQ.materialOrigin.header')} labelValues={labelValues} />
    );
};

import { buildClassStringFromClassMap } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './BadgePrimary.scss';

export type BadgePrimaryProps = {
    icon?: IconIdentifier;
    label?: string;
};

export const BadgePrimary = (props: BadgePrimaryProps) => {
    const { icon, label } = props;

    const badgePrimaryClassMap = {
        'badge-primary': true,
        'badge-primary--collapsed': !icon && !label,
        'badge-primary--expanded-no-icon': !icon && label,
        'badge-primary--expanded-with-icon': icon && label,
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="badge-primary__label">{label}</span>;
    };

    return (
        <div className={buildClassStringFromClassMap(badgePrimaryClassMap)}>
            {renderIcon()}
            {renderLabel()}
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PreparedSortCriteria, SortDirection } from '@steelbuy/api-integration';
import { FetchStatus, useOrderBuyerCollectionData, useRefreshingCollection } from '@steelbuy/data-provider';

import { ListingBuyerOrderTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    LoadingSpinner,
    LoadingStatus,
    MarketingBannerDashboardBuy,
} from '@steelbuy/ui-primitive';
import { MyOrdersEmptyState } from './MyOrdersEmptyState';
import { MyOrdersFilter } from './MyOrdersFilter';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { useFilterContext } from '../filter-context/FilterContext';
import { PageHeader } from '../page-header/PageHeader';

import './MyOrders.scss';

const defaultSortCriteria: PreparedSortCriteria = [
    {
        property: 'createdAt',
        direction: SortDirection.DESCENDING,
    },
];

export const MyOrders = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const orderBuyerCollectionData = useOrderBuyerCollectionData();
    const fetchStatus = useRefreshingCollection(orderBuyerCollectionData, defaultSortCriteria);
    const orders = orderBuyerCollectionData.query();
    const totalOrders = orderBuyerCollectionData.queryTotalItems() ?? undefined;
    const hasNext = orderBuyerCollectionData.hasNextPage();
    const filterStatus = orderBuyerCollectionData.queryFilterCriteria();
    const navigate = useNavigate();
    const { filter } = useFilterContext();
    const handleLoadMore = () => {
        orderBuyerCollectionData.fetchNext();
    };
    const noResults = filter.length > 0 && totalOrders === 0;
    const noOrders = totalOrders === 0 && filterStatus === null;
    const renderOrders = () => {
        if (fetchStatus === FetchStatus.PENDING) {
            return (
                <div className="my-orders-loading-spinner">
                    <LoadingSpinner fullHeight={false} />
                </div>
            );
        }
        return (
            <>
                {noOrders ? (
                    <div className="my-orders__no-results">
                        <MarketingBannerDashboardBuy
                            header={t('application.myOrders.bannerBuy.header')}
                            subHeader={t('application.myOrders.bannerBuy.subHeader')}
                            buttonLabel={t('application.myOrders.bannerBuy.buttonLabel')}
                            descriptionText={t('application.myOrders.bannerBuy.description', { returnObjects: true })}
                            onClick={() => navigate(RoutePath.SEARCH_LISTINGS)}
                        />
                    </div>
                ) : (
                    <DataHeader headingValue={t('application.myOrders.numberOfOrders', { count: totalOrders })}>
                        <MyOrdersFilter />
                    </DataHeader>
                )}

                <div className="my-orders__orders">
                    {orders.map((order) => (
                        <Card
                            key={order.id}
                            isClickable
                            url={createRouteUrl(RoutePath.MY_ORDERS_DETAILS, ['orderId', order.id])}
                        >
                            <ListingBuyerOrderTeaser order={order} />
                        </Card>
                    ))}
                </div>
                {hasNext && (
                    <div className="search-results__load-more">
                        <ButtonTertiaryOnLightM
                            onClick={handleLoadMore}
                            label={t('uiDomain:commonList.loadMore')}
                            loadingStatus={
                                fetchStatus === FetchStatus.PAGING_PENDING ? LoadingStatus.PENDING : LoadingStatus.IDLE
                            }
                        />
                    </div>
                )}
                {noResults && <MyOrdersEmptyState />}
            </>
        );
    };

    return (
        <>
            <div className="my-orders__page-header">
                <PageHeader pageTitle={t('application.myOrders.header')} />
            </div>
            <TableLayout>
                <div className="my-orders">{renderOrders()}</div>
            </TableLayout>
        </>
    );
};

import { useEffect, useState } from 'react';

const OTHER = 'OTHER';

const getInitialState = <T,>(values: Readonly<T[]>, initialValue: T, otherValue: string) =>
    values.includes(initialValue) ? initialValue : otherValue;

export const useOtherDropDown = <T,>(initialValue: T | undefined, values: Readonly<T[]>, otherValue = OTHER) => {
    const [dropDownValue, setDropDownValue] = useState(
        initialValue ? getInitialState<T>(values, initialValue, otherValue) : undefined
    );
    const [textValue, setTextValue] = useState(dropDownValue === otherValue ? initialValue : '');

    useEffect(() => {
        if (dropDownValue !== OTHER) {
            setTextValue(undefined);
        }
    }, [dropDownValue]);

    return {
        dropDownValue,
        setDropDownValue,
        textValue,
        setTextValue,
    };
};

import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSpecifications, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialSpecificationSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    error?: FieldError;
}

export const MaterialSpecificationSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    name,
    material,
    rules,
    error,
}: MaterialSpecificationSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const specifications = getSpecifications(material)?.slice()?.sort();

    return (
        <Select
            options={specifications.map((gradeSpecification) => ({
                label: t(`domainModel:material.specification.value.${gradeSpecification}`),
                value: gradeSpecification,
            }))}
            disabled={specifications.length === 0}
            placeholder={t(`uiDomain:materialInput.placeholder`)}
            name={name}
            control={control}
            rules={{ ...(rules || {}), required: specifications.length > 0 ? rules?.required : undefined }}
            error={error?.message}
        />
    );
};

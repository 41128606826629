import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FillHeightContainer, EmptyState } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

export const MyListingEmptyState = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();

    return (
        <FillHeightContainer
            className="my-listings-drafts__empty-state-container"
            extraPadding={FOOTER_HEIGHT}
            minHeight={220}
        >
            <EmptyState
                caption={t('uiDomain:commonList.noListCaption')}
                description={t('application.myListings.noResultsDescription')}
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateData.svg"
                button={{
                    label: t('application.myListings.noListingsCallToAction'),
                    onClick: () => navigate(RoutePath.CREATE_LISTING_WIZARD),
                }}
            />
        </FillHeightContainer>
    );
};

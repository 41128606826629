import { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Definition, getDefinitions, MaterialProperties, ProductType } from '@steelbuy/domain-model';
import { FormItem, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { CheckboxGroup } from '../CheckboxGroup';
import { Checkbox } from '../primitive/checkbox/Checkbox';

interface MaterialDefinitionSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    error?: string;
    addedIndexes: (ProductType | Definition)[];
    showConfirmSelection: (onConfirm: () => void) => void;
}

export const MaterialDefinitionSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    material,
    error,
    addedIndexes,
    showConfirmSelection,
}: MaterialDefinitionSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);
    const availableProductDefinitions = getDefinitions(material)?.slice().sort();
    const productOptions = availableProductDefinitions.map((materialDefinition) => ({
        label: t(`domainModel:material.definition.value.${materialDefinition}`),
        value: materialDefinition,
    }));

    if (!availableProductDefinitions.length) {
        return null;
    }

    const deselectDefinition = (definition: string, field: ControllerRenderProps<TFieldValues, TName>) =>
        field.onChange(field.value.filter((item: string) => item !== definition));

    return (
        <FormItem
            header={t('domainModel:material.definition.name')}
            description={t('uiDomain:rfqForm.definition.description')}
        >
            {!!error && <Icon name={IconIdentifier.ERROR} />}
            <CheckboxGroup>
                <Checkbox
                    control={control}
                    name={name}
                    options={productOptions}
                    rules={{
                        required: t('uiDomain:validation.requiredField'),
                    }}
                    onChange={({ target, field }) => {
                        if (target.checked) {
                            field.onChange([...field.value, target.value]);
                            return;
                        }
                        if (addedIndexes.includes(target.value as Definition)) {
                            showConfirmSelection(() => deselectDefinition(target.value, field));
                            return;
                        }
                        deselectDefinition(target.value, field);
                    }}
                    id="definition-select"
                />
            </CheckboxGroup>
            {!!error && <span className="form-item__error-msg">{error}</span>}
        </FormItem>
    );
};

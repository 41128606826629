import { ListingSellerCollectionDataProvider, ListingSellerDraftEntityDataProvider } from '@steelbuy/data-provider';
import { useCreateListingContext } from '../../../components/create-listing/CreateListingContext';
import { CreateListingSummaryStatusGuard } from '../../../components/create-listing-summary/CreateListingSummaryStatusGuard';

export const CreateListingSummaryView = () => {
    const createListingContext = useCreateListingContext();

    return (
        <ListingSellerCollectionDataProvider lazyLoad>
            <ListingSellerDraftEntityDataProvider entityId={createListingContext.draftId ?? ''} lazyLoad>
                <CreateListingSummaryStatusGuard />
            </ListingSellerDraftEntityDataProvider>
        </ListingSellerCollectionDataProvider>
    );
};

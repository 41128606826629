import { Nullable, Optional, Potentially } from '@steelbuy/types';

import { ApiTimestamp, Timestamp } from './Timestamp';
import { isApiTimestamp, isTimestamp } from './Timestamp.guards';

export const createTimestampFromDate = (date: Date): Timestamp => ({
    discriminator: 'Timestamp',
    value: date.toISOString(),
});

export const createTimestampFromMaybeDate = (date: Potentially<Date>): Optional<Timestamp> => {
    let timestamp = null;
    if (date instanceof Date) {
        timestamp = createTimestampFromDate(date);
    }
    return new Optional<Timestamp>(timestamp);
};

export const createDateFromTimestamp = (timestamp: Timestamp): Date => new Date(timestamp.value);

export const createDateFromMaybeTimestamp = (timestamp: Potentially<Timestamp>): Optional<Date> => {
    const date = timestamp?.value ? new Date(timestamp.value) : null;
    return new Optional(date);
};

export const createTimestampFromApiTimestamp = (apiTimestamp: Potentially<ApiTimestamp>): Optional<Timestamp> => {
    let timestamp = null;
    if (isApiTimestamp(apiTimestamp)) {
        timestamp = {
            discriminator: 'Timestamp',
            value: apiTimestamp,
        } as Timestamp;
    }
    return new Optional<Timestamp>(timestamp);
};

export const createApiTimestampFromTimestamp = (timestamp: Potentially<Timestamp>): Optional<ApiTimestamp> => {
    let apiTimestamp = null;
    if (isTimestamp(timestamp)) {
        apiTimestamp = timestamp.value;
    }
    return new Optional<ApiTimestamp>(apiTimestamp);
};

const formatInputValue = (date: Nullable<Date>): Nullable<string> => {
    if (date === null) {
        return null;
    }
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0'
    )}`;
};

export const createInputValueFromTimestamp = (timestamp: Potentially<Timestamp>): Optional<string> => {
    const inputValue = isTimestamp(timestamp) ? formatInputValue(new Date(timestamp.value)) : null;
    return new Optional(inputValue);
};

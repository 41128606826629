import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListingBuyerAlertCollectionDataProvider } from '@steelbuy/data-provider';

import { useSearchFormDataContext } from '../../components/listing-search/SearchFormDataContext';
import { ListingSearchResults } from '../../components/listing-search-results/ListingSearchResults';
import { RoutePath } from '../../router/Routes';

export const SearchListingsResultsView = () => {
    const navigate = useNavigate();
    const searchFormData = useSearchFormDataContext().getSearchFormData();

    useEffect(() => {
        if (searchFormData === undefined) {
            navigate(RoutePath.SEARCH_LISTINGS);
        }
    });

    return (
        <ListingBuyerAlertCollectionDataProvider lazyLoad>
            <ListingSearchResults />
        </ListingBuyerAlertCollectionDataProvider>
    );
};

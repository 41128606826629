export enum ListingMaterialAge {
    UP_TO_THREE_MONTHS = 'UP_TO_THREE_MONTHS',
    UP_TO_SIX_MONTHS = 'UP_TO_SIX_MONTHS',
    UP_TO_TWELVE_MONTHS = 'UP_TO_TWELVE_MONTHS',
    OLDER_THAN_TWELVE_MONTHS = 'OLDER_THAN_TWELVE_MONTHS',
    UNSPECIFIED = 'UNSPECIFIED',
}

export enum ListingMaterialOrigin {
    WESTERN_EUROPEAN = 'WESTERN_EUROPEAN',
    OTHER_EUROPEAN = 'OTHER_EUROPEAN',
    NON_EUROPEAN = 'NON_EUROPEAN',
    UNSPECIFIED = 'UNSPECIFIED',
}

export enum DeliveryPromise {
    DAYS_3_5 = 'DAYS_3_5',
    WEEKS_2_3 = 'WEEKS_2_3',
    WEEKS_3_5 = 'WEEKS_3_5',
    WEEKS_5_8 = 'WEEKS_5_8',
    WEEKS_8_12 = 'WEEKS_8_12',
    WEEKS_12_16 = 'WEEKS_12_16',
}

import { ProductType, Finish, Grade, Specification } from '../../../MaterialModel.enums';

export const mildSteelPlateFinish = [Finish.BLACK, Finish.SHOTBLASTED, Finish.SHOTBLASTED_PRIMER_PAINTED] as const;

export type MildSteelPlateFinish = typeof mildSteelPlateFinish[number];

export const mildSteelWearResistantGrade = [
    Grade.G_300HB,
    Grade.G_400HB,
    Grade.G_450HB,
    Grade.G_500HB,
    Grade.G_550HB,
    Grade.G_600HB,
] as const;

export type MildSteelWearResistantGrade = typeof mildSteelWearResistantGrade[number];

export const mildSteelHighStrengthStructuralGrade = [
    Grade.S355,
    Grade.S420,
    Grade.S460,
    Grade.S500,
    Grade.S550,
    Grade.S690,
    Grade.S700,
    Grade.S890,
    Grade.S960,
    Grade.S1100,
    Grade.S1400,
    Grade.C22,
    Grade.C25,
    Grade.C35,
    Grade.C40,
    Grade.C45,
    Grade.C50,
    Grade.C55,
    Grade.C60,
    Grade.G_34CRMO4,
    Grade.G_42CRMO4,
    Grade.G_50CRMO4,
    Grade.G_51CRV4,
    Grade.G_58CRV4,
] as const;

export type MildSteelHighStrengthStructuralGrade = typeof mildSteelHighStrengthStructuralGrade[number];

export const mildSteelStructuralSteelGrade = [Grade.S235, Grade.S275, Grade.S355] as const;

export type MildSteelStructuralSteelGrade = typeof mildSteelStructuralSteelGrade[number];

export const mildSteelMediumCarbonSteelGrade = [Grade.C45, Grade.C45E, Grade.EN8, Grade.EN8D] as const;

export type MildSteelMediumCarbonSteelGrade = typeof mildSteelMediumCarbonSteelGrade[number];

export const mildSteelOffshoreGrade = [Grade.S355, Grade.S420, Grade.S460, Grade.API2H_50, Grade.API2W_50] as const;

export type MildSteelOffshoreGrade = typeof mildSteelOffshoreGrade[number];

export const mildSteelBoilerPressureVesselGrade = [
    Grade.A516_GR60,
    Grade.A516_GR65,
    Grade.A516_GR70,
    Grade.A537_CLASS1,
    Grade.G_490B_LT30,
    Grade.G_490B_LT50,
    Grade.P265GH,
    Grade.P265S,
    Grade.P275NH,
    Grade.P355NH,
    Grade.P355NL1,
    Grade.P355NL2,
] as const;

export type MildSteelBoilerPressureVesselGrade = typeof mildSteelBoilerPressureVesselGrade[number];

export const mildSteelHighStrengthStructuralSpecification = [
    Specification.MC,
    Specification.QL,
    Specification.QL1,
    Specification.NL,
] as const;

export type MildSteelHighStrengthStructuralSpecification = typeof mildSteelHighStrengthStructuralSpecification[number];

export const mildSteelStructuralSteelSpecification = [
    Specification.N_A,
    Specification.JR,
    Specification.J0_PLUS_N,
    Specification.JR_PLUS_N,
    Specification.J2_PLUS_N,
    Specification.J2_C_PLUS_N,
    Specification.K2_PLUS_N,
    Specification.GH,
    Specification.NH,
    Specification.NL1,
    Specification.NL2,
    Specification.PLUS_U,
    Specification.PLUS_A,
    Specification.PLUS_S,
    Specification.PLUS_N,
    Specification.J0,
    Specification.J0W,
    Specification.J0WP,
    Specification.J2,
    Specification.J2G3,
    Specification.J2G4,
    Specification.J2W,
    Specification.JRC,
    Specification.K2,
    Specification.K2G3,
    Specification.K2G4,
    Specification.K2W,
    Specification.K2W_PLUS_N,
    Specification.E295,
    Specification.E335,
    Specification.E360,
    Specification.NL,
    Specification.MC,
    Specification.ML,
] as const;

export type MildSteelStructuralSteelSpecification = typeof mildSteelStructuralSteelSpecification[number];

export const mildSteelMediumCarbonSteelSpecification = [
    Specification.PLUS_A,
    Specification.PLUS_N,
    Specification.PLUS_S,
    Specification.PLUS_U,
] as const;

export type MildSteelMediumCarbonSteelSpecification = typeof mildSteelMediumCarbonSteelSpecification[number];

export const mildSteelOffshoreSpecification = [
    Specification.G1,
    Specification.G1_PLUS_N,
    Specification.G1_PLUS_M,
    Specification.G1_PLUS_Q,
    Specification.G7,
    Specification.G7_PLUS_N,
    Specification.G7_PLUS_M,
    Specification.G8,
    Specification.G8_PLUS_N,
    Specification.G8_PLUS_M,
    Specification.G10,
    Specification.G10_PLUS_N,
    Specification.G10_PLUS_M,
    Specification.EMZ,
    Specification.PLUS_M,
    Specification.PLUS_N,
] as const;

export type MildSteelOffshoreSpecification = typeof mildSteelOffshoreSpecification[number];

export const mildSteelProductType = [
    ProductType.BOILER_PRESSURE_VESSEL,
    ProductType.HIGH_STRENGTH_STRUCTURAL,
    ProductType.MEDIUM_CARBON_STEEL,
    ProductType.OFFSHORE,
    ProductType.STRUCTURAL_STEEL,
    ProductType.WEAR_RESISTANT,
] as const;

export const mildSteelNonPrimeProductType = [...mildSteelProductType, ProductType.ROAD_PLATE] as const;

import { ReactNode } from 'react';

import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './ModalContent.scss';

export type ModalContentProps = {
    headline?: string;
    children: ReactNode;
    onCloseModal: () => void;
};

export const ModalContent = (props: ModalContentProps) => {
    const { headline, onCloseModal, children } = props;

    return (
        <article className="modal-content">
            <article className="modal-content__header">
                <span className="modal-content__header__title">{headline}</span>
                <span className="modal-content__header__close">
                    <ButtonIcon onClick={onCloseModal}>
                        <Icon name={IconIdentifier.CLOSE} />
                    </ButtonIcon>
                </span>
            </article>
            {children}
        </article>
    );
};

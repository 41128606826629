import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@steelbuy/currency';
import { CartProductType } from '@steelbuy/data-access';
import { CartEntryModel, AnyMaterialModel, Product } from '@steelbuy/domain-model';

import { CartSummaryHeader } from '../cart-summary/cart-summary-header/CartSummaryHeader';
import { useMaterialProperties } from '../material/Material';

import './CheckoutCartSummary.scss';

export type CheckoutCartSummaryProps = {
    cartData: CartEntryModel;
};

export const CheckoutCartSummary = ({ cartData }: CheckoutCartSummaryProps) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);

    const isPackage = cartData.productType === CartProductType.PACKAGE;
    const material = cartData.product.material as AnyMaterialModel;
    const materialTypeProperty = isPackage ? cartData.product.material : cartData.product.material.materialType;

    const materialProperties = useMaterialProperties(isPackage ? cartData.product : material, ['materialType']);

    const materialDimensions = isPackage
        ? { thickness: undefined, width: undefined }
        : cartData.product.materialDimensions;

    const { thickness, width } = materialDimensions;

    const length = 'length' in materialDimensions ? materialDimensions.length : undefined;

    const dimensionsText = [
        thickness ? `${thickness}mm` : '',
        width ? `x ${width}mm` : '',
        length ? `x ${length}mm` : '',
    ]
        .filter(Boolean)
        .join(' ');

    const materialType = t(`material.materialType.value.${materialTypeProperty}`);

    const tolerance = material.tolerance ? t(`material.tolerance.value.${material.tolerance}`) : '';

    const productType = isPackage
        ? t(`domainModel:material.product.value.${Product.PACKAGE}`)
        : t(`domainModel:material.product.value.${cartData.product.material.product}`);

    const weight =
        cartData.product.weight && cartData.product.tradeUnit
            ? t(`material.tradeUnit.value.${cartData.product.tradeUnit}.quantity`, {
                  count: cartData.product.weight,
              })
            : '';

    const totalExcludingVat = formatCurrency(
        navigator.language,
        cartData.product.pricing.totalExcludingVat?.value,
        cartData.product.pricing.currencyCode
    );

    return (
        <div className="checkout-cart-summary">
            <div className="checkout-cart-summary__header-wraper">
                <CartSummaryHeader
                    symbol={isPackage ? cartData.productType.toLowerCase() : material.product.toLowerCase()}
                    firstLine={materialProperties.map((property) => property.label).join(', ')}
                    secondLine={`${productType}, ${materialType}, ${
                        cartData.product.prime
                            ? t('domainModel:material.quality.value.prime')
                            : t('domainModel:material.quality.value.nonPrime')
                    }, ${weight} `}
                    thirdLine={[dimensionsText, tolerance].filter(Boolean).join(', ')}
                />
                <div className="checkout-cart-summary__header-wraper__pricing">
                    <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__label">
                        {t(`uiDomain:listingBuyerDetailsPrice.totalExclVat`)}:
                    </span>
                    <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__value">
                        {totalExcludingVat}
                    </span>
                </div>
            </div>
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { LegalLayout } from '../../views/layouts/legal-layout/LegalLayout';
import { PageHeader } from '../page-header/PageHeader';

import './PrivacyPolicy.scss';

export const PrivacyPolicy = () => {
    const { t } = useTranslation('translation');
    const address = (
        <span>
            <span>SteelBuy Ltd.</span>
            <br />
            <span>{t('constants.primaryAddress.line1')} </span>
            <br />
            <span>
                {t('constants.primaryAddress.line2')} {t('constants.primaryAddress.line3')}
            </span>
            <br />
            <span>
                {t('constants.primaryAddress.line4')}, {t('constants.primaryAddress.line5')}{' '}
                {t('constants.primaryAddress.postcode')} UK
            </span>
        </span>
    );

    const httpAppLink = `https://${t('constants.marketplace')}`;
    const httpWebsiteLink = `https://${t('constants.website')}`;

    return (
        <>
            <PageHeader pageTitle={t('application.privacyPolicy.headerTitle')} />
            <LegalLayout>
                <div className="privacy-policy" id="top">
                    <h1 id="privacy-policy">Privacy Policy</h1>
                    <p>
                        <strong>Data controller (We)</strong> is:
                        <br />
                        {address}
                        <br />
                        Email:{' '}
                        <CustomLink path={`mailto: ${t('constants.emailInfo')}`} isInlineLink isAnchorLink>
                            {t('constants.emailInfo')}
                        </CustomLink>
                    </p>

                    <p>
                        Thank you for visiting SteelBuy via Our online Marketplace (
                        <CustomLink path={RoutePath.DASHBOARD}>{httpAppLink}</CustomLink>) and/or Our Website (
                        <CustomLink path={httpWebsiteLink} isInlineLink isAnchorLink>
                            {httpWebsiteLink}
                        </CustomLink>
                        ). Protection of your privacy is very important to Us. Below you will find extensive information
                        about how We handle your data.
                    </p>

                    <h2 id="1-access-data-and-hosting">1. Access data and hosting</h2>
                    <p>
                        You may visit Our Website without revealing any personal information. With every visit on Our
                        Website, the web server stores automatically only a so-called server log file which contains
                        e.g. the name of the requested file, your IP address, the date and time of the request, the
                        volume of data transferred and the requesting provider (access data), and documents the request.
                        These access data are analysed exclusively for the purpose of ensuring the smooth operation of
                        Our Website and improving Our offer. This serves according to Art. 6 (1) (f) GDPR the protection
                        of Our legitimate interests in the proper presentation of Our offer that are overriding in the
                        process of balancing of interests. All access data are deleted no later than seven days after
                        the end of your visit on Our Website.
                    </p>

                    <h3 id="1-1-hosting">1.1 Hosting</h3>
                    <p>
                        The services for hosting and displaying Our Marketplace and Our Website are partly provided by
                        Our service providers on the basis of processing on Our behalf. Unless otherwise stated in this
                        Privacy Policy, all access data and all data collected in forms provided for this purpose on Our
                        Marketplace and/or Our Website are processed on their servers. If you have any questions about
                        Our service providers and the basis of Our cooperation with them, please use the contact option
                        described in this Privacy Policy.
                    </p>

                    <h3 id="1-2-content-delivery-network">1.2 Content Delivery Network</h3>
                    <p>
                        For the purpose of a shorter loading time, We use for some offers a so-called Content Delivery
                        Network (&quot;CDN&quot;). This service provides content, e.g. large media files, via regionally
                        distributed servers of external CDN service providers. For this reason, access data will be
                        processed on the servers of these service providers. We engage Our service providers on the
                        basis of processing on Our behalf. If you have any questions about Our service providers and the
                        basis of Our cooperation with them, please use the contact option described in this Privacy
                        Policy.{' '}
                    </p>

                    <h2 id="2-data-processing-for-contract-processing-and-for-contacting-us">
                        2. Data processing for contract processing and for contacting Us
                    </h2>

                    <h3 id="2-1-data-processing-for-the-purposes-of-performing-the-contract">
                        2.1 Data processing for the purposes of performing the contract
                    </h3>

                    <p>
                        For the purpose of performing the contract (including enquiries regarding the processing of any
                        existing warranty and performance fault claims as well as any statutory updating obligations) in
                        accordance with Art. 6 (1) (b) GDPR, We collect personal data if you provide it to Us
                        voluntarily as part of your order. Mandatory fields are marked as such, as in these cases We
                        necessarily need the data to process the contract and We cannot send the order without their
                        specification. Which data is collected can be seen from the respective input forms.
                    </p>

                    <p>
                        Further information on the processing of your data, in particular on the forwarding of the data
                        to Our service providers for the purpose of order, payment and shipping, can be found in the
                        following sections of this Privacy Policy. After complete processing of the contract, your data
                        will be restricted for further processing and deleted after expiry of the retention periods
                        under tax and commercial law in accordance with Art. 6 (1) (c) GDPR, unless you have expressly
                        consented to further use of your data in accordance with Art. 6 (1) (a) GDPR or We reserve the
                        right to use data beyond this, which is permitted by law and about which We inform you in this
                        Privacy Policy.{' '}
                    </p>

                    <h3 id="2-2-customer-account">2.2 Customer account</h3>
                    <p>
                        We collect personal data if you voluntarily provide it to Us when opening a customer account.
                        Mandatory fields are marked as such, since in these cases We require the data to open the
                        customer account and you cannot complete the account opening without providing it. Which data is
                        collected can be seen from the respective input forms. We use the data you provide to perform
                        the contract and handle your inquiries in accordance with Art. 6 (1) (b) GDPR. The deletion of
                        your customer account is possible at any time and can be done either by sending a message to the
                        contact option described in this Privacy Policy or via a function provided for this purpose in
                        the customer account. After deletion of your customer account, your data will be deleted, unless
                        you have expressly consented to further use of your data pursuant to Art. 6 (1) (a) GDPR or We
                        reserve the right to use data beyond this, which is permitted by law and about which We inform
                        you in this Privacy Policy.{' '}
                    </p>

                    <h3 id="2-3-establishing-contact">2.3 Establishing contact</h3>
                    <p>
                        As part of Our customer communication, We collect personal data in order to process your
                        enquiries in accordance with Art. 6 (1) (b) GDPR if you voluntarily provide Us with this data
                        when contacting Us (e.g. via contact form or email). Mandatory fields are marked as such, as in
                        these cases We necessarily need the data to process your enquiry. Which data is collected can be
                        seen from the respective input forms. After your enquiry has been fully processed, your data
                        will be deleted unless you have expressly consented to further use of your data in accordance
                        with Art. 6 (1) (a) GDPR or We reserve the right to use data beyond this, which is permitted by
                        law and about which We inform you in this Privacy Policy.{' '}
                    </p>

                    <h2 id="3-data-processing-for-the-purposes-of-shipment">
                        3. Data processing for the purposes of shipment
                    </h2>
                    <p>
                        We forward your data to the shipping company within the scope required for the delivery of the
                        ordered goods according to Art. 6 (1) (b) GDPR.{' '}
                    </p>

                    <h2 id="4-data-processing-for-the-purposes-of-payment">
                        4. Data processing for the purposes of payment
                    </h2>
                    <p>
                        As part of the payment process in Our Marketplace, We work together with these partners:
                        technical service provider, credit institution, payment service provider.{' '}
                    </p>

                    <h3 id="4-1-data-processing-for-the-purposes-of-transaction-processing">
                        4.1 Data processing for the purposes of transaction processing
                    </h3>
                    <p>
                        Depending on the selected payment method, We forward the data necessary for processing the
                        payment transaction to Our technical service providers, who act for Us on the basis of
                        processing on Our behalf or to the authorised credit institutions or to the selected payment
                        service provider insofar as this is necessary for the payment process. This serves the
                        fulfilment of the contract according to Art. 6 (1) (b) GDPR. In certain cases, payment service
                        providers collect the data required for processing the payment themselves, e.g. on their own
                        website or via technical solution within the ordering process. In this respect, the Privacy
                        Policy of the respective payment service provider applies. If you have any questions about Our
                        payment processing partners and the basis of Our cooperation with them, please use the contact
                        option described in this Privacy Policy.{' '}
                    </p>

                    <h3 id="4-2-data-processing-for-the-purposes-of-fraud-prevention-and-optimisation-of-our-payment-processes">
                        4.2 Data processing for the purposes of fraud prevention and optimisation of Our payment
                        processes
                    </h3>
                    <p>
                        We may forward other data to Our service providers, which they use for the purpose of fraud
                        prevention and to optimise Our payment processes (e.g. invoicing, processing of contested
                        payments, accounting support) together with the data necessary to process the payment as Our
                        processors.
                        <br />
                        This serves to safeguard Our legitimate interests in fraud prevention or an efficient payment
                        management in accordance with Art. 6 (1) (f) GDPR that are overriding in the process of
                        balancing of interests.{' '}
                    </p>

                    <h3 id="4-3-credit-assessment">4.3 Credit assessment</h3>
                    <p>
                        In cases where We make deliveries before payment (e.g. in the case of a purchase on invoice) We
                        will obtain information about your identity and creditworthiness using the services of
                        specialised service providers (credit reference agencies). To this end, We will transfer your
                        personal data needed for the credit assessment to:
                    </p>

                    <p>
                        Atradius Collections Ltd.
                        <br />
                        Harbour Drive, Capital Waterside
                        <br />
                        Cardiff CF10 4WZ, United Kingdom
                        <br />
                        United Kingdom
                    </p>
                    <p>
                        This serves to safeguard Our legitimate interests in assessing the creditworthiness and
                        willingness to pay of Our potential customers prior to the conclusion of the contract and thus
                        to avoid financial losses in accordance with Art. 6 (1) (f) GDPR, that are overriding in the
                        process of balancing of interests. This is necessary for the conclusion of the contract in
                        accordance with Art. 22 (2) (a) GDPR. In this process, We will apply appropriate measures to
                        respect your rights, freedoms and legitimate interests. You can contact Us via the contact
                        option specified in this Privacy Policy to present your position and contest the decision.
                    </p>

                    <p>
                        After full implementation of the contract and after expiry of the tax and commercial legal
                        retention periods, your data processed for this purpose will be deleted, unless you have
                        expressly consented to further use of your data or We reserve the right to use your data for
                        other purposes which are permitted by law and about which We inform you in this Privacy Policy
                        notice.
                    </p>

                    <h2 id="5-marketing-via-e-mail-mail-telephone">5. Marketing via email, mail, telephone</h2>

                    <h3 id="5-1-e-mail-newsletter-with-subscription-and-newsletter-tracking">
                        5.1 Email newsletter with subscription and Newsletter tracking
                    </h3>

                    <p>
                        If you subscribe to Our newsletter/e-newsletter (Newsletter), We will regularly send you Our
                        email Newsletter based on your consent according to Art. 6 (1) (a) GDPR, using the data required
                        or disclosed by you separately for this purpose.
                    </p>
                    <p>
                        You can unsubscribe from the Newsletter at any time. This can either be done by sending a
                        message to the contact option described in this Privacy Policy or via a link provided for this
                        purpose in the Newsletter. After unsubscribing, We will delete your email address from the list
                        of recipients, unless you have expressly consented to the further use of your data according to
                        Art. 6 (1) (a) GDPR or We have reserved the right to use your data for other purposes that are
                        permitted by law and about which We inform you in this Privacy Policy.{' '}
                    </p>
                    <p>
                        We would like to point out that We evaluate your user behaviour when sending the Newsletter. For
                        this purpose, We also analyse your interaction with Our Newsletter by measuring, storing and
                        evaluating opening rates and click-through rates for the purpose of designing future newsletter
                        campaigns (Newsletter Tracking).{' '}
                    </p>
                    <p>
                        For this evaluation, the emails sent contain single-pixel technologies (e.g. so-called web
                        beacons, tracking pixels) that are stored on Our Website and/or Our Marketplace. For the
                        evaluations, We link the following &quot;newsletter data&quot; in particular
                    </p>
                    <ul>
                        <li>
                            the page from which the page was requested (so-called referrer URL), the date and time of
                            the request,
                        </li>
                        <li>the description of the type of web browser used,</li>
                        <li>the IP address of the requesting computer,</li>
                        <li>the email address,</li>
                        <li>the date and time of registration and confirmation</li>
                    </ul>
                    <p>
                        and the single-pixel technologies with your email address or your IP address and, if applicable,
                        an individual ID. Links contained in the newsletter/e-newsletter may also contain this ID.
                    </p>
                    <p>
                        If you do not wish to receive Newsletters, it is possible to unsubscribe from the Newsletter at
                        any time - as described above.
                    </p>
                    <p>The information will be stored as long as you have subscribed to the Newsletter. </p>

                    <h3 id="5-2-e-mail-advertising-without-subscription-to-the-newsletter-and-your-right-to-opt-out">
                        5.2 Email advertising without subscription to the Newsletter and your right to opt out
                    </h3>

                    <p>
                        If We receive your email address in connection with the sale of a product or service and you
                        have not opted out, We reserve the right to regularly email you offers for products from Our
                        product range that are similar to those you have already purchased. This serves the protection
                        of Our legitimate interests in promoting and advertising Our products to customers that are
                        overriding in the process of balancing of interests. You can opt out of this use of your email
                        address at any time by sending a message to the contact option specified in this Privacy Policy
                        or by using the opt-out link in the advertising email, without incurring any costs beyond the
                        cost of transfer calculated at the base rates. After unsubscribing, We will delete your email
                        address from the list of recipients, unless you have expressly consented to the further use of
                        your data according to Art. 6 (1) (a) GDPR or We have reserved the right to use your data for
                        other purposes that are permitted by law and about which We inform you in this Privacy Policy.
                    </p>

                    <h3 id="5-3-newsletter-mailing">5.3 Newsletter mailing</h3>
                    <p>
                        The Newsletter is sent to you by Our service provider who processes data on Our behalf and to
                        whom We disclose your email address. If you have any questions about Our service providers and
                        the basis of Our cooperation with them, please use the contact option described in this Privacy
                        Policy.
                    </p>

                    <p>
                        The Newsletter and the Newsletter Tracking shown above may also be sent by Our service providers
                        as part of processing on Our behalf. If you have any questions about Our service providers and
                        the basis of Our cooperation with them, please use the contact option described in this Privacy
                        Policy.{' '}
                    </p>

                    <p>
                        Our service providers are located and/or use servers in the following countries, for which the
                        European Commission has established an adequate level of data protection by decision: United
                        Kingdom.{' '}
                    </p>

                    <p>
                        Our service providers are also located and/or use servers in these countries: USA, Australia.
                        There is no European Commission adequacy decision for this country/these countries. Our
                        cooperation with you is based on these guarantees: Standard data protection clauses of the
                        European Commission.{' '}
                    </p>

                    <h3 id="5-4-sending-review-requests-by-e-mail">5.4 Sending review requests by email</h3>
                    <p>
                        If you have given Us your explicit consent to do so during or after placing your order in
                        accordance with Art. 6 (1) (a) GDPR, We will use your email address to request a review of your
                        order via the review system We use. This consent can be withdrawn at any time by sending a
                        message to the contact option described in this Privacy Policy or via a link provided for this
                        purpose in the review request.{' '}
                    </p>

                    <p>
                        <ul>
                            <li>
                                The review requests may also be sent by Our service providers on the basis of processing
                                on Our behalf. If you have any questions about Our service providers and the basis of
                                Our cooperation with them, please use the contact option described in this Privacy
                                Policy.
                            </li>
                        </ul>
                    </p>

                    <h3 id="5-5-postal-advertising-and-your-right-to-opt-out">
                        5.5 Postal advertising and your right to opt out
                    </h3>
                    <p>
                        Furthermore, We reserve the right to use your first and last name and your postal address for
                        Our advertising purposes, e.g. for sending interesting offers and information about Our products
                        by post. This serves to safeguard Our legitimate interests in promoting and advertising Our
                        products to customers according to Art. 6 (1) (f) GDPR that are overriding in the process of
                        balancing of interests.{' '}
                    </p>

                    <h3 id="5-6-phone-advertising">5.6 Phone advertising</h3>
                    <p>
                        If you have given your consent in accordance with Art. 6 (1) (a) GDPR, We will use the data
                        required for this purpose or provided separately by you for Our own advertising purposes, e.g.
                        to inform you about interesting offers and Our products. You can withdraw your consent at any
                        time, either by sending a message to the contact option described in this Privacy Policy or by
                        verbal notification within each call. After withdrawal, We will delete your telephone number
                        unless you have expressly consented to the further use of your data or We have reserved the
                        right to use your data for other purposes that are permitted by law and about which We inform
                        you in this Privacy Policy.{' '}
                    </p>

                    <h2 id="cookies-and-further-technologies">6. Cookies and further technologies</h2>

                    <h3 id="6-1-general-information">6.1 General information</h3>
                    <p>
                        In order to make visiting Our Website and Our Marketplace attractive and to enable the use of
                        certain functions, to display suitable products or for market research, We use technologies on
                        various pages, including so-called cookies. Cookies are small text files that are automatically
                        stored on your end device. Some of the cookies We use are deleted after the end of the browser
                        session, i.e. after closing your browser (so-called session cookies). Other cookies remain on
                        your end device and enable Us to recognise your browser during your next visit (persistent
                        cookies).
                    </p>

                    <p>
                        <strong>Protection of privacy for terminal devices</strong>
                    </p>
                    <p>
                        When you use Our Website and Our Marketplace, We use technologies that are absolutely necessary
                        in order to provide the telemedia service you have expressly requested. The storage of
                        information in your terminal device or access to information that is already stored in your
                        terminal device does not require consent in this respect.
                    </p>
                    <p>
                        For functions that are not absolutely necessary, the storage of information in your terminal
                        device or access to information that is already stored in your terminal device requires your
                        consent. Please note that if you do not give your consent, parts of Our Website and/or Our
                        Marketplace may not be available for unrestricted use. Any consent you may have given will
                        remain valid until you adjust or reset the respective settings in your terminal device.{' '}
                    </p>

                    <p>
                        <strong>Analytical / performance cookies:</strong> These cookies enable collecting anonymised
                        data about user behaviour on Our Website and/or Our Marketplace. We analyse them e.g. to improve
                        the functionality of Our Website and/or Our Marketplace and recommend you products that will be
                        interesting to you.
                    </p>
                    <p>
                        <strong>Essential cookies:</strong> These cookies are necessary to enable you to use Our Website
                        and/or Our Marketplace. This includes e.g. cookies that enable you to log into the customer area
                        or add items to your shopping cart.{' '}
                    </p>
                    <p>
                        <strong>Any downstream data processing through cookies and other technologies</strong>
                    </p>
                    <p>
                        We use such technologies that are strictly necessary for the use of certain functions of Our
                        Website and/or Our Marketplace (e.g. shopping cart function). These technologies are used to
                        collect and process IP addresses, time of visit, device and browser information as well as
                        information on your use of Our Website and/or Our Marketplace (e.g. information on your
                        preferences). This serves to safeguard Our legitimate interests in an optimised presentation of
                        Our offer that are overriding in the process of balancing of interests.{' '}
                    </p>
                    <p>
                        In addition, We use technologies to fulfil the legal obligations, which We are subject to (e.g.
                        to be able to prove consent to the processing of your personal data) as well as for web analysis
                        and online marketing. Further information on this, including the respective legal basis for data
                        processing, can be found in the following sections of this privacy policy.{' '}
                    </p>
                    <p>
                        You can find the cookies settings for your browser by clicking on the following links:
                        <CustomLink path={t('application.privacyPolicy.cookieLinks.edge')} isInlineLink isAnchorLink>
                            Microsoft Edge™
                        </CustomLink>{' '}
                        /
                        <CustomLink path={t('application.privacyPolicy.cookieLinks.safari')} isInlineLink isAnchorLink>
                            Safari™
                        </CustomLink>{' '}
                        /
                        <CustomLink path={t('application.privacyPolicy.cookieLinks.chrome')} isInlineLink isAnchorLink>
                            Chrome™
                        </CustomLink>{' '}
                        /
                        <CustomLink path={t('application.privacyPolicy.cookieLinks.firefox')} isInlineLink isAnchorLink>
                            Firefox™
                        </CustomLink>{' '}
                        /
                        <CustomLink path={t('application.privacyPolicy.cookieLinks.opera')} isInlineLink isAnchorLink>
                            Opera™
                        </CustomLink>
                    </p>
                    <p>
                        If you have consented to the use of the technologies in accordance with Art. 6 (1) (a) GDPR, you
                        can withdraw your consent at any time by sending a message to the contact option described in
                        the Privacy Policy. If cookies are not accepted, the functionality of Our Website and/or Our
                        Marketplace may be limited.{' '}
                    </p>

                    <h3 id="6-2-cookiebot-consent-management-plattform">6.2 Cookiebot Consent Management Plattform</h3>
                    <p>
                        On Our Website and/or Our Marketplace We use Cookiebot to inform you about the technologies We
                        use on Our Website and/or Our Marketplace and to obtain, manage and document your consent to the
                        processing of your personal data by these technologies. This is required under Art. 6 (1) (c)
                        GDPR to fulfil Our legal obligation under Art. 7 (1) 1 GDPR to be able to prove your consent to
                        the processing of your personal data, to which We are subject. The consent management service
                        Cookiebot is an offer from Cybot A/S, Havnegade 39, 1058 Copenhagen, Denmark, which processes
                        your data on Our behalf.{' '}
                    </p>
                    <p>
                        After submitting your cookie declaration on Our Website and/or Our Marketplace, Cookiebot&#39;s
                        web server stores your anonymized IP address, the date and time of your declaration, browser
                        information, the URL from which the declaration was sent, information about your consent
                        behaviour and an anonymous random key. In addition, a &quot;CookieConsent&quot; cookie is used,
                        which contains information about your consent behaviour and the key. Your data will be deleted
                        after twelve months, unless you have expressly consented to further use of your data in
                        accordance with Art. 6 (1) (a) GDPR or We reserve the right to use your data for other purposes
                        that are legally permitted and about which We inform you in this Privacy Policy.{' '}
                    </p>

                    <h2 id="7-use-of-cookies-and-other-technologies-for-web-analytics-and-advertising-purposes">
                        7. Use of cookies and other technologies for web analytics and advertising purposes
                    </h2>
                    <p>
                        If you have given your consent in accordance with Art. 6 (1) (a) GDPR, We use the following
                        cookies and other third-party technologies on Our Website and/or Our Marketplace. The data
                        collected in this context will be deleted after the relevant purpose has been fulfilled and We
                        have ended the use of the respective technology. You can withdraw your consent at any time with
                        effect for the future. Further information on your withdrawal options can be found in the
                        section &quot;cookies and further technologies&quot;. Further information, including the basis
                        of Our cooperation with the service providers can be found within the respective technologies.
                        If you have any questions about Our service providers and the basis of Our cooperation with
                        them, please use the contact option described in this Privacy Policy.{' '}
                    </p>

                    <h4 id="other-providers-of-web-analytics-and-online-marketing-services">
                        Other providers of web analytics and online marketing services
                    </h4>
                    <p>
                        <strong>Use of Matomo as a cloud solution for web analytics</strong>
                    </p>
                    <p>
                        For the purpose of website analysis, the software Matomo of
                        <CustomLink
                            path="https://matomo.org/faq/new-to-piwik/who-is-innocraft/"
                            isInlineLink
                            isAnchorLink
                        >
                            InnoCraft Ltd.
                        </CustomLink>
                        , 150 Willis St, 6011 Wellington, New Zealand automatically collects and stores data (IP
                        address, time of visit, device and browser information as well as information on your use of Our
                        Website and/or Our Marketplace), from which user profiles are created using pseudonyms. Cookies
                        may be used for this purpose. The pseudonymised user profiles will not be merged with personal
                        data about the bearer of the pseudonym without explicit consent of the user, which must be given
                        separately. Matomo acts on Our behalf. For New Zealand, the European Commission has established
                        an adequate level of data protection by means of an adequacy decision.
                    </p>

                    <h2 id="8-contact-options-and-your-rights">8. Contact options and your rights</h2>

                    <h3 id="8-1-your-rights">8.1 Your rights</h3>
                    <p>Being the data subject, you have the following rights according to:</p>
                    <ul className="your-rights-list">
                        <li>
                            art. 15 GDPR, the right to obtain information about your personal data which We process,
                            within the scope described therein;
                        </li>
                        <li>
                            art. 16 GDPR, the right to immediately demand rectification of incorrect or completion of
                            your personal data stored by Us;
                        </li>
                        <li>
                            art. 17 GDPR, the right to request erasure of your personal data stored with Us, unless
                            further processing is required
                            <ul>
                                <li>to exercise the right of freedom of expression and information;</li>
                                <li>for compliance with a legal obligation;</li>
                                <li>for reasons of public interest or</li>
                                <li>for establishing, exercising or defending legal claims;</li>
                            </ul>
                        </li>
                        <li>
                            art. 18 GDPR, the right to request restriction of processing of your personal data, insofar
                            as
                            <ul>
                                <li>the accuracy of the data is contested by you;</li>
                                <li>the processing is unlawful, but you refuse their erasure;</li>
                                <li>
                                    We no longer need the data, but you need it to establish, exercise or defend legal
                                    claims, or
                                </li>
                                <li>you have lodged an objection to the processing in accordance with art. 21 GDPR;</li>
                            </ul>
                        </li>
                        <li>
                            art. 20 GDPR, the right to receive your personal data that you have provided to Us in a
                            structured, commonly used and machine-readable format or to request its transmission to
                            another controller;
                        </li>
                        <li>
                            art. 77 GDPR, the right to complain to a supervisory authority. As a rule, you can contact
                            the supervisory authority at your habitual place of residence or workplace or at Our company
                            headquarters.
                        </li>
                    </ul>
                    <div className="right-to-object-box">
                        {/* Has a border around it */}
                        <h4 id="right-to-object">Right to object</h4>
                        <p>
                            If We process personal data as described above to protect Our legitimate interests that are
                            overriding in the process of balancing of interests, you may object to such data processing
                            with future effect. If your data are processed for direct marketing purposes, you may
                            exercise this right at any time as described above. If your data are processed for other
                            purposes, you have the right to object only on grounds relating to your particular
                            situation.{' '}
                        </p>
                        <p>
                            After you have exercised your right to object, We will no longer process your personal data
                            for such purposes unless We can demonstrate compelling legitimate grounds for the processing
                            which override your interests, rights and freedoms or for the establishment, exercise or
                            defence of legal claims.{' '}
                        </p>
                        <p>
                            This does not apply to the processing of personal data for direct marketing purposes. In
                            such a case We will no longer process your personal data for such purposes.
                        </p>
                    </div>

                    <h3 id="8-2-contact-options">8.2 Contact options</h3>
                    <p>
                        If you have any questions about how We collect, process or use your personal data, want to
                        enquire about, correct, restrict or delete your data, or withdraw any consents you have given,
                        or opt-out of any particular data use, please contact Us directly using the contact data
                        provided in Our supplier identification.{' '}
                    </p>
                    <p>
                        <strong>Data protection declaration created with the Trusted Shops legal copywriter.</strong>
                    </p>
                </div>
            </LegalLayout>
        </>
    );
};

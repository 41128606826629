import { AddressCollectionDataProvider } from '@steelbuy/data-provider';
import { CheckoutMyCart } from './CheckoutMyCart';
import { RoutePath } from '../../router/Routes';

type CheckoutMyCartViewProps = {
    goBackPath?: RoutePath;
};

export const CheckoutMyCartView = ({ goBackPath = RoutePath.MY_CART }: CheckoutMyCartViewProps) => (
    <AddressCollectionDataProvider>
        <CheckoutMyCart goBackPath={goBackPath} />
    </AddressCollectionDataProvider>
);

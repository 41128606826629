import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CustomOfferListingStatus,
    ListingSellerModel,
    NegotiationModel,
    OfferListingStatus,
    OfferView,
    SellerListingOfferStatus,
} from '@steelbuy/domain-model';
import { MonetaryAmount } from '@steelbuy/domain-model-types';
import { ListingSellerTeaser } from '@steelbuy/ui-domain';
import { ButtonTertiaryOnLightM, Card, DataHeader, LoadingSpinner, LoadingStatus } from '@steelbuy/ui-primitive';
import { useManageOffersContext } from './ManageOffersContext';
import { RoutePath, createRouteUrl } from '../../router/Routes';
import { OffersFilter } from '../offers/OffersFilter';

interface ManageOffersListProps {
    totalResults?: number;
    results: ReadonlyArray<ListingSellerModel>;
    hasNext: boolean | undefined;
    handleLoadMore: () => void;
    isLoading: boolean;
    isFetchingNextPage: boolean;
}

const calculatePrice = (negotiations: NegotiationModel[] | undefined) => {
    const offerPrices: MonetaryAmount[] = [];

    const acceptedOffer = negotiations?.find(
        ({ status }) => status === OfferListingStatus.ACCEPTED || status === OfferListingStatus.PURCHASED
    );

    if (acceptedOffer) {
        return acceptedOffer.offers[acceptedOffer.offers.length - 1].price.priceIncludingDelivery;
    }

    negotiations?.forEach((item: NegotiationModel) => {
        const lastOffer = item.offers.length && item.offers[item.offers.length - 1];
        if (lastOffer) {
            offerPrices.push(lastOffer.price.priceIncludingDelivery);
        }
    });

    return offerPrices.sort(({ value: a }, { value: b }) => a - b);
};

const getOfferStatus = (negotiations: NegotiationModel[] | undefined) => {
    if (!negotiations) {
        return undefined;
    }
    if (negotiations.length === 1) return negotiations[0].status;

    const statusCount = {} as Record<OfferListingStatus, number>;
    Object.values(OfferListingStatus).forEach((offerListingStatus) => {
        statusCount[offerListingStatus] = 0;
    });

    negotiations.forEach((negotiation) => {
        statusCount[negotiation.status] += 1;
    });

    let status: SellerListingOfferStatus | undefined;

    const nonActiveStatus = [
        OfferListingStatus.CANCELLED,
        OfferListingStatus.EXPIRED,
        OfferListingStatus.REJECTED,
        OfferListingStatus.WITHDRAWN,
    ];
    nonActiveStatus.forEach((offerListingStatus) => {
        if (statusCount[offerListingStatus] === negotiations.length) {
            status = offerListingStatus;
        }
    });

    if (status) {
        return status;
    }

    if (statusCount[OfferListingStatus.ACCEPTED] > 0) {
        return OfferListingStatus.ACCEPTED;
    }

    if (statusCount[OfferListingStatus.PURCHASED] > 0) {
        return OfferListingStatus.PURCHASED;
    }

    if (statusCount[OfferListingStatus.AWAITING_SELLER] > 0 || statusCount[OfferListingStatus.AWAITING_BUYER] > 0) {
        return undefined;
    }

    return CustomOfferListingStatus.NO_SUCCESSFUL_OFFERS;
};

const getNumberActiveOffers = (negotiations: NegotiationModel[] | undefined) =>
    negotiations?.filter(
        ({ status }) => status === OfferListingStatus.AWAITING_BUYER || status === OfferListingStatus.AWAITING_SELLER
    ).length || 0;

export const ManageOfferList = ({
    handleLoadMore,
    hasNext = false,
    results,
    totalResults,
    isLoading,
    isFetchingNextPage,
}: ManageOffersListProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { setFilter, filter } = useManageOffersContext();

    return (
        <div className="manage-offer-list">
            <DataHeader
                headingValue={
                    totalResults ? t('application.manageOffers.dataHeader.numberOfOffers', { count: totalResults }) : ''
                }
            >
                <OffersFilter
                    title={t('application.offerListingsFilter.selectLabel')}
                    placeholder={t('application.offerListingsFilter.selectPlaceholder')}
                    setFilter={setFilter}
                    filter={filter}
                    offerView={OfferView.SELLER}
                />
            </DataHeader>
            {isLoading ? (
                <div className="manage-offer-list__loading-container">
                    <LoadingSpinner fullHeight={false} />
                </div>
            ) : (
                !!results.length && (
                    <>
                        <div className="manage-offer-list__table">
                            {results.map(
                                (listing): ReactNode => (
                                    <Card
                                        isClickable
                                        url={createRouteUrl(RoutePath.MANAGE_OFFERS_DETAILS, ['listingId', listing.id])}
                                        key={listing.id}
                                    >
                                        <ListingSellerTeaser
                                            listing={listing}
                                            isNew={listing.negotiations?.some(
                                                (negotiation) =>
                                                    negotiation.status === OfferListingStatus.AWAITING_SELLER &&
                                                    !negotiation.isRead
                                            )}
                                            offerStatus={getOfferStatus(listing?.negotiations)}
                                            numberOffers={listing.negotiations?.length || 0}
                                            numberActiveOffers={getNumberActiveOffers(listing?.negotiations)}
                                            offerPrice={calculatePrice(listing?.negotiations)}
                                            referenceId={
                                                listing.negotiations?.length === 1
                                                    ? listing.negotiations[0].referenceId
                                                    : undefined
                                            }
                                            isOffer
                                        />
                                    </Card>
                                )
                            )}
                        </div>
                        {totalResults !== 0 && hasNext ? (
                            <div className="manage-offer-list__load-more">
                                <ButtonTertiaryOnLightM
                                    onClick={handleLoadMore}
                                    disabled={!hasNext}
                                    label={t('uiDomain:commonList.loadMore')}
                                    loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                />
                            </div>
                        ) : null}
                    </>
                )
            )}
        </div>
    );
};

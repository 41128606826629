import { ReactNode } from 'react';

import './Wizard.scss';

export type WizardProps = {
    children?: ReactNode;
};

export const Wizard = (props: WizardProps) => {
    const { children } = props;
    return <section className="wizard">{children}</section>;
};

import {
    commonFlatSheetCoatings,
    commonFlatSheetProtectiveColor,
    commonFlatSheetProtectiveCoverage,
    commonFlatSheetProtectiveThickness,
    commonFlatSheetProtectiveType,
} from '../../common/MaterialFlatCoatings';

export const aluminiumFlatSheetCoatings = commonFlatSheetCoatings;

export const aluminiumFlatSheetProtectiveThickness = commonFlatSheetProtectiveThickness;

export const aluminiumFlatSheetProtectiveColor = commonFlatSheetProtectiveColor;

export const aluminiumFlatSheetProtectiveType = commonFlatSheetProtectiveType;

export const aluminiumFlatSheetProtectiveCoverage = commonFlatSheetProtectiveCoverage;

import { stainlessFlatTreadGrades } from './StainlessFlatTreadPlate';
import {
    CoatingType,
    CoatingThicknessType,
    Product,
    CoatingCoverage,
    CoatingColourType,
    MillFinish,
    Polish,
    Grade,
} from '../../../MaterialModel.enums';

export const stainlessFlatGrades = [
    Grade.G_201_14372,
    Grade.G_202_14373,
    Grade.G_2205_14462,
    Grade.G_2507_14539,
    Grade.G_253_SMO_14547,
    Grade.G_301_14310,
    Grade.G_3CR12_14003,
    Grade.G_301LN_14318,
    Grade.G_302_14310,
    Grade.G_304_14301,
    Grade.G_304L_14307,
    Grade.G_304LN_14311,
    Grade.G_305_14303,
    Grade.G_309_14828,
    Grade.G_309S_14833,
    Grade.G_310S_14845,
    Grade.G_316_14401,
    Grade.G_316L_14335,
    Grade.G_316TI_14571,
    Grade.G_317L_14438,
    Grade.G_321_11541,
    Grade.G_347_14550,
    Grade.G_409_14512,
    Grade.G_430_14016,
    Grade.G_430TI_14520,
    Grade.G_434_14113,
    Grade.G_439_14510,
    Grade.G_441_14509,
    Grade.G_4589_14589,
    Grade.G_4622_14622,
    Grade.G_654_SMO_14652,
] as const;

export const stainlessFlatMillFinish = [
    MillFinish.MF_1D_HR,
    MillFinish.MF_2B_CR,
    MillFinish.MF_2D_CR,
    MillFinish.MF_2H,
    MillFinish.MF_2R_BA_CR,
    MillFinish.MF_2Q_CR,
];

export const stainlessFlatPolish = [
    Polish.MILL_STANDARD,
    Polish.BRIGHT_POLISH,
    Polish.CIRCULAR_POLISH,
    Polish.DULL_POLISH,
    Polish.GRIT_POLISH_240,
    Polish.GRIT_POLISH_360,
    Polish.MIRROR_POLISH,
    Polish.SATIN_POLISH,
];

type StainlessCoilModel = {
    product: Product.COIL;
    grade: typeof stainlessFlatGrades[number];
};

type StainlessSheetModel = {
    product: Product.SHEET;
    grade: typeof stainlessFlatGrades[number];
    coatingThicknessValue: CoatingThicknessType;
    coatingColour: CoatingColourType;
    coatingType: CoatingType;
    coatingCoverage: CoatingCoverage;
};

type StainlessPlateModel = {
    product: Product.PLATE;
    grade: typeof stainlessFlatGrades[number];
};

type StainlessTreadModel = {
    product: Product.TREAD;
    grade: typeof stainlessFlatTreadGrades[number];
};

type StainlessSteelMillFinishAndPolish = {
    millFinish: MillFinish;
    polish: Polish;
};

export type StainlessFlatMaterialModel =
    | (StainlessCoilModel | StainlessSheetModel | StainlessPlateModel | StainlessTreadModel) &
          StainlessSteelMillFinishAndPolish;

import { useMutation } from '@tanstack/react-query';
import { RFQDraftModel, RFQDraftModelConverter } from '@steelbuy/domain-model';
import axios from '../axios';

export type CreateRFQDraftModel = Omit<RFQDraftModel, 'id' | 'status' | 'missingFieldsCount'>;

const createRFQDraft = async (rfq: CreateRFQDraftModel) => {
    const modelConverter = new RFQDraftModelConverter();
    const { data } = await axios.post<RFQDraftModel>('/api/buyer/quotes/draft', modelConverter.toApiModel(rfq));
    return data;
};

export const useCreateRFQDraft = () =>
    useMutation({
        mutationFn: (rfq: CreateRFQDraftModel) => createRFQDraft(rfq),
    });

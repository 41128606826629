import { Skeleton, SkeletonType } from '@steelbuy/ui-primitive';
import './SearchSummarySkeleton.scss';

export const SearchSummarySkeleton = ({ count = 1 }: { count?: number }) => {
    const countArray = new Array(count).fill(0);
    return (
        <div className="skeleton-search-summary">
            {countArray.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="skeleton-search-summary-row" key={`search-summary-skeleton-${index}`}>
                    <Skeleton type={SkeletonType.TEXT} />
                    <Skeleton type={SkeletonType.TEXT} />
                </div>
            ))}
        </div>
    );
};

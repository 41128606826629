import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfDownloadFileFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfUploadFileFieldNames,
    DownloadFile,
    DownloadFileFieldNames,
    TimestampFieldNames,
    UploadFile,
    UploadFileFieldNames,
} from '@steelbuy/domain-model-types';

import { ListingBaseModelFoundation } from './ListingBaseSellerModel';
import { DeliveryPromise, ListingMaterialAge, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingSellerAutoRenewModelConverter } from './ListingSellerAutoRenewModel';
import { AnyMaterialDimensionModel, AnyMaterialDimensionModelConverter } from './MaterialDimensionModel';
import { AnyMaterialModel, AnyMaterialModelConverter } from './MaterialModel';

type PrimeListingModelFoundation =
    | {
          prime: true;
          description?: string;
          picture?: never;
          testCertificate: UploadFile | DownloadFile;
      }
    | {
          prime: false;
          description: string;
          picture?: UploadFile | DownloadFile;
          testCertificate?: never;
      };

export type ListingModelFoundation = ListingBaseModelFoundation & {
    age: ListingMaterialAge;
    origin: ListingMaterialOrigin;
    material: AnyMaterialModel;
    materialDimensions: AnyMaterialDimensionModel;
    numberOfItems?: number;
    deliveryPromise?: DeliveryPromise;
} & PrimeListingModelFoundation;

export class ListingModelFoundationConverter extends ModelConverter<ListingModelFoundation> {
    override getTimestampFields(): Array<
        TimestampFieldNames<ListingModelFoundation> | ArrayOfTimestampFieldNames<ListingModelFoundation>
    > {
        return ['expiresAt'];
    }

    override getDownloadFileFields(): Array<
        DownloadFileFieldNames<ListingModelFoundation> | ArrayOfDownloadFileFieldNames<ListingModelFoundation>
    > {
        return ['picture', 'testCertificate'];
    }

    override getUploadFileFields(): Array<
        UploadFileFieldNames<ListingModelFoundation> | ArrayOfUploadFileFieldNames<ListingModelFoundation>
    > {
        return ['picture', 'testCertificate'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<ListingModelFoundation> {
        return {
            material: new AnyMaterialModelConverter(),
            materialDimensions: new AnyMaterialDimensionModelConverter(),
            autoRenew: new ListingSellerAutoRenewModelConverter(),
        };
    }
}

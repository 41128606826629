import './IdentificationNumber.scss';

export type IdentificationNumberProps = {
    identificationNumber: string;
    label: string;
};

export const IdentificationNumber = (props: IdentificationNumberProps) => {
    const { identificationNumber, label } = props;

    return (
        <div className="identification-number">
            <span className="identification-number__label"> {label} </span>
            <span className="identification-number__number"> {identificationNumber}</span>
        </div>
    );
};

export enum IconIdentifier {
    ACTIVITY = 'activity',
    ADD = 'add',
    ADD_TO_LIST = 'add-to-list',
    ALERT = 'alert',
    ARROW_DOWN = 'arrow-down',
    ARROW_LEFT = 'arrow-left',
    ARROW_RIGHT = 'arrow-right',
    ARROW_UP = 'arrow-up',
    BELL = 'bell',
    BIN = 'bin',
    CANCEL = 'cancel',
    CART = 'cart',
    CHEVRON_DOWN = 'chevron-down',
    CHEVRON_LEFT = 'chevron-left',
    CHEVRON_RIGHT = 'chevron-right',
    CHEVRON_UP = 'chevron-up',
    CHEVRONS_DOWN = 'chevrons-down',
    CHEVRONS_UP = 'chevrons-up',
    CLOSE = 'close',
    COIL = 'coil',
    COLLAPSE = 'collapse',
    DASHBOARD = 'dashboard',
    DELIVERY = 'delivery',
    EDIT = 'edit',
    ERROR = 'error',
    EXPAND = 'expand',
    EYE = 'eye',
    EYE_CLOSE = 'eye-close',
    FILE = 'file',
    FILTER = 'filter',
    HAMBURGER = 'hamburger',
    HELP = 'help',
    HORN = 'horn',
    HORN_FLIPPED = 'horn-flipped',
    INFO = 'info',
    JUSTIFY = 'justify',
    LOCK = 'lock',
    MORE = 'more',
    MUTE = 'mute',
    NON_PRIME = 'non-prime',
    OFFER = 'offer',
    OK = 'ok',
    PACKAGE = 'package',
    PERSON = 'person',
    PLACEHOLDER = 'placeholder',
    PLATE = 'plate',
    PLUS = 'plus',
    PRIME = 'prime',
    REMOVE_1 = 'remove-1',
    RESET = 'reset',
    SEARCH = 'search',
    SETTINGS = 'settings',
    SHEET = 'sheet',
    SORT = 'sort',
    STAR = 'star',
    THUMBDOWN = 'thumbdown',
    THUMBUP = 'thumbup',
    TIMER = 'timer',
    TREAD = 'tread',
    UPLOAD = 'upload',
    WORKFLOW_ACTUAL = 'workflow-actual',
    WORKFLOW_EMPTY = 'workflow-empty',
    WORKFLOW_INCOMPLETE = 'workflow-incomplete',
    WORKFLOW_SUCCESS = 'workflow-success',
    BAG = 'bag',
}

import { FooCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { FooModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const fooEntityStoreName = 'foo-entity';
export type FooEntityStore = { [fooEntityStoreName]: EntityStore<FooModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new FooCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<FooModel, FooEntityStore, FooCrudApiClient>(fooEntityStoreName, createApiClient);
const mutateEntity = createMutateThunk<FooModel, FooCrudApiClient>(fooEntityStoreName, createApiClient);
const createEntity = createCreateThunk<FooModel, FooCrudApiClient>(fooEntityStoreName, createApiClient);
const deleteEntity = createDeleteThunk<FooModel, FooCrudApiClient>(fooEntityStoreName, createApiClient);
const entityService = createEntityServiceThunk<FooModel, FooCrudApiClient>(fooEntityStoreName, createApiClient);

// Slice definition
export const fooEntitySlice = createCrudEntitySlice<FooModel>(
    fooEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = fooEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<FooModel, FooEntityStore>(fooEntityStoreName);
const selectCreated = createSelectCreated<FooModel, FooEntityStore>(fooEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<FooModel, FooEntityStore>(fooEntityStoreName);

// Export the store accessors to be used by the data provider value
export const fooEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<FooModel, FooEntityStore>;

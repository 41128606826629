import { useTranslation } from 'react-i18next';
import { ModalAccessor } from '@steelbuy/modal-dialog';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const OfferInProgressModal = ({
    offerInProgressModal,
    handleBuyNow,
}: {
    offerInProgressModal: ModalAccessor;
    handleBuyNow: () => void;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <ConfirmCancelModel
            modalAccessor={offerInProgressModal}
            header={t('application.offers.buyNowModalheader')}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Neutral.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={t('application.offers.buynow')}
            onSubmit={handleBuyNow}
            isSubmitting={false}
            isDanger={false}
            onClose={offerInProgressModal.hide}
        />
    );
};

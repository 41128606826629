import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCode, formatCurrency } from '@steelbuy/currency';

import {
    Coating,
    CoatingThicknessType,
    Finish,
    Grade,
    isStackableProduct,
    ListingMaterialAge,
    ListingMaterialOrigin,
    Product,
    Shape,
    MaterialType,
    Tolerance,
    Definition,
    ProductType,
    Specification,
    Surface,
    Temper,
    TradeUnit,
    isMaterialWithPrime,
    MillFinish,
    Polish,
    PricingUnit,
} from '@steelbuy/domain-model';
import { MonetaryAmount } from '@steelbuy/domain-model-types';
import { Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { formatDayname, formatTime } from '@steelbuy/util';
import { useMaterialProperties } from '../material/Material';

import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';

import './CreateListingTeaser.scss';

const PROPERTIES_ORDER = [
    'definition',
    'plateType',
    'grade',
    'temper',
    'surface',
    'specification',
    'finish',
    'millFinish',
    'polish',
    'coating',
    'coatingThicknessValue',
];

export type CreateListingTeaserProps = {
    // material step
    sku?: string;
    materialType?: MaterialType;
    prime?: boolean;
    shape?: Shape;
    product?: Product;
    definition?: Definition;
    plateType?: ProductType;
    grade?: Grade;
    specification?: Specification;
    surface?: Surface;
    finish?: Finish;
    coating?: Coating;
    coatingThicknessValue?: CoatingThicknessType;
    age?: ListingMaterialAge;
    origins?: ListingMaterialOrigin[];
    temper?: Temper;
    millFinish?: MillFinish;
    polish?: Polish;

    // dimensions step
    thickness?: number;
    tolerance?: Tolerance;
    width?: number;
    length?: number;
    tradeUnit?: TradeUnit;
    pricingUnit?: PricingUnit;

    // listupload step
    packageTitle?: string;

    // weightPrice step
    weight?: number;
    numberOfItems?: number;
    sellingPriceAmount?: MonetaryAmount;
    sellingPriceCurrencyCode?: CurrencyCode;
    expirationDate?: Date;

    // pickUpAdress step
    // eslint-disable-next-line react/no-unused-prop-types
    pickupAddressId?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    additionalInformation?: string;
};

// TODO: create draft type, simplify props
export const CreateListingTeaser = (props: CreateListingTeaserProps) => {
    const {
        sku,
        materialType,
        prime,
        shape,
        product,
        definition,
        plateType,
        grade,
        specification,
        surface,
        finish,
        temper,
        coating,
        coatingThicknessValue,
        millFinish,
        polish,
        age,
        origins,
        thickness,
        tolerance,
        width,
        length,
        weight,
        numberOfItems,
        sellingPriceAmount,
        sellingPriceCurrencyCode,
        expirationDate,
        tradeUnit = TradeUnit.TON,
        pricingUnit,
        packageTitle,
    } = props;

    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const numberOfItemsLabel = useNumberOfItems(product, numberOfItems);

    const materialProperties = useMaterialProperties({
        coating,
        coatingThicknessValue,
        definition,
        finish,
        grade,
        temper,
        plateType,
        specification,
        surface,
        millFinish,
        polish,
        materialType,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const renderIcon = () => {
        if (product) {
            return (
                <div className="create-listing-teaser__header__icon">
                    <Icon name={IconIdentifier[product]} />
                </div>
            );
        }
        return null;
    };

    const attributes1: ReactNode[] = [];

    if (sku) {
        attributes1.push(
            <span className="create-listing-teaser__attributes__sku">
                {t('uiDomain:createListingTeaser.attributeSku')}
                :&nbsp;
                {sku}
            </span>
        );
    }
    if (prime && isMaterialWithPrime(props)) {
        attributes1.push(
            <span className="create-listing-teaser__attributes__prime">
                {t('domainModel:material.quality.value.prime')}
            </span>
        );
    }
    if (shape) {
        attributes1.push(
            <span className="create-listing-teaser__attributes__shape">
                {t(`domainModel:material.shape.value.${shape}`)}
            </span>
        );
    }
    if (age && age !== ListingMaterialAge.UNSPECIFIED) {
        attributes1.push(
            <span className="create-listing-teaser__attributes__age">
                {t('uiDomain:createListingTeaser.attributeAge')}
                :&nbsp;
                {t(`domainModel:material.age.value.${age}`)}
            </span>
        );
    }
    if (origins && !origins.includes(ListingMaterialOrigin.UNSPECIFIED)) {
        origins.forEach((orgin) =>
            attributes1.push(
                <span className="create-listing-teaser__attributes__origin">
                    {t(`domainModel:material.origin.value.${orgin}`)}
                </span>
            )
        );
    }

    const dimensions: ReactNode[] = [];

    if (thickness) {
        dimensions.push(
            <span className="create-listing-teaser__attributes__dimension-attribute">
                {t('domainModel:material.dimensions.units.millimetersWithValue', { value: thickness })}
            </span>
        );
    }
    if (width) {
        dimensions.push(
            <span className="create-listing-teaser__attributes__dimension-attribute">
                {t('domainModel:material.dimensions.units.millimetersWithValue', { value: width })}
            </span>
        );
    }
    if (length) {
        dimensions.push(
            <span className="create-listing-teaser__attributes__dimension-attribute">
                {t('domainModel:material.dimensions.units.millimetersWithValue', { value: length })}
            </span>
        );
    }

    const attributes2: ReactNode[] = [];

    if (weight !== undefined) {
        attributes2.push(
            <span className="create-listing-teaser__attributes__weight">
                {t(`domainModel:material.tradeUnit.value.${tradeUnit}.quantity`, { count: weight })}
            </span>
        );
    }

    if (isStackableProduct(product) && numberOfItems) {
        attributes2.push(
            <span className="create-listing-teaser__attributes__number-of-items">{numberOfItemsLabel}</span>
        );
    }
    if (sellingPriceAmount && sellingPriceCurrencyCode && pricingUnit) {
        attributes2.push(
            <span className="create-listing-teaser__attributes__selling-price-amount">
                {formatCurrency(navigator.language, sellingPriceAmount.value, sellingPriceCurrencyCode)}
                &nbsp;
                {t('domainModel:material.perPricingUnit', {
                    pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                })}
            </span>
        );
    }
    if (expirationDate) {
        attributes2.push(
            <span className="create-listing-teaser__attributes__expiration-date">
                {t('uiDomain:createListingTeaser.expirationTime') + formatDayname(navigator.language, expirationDate)},
                {formatTime(navigator.language, expirationDate)}
            </span>
        );
    }

    const orderedMaterialProperties = [...materialProperties].sort(
        (a, b) => PROPERTIES_ORDER.indexOf(a.name) - PROPERTIES_ORDER.indexOf(b.name)
    );

    return (
        <div className="create-listing-teaser">
            <div className="create-listing-teaser__header">
                {renderIcon()}
                <div className="create-listing-teaser__header__product">
                    <span className="create-listing-teaser__header__product__name">
                        {product !== Product.PACKAGE
                            ? orderedMaterialProperties.map((property) => property.label).join(', ')
                            : packageTitle}
                    </span>
                    <span className="create-listing-teaser__header__product__type">
                        {product && <span>{t(`domainModel:material.product.value.${product}`)}</span>}
                        {materialType && <span>, {t(`domainModel:material.materialType.value.${materialType}`)}</span>}
                    </span>
                </div>
            </div>
            <div className="create-listing-teaser__attributes">
                {attributes1.map((attribute, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {index > 0 && ', '}
                        {attribute}
                    </Fragment>
                ))}

                {dimensions.length > 0 ? ', ' : null}

                <span className="create-listing-teaser__attributes__dimensions">
                    {dimensions.map((dimension, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                            {index > 0 && ' x '}
                            {dimension}
                        </Fragment>
                    ))}

                    {tolerance && (
                        <span className="create-listing-teaser__attributes__dimension-attribute">
                            , {t(`domainModel:material.tolerance.value.${tolerance}`)}
                        </span>
                    )}
                </span>

                {(dimensions.length > 0 || attributes1.length > 0) && attributes2.length > 0 ? ', ' : null}

                {attributes2.map((attribute, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {index > 0 && ', '}
                        {attribute}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

import './Skeleton.scss';

export enum SkeletonType {
    TITLE = 'title',
    HELPER_TEXT = 'helper-text',
    TEXT = 'text',
}

type SkeletonComponentProps = {
    type: SkeletonType;
};

type SkeletonProps = {
    type: SkeletonType;
    count?: number;
};

const SkeletonComponent = ({ type }: SkeletonComponentProps) => <div className={`skeleton skeleton-${type}`} />;

export const Skeleton = ({ type, count = 1 }: SkeletonProps) => {
    const countArray = new Array(count).fill(0);
    return (
        <>
            {countArray.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonComponent type={type} key={`skeleton-${type}-${index}`} />
            ))}
        </>
    );
};

import { CustomError } from '@steelbuy/error';
import { Optional, Potentially } from '@steelbuy/types';
import { ApiMonetaryAmount, MonetaryAmount } from './MonetaryAmount';
import { isApiMonetaryAmount, isMonetaryAmount } from './MonetaryAmount.guards';

export const createMonetaryAmountFromApiMonetaryAmount = (
    apiMonetaryAmount: Potentially<ApiMonetaryAmount>
): Optional<MonetaryAmount> => {
    let monetaryAmount: MonetaryAmount | null = null;
    if (isApiMonetaryAmount(apiMonetaryAmount)) {
        monetaryAmount = {
            discriminator: 'MonetaryAmount',
            value: apiMonetaryAmount,
        };
    }
    return new Optional<MonetaryAmount>(monetaryAmount);
};

export const createApiMonetaryAmountFromMonetaryAmount = (
    monetaryAmount: Potentially<MonetaryAmount>
): Optional<ApiMonetaryAmount> => {
    let apiMonetaryAmount = null;
    if (isMonetaryAmount(monetaryAmount)) {
        if (!Number.isInteger(monetaryAmount.value)) {
            throw new CustomError('Converting MonetaryAmount failed. Not an integer.');
        }
        apiMonetaryAmount = monetaryAmount.value;
    }
    return new Optional<ApiMonetaryAmount>(apiMonetaryAmount);
};

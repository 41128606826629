import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { Icon } from '../../icon/Icon';

import { IconIdentifier } from '../../icon/Icon.enums';

import './ButtonGhostSystemOnLightS.scss';

export type ButtonGhostSystemOnLightSProps = {
    label?: string;
    icon?: IconIdentifier;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonGhostSystemOnLightS = (props: ButtonGhostSystemOnLightSProps) => {
    const { label, icon, type = 'button', ...rest } = props;

    const buttonGhostSystemOnLightSPropsClassMap = {
        'button-ghost-system-on-light-s': true,
        'button-ghost-system-on-light-s__icon': !label,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-ghost-system-on-light-s__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    return (
        <button type={type} className={buildClassStringFromClassMap(buttonGhostSystemOnLightSPropsClassMap)} {...rest}>
            {renderLabel()}
            {renderIcon()}
        </button>
    );
};

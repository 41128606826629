import { useTranslation } from 'react-i18next';
import { RFQBuyerModel, RFQStatus } from '@steelbuy/domain-model';
import { Timestamp, createDateFromTimestamp } from '@steelbuy/domain-model-types';
import { BadgeSecondary } from '@steelbuy/ui-primitive';
import { DaynameFormat, formatDate, formatDayname, formatTime } from '@steelbuy/util';
import { RFQTeaser } from './RFQTeaser';
import { RFQTeaserHeader } from './RFQTeaserHeader';
import { RFQTeaserQuality } from './RFQTeaserQuality';
import { RFQTeaserStatus } from './RFQTeaserStatus';
import { getBadgeVariant } from '../status-badge-variant/StatusBadgeVariant';

export const RFQBuyerTeaser = ({ rfq }: { rfq: RFQBuyerModel }) => {
    const { t } = useTranslation('uiDomain');
    const { expiresAt, status } = rfq;

    const formatDateTime = (timestamp: Timestamp) => {
        const date = createDateFromTimestamp(timestamp);
        return `${formatDate(navigator.language, date)} ${formatTime(navigator.language, date)}`;
    };

    const formatExpiryDateWithDayName = (timestamp: Timestamp) => {
        const date = createDateFromTimestamp(timestamp);
        const expiryDate = `${formatDayname(navigator.language, date, undefined, DaynameFormat.SHORT)} ${formatDate(
            navigator.language,
            date
        )}`;
        return t('rfqBuyerTeaser.expiryDate', { expiryDate });
    };

    const getStatusLabel = (rfqStatus: RFQStatus) => t(`rfqTeaserStatusBadge.status.${rfqStatus}`);

    const getModifiedAtLabel = (timestamp: Timestamp, rfqStatus: RFQStatus) => {
        const formattedDate = formatDateTime(timestamp);
        return `${getStatusLabel(rfqStatus)}: ${formattedDate}`;
    };

    const renderBadge = () => {
        if (status.value === RFQStatus.EXPIRED) {
            return (
                <BadgeSecondary
                    variant={getBadgeVariant(status.value)}
                    label={getModifiedAtLabel(expiresAt, status.value)}
                />
            );
        }
        return (
            <RFQTeaserStatus
                expiryTime={status.value !== RFQStatus.WITHDRAWN ? formatExpiryDateWithDayName(expiresAt) : undefined}
                modifiedAt={getModifiedAtLabel(status.modifiedAt, status.value)}
                status={status.value}
            />
        );
    };

    return (
        <RFQTeaser>
            <RFQTeaserHeader quoteEntry={rfq.quoteEntry} referenceNumber={rfq.quoteReferenceNumber} />
            <div className="rfq-list-teaser__container">
                <RFQTeaserQuality />
                {renderBadge()}
            </div>
        </RFQTeaser>
    );
};

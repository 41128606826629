import { ListingBuyerModel, ListingBuyerModelConverter } from '@steelbuy/domain-model';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class ListingSearchResultCrudApiClient extends AbstractCrudApiClient<ListingBuyerModel> {
    protected collectionControllerUri = 'api/universal/search-listings/';

    protected paginationControllerUri = 'api/universal/search-listings/';

    protected entityCreateUri = 'api/universal/search-listings/';

    protected entityFetchUri = 'api/universal/search-listings/{id}/';

    protected entityMutateUri = 'api/universal/search-listings/{id}/';

    protected entityDeleteUri = 'api/universal/search-listings/{id}/';

    protected entityServiceCallUri = 'api/universal/search-listings/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new ListingBuyerModelConverter();
}

import { Tenant } from '@steelbuy/api-integration';

import { ResetPassword } from '@steelbuy/ui-domain';
import { constants } from '../constants';
import { RoutePath } from '../router/Routes';

export const ResetPasswordView = () => (
    <ResetPassword
        tenant={Tenant.MARKETPLACE}
        apiBaseUrl={constants.apiBaseUrl}
        loginPath={RoutePath.LOGIN}
        forgotPasswordPath={RoutePath.FORGOT_PASSWORD}
    />
);

import { AccessibleUrl, ApiAccessibleUrl, ApiProtectedUrl, ApiUrl, ProtectedUrl, Url } from './Url';

export const isAccessableUrl = (accessableUrl?: unknown): accessableUrl is AccessibleUrl => {
    if ((accessableUrl ?? null) === null) {
        return false;
    }
    return (
        (accessableUrl as AccessibleUrl).discriminator === 'Url' &&
        (accessableUrl as AccessibleUrl).accessible &&
        (accessableUrl as AccessibleUrl).value !== undefined
    );
};

export const isProtectedUrl = (protectedUrl?: unknown): protectedUrl is ProtectedUrl => {
    if ((protectedUrl ?? null) === null) {
        return false;
    }
    return (
        (protectedUrl as ProtectedUrl).discriminator === 'Url' &&
        !(protectedUrl as ProtectedUrl).accessible &&
        (protectedUrl as ProtectedUrl).value !== undefined
    );
};

export const isUrl = (url?: unknown): url is Url => {
    if ((url ?? null) === null) {
        return false;
    }
    return isAccessableUrl(url) || isProtectedUrl(url);
};

export const isApiAccessableUrl = (apiAccessableUrl?: unknown): apiAccessableUrl is ApiAccessibleUrl => {
    if ((apiAccessableUrl ?? null) === null) {
        return false;
    }
    // noinspection PointlessBooleanExpressionJS
    return (
        (apiAccessableUrl as ApiAccessibleUrl).accessible === true &&
        (apiAccessableUrl as ApiAccessibleUrl).value !== undefined
    );
};

export const isApiProtectedUrl = (apiProtectedUrl?: unknown): apiProtectedUrl is ApiProtectedUrl => {
    if ((apiProtectedUrl ?? null) === null) {
        return false;
    }
    // noinspection PointlessBooleanExpressionJS
    return (
        (apiProtectedUrl as ApiProtectedUrl).accessible === false &&
        (apiProtectedUrl as ApiProtectedUrl).value !== undefined
    );
};

export const isApiUrl = (apiUrl?: unknown): apiUrl is ApiUrl => {
    if ((apiUrl ?? null) === null) {
        return false;
    }
    return isApiAccessableUrl(apiUrl) || isApiProtectedUrl(apiUrl);
};

import { ReactNode } from 'react';

import './CardContentTracking.scss';

export type CardContentTrackingProps = {
    header: string;
    children: ReactNode;
};

export const CardContentTracking = (props: CardContentTrackingProps) => {
    const { header, children } = props;

    return (
        <article className="card-content-tracking">
            <span className="card-content-tracking__header">{header}</span>
            <article className="card-content-tracking__tracking_indicator">{children}</article>
        </article>
    );
};

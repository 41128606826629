import { MonetaryAmount } from '@steelbuy/domain-model-types';

export const AUTO_RENEW_WINDOW_MS = 24 * 60 * 60 * 1000; // 24 hours

export const getMonetaryAmount = (value?: number): MonetaryAmount | undefined =>
    value === undefined
        ? undefined
        : {
              discriminator: 'MonetaryAmount',
              value,
          };

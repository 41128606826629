import { ListingBuyerSearchApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingBuyerSearchResultModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';

import { createSearchSlice, createSelectCollection } from '../../util/store/crud/SearchSlice';
import { SearchStore } from '../../util/store/crud/SearchStore';
import { SearchStoreAccessors } from '../../util/store/crud/SearchStoreAccessor';
import { createSearchAllThunk, createSearchNextThunk } from '../../util/store/crud/Thunks';

// Basic definition
export const listingBuyerSearchStoreName = 'listing-buyer-collection-search';
export type ListingBuyerSearchStore = {
    [listingBuyerSearchStoreName]: SearchStore<ListingBuyerSearchResultModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingBuyerSearchApiClient().init(apiBaseUrl, jsonWebToken);
const fetchAll = createSearchAllThunk<
    ListingBuyerSearchResultModel,
    ListingBuyerSearchStore,
    ListingBuyerSearchApiClient
>(listingBuyerSearchStoreName, createApiClient);
const fetchNext = createSearchNextThunk<
    ListingBuyerSearchResultModel,
    ListingBuyerSearchStore,
    ListingBuyerSearchApiClient
>(listingBuyerSearchStoreName, createApiClient);

// Slice definition
export const listingBuyerSearchSlice = createSearchSlice<ListingBuyerSearchResultModel>(
    listingBuyerSearchStoreName,
    fetchAll,
    fetchNext
);
const { resetFetchStatus } = listingBuyerSearchSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<ListingBuyerSearchResultModel, ListingBuyerSearchStore>(
    listingBuyerSearchStoreName
);

// Export the store accessors to be used by the data provider value
export const listingBuyerSearchStoreAccessors = {
    fetchAll,
    fetchNext,
    resetFetchStatus,
    selectCollection,
} as SearchStoreAccessors<ListingBuyerSearchResultModel, ListingBuyerSearchStore>;

import { JsonWebToken } from '@steelbuy/auth';
import {
    AccessDeniedError,
    ApiError,
    AuthenticationRequiredError,
    CustomError,
    NetworkError,
    NotFoundError,
    RateLimitError,
    TimeoutError,
    ValidationError,
    getNetworkErrorMessage,
} from '@steelbuy/error';
import { JsonTransportValue, Nullable } from '@steelbuy/types';
import { Timeout, reportNRError } from '@steelbuy/util';

export enum CorsMode {
    CORS = 'cors',
    NO_CORS = 'no-cors',
    SAME_ORIGIN = 'same-origin',
}

export type RestError =
    | AccessDeniedError
    | ApiError
    | AuthenticationRequiredError
    | CustomError
    | NetworkError
    | NotFoundError
    | TimeoutError
    | ValidationError;

enum HttpVerb {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
}

type JsonExceptionDetailsTransportValue = {
    message: string;
};

type JsonExceptionTransportValue = {
    status?: number;
    message: string;
    timestamp: string;
    details?: Array<JsonExceptionDetailsTransportValue>;
};

type RestResponse<Body> = {
    body: Body;
    headers: Map<string, string>;
};

const stripTrailingSlashes = (str: string) => str.replace(/\/+(\?)/, '$1').replace(/\/+$/, '');

export class JsonRestConnector {
    private readonly corsMode: CorsMode;

    private readonly jsonWebToken: Nullable<JsonWebToken> = null;

    constructor(jsonWebToken: Nullable<JsonWebToken> = null, corsMode = CorsMode.CORS) {
        this.jsonWebToken = jsonWebToken;
        this.corsMode = corsMode;
    }

    public async get(uri: string): Promise<RestResponse<Array<JsonTransportValue> | JsonTransportValue | null>> {
        const request = new Request(stripTrailingSlashes(uri), this.buildRequestOptions(HttpVerb.GET));
        try {
            const response = await this.perform(request);
            const responseBody = await this.unwrapResponse(response);
            return {
                body: this.handleResponse(response, responseBody),
                headers: this.unwrapHeaders(response),
            };
        } catch (e: unknown) {
            if (e instanceof Error) {
                e.message = getNetworkErrorMessage(e.message, 'GET', uri);
            }
            throw e;
        }
    }

    public async put(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
        const request = new Request(stripTrailingSlashes(uri), this.buildRequestOptionsWithBody(HttpVerb.PUT, data));
        try {
            const response = await this.perform(request);
            const responseBody = await this.unwrapResponse(response);
            return {
                body: this.handleResponse(response, responseBody),
                headers: this.unwrapHeaders(response),
            };
        } catch (e: unknown) {
            if (e instanceof Error) {
                e.message = getNetworkErrorMessage(e.message, 'PUT', uri);
            }
            throw e;
        }
    }

    public async patch(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
        const request = new Request(stripTrailingSlashes(uri), this.buildRequestOptionsWithBody(HttpVerb.PATCH, data));
        try {
            const response = await this.perform(request);
            const responseBody = await this.unwrapResponse(response);
            return {
                body: this.handleResponse(response, responseBody),
                headers: this.unwrapHeaders(response),
            };
        } catch (e: unknown) {
            if (e instanceof Error) {
                e.message = getNetworkErrorMessage(e.message, 'PATCH', uri);
            }
            throw e;
        }
    }

    public async post(uri: string, data: JsonTransportValue): Promise<RestResponse<JsonTransportValue>> {
        const request = new Request(stripTrailingSlashes(uri), this.buildRequestOptionsWithBody(HttpVerb.POST, data));
        try {
            const response = await this.perform(request);
            const responseBody = await this.unwrapResponse(response);
            return {
                body: this.handleResponse(response, responseBody),
                headers: this.unwrapHeaders(response),
            };
        } catch (e: unknown) {
            if (e instanceof Error) {
                e.message = getNetworkErrorMessage(e.message, 'POST', uri);
            }
            throw e;
        }
    }

    public async delete(uri: string): Promise<RestResponse<Nullable<JsonTransportValue>>> {
        const request = new Request(stripTrailingSlashes(uri), this.buildRequestOptions(HttpVerb.DELETE));
        try {
            const response = await this.perform(request);
            const responseBody = await this.unwrapResponse(response);
            return {
                body: this.handleResponse(response, responseBody),
                headers: this.unwrapHeaders(response),
            };
        } catch (e: unknown) {
            if (e instanceof Error) {
                e.message = getNetworkErrorMessage(e.message, 'DELETE', uri);
            }
            throw e;
        }
    }

    private buildRequestOptions(method: HttpVerb): RequestInit {
        const requestHeaders: Record<string, string> = {
            'Accept-Language': '',
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        const jsonWebToken = this.jsonWebToken?.accessToken ?? null;
        if (jsonWebToken !== null) {
            requestHeaders['Authorization'] = `Bearer ${jsonWebToken}`;
        }
        return {
            method: String(method),
            cache: 'no-cache',
            mode: this.corsMode,
            headers: requestHeaders,
            credentials: 'include',
        };
    }

    private buildRequestOptionsWithBody(method: HttpVerb, body: JsonTransportValue): RequestInit {
        return {
            ...this.buildRequestOptions(method),
            body: JSON.stringify(body),
        };
    }

    private async perform(request: Request): Promise<Response> {
        return Timeout.wrap<Response>(fetch(request), 30000, new TimeoutError('Request timeout'));
    }

    private async unwrapResponse(response: Response): Promise<Nullable<JsonTransportValue>> {
        if (response === null || response.body === null || response.status === 204) {
            return null;
        }
        try {
            return await response.json();
        } catch (e) {
            const error = new ApiError(`Parse response failed with message: ${(e as Error).message}`);
            reportNRError(error);
            throw error;
        }
    }

    private unwrapHeaders(response: Response): Map<string, string> {
        const headers = new Map();
        if (response === null || response.headers === null) {
            return headers;
        }
        response.headers.forEach((value, key) => {
            headers.set(key.toLowerCase(), value);
        });
        return headers;
    }

    private handleResponse(
        response: Response,
        responseBody: Nullable<JsonTransportValue>
    ): Nullable<JsonTransportValue> {
        if (
            (this.corsMode === CorsMode.NO_CORS && response.status === 0) ||
            (response.status >= 200 && response.status < 300)
        ) {
            return responseBody;
        }

        const exceptionResponseBody = responseBody as JsonExceptionTransportValue;

        let error;
        if (response.status < 200) {
            error = new NetworkError(
                `Request failed with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status === 400) {
            error = new ValidationError(
                `Invalid request with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody?.details
            );
        }
        if (!error && response.status === 401) {
            error = new AuthenticationRequiredError(
                `Authentication required with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status === 403) {
            error = new AccessDeniedError(
                `Forbidden with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status === 404) {
            error = new NotFoundError(
                `Not found with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status === 408) {
            error = new TimeoutError(
                `Response timeout with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status === 429) {
            error = new RateLimitError(
                `Request rate limited with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error && response.status >= 400) {
            error = new ApiError(
                `Unexpected response with message ${exceptionResponseBody.message}`,
                exceptionResponseBody?.status,
                exceptionResponseBody?.timestamp,
                exceptionResponseBody.message
            );
        }
        if (!error) {
            error = new CustomError('HTTP connector error');
        }
        reportNRError(error);
        throw error;
    }
}

import { useWindowSize } from '@steelbuy/util';
import { WizardBarItem, WizardBarItemProps } from './wizard-bar-item/WizardBarItem';

import './WizardBar.scss';

const SHOW_SMALL_LABEL_SCREEN_SIZE = 675;

export type WorkflowWizardBarProps = {
    items: Array<WizardBarItemProps>;
    currentStepIndex: number;
};

export const WizardBar = ({ currentStepIndex, items }: WorkflowWizardBarProps) => {
    const { width } = useWindowSize();

    const renderItems = () => {
        if (items === undefined) {
            return null;
        }
        return items.map((item, index) => (
            <WizardBarItem
                key={item.label}
                status={item.status}
                label={width > SHOW_SMALL_LABEL_SCREEN_SIZE || index === currentStepIndex ? item.label : `${index + 1}`}
                onClick={item.onClick}
                selected={index === currentStepIndex}
            />
        ));
    };

    return <article className="wizard-bar">{renderItems()}</article>;
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetInvoiceAddress, useGetWarehouseAddress } from '@steelbuy/data-access';
import { useUserSelf } from '@steelbuy/data-provider';
import { AddressModel, DeliveryPromise, Product } from '@steelbuy/domain-model';
import { useRequiredFieldValidator } from '@steelbuy/ui-domain';
import {
    Card,
    FileUploadStatus,
    FormItem,
    InputSelect,
    InputTextarea,
    MarketingBannerAdvantages,
    Notification,
    NotificationLevel,
    WizardBarItemStatus,
} from '@steelbuy/ui-primitive';
import CollectionAddresses from './CollectionAddresses';
import { StepProps } from './StepProps';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';

import { useCreateListingContext } from '../CreateListingContext';
import { packageStepsOrder, stepsOrder } from '../CreateListingContextUtil';
import { StepVisitation, useCreateListingStep } from '../CreateListingHooks';
import './PickupAddressStep.scss';

const getDefaultAddressId = (addresses: ReadonlyArray<AddressModel>) =>
    (addresses.find((address) => address.primary) ?? addresses[0])?.id ?? '';

const deliveryTimeframeOptions = Object.values(DeliveryPromise);

export const PickupAddressStep = ({ stepId }: StepProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);

    const createListingContext = useCreateListingContext();
    const { data: invoiceAddresses } = useGetInvoiceAddress();
    const { data: warehouseAddresses } = useGetWarehouseAddress();
    const { formRef, refreshStepStatus, stepVisitation } = useCreateListingStep(stepId);
    const {
        pickupAddressStepData,
        certificatesStatus,
        picturesStatus,
        materialStepData,
        updatePickupAddressStepData,
        packageStatus,
    } = createListingContext;
    const { deliveryTimeframe } = pickupAddressStepData;
    const isPackageListing = materialStepData.product === Product.PACKAGE;
    const steps = isPackageListing ? packageStepsOrder : stepsOrder;
    const [pickupAddressId, setPickupAddressId] = useState<string | undefined>(pickupAddressStepData.pickupAddressId);
    const requiredFieldValidator = useRequiredFieldValidator();
    const userSelf = useUserSelf().get();
    const defaultDeliveryPromise =
        userSelf?.deliveryPromise && deliveryTimeframeOptions.includes(userSelf.deliveryPromise)
            ? userSelf.deliveryPromise
            : deliveryTimeframeOptions[0];

    const areFilesUploading = Object.values({ ...certificatesStatus, ...picturesStatus, ...packageStatus }).some(
        (certStatus) => certStatus === FileUploadStatus.Uploading
    );

    useEffect(() => {
        if (invoiceAddresses && warehouseAddresses) {
            const pickupId = pickupAddressStepData.pickupAddressId;
            setPickupAddressId(pickupId);
            const invoiceAddressId = pickupAddressStepData.invoiceAddressId ?? getDefaultAddressId(invoiceAddresses);
            if (
                pickupAddressStepData.invoiceAddressId === invoiceAddressId &&
                pickupAddressStepData.pickupAddressId === pickupId
            ) {
                return;
            }

            updatePickupAddressStepData(
                {
                    invoiceAddressId,
                    pickupAddressId: pickupId,
                },
                true
            );
        }
    }, [invoiceAddresses, warehouseAddresses]);

    useEffect(() => {
        if (stepVisitation !== StepVisitation.NEVER) {
            refreshStepStatus();
        }
    }, [stepVisitation, pickupAddressStepData.pickupAddressId]);

    useEffect(() => {
        if (deliveryTimeframe !== undefined && materialStepData.product !== Product.PACKAGE) {
            updatePickupAddressStepData({ deliveryTimeframe: undefined }, true);
        } else {
            updatePickupAddressStepData(
                {
                    deliveryTimeframe: pickupAddressStepData.deliveryTimeframe ?? defaultDeliveryPromise,
                },
                true
            );
        }
    }, [materialStepData.product]);

    const selectOptions = deliveryTimeframeOptions.map((timeframe) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`domainModel:deliveryTimeframe.${timeframe}`),
        value: timeframe,
    }));

    const allStepsFinished = steps
        .map((step) => createListingContext.getStepStatus(step))
        .every((getStepStatus) => getStepStatus === WizardBarItemStatus.SUCCESS);

    let errorMessage = '';
    if (!allStepsFinished) {
        errorMessage = t('translation:application.pickupAddressStep.incompleteInformationNotification');
    } else if (areFilesUploading) {
        errorMessage = t('translation:application.pickupAddressStep.filesUploadingNotification');
    }

    const isVisited = stepVisitation === StepVisitation.VISITED;

    return (
        <FormLayout>
            <form ref={formRef} className="pickup-address-form">
                <Card isClickable={false}>
                    <CollectionAddresses pickupAddressId={pickupAddressId} forceValidation={isVisited} />
                    <section className="collection-address-notes">
                        <InputTextarea
                            label={t('translation:application.pickupAddressStep.additionalInformationLabel')}
                            onChange={(value) => {
                                updatePickupAddressStepData({
                                    additionalInformation: value,
                                });
                            }}
                            value={pickupAddressStepData.additionalInformation}
                            placeholder={t('translation:application.pickupAddressStep.additionalInfoPlaceholder')}
                        />
                    </section>
                </Card>
                {materialStepData.product === Product.PACKAGE && (
                    <FormItem header={t(`translation:application.pickupAddressStep.deliveryTimeframeHeader`)}>
                        <InputSelect
                            options={selectOptions}
                            value={deliveryTimeframe}
                            onSelect={(selectedTimeFrame) =>
                                updatePickupAddressStepData({ deliveryTimeframe: selectedTimeFrame })
                            }
                            validators={[requiredFieldValidator]}
                            forceValidation={isVisited}
                            label={t('translation:application.pickupAddressStep.deliveryTimeframeLabel')}
                            testId="delivery-timeframe"
                        />
                    </FormItem>
                )}
            </form>
            <MarketingBannerAdvantages
                header={t('translation:application.pickupAddressStep.anonymityBannerHeader')}
                text={t('translation:application.pickupAddressStep.anonymityBannerText')}
            />
            {errorMessage && <Notification level={NotificationLevel.ERROR} message={errorMessage} />}
        </FormLayout>
    );
};

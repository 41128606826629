import { OfferListingStatus, ListingSellerModel, ListingSellerModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MANAGE_OFFERS_LISTING_KEY = 'manage-offers-listings';

const modelConverter = new ListingSellerModelConverter();
const offerListingMapFn = (data: ListingSellerModel) =>
    modelConverter.toViewModel(data as unknown as ApiModel<ListingSellerModel>);

export const useManageOfferListings = ({ filter }: { filter: OfferListingStatus[] }) =>
    usePaginatedQuery<ListingSellerModel>({
        url: '/api/seller/negotiations',
        requestOptions: {
            status: filter?.length ? filter.join(',') : undefined,
        },
        mapFn: offerListingMapFn,
        queryKey: [MANAGE_OFFERS_LISTING_KEY, filter],
        queryOptions: {
            cacheTime: 0,
        },
    });

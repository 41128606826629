import { ButtonHTMLAttributes } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './ButtonTertiaryOnDarkS.scss';

export type ButtonTertiaryOnDarkSProps = {
    label?: string;
    icon?: IconIdentifier;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonTertiaryOnDarkS = (props: ButtonTertiaryOnDarkSProps) => {
    const { label, icon, type = 'button', ...rest } = props;

    const buttonTertiaryOnLightMClassMap = {
        'button-tertiary-on-dark-s': true,
        'button-tertiary-on-dark-s__only-icon': !label,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-tertiary-on-dark-s__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    return (
        <button type={type} className={buildClassStringFromClassMap(buttonTertiaryOnLightMClassMap)} {...rest}>
            {renderLabel()}
            {renderIcon()}
        </button>
    );
};

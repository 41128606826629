import { ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfUrlFieldNames, ModelPrimaryKey, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';

export enum AlertType {
    URGENT = 'URGENT',
    REGULAR = 'REGULAR',
}

export type ListingBuyerAlertModel = {
    readonly id: ModelPrimaryKey;
    readonly numberOfNewListings: number;
    readonly numberOfListings: number;
    readonly createdAt: Timestamp;
    filterQuery: string;
    enabled: boolean;
    type: AlertType;
    comment: string | undefined;
};

export class ListingBuyerAlertModelConverter extends ModelConverter<ListingBuyerAlertModel> {
    override getTimestampFields():
        | Array<TimestampFieldNames<ListingBuyerAlertModel>>
        | ArrayOfUrlFieldNames<ListingBuyerAlertModel> {
        return ['createdAt'];
    }
}

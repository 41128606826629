import {
    commonFlatSheetCoatings,
    commonFlatSheetProtectiveColor,
    commonFlatSheetProtectiveCoverage,
    commonFlatSheetProtectiveThickness,
    commonFlatSheetProtectiveType,
} from '../../common/MaterialFlatCoatings';

export const stainlessFlatSheetCoatings = commonFlatSheetCoatings;

export const stainlessFlatSheetProtectiveThickness = commonFlatSheetProtectiveThickness;

export const stainlessFlatSheetProtectiveColor = commonFlatSheetProtectiveColor;

export const stainlessFlatSheetProtectiveType = commonFlatSheetProtectiveType;

export const stainlessFlatSheetProtectiveCoverage = commonFlatSheetProtectiveCoverage;

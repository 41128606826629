import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DraftQuoteEntry, isMaterialWithDefinition, isMaterialWithPlateType } from '@steelbuy/domain-model';

export type RFQTeaserHeaderProps = {
    referenceNumber?: string;
    quoteEntry: DraftQuoteEntry[];
};

const QUOTE_ENTRY_COUNT = 3;

export const RFQTeaserHeader = ({ quoteEntry, referenceNumber }: RFQTeaserHeaderProps) => {
    const { t } = useTranslation('domainModel');

    const uniqueQuoteEntries = useMemo(() => {
        const uniqueMaterials = new Map();

        quoteEntry?.forEach((quote) => {
            const definition = isMaterialWithDefinition(quote.material) && quote.material.definition;
            const type = isMaterialWithPlateType(quote.material) && quote.material.plateType;
            const definitionOrType = definition || type;
            const key = definitionOrType;
            if (!uniqueMaterials.has(key)) {
                uniqueMaterials.set(key, quote);
            }
        });

        return Array.from(uniqueMaterials.values()) as DraftQuoteEntry[];
    }, [quoteEntry]);

    return (
        <div className="rfq-list-teaser__header">
            {referenceNumber && <div className="rfq-list-teaser__header__reference-number">{referenceNumber}</div>}
            <div className="rfq-list-teaser__header__material">
                {uniqueQuoteEntries.slice(0, QUOTE_ENTRY_COUNT)?.map((quote, index, array) => {
                    const definition =
                        isMaterialWithDefinition(quote.material) && quote.material.definition
                            ? t(`material.definition.value.${quote.material.definition}`)
                            : undefined;
                    const type =
                        isMaterialWithPlateType(quote.material) && quote.material.plateType
                            ? t(`material.plateType.value.${quote.material.plateType}`)
                            : undefined;

                    const definitionOrType = definition || type;
                    const isLast = index === array.length - 1 && uniqueQuoteEntries.length > QUOTE_ENTRY_COUNT;
                    return (
                        <div key={quote.id}>
                            {quote.material.product && t(`material.product.value.${quote.material.product}`)},{' '}
                            {quote.material.materialType &&
                                t(`material.materialType.value.${quote.material.materialType}`)}
                            {definitionOrType && ','} {definitionOrType}
                            {isLast && '...'}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

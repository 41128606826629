import ReactDOM from 'react-dom';

import './Modal.scss';
import { ModalAccessor } from './UseModalHook';

interface ModalWrapperProps {
    modalAccessor: ModalAccessor;
    children: React.ReactNode;
}

export const Modal = ({ children, modalAccessor: { modalRef, visible } }: ModalWrapperProps) =>
    visible
        ? ReactDOM.createPortal(
              <div className="modal">
                  <div className="modal__overlay" />
                  <div className="modal__wrapper">
                      <div className="modal__container" role="dialog" aria-modal="true" ref={modalRef}>
                          {children}
                      </div>
                  </div>
              </div>,
              document.body
          )
        : null;

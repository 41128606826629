import { UserCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { UserModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const userEntityStoreName = 'user-entity';
export type UserEntityStore = { [userEntityStoreName]: EntityStore<UserModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new UserCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<UserModel, UserEntityStore, UserCrudApiClient>(
    userEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<UserModel, UserCrudApiClient>(userEntityStoreName, createApiClient);
const createEntity = createCreateThunk<UserModel, UserCrudApiClient>(userEntityStoreName, createApiClient);
const deleteEntity = createDeleteThunk<UserModel, UserCrudApiClient>(userEntityStoreName, createApiClient);
const entityService = createEntityServiceThunk<UserModel, UserCrudApiClient>(userEntityStoreName, createApiClient);

// Slice definition
export const userEntitySlice = createCrudEntitySlice<UserModel>(
    userEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = userEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<UserModel, UserEntityStore>(userEntityStoreName);
const selectCreated = createSelectCreated<UserModel, UserEntityStore>(userEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<UserModel, UserEntityStore>(userEntityStoreName);

// Export the store accessors to be used by the data provider value
export const userEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<UserModel, UserEntityStore>;

import { isArray } from '@steelbuy/types';

import {
    MultiValueFilterComparator,
    MultiValueFilterCriteriaRule,
    PreparedMultiValueFilterCriteriaRule,
    PreparedSingleValueFilterCriteriaRule,
    SingleValueFilterComparator,
    SingleValueFilterCriteriaRule,
} from './FilterCriteria';

const singleValueFilterCriteriaRuleComparators = [...Object.values(SingleValueFilterComparator), undefined];
const multiValueFilterCriteriaRuleComparators = [...Object.values(MultiValueFilterComparator), undefined];

const isModelRelatedSingleValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is SingleValueFilterCriteriaRule<unknown> =>
    singleValueFilterCriteriaRuleComparators.includes(
        (filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).comparator
    ) &&
    (filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).property !== undefined &&
    (filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value !== undefined &&
    !isArray((filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value);

const isModelRelatedMultiValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is MultiValueFilterCriteriaRule<unknown> =>
    multiValueFilterCriteriaRuleComparators.includes(
        (filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).comparator
    ) &&
    (filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).property !== undefined &&
    (filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).value !== undefined &&
    isArray((filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value);

const isPreparedSingleValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is PreparedSingleValueFilterCriteriaRule<unknown> =>
    singleValueFilterCriteriaRuleComparators.includes(
        (filterCriteriaRule as PreparedSingleValueFilterCriteriaRule<unknown>).comparator
    ) &&
    (filterCriteriaRule as PreparedSingleValueFilterCriteriaRule<unknown>).property !== undefined &&
    (filterCriteriaRule as PreparedSingleValueFilterCriteriaRule<unknown>).value !== undefined &&
    !isArray((filterCriteriaRule as PreparedSingleValueFilterCriteriaRule<unknown>).value);

const isPreparedMultiValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is PreparedMultiValueFilterCriteriaRule<unknown> =>
    multiValueFilterCriteriaRuleComparators.includes(
        (filterCriteriaRule as PreparedMultiValueFilterCriteriaRule<unknown>).comparator
    ) &&
    (filterCriteriaRule as PreparedMultiValueFilterCriteriaRule<unknown>).property !== undefined &&
    (filterCriteriaRule as PreparedMultiValueFilterCriteriaRule<unknown>).value !== undefined &&
    isArray((filterCriteriaRule as PreparedMultiValueFilterCriteriaRule<unknown>).value);

export const isSingleValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is SingleValueFilterCriteriaRule<unknown> | PreparedSingleValueFilterCriteriaRule<unknown> =>
    isModelRelatedSingleValueFilterCriteriaRule(filterCriteriaRule) ||
    isPreparedSingleValueFilterCriteriaRule(filterCriteriaRule);

export const isMultiValueFilterCriteriaRule = (
    filterCriteriaRule: unknown
): filterCriteriaRule is MultiValueFilterCriteriaRule<unknown> | PreparedMultiValueFilterCriteriaRule<unknown> =>
    isModelRelatedMultiValueFilterCriteriaRule(filterCriteriaRule) ||
    isPreparedMultiValueFilterCriteriaRule(filterCriteriaRule);

import { useEffect, ReactNode, useCallback, useRef } from 'react';
import { useForm } from './form-context/FormConsumer';
import { FormProvider } from './form-context/FormProvider';

type NativeFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export interface FormProps extends NativeFormProps {
    children: ReactNode;
    className?: string;
}

const InnerForm = ({ children, ...rest }: FormProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { setIsValid, setFormRef } = useForm();
    const isFormValidRef = useRef<boolean>(true);
    const setIsFormValid = useCallback((value: boolean) => {
        isFormValidRef.current = value;
        setIsValid(value);
    }, []);

    useEffect(() => {
        setFormRef(formRef.current);
        setIsFormValid(!!formRef.current?.checkValidity());
    }, []);

    return (
        <form
            ref={formRef}
            onInvalid={() => {
                // prevents infinite loop of onInvalid events firing
                if (isFormValidRef.current) {
                    setIsFormValid(false);
                }
            }}
            onChange={() => {
                setIsFormValid(!!formRef.current?.checkValidity());
                // revalidate asynchronously in case of state updates
                setTimeout(() => {
                    if (formRef.current) {
                        setIsFormValid(!!formRef.current?.checkValidity());
                    }
                }, 0);
            }}
            {...rest}
        >
            {children}
        </form>
    );
};

export const Form = ({ children, ...rest }: FormProps) => (
    <FormProvider>
        <InnerForm {...rest}>{children}</InnerForm>
    </FormProvider>
);

import classnames from 'classnames';
import { ControllerRenderProps, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

import './Checkbox.scss';

interface CheckboxProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
    options: Array<{ value: string; label: string }>;
    id: string;
    onChange?: ({
        target,
        field,
    }: {
        target: EventTarget & HTMLInputElement;
        field: ControllerRenderProps<TFieldValues, TName>;
    }) => void;
}

export const Checkbox = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    options,
    control,
    id,
    onChange,
    ...rest
}: CheckboxProps<TFieldValues, TName>) => {
    const checkboxClassMap = {
        'group-input-checkbox': true,
        'group-input-checkbox--disabled': false,
    };

    const { field } = useController({
        control,
        ...rest,
    });

    return options.map(({ value, label }) => (
        <label className={classnames(checkboxClassMap)} key={label} htmlFor={`${id}_${value}`}>
            <span className="group-input-checkbox__input">
                <input
                    onChange={({ target }) => {
                        if (onChange) {
                            onChange({ target, field });
                            return;
                        }
                        if (target.checked) {
                            field.onChange([...field.value, target.value]);
                            return;
                        }
                        field.onChange(field.value.filter((item: string) => item !== target.value));
                    }}
                    type="checkbox"
                    checked={field.value.includes(value)}
                    value={value}
                    id={`${id}_${value}`}
                />
            </span>
            <article className="group-input-checkbox__content">{label}</article>
        </label>
    ));
};

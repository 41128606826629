import { useParams } from 'react-router-dom';
import { OrderBuyerEntityDataProvider, WarehouseAddressCollectionDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { MyOrderDetails } from '../../components/my-order-details/MyOrderDetails';
import { MyOrderDetailsNotFound } from '../../components/my-order-details/MyOrderDetailsNotFound';

export const MyOrdersDetailsView = () => {
    const { orderId = null } = useParams();

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <MyOrderDetailsNotFound />;
        }

        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <WarehouseAddressCollectionDataProvider>
                <OrderBuyerEntityDataProvider entityId={orderId ?? ''}>
                    <MyOrderDetails />
                </OrderBuyerEntityDataProvider>
            </WarehouseAddressCollectionDataProvider>
        </ErrorHandler>
    );
};

import { useNavigate } from 'react-router-dom';

import { ActionStatus, useFooEntityData } from '@steelbuy/data-provider';
import { FooEntityDeletion, FooEntityMutation } from '@steelbuy/ui-domain';

import { RoutePath } from '../../router/Routes';

export const FooEntity = () => {
    const fooEntity = useFooEntityData();
    const fooModel = fooEntity.query().get();
    const fooActionStatus = fooEntity.queryActionStatus();
    const navigate = useNavigate();

    fooEntity.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            navigate(RoutePath.FOOS);
        },
        true
    );

    if (
        fooActionStatus === ActionStatus.DELETE_PENDING ||
        fooActionStatus === ActionStatus.DELETE_SUCCESS ||
        fooActionStatus === ActionStatus.MUTATE_PENDING
    ) {
        return <pre>Pending …</pre>;
    }

    if (fooModel === null) {
        return <pre>Not found</pre>;
    }

    return (
        <div>
            <h1>{fooModel.name}</h1>
            <FooEntityMutation model={fooModel} />
            <hr />
            <FooEntityDeletion />
        </div>
    );
};

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export type SortCriteria<Model> = Array<{
    property: keyof Model;
    direction?: SortDirection;
}>;

export type PreparedSortCriteria<SortCriteriaProperty = string> = Array<{
    property: SortCriteriaProperty;
    direction?: SortDirection;
}>;

export const compareSortCriteria = <Model>(
    left?: SortCriteria<Model> | PreparedSortCriteria,
    right?: SortCriteria<Model> | PreparedSortCriteria
): boolean => {
    if (left === undefined && right === undefined) {
        return true;
    }
    if (left === undefined || right === undefined) {
        return false;
    }
    if (left.length !== right.length) {
        return false;
    }
    for (let i = 0; i < left.length; i++) {
        if (left[i].property !== right[i].property) {
            return false;
        }
        if (left[i].direction !== right[i].direction) {
            return false;
        }
    }
    return true;
};

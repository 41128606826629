import {
    mildSteelCoatedCoating,
    mildSteelCoatedCoatingThicknessAS,
    mildSteelCoatedCoatingThicknessAZ,
    mildSteelCoatedCoatingThicknessZ,
    mildSteelCoatedCoatingThicknessZA,
    mildSteelCoatedCoatingThicknessZF,
    mildSteelCoatedCoatingThicknessZM,
    mildSteelCoatedGrade,
    mildSteelCoatedSurface,
} from './flat/MildSteelCoatedSteel';
import {
    mildSteelColdRolledFinish,
    mildSteelColdRolledGrade,
    mildSteelColdRolledSurface,
} from './flat/MildSteelColdRolled';
import {
    mildSteelElectroZincCoating,
    mildSteelElectroZincCoatingThicknessOneSided,
    mildSteelElectroZincCoatingThicknessTwoSided,
    mildSteelElectroZincGrade,
    mildSteelElectroZincSurface,
} from './flat/MildSteelElectroZinc';
import { mildSteelCoilOrSheetDefinitions } from './flat/MildSteelFlatMaterialModel';
import { mildSteelFlatProduct } from './flat/MildSteelFlatProduct';
import {
    mildSteelHotRolledFinish,
    mildSteelHotRolledGradeCluster1,
    mildSteelHotRolledGradeCluster2,
    mildSteelHotRolledGradeCluster2Specification,
    mildSteelHotRolledGradeCluster3,
    mildSteelHotRolledGradeCluster3Specification,
} from './flat/MildSteelHotRolled';
import {
    mildSteelHotRolledFloorplateFinish,
    mildSteelHotRolledFloorplateGrade,
    mildSteelHotRolledFloorplateSpecification,
} from './flat/MildSteelHotRolledFloorplate';
import {
    mildSteelBoilerPressureVesselGrade,
    mildSteelHighStrengthStructuralGrade,
    mildSteelHighStrengthStructuralSpecification,
    mildSteelMediumCarbonSteelGrade,
    mildSteelMediumCarbonSteelSpecification,
    mildSteelNonPrimeProductType,
    mildSteelOffshoreGrade,
    mildSteelOffshoreSpecification,
    mildSteelPlateFinish,
    mildSteelProductType,
    mildSteelStructuralSteelGrade,
    mildSteelStructuralSteelSpecification,
    mildSteelWearResistantGrade,
} from './flat/MildSteelPlate';
import { mildSteelShape } from './MildSteelShape';
import { mildSteelTolerances } from './MildSteelTolerance';
import { popularGradesByDefinition } from './rfq/MildSteelRFQTaxonomy';
import {
    Coating,
    Definition,
    Grade,
    MaterialType,
    Product,
    ProductType,
    Shape,
    Tolerance,
} from '../../MaterialModel.enums';
import { SearchFields, SearchFormData } from '../../SearchFormData';
import { AbstractMaterialTaxonomy } from '../AbstractMaterialTaxonomy';
import { MaterialProperties, RFQField } from '../MaterialProperties';

type GradeArray = Readonly<Array<Grade>>;

const MINIMUM_WIDTH = 0.0000000001;
const MINIMUM_THICKNESS = 0.1000000001;
const MINIMUM_LENGTH = MINIMUM_WIDTH;
const MINIMUM_WEIGHT = MINIMUM_THICKNESS;

export class MildSteelTaxonomy extends AbstractMaterialTaxonomy {
    override getShapes() {
        return mildSteelShape;
    }

    override getProducts(material: MaterialProperties, isPackagesSupported?: boolean) {
        if (material.shape === Shape.FLAT) {
            if (!isPackagesSupported) {
                return super.getFilteredProducts(mildSteelFlatProduct, [Product.PACKAGE]);
            }
            return mildSteelFlatProduct;
        }
        return super.getProducts(material, isPackagesSupported);
    }

    override getProductTypes(material: MaterialProperties, isPrime: boolean) {
        if (material.product === Product.PLATE) {
            if (!isPrime) {
                return mildSteelNonPrimeProductType;
            }
            return mildSteelProductType;
        }
        return super.getProductTypes(material, isPrime);
    }

    override getDefinitions(material: MaterialProperties) {
        switch (material.product) {
            case Product.COIL:
            case Product.SHEET:
                return mildSteelCoilOrSheetDefinitions;
            default:
                return super.getDefinitions(material);
        }
    }

    override getGrades(material: MaterialProperties) {
        if (material.definition) {
            switch (material.definition) {
                case Definition.HOT_ROLLED:
                    return [
                        ...mildSteelHotRolledGradeCluster1,
                        ...mildSteelHotRolledGradeCluster2,
                        ...mildSteelHotRolledGradeCluster3,
                    ];
                case Definition.HOT_ROLLED_FLOORPLATE:
                    return mildSteelHotRolledFloorplateGrade;
                case Definition.COLD_ROLLED:
                    return mildSteelColdRolledGrade;
                case Definition.COATED_STEEL:
                    return mildSteelCoatedGrade;
                case Definition.ELECTRO_ZINC:
                    return mildSteelElectroZincGrade;
                default:
                    return super.getGrades(material);
            }
        }
        if (material.plateType) {
            switch (material.plateType) {
                case ProductType.WEAR_RESISTANT:
                    return mildSteelWearResistantGrade;
                case ProductType.HIGH_STRENGTH_STRUCTURAL:
                    return mildSteelHighStrengthStructuralGrade;
                case ProductType.STRUCTURAL_STEEL:
                    return mildSteelStructuralSteelGrade;
                case ProductType.MEDIUM_CARBON_STEEL:
                    return mildSteelMediumCarbonSteelGrade;
                case ProductType.OFFSHORE:
                    return mildSteelOffshoreGrade;
                case ProductType.BOILER_PRESSURE_VESSEL:
                    return mildSteelBoilerPressureVesselGrade;
                case ProductType.ROAD_PLATE:
                    return [];
                default:
                    return super.getGrades(material);
            }
        }
        return super.getGrades(material);
    }

    override getSurfaces(material: MaterialProperties) {
        switch (material.definition) {
            case Definition.COLD_ROLLED:
                return mildSteelColdRolledSurface;
            case Definition.COATED_STEEL:
                return mildSteelCoatedSurface;
            case Definition.ELECTRO_ZINC:
                return mildSteelElectroZincSurface;
            default:
                return super.getSurfaces(material);
        }
    }

    override getFinishes(material: MaterialProperties) {
        if (material.definition) {
            switch (material.definition) {
                case Definition.HOT_ROLLED:
                    return mildSteelHotRolledFinish;
                case Definition.HOT_ROLLED_FLOORPLATE:
                    return mildSteelHotRolledFloorplateFinish;
                case Definition.COLD_ROLLED:
                    return mildSteelColdRolledFinish;
                default:
                    return super.getFinishes(material);
            }
        }
        switch (material.plateType) {
            case ProductType.WEAR_RESISTANT:
            case ProductType.HIGH_STRENGTH_STRUCTURAL:
            case ProductType.STRUCTURAL_STEEL:
            case ProductType.MEDIUM_CARBON_STEEL:
            case ProductType.OFFSHORE:
            case ProductType.BOILER_PRESSURE_VESSEL:
                return mildSteelPlateFinish;
            default:
                return super.getFinishes(material);
        }
    }

    override getSpecifications(material: MaterialProperties) {
        if (material.materialType !== MaterialType.MILD_STEEL) {
            return super.getSpecifications(material);
        }
        if (material.grade !== undefined && material.definition === Definition.HOT_ROLLED) {
            // need to cast to GradeArray to keep typescript happy, otherwise we need to use find in place of includes
            if ((mildSteelHotRolledGradeCluster2 as GradeArray).includes(material.grade)) {
                return mildSteelHotRolledGradeCluster2Specification;
            }
            if ((mildSteelHotRolledGradeCluster3 as GradeArray).includes(material.grade)) {
                return mildSteelHotRolledGradeCluster3Specification;
            }
            return super.getSpecifications(material);
        }
        if (material.definition === Definition.HOT_ROLLED_FLOORPLATE) {
            return mildSteelHotRolledFloorplateSpecification;
        }

        if (material.plateType) {
            switch (material.plateType) {
                case ProductType.HIGH_STRENGTH_STRUCTURAL:
                    return mildSteelHighStrengthStructuralSpecification;
                case ProductType.STRUCTURAL_STEEL:
                    return mildSteelStructuralSteelSpecification;
                case ProductType.MEDIUM_CARBON_STEEL: {
                    return mildSteelMediumCarbonSteelSpecification;
                }
                case ProductType.OFFSHORE:
                    return mildSteelOffshoreSpecification;
                default:
                    return super.getSpecifications(material);
            }
        }
        return super.getSpecifications(material);
    }

    override getCoatings(material: MaterialProperties) {
        switch (material.definition) {
            case Definition.COATED_STEEL:
                return mildSteelCoatedCoating;
            case Definition.ELECTRO_ZINC:
                return mildSteelElectroZincCoating;
            default:
                return super.getCoatings(material);
        }
    }

    override getCoatingThickness(material: MaterialProperties) {
        switch (material.coating) {
            case Coating.Z:
                return mildSteelCoatedCoatingThicknessZ;
            case Coating.AZ:
                return mildSteelCoatedCoatingThicknessAZ;
            case Coating.ZM:
                return mildSteelCoatedCoatingThicknessZM;
            case Coating.ZA:
                return mildSteelCoatedCoatingThicknessZA;
            case Coating.ZF:
                return mildSteelCoatedCoatingThicknessZF;
            case Coating.AS:
                return mildSteelCoatedCoatingThicknessAS;
            case Coating.ONE_SIDED:
                return mildSteelElectroZincCoatingThicknessOneSided;
            case Coating.TWO_SIDED:
                return mildSteelElectroZincCoatingThicknessTwoSided;
            default:
                return super.getCoatingThickness(material);
        }
    }

    override getTolerances(material: MaterialProperties, isPrime: boolean) {
        if (isPrime) {
            return mildSteelTolerances.filter((tolerance) => tolerance !== Tolerance.NON_PRIME);
        }
        return mildSteelTolerances;
    }

    override getMaterialAges(material: MaterialProperties) {
        if (material.plateType === ProductType.ROAD_PLATE) {
            return [];
        }
        return super.getMaterialAges(material);
    }

    override getOrigins(material: MaterialProperties) {
        if (material.plateType === ProductType.ROAD_PLATE) {
            return [];
        }
        return super.getOrigins(material);
    }

    override getUrgentRequestMandatoryFields(searchFormData: SearchFormData) {
        const fields: SearchFields = ['materialType', 'shape', 'product', 'definition', 'thickness', 'width', 'weight'];
        if (searchFormData.product !== Product.PLATE) {
            switch (searchFormData.definition) {
                case Definition.COATED_STEEL:
                    fields.push('grade', 'coating');
                    break;
                case Definition.COLD_ROLLED:
                case Definition.ELECTRO_ZINC:
                case Definition.HOT_ROLLED:
                    fields.push('grade');
                    break;
                default:
                // leave fields with default mandatory search fields
            }
        } else if (searchFormData.plateType !== ProductType.ROAD_PLATE) {
            fields.push('grade');
        }
        return fields;
    }

    override getMinimumTargetPrice() {
        return 100;
    }

    override showNumberOfItemsForIMR(): boolean {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override getRFQFields(material: MaterialProperties, t: any): RFQField[] {
        const createRFQField = super.createRFQField;
        const minLiteral = 'uiDomain:validation.numberLargerThan';
        const requiredRule = { required: { value: true, message: t('uiDomain:validation.requiredField') } };
        const gradeField = createRFQField('grade', requiredRule);
        const surfaceField = createRFQField('surface', requiredRule);
        const finishField = createRFQField('finish', requiredRule);
        const widthField = createRFQField('width', {
            ...requiredRule,
            min: { value: MINIMUM_WIDTH, message: t(minLiteral, { min: 0 }) },
        });
        const thicknessField = createRFQField('thickness', {
            ...requiredRule,
            min: { value: MINIMUM_THICKNESS, message: t(minLiteral, { min: 0.1 }) },
        });
        const lengthField = createRFQField('length', {
            ...requiredRule,
            min: { value: MINIMUM_LENGTH, message: t(minLiteral, { min: 0 }) },
        });
        const weightField = createRFQField('weight', {
            ...requiredRule,
            min: { value: MINIMUM_WEIGHT, message: t(minLiteral, { min: 0.1 }) },
        });
        const toleranceField = createRFQField('tolerance', requiredRule);
        const specificationField = createRFQField('specification', requiredRule);
        const fields: RFQField[] = [gradeField];
        switch (material.product) {
            case Product.COIL:
            case Product.SHEET: {
                switch (material.definition) {
                    case Definition.COATED_STEEL:
                    case Definition.ELECTRO_ZINC:
                        fields.push(
                            createRFQField('coating', requiredRule),
                            createRFQField('coatingThicknessValue', {
                                ...requiredRule,
                                min: { value: MINIMUM_THICKNESS, message: t(minLiteral, { min: 0.1 }) },
                            }),
                            surfaceField
                        );
                        break;
                    case Definition.COLD_ROLLED:
                        fields.push(surfaceField, finishField);
                        break;
                    case Definition.HOT_ROLLED:
                    case Definition.HOT_ROLLED_FLOORPLATE:
                        fields.push(specificationField, finishField);
                        break;
                    default:
                        break;
                }

                fields.push(thicknessField, widthField);
                if (material.product === Product.SHEET) {
                    fields.push(lengthField);
                }
                fields.push(weightField, toleranceField);
                return fields;
            }
            case Product.PLATE: {
                if (this.getSpecifications(material).length > 0) {
                    fields.push(specificationField);
                }
                return [...fields, finishField, thicknessField, widthField, lengthField, weightField, toleranceField];
            }
            default:
                return super.getRFQFields(material, t);
        }
    }

    override getRFQGrades(material: MaterialProperties) {
        const grades = this.getGrades(material);

        if (material.definition) {
            const rfqGrades = [];
            const popularGrades = popularGradesByDefinition[material.definition];
            if (popularGrades.length) {
                rfqGrades.push(popularGrades);
            }
            return [...rfqGrades, grades];
        }
        return [grades];
    }
}

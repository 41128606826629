import { useOrderBuyerCreateEntityData } from '@steelbuy/data-provider';
import { CheckoutIllustratedSuccess } from '@steelbuy/ui-domain';
import { CheckoutSuccessWrapper } from './CheckoutSuccessWrapper';
import { RoutePath } from '../../router/Routes';

export const CheckoutSuccess = () => {
    const orderModel = useOrderBuyerCreateEntityData().query().get();

    if (orderModel === null) {
        return null;
    }

    return (
        <CheckoutSuccessWrapper>
            <CheckoutIllustratedSuccess orderModel={orderModel} myOrdersUrl={RoutePath.MY_ORDERS} />
        </CheckoutSuccessWrapper>
    );
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { exchangeDocumentIdForAccessibleLink } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';
import { useUploadDocument } from '@steelbuy/data-access';
import { isMaterialWithPrime, MaterialType, DocumentType, ProductType } from '@steelbuy/domain-model';
import { createUploadFileFromFile, UploadFile } from '@steelbuy/domain-model-types';
import {
    FileUploadStatus,
    FormItem,
    InputFile,
    InputRadioButton,
    InputRadioButtonGroup,
    InputRadioButtonGroupAlign,
    InputTextarea,
    ValidatableElement,
} from '@steelbuy/ui-primitive';
import { getSelectedFiles } from '../file-input-util/FileUtil';
import { RequiredFieldValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';
import { FILE_SIZE_LIMIT_IN_MEGABYTES, exceedsMaxFileSize, isValidFileType } from '../validation/ValidationUtils';

type MaterialQualitySelectionProps = {
    prime: boolean;
    primeStatusSelected: (prime: boolean | undefined) => void;
    description?: string;
    descriptionChanged: (newDescription?: string) => void;
    testCertificates?: UploadFile[];
    pictures?: UploadFile[];
    materialType: MaterialType;
    multiple: boolean;
    plateType?: ProductType;
    picturesStatus: Record<string, FileUploadStatus>;
    certificatesStatus: Record<string, FileUploadStatus>;
    updateFileStatus: (type: DocumentType, filename: string, status?: FileUploadStatus, clearFiles?: boolean) => void;
    addFile: (type: DocumentType, file: UploadFile, isMulti: boolean) => void;
    apiBaseUrl: string;
} & RequiredFieldValidationProps;

export const MaterialQualitySelection = ({
    description,
    descriptionChanged,
    forceValidation,
    materialType,
    pictures,
    prime,
    primeStatusSelected,
    testCertificates,
    plateType,
    multiple,
    picturesStatus,
    certificatesStatus,
    updateFileStatus,
    addFile,
    apiBaseUrl,
}: MaterialQualitySelectionProps) => {
    const auth = useAuth();
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const shouldShowPrimeOptions = isMaterialWithPrime({
        materialType,
    });

    const uploadDocument = useUploadDocument({
        onSuccess: (data, { file }) => {
            const documentType = data.type === DocumentType.ORIGINAL ? DocumentType.ORIGINAL : DocumentType.PICTURE;
            updateFileStatus(documentType, data.fileName, FileUploadStatus.Success);
            addFile(documentType, createUploadFileFromFile(data.id, file), multiple);
        },
        onError: (_, { type, file }) => {
            const documentType = type === DocumentType.ORIGINAL ? DocumentType.ORIGINAL : DocumentType.PICTURE;
            updateFileStatus(documentType, file.name, FileUploadStatus.Error);
        },
    });

    const handleFileSelection = (type: DocumentType, files?: File[]) => {
        if (files) {
            files.forEach((file) => {
                if (exceedsMaxFileSize(file.size) || !isValidFileType(type, file.name)) {
                    updateFileStatus(type, file.name, FileUploadStatus.Error);
                } else {
                    updateFileStatus(type, file.name, FileUploadStatus.Uploading, !multiple);
                    uploadDocument.mutateAsync({ file, type });
                }
            });
        }
    };

    const getInvalidMessage = (type: DocumentType) => (file: File) => {
        if (!isValidFileType(type, file.name)) {
            return t('uiDomain:validation.unsupporttedFileType');
        }
        if (exceedsMaxFileSize(file.size)) {
            return t('uiDomain:validation.fileItemSizeError', {
                sizeLimitInMegabytes: FILE_SIZE_LIMIT_IN_MEGABYTES.toString(),
            });
        }
        return undefined;
    };

    const validate = (type: DocumentType, input?: ValidatableElement) => {
        if (input && input instanceof HTMLInputElement && input.files) {
            return !Array.from(input.files).some((file) => file && getInvalidMessage(type)(file));
        }
        return true;
    };

    const pictureValidator = {
        validate: (value: unknown, input?: ValidatableElement) => validate(DocumentType.PICTURE, input),
        message: ' ', // error is displayed below the file item
    };

    const certificateValidator = {
        validate: (value: unknown, input?: ValidatableElement) => validate(DocumentType.ORIGINAL, input),
        message: ' ', // error is displayed below the file item
    };

    useEffect(() => {
        if (!shouldShowPrimeOptions && prime !== true) {
            primeStatusSelected(true); // set to true for now as backend requires it
        }
    }, [materialType, prime, shouldShowPrimeOptions, primeStatusSelected]);

    useEffect(() => {
        if (plateType === ProductType.ROAD_PLATE) {
            primeStatusSelected(false);
        }
    }, [plateType]);

    const getDownloadFile = async (documentId: string) => {
        const fileDownload = await exchangeDocumentIdForAccessibleLink(apiBaseUrl, documentId, auth.getToken().get());
        return fileDownload;
    };

    return (
        <FormItem header={t('domainModel:material.quality.name')}>
            {shouldShowPrimeOptions && (
                <InputRadioButtonGroup align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}>
                    <InputRadioButton
                        name="materialQuality"
                        value={t('domainModel:material.quality.value.prime')}
                        onChange={(checked) => {
                            if (checked) {
                                primeStatusSelected(true);
                            }
                            descriptionChanged(undefined);
                        }}
                        checked={prime}
                    />
                    <InputRadioButton
                        name="materialQuality"
                        value={t('domainModel:material.quality.value.nonPrime')}
                        onChange={(checked) => {
                            if (checked) {
                                primeStatusSelected(false);
                            }
                            descriptionChanged(undefined);
                        }}
                        checked={!prime}
                    />
                </InputRadioButtonGroup>
            )}
            {prime === false && (
                <>
                    <InputTextarea
                        name="materialDescription"
                        label={t('uiDomain:materialQualitySelection.descriptionLabel')}
                        placeholder={t('uiDomain:materialQualitySelection.descriptionPlaceholder')}
                        onChange={descriptionChanged}
                        value={description}
                        validators={[requiredFieldValidator]}
                        forceValidation={forceValidation}
                    />
                    <InputFile
                        label={t('uiDomain:materialQualitySelection.pictureUploadLabel')}
                        fileType={['.png', '.jpg']}
                        onChange={(files) => {
                            handleFileSelection(DocumentType.PICTURE, files);
                        }}
                        onRemoveFile={(filename: string) => updateFileStatus(DocumentType.PICTURE, filename)}
                        validators={[pictureValidator]}
                        forceValidation={forceValidation}
                        multiple={multiple}
                        fileStatus={picturesStatus}
                        initialSelectedFiles={getSelectedFiles(pictures)}
                        getInvalidMessage={getInvalidMessage(DocumentType.PICTURE)}
                        getDownloadFile={getDownloadFile}
                    />
                </>
            )}

            <InputFile
                label={
                    prime === false
                        ? t('uiDomain:materialQualitySelection.testCertificateLabelOptional')
                        : t('uiDomain:materialQualitySelection.testCertificateLabel')
                }
                fileType={['.pdf', '.png', '.jpg']}
                name={prime !== false ? 'testCertificate' : undefined}
                onChange={(files) => {
                    handleFileSelection(DocumentType.ORIGINAL, files);
                }}
                onRemoveFile={(filename: string) => updateFileStatus(DocumentType.ORIGINAL, filename)}
                validators={prime === false ? [certificateValidator] : [requiredFieldValidator, certificateValidator]}
                forceValidation={forceValidation}
                multiple={multiple}
                fileStatus={certificatesStatus}
                initialSelectedFiles={getSelectedFiles(testCertificates)}
                getInvalidMessage={getInvalidMessage(DocumentType.ORIGINAL)}
                getDownloadFile={getDownloadFile}
            />
            {prime === true && (
                <InputTextarea
                    name="materialDescription"
                    label={t('uiDomain:materialQualitySelection.descriptionPrimeLabel')}
                    placeholder={t('uiDomain:materialQualitySelection.descriptionPrimePlaceholder')}
                    onChange={descriptionChanged}
                    value={description}
                />
            )}
        </FormItem>
    );
};

export const handleTabKey = (e: KeyboardEvent, element: HTMLElement) => {
    const focusableElements = Array.from(
        element.querySelectorAll<HTMLElement>(
            'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
        )
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (document.activeElement instanceof HTMLElement) {
        const activeIndex = focusableElements.indexOf(document.activeElement);
        if (activeIndex > 0 && activeIndex < focusableElements.length - 1) {
            return;
        }
    }

    if (!e.shiftKey && document.activeElement !== firstElement) {
        firstElement.focus();
        // eslint-disable-next-line consistent-return
        return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
        lastElement.focus();
        // eslint-disable-next-line consistent-return
        return e.preventDefault();
    }
};

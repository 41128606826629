import { useMutation } from '@tanstack/react-query';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import axios from '../../axios';

const deleteRFQ = async (draftId: ModelPrimaryKey) => {
    const { data } = await axios.delete<ModelPrimaryKey>(`/api/buyer/quotes/draft/${draftId}`);
    return data;
};

export const useDeleteDraftRFQ = () =>
    useMutation({
        mutationFn: (draftId: ModelPrimaryKey) => deleteRFQ(draftId),
    });

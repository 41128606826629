import { ReactNode } from 'react';
import { Separator } from '../separator/Separator';
import { SeparatorIdentifier } from '../separator/Separator.enums';

import './heading-cards-section.scss';

export type HeadingCardsSectionProps = {
    headline?: string;
    children: ReactNode;
};

export const HeadingCardsSection = (props: HeadingCardsSectionProps) => {
    const { headline, children } = props;

    const renderSeparator = () => {
        if (headline === undefined) {
            return null;
        }
        return <Separator header={headline} separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />;
    };

    return (
        <article className="heading-cards-section">
            {renderSeparator()}
            <article className="heading-cards-section__children">{children}</article>
        </article>
    );
};

import { useTranslation } from 'react-i18next';

import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import {
    ButtonDanger,
    ButtonSecondary,
    LoadingStatus,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
} from '@steelbuy/ui-primitive';
import './DeleteDraftModal.scss';

export type DeleteDraftModalProps = {
    modalAccessor: ModalAccessor;
    onConfirm: () => void;
};

export const DeleteDraftModal = ({ modalAccessor, onConfirm }: DeleteDraftModalProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <Modal modalAccessor={modalAccessor}>
            <div className="delete-draft__content">
                <ModalContent onCloseModal={modalAccessor.hide} headline={t('deleteDraftModal.textContent')}>
                    <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/DeleteItem.svg" />
                    <ModalContentActionBar>
                        <ButtonSecondary
                            disabled={modalAccessor.actionPending}
                            label={t('common.cancel')}
                            onClick={modalAccessor.hide}
                        />
                        <ButtonDanger
                            label={t('deleteDraftModal.confirmButtonLabel')}
                            disabled={modalAccessor.actionPending}
                            onClick={onConfirm}
                            loadingStatus={modalAccessor.actionPending ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    </ModalContentActionBar>
                </ModalContent>
            </div>
        </Modal>
    );
};

import { useTranslation } from 'react-i18next';
import { OrganisationEntityDataProvider, useUserSelf } from '@steelbuy/data-provider';
import { CustomError } from '@steelbuy/error';

import { EditPurchaseContact } from '../../components/edit-purchase-contact/EditPurchaseContact';

export const EditPurchaseContactView = () => {
    const { t } = useTranslation('translation');

    const userModel = useUserSelf().getOrThrow(new CustomError(t('error.noUserError')));
    return (
        <OrganisationEntityDataProvider entityId={userModel.organisation.id}>
            <EditPurchaseContact />
        </OrganisationEntityDataProvider>
    );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CardContentAttributesItem,
    CardContentSellerSummaryExpiryTime,
    InputToggleButton,
} from '@steelbuy/ui-primitive';
import { buildClassStringFromClassMap, formatDate, formatDayname, formatTime } from '@steelbuy/util';
import { CardSellerSummaryExpiryTime } from '../card-seller-summary-expiry-time/CardSellerSummaryExpiryTime';
import { ExpiryTimeSelection } from '../expiry-time-selection/ExpiryTimeSelection';

import './ListingDetailsExpiration.scss';

export enum ListingDetailsExpirationView {
    READ = 'READ',
    LIVE_EDITABLE = 'LIVE_EDITABLE',
    EXPIRED = 'EXPIRED',
}

export type ListingDetailsExpirationProps = {
    children?: ReactNode;
    expiryDate?: Date;
    onExpiryDateChange?: (expiryDate?: Date) => void;
    autoRenewExpiryDate?: Date;
    onAutoRenewExpiryChange?: (expiryDate?: Date) => void;
    autoRenew?: boolean;
    showAutoRenew?: boolean;
    onAutoRenewChange?: (enabled: boolean) => void;
    viewMode?: ListingDetailsExpirationView;
};

export const ListingDetailsExpiration = ({
    expiryDate,
    onExpiryDateChange,
    autoRenew,
    showAutoRenew = false,
    onAutoRenewChange,
    autoRenewExpiryDate,
    onAutoRenewExpiryChange,
    viewMode = ListingDetailsExpirationView.READ,
    children,
}: ListingDetailsExpirationProps) => {
    const { t } = useTranslation('uiDomain');

    const headerLabel = t('listingDetailsExpiration.title');
    const isExpiryEditable = viewMode === ListingDetailsExpirationView.EXPIRED;
    const isAutoRenewExpiryEditable =
        showAutoRenew && autoRenew && viewMode === ListingDetailsExpirationView.LIVE_EDITABLE;
    const isAutoRenewExpiryReadable =
        showAutoRenew && autoRenewExpiryDate && autoRenew && viewMode === ListingDetailsExpirationView.READ;
    const isEditable = isExpiryEditable || isAutoRenewExpiryEditable;

    const expiryDatesWrapper = {
        'listing-details-expiration-dates-read': viewMode === ListingDetailsExpirationView.READ,
        'listing-details-expiration-dates-edit-renew': isAutoRenewExpiryEditable,
    };

    const now = new Date();
    const earliestAutoRenewDate = !expiryDate || expiryDate < now ? now : expiryDate;
    return (
        <CardSellerSummaryExpiryTime isEditable={isEditable}>
            <CardContentSellerSummaryExpiryTime header={headerLabel}>
                <div className={buildClassStringFromClassMap(expiryDatesWrapper)}>
                    {isExpiryEditable ? (
                        <ExpiryTimeSelection
                            hideTextWrapper
                            label=""
                            expiryTime={expiryDate}
                            expiryTimeChanged={(expiryTime) => onExpiryDateChange?.(expiryTime)}
                            required
                        />
                    ) : (
                        <CardContentAttributesItem
                            label={t('listingDetailsExpiration.text')}
                            value={`${formatDayname(navigator.language, expiryDate)}, ${formatDate(
                                navigator.language,
                                expiryDate
                            )}, ${formatTime(navigator.language, expiryDate)}`}
                        />
                    )}
                    {isAutoRenewExpiryEditable && (
                        <ExpiryTimeSelection
                            hideTextWrapper
                            label={t('listingDetailsExpiration.autoRewewExpiryLabel')}
                            expiryTime={autoRenewExpiryDate}
                            expiryTimeChanged={(expiryTime) => onAutoRenewExpiryChange?.(expiryTime)}
                            required
                            earliestExpiryDate={earliestAutoRenewDate}
                            testId="auto-renew"
                            includeWeekends
                        />
                    )}
                    {isAutoRenewExpiryReadable && (
                        <CardContentAttributesItem
                            label={t('listingDetailsExpiration.autoRewewExpiryLabel')}
                            value={`${formatDayname(navigator.language, autoRenewExpiryDate)}, ${formatDate(
                                navigator.language,
                                autoRenewExpiryDate
                            )}, ${formatTime(navigator.language, autoRenewExpiryDate)}`}
                        />
                    )}
                </div>

                {showAutoRenew &&
                    (viewMode !== ListingDetailsExpirationView.READ ? (
                        <InputToggleButton
                            value={t('listingDetailsExpiration.autoRenewLabel')}
                            checked={autoRenew}
                            onChange={() => onAutoRenewChange?.(!autoRenew)}
                            helperText={t('listingDetailsExpiration.autoRenewToggleHelper')}
                            data-testid="auto-renew-toggle"
                        />
                    ) : (
                        <CardContentAttributesItem
                            label={t('listingDetailsExpiration.autoRenewLabel')}
                            value={t(
                                `listingDetailsExpiration.${autoRenew ? 'autoRenewEnabled' : 'autoRenewDisabled'}`
                            )}
                        />
                    ))}
            </CardContentSellerSummaryExpiryTime>
            {children}
        </CardSellerSummaryExpiryTime>
    );
};

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfTimestampFieldNames,
    TimestampFieldNames,
    UploadFileFieldNames,
    ArrayOfUploadFileFieldNames,
    UploadFile,
    ModelPrimaryKey,
    Timestamp,
} from '@steelbuy/domain-model-types';
import { ListingSellerPricingModelConverter } from './ListingSellerPricingModel';
import { ListingSellerStatusModelConverter } from './ListingSellerStatusModel';
import { PackageSellerModel } from './PackageSellerModel';

export type PackageSellerDraftModel = Partial<
    Omit<
        PackageSellerModel,
        'autoRenew' | 'pictures' | 'certificates' | 'packageDocument' | 'invoiceAddress' | 'pickupAddress' | 'id'
    > & {
        autoRenew: boolean;
        pickupAddressId: ModelPrimaryKey;
        invoiceAddressId: ModelPrimaryKey;
        pictures: UploadFile[];
        certificates: UploadFile[];
        packageDocument: UploadFile;
    }
> & { readonly id: ModelPrimaryKey; readonly modifiedAt: Timestamp };

export class PackageSellerDraftModelConverter extends ModelConverter<PackageSellerDraftModel> {
    override getUploadFileFields(): Array<
        UploadFileFieldNames<PackageSellerDraftModel> | ArrayOfUploadFileFieldNames<PackageSellerDraftModel>
    > {
        return ['pictures', 'certificates', 'packageDocument'];
    }

    override getTimestampFields(): Array<
        TimestampFieldNames<PackageSellerDraftModel> | ArrayOfTimestampFieldNames<PackageSellerDraftModel>
    > {
        return ['expiresAt', 'modifiedAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<PackageSellerDraftModel> {
        return {
            pricing: new ListingSellerPricingModelConverter(),
            status: new ListingSellerStatusModelConverter(),
        } as FieldConverterMap<PackageSellerDraftModel>;
    }
}

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetNotificationSettings } from '@steelbuy/data-access';
import { useUserSelf } from '@steelbuy/data-provider';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { UrgentRequestsSettingsForm } from './UrgentRequestsSettingsForm';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../page-header/PageHeader';

export const UrgentRequestsSettings = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const userModel = useUserSelf().get();
    const { data: notificationSettings, isLoading } = useGetNotificationSettings();

    if (!userModel || isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <PageHeader
                pageTitle={t('application.urgentRequestsSettings.pageHeader')}
                previousPageTitle={t('application.urgentRequestsSettings.headerBackLinkLabel')}
                onBackClick={() => navigate(RoutePath.URGENT_REQUESTS)}
            />
            <div className="urgent-requests-settings">
                <FormLayout>
                    {notificationSettings && <UrgentRequestsSettingsForm notificationSettings={notificationSettings} />}
                </FormLayout>
            </div>
        </>
    );
};

import { RoutePath } from '../../router/Routes';

export enum AccountTabId {
    MY_ACCOUNT = 'my-account',
    ORGANISATION = 'organisation',
    USER_MANAGEMENT = 'user-management',
}

export const ROUTE_ACCOUNT_OVERVIEW = `${RoutePath.ACCOUNT}#${AccountTabId.MY_ACCOUNT}`;
export const ROUTE_ACCOUNT_ORGANISATION = `${RoutePath.ACCOUNT}#${AccountTabId.ORGANISATION}`;
export const ROUTE_ACCOUNT_USER_MANAGEMENT = `${RoutePath.ACCOUNT}#${AccountTabId.USER_MANAGEMENT}`;

import { useTranslation } from 'react-i18next';
import { HistoricalAddressEntityDataProvider, useHistoricalAddressEntityData } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';

import { Address, AddressLoadingIndicator } from '@steelbuy/ui-domain';
import { AddressIdentifier, CardContentAddressDetails, ErrorHandlerNotitification } from '@steelbuy/ui-primitive';

const HistoricalAddress = () => {
    const historicalAddressEntityData = useHistoricalAddressEntityData();

    const addressModel = historicalAddressEntityData.query().getOrThrow(new NotFoundError());

    return <Address addressModel={addressModel} />;
};

export const HistoricalAddressDetails = ({
    additionalText,
    addressId,
    type,
    labelText,
    attributeLabel,
}: {
    addressId?: string;
    type: AddressIdentifier;
    additionalText?: string;
    labelText?: string;
    attributeLabel?: string;
}) => {
    const { t } = useTranslation('translation');

    if (!addressId) {
        return null;
    }

    return (
        <CardContentAddressDetails
            heading={t(`application.addressDetails.sectionHeading.${type}`)}
            type={type}
            additionalText={additionalText}
            additionalTextLabel={labelText || t('application.addressDetails.additionalInformationLabel')}
            readonly
            attributeLabel={attributeLabel}
        >
            <ErrorHandler
                errorComponent={ErrorHandlerNotitification(t('application.addressDetails.addressNotLoadable'))}
            >
                <HistoricalAddressEntityDataProvider entityId={addressId} pendingComponent={AddressLoadingIndicator}>
                    <HistoricalAddress />
                </HistoricalAddressEntityDataProvider>
            </ErrorHandler>
        </CardContentAddressDetails>
    );
};

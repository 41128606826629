import { useMutation } from '@tanstack/react-query';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import axios from '../../axios';

type UseMutationParams = {
    onSuccess?: (data: unknown, variables: string, context: unknown) => unknown;
    onError?: (error: unknown, variables: string, context: unknown) => unknown;
};

const deleteCartEntry = async (cartEntryId: ModelPrimaryKey) => {
    const { data } = await axios.delete<ModelPrimaryKey>(`/api/buyer/cart/cart-entry/${cartEntryId}`);
    return data;
};

export const useDeleteCartEntry = (mutateOptions?: UseMutationParams) =>
    useMutation({
        mutationFn: (cartEntryId: ModelPrimaryKey) => deleteCartEntry(cartEntryId),
        ...mutateOptions,
    });

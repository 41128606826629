import { Tenant } from '@steelbuy/api-integration';

import { ChallengeRespond } from '@steelbuy/ui-domain';
import { constants } from '../constants';
import { RoutePath } from '../router/Routes';

export const ChallengeRespondView = () => (
    <ChallengeRespond
        apiBaseUrl={constants.apiBaseUrl}
        loginUrl={RoutePath.LOGIN}
        challengeUrl={RoutePath.CHALLENGE_RESPOND}
        tenant={Tenant.MARKETPLACE}
    />
);

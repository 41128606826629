import { useParams } from 'react-router-dom';
import { ListingSellerEntityDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { SellerListingDetails } from '../../components/seller-listing-details/SellerListingDetails';
import { SellerListingDetailsNotFound } from '../../components/seller-listing-details/SellerListingDetailsNotFound';
import { RoutePath } from '../../router/Routes';

type MyListingsDetailsViewProps = {
    goBackPath: RoutePath;
};

export const MyListingsDetailsView = ({ goBackPath }: MyListingsDetailsViewProps) => {
    const { listingId = null } = useParams();

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <SellerListingDetailsNotFound />;
        }

        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <SuccessErrorNotificationContextProvider stayOpen>
                <ListingSellerEntityDataProvider entityId={listingId ?? ''} alwaysFetchOnMount>
                    <SellerListingDetails listingId={listingId ?? ''} goBackPath={goBackPath} />
                </ListingSellerEntityDataProvider>
            </SuccessErrorNotificationContextProvider>
        </ErrorHandler>
    );
};

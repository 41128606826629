import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import { AxiosError } from 'axios';
import { lazy, Suspense, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { challengeRespond, isLoginSuccess, Tenant } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';
import { useAssociateSoftwareToken, useVerifySoftwareToken } from '@steelbuy/data-access';
import { ButtonPrimary, InputTextfield, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import './MfaSetup.scss';

const QRCodeSVG = lazy(() => import('../QrCode'));
interface MfaSetupProps {
    apiBaseUrl: string;
    session: string;
    username: string;
    challengeUrl: string;
    tenant: Tenant;
}

interface FormData {
    code: string;
}

const literal = 'login.challengeRespond.mfaSetup';
export const MfaSetup = ({ apiBaseUrl, session, username, challengeUrl, tenant }: MfaSetupProps) => {
    const { t } = useTranslation('uiDomain');
    const navigate = useNavigate();
    const auth = useAuth();
    const { control, handleSubmit } = useForm<FormData>();
    const [error, setError] = useState('');
    const { data } = useAssociateSoftwareToken({ session });
    const mutation = useVerifySoftwareToken();
    const onSubmit = handleSubmit(({ code }) => {
        mutation.mutate(
            {
                session: data?.session || '',
                userCode: code,
            },
            {
                onError: (e: unknown) => e instanceof AxiosError && e.response && setError(e.response.data.message),
                onSuccess: async (verifySoftwareTokenData) => {
                    if (verifySoftwareTokenData.status === 'SUCCESS') {
                        const response = await challengeRespond(apiBaseUrl, {
                            type: ChallengeNameType.MFA_SETUP,
                            session: verifySoftwareTokenData.session || '',
                            options: {
                                username,
                            },
                            tenant,
                        });
                        if (isLoginSuccess(response)) {
                            auth.authenticate(response.accessToken, response.refreshToken);
                            navigate('/');
                        } else {
                            navigate(challengeUrl, {
                                state: {
                                    session: response.session,
                                    username,
                                    type: response.challengeType,
                                },
                            });
                        }
                    }
                },
            }
        );
    });

    return (
        <main className="mfa-setup">
            <header className="mfa-setup__header">
                <div>{t(`${literal}.header`)}</div>
                <section className="mfa-setup__header--subheading">
                    <p>{t(`${literal}.subheading`)}</p>
                    <ol>
                        <li>
                            <strong>{t(`${literal}.instructions.downloadAndInstall`)}</strong>
                        </li>
                        <li>
                            <strong>{t(`${literal}.instructions.scanQRCode`)}</strong>
                        </li>
                        <li>
                            <strong>{t(`${literal}.instructions.verifyAuthCode`)}</strong>
                        </li>
                    </ol>
                </section>
            </header>
            <Suspense fallback={<div>loading</div>}>
                {data && (
                    <div className="mfa-setup__qr-code">
                        <QRCodeSVG value={`otpauth://totp/steelbuy.app?secret=${data.secretCode}`} />
                    </div>
                )}
            </Suspense>
            <form className="mfa-setup__form" onSubmit={onSubmit}>
                <Controller
                    render={({ field: { onChange, onBlur } }) => (
                        <InputTextfield label="6 digit code" onChange={onChange} onBlur={onBlur} />
                    )}
                    name="code"
                    control={control}
                />
                <ButtonPrimary label="Continue" type="submit" />
            </form>
            {error && <Notification level={NotificationLevel.ERROR} message={error} stayOpen />}
        </main>
    );
};

import { ReactNode } from 'react';

import './ModalContentMain.scss';

export type ModalContentMainProps = {
    imgSrc?: string;
    children?: ReactNode;
};

export const ModalContentMain = (props: ModalContentMainProps) => {
    const { imgSrc, children } = props;

    return (
        <article className="modal-content-main">
            {imgSrc && <img src={imgSrc} alt="Modal Illustration" />}
            {children}
        </article>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferListingStatus, OfferView } from '@steelbuy/domain-model';
import { Dropdown, SelectOption } from '@steelbuy/ui-primitive';

export const FILTER_CRITERIA_ID = 'filter';
export const FILTER_PROPERTY = 'status.value';

interface MyOffersFilterProps {
    title: string;
    placeholder: string;
    setFilter: (filter: OfferListingStatus[]) => void;
    filter: OfferListingStatus[];
    offerView: OfferView;
}
export const OffersFilter = ({ title, placeholder, setFilter, filter, offerView }: MyOffersFilterProps) => {
    const { t } = useTranslation('translation');

    const offerListingStatus = Object.values(OfferListingStatus);

    if (offerView === OfferView.SELLER) {
        [offerListingStatus[0], offerListingStatus[1]] = [offerListingStatus[1], offerListingStatus[0]];
    }

    const filterOptions: SelectOption[] = offerListingStatus.map((value) => ({
        label:
            (offerView === OfferView.SELLER && value === OfferListingStatus.AWAITING_SELLER) ||
            (offerView === OfferView.BUYER && value === OfferListingStatus.AWAITING_BUYER)
                ? t(`application.offerListingsFilter.awaitingYourResponse`)
                : t(`application.offerListingsFilter.optionLabels.${value}`),
        value,
    }));

    return (
        <Dropdown
            multiselect
            title={title}
            options={filterOptions}
            preselection={filter}
            onChange={(value: string[]) => {
                setFilter(value as OfferListingStatus[]);
            }}
            placeholder={placeholder}
        />
    );
};

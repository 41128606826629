import { ReactNode } from 'react';

import './CardContentProductViewDescription.scss';

export type CardContentProductViewDescriptionProps = {
    header: string;
    descriptionText: string;
    children?: ReactNode;
};

export const CardContentProductViewDescription = (props: CardContentProductViewDescriptionProps) => {
    const { header, children, descriptionText } = props;

    const renderChildren = () => {
        if (!children) {
            return null;
        }
        return <span className="card-content-product-view-description__content__image">{children}</span>;
    };

    return (
        <article className="card-content-product-view-description">
            <article className="card-content-product-view-description__header">{header}</article>
            <article className="card-content-product-view-description__content">
                <span className="card-content-product-view-description__content__text">{descriptionText}</span>
                {renderChildren()}
            </article>
        </article>
    );
};

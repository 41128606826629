import { ReactNode } from 'react';
import './CardContentAttributesItem.scss';

export type CardContentAttributesItemProps = {
    label?: string;
    value: string | Array<string>;
    children?: ReactNode;
};

export const CardContentAttributesItem = ({ label, value, children }: CardContentAttributesItemProps) => {
    const valueAsString = Array.isArray(value) ? value.join(', ') : value;

    return (
        <article className="card-content-attributes-item">
            {label && <span className="card-content-attributes-item__label">{label}</span>}
            <div className="card-content-attributes-item__value">
                <span>{valueAsString}</span>
                {children}
            </div>
        </article>
    );
};

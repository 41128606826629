import { FinancialDetailsWithBalanceModel } from './FinancialDetailsModel';

export const isFinancialDetailsWithBalanceModel = (
    financialDetails?: unknown
): financialDetails is FinancialDetailsWithBalanceModel => {
    if (!financialDetails) {
        return false;
    }

    return (
        (financialDetails as FinancialDetailsWithBalanceModel).erpBalance !== undefined &&
        (financialDetails as FinancialDetailsWithBalanceModel).currencyCode !== undefined
    );
};

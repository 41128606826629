import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SEARCH_LISTING_KEY, SEARCH_PACKAGE_KEY } from '@steelbuy/data-access';
import { useListingBuyerSearchData } from '@steelbuy/data-provider';
import { Product, SearchFormData } from '@steelbuy/domain-model';
import { ListingSearchForm } from '../../components/listing-search/ListingSearchForm';
import { useSearchFormDataContext } from '../../components/listing-search/SearchFormDataContext';
import { useSearchResultsContext } from '../../components/listing-search/SearchResultsContext';
import { RoutePath } from '../../router/Routes';

export const SearchFormView = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const searchFormDataContext = useSearchFormDataContext();
    const listingSearchData = useListingBuyerSearchData();
    const queryClient = useQueryClient();
    const { reset } = useSearchResultsContext();
    const submitSearchForm = (searchFormData: SearchFormData) => {
        if (searchFormData.product === Product.PACKAGE) {
            queryClient.removeQueries({ queryKey: [SEARCH_PACKAGE_KEY] });
            searchFormDataContext.setSearchFormData(searchFormData);
            navigate(RoutePath.PACKAGE_SEARCH_RESULTS);
        } else {
            queryClient.removeQueries({ queryKey: [SEARCH_LISTING_KEY] });
            searchFormDataContext.setSearchFormData(searchFormData);
            listingSearchData.resolveFetchStatus();
            navigate(RoutePath.SEARCH_LISTINGS_RESULTS);
        }
    };

    useEffect(() => {
        reset();
    }, []);

    return (
        <ListingSearchForm
            heading={t('application.listingSearchForm.headerTitle')}
            onSubmit={submitSearchForm}
            submitButtonLabel={t('application.ListingSearchFormInputs.submitText')}
        />
    );
};

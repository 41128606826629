import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RFQBuyerDetailsModel, RFQBuyerDetailsModelConverter } from '@steelbuy/domain-model';
import { ApiModel, ModelPrimaryKey } from '@steelbuy/domain-model-types';
import axios from '../../axios';
import { RFQ_DETAILS_CACHE_KEY } from '../../query/rfqs';
import { mapRFQQuoteEntryForDetails } from '../../utils/rfqs/rfqMapper';

const modelConverter = new RFQBuyerDetailsModelConverter();

const withdrawRFQ = async (rfqId: ModelPrimaryKey) => {
    const { data } = await axios.put<ModelPrimaryKey>(`/api/buyer/quotes/${rfqId}/withdraw`);
    const rfqModel = modelConverter.toViewModel(data as unknown as ApiModel<RFQBuyerDetailsModel>);
    rfqModel.quoteEntry = mapRFQQuoteEntryForDetails(rfqModel);
    return rfqModel;
};

export const useWithdrawRFQ = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (rfqId: ModelPrimaryKey) => withdrawRFQ(rfqId),
        onSuccess: (rfqModel: RFQBuyerDetailsModel) => {
            queryClient.setQueryData([RFQ_DETAILS_CACHE_KEY, rfqModel.id], rfqModel);
        },
    });
};

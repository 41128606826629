import { useParams } from 'react-router-dom';
import { UserCollectionDataProvider, UserEntityDataProvider } from '@steelbuy/data-provider';
import { EditUser } from '../../components/edit-add-user/EditUser';

export const EditUserView = () => {
    const { userId } = useParams();

    if (!userId) {
        return null;
    }

    return (
        <UserCollectionDataProvider lazyLoad>
            <UserEntityDataProvider entityId={userId}>
                <EditUser />
            </UserEntityDataProvider>
        </UserCollectionDataProvider>
    );
};

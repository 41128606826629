import { useFooEntityData } from '@steelbuy/data-provider';

export const FooEntityDeletion = () => {
    const fooEntity = useFooEntityData();

    const handleDelete = (): void => {
        fooEntity.delete();
    };

    return (
        <div>
            <button onClick={handleDelete}>Delete</button>
        </div>
    );
};

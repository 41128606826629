import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ButtonPrimary,
    FormActionbar,
    MarketingBannerContact,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../page-header/PageHeader';

import './RFQDetailsSuccess.scss';

export const RFQDetailsSuccess = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state?.quoteReferenceNumber) {
            navigate(RoutePath.MY_RFQS);
        }
    }, []);

    if (!location.state?.quoteReferenceNumber) {
        return null;
    }

    const referenceNumberText = (
        <>
            {t('application.createRFQSuccess.referenceNumber')}
            <span className="reference-number">{location.state.quoteReferenceNumber}</span>
        </>
    );

    return (
        <>
            <PageHeader
                pageTitle={location.state.quoteReferenceNumber}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(RoutePath.MY_RFQS)}
            />
            <div className="rfq-details-success">
                <FormLayout>
                    <NotificationIllustrated
                        alignment={NotificationIllustratedAlignment.VERTICAL}
                        imgSrc="/assets/lib-ui-primitive/images/ListingLive-Success.svg"
                        header={t('application.myRFQDetails.success')}
                        helperText={referenceNumberText}
                    />
                    <MarketingBannerContact
                        header={t('application.createRFQSuccess.marketingBannerHeader')}
                        text={t('application.createRFQSuccess.marketingBannerText')}
                    >
                        <a href="mailto:support@steel-buy.com">
                            {t('application.createRFQSuccess.marketingBannerEmail')}
                        </a>
                    </MarketingBannerContact>
                    <FormActionbar>
                        <ButtonPrimary
                            label={t('application.createRFQSuccess.buttonLabelMyListings')}
                            onClick={() => navigate(RoutePath.MY_RFQS)}
                        />
                    </FormActionbar>
                </FormLayout>
            </div>
        </>
    );
};

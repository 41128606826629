import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '@steelbuy/data-provider';
import { Feature } from '@steelbuy/domain-model';
import {
    ButtonTertiaryOnLightM,
    SubTab,
    TabBarItem,
    TabChip,
    TabbedPane,
    WizardNavigationButtons,
} from '@steelbuy/ui-primitive';
import { MyListingsDrafts } from './MyListingsDrafts';
import { MyListingsList } from './MyListingsList';
import { useTabbedPane } from '../../hooks/useTabbedPane';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { MyPackagesDrafts } from '../my-packages/MyPackagesDrafts';
import { MyPackagesList } from '../my-packages/MyPackagesList';
import { PageHeader } from '../page-header/PageHeader';

enum MyListingsTabId {
    LISTINGS = 'listings',
    DRAFTS = 'drafts',
}
enum MyListingSubTab {
    LISTINGS = 'listings',
    PACKAGES = 'packages',
}

const subTabConfig = {
    [MyListingsTabId.DRAFTS]: {
        defaultSubTab: MyListingSubTab.LISTINGS,
        tabs: Object.values(MyListingSubTab),
    },
    [MyListingsTabId.LISTINGS]: {
        defaultSubTab: MyListingSubTab.LISTINGS,
        tabs: Object.values(MyListingSubTab),
    },
};

const myListingsTabId = Object.values(MyListingsTabId);
const DEFAULT_TAB_ID = MyListingsTabId.LISTINGS;

export const MyListings = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { isFeatureSupported, areFeaturesSupported } = useFeatureFlag();
    const isPackageFeatureEnabled = isFeatureSupported(Feature.PACKAGES);
    const isPackageDraftFeatureEnabled = areFeaturesSupported([Feature.PACKAGES, Feature.PACKAGES_DRAFT]);
    const { activeTabId, setActiveTabId, activeSubTabId, setActiveSubTabId } = useTabbedPane<
        MyListingsTabId,
        MyListingSubTab
    >(myListingsTabId, DEFAULT_TAB_ID, subTabConfig);

    return (
        <div className="my-listings">
            <PageHeader pageTitle={t('application.myListings.header')} />
            <TableLayout>
                <TabbedPane
                    activeTabId={activeTabId}
                    renderTabContent={(tabId) =>
                        tabId === MyListingsTabId.DRAFTS ? (
                            <>
                                {isPackageDraftFeatureEnabled && (
                                    <SubTab
                                        activeSubTab={activeSubTabId ?? MyListingSubTab.LISTINGS}
                                        renderSubTabContent={(subTabId) =>
                                            subTabId === MyListingSubTab.LISTINGS ? (
                                                <MyListingsDrafts />
                                            ) : (
                                                <MyPackagesDrafts />
                                            )
                                        }
                                        subTabChanged={setActiveSubTabId}
                                    >
                                        <div className="tab-chip-wrapper">
                                            <TabChip
                                                label={t('application.myListings.subTabDraftsLabel')}
                                                tabId={MyListingSubTab.LISTINGS}
                                            />
                                            <TabChip
                                                label={t('application.myPackages.subTabDraftsLabel')}
                                                tabId={MyListingSubTab.PACKAGES}
                                            />
                                        </div>
                                    </SubTab>
                                )}
                                {!isPackageDraftFeatureEnabled && <MyListingsDrafts />}
                            </>
                        ) : (
                            <>
                                {isPackageFeatureEnabled && (
                                    <SubTab
                                        activeSubTab={activeSubTabId ?? MyListingSubTab.LISTINGS}
                                        renderSubTabContent={(subTabId) =>
                                            subTabId === MyListingSubTab.LISTINGS ? (
                                                <MyListingsList />
                                            ) : (
                                                <MyPackagesList />
                                            )
                                        }
                                        subTabChanged={setActiveSubTabId}
                                    >
                                        <div className="tab-chip-wrapper">
                                            <TabChip
                                                label={t('application.myListings.subTabListingLabel')}
                                                tabId={MyListingSubTab.LISTINGS}
                                            />
                                            <TabChip
                                                label={t('application.myPackages.subTabPackageLabel')}
                                                tabId={MyListingSubTab.PACKAGES}
                                            />
                                        </div>
                                    </SubTab>
                                )}
                                {!isPackageFeatureEnabled && <MyListingsList />}
                            </>
                        )
                    }
                    tabChanged={setActiveTabId}
                >
                    <WizardNavigationButtons columns>
                        <div>
                            <TabBarItem label={t('application.myListings.listings')} tabId={MyListingsTabId.LISTINGS} />
                            <TabBarItem label={t('application.myListings.drafts')} tabId={MyListingsTabId.DRAFTS} />
                        </div>
                        <ButtonTertiaryOnLightM
                            label={t('application.myListings.noListingsCallToAction')}
                            onClick={() =>
                                navigate(RoutePath.CREATE_LISTING_WIZARD, {
                                    state: {
                                        goBackLink:
                                            activeTabId === MyListingsTabId.LISTINGS
                                                ? RoutePath.MY_LISTINGS
                                                : RoutePath.MY_LISTINGS_DRAFTS,
                                        goBackText:
                                            activeTabId === MyListingsTabId.LISTINGS
                                                ? t('application.myListings.listings')
                                                : t('application.myListings.drafts'),
                                    },
                                })
                            }
                        />
                    </WizardNavigationButtons>
                </TabbedPane>
            </TableLayout>
        </div>
    );
};

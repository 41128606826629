import { Grade, Temper } from '../../../MaterialModel.enums';

export const aluminiumFlatTreadPlateGradeCluster1 = [Grade.G_1050, Grade.G_3003] as const;

export const aluminiumFlatTreadPlateGradeCluster2 = [Grade.G_1050A] as const;

export const aluminiumFlatTreadPlateGradeCluster3 = [Grade.G_5052] as const;

export const aluminiumFlatTreadPlateGradeCluster4 = [Grade.G_5454_BS_4300_8] as const;

export const aluminiumFlatTreadPlateGradeCluster5 = [Grade.G_5754] as const;

export const aluminiumFlatTreadPlateGrades = [
    aluminiumFlatTreadPlateGradeCluster1,
    aluminiumFlatTreadPlateGradeCluster2,
    aluminiumFlatTreadPlateGradeCluster3,
    aluminiumFlatTreadPlateGradeCluster4,
    aluminiumFlatTreadPlateGradeCluster5,
];

export const aluminiumFlatTreadPlateTemperCluster1 = [Temper.O] as const;

export const aluminiumFlatTreadPlateTemperCluster2 = [Temper.H14] as const;

export const aluminiumFlatTreadPlateTemperCluster3 = [Temper.H32] as const;

export const aluminiumFlatTreadPlateTemperCluster4 = [Temper.H22, Temper.H32, Temper.H111, Temper.O] as const;

export const aluminiumFlatTreadPlateTemperCluster5 = [Temper.H22, Temper.H111, Temper.H114] as const;

export const aluminiumFlatTreadPlateTempers = [
    aluminiumFlatTreadPlateTemperCluster1,
    aluminiumFlatTreadPlateTemperCluster2,
    aluminiumFlatTreadPlateTemperCluster3,
    aluminiumFlatTreadPlateTemperCluster4,
    aluminiumFlatTreadPlateTemperCluster5,
];

export type AluminiumFlatTreadPlateGradeAndTemper =
    | {
          grade: typeof aluminiumFlatTreadPlateGradeCluster1[number];
          temper: typeof aluminiumFlatTreadPlateTemperCluster1[number];
      }
    | {
          grade: typeof aluminiumFlatTreadPlateGradeCluster2[number];
          temper: typeof aluminiumFlatTreadPlateTemperCluster2[number];
      }
    | {
          grade: typeof aluminiumFlatTreadPlateGradeCluster3[number];
          temper: typeof aluminiumFlatTreadPlateTemperCluster3[number];
      }
    | {
          grade: typeof aluminiumFlatTreadPlateGradeCluster4[number];
          temper: typeof aluminiumFlatTreadPlateTemperCluster4[number];
      }
    | {
          grade: typeof aluminiumFlatTreadPlateGradeCluster5[number];
          temper: typeof aluminiumFlatTreadPlateTemperCluster5[number];
      };

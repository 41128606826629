import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Definition, MaterialWithDimensions, ProductType } from '@steelbuy/domain-model';
import { WizardBarItemStatus, useWizardStepsContext } from '@steelbuy/ui-primitive';
import { AddRFQItem } from './add-rfq-item/AddRFQItem';
import { DeleteRFQItemModal } from './DeleteRFQItemModal';
import { RFQTable } from './rfq-table/RFQTable';
import { RFQItemGroup } from './RFQItemGroup';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { RFQStep, getStepRoute, hasFormData, isStep2Valid } from '../createRFQUtil';
import { RFQTeaser } from '../rfq-teaser/RFQTeaser';
import { useRfqFormContext } from '../RfqFormContext';
import { isStep1WithDefinition, isStep1WithPlateType, isStep2, RFQType, Step2Type } from '../Schema';
import './Step2.scss';
import { CreateRFQActionBar } from '../step3/CreateRFQActionBar';

interface SaveDraftContext {
    onSaveDraft: () => void;
    loadingState: boolean;
}

export const Step2 = () => {
    const { onSaveDraft, loadingState } = useOutletContext<SaveDraftContext>();
    const { formData, updateFormData, cancelEditRow, setUpdateValuesCallback, draftId, saveDraftCounter } =
        useRfqFormContext();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const methods = useForm<RFQType>({
        defaultValues: isStep2(formData) ? formData : (formData as RFQType),
    });
    const navigate = useNavigate();
    const clickedButton = useRef<'next' | 'back' | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const deleteItemCallback = useRef<() => void>(() => null);
    const { updateStepStatus, isStepVisited } = useWizardStepsContext();

    useEffect(() => {
        setUpdateValuesCallback((setStepStatus) => {
            updateFormData(methods.getValues());
            if (setStepStatus) {
                updateStepStatus(
                    RFQStep.ADD_ITEMS,
                    isStep2Valid(methods.getValues()) ? WizardBarItemStatus.SUCCESS : WizardBarItemStatus.INCOMPLETE
                );
            }
        });
    }, []);

    if (!hasFormData(formData)) {
        return null;
    }

    let tableData: {
        materialData: MaterialWithDimensions;
        key: Definition | ProductType;
    }[] = [];
    if (isStep1WithPlateType(formData)) {
        tableData = formData.plateType.map((plateType) => ({
            materialData: {
                ...formData,
                definition: undefined,
                plateType,
            } as MaterialWithDimensions,
            key: plateType,
        }));
    } else if (isStep1WithDefinition(formData)) {
        tableData = formData.definition.map((definition) => ({
            materialData: {
                ...formData,
                plateType: undefined,
                definition,
            } as MaterialWithDimensions,
            key: definition,
        }));
    }
    const onSubmit = methods.handleSubmit(
        (data) => {
            cancelEditRow();
            updateFormData(data);
            updateStepStatus(
                RFQStep.ADD_ITEMS,
                isStep2Valid(data) ? WizardBarItemStatus.SUCCESS : WizardBarItemStatus.INCOMPLETE
            );
            if (clickedButton.current === 'back') {
                navigate(getStepRoute(1, draftId));
            }
            if (clickedButton.current === 'next') {
                navigate(getStepRoute(3, draftId));
            }
        },
        (error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    );
    return (
        <div className="create-rfq-step2">
            <form onSubmit={onSubmit}>
                <FormLayout>
                    <p className="create-rfq-step2__title">{t('application.createRFQ.addItems')}</p>
                    {tableData?.map(({ materialData, key }, index) => (
                        <div className="create-rfq-step2__rfq-section" key={key}>
                            <RFQTeaser material={materialData} />
                            <RFQItemGroup methods={methods} material={materialData} indexer={key}>
                                {(headings, append, fields, remove, update) => (
                                    <>
                                        {fields && (
                                            <RFQTable
                                                fields={fields}
                                                headings={headings}
                                                material={materialData}
                                                onDelete={(rowIndex) => {
                                                    deleteItemCallback.current = () => {
                                                        remove(rowIndex);
                                                    };
                                                    setShowDeleteModal(true);
                                                }}
                                                groupIndex={index}
                                                append={append}
                                                update={update}
                                            />
                                        )}
                                        <AddRFQItem
                                            append={append}
                                            headings={headings}
                                            material={materialData}
                                            fields={fields as MaterialWithDimensions[]}
                                            hasAddedRows={
                                                (methods.getValues() as Step2Type).materials?.[key]?.length > 0
                                            }
                                            draftId={draftId}
                                            stepVisited={isStepVisited(RFQStep.ADD_ITEMS)}
                                            saveDraftCounter={saveDraftCounter}
                                        />
                                    </>
                                )}
                            </RFQItemGroup>
                        </div>
                    ))}

                    <CreateRFQActionBar
                        onNext={() => {
                            clickedButton.current = 'next';
                            onSubmit();
                        }}
                        isSubmitDisabled={false}
                        nextLabel={t('application.createRFQ.wizardNextButtonLabel')}
                        onSaveDraft={onSaveDraft}
                        onBack={() => {
                            clickedButton.current = 'back';
                            onSubmit();
                        }}
                        isLoading={loadingState}
                    />
                </FormLayout>
            </form>
            <DeleteRFQItemModal
                show={showDeleteModal}
                onConfirm={() => {
                    deleteItemCallback.current();
                    setShowDeleteModal(false);
                }}
                onModalClose={() => setShowDeleteModal(false)}
            />
        </div>
    );
};

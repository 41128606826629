import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    MY_DRAFT_PACKAGE_KEY,
    removeItemFromCache,
    useDeletePackageDraft,
    useMyDraftPackages,
} from '@steelbuy/data-access';
import { PackageSellerDraftModel } from '@steelbuy/domain-model';
import { useModal } from '@steelbuy/modal-dialog';
import { DeleteDraftModal, ListingDraftTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    FillHeightContainer,
    LoadingSpinner,
    LoadingStatus,
    useSuccessErrorNotification,
} from '@steelbuy/ui-primitive';
import { RoutePath, createRouteUrl } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';
import { MyListingEmptyState } from '../my-listings/MyListingEmptyState';
import './MyPackagesDrafts.scss';
import '../my-listings/MyListings.scss';

export const MyPackagesDrafts = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, total, refetch } = useMyDraftPackages();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const handleLoadMore = () => {
        fetchNextPage();
    };
    const modal = useModal();
    const { mutate } = useDeletePackageDraft();
    const selectedPackageDraftId = useRef<string>('');

    const { Notification, showSuccessNotification, showErrorNotification, hideNotification } =
        useSuccessErrorNotification();
    useEffect(
        () => () => {
            hideNotification();
        },
        []
    );
    const handleDeleteDraft = (draftId: string) => {
        hideNotification();
        modal.show();
        selectedPackageDraftId.current = draftId;
    };

    const handleDeletePackageDraft = (draftId: string) => {
        modal.setActionPending(true);
        mutate(draftId, {
            onSuccess: () => {
                showSuccessNotification(t('application.createListingOverview.notificationSuccess'));
                modal.setActionPending(false);
                modal.hide();
                removeItemFromCache(queryClient, [MY_DRAFT_PACKAGE_KEY], draftId);
                refetch();
            },
            onError: () => {
                modal.setActionPending(false);
                modal.hide();
                showErrorNotification(t('application.createListingOverview.notificationError'));
            },
        });
    };

    const editNavigateState = {
        goBackLink: RoutePath.MY_PACKAGES_DRAFTS,
        goBackText: t('application.myListings.drafts'),
    };

    const handleEditDraft = (draft: PackageSellerDraftModel) => {
        navigate(
            createRouteUrl(
                RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                ['draftId', draft.id],
                ['listingType', 'package']
            ),
            {
                state: editNavigateState,
            }
        );
    };

    return (
        <div className="my-packages-drafts">
            <div className="my-listings-list">
                {isLoading ? (
                    <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                        <LoadingSpinner fillContainerHeight />
                    </FillHeightContainer>
                ) : (
                    <>
                        {Notification}
                        <DataHeader
                            headingValue={t('application.myPackages.numberOfPackageDrafts', {
                                count: total ?? 0,
                            })}
                        />
                        <div className="my-listings-list__listings">
                            {data?.map((packageListing) => (
                                <Card
                                    key={packageListing.id}
                                    isClickable
                                    url={createRouteUrl(
                                        RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                                        ['draftId', packageListing.id],
                                        ['listingType', 'package']
                                    )}
                                    linkState={editNavigateState}
                                >
                                    <ListingDraftTeaser
                                        draft={packageListing}
                                        isPackage
                                        onEdit={() => handleEditDraft(packageListing)}
                                        onDelete={() => handleDeleteDraft(packageListing.id)}
                                    />
                                </Card>
                            ))}
                        </div>
                        {hasNextPage && (
                            <div className="my-listings-list__load-more">
                                <ButtonTertiaryOnLightM
                                    onClick={handleLoadMore}
                                    label={t('uiDomain:commonList.loadMore')}
                                    loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                />
                            </div>
                        )}
                    </>
                )}
                {data?.length === 0 && <MyListingEmptyState />}
            </div>
            <DeleteDraftModal
                modalAccessor={modal}
                onConfirm={() => handleDeletePackageDraft(selectedPackageDraftId.current)}
            />
        </div>
    );
};

import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { MaterialType, Product, Shape } from '@steelbuy/domain-model';
import { hasDraftChanged } from './createRFQUtil';
import { isPlateProductSelected, isStep1WithDefinition, isStep1WithPlateType, RFQType } from './Schema';

type EditRowsState = Record<number, number>;
type UpdateValuesCallback = (updateStepStatus?: boolean) => void;
type GoBack = {
    link: string;
    label: string;
} | null;

export interface RfqContextType {
    formData?: RFQType;
    updateFormData: (val: Partial<RFQType>) => void;
    editRow: (rfqGroupIndex: number, rowIndex: number) => void;
    cancelEditRow: () => void;
    editRowByGroup: EditRowsState;
    getUpdatedFormValues: (updateStepStatus?: boolean) => Partial<RFQType>;
    setDraft: (val: Partial<RFQType>, id: string) => void;
    addSimilarGroupIndex: number | null;
    draftId: string | null;
    addSimilarRow: (index: number) => void;
    reset: () => void;
    setUpdateValuesCallback: (callback: UpdateValuesCallback) => void;
    hasUnsavedChanges: () => boolean;
    setGoBack: (goBack: GoBack) => void;
    goBack: GoBack;
    allowNavigation: boolean;
    setAllowNavigation: (allowNavigation: boolean) => void;
    onSaveDraft: () => void;
    saveDraftCounter: number;
}

const defaultFormValues: Partial<RFQType> = {
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
};

const FormContext = createContext<RfqContextType>({
    formData: undefined,
    getUpdatedFormValues: () => defaultFormValues,
    updateFormData: () => {},
    editRow: () => null,
    cancelEditRow: () => null,
    editRowByGroup: {},
    addSimilarGroupIndex: null,
    draftId: null,
    addSimilarRow: () => null,
    reset: () => null,
    setUpdateValuesCallback: () => null,
    hasUnsavedChanges: () => false,
    setDraft: () => null,
    setGoBack: () => null,
    goBack: null,
    allowNavigation: false,
    setAllowNavigation: () => null,
    onSaveDraft: () => null,
    saveDraftCounter: 0,
});

export const useRfqFormContext = () => useContext(FormContext);

export const RfqFormProvider = ({ children }: PropsWithChildren) => {
    const [updateCounter, setUpdateCounter] = useState(0);
    const formData = useRef<RFQType>({} as RFQType);
    const prevDraft = useRef<Partial<RFQType>>({} as RFQType);
    const [editRowByGroup, setEditRowByGroup] = useState<EditRowsState>({});
    const [addSimilarGroupIndex, setAddSimilarGroupIndex] = useState<number | null>(null);
    const [draftId, setDraftId] = useState<string | null>(null);
    const [goBack, setGoBack] = useState<GoBack>(null);
    const [allowNavigation, setAllowNavigation] = useState(false);
    const [saveDraftCounter, setSaveDraftCounter] = useState(0);

    const updateValuesCallback = useRef<UpdateValuesCallback>(() => formData);
    const updateFormData = useCallback((updatedData: Partial<RFQType>) => {
        formData.current = { ...formData.current, ...updatedData };
        setUpdateCounter((prev) => prev + 1);
    }, []);

    const setUpdateValuesCallback = useCallback((callback: UpdateValuesCallback) => {
        updateValuesCallback.current = callback;
    }, []);

    const setDraft = useCallback((draftRFQ: Partial<RFQType>, id: string) => {
        prevDraft.current = structuredClone(draftRFQ);
        setDraftId(id);
    }, []);

    const getUpdatedFormValues = useCallback((setStepStatus?: boolean) => {
        updateValuesCallback.current(setStepStatus);
        return formData.current;
    }, []);

    const hasUnsavedChanges = useCallback(() => {
        updateValuesCallback.current();
        if (draftId) {
            return hasDraftChanged(prevDraft.current, formData.current);
        }
        return (
            isStep1WithDefinition(formData.current) ||
            isStep1WithPlateType(formData.current) ||
            isPlateProductSelected(formData.current)
        );
    }, [formData, draftId]);

    const editRow = useCallback(
        (rfqGroupIndex: number, rowIndex: number) => {
            setAddSimilarGroupIndex(null);
            setEditRowByGroup({ [rfqGroupIndex]: rowIndex });
        },
        [setEditRowByGroup]
    );

    const cancelEditRow = useCallback(() => {
        setEditRowByGroup({});
        setAddSimilarGroupIndex(null);
    }, [setEditRowByGroup]);

    const addSimilarRow = useCallback(
        (groupIndex: number) => {
            setAddSimilarGroupIndex(groupIndex);
            setEditRowByGroup({});
        },
        [setAddSimilarGroupIndex]
    );

    const reset = useCallback(() => {
        formData.current = {} as RFQType;
        setUpdateCounter((prev) => prev + 1);
        cancelEditRow();
        prevDraft.current = {};
        setDraftId(null);
        setGoBack(null);
    }, []);

    const onSaveDraft = useCallback(() => {
        cancelEditRow();
        setSaveDraftCounter((prev) => prev + 1);
    }, [cancelEditRow, setSaveDraftCounter]);

    const value = useMemo(
        () => ({
            formData: formData.current,
            updateFormData,
            editRow,
            cancelEditRow,
            editRowByGroup,
            addSimilarGroupIndex,
            addSimilarRow,
            reset,
            getUpdatedFormValues,
            setUpdateValuesCallback,
            hasUnsavedChanges,
            setDraft,
            draftId,
            setGoBack,
            goBack,
            allowNavigation,
            setAllowNavigation,
            onSaveDraft,
            saveDraftCounter,
        }),
        [
            updateFormData,
            editRow,
            cancelEditRow,
            editRowByGroup,
            addSimilarGroupIndex,
            addSimilarRow,
            setUpdateValuesCallback,
            hasUnsavedChanges,
            updateCounter,
            getUpdatedFormValues,
            setDraft,
            draftId,
            goBack,
            allowNavigation,
            setAllowNavigation,
            onSaveDraft,
            saveDraftCounter,
        ]
    );

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

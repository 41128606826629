import { ButtonHTMLAttributes, useContext } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import { TabbedPaneContext } from '../../tabbed-pane/TabbedPane';

import './TabBarItem.scss';

export type TabBarItemProps = {
    label: string;
    tabId: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export const TabBarItem = (props: TabBarItemProps) => {
    const { label, tabId, ...rest } = props;
    const { getTabId, setTabId } = useContext(TabbedPaneContext);

    const tabItemClassMap = {
        'tab-bar-item': true,
        'tab-bar-item--selected': getTabId() === tabId,
    };

    return (
        <button className={buildClassStringFromClassMap(tabItemClassMap)} onClick={() => setTabId(tabId)} {...rest}>
            {label}
        </button>
    );
};

import { Optional, Potentially } from '@steelbuy/types';

import { AccessibleUrl, ApiUrl, Url } from './Url';
import { isApiUrl, isUrl } from './Url.guards';

export const createUrlFromApiUrl = (apiUrl: Potentially<ApiUrl>): Optional<Url> => {
    let url = null;
    if (isApiUrl(apiUrl)) {
        url = {
            discriminator: 'Url',
            ...apiUrl,
        } as AccessibleUrl;
    }
    return new Optional<Url>(url);
};

export const createApiUrlFromUrl = (url: Potentially<Url>): Optional<ApiUrl> => {
    let apiUrl = null;
    if (isUrl(url)) {
        apiUrl = { ...url } as Record<string, unknown>;
        delete apiUrl['discriminator'];
    }
    return new Optional<ApiUrl>(apiUrl as ApiUrl);
};

export const createAccessableUrlFromString = (urlString: string): AccessibleUrl =>
    ({
        accessible: true,
        discriminator: 'Url',
        value: urlString,
    } as AccessibleUrl);

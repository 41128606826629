import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resetPassword, Tenant } from '@steelbuy/api-integration';
import { SetPasswordForm } from '../../set-password-form/SetPasswordForm';

import './ResetPasswordForm.scss';

interface ResetPasswordFormProps {
    token: string;
    onSuccess: (username: string) => void;
    forgotPasswordUrl: string;
    tenant: Tenant;
    apiBaseUrl: string;
}

export const ResetPasswordForm = ({
    token,
    onSuccess,
    forgotPasswordUrl,
    tenant,
    apiBaseUrl,
}: ResetPasswordFormProps) => {
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation('uiDomain');
    const classPrefix = 'reset-password-form';
    const [error, setError] = useState('');

    const onSubmit = async (newPassword: string) => {
        setError('');
        setLoading(true);
        try {
            const username = await resetPassword(apiBaseUrl, newPassword, token, tenant);
            onSuccess(username);
        } catch (e) {
            setError(t('login.resetPassword.tokenExpired'));
        }
        setLoading(false);
    };

    return (
        <main className={`${classPrefix} ${classPrefix}--bg`}>
            <header className={`${classPrefix}__header`}>
                <div>{t('login.resetPassword.header')}</div>
            </header>
            <SetPasswordForm
                onSubmit={onSubmit}
                isLoading={isLoading}
                error={error}
                notificationLink={{
                    label: t('login.resetPassword.resendEmail'),
                    to: forgotPasswordUrl,
                }}
            />
        </main>
    );
};

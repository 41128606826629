import { ChangeEvent, InputHTMLAttributes, ReactElement, ReactNode } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';

import './InputCheckbox.scss';

export type InputCheckboxProps = {
    value?: string;
    label?: ReactElement;
    children?: ReactNode;
    onChange?: (checked: boolean, value: string) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputCheckbox = (props: InputCheckboxProps) => {
    const { value, disabled, label, children, onChange, ...rest } = props;

    const inputCheckboxClassMap = {
        'input-checkbox': true,
        'input-checkbox--disabled': disabled,
    };

    const renderValue = () => {
        if (value === undefined) {
            return null;
        }
        return <span className="input-checkbox__value">{value}</span>;
    };
    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="input-checkbox__label">{label}</span>;
    };

    const renderChildren = () => {
        if (children === undefined) {
            return null;
        }
        return <article className="input-checkbox__children">{children}</article>;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onChange !== undefined) {
            onChange(event.currentTarget.checked, event.currentTarget.value ?? null);
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputCheckboxClassMap)}>
            <span className="input-checkbox__input">
                <input type="checkbox" onChange={handleChange} {...rest} />
            </span>
            <article className="input-checkbox__content">
                {renderValue()}
                {renderLabel()}
                {renderChildren()}
            </article>
        </label>
    );
};

import { ButtonHTMLAttributes } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';
import { ButtonSize } from '../button/Button.enum';
import { LoadingStatus } from '../button-loading-spinner/LoadingStatus.enum';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './ButtonDanger.scss';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../loading-spinner/LoadingSpinner.enums';

export type ButtonDangerProps = {
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
    size?: ButtonSize;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonDanger = (props: ButtonDangerProps) => {
    const {
        label,
        icon,
        disabled = false,
        loadingStatus = LoadingStatus.IDLE,
        type = 'button',
        size = ButtonSize.M,
        ...rest
    } = props;

    const isSmall = size === ButtonSize.S;

    const buttonDangerClassMap = {
        'button-danger': true,
        'button-danger--small': isSmall,
        'button-danger__only-icon': !label,
    };

    const buttonDangerContentClassMap = {
        'button-danger__content': true,
        'button-danger__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-danger__content__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLoadingSpinner = () => {
        if (loadingStatus === LoadingStatus.PENDING) {
            return <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />;
        }
        return null;
    };

    return (
        <button
            type={type}
            className={buildClassStringFromClassMap(buttonDangerClassMap)}
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonDangerContentClassMap)}>
                {renderLabel()}
                {renderIcon()}
            </span>
            {renderLoadingSpinner()}
        </button>
    );
};

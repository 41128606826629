import { Grade, Specification, Finish } from '../../../MaterialModel.enums';

export const mildSteelHotRolledFloorplateGrade = [Grade.S235, Grade.S275, Grade.S355] as const;

export type MildSteelHotRolledFloorplateGrade = typeof mildSteelHotRolledFloorplateGrade[number];

export const mildSteelHotRolledFloorplateSpecification = [
    Specification.JR,
    Specification.J0,
    Specification.J0W,
    Specification.J0WP,
    Specification.J2,
    Specification.J2_PLUS_N,
    Specification.J2G3,
    Specification.J2G4,
    Specification.J2W,
    Specification.JRC,
] as const;

export type MildSteelHotRolledFloorplateSpecification = typeof mildSteelHotRolledFloorplateSpecification[number];

export const mildSteelHotRolledFloorplateFinish = [Finish.DRY] as const;

export type MildSteelHotRolledFloorplateFinish = typeof mildSteelHotRolledFloorplateFinish[number];

export type MildSteelHotRolledFloorplate = {
    grade: MildSteelHotRolledFloorplateGrade;
    specification: MildSteelHotRolledFloorplateSpecification;
    finish: MildSteelHotRolledFloorplateFinish;
};

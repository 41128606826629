import { CurrencyCode } from '@steelbuy/currency';
import { ApiDownloadFile, ApiModel } from '@steelbuy/domain-model-types';

import { ListingBuyerModel } from './ListingBuyerModel';
import { DeliveryPromise, ListingMaterialAge, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingStatus } from './ListingSellerModel.enums';
import { materialDimensionCoilModelMock } from './MaterialDimensionModel.mock';
import { TradeUnit } from './MaterialModel.enums';
import {
    coatedCoilMaterialModelMock,
    coldRolledCoilMaterialModelMock,
    electroZincCoilMaterialModelMock,
    hotRolledCoilMaterialModelMock,
    hotRolledFloorplateCoilMaterialModelMock,
} from './MaterialModel.mock';

const apiDownloadFile: ApiDownloadFile = {
    url: {
        accessible: true,
        value: 'https://steelbuy.com',
    },
    meta: {
        mimeType: 'application/pdf',
        title: 'Test',
        fileName: 'test.pdf',
        lastModifiedDate: '2022-01-01T16:30:12.345Z',
        size: 12345,
    },
};

const pdfDownloadFile: ApiDownloadFile = {
    url: {
        accessible: true,
        value: 'https://file-examples.com/wp-content/uploads/2017/10/file-sample_150kB.pdf',
    },
    meta: {
        mimeType: 'application/pdf',
        title: 'Test',
        fileName: 'test.pdf',
        lastModifiedDate: '2022-01-01T16:30:12.345Z',
        size: 150000,
    },
};

const listingBuyerModelFoundation = {
    expiresAt: '2022-12-31T16:30:12.345Z',
    currency: CurrencyCode.GBP,
    pricing: {
        priceIncludingDelivery: 209465,
        totalIncludingVat: 12 * 209465 + 100000,
        totalExcludingVat: 12 * 209465,
        vat: 100000,
        currencyCode: CurrencyCode.GBP,
    },
    weight: 12,
    age: ListingMaterialAge.UP_TO_SIX_MONTHS,
    origin: ListingMaterialOrigin.WESTERN_EUROPEAN,
    tradeUnit: TradeUnit.TON,
    deliveryPromise: DeliveryPromise.DAYS_3_5,
    autoRenew: { enabled: true },
    negotiations: [],
    isAccepted: false,
    listingStatusType: ListingStatus.PURCHASED,
};

export const hotRolledCoilListingBuyerModelMock: ApiModel<ListingBuyerModel> = {
    ...listingBuyerModelFoundation,
    id: 'hotRolledCoilListing',
    listingId: 'hotRolledCoilListingBuyerModelMock',
    prime: true,
    testCertificate: pdfDownloadFile,
    material: hotRolledCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    canCheckout: true,
};

export const coldRolledCoilListingBuyerModelMock: ApiModel<ListingBuyerModel> = {
    ...listingBuyerModelFoundation,
    id: 'coldRolledCoilListing',
    listingId: 'coldRolledCoilListingBuyerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: coldRolledCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    canCheckout: true,
};

export const hotRolledFloorplateCoilListingBuyerModelMock: ApiModel<ListingBuyerModel> = {
    ...listingBuyerModelFoundation,
    id: 'hotRolledFloorplateCoilListing',
    listingId: 'hotRolledFloorplateCoilListingBuyerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: hotRolledFloorplateCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    canCheckout: true,
};

export const electroZincCoilListingBuyerModelMock: ApiModel<ListingBuyerModel> = {
    ...listingBuyerModelFoundation,
    id: 'electroZincCoilListing',
    listingId: 'electroZincCoilListingBuyerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: electroZincCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    canCheckout: true,
};

export const coatedCoilListingBuyerModelMock: ApiModel<ListingBuyerModel> = {
    ...listingBuyerModelFoundation,
    id: 'coatedCoilListing',
    listingId: 'coatedCoilListingBuyerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: coatedCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    canCheckout: true,
};

import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Tag.scss';

export enum TagVariant {
    NEUTRAL = 'neutral',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    WARNING = 'warning',
    ACCENT = 'accent',
    PRIME = 'prime',
    NON_PRIME = 'non-prime',
}

export type TagProps = {
    label?: string;
    icon?: IconIdentifier;
    variant: TagVariant;
    isVertical?: boolean;
};

export const Tag = (props: TagProps) => {
    const { icon, label, variant, isVertical } = props;

    const tagClassMap = {
        tag: true,
        'tag--icon': icon,
        'tag--vertical': isVertical,
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="tag__label">{label}</span>;
    };

    return (
        <div className={`${buildClassStringFromClassMap(tagClassMap)} ${classNameFromEnumValue(variant, 'tag')}`}>
            {renderIcon()}
            {renderLabel()}
        </div>
    );
};

import { useMutation } from '@tanstack/react-query';
import axios from '../axios';

interface SaveListingViewsRequest {
    listingIds: Array<string>;
}

const saveListingViews = async (options: SaveListingViewsRequest) => {
    await axios.post('/api/universal/listings/views', { ...options });
};

export const useSaveListingViews = () =>
    useMutation({
        mutationKey: ['SaveListingViews'],
        mutationFn: (request: SaveListingViewsRequest) => saveListingViews(request),
    });

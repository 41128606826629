import { Grade, Specification, Finish } from '../../../MaterialModel.enums';

export const mildSteelHotRolledGradeCluster1 = [
    Grade.C35,
    Grade.C50E,
    Grade.C67S,
    Grade.DD11,
    Grade.DD12,
    Grade.DD13,
    Grade.DD14,
] as const;

export const mildSteelHotRolledGradeCluster2 = [Grade.S235, Grade.S275, Grade.S355] as const;

export const mildSteelHotRolledGradeCluster3 = [
    Grade.S300,
    Grade.S315,
    Grade.S420,
    Grade.S460,
    Grade.S500,
    Grade.S550,
    Grade.S600,
    Grade.S650,
    Grade.S700,
] as const;

export const mildSteelHotRolledGradeCluster2Specification = [
    Specification.JR,
    Specification.J0,
    Specification.J0W,
    Specification.J0WP,
    Specification.J2,
    Specification.J2_PLUS_N,
    Specification.J2G3,
    Specification.J2G4,
    Specification.J2W,
    Specification.J4,
    Specification.JRC,
    Specification.E295,
    Specification.E335,
    Specification.E360,
    Specification.N,
    Specification.NL,
    Specification.MC,
    Specification.ML,
] as const;

export const mildSteelHotRolledGradeCluster3Specification = [Specification.MC] as const;

export const mildSteelHotRolledFinish = [Finish.DRY, Finish.PICKLED_OILED] as const;

export type MildSteelHotRolledFinish = typeof mildSteelHotRolledFinish[number];

type MildSteelHotRolledGradeAndSpecification =
    | { grade: typeof mildSteelHotRolledGradeCluster1[number] }
    | {
          grade: typeof mildSteelHotRolledGradeCluster2[number];
          specification: typeof mildSteelHotRolledGradeCluster2Specification[number];
      }
    | {
          grade: typeof mildSteelHotRolledGradeCluster3[number];
          specification: typeof mildSteelHotRolledGradeCluster3Specification[number];
      };

export type MildSteelHotRolled = {
    finish: MildSteelHotRolledFinish;
} & MildSteelHotRolledGradeAndSpecification;

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RFQ_DETAILS_CACHE_KEY } from '@steelbuy/data-access';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { RFQDetails } from '../../components/my-rfqs/RFQDetails';
import { RoutePath } from '../../router/Routes';

type MyRFQsDetailsViewProps = {
    goBackPath: RoutePath;
};

export const MyRFQsDetailsView = ({ goBackPath }: MyRFQsDetailsViewProps) => {
    const { rfqId = null } = useParams();
    const queryClient = useQueryClient();

    useEffect(
        () => () => {
            queryClient.removeQueries({ queryKey: [RFQ_DETAILS_CACHE_KEY] });
        },
        []
    );

    return (
        <SuccessErrorNotificationContextProvider stayOpen>
            <RFQDetails rfqId={rfqId ?? ''} goBackPath={goBackPath} />
        </SuccessErrorNotificationContextProvider>
    );
};

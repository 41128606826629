import { useMutation } from '@tanstack/react-query';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import axios from '../../axios';

const deletePackageDraft = async (draftId: ModelPrimaryKey) => {
    const { data } = await axios.delete<ModelPrimaryKey>(`/api/seller/draft-packages/${draftId}`);
    return data;
};

export const useDeletePackageDraft = () =>
    useMutation({
        mutationFn: (draftId: ModelPrimaryKey) => deletePackageDraft(draftId),
    });

import { useAuth } from '@steelbuy/auth';

import { OrderBuyerModel } from '@steelbuy/domain-model';

import { OrderBuyerCreateEntityData } from './OrderBuyerCreateEntityData';
import {
    OrderBuyerCreateEntityStore,
    orderBuyerCreateEntityStoreAccessors,
    orderBuyerCreateEntityStoreName,
} from './OrderBuyerCreateEntitySlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { createCreateEntityDataProviderValue } from '../../util/provider/CreateEntityDataProviderValue';
import { CreateEntityDataProviderProps } from '../../util/provider/DataProviderProps';
import { getBaseUrl } from '../../util/urlUtils';

export const OrderBuyerCreateEntityDataProvider = ({ apiMock = false, children }: CreateEntityDataProviderProps) => {
    const authConsumer = useAuth();

    const dataProviderValue = createCreateEntityDataProviderValue<OrderBuyerModel, OrderBuyerCreateEntityStore>(
        orderBuyerCreateEntityStoreName,
        orderBuyerCreateEntityStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    return (
        <OrderBuyerCreateEntityData.Provider value={dataProviderValue}>{children}</OrderBuyerCreateEntityData.Provider>
    );
};

import { ListingSellerDraftCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingSellerDraftModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const listingSellerDraftEntityStoreName = 'listing-seller-draft-entity';
export type ListingSellerDraftEntityStore = {
    [listingSellerDraftEntityStoreName]: EntityStore<ListingSellerDraftModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingSellerDraftCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<
    ListingSellerDraftModel,
    ListingSellerDraftEntityStore,
    ListingSellerDraftCrudApiClient
>(listingSellerDraftEntityStoreName, createApiClient);
const mutateEntity = createMutateThunk<ListingSellerDraftModel, ListingSellerDraftCrudApiClient>(
    listingSellerDraftEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<ListingSellerDraftModel, ListingSellerDraftCrudApiClient>(
    listingSellerDraftEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<ListingSellerDraftModel, ListingSellerDraftCrudApiClient>(
    listingSellerDraftEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<ListingSellerDraftModel, ListingSellerDraftCrudApiClient>(
    listingSellerDraftEntityStoreName,
    createApiClient
);

// Slice definition
export const listingSellerDraftEntitySlice = createCrudEntitySlice<ListingSellerDraftModel>(
    listingSellerDraftEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = listingSellerDraftEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<ListingSellerDraftModel, ListingSellerDraftEntityStore>(
    listingSellerDraftEntityStoreName
);
const selectCreated = createSelectCreated<ListingSellerDraftModel, ListingSellerDraftEntityStore>(
    listingSellerDraftEntityStoreName
);
const selectServiceEntity = createSelectServiceEntity<ListingSellerDraftModel, ListingSellerDraftEntityStore>(
    listingSellerDraftEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const listingSellerDraftEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<ListingSellerDraftModel, ListingSellerDraftEntityStore>;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const MyCartEmptyState = () => {
    const { t } = useTranslation(['translation']);
    const navigate = useNavigate();
    return (
        <EmptyState
            caption={t('application.myCart.noItemCaption')}
            description={t('application.myCart.noResultsDescription')}
            imgSrc="/assets/lib-ui-primitive/images/EmptyStateData.svg"
            button={{
                label: t('application.myCart.noItemCallToAction'),
                onClick: () => {
                    navigate(RoutePath.SEARCH_LISTINGS);
                },
            }}
        />
    );
};

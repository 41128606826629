import { Outlet } from 'react-router';
import { CheckoutContextProvider } from '../../components/checkout/CheckoutContext';
import { MyOffersContextProvider } from '../../components/my-offers/MyOffersContext';

export const MyOffersView = () => (
    <MyOffersContextProvider>
        <CheckoutContextProvider>
            <Outlet />
        </CheckoutContextProvider>
    </MyOffersContextProvider>
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterCriteria, PathVariables, PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AnonymousViewModel } from '@steelbuy/domain-model-types';
import { AnyErrorObject } from '@steelbuy/error';
import { Nullable } from '@steelbuy/types';

import { SearchStore } from '../store/crud/SearchStore';
import { SearchStoreAccessors } from '../store/crud/SearchStoreAccessor';
import { FetchStatus } from '../store/FetchStatus';
import { ModelFilter } from '../store/ModelFilter';
import { ModelSort } from '../store/ModelSort';

export type SearchDataProviderValue<Model extends AnonymousViewModel> = {
    fetch: (
        sortCriteria?: SortCriteria<Model> | PreparedSortCriteria,
        filterCriteria?: FilterCriteria<Model>,
        pathVariables?: PathVariables
    ) => void;
    fetchNext: () => void;
    query: (sort?: ModelSort<Model>, filter?: ModelFilter<Model>) => ReadonlyArray<Model>;
    idle: () => boolean;
    pending: () => boolean;
    hasNextPage: () => boolean;
    isPaginated: () => boolean;
    queryCurrentPage: () => Nullable<number>;
    queryMaxPages: () => Nullable<number>;
    queryTotalItems: () => Nullable<number>;
    queryPathVariables: () => Nullable<PathVariables>;
    isSorted: () => boolean;
    querySortCriteria: () => Nullable<SortCriteria<Model> | PreparedSortCriteria>;
    isFiltered: () => boolean;
    queryFilterCriteria: () => Nullable<FilterCriteria<Model>>;
    queryFetchStatus: () => FetchStatus;
    queryFetchError: () => Nullable<AnyErrorObject>;
    resolveFetchStatus: () => void;
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void;
};

// noinspection JSUnusedLocalSymbols
export const defaultSearchDataValue: SearchDataProviderValue<any> = {
    idle: (): boolean => true,
    pending: (): boolean => false,
    hasNextPage: (): boolean => false,
    isPaginated: (): boolean => false,
    queryCurrentPage: (): Nullable<number> => null,
    queryMaxPages: (): Nullable<number> => null,
    queryTotalItems: (): Nullable<number> => null,
    queryPathVariables: (): Nullable<PathVariables> => null,
    isSorted: (): boolean => false,
    querySortCriteria: (): Nullable<SortCriteria<any> | PreparedSortCriteria> => null,
    isFiltered: (): boolean => false,
    queryFilterCriteria: (): Nullable<FilterCriteria<any>> => null,
    queryFetchStatus: (): FetchStatus => FetchStatus.IDLE,
    queryFetchError: (): Nullable<AnyErrorObject> => null,
    fetch: (
        sortCriteria?: SortCriteria<any> | PreparedSortCriteria,
        filterCriteria?: FilterCriteria<any>,
        pathVariables?: PathVariables
    ): void => {
        throw new Error('Data provider missing');
    },
    fetchNext: (): void => {
        throw new Error('Data provider missing');
    },
    query: (sort?: ModelSort<never>, filter?: ModelFilter<any>): ReadonlyArray<any> => {
        throw new Error('Data provider missing');
    },
    resolveFetchStatus: (): void => {
        throw new Error('Data provider missing');
    },
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
        throw new Error('Data provider missing');
    },
};

export const createSearchDataProviderValue = <
    Model extends AnonymousViewModel,
    RootStore extends Record<string, SearchStore<Model>>
>(
    storeKey: string,
    storeAccessors: SearchStoreAccessors<Model, RootStore>,
    apiBaseUrl: string,
    jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>
): SearchDataProviderValue<Model> => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    const dataProviderValue = {
        idle: (): boolean => dataProviderValue.queryFetchStatus() === FetchStatus.IDLE,
        pending: (): boolean => {
            const fetchStatus = dataProviderValue.queryFetchStatus();
            return fetchStatus === FetchStatus.PENDING || fetchStatus === FetchStatus.PAGING_PENDING;
        },
        hasNextPage: (): boolean => {
            const currentPage = dataProviderValue.queryCurrentPage();
            const maxPages = dataProviderValue.queryMaxPages();
            return (currentPage ?? 1) < (maxPages ?? 1);
        },
        isPaginated: (): boolean => {
            const maxPages = dataProviderValue.queryMaxPages();
            return (maxPages ?? 1) > 1;
        },
        queryCurrentPage: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].currentPage),
        queryMaxPages: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].maxPages),
        queryTotalItems: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].totalItems),
        queryPathVariables: (): Nullable<PathVariables> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<PathVariables>>((state) => state[storeKey].pathVariables),
        isSorted: (): boolean => {
            const sortCriteria = dataProviderValue.querySortCriteria();
            return sortCriteria !== null;
        },
        querySortCriteria: (): Nullable<SortCriteria<Model> | PreparedSortCriteria> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<SortCriteria<Model>>>((state) => state[storeKey].sortCriteria ?? null),
        isFiltered: (): boolean => {
            const filterCriteria = dataProviderValue.queryFilterCriteria();
            return filterCriteria !== null;
        },
        queryFilterCriteria: (): Nullable<FilterCriteria<Model>> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<FilterCriteria<Model>>>((state) => state[storeKey].filterCriteria ?? null),
        queryFetchStatus: (): FetchStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, FetchStatus>((state) => state[storeKey].fetchStatus),
        queryFetchError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>((state) => state[storeKey].lastFetchError),
        fetch: (
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria,
            filterCriteria?: FilterCriteria<Model>,
            pathVariables?: PathVariables
        ): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetchAll({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    sortCriteria,
                    filterCriteria,
                    pathVariables,
                })
            );
        },
        fetchNext: (): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetchNext({ apiBaseUrl, jsonWebTokenLoader })
            );
        },
        query: (sort?: ModelSort<Model>, filter?: ModelFilter<Model>): ReadonlyArray<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyArray<Model>>(storeAccessors.selectCollection(filter, sort)),
        resolveFetchStatus: (): void => {
            dispatch(storeAccessors.resetFetchStatus());
        },
        useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
            const fetchStatus = dataProviderValue.queryFetchStatus();
            useEffect(() => {
                if (status.includes(fetchStatus)) {
                    effect();
                }
            }, [fetchStatus]);
        },
    };

    return dataProviderValue;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ApiDateRange,
    ApiDownloadFile,
    ApiModel,
    ApiMonetaryAmount,
    ApiTimestamp,
    ApiUrl,
    ArrayOfDateRangeFieldNames,
    ArrayOfDownloadFileFieldNames,
    ArrayOfMonetaryAmountFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfUploadFileFieldNames,
    ArrayOfUrlFieldNames,
    createApiDateRangeFromDateRange,
    createApiMonetaryAmountFromMonetaryAmount,
    createApiTimestampFromTimestamp,
    createApiUploadFileFromUploadFile,
    createApiUrlFromUrl,
    createDateRangeFromApiDateRange,
    createDownloadFileFromApiDownloadFile,
    createMonetaryAmountFromApiMonetaryAmount,
    createTimestampFromApiTimestamp,
    createUrlFromApiUrl,
    DateRange,
    DateRangeFieldNames,
    DownloadFileFieldNames,
    isApiDownloadFile,
    MonetaryAmount,
    MonetaryAmountFieldNames,
    Mutable,
    Mutation,
    Timestamp,
    TimestampFieldNames,
    UploadFile,
    UploadFileFieldNames,
    Url,
    UrlFieldNames,
    ViewModelValue,
} from '@steelbuy/domain-model-types';
import { ApiError } from '@steelbuy/error';
import { Nullable } from '@steelbuy/types';

export type FieldConverterMap<T> = Partial<Record<keyof T, ModelConverterInterface<any>>>;

export interface ModelConverterInterface<Model> {
    toApiModel(viewModel: Model | Mutable<Model> | Mutation<Model>): ApiModel<Model>;

    toViewModel(apiModel: ApiModel<Model>): Model;

    getTimestampFields(): Array<TimestampFieldNames<Model> | ArrayOfTimestampFieldNames<Model>>;

    getDateRangeFields(): Array<DateRangeFieldNames<Model> | ArrayOfDateRangeFieldNames<Model>>;

    getUrlFields(): Array<UrlFieldNames<Model> | ArrayOfUrlFieldNames<Model>>;

    getUploadFileFields(): Array<UploadFileFieldNames<Model> | ArrayOfUploadFileFieldNames<Model>>;

    getDownloadFileFields(): Array<DownloadFileFieldNames<Model> | ArrayOfDownloadFileFieldNames<Model>>;

    getFieldConverterMapByModel(model: Model | ApiModel<Model>): FieldConverterMap<Model>;
}

export abstract class ModelConverter<Model> implements ModelConverterInterface<Model> {
    public getTimestampFields(): Array<TimestampFieldNames<Model> | ArrayOfTimestampFieldNames<Model>> {
        return [];
    }

    public getDateRangeFields(): Array<DateRangeFieldNames<Model> | ArrayOfDateRangeFieldNames<Model>> {
        return [];
    }

    public getUrlFields(): Array<UrlFieldNames<Model> | ArrayOfUrlFieldNames<Model>> {
        return [];
    }

    public getUploadFileFields(): Array<UploadFileFieldNames<Model> | ArrayOfUploadFileFieldNames<Model>> {
        return [];
    }

    public getDownloadFileFields(): Array<DownloadFileFieldNames<Model> | ArrayOfDownloadFileFieldNames<Model>> {
        return [];
    }

    public getMonetaryAmountFields(): Array<MonetaryAmountFieldNames<Model> | ArrayOfMonetaryAmountFieldNames<Model>> {
        return [];
    }

    public getCustomFormatter() {
        return {} as Partial<
            Record<keyof any, { toViewFormatter: (fieldValue: any) => any; toApiFormatter: (fieldValue: any) => any }>
        >;
    }

    public getFieldConverterMapByModel(_model: Model | ApiModel<Model>): FieldConverterMap<Model> {
        return {} as FieldConverterMap<any>;
    }

    private getFieldConverterByModel(
        fieldName: keyof Model,
        model: Model | ApiModel<Model>
    ): Nullable<ModelConverterInterface<any>> {
        return this.getFieldConverterMapByModel(model)?.[fieldName] ?? null;
    }

    public toViewModel(apiModel: ApiModel<Model>): Model {
        const model = { ...apiModel } as Record<string, ViewModelValue>;

        for (const timestampField of this.getTimestampFields()) {
            const timestampValue = (apiModel?.[timestampField] as ApiTimestamp | Array<ApiTimestamp>) ?? null;
            if (Array.isArray(timestampValue)) {
                model[timestampField as string] = timestampValue.map((apiTimestamp) =>
                    createTimestampFromApiTimestamp(apiTimestamp).getOrThrow(
                        new ApiError(
                            `Expected array of unix timestamp for field ${timestampField.toString()}. Got ${timestampValue}`
                        )
                    )
                );
                continue;
            }
            if (timestampValue !== null) {
                model[timestampField as string] = createTimestampFromApiTimestamp(timestampValue).get();
            }
        }

        for (const dateRangeField of this.getDateRangeFields()) {
            const dateRangeValue = (apiModel?.[dateRangeField] as ApiDateRange | Array<ApiDateRange>) ?? null;
            if (Array.isArray(dateRangeValue)) {
                model[dateRangeField as string] = dateRangeValue.map((apiTimestamp) =>
                    createDateRangeFromApiDateRange(apiTimestamp)
                );
                continue;
            }
            if (dateRangeValue !== null) {
                model[dateRangeField as string] = createDateRangeFromApiDateRange(dateRangeValue);
            }
        }

        for (const urlField of this.getUrlFields()) {
            const urlValue = (apiModel?.[urlField] as ApiUrl | Array<ApiUrl>) ?? null;
            if (Array.isArray(urlValue)) {
                model[urlField as string] = urlValue.map((apiUrl) => createUrlFromApiUrl(apiUrl).get());
                continue;
            }
            if (urlValue !== null) {
                model[urlField as string] = createUrlFromApiUrl(urlValue).get();
            }
        }

        for (const downloadFileField of this.getDownloadFileFields()) {
            const fileValue = (apiModel?.[downloadFileField] as ApiDownloadFile | Array<ApiDownloadFile>) ?? null;
            if (Array.isArray(fileValue)) {
                if (!isApiDownloadFile(fileValue[0])) {
                    continue;
                }
                model[downloadFileField as string] = fileValue.map((apiFile) =>
                    createDownloadFileFromApiDownloadFile(apiFile).get()
                );
                continue;
            }
            if (fileValue !== null) {
                if (!isApiDownloadFile(fileValue)) {
                    continue;
                }
                model[downloadFileField as string] = createDownloadFileFromApiDownloadFile(fileValue).get();
            }
        }

        for (const monetaryAmountField of this.getMonetaryAmountFields()) {
            const monetaryAmountValue =
                (apiModel?.[monetaryAmountField] as ApiMonetaryAmount | Array<ApiMonetaryAmount>) ?? null;
            if (Array.isArray(monetaryAmountValue)) {
                model[monetaryAmountField as string] = monetaryAmountValue.map((monetaryAmount) =>
                    createMonetaryAmountFromApiMonetaryAmount(monetaryAmount).get()
                );
                continue;
            }
            if (monetaryAmountValue !== null) {
                model[monetaryAmountField as string] =
                    createMonetaryAmountFromApiMonetaryAmount(monetaryAmountValue).get();
            }
        }

        for (const customFormatterField of this.getCustomFormatterMapKeys()) {
            const modelValue = (apiModel?.[customFormatterField] as Record<string, any>) ?? null;
            if (Array.isArray(modelValue)) {
                model[customFormatterField as string] =
                    this.getCustomFormatter()?.[customFormatterField]?.toViewFormatter(modelValue) ?? null;
                continue;
            }
            if (modelValue !== null) {
                model[customFormatterField as string] =
                    this.getCustomFormatter()?.[customFormatterField]?.toViewFormatter(modelValue) ?? null;
            }
        }

        for (const convertableField of this.getFieldConverterMapKeys(apiModel)) {
            const modelValue = (apiModel?.[convertableField] as Record<string, any>) ?? null;
            if (Array.isArray(modelValue)) {
                model[convertableField as string] = modelValue.map((value) => {
                    const convertedModel =
                        this.getFieldConverterByModel(convertableField, apiModel)?.toViewModel(value) ?? null;
                    if (convertedModel === null) {
                        throw new ApiError(
                            `Expected array of convertables for field ${convertableField.toString()}. Got ${modelValue}`
                        );
                    }
                    return convertedModel;
                });
                continue;
            }
            if (modelValue !== null) {
                model[convertableField as string] =
                    this.getFieldConverterByModel(convertableField, apiModel)?.toViewModel(modelValue) ?? null;
            }
        }

        return model as unknown as Model;
    }

    public toApiModel(viewModel: Mutable<Model> | Mutation<Model> | Model): ApiModel<Model> {
        const apiModel = { ...viewModel } as Record<string, ViewModelValue>;

        for (const modelField in apiModel) {
            if (this.isTimestampField(modelField as keyof Model)) {
                const timestampValue = (apiModel?.[modelField] as Timestamp | Array<Timestamp>) ?? null;
                if (Array.isArray(timestampValue)) {
                    apiModel[modelField] = timestampValue.map((timestamp) =>
                        createApiTimestampFromTimestamp(timestamp).getOrThrow(
                            new ApiError(`Expected array of timestamp for field ${modelField}. Got ${timestampValue}`)
                        )
                    );
                    continue;
                }
                if (timestampValue !== null) {
                    apiModel[modelField] = createApiTimestampFromTimestamp(timestampValue).get();
                }
                continue;
            }

            if (this.isDateRangeField(modelField as keyof Model)) {
                const dateRangeValue = (apiModel?.[modelField] as DateRange | Array<DateRange>) ?? null;
                if (Array.isArray(dateRangeValue)) {
                    apiModel[modelField] = dateRangeValue.map((dateRange) =>
                        createApiDateRangeFromDateRange(dateRange).getOrThrow(
                            new ApiError(`Expected array of date range for field ${modelField}. Got ${dateRangeValue}`)
                        )
                    );
                    continue;
                }
                if (dateRangeValue !== null) {
                    apiModel[modelField] = createApiDateRangeFromDateRange(dateRangeValue).get();
                }
                continue;
            }

            if (this.isUrlField(modelField as keyof Model)) {
                const urlValue = (apiModel?.[modelField] as Url | Array<Url>) ?? null;
                if (Array.isArray(urlValue)) {
                    apiModel[modelField] = urlValue.map((url) =>
                        createApiUrlFromUrl(url).getOrThrow(
                            new ApiError(`Expected array of URL for field ${modelField}. Got ${urlValue}`)
                        )
                    );
                    continue;
                }
                if (urlValue !== null) {
                    apiModel[modelField] = createApiUrlFromUrl(urlValue).get();
                }
                continue;
            }

            if (this.isUploadFileField(modelField as keyof Model)) {
                const uploadFileField = (apiModel?.[modelField] as UploadFile | Array<UploadFile>) ?? null;
                if (Array.isArray(uploadFileField)) {
                    apiModel[modelField] = uploadFileField.map((file) =>
                        createApiUploadFileFromUploadFile(file).getOrThrow(
                            new ApiError(`Expected array of file for field ${modelField}. Got ${uploadFileField}`)
                        )
                    );
                    continue;
                }
                if (uploadFileField !== null) {
                    apiModel[modelField] = createApiUploadFileFromUploadFile(uploadFileField).get();
                }
                continue;
            }

            if (this.isMonetaryAmountField(modelField as keyof Model)) {
                const monetaryAmountValue = (apiModel?.[modelField] as MonetaryAmount | Array<MonetaryAmount>) ?? null;
                if (Array.isArray(monetaryAmountValue)) {
                    apiModel[modelField] = monetaryAmountValue.map((monetaryAmount) =>
                        createApiMonetaryAmountFromMonetaryAmount(monetaryAmount).getOrThrow(
                            new ApiError(
                                `Expected array of MonetaryAmount for field ${modelField}. Got ${monetaryAmountValue}`
                            )
                        )
                    );
                    continue;
                }
                if (monetaryAmountValue !== null) {
                    apiModel[modelField] = createApiMonetaryAmountFromMonetaryAmount(monetaryAmountValue).get();
                }
                continue;
            }

            if (this.isCustomFormattedField(modelField as keyof Model)) {
                const modelViewFormatValue = (apiModel?.[modelField] as Record<string, any>) ?? null;
                if (Array.isArray(modelViewFormatValue)) {
                    apiModel[modelField] = modelViewFormatValue.map((modelApiFormatValue) => {
                        const apiFormattedValue =
                            this.getCustomFormatter()?.[modelField]?.toApiFormatter(modelApiFormatValue) ?? null;
                        if (apiFormattedValue === null) {
                            throw new ApiError(
                                `Failed to custom format field ${modelField} for value ${modelApiFormatValue}`
                            );
                        }
                        return apiFormattedValue;
                    });
                    continue;
                }
                if (modelViewFormatValue !== null) {
                    apiModel[modelField as string] =
                        this.getCustomFormatter()?.[modelField]?.toApiFormatter(modelViewFormatValue) ?? null;
                }
            }

            if (this.isConvertableField(modelField as keyof Model, viewModel as Model)) {
                const modelValue = (apiModel?.[modelField] as Record<string, any>) ?? null;
                if (Array.isArray(modelValue)) {
                    apiModel[modelField] = modelValue.map((model) => {
                        const convertedModel =
                            this.getFieldConverterByModel(modelField as keyof Model, viewModel as Model)?.toApiModel(
                                model
                            ) ?? null;
                        if (convertedModel === null) {
                            throw new ApiError(
                                `Expected array of convertables for field ${modelField}. Got ${modelValue}`
                            );
                        }
                        return convertedModel;
                    });
                    continue;
                }
                if (modelValue !== null) {
                    apiModel[modelField] =
                        this.getFieldConverterByModel(modelField as keyof Model, viewModel as Model)?.toApiModel(
                            modelValue
                        ) ?? null;
                }
            }
        }

        return apiModel as ApiModel<Model>;
    }

    private isTimestampField(fieldName: keyof Model): boolean {
        return this.getTimestampFields().includes(fieldName as TimestampFieldNames<Model>);
    }

    private isDateRangeField(fieldName: keyof Model): boolean {
        return this.getDateRangeFields().includes(fieldName as DateRangeFieldNames<Model>);
    }

    private isUrlField(fieldName: keyof Model): boolean {
        return this.getUrlFields().includes(fieldName as UrlFieldNames<Model>);
    }

    private isUploadFileField(fieldName: keyof Model): boolean {
        return this.getUploadFileFields().includes(fieldName as UploadFileFieldNames<Model>);
    }

    private isDownloadFileField(fieldName: keyof Model): boolean {
        return this.getDownloadFileFields().includes(fieldName as DownloadFileFieldNames<Model>);
    }

    private isMonetaryAmountField(fieldName: keyof Model): boolean {
        return this.getMonetaryAmountFields().includes(fieldName as MonetaryAmountFieldNames<Model>);
    }

    private isCustomFormattedField(fieldName: keyof Model): boolean {
        return this.getCustomFormatterMapKeys().includes(fieldName);
    }

    private getCustomFormatterMapKeys(): Array<keyof Model> {
        return Object.keys(this.getCustomFormatter()) as Array<keyof Model>;
    }

    private isConvertableField(fieldName: keyof Model, model: Model | ApiModel<Model>): boolean {
        return this.getFieldConverterMapKeys(model).includes(fieldName);
    }

    private getFieldConverterMapKeys(model: Model | ApiModel<Model>): Array<keyof Model> {
        return Object.keys(this.getFieldConverterMapByModel(model)) as Array<keyof Model>;
    }
}

import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import './InputRadioButton.scss';

export type InputRadioButtonProps = {
    value?: string;
    children?: ReactNode;
    onChange?: (value: boolean) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputRadioButton = (props: InputRadioButtonProps) => {
    const { value, disabled, children, onChange, ...rest } = props;

    const inputRadioButtonClassMap = {
        'input-radio-button': true,
        'input-radio-button--has-children': children,
        'input-radio-button--disabled': disabled,
    };

    const renderValue = () => {
        if (value === undefined) {
            return null;
        }
        return <span className="input-radio-button__value">{value}</span>;
    };

    const renderChildren = () => {
        if (children === undefined) {
            return null;
        }
        return <article className="input-radio-button__children">{children}</article>;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onChange !== undefined) {
            onChange(event.target.checked);
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputRadioButtonClassMap)}>
            <span className="input-radio-button__input">
                <input disabled={disabled} type="radio" onChange={handleChange} {...rest} />
            </span>
            <article className="input-radio-button__content">
                {renderValue()}
                {renderChildren()}
            </article>
        </label>
    );
};

import { useTranslation } from 'react-i18next';
import { EmptyState } from '@steelbuy/ui-primitive';

type SearchResultsEmptyStateProps = {
    handleBackToSearch: () => void;
};

export const SearchResultsEmptyState = ({ handleBackToSearch }: SearchResultsEmptyStateProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <div className="listing-search-results__empty">
            <EmptyState
                caption={t('uiDomain:commonList.noListCaption')}
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                description={t('application.listingSearchResults.noResultsText')}
                button={{
                    label: t('application.listingSearchResults.noResultsButtonLabel'),
                    onClick: handleBackToSearch,
                }}
            />
        </div>
    );
};

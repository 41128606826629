import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserCollectionData } from '@steelbuy/data-provider';
import { UserModel } from '@steelbuy/domain-model';
import { Mutable } from '@steelbuy/domain-model-types';
import { ROUTE_ACCOUNT_USER_MANAGEMENT } from '../account/Account.enum';

import { PageHeader } from '../page-header/PageHeader';
import { UserForm } from '../user-form/UserForm';

export const AddUser = () => {
    const userCollectionData = useUserCollectionData();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const addNewUser = (model: Mutable<UserModel>) => {
        userCollectionData.create(model);
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.addUser.headerTitle')}
                previousPageTitle={t('application.addUser.backLinkText')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_USER_MANAGEMENT)}
            />

            <UserForm onPrimary={addNewUser} saveLabelText={t('application.addUser.addUserButtonLabel')} />
        </>
    );
};

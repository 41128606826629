import { PropsWithChildren, ReactElement, createContext, useContext, useMemo, useReducer } from 'react';
import { CustomError } from '@steelbuy/error';
import { Notification, NotificationProps } from './Notification';
import { NotificationLevel } from './Notification.enums';

type State = {
    message: string;
    showNotification: boolean;
    level: NotificationLevel;
};

type Action = { type: 'SHOW_SUCCESS' | 'SHOW_ERROR'; message: string } | { type: 'HIDE_NOTIFICATION' };

const defaultState: State = {
    message: '',
    level: NotificationLevel.SUCCESS,
    showNotification: false,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SHOW_SUCCESS':
        case 'SHOW_ERROR':
            return {
                ...state,
                message: action.message,
                showNotification: true,
                level: action.type === 'SHOW_SUCCESS' ? NotificationLevel.SUCCESS : NotificationLevel.ERROR,
            };
        case 'HIDE_NOTIFICATION':
            return {
                ...state,
                message: '',
                showNotification: false,
            };
        default:
            return state;
    }
};

const SuccessErrorNotificationContext = createContext<{
    Notification: ReactElement | null;
    showSuccessNotification: (successMessage: string) => void;
    showErrorNotification: (errorMessage: string) => void;
    hideNotification: () => void;
}>({
    Notification: null,
    showSuccessNotification: () => {},
    showErrorNotification: () => {},
    hideNotification: () => {},
});

export const SuccessErrorNotificationContextProvider = (
    props: PropsWithChildren & Omit<NotificationProps, 'level' | 'message'> = {}
) => {
    const { children, ...notificationProps } = props;
    const [{ showNotification, level, message }, dispatch] = useReducer(reducer, defaultState);

    const value = useMemo(
        () => ({
            Notification: showNotification ? (
                <Notification {...notificationProps} level={level} message={message} />
            ) : null,
            showSuccessNotification: (successMessage: string) =>
                dispatch({ type: 'SHOW_SUCCESS', message: successMessage }),
            showErrorNotification: (errorMessage: string) => dispatch({ type: 'SHOW_ERROR', message: errorMessage }),
            hideNotification: () => dispatch({ type: 'HIDE_NOTIFICATION' }),
        }),
        [showNotification, level, message]
    );

    return (
        <SuccessErrorNotificationContext.Provider value={value}>{children}</SuccessErrorNotificationContext.Provider>
    );
};

export const useSuccessErrorNotification = () => {
    const context = useContext(SuccessErrorNotificationContext);

    if (!context) {
        throw new CustomError('No provider present for SuccessErrorNotificationContext!');
    }

    return context;
};

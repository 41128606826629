import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomLink, Icon, IconIdentifier } from '@steelbuy/ui-primitive';

import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { PageHeader } from '../page-header/PageHeader';

import './OfferSuccess.scss';

export const OfferSuccess = () => {
    const { t } = useTranslation('translation');
    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    if (!state?.referenceId) {
        if (pathname.includes('/search/')) {
            navigate(RoutePath.SEARCH_LISTINGS);
        } else if (pathname.includes('/cart/')) {
            navigate(RoutePath.MY_CART);
        } else {
            navigate(RoutePath.MY_ALERTS);
        }
        return null;
    }

    return (
        <>
            <PageHeader pageTitle={t('application.offerSuccess.title')} />
            <TableLayout>
                <div className="offer-success">
                    <section className="offer-success__illustration-success">
                        <img alt="Success" src="/assets/lib-ui-primitive/images/Checkout-Success.svg" />
                    </section>

                    <div className="offer-success__message">
                        <div className="offer-success__illustration-success__header">
                            {t('application.offerSuccess.successText')}
                        </div>
                        <div className="offer-success__illustration-success__help-text">
                            {t('application.offerSuccess.referenceNumberText')}{' '}
                            <span className="offer-success__illustration-success__order-number">
                                {state?.referenceId}
                            </span>
                        </div>

                        <section className="offer-success__illustration-success__hint">
                            <span className="offer-success__illustration-success__hint__wrapper">
                                <Icon name={IconIdentifier.OK} />
                                <div className="offer-success__illustration-success__hint__wrapper__text">
                                    {t('application.offerSuccess.responseHint')}
                                </div>
                            </span>
                            <span className="offer-success__illustration-success__hint__wrapper">
                                <Icon name={IconIdentifier.OK} />
                                <div className="offer-success__illustration-success__hint__wrapper__text">
                                    <Trans
                                        i18nKey="application.offerSuccess.manageOffers"
                                        components={{
                                            link1: <CustomLink path={RoutePath.MY_OFFERS} isInlineLink />,
                                        }}
                                        t={t}
                                    />
                                </div>
                            </span>
                        </section>
                    </div>
                </div>
            </TableLayout>
        </>
    );
};

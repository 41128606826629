import { useMutation } from '@tanstack/react-query';
import axios from '../axios';
import { OrderModel } from '../utils/cart/cartUtil';

interface PurchaseEntryModel {
    entryId: string;
    productId: string;
    negotiationId?: string;
    haulierRequired: boolean;
}

export type PurchaseOrderModel = {
    cartId: string;
    deliveryAddressId: string;
    invoiceAddressId: string;
    buyerOrderNumber: string | undefined;
    cartEntries: PurchaseEntryModel[];
};

const purchaseOrder = async (order: PurchaseOrderModel) => {
    const { data } = await axios.post<OrderModel>('/api/buyer/cart/purchase-orders', order);
    return data;
};

export const useCreatePurchaseOrder = () =>
    useMutation({
        mutationFn: (order: PurchaseOrderModel) => purchaseOrder(order),
    });

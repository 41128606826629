/* eslint-disable @typescript-eslint/no-unused-vars */
import { TFunction } from 'i18next';
import { Dimensions, MaterialProperties, MaterialWithDimensions, RFQField } from './MaterialProperties';
import { ListingMaterialAge, ListingMaterialOrigin } from '../ListingBuyerModel.enums';
import {
    Coating,
    CoatingThicknessUnit,
    ProductType,
    Definition,
    Product,
    Shape,
    Grade,
    Surface,
    Finish,
    Specification,
    CoatingThicknessType,
    Temper,
    Tolerance,
    CoatingType,
    CoatingCoverage,
    TradeUnit,
    CoatingColourType,
    MillFinish,
    Polish,
    TradeUnitCalculation,
    PricingUnit,
} from '../MaterialModel.enums';
import { DimensionsWithRanges, SearchFields, SearchFormData } from '../SearchFormData';

export const otherFilter = (value: string) => value !== 'OTHER';

export abstract class AbstractMaterialTaxonomy {
    getPrimeOptions(material: MaterialProperties): boolean[] {
        if (material.plateType === ProductType.ROAD_PLATE) {
            return [false];
        }
        return [true, false];
    }

    getShapes(): Readonly<Shape[]> {
        return [];
    }

    protected getFilteredProducts(products: Readonly<Product[]>, filteredProducts: Product[]) {
        return products.filter((product) => !filteredProducts.includes(product));
    }

    getProducts(_material: MaterialProperties, isPackagesSupported = false): Readonly<Product[]> {
        return [];
    }

    getProductTypes(material: MaterialProperties, isPrime: boolean): Readonly<ProductType[]> {
        return [];
    }

    getDefinitions(material: MaterialProperties): Readonly<Definition[]> {
        return [];
    }

    getGrades(material: MaterialProperties): Readonly<Grade[]> {
        return [];
    }

    getSurfaces(material: MaterialProperties): Readonly<Surface[]> {
        return [];
    }

    getFinishes(material: MaterialProperties): Readonly<Finish[]> {
        return [];
    }

    getSpecifications(material: MaterialProperties): Readonly<Specification[]> {
        return [];
    }

    getCoatings(material: MaterialProperties): Readonly<Coating[]> {
        return [];
    }

    getCoatingThickness(material: MaterialProperties, excludeOther?: boolean): Readonly<CoatingThicknessType[]> {
        return [];
    }

    getTempers(material: MaterialProperties): Readonly<Temper[]> {
        return [];
    }

    getTolerances(material: MaterialProperties, isPrime: boolean): Readonly<Tolerance[]> {
        return [];
    }

    getCoatingColours(material: MaterialProperties, excludeOther?: boolean): Readonly<CoatingColourType[]> {
        return [];
    }

    getCoatingTypes(material: MaterialProperties): Readonly<CoatingType[]> {
        return [];
    }

    getCoatingCoverages(material: MaterialProperties): Readonly<CoatingCoverage[]> {
        return [];
    }

    getTradeUnit(material: MaterialProperties): Readonly<TradeUnit> {
        return TradeUnit.TON;
    }

    getTradeUnitCalculation(material: MaterialProperties): Readonly<TradeUnitCalculation> {
        return TradeUnitCalculation.BY_WEIGHT;
    }

    getPricingUnit(material: MaterialProperties): Readonly<PricingUnit> {
        return PricingUnit.TON;
    }

    getMillFinishes(material: MaterialProperties): Readonly<MillFinish[]> {
        return [];
    }

    getPolishes(material: MaterialProperties): Readonly<Polish[]> {
        return [];
    }

    getCoatingThicknessUnit(material: MaterialProperties): CoatingThicknessUnit {
        return CoatingThicknessUnit.GRAMS_PER_SQUARE_METRE;
    }

    getMaterialAges(material: MaterialProperties): ListingMaterialAge[] {
        return material.product === Product.PACKAGE
            ? []
            : Object.values(ListingMaterialAge).filter((age) => age !== ListingMaterialAge.UNSPECIFIED);
    }

    getOrigins(material: MaterialProperties): ListingMaterialOrigin[] {
        return Object.values(ListingMaterialOrigin).filter((origin) => origin !== ListingMaterialOrigin.UNSPECIFIED);
    }

    getUrgentRequestMandatoryFields(searchFormData: SearchFormData): SearchFields {
        return [];
    }

    getTheoreticalWeight(
        material: MaterialProperties,
        dimensions: Dimensions,
        numberOfItems?: number
    ): number | undefined {
        return undefined;
    }

    getMaxTheoreticalWeight(
        material: MaterialProperties,
        dimensions: DimensionsWithRanges,
        numberOfItems?: number
    ): number | undefined {
        return undefined;
    }

    protected getTheorericalWeightForDensity(
        dimensions: Dimensions,
        numberOfItems: number | undefined,
        density: number
    ): number | undefined {
        const { width, length, thickness } = dimensions || {};
        const weight =
            width && length && thickness && numberOfItems && density
                ? parseFloat((width * length * thickness * density * numberOfItems).toFixed(2))
                : undefined;
        if (weight === 0) {
            return 0.01;
        }
        return weight;
    }

    protected getMaxDimensions(dimensions: DimensionsWithRanges): Dimensions {
        const { maxWidth, maxLength, thickness } = dimensions;
        const width = maxWidth ?? dimensions.width;
        const length = maxLength ?? dimensions.length;
        return {
            width: width !== undefined ? parseFloat(width) : undefined,
            thickness: thickness !== undefined ? parseFloat(thickness) : undefined,
            length: length !== undefined ? parseFloat(length) : undefined,
        };
    }

    getMinimumTargetPrice(
        material: MaterialProperties,
        weight: number | undefined,
        numberOfItems: number | undefined
    ): number {
        return 1;
    }

    isMultiOrigin(material: MaterialProperties): boolean {
        return material.product === Product.PACKAGE;
    }

    showNumberOfItemsForIMR(material: MaterialProperties): boolean {
        const numberOfItemsProducts = [Product.SHEET, Product.PLATE, Product.TREAD];
        if (material.product && numberOfItemsProducts.includes(material.product)) {
            return true;
        }
        return false;
    }

    getRFQFields(material: MaterialProperties, t: TFunction<'domainModel', undefined>) {
        return [] as RFQField[];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected createRFQField(field: keyof MaterialWithDimensions, rules: Record<string, any>): RFQField {
        return {
            field,
            rules,
        };
    }

    getRFQGrades(material: MaterialProperties): Readonly<Grade[]>[] {
        return [];
    }
}

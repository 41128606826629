import { FormEvent, ReactNode } from 'react';

import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputCheckboxGroup.scss';
import { InputValue } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

export enum InputCheckboxGroupAlign {
    ALIGN_VERTICAL = 'ALIGN_VERTICAL',
    ALIGN_HORIZONTAL = 'ALIGN_HORIZONTAL',
}

export type InputCheckboxGroupBaseProps = {
    align: InputCheckboxGroupAlign;
    label?: string;
    helperText?: string;
    error?: string;
    children: ReactNode;
    value?: string[];
};

type InputCheckboxGroupWithRequiredValidationProps = InputCheckboxGroupBaseProps & {
    required: true;
    requiredMessage: string;
    forceValidation?: boolean;
};

type InputCheckboxGroupWithoutValidationProps = InputCheckboxGroupBaseProps & {
    required?: never;
    requiredMessage?: never;
    forceValidation?: never;
};

type InputCheckboxGroupProps = InputCheckboxGroupWithRequiredValidationProps | InputCheckboxGroupWithoutValidationProps;

export const InputCheckboxGroup = (props: InputCheckboxGroupProps) => {
    const {
        align = null,
        label,
        helperText,
        error,
        children,
        required = false,
        requiredMessage,
        forceValidation,
        value,
    } = props;

    const validators =
        required && requiredMessage
            ? [
                  {
                      validate: (val: InputValue) => Array.isArray(val) && val.length > 0,
                      message: requiredMessage,
                  },
              ]
            : undefined;

    const { validationError, setIsTouched } = useValidation<HTMLInputElement>(value, validators, forceValidation);

    const combinedError = validationError ?? error;

    const alignClass = ` input-checkbox-group__items--${classNameFromEnumValue(String(align))}`;

    const helperTextClassMap = {
        'input-checkbox-group__helper-text': true,
        'input-checkbox-group__helper-text--error': combinedError,
    };

    const renderErrorIcon = () => {
        if (combinedError === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const renderHelperText = () => {
        const text = combinedError ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const handleInvalid = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <article className="input-checkbox-group">
            <span className="input-checkbox-group__wrapper">
                {label && <span className="input-checkbox-group__wrapper__label">{label}</span>}
                {renderErrorIcon()}
            </span>
            <article
                className={`input-checkbox-group__items${alignClass}`}
                onInvalid={handleInvalid}
                onBlur={() => setIsTouched(true)}
            >
                {children}
            </article>
            {renderHelperText()}
        </article>
    );
};

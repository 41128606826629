/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { JsonWebToken } from '@steelbuy/auth';
import { ModelPrimaryKey, Mutable, Mutation, ViewModel } from '@steelbuy/domain-model-types';
import { AnyErrorObject } from '@steelbuy/error';
import { Nullable, ReadonlyOptional } from '@steelbuy/types';

import { CreateEntityStore } from './CreateEntityStore';
import { ThunkApiConfig } from './Thunks';
import { ActionStatus } from '../ActionStatus';

// Initial store
export const createInitialCreateEntityStore = <Model extends ViewModel>() =>
    ({
        model: null,
        actionStatus: ActionStatus.IDLE,
        lastActionError: null,
    } as CreateEntityStore<Model>);

// Slice creator
export const createCrudCreateEntitySlice = <Model extends ViewModel>(
    storeName: string,
    mutateCreatedEntity: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            modelMutation: Mutation<Model>;
        },
        ThunkApiConfig
    >,
    createEntity: AsyncThunk<
        Model,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            mutableModel: Mutable<Model>;
        },
        ThunkApiConfig
    >,
    deleteEntity: AsyncThunk<
        ModelPrimaryKey,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
        },
        ThunkApiConfig
    >
) =>
    createSlice({
        name: storeName,
        initialState: createInitialCreateEntityStore<Model>(),
        // Regular synchronous reducers
        reducers: {
            resetStore(store) {
                Object.assign(store, createInitialCreateEntityStore<Model>());
            },
            resetActionStatus(store) {
                store.actionStatus = ActionStatus.IDLE;
            },
        },
        // Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
        // Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
        // handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
        // https://github.com/immerjs/immer.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraReducers: (builder) => {
            builder.addCase(String(mutateCreatedEntity.pending), (state) => {
                state.actionStatus = ActionStatus.MUTATE_PENDING;
            });
            builder.addCase(
                String(mutateCreatedEntity.fulfilled),
                (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                    if (action.payload !== null) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        state.model = action.payload;
                        state.actionStatus = ActionStatus.MUTATE_SUCCESS;
                    }
                }
            );
            builder.addCase(
                String(mutateCreatedEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(String(createEntity.pending), (state) => {
                state.actionStatus = ActionStatus.CREATE_PENDING;
            });
            builder.addCase(String(createEntity.fulfilled), (state, action: PayloadAction<Draft<Model>>) => {
                state.model = action.payload;
                state.actionStatus = ActionStatus.CREATE_SUCCESS;
            });
            builder.addCase(
                String(createEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(String(deleteEntity.pending), (state) => {
                state.actionStatus = ActionStatus.DELETE_PENDING;
            });
            builder.addCase(String(deleteEntity.fulfilled), (state, action: PayloadAction<Draft<ModelPrimaryKey>>) => {
                if (action.payload !== null) {
                    state.model = null;
                    state.actionStatus = ActionStatus.DELETE_SUCCESS;
                }
            });
            builder.addCase(
                String(deleteEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
        },
    });

// Selector creators
export const createSelectEntity =
    <Model extends ViewModel, Store extends Record<string, CreateEntityStore<Model>>>(storeName: string) =>
    (): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> =>
        new ReadonlyOptional(rootStore[storeName].model);

import classNames from 'classnames';
import { Fragment } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps, useWatch } from 'react-hook-form';
import { Icon, IconIdentifier, Tooltip } from '@steelbuy/ui-primitive';
import { InputType } from '../../Form.enums';

import './Select.scss';

type Options = Array<{ value: string | number; label: string }>;

type OptionsOrOptGroups = { options: Options; optGroups?: never } | { optGroups: Array<Options>; options?: never };

type SelectProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
    placeholder?: string;
    disabled?: boolean;
    error?: string;
    type?: InputType;
} & UseControllerProps<TFieldValues, TName> &
    OptionsOrOptGroups;

const mapOptions = (options: Options) =>
    options.map((option) => (
        <option value={option.value} key={option.value}>
            {option.label}
        </option>
    ));

export const Select = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    placeholder,
    disabled,
    options,
    optGroups,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    type = InputType.INLINE,
    ...rest
}: SelectProps<TFieldValues, TName>) => {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        field: { value: fieldValue, ...field },
    } = useController({
        control,
        ...rest,
    });

    const value = useWatch({ control, name: rest.name });

    const customSelectClassMap = {
        'form-select': true,
        'form-select--disabled': disabled,
    };
    const customSelectFieldClassMap = {
        'form-select__field': true,
        'form-select__field--block': type === InputType.BLOCK,
        'form-select__field--error': !!error,
        'form-select__field--disabled': disabled,
    };
    const customSelectFieldSelectClassMap = {
        'form-select__field__select': true,
        'form-select__field__select--disabled': false,
        'form-select__field__select--read-only': false,
    };

    return (
        <div className={classNames(customSelectClassMap)}>
            <span className={classNames(customSelectFieldClassMap)}>
                <select
                    disabled={disabled}
                    className={classNames(customSelectFieldSelectClassMap)}
                    value={
                        (value === undefined && placeholder !== undefined) ||
                        (options !== undefined && options?.length === 0) ||
                        (optGroups !== undefined && (optGroups?.length === 0 || optGroups?.[0].length === 0))
                            ? ''
                            : value
                    }
                    {...field}
                    {...rest}
                >
                    {placeholder && (
                        <option disabled value="">
                            {placeholder}
                        </option>
                    )}
                    {options && mapOptions(options)}
                    {optGroups?.map((group, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={`${rest.name}-${index}`}>
                            {index === 0 ? (
                                mapOptions(group)
                            ) : (
                                <>
                                    <option disabled value={`group-${index}`}>
                                        ----------------
                                    </option>{' '}
                                    {mapOptions(group)}
                                </>
                            )}
                        </Fragment>
                    ))}
                </select>
                <Icon name={IconIdentifier.CHEVRON_DOWN} />
                {!!error && (
                    <span>
                        {type !== InputType.BLOCK ? (
                            <Tooltip content={error}>
                                <div className="icon__position">
                                    <Icon name={IconIdentifier.ERROR} />
                                </div>
                            </Tooltip>
                        ) : (
                            <div className="icon__position">
                                <Icon name={IconIdentifier.ERROR} />
                            </div>
                        )}
                    </span>
                )}
            </span>
            {!!error && type === InputType.BLOCK && <span className="form-select__field__error-msg">{error}</span>}
        </div>
    );
};

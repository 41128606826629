import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { RFQBuyerModel } from './RFQBuyerModel';
import { StatusModelConverter } from './StatusModel';
import { quoteDetailsConvertor, QuoteEntryDetail } from './util/quoteDetails';

export type RFQBuyerDetailsModel = Omit<RFQBuyerModel, 'quoteEntry'> & { quoteEntry: QuoteEntryDetail[] };

export class RFQBuyerDetailsModelConverter extends ModelConverter<RFQBuyerDetailsModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<RFQBuyerDetailsModel> | ArrayOfTimestampFieldNames<RFQBuyerDetailsModel>
    > {
        return ['expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<RFQBuyerDetailsModel> {
        return {
            status: new StatusModelConverter(),
        };
    }

    override getCustomFormatter() {
        return {
            quoteEntry: {
                toViewFormatter: quoteDetailsConvertor,
                toApiFormatter: (quoteEntry: QuoteEntryDetail[]) => quoteEntry,
            },
        };
    }
}

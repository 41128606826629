import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import {
    UploadFile,
    UploadFileFieldNames,
    ArrayOfUploadFileFieldNames,
    ArrayOfTimestampFieldNames,
    TimestampFieldNames,
    ModelPrimaryKey,
} from '@steelbuy/domain-model-types';
import { ListingSellerPricingModel, ListingSellerPricingModelConverter } from './ListingSellerPricingModel';
import { ListingSellerStatusModelConverter } from './ListingSellerStatusModel';
import { PackageSellerBaseModel, PrimePackageSellerBaseModel } from './PackageSellerBaseModel';

export type PackageSellerCreateDraftModel = Partial<
    Omit<PackageSellerBaseModel, 'invoiceAddress' | 'pickupAddress' | 'autoRenew' | 'pricing'> & {
        pictures: UploadFile[];
        packageDocument: UploadFile;
        autoRenew: boolean;
        pickupAddressId: ModelPrimaryKey;
        invoiceAddressId: ModelPrimaryKey;
        pricing: Partial<ListingSellerPricingModel>;
    } & PrimePackageSellerBaseModel<UploadFile>
>;

export class PackageSellerCreateDraftModelConverter extends ModelConverter<PackageSellerCreateDraftModel> {
    override getUploadFileFields(): Array<
        UploadFileFieldNames<PackageSellerCreateDraftModel> | ArrayOfUploadFileFieldNames<PackageSellerCreateDraftModel>
    > {
        return ['pictures', 'certificates', 'packageDocument'];
    }

    override getTimestampFields(): Array<
        TimestampFieldNames<PackageSellerCreateDraftModel> | ArrayOfTimestampFieldNames<PackageSellerCreateDraftModel>
    > {
        return ['expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<PackageSellerCreateDraftModel> {
        return {
            pricing: new ListingSellerPricingModelConverter(),
            status: new ListingSellerStatusModelConverter(),
        } as FieldConverterMap<PackageSellerCreateDraftModel>;
    }
}

import { ButtonTertiaryOnDarkS } from '@steelbuy/ui-primitive';

import './NavigationButton.scss';

type NavigationButtonProps = {
    label: string;
    onClick: () => void;
};

export const NavigationButton = ({ label, onClick }: NavigationButtonProps) => (
    <article className="navigation-button">
        <ButtonTertiaryOnDarkS label={label} onClick={onClick} />
    </article>
);

import { ReactNode } from 'react';

import './DetailsLayout.scss';

export type DetailsViewLayoutProps = {
    children: ReactNode;
    className?: string;
};

export const DetailsLayout = ({ children, className }: DetailsViewLayoutProps) => (
    <div className={`details-layout ${className ?? ''}`}>{children}</div>
);

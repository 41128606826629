import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ButtonGhostSystemOnLightM,
    ButtonPrimary,
    FormActionbar,
    MarketingBannerContact,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
    NotificationIllustratedItem,
    useWizardStepsContext,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../../router/Routes';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../../page-header/PageHeader';
import { useRfqFormContext } from '../RfqFormContext';

import './CreateRFQSuccess.scss';

export const CreateRFQSuccess = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const location = useLocation();
    const { reset } = useRfqFormContext();
    const { reset: wizardContextReset } = useWizardStepsContext();

    const handleCreateNew = () => {
        reset();
        wizardContextReset();
        navigate(RoutePath.CREATE_RFQ_WIZARD);
    };

    useEffect(() => {
        if (!location.state?.quoteReferenceNumber) {
            navigate(RoutePath.CREATE_RFQ_WIZARD);
        }
    }, []);

    if (!location.state?.quoteReferenceNumber) {
        return null;
    }

    const referenceNumberText = (
        <>
            {t('application.createRFQSuccess.referenceNumber')}
            <span className="reference-number">{location.state.quoteReferenceNumber}</span>
        </>
    );

    return (
        <>
            <PageHeader pageTitle={t('application.createRFQSuccess.header')} />
            <div className="create-rfq-success">
                <FormLayout>
                    <NotificationIllustrated
                        alignment={NotificationIllustratedAlignment.VERTICAL}
                        imgSrc="/assets/lib-ui-primitive/images/ListingReview-Success.svg"
                        header={t('application.createRFQSuccess.notificationMessage')}
                        helperText={referenceNumberText}
                    >
                        <NotificationIllustratedItem text={t('application.createRFQSuccess.nextStepsHint')} />
                        <NotificationIllustratedItem
                            text={t('application.createRFQSuccess.nextStepReview')}
                            isChecked
                        />
                        <NotificationIllustratedItem
                            text={t('application.createRFQSuccess.nextStepNotification')}
                            isChecked
                        />
                    </NotificationIllustrated>
                    <MarketingBannerContact
                        header={t('application.createRFQSuccess.marketingBannerHeader')}
                        text={t('application.createRFQSuccess.marketingBannerText')}
                    >
                        <a href="mailto:support@steel-buy.com">
                            {t('application.createRFQSuccess.marketingBannerEmail')}
                        </a>
                    </MarketingBannerContact>
                    <FormActionbar>
                        <ButtonGhostSystemOnLightM
                            label={t('application.createRFQSuccess.buttonLabelMyListings')}
                            onClick={() => navigate(RoutePath.MY_RFQS)}
                        />
                        <ButtonPrimary
                            label={t('application.createRFQSuccess.buttonLabelCreateNew')}
                            onClick={handleCreateNew}
                        />
                    </FormActionbar>
                </FormLayout>
            </div>
        </>
    );
};

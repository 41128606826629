import { ListingSearchResultCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingBuyerModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const listingBuyerEntityStoreName = 'listing-buyer-entity';
export type ListingBuyerEntityStore = {
    [listingBuyerEntityStoreName]: EntityStore<ListingBuyerModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingSearchResultCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<ListingBuyerModel, ListingBuyerEntityStore, ListingSearchResultCrudApiClient>(
    listingBuyerEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<ListingBuyerModel, ListingSearchResultCrudApiClient>(
    listingBuyerEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<ListingBuyerModel, ListingSearchResultCrudApiClient>(
    listingBuyerEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<ListingBuyerModel, ListingSearchResultCrudApiClient>(
    listingBuyerEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<ListingBuyerModel, ListingSearchResultCrudApiClient>(
    listingBuyerEntityStoreName,
    createApiClient
);

// Slice definition
export const listingBuyerEntitySlice = createCrudEntitySlice<ListingBuyerModel>(
    listingBuyerEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = listingBuyerEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<ListingBuyerModel, ListingBuyerEntityStore>(listingBuyerEntityStoreName);
const selectCreated = createSelectCreated<ListingBuyerModel, ListingBuyerEntityStore>(listingBuyerEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<ListingBuyerModel, ListingBuyerEntityStore>(
    listingBuyerEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const listingBuyerEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<ListingBuyerModel, ListingBuyerEntityStore>;

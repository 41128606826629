import { classNameFromEnumValue } from '@steelbuy/util';
import { SeparatorIdentifier } from './Separator.enums';

import './Separator.scss';

export type SeparatorProps = {
    separatorType: SeparatorIdentifier;
    header?: string;
};

export const Separator = (props: SeparatorProps) => {
    const { separatorType, header } = props;

    const renderHeader = () => {
        if (header === undefined) {
            return null;
        }
        return <span className="separator__header">{header}</span>;
    };

    return (
        <div className="separator" role="separator">
            {renderHeader()}
            <span className={`separator__stroke ${classNameFromEnumValue(separatorType, 'separator__stroke')}`} />
        </div>
    );
};

import { useEffect, Fragment, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatCurrency } from '@steelbuy/currency';
import {
    CartProductType,
    OrderModel,
    useCartDetails,
    useCreatePurchaseOrder,
    useUpdateCartNotification,
} from '@steelbuy/data-access';
import { useAddressCollectionData, useUserSelf } from '@steelbuy/data-provider';
import { AddressModel, OfferListingStatus } from '@steelbuy/domain-model';
import { useModal } from '@steelbuy/modal-dialog';
import { CheckoutCartSummary, ListingBuyerTeaser } from '@steelbuy/ui-domain';
import {
    Accordion,
    AddressIdentifier,
    ButtonCallToAction,
    ButtonSecondary,
    Card,
    CardContentCheckoutDelivery,
    CardContentCheckoutPayment,
    CardContentProductViewPrice,
    CardLayout,
    FormActionbar,
    LoadingSpinner,
    NotificationLevel,
    Notification,
    Separator,
    SeparatorIdentifier,
    LoadingStatus,
    toaster,
    CRITICAL_ERROR_ID,
    CustomLink,
} from '@steelbuy/ui-primitive';
import { useWindowSize } from '@steelbuy/util';
import { CheckoutCancelModal } from './CheckoutCancelModal';
import CheckoutCheckboxes from './CheckoutCheckboxes';
import { useCheckoutContext } from './CheckoutContext';
import { CheckoutErrorNotification } from './CheckoutErrorNotification';
import CheckoutPurchaseOrderNumber from './CheckoutPurchaseOrderNumber';
import { CHOOSE_ADDRESS_RELATIVE_PATH, RoutePath } from '../../router/Routes';
import { AddressDetails } from '../address-details/AddressDetails';
import { CheckoutSuccessMyCart } from '../checkout-success/CheckoutSucessMyCart';
import { PageHeader } from '../page-header/PageHeader';

import './CheckoutMyCart.scss';

interface CheckoutMyCartViewProps {
    goBackPath: string;
}

export const CheckoutMyCart = ({ goBackPath }: CheckoutMyCartViewProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);

    const navigate = useNavigate();
    const { width } = useWindowSize();
    const SHOW_LARGE_SCREEN_SIZE = 1006;
    const { data, isLoading, refetch } = useCartDetails();
    const checkoutContext = useCheckoutContext();
    const purchaseOrderMutation = useCreatePurchaseOrder();
    const {
        formState: { isValid },
        control,
        trigger,
    } = useForm({ mode: 'all' });
    const { invoiceAddressCollectionData, warehouseAddressCollectionData } = useAddressCollectionData();
    const userData = useUserSelf().get();
    const cancelModal = useModal();
    const [showUpdatedCartNotification, setShowUpdatedCartNotification] = useState(false);
    const { mutate: updateCartNotification } = useUpdateCartNotification();
    const invoiceAddresses = invoiceAddressCollectionData.query();
    const warehouseAddresses = warehouseAddressCollectionData.query();
    const [orderPlaced, setOrderPlaced] = useState<OrderModel | null>(null);

    const retryCount = useRef(0);

    const defaultInvoiceAddressId = invoiceAddresses[0]?.id ?? '';
    const defaultWarehouseAddressId =
        (warehouseAddresses.find((address: AddressModel) => address.primary) ?? warehouseAddresses[0])?.id ?? '';

    const buyIsDisabled = () => {
        if (!checkoutContext.agreedTermsAndConditions || !isValid) {
            return true;
        }
        return !checkoutContext.agreedAuthorised;
    };

    const dismissCriticalError = () => {
        toast.dismiss(CRITICAL_ERROR_ID);
    };

    const handleCheckout = () => {
        dismissCriticalError();
        if (!checkoutContext.deliveryAddressId || !data) return;

        const payload = {
            cartId: data.id,
            deliveryAddressId: checkoutContext.deliveryAddressId,
            invoiceAddressId: defaultInvoiceAddressId,
            buyerOrderNumber: checkoutContext.buyerOrderNumber,
            cartEntries: data.cartEntries.map((entry) => ({
                entryId: entry.id,
                productId: entry.product.id,
                negotiationId:
                    entry.productType === CartProductType.LISTING
                        ? entry.product.negotiations?.find(
                              (negotiation) => negotiation.status === OfferListingStatus.ACCEPTED
                          )?.id
                        : undefined,
                haulierRequired: checkoutContext.haulierRequiredCart[entry.id] || false,
            })),
        };

        purchaseOrderMutation.mutate(payload, {
            onSuccess: (order: OrderModel) => {
                refetch();
                setOrderPlaced(order);
            },
            onError: () => {
                retryCount.current += 1;
                const isRetryLimitExceed = retryCount.current >= 4;
                const mailto = `mailto: ${t('constants.emailSupport')}`;
                toaster.criticalError(
                    <div>
                        <Trans
                            i18nKey={
                                isRetryLimitExceed
                                    ? 'translation:application.checkout.error.unableToPurchaseCartAfterRetryExceeded'
                                    : 'translation:application.checkout.error.unableToPurchaseCartWithRetry'
                            }
                            components={{
                                link1: <CustomLink isInlineLink isAnchorLink openInNewTab path={mailto} />,
                            }}
                            t={t}
                        />
                    </div>,
                    isRetryLimitExceed
                        ? {
                              label: t('translation:application.checkout.error.contactSupport'),
                              onClick: () => {
                                  const mail = document.createElement('a');
                                  mail.href = mailto;
                                  mail.target = '_blank';
                                  mail.rel = 'noopener noreferrer';
                                  mail.click();
                              },
                          }
                        : {
                              label: t('uiDomain:common.retry'),
                              onClick: () => {
                                  handleCheckout();
                              },
                          }
                );
            },
        });
    };

    useEffect(
        () => () => {
            dismissCriticalError();
            checkoutContext.setAgreedAuthorised(false);
            checkoutContext.setAgreedTermsAndConditions(false);
        },
        []
    );

    useEffect(() => {
        if (data?.errors?.code === 2001) {
            setShowUpdatedCartNotification(true);
            updateCartNotification({
                cartId: data.errors.cartId || data.id,
                entryIds: data.errors.entryIds,
            });
        }
        if (!orderPlaced && data !== undefined && !data?.cartEntries?.length) {
            navigate(RoutePath.MY_CART);
        }
    }, [data]);

    useEffect(() => {
        if (!checkoutContext.deliveryAddressId) {
            checkoutContext.setDeliveryAddressId(defaultWarehouseAddressId);
        }
    }, [defaultWarehouseAddressId]);

    useEffect(() => {
        if (!isValid && (checkoutContext.agreedTermsAndConditions || checkoutContext.agreedAuthorised)) {
            trigger();
        }
    }, [checkoutContext.agreedTermsAndConditions, checkoutContext.agreedAuthorised]);

    let errorMessage = '';
    if (!checkoutContext.agreedTermsAndConditions || !checkoutContext.agreedAuthorised || !isValid) {
        errorMessage = t('translation:application.checkout.incompleteInformationNotification');
    }

    if (orderPlaced) return <CheckoutSuccessMyCart orderPlaced={orderPlaced} />;

    return (
        <div className="checkout-cart">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        pageTitle={t('translation:application.checkout.header.title')}
                        previousPageTitle={t('uiDomain:common.back')}
                        onBackClick={() => navigate(goBackPath)}
                        showCartIcon={false}
                    />
                    {showUpdatedCartNotification && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('application.myCart.itemsExpiredOrSold')}
                            className="my-cart-list__updated-notification"
                            closeButton
                        />
                    )}
                    <div className="checkout-my-cart">
                        <div className="checkout-my-cart-view">
                            <div className="checkout-my-cart-view__details">
                                <Card isClickable={false}>
                                    <CardLayout>
                                        <AddressDetails
                                            addressId={defaultInvoiceAddressId}
                                            type={AddressIdentifier.BILLING_ADDRESS}
                                            additionalInfoLabel={t(
                                                'translation:application.addressDetails.additionalInformationLabel'
                                            )}
                                        />
                                    </CardLayout>
                                </Card>

                                {/* buyer purchase order number input field */}
                                <CheckoutPurchaseOrderNumber
                                    setBuyerOrderNumber={checkoutContext.setBuyerOrderNumber}
                                    control={control}
                                />
                                {/* payment  */}
                                <Card isClickable={false}>
                                    <CardLayout>
                                        <CardContentCheckoutPayment
                                            header={t('translation:application.checkout.payment.sectionHeading')}
                                            notificationMessage={t(
                                                'translation:application.checkout.payment.creditInfoNotification'
                                            )}
                                        />
                                    </CardLayout>
                                </Card>

                                <Card isClickable={false}>
                                    <CardLayout>
                                        <AddressDetails
                                            addressId={checkoutContext.deliveryAddressId}
                                            type={AddressIdentifier.DELIVERY_ADDRESS}
                                            onButtonClick={() => navigate(CHOOSE_ADDRESS_RELATIVE_PATH)}
                                            additionalText={checkoutContext.additionalDeliveryInformation ?? ''}
                                            onChangeAdditionalText={(value) =>
                                                checkoutContext.setAdditionalDeliveryInformation(value)
                                            }
                                            additionalInfoLabel={t(
                                                'translation:application.addressDetails.deliveryInformationLabel'
                                            )}
                                            additionalInfoPlaceholder={t(
                                                'translation:application.pickupAddressStep.additionalInfoPlaceholder'
                                            )}
                                            isCartCheckout
                                        />
                                    </CardLayout>
                                </Card>

                                <div className="my-cart-list__container__listing-teaser">
                                    {data?.cartEntries?.map((entry) => {
                                        const isPackage = entry.productType === 'PACKAGE';
                                        return (
                                            <Fragment key={entry.id}>
                                                <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
                                                <Card isClickable={false}>
                                                    {isPackage ? (
                                                        <ListingBuyerTeaser
                                                            listing={entry.product}
                                                            isCart
                                                            isPackage
                                                            isCartCheckout
                                                        />
                                                    ) : (
                                                        <ListingBuyerTeaser
                                                            listing={entry.product}
                                                            isCart
                                                            isCartCheckout
                                                        />
                                                    )}
                                                </Card>

                                                <CardContentCheckoutDelivery
                                                    deliveryPromise={
                                                        isPackage
                                                            ? entry.product.deliveryTimeframe
                                                            : entry.product.deliveryPromise
                                                    }
                                                    onHaulierRequiredChanged={(value) => {
                                                        checkoutContext.setHaulierRequiredCart(entry.id, value);
                                                    }}
                                                    haulierRequired={
                                                        checkoutContext.haulierRequiredCart[entry.id] ?? false
                                                    }
                                                    countryCode={entry.product.country}
                                                    name={`radio-button-group-delivery-${entry.id}`}
                                                />
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* Check boxes */}
                            <div className="input-checkbox-container">{userData && <CheckoutCheckboxes />}</div>
                            {errorMessage && <CheckoutErrorNotification errorMessage={errorMessage} />}

                            <FormActionbar>
                                <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={cancelModal.show} />
                                <ButtonCallToAction
                                    label={t('translation:application.checkout.submitCheckoutButtonLabel')}
                                    disabled={buyIsDisabled() || purchaseOrderMutation.isLoading}
                                    onClick={handleCheckout}
                                    loadingStatus={
                                        purchaseOrderMutation.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE
                                    }
                                />
                            </FormActionbar>
                        </div>
                        <div className="checkout-my-cart-summary">
                            <div className="checkout-my-cart-summary__list">
                                <Card isClickable={false}>
                                    <CardLayout>
                                        {width > SHOW_LARGE_SCREEN_SIZE ? (
                                            <div className="checkout-my-cart-summary__list__withoutAccordion">
                                                <div className="checkout-my-cart-summary__list__items">
                                                    {t('uiDomain:checkoutCart.item', {
                                                        count: data?.cartEntries?.length,
                                                    })}
                                                </div>
                                                {data?.cartEntries?.map((entry) => (
                                                    <Fragment key={entry.id}>
                                                        <div>
                                                            <CheckoutCartSummary cartData={entry} />
                                                        </div>
                                                        <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
                                                    </Fragment>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="checkout-my-cart-summary__list__withAccordion">
                                                <Accordion
                                                    collapsedLabel={t('uiDomain:checkoutCart.item', {
                                                        count: data?.cartEntries?.length,
                                                    })}
                                                    extendedLabel={t('uiDomain:checkoutCart.item', {
                                                        count: data?.cartEntries?.length,
                                                    })}
                                                >
                                                    {data?.cartEntries?.map((entry) => (
                                                        <Fragment key={entry.id}>
                                                            <div>
                                                                <CheckoutCartSummary cartData={entry} />
                                                            </div>
                                                            <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
                                                        </Fragment>
                                                    ))}
                                                </Accordion>
                                            </div>
                                        )}
                                    </CardLayout>
                                </Card>
                            </div>
                            <div className="checkout-my-cart-summary__price">
                                <Card isClickable={false}>
                                    <CardLayout>
                                        <CardContentProductViewPrice
                                            header={t('translation:application.checkoutDeliveryOption.orderSummary')}
                                            pricePerUnit=""
                                            pricePerUnitLabel={t(
                                                'translation:application.checkoutDeliveryOption.totalWeight'
                                            )}
                                            isCartCheckout
                                            weight=""
                                            typeWithWeight={data?.typeWithWeight}
                                            totalExVat={
                                                data?.totalValueVAT?.value
                                                    ? formatCurrency(
                                                          navigator.language,
                                                          data.totalValue.value,
                                                          data?.currencyCode
                                                      )
                                                    : ''
                                            }
                                            vatPercent={data?.taxPercent ? (data.taxPercent / 100).toString() : '0'}
                                            totalPrice={
                                                data?.totalValueVAT?.value
                                                    ? formatCurrency(
                                                          navigator.language,
                                                          data.totalValueVAT.value,
                                                          data?.currencyCode
                                                      )
                                                    : ''
                                            }
                                            vat={
                                                data?.vat
                                                    ? formatCurrency(navigator.language, data.vat, data?.currencyCode)
                                                    : ''
                                            }
                                        />
                                    </CardLayout>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <CheckoutCancelModal
                        modalAccessor={cancelModal}
                        action={() => {
                            checkoutContext.reset();
                            navigate(RoutePath.MY_CART);
                        }}
                    />
                </>
            )}
        </div>
    );
};

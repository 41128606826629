import { BadgeState, BadgeVariant, IconIdentifier, Tag, TagVariant } from '@steelbuy/ui-primitive';

interface RFQTeaserQualityProps {
    label?: string;
}

export const RFQTeaserQuality = ({ label }: RFQTeaserQualityProps) => {
    if (label === 'Draft') {
        return (
            <span className="draft-badgestate">
                <BadgeState label={label} variant={BadgeVariant.INFO} />
            </span>
        );
    }
    return <Tag label="Prime" icon={IconIdentifier.PRIME} variant={TagVariant.PRIME} />;
};

import { ReactNode } from 'react';
import { InvoiceAddressCollectionDataProvider } from '../invoice-address-collection/InvoiceAddressCollectionDataProvider';
import { WarehouseAddressCollectionDataProvider } from '../warehouse-address-collection/WarehouseAddressCollectionDataProvider';

type AddressCollectionDataProviderProps = {
    children: ReactNode;
    lazyLoad?: boolean;
};

export const AddressCollectionDataProvider = ({ children, lazyLoad }: AddressCollectionDataProviderProps) => (
    <InvoiceAddressCollectionDataProvider lazyLoad={lazyLoad}>
        <WarehouseAddressCollectionDataProvider lazyLoad={lazyLoad}>{children}</WarehouseAddressCollectionDataProvider>
    </InvoiceAddressCollectionDataProvider>
);

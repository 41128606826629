import { JsonRestConnector } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import {
    AccessibleUrl,
    createUrlFromApiUrl,
    DownloadFile,
    isAccessableUrl,
    isApiDownloadFile,
    ProtectedUrl,
} from '@steelbuy/domain-model-types';
import { Optional, Potentially } from '@steelbuy/types';

export const resolveProtectedUrl = async (
    protectedUrl: ProtectedUrl,
    jsonWebToken: Potentially<JsonWebToken>
): Promise<Optional<AccessibleUrl>> => {
    const restConnector = new JsonRestConnector(jsonWebToken);
    const resolveResponse = await restConnector.get(protectedUrl.value);
    const apiAccessableFile = resolveResponse.body;
    if (!isApiDownloadFile(apiAccessableFile)) {
        return new Optional<AccessibleUrl>(null);
    }
    const url = createUrlFromApiUrl(apiAccessableFile.url).get();
    return new Optional(url as AccessibleUrl);
};

const convertBlobToDataUri = async (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });

export const resolveAccessibleUrl = async (accessibleUrl: AccessibleUrl): Promise<string | null> => {
    const request = new Request(accessibleUrl.value);
    const response = await fetch(request);

    const blob = await response.blob();

    return convertBlobToDataUri(blob);
};

export const resolveUrl = async (
    file: DownloadFile,
    jsonWebToken: Potentially<JsonWebToken>
): Promise<string | null> => {
    let accessibleUrl: AccessibleUrl | undefined;
    if (isAccessableUrl(file.url)) {
        accessibleUrl = file.url;
    } else {
        accessibleUrl = (await resolveProtectedUrl(file.url, jsonWebToken)).getOrUndefined();
    }

    if (accessibleUrl !== undefined) {
        return resolveAccessibleUrl(accessibleUrl);
    }

    return null;
};

import { useRef, ReactNode, useEffect, useState } from 'react';
import { useWindowSize } from '@steelbuy/util';

interface FillHeightContainerProps {
    children: ReactNode;
    className?: string;
    extraPadding?: number;
    minHeight?: number;
}

export const FillHeightContainer = ({
    children,
    className = '',
    extraPadding = 0,
    minHeight = 0,
    ...props
}: FillHeightContainerProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { height } = useWindowSize();
    const [minimumHeight, setMinimumHeight] = useState(0);
    const [isHeightSet, setIsHeightSet] = useState(false);

    useEffect(() => {
        if (containerRef.current) {
            const newHeight = height - extraPadding - containerRef.current.getBoundingClientRect().top;
            setMinimumHeight(Math.max(newHeight, minHeight));
            setIsHeightSet(true);
        }
    }, [height]);

    return (
        <div className={className} ref={containerRef} {...props} style={{ minHeight: `${minimumHeight}px` }}>
            {isHeightSet && children}
        </div>
    );
};

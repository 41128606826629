import * as amplitude from '@amplitude/analytics-browser';
import { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserSelf } from '@steelbuy/data-provider';
import { AnalyticsContext, AnalyticsContextValue } from './AnalyticsContext';
import { TrackingEvent } from '../events/tracking-events';

export type AnalyticsProviderProps = {
    children: ReactNode;
};

export const AnalyticsProvider = (props: AnalyticsProviderProps): ReactElement => {
    const { children } = props;

    const user = useUserSelf().getOrUndefined();
    const location = useLocation();

    const authContextValue: AnalyticsContextValue = {
        sendEvent: (event: TrackingEvent, payload: Record<string, unknown> = {}) => {
            amplitude.track(event, {
                ...payload,
                user_id: user?.id,
                organisation_id: user?.organisation.id,
                page_name: location.pathname,
            });
        },
    };

    return <AnalyticsContext.Provider value={authContextValue}>{children}</AnalyticsContext.Provider>;
};

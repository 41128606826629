import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDraftPackage } from '@steelbuy/data-access';
import { ListingSellerDraftEntityDataProvider } from '@steelbuy/data-provider';
import { PackageSellerDraftModel } from '@steelbuy/domain-model';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { CreateListing } from '../../../components/create-listing/CreateListing';
import { useCreateListingContext } from '../../../components/create-listing/CreateListingContext';
import { InitialCreateListingData } from '../../../components/create-listing/CreateListingContextUtil';
import { CreateListingDraftGuard } from '../../../components/create-listing/CreateListingDraftGuard';
import { CreateListingDraftNotFound } from '../../../components/create-listing/CreateListingDraftNotFound';

const CreateListingWithInitialData = ({ initialData }: { initialData: InitialCreateListingData }) => {
    const [isInitialised, setIsInitialised] = useState(false);
    const { setInitialFormData } = useCreateListingContext();

    useEffect(() => {
        setInitialFormData(initialData);
        setIsInitialised(true);
    }, []);

    if (!isInitialised) {
        return <LoadingSpinner />;
    }
    return <CreateListing />;
};

const PACKAGE = 'package';

export const CreateListingCreateView = () => {
    const { draftId = null, listingType } = useParams();
    const location = useLocation();
    const [isInitialDataSet, setIsInitialDataSet] = useState(false);
    const createListingContext = useCreateListingContext();
    const shouldFetchPackage = listingType === PACKAGE && !createListingContext.draftId;

    const {
        data,
        isLoading,
        error: getDraftPackageError,
    } = useDraftPackage({
        packageId: draftId || '',
        queryOptions: { enabled: shouldFetchPackage, cacheTime: 0 },
    });

    const setPackageDraftData = (draft: PackageSellerDraftModel) => {
        createListingContext.loadFromPackageDraft(draft);
        setIsInitialDataSet(true);
    };

    useEffect(() => {
        if (shouldFetchPackage && !isLoading && draftId && data && !getDraftPackageError) {
            setPackageDraftData(data);
        }
    }, [isLoading]);

    useEffect(() => {
        if (createListingContext.goBack) {
            return;
        }
        createListingContext.setGoBack(
            location.state?.goBackLink
                ? {
                      link: location.state?.goBackLink,
                      label: location.state?.goBackText,
                  }
                : null
        );
    }, []);

    if (getDraftPackageError) {
        return <CreateListingDraftNotFound />;
    }

    if (shouldFetchPackage && isLoading) {
        return <LoadingSpinner />;
    }

    if (!isInitialDataSet && listingType === PACKAGE && !createListingContext.draftId) {
        return null;
    }

    if (draftId && listingType !== PACKAGE) {
        const handleError = (error: Error) => {
            if (error instanceof NotFoundError) {
                return <CreateListingDraftNotFound />;
            }

            return null;
        };

        return (
            <ErrorHandler errorComponent={handleError}>
                <ListingSellerDraftEntityDataProvider entityId={draftId} interceptRendering={false}>
                    <CreateListingDraftGuard draftId={draftId} />
                </ListingSellerDraftEntityDataProvider>
            </ErrorHandler>
        );
    }
    if (location.state?.materialStepData) {
        return <CreateListingWithInitialData initialData={location.state} />;
    }
    return <CreateListing />;
};

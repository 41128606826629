import { useTranslation } from 'react-i18next';
import { EmptyState } from '@steelbuy/ui-primitive';

export const MyAlertsEmptyState = ({ navigateToSearch }: { navigateToSearch: () => void }) => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="my-alerts__empty-state">
            <EmptyState
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateData.svg"
                description={t('myAlertsEmptyState.description')}
                caption={t('myAlertsEmptyState.caption')}
                button={{
                    label: t('myAlertsEmptyState.searchButtonLabel'),
                    onClick: navigateToSearch,
                }}
            />
        </div>
    );
};

import { isArray } from '@steelbuy/types';

export type ClassMap = Record<string, unknown>;

export const buildClassNamesFromClassMap = (classMap: ClassMap): Array<string> => {
    const classNames: Array<string> = [];
    Object.keys(classMap).forEach((className) => {
        const classMapElement = classMap[className];
        const checkValue = isArray(classMapElement) && classMapElement.length === 0 ? false : classMapElement;
        if ((checkValue ?? false) !== false) {
            classNames.push(className);
        }
    });
    return classNames;
};

export const buildClassStringFromClassMap = (classMap: ClassMap): string =>
    buildClassNamesFromClassMap(classMap).join(' ');

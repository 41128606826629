import { ChangeEvent, InputHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import iconSvg from '../../assets/lib-ui-primitive/icons/icon.svg';
import { IconIdentifier } from '../icon/Icon.enums';
import './InputRadioButtonIllustration.scss';

export type InputRadioButtonIllustrationProps = {
    value: string;
    icon: IconIdentifier;
    onChange?: (value: boolean) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputRadioButtonIllustration = (props: InputRadioButtonIllustrationProps) => {
    const { value, icon, disabled, onChange, ...rest } = props;

    const inputRadioButtonIllustrationClassMap = {
        'input-radio-button-illustration': true,
        'input-radio-button-illustration--disabled': disabled,
    };

    const inputRadioButtonIllustrationInputFieldClassMap = {
        'input-radio-button-illustration__input-field': true,
        'input-radio-button-illustration__input-field--disabled': disabled,
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return (
            <svg>
                <use href={`${iconSvg}#${icon}`} />
            </svg>
        );
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onChange !== undefined) {
            onChange(event.target.checked);
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputRadioButtonIllustrationClassMap)}>
            <span className="input-radio-button-illustration__input">
                <input
                    className={buildClassStringFromClassMap(inputRadioButtonIllustrationInputFieldClassMap)}
                    type="radio"
                    onChange={handleChange}
                    disabled={disabled}
                    {...rest}
                />
                <div className="input-radio-button-illustration__input__wrapper">
                    {renderIcon()}
                    <span className="input-radio-button-illustration__input__wrapper__value">{value}</span>
                </div>
            </span>
        </label>
    );
};

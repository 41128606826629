import { Card } from '../card/Card';
import { CardLayout } from '../card/card-layout/CardLayout';
import { CardContentAttributesItem } from '../card-content-attributes/card-content-attributes-item/CardContentAttributesItem';
import { CardContentAttributes } from '../card-content-attributes/CardContentAttributes';

export type CardContentLabelValuesProps = {
    title: string;
    labelValues: { label: string; value: string }[];
};

export const CardContentLabelValues = (props: CardContentLabelValuesProps) => {
    const { title, labelValues } = props;

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={title}>
                    {labelValues.map(({ label, value }) => (
                        <CardContentAttributesItem label={label} value={value} key={label} />
                    ))}
                </CardContentAttributes>
            </CardLayout>
        </Card>
    );
};

import { Optional, Potentially } from '@steelbuy/types';

import { ApiDateRange, DateRange } from './DateRange';
import { isDateRange } from './DateRange.guards';
import { ApiTimestamp, Timestamp } from './Timestamp';
import {
    createApiTimestampFromTimestamp,
    createTimestampFromApiTimestamp,
    createTimestampFromDate,
} from './Timestamp.util';

const createDateRangeTimestampFromApiTimestamp = (apiTimestamp: Potentially<ApiTimestamp>): Timestamp =>
    createTimestampFromApiTimestamp(apiTimestamp).getOrThrow(
        new Error(`Expected date string value. Got ${apiTimestamp}`)
    );

export const createDateRangeFromApiTimestamps = (start: ApiTimestamp, end: ApiTimestamp): DateRange => ({
    discriminator: 'DateRange',
    start: createDateRangeTimestampFromApiTimestamp(start),
    end: createDateRangeTimestampFromApiTimestamp(end),
});

export const createDateRangeFromTimestamps = (start: Timestamp, end: Timestamp): DateRange => ({
    discriminator: 'DateRange',
    start,
    end,
});

export const createDateRangeFromDates = (start: Date, end: Date): DateRange => ({
    discriminator: 'DateRange',
    start: createTimestampFromDate(start),
    end: createTimestampFromDate(end),
});

const createDateRangeApiTimestampFromTimestamp = (timestamp: Potentially<Timestamp>): ApiTimestamp =>
    createApiTimestampFromTimestamp(timestamp).getOrThrow(new Error(`Expected timestamp value. Got ${timestamp}`));

export const createApiDateRangeFromDateRange = (dateRange: Potentially<DateRange>): Optional<ApiDateRange> => {
    let apiDateRange = null;
    if (isDateRange(dateRange)) {
        apiDateRange = {
            start: createDateRangeApiTimestampFromTimestamp(dateRange.start),
            end: createDateRangeApiTimestampFromTimestamp(dateRange.end),
        };
    }
    return new Optional<ApiDateRange>(apiDateRange);
};

export const createDateRangeFromApiDateRange = (apiDateRange: ApiDateRange): DateRange =>
    createDateRangeFromApiTimestamps(apiDateRange.start, apiDateRange.end);

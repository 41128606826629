import { useTranslation } from 'react-i18next';
import {
    ListingSellerModel,
    ListingStatus,
    SellerListingOfferStatus,
    OfferView,
    PackageSellerModel,
    SearchFormData,
    getPricingUnit,
} from '@steelbuy/domain-model';
import { MonetaryAmount, createDateFromTimestamp } from '@steelbuy/domain-model-types';

import { DaynameFormat, formatDateTime, formatDayname, formatTime } from '@steelbuy/util';
import { ListingTeaser } from './ListingTeaser';
import { ListingTeaserContainer } from './ListingTeaserContainer';
import { ListingTeaserHeader } from './ListingTeaserHeader';
import { ListingTeaserPrice } from './ListingTeaserPrice';
import { ListingTeaserWeightAndItems } from './ListingTeaserWeightAndItems';
import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';
import { PackageTeaserHeader } from '../package-teaser/PackageTeaserHeader';
import { getBadgeVariant } from '../status-badge-variant/StatusBadgeVariant';

import './ListingTeaser.scss';

export type ListingSellerTeaserProps = {
    searchData?: SearchFormData;
    isNew?: boolean;
    offerStatus?: SellerListingOfferStatus;
    numberOffers?: number;
    numberActiveOffers?: number;
    offerPrice?: MonetaryAmount | MonetaryAmount[];
    referenceId?: string;
    isOffer?: boolean;
} & ({ isPackage: true; listing: PackageSellerModel } | { isPackage?: false; listing: ListingSellerModel });

const expiryTimeRelatedStatus = [ListingStatus.PUBLISHED, ListingStatus.REVIEW_REQUIRED, ListingStatus.REVIEW_REJECTED];

const isSameDay = (a: Date, b: Date) =>
    a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

export const ListingSellerTeaser = (props: ListingSellerTeaserProps) => {
    const {
        listing,
        isNew,
        searchData,
        numberOffers,
        numberActiveOffers,
        offerPrice,
        referenceId,
        offerStatus,
        isOffer,
        isPackage,
    } = props;

    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const numberOfItems = useNumberOfItems(
        !isPackage ? listing.material?.product : undefined,
        !isPackage ? listing.numberOfItems : undefined
    );

    let expiryTime;
    if (!offerStatus && listing.expiresAt && expiryTimeRelatedStatus.includes(listing.status.value)) {
        const expirationDate = createDateFromTimestamp(listing.expiresAt);
        const dayname = isSameDay(expirationDate, new Date())
            ? t('listingSellerTeaser.today')
            : formatDayname(navigator.language, expirationDate, undefined, DaynameFormat.SHORT);

        expiryTime = t('listingTeaser.expiryDate', {
            expiryDate: `${dayname} ${formatTime(navigator.language, expirationDate)}`,
        });
    }
    const statusLabel = listing.status ? t(`listingSellerStatus.${listing.status.value}`) : '';

    const statusDate = listing.status?.modifiedAt
        ? formatDateTime(navigator.language, createDateFromTimestamp(listing.status.modifiedAt))
        : undefined;

    const pricingUnit = getPricingUnit(isPackage ? { materialType: listing.material } : listing.material);

    const deliveryPromise = isPackage
        ? t(`domainModel:deliveryPromise.${listing.deliveryTimeframe}`)
        : t(`domainModel:deliveryPromise.${listing.seller.deliveryPromise}`);

    return (
        <ListingTeaser>
            {isPackage ? (
                <PackageTeaserHeader packages={listing} isNew={isNew} />
            ) : (
                <ListingTeaserHeader listing={listing} searchData={searchData} isNew={isNew} />
            )}
            {numberOffers ? (
                <ListingTeaserContainer
                    isPrime={listing.prime}
                    offerStatus={offerStatus}
                    numberOffers={numberOffers}
                    numberActiveOffers={numberActiveOffers}
                    referenceId={referenceId}
                    deliveryTime={deliveryPromise}
                    offerView={OfferView.SELLER}
                />
            ) : (
                <ListingTeaserContainer
                    isPrime={listing.prime}
                    sellerMaterialId={listing.internalSellerSku}
                    expiryTime={expiryTime}
                    statusLabel={statusLabel + (statusDate ? `: ${statusDate}` : '')}
                    statusBadgeVariant={getBadgeVariant(listing.status?.value)}
                    deliveryTime={deliveryPromise}
                />
            )}

            {listing.weight && listing.tradeUnit ? (
                <ListingTeaserWeightAndItems
                    weight={listing.weight}
                    unit={listing.tradeUnit}
                    numberOfItems={numberOfItems}
                />
            ) : (
                <div />
            )}
            {(offerPrice || listing.pricing?.netPricePerUnit !== undefined) &&
                pricingUnit &&
                listing.pricing.currencyCode && (
                    <ListingTeaserPrice
                        value={offerPrice || listing.pricing.netPricePerUnit}
                        currencyCode={listing.pricing.currencyCode}
                        unit={pricingUnit}
                        isNew={isNew}
                        isMyOffer={isOffer}
                    />
                )}
        </ListingTeaser>
    );
};

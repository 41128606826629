import { ListingBuyerAlertSearchApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingBuyerSearchResultModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';

import { createSearchSlice, createSelectCollection } from '../../util/store/crud/SearchSlice';
import { SearchStore } from '../../util/store/crud/SearchStore';
import { SearchStoreAccessors } from '../../util/store/crud/SearchStoreAccessor';
import { createSearchAllThunk, createSearchNextThunk } from '../../util/store/crud/Thunks';

// Basic definition
export const listingBuyerAlertSearchStoreName = 'listing-buyer-alert-collection-search';
export type ListingBuyerAlertSearchStore = {
    [listingBuyerAlertSearchStoreName]: SearchStore<ListingBuyerSearchResultModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingBuyerAlertSearchApiClient().init(apiBaseUrl, jsonWebToken);
const fetchAll = createSearchAllThunk<
    ListingBuyerSearchResultModel,
    ListingBuyerAlertSearchStore,
    ListingBuyerAlertSearchApiClient
>(listingBuyerAlertSearchStoreName, createApiClient);
const fetchNext = createSearchNextThunk<
    ListingBuyerSearchResultModel,
    ListingBuyerAlertSearchStore,
    ListingBuyerAlertSearchApiClient
>(listingBuyerAlertSearchStoreName, createApiClient);

// Slice definition
export const listingBuyerAlertSearchSlice = createSearchSlice<ListingBuyerSearchResultModel>(
    listingBuyerAlertSearchStoreName,
    fetchAll,
    fetchNext
);
const { resetFetchStatus } = listingBuyerAlertSearchSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<ListingBuyerSearchResultModel, ListingBuyerAlertSearchStore>(
    listingBuyerAlertSearchStoreName
);

// Export the store accessors to be used by the data provider value
export const listingBuyerAlertSearchStoreAccessors = {
    fetchAll,
    fetchNext,
    resetFetchStatus,
    selectCollection,
} as SearchStoreAccessors<ListingBuyerSearchResultModel, ListingBuyerAlertSearchStore>;

import { ButtonCallToAction } from '../button-call-to-action/ButtonCallToAction';

import './EmptyState.scss';

interface EmptyStateButtonProps {
    label: string;
    onClick: () => void;
}

interface EmptyStateProps {
    description: string;
    button?: EmptyStateButtonProps;
    imgSrc?: string;
    caption?: string;
}

export const EmptyState = (props: EmptyStateProps) => {
    const { description, button, imgSrc, caption } = props;

    return (
        <div className="empty-state">
            {imgSrc && <img alt="empty state" src={imgSrc} />}
            <div className="empty-state__description">
                {caption && <div className="empty-state__description__caption">{caption}</div>}
                <span>{description}</span>
                {button && <ButtonCallToAction label={button.label} onClick={button.onClick} />}
            </div>
        </div>
    );
};

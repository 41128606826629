import { ButtonHTMLAttributes, useContext } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { SubTabContext } from '../sub-tab/SubTab';

import './TabChip.scss';

export type TabChipProps = {
    label: string;
    tabId: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export const TabChip = (props: TabChipProps) => {
    const { label, tabId, ...rest } = props;
    const { getSubTabId, setSubTabId } = useContext(SubTabContext);

    const tabItemClassMap = {
        'tab-chip': true,
        'tab-chip--selected': getSubTabId() === tabId,
    };

    return (
        <button className={buildClassStringFromClassMap(tabItemClassMap)} onClick={() => setSubTabId(tabId)} {...rest}>
            {label}
        </button>
    );
};

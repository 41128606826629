import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JsonWebToken } from '@steelbuy/auth';
import { Mutable, Mutation, ViewModel } from '@steelbuy/domain-model-types';
import { AnyErrorObject } from '@steelbuy/error';
import { Nullable, ReadonlyOptional } from '@steelbuy/types';

import { ActionStatus } from '../store/ActionStatus';
import { CreateEntityStore } from '../store/crud/CreateEntityStore';
import { CreateEntityStoreAccessor } from '../store/crud/CreateEntityStoreAccessor';

export type CreateEntityDataProviderValue<Model extends ViewModel> = {
    query: () => ReadonlyOptional<Model>;
    mutate: (mutation: Mutation<Model>) => void;
    create: (mutatable: Mutable<Model>) => void;
    delete: () => void;
    queryActionStatus: () => ActionStatus;
    queryActionError: () => Nullable<AnyErrorObject>;
    resolveActionStatus: () => void;
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve: boolean) => void;
};

// noinspection JSUnusedLocalSymbols
export const defaultCreateEntityDataValue: CreateEntityDataProviderValue<never> = {
    queryActionStatus: (): ActionStatus => ActionStatus.IDLE,
    queryActionError: (): Nullable<AnyErrorObject> => null,
    query: (): ReadonlyOptional<never> => {
        throw new Error('Data provider missing');
    },
    resolveActionStatus: (): void => {
        throw new Error('Data provider missing');
    },
    mutate: (mutation: Mutation<never>): void => {
        throw new Error('Data provider missing');
    },
    create: (mutatable: Mutable<never>): void => {
        throw new Error('Data provider missing');
    },
    delete: (): void => {
        throw new Error('Data provider missing');
    },
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
        throw new Error('Data provider missing');
    },
};

export const createCreateEntityDataProviderValue = <
    Model extends ViewModel,
    RootStore extends Record<string, CreateEntityStore<Model>>
>(
    storeKey: string,
    storeAccessors: CreateEntityStoreAccessor<Model, RootStore>,
    apiBaseUrl: string,
    jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>
): CreateEntityDataProviderValue<Model> => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    const dataProviderValue = {
        queryActionStatus: (): ActionStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ActionStatus>((state) => state[storeKey]?.actionStatus ?? ActionStatus.IDLE),
        queryActionError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>((state) => state[storeKey]?.lastActionError ?? null),
        query: (): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.select()),
        resolveActionStatus: (): void => {
            dispatch(storeAccessors.resetActionStatus());
        },
        mutate: (modelMutation: Mutation<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.mutateCreatedEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    modelMutation,
                })
            );
        },
        create: (mutableModel: Mutable<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.createEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    mutableModel,
                })
            );
        },
        delete: (): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.deleteCreatedEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                })
            );
        },
        useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
            const actionStatus = dataProviderValue.queryActionStatus();
            useEffect(() => {
                if (status.includes(actionStatus)) {
                    try {
                        effect();
                    } finally {
                        if (resolve) {
                            dataProviderValue.resolveActionStatus();
                        }
                    }
                }
            }, [actionStatus]);
        },
    };

    return dataProviderValue;
};

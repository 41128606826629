import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useWarehouseAddressCollectionData } from '@steelbuy/data-provider';
import { AddressModel } from '@steelbuy/domain-model';
import { Mutable } from '@steelbuy/domain-model-types';
import { AddressForm } from '@steelbuy/ui-domain';
import { CHOOSE_ADDRESS_RELATIVE_PATH } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../page-header/PageHeader';

export const CheckoutAddAddress = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const warehouseAddressCollectionData = useWarehouseAddressCollectionData();

    const [error, setError] = useState<string>();

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(t('application.checkoutAddAddress.errorMessage'));
        },
        true
    );

    const goToChooseAddress = () => {
        navigate(`./../${CHOOSE_ADDRESS_RELATIVE_PATH}`, { replace: true });
    };

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            // Refresh all addresses, because the primary address could have changed.
            warehouseAddressCollectionData.resolveFetchStatus();
            goToChooseAddress();
        },
        false
    );

    const handleSave = (address: Mutable<AddressModel>) => {
        warehouseAddressCollectionData.create(address);
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.checkoutAddAddress.pageTitle')}
                previousPageTitle={t('application.checkoutAddAddress.previousPageTitle')}
                onBackClick={goToChooseAddress}
                showCartIcon={false}
            />

            <FormLayout>
                <AddressForm error={error} onCancel={goToChooseAddress} onPrimary={handleSave} />
            </FormLayout>
        </>
    );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionStatus, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';
import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';

enum AlertStatus {
    NONE = 'NONE',
    CREATED = 'CREATED',
    DELETED = 'DELETED',
}

export const ListingAlertNotification = () => {
    const { t } = useTranslation('translation');
    const listingAlertCollectionData = useListingBuyerAlertCollectionData();

    const linkObj = {
        label: t('application.listingAlertNotification.link'),
        to: '/my-alerts',
    };

    const [status, setStatus] = useState<AlertStatus>(AlertStatus.NONE);

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            setStatus(AlertStatus.CREATED);
        },
        false
    );

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            setStatus(AlertStatus.DELETED);
        },
        false
    );

    switch (status) {
        case AlertStatus.CREATED:
            return (
                <Notification
                    key="notification-created"
                    link={linkObj}
                    stayOpen
                    closeButton
                    level={NotificationLevel.SUCCESS}
                    message={t('application.listingAlertNotification.createSuccess')}
                />
            );
        case AlertStatus.DELETED:
            return (
                <Notification
                    key="notification-deleted"
                    closeButton
                    level={NotificationLevel.SUCCESS}
                    message={t('application.listingAlertNotification.deleteSuccess')}
                />
            );
        default:
            return (
                <Notification
                    key="notification-info"
                    link={linkObj}
                    closeButton
                    level={NotificationLevel.INFO}
                    message={t('application.listingAlertNotification.explanation')}
                />
            );
    }
};

// eslint-disable-next-line max-classes-per-file
import { CustomError as BaseCustomError } from 'ts-custom-error';

export class SimpleError extends BaseCustomError {
    constructor(
        name: string,
        message?: string,
        public status?: number,
        public timestamp?: string,
        public responseMessage?: string
    ) {
        super(message);
        Object.defineProperty(this, 'name', { value: name });
    }
}

export interface ErrorDetail {
    message: string;
}

export class DetailedError extends SimpleError {
    constructor(
        name: string,
        message?: string,
        public override status?: number,
        public override timestamp?: string,
        public details: Array<ErrorDetail> = []
    ) {
        super(name, message, status, timestamp);
    }
}

export class CustomError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string) {
        super('CustomError', message, status, timestamp);
    }
}

export class StoreError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string) {
        super('StoreError', message, status, timestamp);
    }
}

export class NotFoundError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('NotFoundError', message, status, timestamp, responseMessage);
    }
}

export class NetworkError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('NetworkError', message, status, timestamp, responseMessage);
    }
}

export class TimeoutError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('TimeoutError', message, status, timestamp, responseMessage);
    }
}

export class RateLimitError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('RateLimitError', message, status, timestamp, responseMessage);
    }
}

export class ApiError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('ApiError', message, status, timestamp, responseMessage);
    }
}

export class ValidationError extends DetailedError {
    constructor(message?: string, status?: number, timestamp?: string, details?: Array<ErrorDetail>) {
        super('ValidationError', message, status, timestamp, details);
    }
}

export class AuthenticationRequiredError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('AuthenticationRequiredError', message, status, timestamp, responseMessage);
    }
}

export class AuthenticationFailedError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('AuthenticationFailedError', message, status, timestamp, responseMessage);
    }
}

export class AccessDeniedError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('AccessDeniedError', message, status, timestamp, responseMessage);
    }
}

export class WebSocketError extends SimpleError {
    constructor(message?: string, status?: number, timestamp?: string, responseMessage?: string) {
        super('WebSocketError', message, status, timestamp, responseMessage);
    }
}

import { AddressCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AddressModel, UserRole } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCollectionSlice,
    createSelectCollection,
    createSelectCollectionEntity,
    createSelectCreated,
} from '../../util/store/crud/CollectionSlice';
import { CollectionStore } from '../../util/store/crud/CollectionStore';
import { CollectionStoreAccessors } from '../../util/store/crud/CollectionStoreAccessor';
import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchAllThunk,
    createFetchNextThunk,
    createFetchOneThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const warehouseAddressCollectionStoreName = 'warehouse-address-collection-crud';
export type WarehouseAddressCollectionStore = {
    [warehouseAddressCollectionStoreName]: CollectionStore<AddressModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => {
    if (roles?.includes(UserRole.MANAGER)) {
        return new AddressCrudApiClient('manager').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.BUYER)) {
        return new AddressCrudApiClient('buyer').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.SELLER)) {
        return new AddressCrudApiClient('seller').init(apiBaseUrl, jsonWebToken);
    }

    return new AddressCrudApiClient('universal').init(apiBaseUrl, jsonWebToken);
};
const fetchAll = createFetchAllThunk<AddressModel, WarehouseAddressCollectionStore, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const fetchNext = createFetchNextThunk<AddressModel, WarehouseAddressCollectionStore, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const fetchOne = createFetchOneThunk<AddressModel, WarehouseAddressCollectionStore, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<AddressModel, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const createEntity = createCreateThunk<AddressModel, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<AddressModel, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<AddressModel, AddressCrudApiClient>(
    warehouseAddressCollectionStoreName,
    createApiClient
);

// Slice definition
export const warehouseAddressCollectionSlice = createCollectionSlice<AddressModel>(
    warehouseAddressCollectionStoreName,
    fetchAll,
    fetchNext,
    fetchOne,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetFetchStatus, resetActionStatus } = warehouseAddressCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<AddressModel, WarehouseAddressCollectionStore>(
    warehouseAddressCollectionStoreName
);
const selectEntity = createSelectCollectionEntity<AddressModel, WarehouseAddressCollectionStore>(
    warehouseAddressCollectionStoreName
);
const selectCreated = createSelectCreated<AddressModel, WarehouseAddressCollectionStore>(
    warehouseAddressCollectionStoreName
);

// Export the store accessors to be used by the data provider value
export const warehouseAddressCollectionStoreAccessors = {
    fetchAll,
    fetchNext,
    fetchOne,
    createEntity,
    mutateEntity,
    deleteEntity,
    resetFetchStatus,
    resetActionStatus,
    selectCollection,
    selectEntity,
    selectCreated,
    entityService,
} as CollectionStoreAccessors<AddressModel, WarehouseAddressCollectionStore>;

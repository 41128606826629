import './CardContentAddressItem.scss';

export type CardContentAddressItemProps = {
    organisation: string;
    name?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    country: string;
    postalCode: string;
    phoneNumber?: string;
};

export const CardContentAddressItem = (props: CardContentAddressItemProps) => {
    const { organisation, name, addressLine1, addressLine2, city, country, postalCode, phoneNumber } = props;

    return (
        <article className="card-content-address-item">
            <span>{organisation}</span>
            <span>{name}</span>
            <span>{addressLine1}</span>
            {addressLine2 && <span>{addressLine2}</span>}
            <span>{city}</span>
            <span>{country}</span>
            <span>{postalCode}</span>
            <span>{phoneNumber}</span>
        </article>
    );
};

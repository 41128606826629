import { Country } from '@steelbuy/domain-model';
import { CountryIcon } from '../country-icon/CountryIcon';
import './MaterialLocation.scss';

type MaterialLocationProps = {
    label: string;
    flag: Country;
    value: string;
};

export const MaterialLocation = (props: MaterialLocationProps) => {
    const { flag, value, label } = props;

    return (
        <div className="material-location">
            <span className="material-location__label"> {label} </span>
            <div className="material-location__value">
                <span className="material-location__value-country"> {value}</span>
                <CountryIcon code={flag} />
            </div>
        </div>
    );
};

import { useEffect, useLayoutEffect, useState } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { NavigationFooter } from './navigation-footer/NavigationFooter';
import { NavigationHeader } from './navigation-header/NavigationHeader';
import { NavigationItemGroup } from './navigation-item-group/NavigationItemGroup';

import './Navigation.scss';
import { NavigationContext } from './NavigationContext';

type NavigationProps = {
    isAuthenticated?: boolean;
};

export const Navigation = ({ isAuthenticated = true }: NavigationProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [defaultView, setDefaultView] = useState<boolean>(false);
    const [collapseOnNavigation, setCollapseOnNavigation] = useState<boolean>(false);

    const navigationClassMap = {
        navigation: true,
        'navigation--is-collapsed': isCollapsed,
        'navigation--is-anonymous': !isAuthenticated,
    };

    // set initial states
    useLayoutEffect(() => {
        if (window.innerWidth > 1006) {
            setDefaultView(true);
            setIsCollapsed(false);
        } else {
            setCollapseOnNavigation(true);
        }
    }, []);

    // toggle states when resizing viewport
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1006) {
                setIsCollapsed(false);
                setDefaultView(true);
            } else {
                setIsCollapsed(true);
                setDefaultView(false);
            }

            if (window.innerWidth > 800) {
                setCollapseOnNavigation(false);
            } else {
                setCollapseOnNavigation(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <NavigationContext.Provider
            value={{
                isCollapsed,
                setIsCollapsed,
                isAuthenticated,
                defaultView,
                setDefaultView,
                collapseOnNavigation,
                setCollapseOnNavigation,
            }}
        >
            <nav className={buildClassStringFromClassMap(navigationClassMap)} role="navigation">
                <NavigationHeader />
                {isAuthenticated && (
                    <div className="navigation-item-group__wrapper">
                        <NavigationItemGroup />
                    </div>
                )}
                <NavigationFooter />
            </nav>
        </NavigationContext.Provider>
    );
};

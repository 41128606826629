import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { CustomLink, ServiceHub } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../../router/Routes';
import { TableLayout } from '../../../views/layouts/table-layout/TableLayout';
import { ScrollBehaviourControl } from '../../scroll-behaviour-control/ScrollBehaviourControl';
import { AppFooter } from '../app-footer/AppFooter';

import './AppMainLayout.scss';

export type AppMainLayoutProps = {
    children: ReactNode;
};
const src = '/assets/img/cameron_avatar.jpeg';

export const APP_MAIN_LAYOUT_ID = 'app-main-layout';

export const scrollMainLayout = (x: number, y: number) => {
    document.getElementById(APP_MAIN_LAYOUT_ID)?.scrollTo(x, y);
};

export const getScrollTopMainLayout = () => document.getElementById(APP_MAIN_LAYOUT_ID)?.scrollTop || 0;

const fitContentPaths: string[] = [
    RoutePath.CREATE_RFQ_STEP2,
    RoutePath.CREATE_RFQ_SUMMARY,
    RoutePath.MY_RFQS_DETAILS,
    RoutePath.CREATE_DRAFT_RFQ_STEP2,
];

export const AppMainLayout = (props: AppMainLayoutProps) => {
    const { children } = props;
    const { t } = useTranslation('translation');

    const scrollContainerRef = useRef<HTMLElement>(null);
    const { pathname } = useLocation();

    return (
        <main className="app-main-layout" ref={scrollContainerRef} id={APP_MAIN_LAYOUT_ID}>
            <ScrollBehaviourControl scrollContainerRef={scrollContainerRef} />
            <div
                className={classNames('app-main-layout__content', {
                    'app-main-layout__content__fit-content': !!fitContentPaths.find(
                        (path: string) => !!matchPath(path, pathname)
                    ),
                })}
            >
                {children}
            </div>
            <ServiceHub imgSrc={src} mailAddress="support@steel-buy.com" />
            <TableLayout>
                <AppFooter>
                    <CustomLink path={RoutePath.CONTACT_DETAILS}>
                        {t('application.footer.contactDetailsLinkText')}
                    </CustomLink>
                    <CustomLink path={RoutePath.PRIVACY_POLICY}>
                        {t('application.footer.privacyPolicyLinkText')}
                    </CustomLink>
                    <CustomLink path={RoutePath.TERMS_AND_CONDITIONS}>
                        {t('application.footer.termsLinkText')}
                    </CustomLink>
                    <CustomLink path={RoutePath.ACCEPTABLE_USE_POLICY}>
                        {t('application.footer.acceptableUseLinkText')}
                    </CustomLink>
                </AppFooter>
            </TableLayout>
        </main>
    );
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmailAddress } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';

import { useUserSelfEntityData } from '@steelbuy/data-provider';
import { LoadingSpinnerOverlay } from '@steelbuy/ui-primitive';
import { constants } from '../../constants';
import { RoutePath } from '../../router/Routes';

export const AccountEmailValidation = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const auth = useAuth();
    const { token } = useParams();
    const userEntityData = useUserSelfEntityData();

    useEffect(() => {
        if (!token) {
            navigate(RoutePath.ACCOUNT, {
                state: {
                    errorNotification: t('application.accountValidateEmail.unableToUpdateEmail'),
                },
            });
            return;
        }
        (async () => {
            try {
                await validateEmailAddress(auth, constants.apiBaseUrl, token);
                userEntityData.resolveFetchStatus();
                navigate(RoutePath.ACCOUNT, {
                    state: {
                        successNotification: t('application.accountValidateEmail.emailUpdated'),
                    },
                });
            } catch (e) {
                navigate(RoutePath.ACCOUNT, {
                    state: {
                        errorNotification: t('application.accountValidateEmail.unableToUpdateEmail'),
                    },
                });
            }
        })();
    }, []);

    return <LoadingSpinnerOverlay />;
};

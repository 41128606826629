import { useEffect, useState } from 'react';
import { useListingSellerDraftEntityData } from '@steelbuy/data-provider';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { CreateListing } from './CreateListing';
import { useCreateListingContext } from './CreateListingContext';

interface CreateListingDraftGuardProps {
    draftId: string;
}

/**
 * This component ensures the given draftId is loaded in CreateListingContext.
 */
export const CreateListingDraftGuard = ({ draftId }: CreateListingDraftGuardProps) => {
    const createListingContext = useCreateListingContext();
    const listingSellerDraftEntityData = useListingSellerDraftEntityData();
    const queriedDraft = listingSellerDraftEntityData.query().get();

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!queriedDraft) {
            return;
        }

        // Don't load draft if the context already contains data with the same id.
        if (createListingContext.draftId === queriedDraft.id) {
            setInitialized(true);
            return;
        }

        createListingContext.loadFromDraft(queriedDraft);
        setInitialized(true);
    }, [draftId, queriedDraft]);

    if (!initialized) {
        return <LoadingSpinner />;
    }

    return <CreateListing />;
};

import { OrganisationSelfCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { OrganisationModel, UserRole } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const organisationEntityStoreName = 'organisation-entity';
export type OrganisationEntityStore = {
    [organisationEntityStoreName]: EntityStore<OrganisationModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => {
    if (roles?.includes(UserRole.MANAGER)) {
        return new OrganisationSelfCrudApiClient('manager').init(apiBaseUrl, jsonWebToken);
    }

    return new OrganisationSelfCrudApiClient().init(apiBaseUrl, jsonWebToken);
};
const fetch = createFetchEntityThunk<OrganisationModel, OrganisationEntityStore, OrganisationSelfCrudApiClient>(
    organisationEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<OrganisationModel, OrganisationSelfCrudApiClient>(
    organisationEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<OrganisationModel, OrganisationSelfCrudApiClient>(
    organisationEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<OrganisationModel, OrganisationSelfCrudApiClient>(
    organisationEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<OrganisationModel, OrganisationSelfCrudApiClient>(
    organisationEntityStoreName,
    createApiClient
);

// Slice definition
export const organisationEntitySlice = createCrudEntitySlice<OrganisationModel>(
    organisationEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = organisationEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<OrganisationModel, OrganisationEntityStore>(organisationEntityStoreName);
const selectCreated = createSelectCreated<OrganisationModel, OrganisationEntityStore>(organisationEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<OrganisationModel, OrganisationEntityStore>(
    organisationEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const organisationEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<OrganisationModel, OrganisationEntityStore>;

import { createRef, useCallback, useEffect, useState } from 'react';
import { WizardBarItemStatus } from '@steelbuy/ui-primitive';
import { useCreateListingContext } from './CreateListingContext';

export enum StepVisitation {
    NEVER = 'NEVER',
    ACTIVE = 'ACTIVE',
    VISITED = 'VISITED',
}

export const useCreateListingStep = (stepId: string) => {
    const createListingContext = useCreateListingContext();
    const isActive = createListingContext.currentStepId === stepId;
    const formRef = createRef<HTMLFormElement>();

    const initiallyVisited =
        createListingContext.draftId !== undefined || createListingContext.getStepStatus(stepId) !== undefined;

    // StepVisitation.NEVER is used if a step was never active
    // StepVisitation.ACTIVE is used when a step is active
    // StepVisitation.VISITED is the final state and is used after a step has been inactivated (or a draft is loaded)
    const [stepVisitation, setStepVisitation] = useState<StepVisitation>(
        initiallyVisited ? StepVisitation.VISITED : StepVisitation.NEVER
    );

    const refreshStepStatus = useCallback(() => {
        if (!formRef.current) {
            return;
        }

        const formValid = formRef.current.checkValidity();
        const stepActiveOrVisited =
            stepVisitation === StepVisitation.ACTIVE || stepVisitation === StepVisitation.VISITED;

        if (stepActiveOrVisited && formValid) {
            createListingContext.setStepStatus(stepId, WizardBarItemStatus.SUCCESS);
        } else if (stepVisitation === StepVisitation.NEVER) {
            createListingContext.setStepStatus(stepId, WizardBarItemStatus.EMPTY);
        } else {
            createListingContext.setStepStatus(stepId, WizardBarItemStatus.INCOMPLETE);
        }
    }, [formRef, stepId, createListingContext.setStepStatus]);

    // refresh status initially to validate loaded data
    useEffect(() => {
        refreshStepStatus();
    }, [refreshStepStatus]);

    // refresh status for inactive step and update stepVisitation
    useEffect(() => {
        if (isActive) {
            if (stepVisitation === StepVisitation.NEVER) {
                setStepVisitation(StepVisitation.ACTIVE);
            }
        } else {
            refreshStepStatus();

            if (stepVisitation === StepVisitation.ACTIVE) {
                setStepVisitation(StepVisitation.VISITED);
            }
        }
    }, [isActive, stepVisitation, refreshStepStatus]);

    return { formRef, refreshStepStatus, stepVisitation };
};

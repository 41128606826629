export enum AddressType {
    WAREHOUSE = 'WAREHOUSE',
    INVOICE = 'INVOICE',
}

export enum Country {
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    CH = 'CH',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    ES = 'ES',
    FI = 'FI',
    FR = 'FR',
    GB = 'GB',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    LU = 'LU',
    NL = 'NL',
    NO = 'NO',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    RS = 'RS',
    SE = 'SE',
    SK = 'SK',
    TR = 'TR',
    VN = 'VN',
    TW = 'TW',
    KR = 'KR',
    IN = 'IN',
    JP = 'JP',
}

import { useCallback, useEffect, useRef } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Definition, MaterialType, Product, ProductType, Shape } from '@steelbuy/domain-model';
import { MaterialDefinitionSelection, MaterialProductSelection, MaterialProductTypeSelection } from '@steelbuy/form';
import { useModal } from '@steelbuy/modal-dialog';
import { ConfirmSelectionModal, MaterialShapeSelection, MaterialTypeSelection } from '@steelbuy/ui-domain';
import { WizardBarItemStatus, useWizardStepsContext } from '@steelbuy/ui-primitive';
import { entries } from '@steelbuy/util';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { RFQStep, getStepRoute, getAddedItemsIndexes, isStep2Valid } from '../createRFQUtil';
import { useRfqFormContext } from '../RfqFormContext';
import {
    isStep1WithDefinition,
    isStep1WithPlateType,
    isStep2,
    Step1SchemaWithDefinition,
    Step1SchemaWithPlateType,
    Step1Type,
    Step2Materials,
} from '../Schema';
import './Step1.scss';
import { CreateRFQActionBar } from '../step3/CreateRFQActionBar';

const disabledTypes = [MaterialType.ALUMINIUM, MaterialType.STAINLESS_STEEL];

interface SaveDraftContext {
    onSaveDraft: () => void;
    loadingState: boolean;
}

export const Step1 = () => {
    const { t } = useTranslation('translation');
    const confirmSelectionModal = useModal();
    const confirmSelectionRef = useRef<() => void>(() => null);
    const { formData, updateFormData, setUpdateValuesCallback, draftId, setAllowNavigation } = useRfqFormContext();
    const { onSaveDraft, loadingState } = useOutletContext<SaveDraftContext>();
    const {
        watch,
        setValue,
        control,
        handleSubmit,
        formState: { isValid, errors },
        getValues,
        clearErrors,
        trigger,
    } = useForm<Step1Type>({
        defaultValues: {
            materialType: MaterialType.MILD_STEEL,
            shape: Shape.FLAT,
            product: formData?.product || Product.COIL,
            definition: isStep1WithDefinition(formData) ? formData.definition : [],
            plateType: isStep1WithPlateType(formData) ? formData.plateType : [],
        },
        mode: 'all',
    });
    const navigate = useNavigate();
    const { updateStepStatus, isStepVisited, visitedSteps } = useWizardStepsContext();

    const onSubmit = handleSubmit(
        (data) => {
            updateFormData(data);
            updateStepStatus(RFQStep.CHOOSE_MATERIALS, WizardBarItemStatus.SUCCESS);
            navigate(getStepRoute(2, draftId));
        },
        (error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    );
    useEffect(() => {
        setUpdateValuesCallback((setStepStatus?: boolean) => {
            updateFormData(getValues());
            if (setStepStatus) {
                updateStepStatus(RFQStep.CHOOSE_MATERIALS, WizardBarItemStatus.SUCCESS);
            }
        });
    }, []);

    useEffect(() => {
        if (watch('product') !== formData?.product) {
            clearErrors(['definition', 'plateType']);
            setValue('definition', []);
            setValue('plateType', []);
            updateFormData({ materials: undefined as never });
        }
        if (draftId || isStepVisited(RFQStep.ADD_ITEMS)) {
            updateStepStatus(RFQStep.ADD_ITEMS, WizardBarItemStatus.INCOMPLETE);
            trigger();
        }
    }, [watch('product'), visitedSteps]);

    useEffect(() => {
        const definitions = watch('definition');
        const plateTypes = watch('plateType');
        setAllowNavigation(!!(definitions?.length || plateTypes?.length));
        if (isStep2(formData)) {
            const updatedMaterials = entries(formData.materials).reduce((acc, [key, value]) => {
                if (definitions.includes(key as Definition) || plateTypes.includes(key as ProductType)) {
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, {} as Step2Materials);
            updateFormData({
                materials: updatedMaterials,
            });

            if (draftId || isStepVisited(RFQStep.ADD_ITEMS)) {
                const isAddItemsValid = isStep2Valid({
                    ...formData,
                    materials: updatedMaterials,
                    definition: definitions,
                    plateType: plateTypes,
                });
                updateStepStatus(
                    RFQStep.ADD_ITEMS,
                    isAddItemsValid ? WizardBarItemStatus.SUCCESS : WizardBarItemStatus.INCOMPLETE
                );
            }
        }
    }, [watch('definition'), watch('plateType'), visitedSteps]);

    const showConfirmSelection = useCallback(
        (onConfirm: () => void) => {
            confirmSelectionRef.current = onConfirm;
            confirmSelectionModal.show();
        },
        [confirmSelectionModal]
    );

    const indexesWithAddedItems = getAddedItemsIndexes(formData);

    return (
        <>
            <div className="create-rfq-step1">
                <FormLayout>
                    <p className="create-rfq-step1__title">{t('application.createRFQ.chooseMaterial')}</p>
                    <form onSubmit={onSubmit}>
                        <Controller
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <MaterialTypeSelection
                                    materialType={value}
                                    materialTypeSelected={(materialType) => onChange(materialType)}
                                    isStainlessFeatureEnabled
                                    disabledTypes={disabledTypes}
                                />
                            )}
                            name="materialType"
                        />
                        <Controller
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <MaterialShapeSelection
                                    material={{
                                        materialType: watch('materialType'),
                                        shape: value,
                                    }}
                                    shapeSelected={(shape) => onChange(shape)}
                                />
                            )}
                            name="shape"
                        />
                        <MaterialProductSelection
                            material={{
                                materialType: watch('materialType'),
                                shape: watch('shape'),
                            }}
                            isPackagesSupported={false}
                            name="product"
                            control={control}
                            disabledItems={[Product.SHEET]}
                            addedIndexes={indexesWithAddedItems}
                            showConfirmSelection={showConfirmSelection}
                        />
                        <MaterialDefinitionSelection
                            control={control}
                            name="definition"
                            material={{
                                materialType: watch('materialType'),
                                shape: watch('shape'),
                                product: watch('product'),
                            }}
                            error={(errors as FieldErrors<Step1SchemaWithDefinition>).definition?.message}
                            addedIndexes={indexesWithAddedItems}
                            showConfirmSelection={showConfirmSelection}
                        />
                        <MaterialProductTypeSelection
                            control={control}
                            name="plateType"
                            material={{
                                materialType: watch('materialType'),
                                shape: watch('shape'),
                                product: watch('product'),
                            }}
                            isPrime
                            error={(errors as FieldErrors<Step1SchemaWithPlateType>).plateType?.message}
                            addedIndexes={indexesWithAddedItems}
                            showConfirmSelection={showConfirmSelection}
                        />
                        <CreateRFQActionBar
                            onNext={onSubmit}
                            isSubmitDisabled={!isValid}
                            nextLabel={t('application.createRFQ.wizardNextButtonLabel')}
                            onSaveDraft={onSaveDraft}
                            isLoading={loadingState}
                        />
                    </form>
                </FormLayout>
            </div>
            <ConfirmSelectionModal
                modal={confirmSelectionModal}
                onConfirm={() => {
                    confirmSelectionRef.current();
                    confirmSelectionModal.hide();
                }}
            />
        </>
    );
};

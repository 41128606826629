import { ButtonPrimary } from '../../button-primary/ButtonPrimary';
import { ButtonTertiaryOnLightM } from '../../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { IconIdentifier } from '../../icon/Icon.enums';

import './MarketingBannerDashboardSell.scss';

export type MarketingBannerDashboardSellProps = {
    header: string;
    text?: string;
    buttonLabel1: string;
    buttonLabel2: string;
    onClick1?: () => void;
    onClick2?: () => void;
};

export const MarketingBannerDashboardSell = (props: MarketingBannerDashboardSellProps) => {
    const { header, text, onClick1, onClick2, buttonLabel1, buttonLabel2 } = props;

    return (
        <article className="marketing-banner-dashboard-sell">
            <div className="marketing-banner-dashboard-sell__body">
                <div className="marketing-banner-dashboard-sell__body__content">
                    <header className="marketing-banner-dashboard-sell__body__content__header">{header}</header>
                    <div className="marketing-banner-dashboard-sell__body__content__text">{text}</div>
                </div>
                <div className="marketing-banner-dashboard-sell__body__actions">
                    <ButtonPrimary label={buttonLabel1} onClick={onClick1} icon={IconIdentifier.PLUS} />
                    <ButtonTertiaryOnLightM label={buttonLabel2} onClick={onClick2} />
                </div>
            </div>
            <img
                alt="A person putting an item into a box"
                src="/assets/lib-ui-primitive/images/ListingLive-Neutral.svg"
            />
        </article>
    );
};

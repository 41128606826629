import { BadgePrimary } from '../../badge-primary/BadgePrimary';

import './SearchSummaryResults.scss';

export type SearchSummaryResultsProps = {
    results: string;
};

export const SearchSummaryResults = (props: SearchSummaryResultsProps) => {
    const { results } = props;

    const renderResults = () => {
        if (results === undefined) {
            return null;
        }
        return (
            <span className="search-summary__results">
                <BadgePrimary label={results} />
            </span>
        );
    };

    return <>{renderResults()}</>;
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Product, getProducts, MaterialProperties } from '@steelbuy/domain-model';
import {
    FormItem,
    IconIdentifier,
    InputRadioButtonGroupIllustration,
    InputRadioButtonGroupIllustrationAlign,
    InputRadioButtonIllustration,
} from '@steelbuy/ui-primitive';

interface MaterialProductSelectionProps {
    material: MaterialProperties;
    productSelected: (product: Product) => void;
    isPackagesSupported: boolean;
    disabledProducts?: string[];
}

export const MaterialProductSelection = ({
    material,
    productSelected,
    isPackagesSupported,
    disabledProducts,
}: MaterialProductSelectionProps) => {
    const { t } = useTranslation('domainModel');
    const availableProducts = getProducts(material, isPackagesSupported);

    useEffect(() => {
        if (material.product === undefined || !availableProducts.includes(material.product)) {
            productSelected(availableProducts[0]);
        }
    }, [material.product, productSelected, availableProducts]);

    return (
        <FormItem header={t('material.product.name')}>
            <InputRadioButtonGroupIllustration align={InputRadioButtonGroupIllustrationAlign.ALIGN_HORIZONTAL}>
                {availableProducts.map((product) => (
                    <InputRadioButtonIllustration
                        icon={IconIdentifier[product]}
                        key={product}
                        name="materialProduct"
                        value={t(`material.product.value.${product}`)}
                        onChange={() => productSelected(product)}
                        checked={product === material.product}
                        disabled={disabledProducts?.includes(product)}
                    />
                ))}
            </InputRadioButtonGroupIllustration>
        </FormItem>
    );
};

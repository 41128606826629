import { useQuery } from '@tanstack/react-query';
import { AddressModel } from '@steelbuy/domain-model';
import axios from '../../../axios';
import { createFilterString } from '../../../utils/createFilterString';
import { createSortString } from '../../../utils/createSortString';
import { FilterCriteria, ResponseModel, SortCriteria } from '../../../utils/types';

interface GetAddressOptions {
    limit?: number;
    offset?: number;
    filter?: FilterCriteria<AddressModel>;
    sort?: SortCriteria<AddressModel>;
}

const getAddress = async ({ limit = 100, offset = 0, filter, sort }: GetAddressOptions) => {
    const { data } = await axios.get<ResponseModel<AddressModel[]>>('/api/universal/addresses', {
        params: {
            limit,
            offset,
            ...(filter && { filter: createFilterString(filter) }),
            ...(sort && { sort: createSortString(sort) }),
        },
    });
    return data.items;
};

export const useGetAddress = (options: GetAddressOptions) =>
    useQuery({
        queryKey: ['Address', JSON.stringify(options)],
        queryFn: () => getAddress(options),
    });

import { PropsWithChildren } from 'react';
import './LoginLayout.scss';

export const LoginLayout = ({ children }: PropsWithChildren) => (
    <div className="full-page-layout">
        <div className="login-layout">
            <img
                className="login-layout__logo"
                alt="SteelBuy Logo"
                src="/assets/lib-ui-primitive/images/steelbuy-logo_vertical_snow.svg"
            />
            {children}
        </div>
    </div>
);

import { Optional, Potentially } from '@steelbuy/types';

import {
    createApiTimestampFromTimestamp,
    createTimestampFromApiTimestamp,
    createTimestampFromDate,
} from './Timestamp.util';
import { ApiUploadFile, ApiUploadFileMetaData, UploadFile, UploadFileMetaData } from './UploadFile';
import { isApiUploadFile, isApiUploadFileMetaData, isUploadFile, isUploadFileMetaData } from './UploadFile.guards';

const createApiUploadFileMetaDataFromUploadFileMetaData = (
    fileMeta: Potentially<UploadFileMetaData>
): Optional<ApiUploadFileMetaData> => {
    let apiFileMetaData = null;
    if (isUploadFileMetaData(fileMeta)) {
        apiFileMetaData = {
            ...fileMeta,
            lastModifiedDate: createApiTimestampFromTimestamp(fileMeta.lastModifiedDate).get(),
        } as ApiUploadFileMetaData;
    }
    return new Optional<ApiUploadFileMetaData>(apiFileMetaData);
};

const createUploadFileMetaDataFromApiUploadFileMetaData = (
    apiFileMeta: Potentially<ApiUploadFileMetaData>
): Optional<UploadFileMetaData> => {
    let fileMetaData = null;
    if (isApiUploadFileMetaData(apiFileMeta)) {
        fileMetaData = {
            ...apiFileMeta,
            lastModifiedDate: createTimestampFromApiTimestamp(apiFileMeta.lastModifiedDate).get(),
        } as UploadFileMetaData;
    }
    return new Optional<UploadFileMetaData>(fileMetaData);
};

export const createApiUploadFileFromUploadFile = (file: Potentially<UploadFile>): Optional<ApiUploadFile> => {
    let apiFile = null;
    if (isUploadFile(file)) {
        apiFile = {
            documentId: file.documentId,
        } as ApiUploadFile;
    }
    return new Optional<ApiUploadFile>(apiFile);
};

export const createUploadFileFromApiUploadFile = (apiFile: Potentially<ApiUploadFile>): Optional<UploadFile> => {
    let file = null;
    if (isApiUploadFile(apiFile)) {
        file = {
            discriminator: 'UploadFile',
            documentId: apiFile.documentId,
            meta: createUploadFileMetaDataFromApiUploadFileMetaData(apiFile.meta).get(),
        } as UploadFile;
    }
    return new Optional<UploadFile>(file);
};

const createUploadFileMetaDataFromFile = (file: File): UploadFileMetaData =>
    ({
        fileName: file.name,
        size: file.size,
        lastModifiedDate: createTimestampFromDate(new Date(file.lastModified)),
    } as UploadFileMetaData);

export const createUploadFileFromFile = (documentId: string, file: File): UploadFile => ({
    discriminator: 'UploadFile',
    documentId,
    meta: createUploadFileMetaDataFromFile(file),
});

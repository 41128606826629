import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { AddressType, Country } from './AddressModel.enums';

import { OrganisationModel, OrganisationModelConverter } from './OrganisationModel';

export type AddressModel = {
    readonly id: ModelPrimaryKey;
    readonly organisation: OrganisationModel;
    addressType: AddressType;
    name: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postalCode: string;
    country: Country;
    primary: boolean;
    phone?: string;
};

export class AddressModelConverter extends ModelConverter<AddressModel> {
    override getFieldConverterMapByModel(): FieldConverterMap<AddressModel> {
        return {
            organisation: new OrganisationModelConverter(),
        };
    }
}

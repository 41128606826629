import { Link } from 'react-router-dom';

import { FooCreateEntityDataProvider } from '@steelbuy/data-provider';

import { FooCreateEntity } from '../components/foo-create-entity/FooCreateEntity';
import { RoutePath } from '../router/Routes';

export const FooCreateEntityView = () => (
    <FooCreateEntityDataProvider apiMock>
        <Link to={RoutePath.FOOS}>Overview</Link>
        <hr />
        <FooCreateEntity />
    </FooCreateEntityDataProvider>
);

import { Trans, useTranslation } from 'react-i18next';
import { useUserSelf } from '@steelbuy/data-provider';
import { InputCheckbox, CustomLink } from '@steelbuy/ui-primitive';
import { useCheckoutContext } from './CheckoutContext';
import { RoutePath } from '../../router/Routes';

const CheckoutCheckboxes = () => {
    const { t } = useTranslation('translation');
    const userData = useUserSelf().get();
    const checkoutContext = useCheckoutContext();

    return (
        <>
            <InputCheckbox
                data-testid="authorised-checkbox"
                label={
                    <>
                        {' '}
                        {t('application.checkout.checkbox1Label', {
                            organisationName: userData?.organisation?.name ?? 'my organisation',
                        })}
                    </>
                }
                checked={checkoutContext.agreedAuthorised}
                onChange={(checked) => checkoutContext.setAgreedAuthorised(checked)}
            />
            <InputCheckbox
                data-testid="tnc-checkbox"
                label={
                    <>
                        {' '}
                        <Trans
                            i18nKey="application.checkout.checkbox2Label"
                            components={{
                                websitetncs: (
                                    <CustomLink
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        isInlineLink
                                        path={RoutePath.TERMS_WEBSITE}
                                    />
                                ),
                                buyertncs: (
                                    <CustomLink
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        isInlineLink
                                        path={RoutePath.TERMS_BUYER}
                                    />
                                ),
                            }}
                            t={t}
                            values={{ organisationName: userData?.organisation?.name }}
                        />
                    </>
                }
                checked={checkoutContext.agreedTermsAndConditions}
                onChange={(checked) => checkoutContext.setAgreedTermsAndConditions(checked)}
            />
        </>
    );
};

export default CheckoutCheckboxes;

import { ReactNode } from 'react';

import './AccordionGroup.scss';

export type AccordionGroupProps = {
    heading?: string;
    children: ReactNode;
};

export const AccordionGroup = (props: AccordionGroupProps) => {
    const { children, heading } = props;

    const renderHeading = () => {
        if (heading === undefined) {
            return null;
        }
        return <div className="accordion-group__heading">{heading}</div>;
    };

    return (
        <div className="accordion-group">
            {renderHeading()}
            {children}
        </div>
    );
};

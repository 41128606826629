import { useNavigate } from 'react-router-dom';
import { useIsFeatureSupported, userHasRole, useUserSelf } from '@steelbuy/data-provider';
import { Feature, UserRole } from '@steelbuy/domain-model';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { MyRFQs } from '../../components/my-rfqs/MyRFQs';
import { RoutePath } from '../../router/Routes';

export const MyRFQsView = () => {
    const navigate = useNavigate();
    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showRfq = useIsFeatureSupported(Feature.RFQ) && hasBuyerRole;

    if (!showRfq) {
        navigate(RoutePath.DASHBOARD);
    }
    return (
        <SuccessErrorNotificationContextProvider>
            <MyRFQs />
        </SuccessErrorNotificationContextProvider>
    );
};

import { Optional, Potentially } from '@steelbuy/types';

import { ApiDownloadFile, ApiDownloadFileMetaData, DownloadFile, DownloadFileMetaData } from './DownloadFile';
import {
    isApiDownloadFile,
    isApiDownloadFileMetaData,
    isDownloadFile,
    isDownloadFileMetaData,
} from './DownloadFile.guards';
import { createApiTimestampFromTimestamp, createTimestampFromApiTimestamp } from './Timestamp.util';
import { createApiUrlFromUrl, createUrlFromApiUrl } from './Url.util';

const createDownloadFileMetaDataFromApiDownloadFileMetaData = (
    apiFileMeta: Potentially<ApiDownloadFileMetaData>
): Optional<DownloadFileMetaData> => {
    let fileMetaData = null;
    if (isApiDownloadFileMetaData(apiFileMeta)) {
        fileMetaData = {
            ...apiFileMeta,
            lastModifiedDate: createTimestampFromApiTimestamp(apiFileMeta.lastModifiedDate).get(),
        } as DownloadFileMetaData;
    }
    return new Optional<DownloadFileMetaData>(fileMetaData);
};

const createApiDownloadFileMetaDataFromDownloadFileMetaData = (
    fileMeta: Potentially<DownloadFileMetaData>
): Optional<ApiDownloadFileMetaData> => {
    let apiFileMetaData = null;
    if (isDownloadFileMetaData(fileMeta)) {
        apiFileMetaData = {
            ...fileMeta,
            lastModifiedDate: createApiTimestampFromTimestamp(fileMeta.lastModifiedDate).get(),
        } as ApiDownloadFileMetaData;
    }
    return new Optional<ApiDownloadFileMetaData>(apiFileMetaData);
};

export const createDownloadFileFromApiDownloadFile = (
    apiFile: Potentially<ApiDownloadFile>
): Optional<DownloadFile> => {
    let file = null;
    if (isApiDownloadFile(apiFile)) {
        file = {
            discriminator: 'DownloadFile',
            url: createUrlFromApiUrl(apiFile.url).get(),
            meta: createDownloadFileMetaDataFromApiDownloadFileMetaData(apiFile.meta).get(),
        } as DownloadFile;
    }
    return new Optional<DownloadFile>(file);
};

export const createApiDownloadFileFromDownloadFile = (file: Potentially<DownloadFile>): Optional<ApiDownloadFile> => {
    let apiFile = null;
    if (isDownloadFile(file)) {
        apiFile = {
            url: createApiUrlFromUrl(file.url).get(),
            meta: createApiDownloadFileMetaDataFromDownloadFileMetaData(file.meta).get(),
        } as ApiDownloadFile;
    }
    return new Optional<ApiDownloadFile>(apiFile);
};

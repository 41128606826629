import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isStackableProduct, MaterialType, Product, stackableProducts } from '@steelbuy/domain-model';
import { FormItem, InputTextfield } from '@steelbuy/ui-primitive';
import { useNumberOfItemsLabel } from '../number-of-items-label/NumberOfItemsLabel';
import { RequiredFieldValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

type NumberOfItemsInputProps = {
    product: Product;
    numberOfItems?: number;
    setNumberOfItems: (newItems?: number) => void;
    materialType: MaterialType;
    label?: string;
    show?: boolean;
} & RequiredFieldValidationProps;

export const NumberOfItemsInput = (props: NumberOfItemsInputProps) => {
    const { product, numberOfItems, setNumberOfItems, materialType, forceValidation, label, show = true } = props;
    const { t } = useTranslation('uiDomain');
    const numberOfItemsLabel = useNumberOfItemsLabel(product);
    const requiredFieldValidator = useRequiredFieldValidator();

    useEffect(() => {
        if (numberOfItems && !isStackableProduct(product)) {
            setNumberOfItems(undefined);
        }
    }, [product, numberOfItems, setNumberOfItems]);

    if (!isStackableProduct(product) || !show) {
        return null;
    }

    const getFormItemLabel = (eligibleProduct: typeof stackableProducts[number]): string => {
        const isNumberInPackage = materialType === MaterialType.MILD_STEEL;

        if (
            eligibleProduct === Product.SHEET ||
            eligibleProduct === Product.PLATE ||
            eligibleProduct === Product.TREAD
        ) {
            return t(`numberOfItemsInput.${isNumberInPackage ? 'itemLabelInPackage' : 'itemLabel'}.${eligibleProduct}`);
        }
        return '';
    };

    return (
        <FormItem header={numberOfItemsLabel}>
            <InputTextfield
                pattern="[0-9]*"
                label={label || getFormItemLabel(product)}
                name="numberOfItems"
                value={numberOfItems ?? ''}
                onChange={(value) => {
                    const parsedValue = Number.parseInt(value, 10);
                    setNumberOfItems(Number.isInteger(parsedValue) ? parsedValue : undefined);
                }}
                validators={[requiredFieldValidator]}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};

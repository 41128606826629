import { ReactNode } from 'react';
import './AppFooter.scss';

export type AppFooterProps = {
    children: ReactNode;
};

export const FOOTER_HEIGHT = 108;

export const AppFooter = (props: AppFooterProps) => {
    const { children } = props;
    return <footer className="app-footer">{children}</footer>;
};

import { stainlessFlatGrades, stainlessFlatMillFinish, stainlessFlatPolish } from './flat/StainlessFlatMaterialModel';
import { stainlessFlatProduct } from './flat/StainlessFlatProduct';
import {
    stainlessFlatSheetCoatings,
    stainlessFlatSheetProtectiveThickness,
    stainlessFlatSheetProtectiveColor,
    stainlessFlatSheetProtectiveType,
    stainlessFlatSheetProtectiveCoverage,
} from './flat/StainlessFlatSheet';
import { stainlessFlatTreadGrades } from './flat/StainlessFlatTreadPlate';
import { stainlessShape } from './StainlessShape';
import { stainlessTolerances } from './StainlessTolerance';
import {
    Coating,
    CoatingThicknessUnit,
    PricingUnit,
    Product,
    Shape,
    TradeUnit,
    TradeUnitCalculation,
} from '../../MaterialModel.enums';
import { DimensionsWithRanges, SearchFields, SearchFormData } from '../../SearchFormData';
import { AbstractMaterialTaxonomy, otherFilter } from '../AbstractMaterialTaxonomy';
import { getMinimumTargetPriceForAluminiumAndStainless } from '../common/MaterialUtil';
import { Dimensions, MaterialProperties } from '../MaterialProperties';

const hasCoatingProperties = (material: MaterialProperties) =>
    material.product === Product.SHEET && material.coating === Coating.PROTECTIVE_COATING;

const DENSITY = 7.85 / 1000000;

export class StainlessTaxonomy extends AbstractMaterialTaxonomy {
    override getShapes() {
        return stainlessShape;
    }

    override getProducts(material: MaterialProperties, isPackagesSupported = false) {
        if (material.shape === Shape.FLAT) {
            if (!isPackagesSupported) {
                return super.getFilteredProducts(stainlessFlatProduct, [Product.PACKAGE]);
            }
            return stainlessFlatProduct;
        }
        return super.getProducts(material, isPackagesSupported);
    }

    override getGrades(material: MaterialProperties) {
        switch (material.product) {
            case Product.COIL:
            case Product.PLATE:
            case Product.SHEET:
                return stainlessFlatGrades;
            case Product.TREAD:
                return stainlessFlatTreadGrades;
            default:
                return super.getGrades(material);
        }
    }

    override getMillFinishes(material: MaterialProperties) {
        if (material.product && material.product !== Product.PACKAGE) {
            return stainlessFlatMillFinish;
        }
        return super.getMillFinishes(material);
    }

    override getPolishes(material: MaterialProperties) {
        if (material.product && material.product !== Product.PACKAGE) {
            return stainlessFlatPolish;
        }
        return super.getPolishes(material);
    }

    override getCoatings(material: MaterialProperties) {
        if (material.product !== Product.SHEET) {
            return super.getCoatings(material);
        }
        return stainlessFlatSheetCoatings;
    }

    override getCoatingThickness(material: MaterialProperties, excludeOther?: boolean) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingThickness(material);
        }
        if (!excludeOther) {
            return stainlessFlatSheetProtectiveThickness;
        }
        return stainlessFlatSheetProtectiveThickness.filter(otherFilter);
    }

    override getCoatingColours(material: MaterialProperties, excludeOther?: boolean) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingColours(material);
        }
        if (!excludeOther) {
            return stainlessFlatSheetProtectiveColor;
        }
        return stainlessFlatSheetProtectiveColor.filter(otherFilter);
    }

    override getCoatingTypes(material: MaterialProperties) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingTypes(material);
        }
        return stainlessFlatSheetProtectiveType;
    }

    override getCoatingCoverages(material: MaterialProperties) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingCoverages(material);
        }
        return stainlessFlatSheetProtectiveCoverage;
    }

    override getTradeUnit() {
        return TradeUnit.KILOGRAM;
    }

    override getTradeUnitCalculation(material: MaterialProperties) {
        return material.product === Product.COIL || material.product === Product.PACKAGE
            ? TradeUnitCalculation.BY_WEIGHT
            : TradeUnitCalculation.BY_ITEM;
    }

    override getPricingUnit(material: MaterialProperties) {
        switch (material.product) {
            case Product.SHEET:
                return PricingUnit.SHEET;
            case Product.PLATE:
                return PricingUnit.PLATE;
            case Product.TREAD:
                return PricingUnit.TREAD;
            case Product.COIL:
            case Product.PACKAGE:
                return PricingUnit.KILOGRAM;
            default:
                return PricingUnit.KILOGRAM;
        }
    }

    override getTolerances() {
        return stainlessTolerances;
    }

    override getCoatingThicknessUnit() {
        return CoatingThicknessUnit.MICRON;
    }

    override getUrgentRequestMandatoryFields(searchFormData: SearchFormData) {
        const fields: SearchFields = ['materialType', 'shape', 'product', 'thickness', 'width', 'weight', 'grade'];
        if (
            searchFormData.product === Product.SHEET ||
            searchFormData.product === Product.PLATE ||
            searchFormData.product === Product.TREAD
        ) {
            fields.push('length');
        }
        return fields;
    }

    override getTheoreticalWeight(material: MaterialProperties, dimensions: Dimensions, numberOfItems?: number) {
        if (this.getTradeUnitCalculation(material) === TradeUnitCalculation.BY_WEIGHT) {
            return undefined;
        }
        return super.getTheorericalWeightForDensity(dimensions, numberOfItems, DENSITY);
    }

    override getMaxTheoreticalWeight(
        material: MaterialProperties,
        dimensions: DimensionsWithRanges,
        numberOfItems?: number
    ): number | undefined {
        return this.getTheoreticalWeight(material, super.getMaxDimensions(dimensions), numberOfItems);
    }

    override getMinimumTargetPrice(material: MaterialProperties, weight: number, numberOfItems: number | undefined) {
        const minimumOrderValue = 41;
        const tradeUnitCalculation = this.getTradeUnitCalculation(material);
        return getMinimumTargetPriceForAluminiumAndStainless(
            tradeUnitCalculation,
            minimumOrderValue,
            weight,
            numberOfItems
        );
    }

    override showNumberOfItemsForIMR(material: MaterialProperties): boolean {
        return super.showNumberOfItemsForIMR(material);
    }
}

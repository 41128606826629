import { FormEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCode } from '@steelbuy/currency';
import { useFeatureFlag } from '@steelbuy/data-provider';
import {
    Coating,
    Finish,
    Grade,
    Product,
    Shape,
    MaterialType,
    Definition,
    ProductType,
    SearchFormData,
    Specification,
    Surface,
    Temper,
    MaterialProperties,
    CoatingType,
    CoatingCoverage,
    CoatingColourType,
    getMillFinishes,
    getPolishes,
    MillFinish,
    Polish,
    CoatingThicknessType,
    SearchFields,
    getTradeUnit,
    getPricingUnit,
    getMinimumTargetPrice,
    Feature,
    getTradeUnitCalculation,
    TradeUnitCalculation,
    getMaxTheoreticalWeight,
    showNumberOfItemsForIMR,
} from '@steelbuy/domain-model';

import {
    AnyValidationProps,
    MaterialAdditionalComment,
    MaterialCoatingSelection,
    MaterialDimensionSearchSelection,
    MaterialDimensionSearchSpecification,
    MaterialFinishSelection,
    MaterialGenericSelection,
    MaterialGradeSelection,
    MaterialPriceInput,
    MaterialProductDefinitionSelection,
    MaterialProductSelection,
    MaterialProductTypeSelection,
    MaterialShapeSelection,
    MaterialSpecificationSelection,
    MaterialSurfaceSelection,
    MaterialTemperSelection,
    MaterialTypeSelection,
    MaterialWeightInput,
    NumberOfItemsInput,
    useMinNumberValidator,
} from '@steelbuy/ui-domain';

import {
    ButtonPrimary,
    Form,
    FormActionbar,
    IconIdentifier,
    LoadingStatus,
    Notification,
    NotificationLevel,
    useForm,
} from '@steelbuy/ui-primitive';

import { useSearchFormDataContext } from './SearchFormDataContext';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../page-header/PageHeader';

import './ListingSearchForm.scss';

const coatingFields: SearchFields = [
    'coating',
    'coatingColour',
    'coatingThicknessValue',
    'coatingType',
    'coatingCoverage',
];

const maxLength = 70;

type ListingSearchFormInputsProps = {
    setData: (data: SearchFormData) => void;
    getMandatoryFields?: (searchFormData: SearchFormData) => SearchFields;
    submitButtonLabel: string;
    forceValidation?: boolean;
    isLoading?: boolean;
    showSubmitIcon?: boolean;
    isIMR?: boolean;
};

const ListingSearchFormInputs = ({
    setData,
    getMandatoryFields,
    submitButtonLabel,
    forceValidation = false,
    isLoading = false,
    showSubmitIcon = true,
    isIMR,
}: ListingSearchFormInputsProps) => {
    const { t } = useTranslation('translation');
    const searchFormDataContext = useSearchFormDataContext();
    const searchFormData = searchFormDataContext.getSearchFormData();
    const { isValid, validate } = useForm();
    const [materialType, setMaterialType] = useState<MaterialType>(searchFormData.materialType);
    const [shape, setShape] = useState<Shape>(searchFormData.shape);
    const [product, setProduct] = useState<Product>(searchFormData.product);
    const [definition, setDefinition] = useState<Definition | undefined>(searchFormData.definition);
    const [plateType, setPlateType] = useState<ProductType | undefined>(searchFormData.plateType);
    const [grade, setGrade] = useState<Grade | undefined>(searchFormData.grade);
    const [specification, setSpecification] = useState<Specification | undefined>(searchFormData.specification);
    const [surface, setSurface] = useState<Surface | undefined>(searchFormData.surface);
    const [dimensions, setDimensions] = useState<MaterialDimensionSearchSpecification>({
        thickness: searchFormData.thickness,
        width: searchFormData.width,
        minWidth: searchFormData.minWidth,
        maxWidth: searchFormData.maxWidth,
        length: searchFormData.length,
        minLength: searchFormData.minLength,
        maxLength: searchFormData.maxLength,
    });
    const [finish, setFinish] = useState<Finish | undefined>(searchFormData.finish);
    const [millFinish, setMillFinish] = useState<MillFinish | undefined>(searchFormData.millFinish);
    const [polish, setPolish] = useState<Polish | undefined>(searchFormData.polish);
    const [coating, setCoating] = useState<Coating | undefined>(searchFormData.coating);
    const [coatingThicknessValue, setCoatingThicknessValue] = useState<CoatingThicknessType | undefined>(
        searchFormData.coatingThicknessValue
    );
    const [coatingColour, setCoatingColour] = useState<CoatingColourType | undefined>(searchFormData.coatingColour);
    const [coatingType, setCoatingType] = useState<CoatingType | undefined>(searchFormData.coatingType);
    const [coatingCoverage, setCoatingCoverage] = useState<CoatingCoverage | undefined>(searchFormData.coatingCoverage);
    const [temper, setTemper] = useState<Temper | undefined>(searchFormData.temper);
    const [weight, setWeight] = useState<number | undefined>();
    const [targetPrice, setTargetPrice] = useState<number | undefined>();
    const [comment, setComment] = useState<string | undefined>(searchFormData.comment);
    const [numberOfItems, setNumberOfItems] = useState<number | undefined>();

    const [isWidthRange, setIsWidthRange] = useState(false);
    const [isLengthRange, setIsLengthRange] = useState(false);

    const data = {
        materialType,
        shape,
        product,
        ...dimensions,
        definition,
        plateType,
        grade,
        specification,
        surface,
        finish,
        millFinish,
        polish,
        coating,
        coatingThicknessValue,
        temper,
        coatingColour,
        coatingType,
        coatingCoverage,
        weight: weight ? weight.toString() : undefined,
        targetPrice: targetPrice ? targetPrice.toString() : undefined,
        comment,
        numberOfItems,
    };

    setData(data);

    const materialProperties: MaterialProperties = {
        materialType,
        product,
        shape,
        plateType,
        grade,
        specification,
        definition,
        finish,
        coating,
        surface,
        coatingThicknessValue,
        temper,
        coatingColour,
        coatingType,
        coatingCoverage,
        millFinish,
        polish,
    };
    const tradeUnitCalculation = useRef<TradeUnitCalculation>(getTradeUnitCalculation(materialProperties));

    const mandatoryFields = getMandatoryFields ? getMandatoryFields(data) : [];

    const isMandatoryField = (field: keyof SearchFormData) => mandatoryFields.includes(field);

    const getRequiredProps = (field: keyof SearchFormData): AnyValidationProps =>
        mandatoryFields.includes(field) ? { required: true, forceValidation } : { required: false };

    const coatingRequiredFields = mandatoryFields.length
        ? coatingFields.filter((field) => isMandatoryField(field))
        : [];

    const minPriceValidator = useMinNumberValidator(
        getMinimumTargetPrice(materialProperties, weight, numberOfItems),
        t('application.ListingSearchFormInputs.minimumTargetPriceError')
    );

    const { isFeatureSupported } = useFeatureFlag();

    useLayoutEffect(() => validate());

    const numberOfInputsLabel =
        product === Product.TREAD || product === Product.SHEET || product === Product.PLATE
            ? t(`application.ListingSearchFormInputs.numberOfItems.${product}`)
            : '';

    useEffect(() => {
        const updatedTradeUnitCalculation = getTradeUnitCalculation(materialProperties);
        if (updatedTradeUnitCalculation === TradeUnitCalculation.BY_ITEM) {
            const theoreticalWeight = getMaxTheoreticalWeight(materialProperties, dimensions, numberOfItems);
            setWeight(theoreticalWeight);
        } else if (tradeUnitCalculation.current === TradeUnitCalculation.BY_ITEM) {
            setWeight(undefined);
        }
        tradeUnitCalculation.current = updatedTradeUnitCalculation;
    }, [
        numberOfItems,
        dimensions.length,
        dimensions.width,
        dimensions.thickness,
        dimensions.minLength,
        dimensions.maxLength,
        dimensions.minWidth,
        dimensions.maxWidth,
        materialType,
        product,
    ]);

    const isTheoreticalWeight = tradeUnitCalculation.current === TradeUnitCalculation.BY_ITEM;

    let errorMessage = '';
    if (!isValid) {
        errorMessage = t('application.ListingSearchFormInputs.incompleteInformationNotification');
    }

    return (
        <>
            <div>
                <MaterialTypeSelection
                    materialType={materialType}
                    materialTypeSelected={setMaterialType}
                    isStainlessFeatureEnabled
                />

                <MaterialShapeSelection material={materialProperties} shapeSelected={setShape} />

                <MaterialProductSelection
                    material={materialProperties}
                    productSelected={setProduct}
                    isPackagesSupported={!isIMR && isFeatureSupported(Feature.PACKAGES)}
                />

                <MaterialProductDefinitionSelection material={materialProperties} definitionSelected={setDefinition} />

                <MaterialProductTypeSelection
                    material={materialProperties}
                    typeSelected={setPlateType}
                    isPrime={false}
                />
            </div>

            {product !== Product.PACKAGE && (
                <>
                    <div className="listing-search-form__refine">
                        <p className="listing-search-form__refine--text">
                            {t('application.ListingSearchFormInputs.refineSearch')}
                        </p>
                        <span className="listing-search-form__refine--line" />
                    </div>
                    <MaterialDimensionSearchSelection
                        product={product}
                        dimensions={dimensions}
                        setDimensions={setDimensions}
                        widthRequired={isMandatoryField('width')}
                        thicknessRequired={isMandatoryField('thickness')}
                        forceValidation={forceValidation}
                        lengthRequired={isMandatoryField('length')}
                        setIsWidthRange={setIsWidthRange}
                        setIsLengthRange={setIsLengthRange}
                    />
                </>
            )}

            <MaterialGradeSelection
                material={materialProperties}
                gradeSelected={setGrade}
                {...getRequiredProps('grade')}
            />

            <MaterialTemperSelection material={materialProperties} temperSelected={setTemper} />

            <MaterialSpecificationSelection material={materialProperties} specificationSelected={setSpecification} />

            <MaterialSurfaceSelection material={materialProperties} surfaceSelected={setSurface} />

            <MaterialFinishSelection material={materialProperties} finishSelected={setFinish} />

            <MaterialGenericSelection<MillFinish>
                material={materialProperties}
                onSelected={setMillFinish}
                getOptions={getMillFinishes}
                field="millFinish"
                testId="mill-finish-select"
            />

            <MaterialGenericSelection<Polish>
                material={materialProperties}
                onSelected={setPolish}
                getOptions={getPolishes}
                field="polish"
                testId="polish-select"
            />

            <MaterialCoatingSelection
                material={materialProperties}
                coatingSelected={setCoating}
                coatingThicknessSelected={setCoatingThicknessValue}
                coatingColourSelected={setCoatingColour}
                coatingTypeSelected={setCoatingType}
                coatingCoverageSelected={setCoatingCoverage}
                isSearch
                requiredFields={coatingRequiredFields}
                forceValidation={forceValidation}
            />

            {isIMR && (
                <>
                    <NumberOfItemsInput
                        product={product}
                        materialType={materialType}
                        numberOfItems={numberOfItems}
                        setNumberOfItems={setNumberOfItems}
                        required
                        forceValidation={forceValidation}
                        label={numberOfInputsLabel}
                        show={showNumberOfItemsForIMR(materialProperties)}
                    />

                    <MaterialWeightInput
                        initialWeight={weight}
                        setWeight={setWeight}
                        required
                        forceValidation={forceValidation}
                        tradeUnit={getTradeUnit({ materialType })}
                        label={
                            isTheoreticalWeight
                                ? t('application.ListingSearchFormInputs.theoreticalWeightLabel')
                                : t('application.ListingSearchFormInputs.weightLabel')
                        }
                        tradeUnitCalculation={getTradeUnitCalculation(materialProperties)}
                    />

                    {(isWidthRange || isLengthRange) && isTheoreticalWeight && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('application.listingSearchForm.theoreticalWeightNotification')}
                            className="theoretical-weight-warning"
                        />
                    )}

                    {isFeatureSupported(Feature.IMR_TARGET_PRICE) && (
                        <MaterialPriceInput
                            name="targetPrice"
                            priceCurrencyCode={CurrencyCode.GBP}
                            setPrice={(price) => setTargetPrice(price?.value)}
                            required={false}
                            forceValidation={forceValidation}
                            pricingUnit={getPricingUnit(materialProperties)}
                            header={t('application.ListingSearchFormInputs.targetPriceHeader')}
                            label={t('application.ListingSearchFormInputs.targetPriceLabel')}
                            description={t('application.ListingSearchFormInputs.targetPriceDescription')}
                            validators={[minPriceValidator]}
                        />
                    )}

                    <MaterialAdditionalComment initialComment={comment} setComment={setComment} maxLength={maxLength} />
                </>
            )}

            {errorMessage && (
                <Notification
                    level={NotificationLevel.ERROR}
                    message={errorMessage}
                    disableScroll
                    className="missing-field-error"
                />
            )}
            <FormActionbar>
                <ButtonPrimary
                    label={submitButtonLabel}
                    icon={showSubmitIcon ? IconIdentifier.SEARCH : undefined}
                    type="submit"
                    disabled={!isValid}
                    loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                />
            </FormActionbar>
        </>
    );
};

interface ListingSearchFormProps {
    heading: string;
    warningMessage?: string;
    notificationLevel?: NotificationLevel;
    onSubmit: (formData: SearchFormData) => void;
    submitButtonLabel: string;
    getMandatoryFields?: (searchFormData: SearchFormData) => SearchFields;
    forceValidation?: boolean;
    isLoading?: boolean;
    showSubmitIcon?: boolean;
    isIMR?: boolean;
}

export const ListingSearchForm = ({
    heading,
    warningMessage = '',
    notificationLevel = NotificationLevel.WARNING,
    onSubmit,
    getMandatoryFields,
    ...rest
}: ListingSearchFormProps) => {
    const searchFormDataContext = useSearchFormDataContext();
    const searchFormData = searchFormDataContext.getSearchFormData();
    const data = useRef<SearchFormData>(searchFormData);
    const setData = useCallback((updatedData: SearchFormData) => {
        data.current = updatedData;
    }, []);

    const submitSearchForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(data.current);
    };

    return (
        <>
            <PageHeader pageTitle={heading} />
            <FormLayout>
                {warningMessage && (
                    <Notification level={notificationLevel} message={warningMessage} className="search-form-warning" />
                )}
                <Form className="listing-search-form" onSubmit={submitSearchForm}>
                    <ListingSearchFormInputs setData={setData} getMandatoryFields={getMandatoryFields} {...rest} />
                </Form>
            </FormLayout>
        </>
    );
};

import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import { ButtonDanger } from '../../button-danger/ButtonDanger';
import { LoadingStatus } from '../../button-loading-spinner/LoadingStatus.enum';
import { ButtonPrimary } from '../../button-primary/ButtonPrimary';
import { ButtonSecondary } from '../../button-secondary/ButtonSecondary';
import { ModalContentActionBar } from '../modal-content-action-bar/ModalContentActionBar';
import { ModalContentMain } from '../modal-content-main/ModalContentMain';
import { ModalContent } from '../ModalContent';

interface ConfirmCancelModelProps {
    modalAccessor: ModalAccessor;
    header: string;
    bodyImage: string;
    cancelLabel: string;
    submitLabel: string;
    onSubmit: () => void;
    isSubmitting?: boolean;
    isDanger?: boolean;
    onClose: () => void;
    blurOnClose?: boolean;
}

export const ConfirmCancelModel = ({
    modalAccessor,
    header,
    bodyImage,
    cancelLabel,
    submitLabel,
    isSubmitting = false,
    isDanger = false,
    onSubmit,
    onClose,
    blurOnClose,
}: ConfirmCancelModelProps) => {
    const handleClose = () => {
        modalAccessor.hide();
        onClose();
        if (blurOnClose && document.activeElement instanceof HTMLElement) {
            (window.document.activeElement as HTMLElement)?.blur?.();
        }
    };
    return (
        <Modal modalAccessor={modalAccessor}>
            <ModalContent onCloseModal={handleClose} headline={header}>
                <ModalContentMain imgSrc={bodyImage} />
                <ModalContentActionBar>
                    <ButtonSecondary label={cancelLabel} onClick={handleClose} />
                    {!isDanger ? (
                        <ButtonPrimary
                            disabled={isSubmitting}
                            label={submitLabel}
                            onClick={onSubmit}
                            loadingStatus={isSubmitting ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    ) : (
                        <ButtonDanger
                            disabled={isSubmitting}
                            label={submitLabel}
                            onClick={onSubmit}
                            loadingStatus={isSubmitting ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    )}
                </ModalContentActionBar>
            </ModalContent>
        </Modal>
    );
};

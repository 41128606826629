import { useTranslation } from 'react-i18next';
import { BadgeSecondary, Icon, IconIdentifier } from '@steelbuy/ui-primitive';

export type RFQDraftTeaserStatusProps = {
    expiryTime?: string;
    missingFieldsCount: number;
};

export const RFQDraftTeaserStatus = ({ expiryTime, missingFieldsCount }: RFQDraftTeaserStatusProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="rfq-list-teaser-draft__container__status">
            <BadgeSecondary label={t('rfqDraftTeaser.lastEdited') + (expiryTime ? `: ${expiryTime}` : '')} />
            <div className="missing__fields">
                <Icon name={IconIdentifier.WORKFLOW_INCOMPLETE} />
                <span className="missing__fields__label">
                    {t(`rfqDraftTeaser.infoFieldsMissing`, { count: missingFieldsCount })}
                </span>
            </div>
        </div>
    );
};

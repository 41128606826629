import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type ScrollBehaviourControlProps = {
    scrollContainerRef: RefObject<HTMLElement>;
};

const ANCHOR_LINK_SCROLL_OFFSET_PX = 10;

export function getPageHeaderScrollOffset(container: HTMLElement | null): number {
    const header = container?.getElementsByClassName('page-header')[0];
    return (header?.scrollHeight ?? 0) + ANCHOR_LINK_SCROLL_OFFSET_PX;
}

/*
 * Having a component here that renders nothing seems a little bit wierd, but
 * we're using this component to implement the scrolling restoration logic which
 * leads to rerendering the component the logic is in. Therefore using a separate
 * component means that only this component is rerendered and not the whole app ;)
 */

export const ScrollBehaviourControl = (props: ScrollBehaviourControlProps) => {
    const { scrollContainerRef } = props;
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer !== null) {
            if (hash.length > 0) {
                const id = hash.replace('#', '');
                const element = scrollContainer.querySelector(`#${id}`);
                if (element) {
                    const offset = getPageHeaderScrollOffset(scrollContainer);
                    const elementRect = element.getBoundingClientRect();

                    scrollContainer.scrollBy({
                        top: elementRect.top - offset,
                        behavior: 'smooth',
                    });
                } else {
                    scrollContainer.scrollTo(0, 0);
                }
            } else {
                scrollContainer.scrollTo(0, 0);
            }
        }
        // we want to scroll to the anchor even if the hash is still the same, in this case the key changes, so we add this as a dependency
    }, [pathname, hash, key]);

    return null;
};

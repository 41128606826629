import { useContext, createContext } from 'react';
import { CustomError } from '@steelbuy/error';

type NavigationContextType = {
    defaultView: boolean;
    setDefaultView: (isCollapsed: boolean) => void;
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
    collapseOnNavigation: boolean;
    setCollapseOnNavigation: (collapseOnNavigation: boolean) => void;
    isAuthenticated: boolean;
};

export const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const useNavigationContext = () => {
    const context = useContext(NavigationContext);

    if (!context) {
        throw new CustomError('No provider present for NavigationContext!');
    }

    return context;
};

import { buildClassStringFromClassMap } from '@steelbuy/util';
import { ServiceHubItem } from './service-hub-item/ServiceHubItem';
import { Icon } from '../icon/Icon';

import { IconIdentifier } from '../icon/Icon.enums';

import './ServiceHub.scss';

export type ServiceHubProps = {
    imgSrc: string;
    mailAddress: string;
};

export const ServiceHub = (props: ServiceHubProps) => {
    const { imgSrc, mailAddress } = props;

    /*
	const [expanded, setExpanded] = useState(false);
	 This is for when the functionality gets extended
		const handleClick = () => setExpanded(!expanded);
	*/

    const expanded = false;
    const renderImg = () => {
        if (imgSrc === null) {
            return null;
        }
        return <img src={imgSrc} alt="Portrait of your service person" />;
    };

    const renderItems = () => {
        if (!expanded) {
            return null;
        }
        return (
            <div className="service-hub__items">
                <ServiceHubItem icon={IconIdentifier.DASHBOARD} />
                <ServiceHubItem icon={IconIdentifier.DASHBOARD} />
                <ServiceHubItem icon={IconIdentifier.DASHBOARD} />
            </div>
        );
    };

    const serviceHubClassMap = {
        'service-hub': true,
        /*		'service-hub--expanded': expanded */
    };

    return (
        <a className={buildClassStringFromClassMap(serviceHubClassMap)} href={`mailto:${mailAddress}`}>
            {renderItems()}
            <div className="service-hub__profile">
                <div className="service-hub__profile__img">{renderImg()}</div>
                <span className="service-hub__profile__icon">
                    <Icon name={IconIdentifier.HELP} />
                </span>
            </div>
        </a>
    );
};

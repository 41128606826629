import { useTranslation } from 'react-i18next';
import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiaryOnLightM,
    IconIdentifier,
    LoadingStatus,
    WizardNavigationButtons,
} from '@steelbuy/ui-primitive';

export const CreateRFQActionBar = ({
    onNext,
    onBack,
    nextLabel,
    isSubmitDisabled,
    onSaveDraft,
    isLoading = false,
}: {
    onNext: () => void;
    onBack?: () => void | undefined;
    nextLabel: string;
    isSubmitDisabled: boolean;
    onSaveDraft: () => void;
    isLoading: boolean;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <WizardNavigationButtons columns>
            <ButtonTertiaryOnLightM
                label={t('application.createRFQ.saveAsDraft')}
                onClick={onSaveDraft}
                loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
            />
            <div>
                {onBack && <ButtonSecondary label={t('uiDomain:common.back')} type="submit" onClick={onBack} />}
                <ButtonPrimary
                    type="submit"
                    label={nextLabel}
                    icon={IconIdentifier.CHEVRON_RIGHT}
                    disabled={isSubmitDisabled}
                    onClick={onNext}
                />
            </div>
        </WizardNavigationButtons>
    );
};

import { useTranslation } from 'react-i18next';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { useAuth } from '@steelbuy/auth';
import { useUserSelf } from '@steelbuy/data-provider';
import { NavigationButton } from '../../navigation-button/NavigationButton';
import './NavigationItemUser.scss';

export const NavigationItemUser = () => {
    const analytics = useAnalytics();
    const { t } = useTranslation('translation');
    const auth = useAuth();
    const userData = useUserSelf().get();

    const logout = () => {
        analytics.sendEvent(TrackingEvent.LOGGED_OUT);
        auth.unauthenticate();
    };

    return (
        <article className="navigation-item-user">
            <article className="navigation-item-user__user-information">
                <span className="navigation-item-user__user-information__name">{userData?.name}</span>
                <span className="navigation-item-user__user-information__mail">{userData?.organisation?.name}</span>
            </article>
            <NavigationButton label={t('application.navigationItemUser.logoutButtonLabel')} onClick={logout} />
        </article>
    );
};

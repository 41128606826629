import { AddressCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AddressModel, UserRole } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    createCollectionSlice,
    createSelectCollection,
    createSelectCollectionEntity,
    createSelectCreated,
} from '../../util/store/crud/CollectionSlice';
import { CollectionStore } from '../../util/store/crud/CollectionStore';
import { CollectionStoreAccessors } from '../../util/store/crud/CollectionStoreAccessor';
import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchAllThunk,
    createFetchNextThunk,
    createFetchOneThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const invoiceAddressCollectionStoreName = 'invoice-address-collection-crud';
export type InvoiceAddressCollectionStore = {
    [invoiceAddressCollectionStoreName]: CollectionStore<AddressModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => {
    if (roles?.includes(UserRole.MANAGER)) {
        return new AddressCrudApiClient('manager').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.BUYER)) {
        return new AddressCrudApiClient('buyer').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.SELLER)) {
        return new AddressCrudApiClient('seller').init(apiBaseUrl, jsonWebToken);
    }

    return new AddressCrudApiClient('universal').init(apiBaseUrl, jsonWebToken);
};
const fetchAll = createFetchAllThunk<AddressModel, InvoiceAddressCollectionStore, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const fetchNext = createFetchNextThunk<AddressModel, InvoiceAddressCollectionStore, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const fetchOne = createFetchOneThunk<AddressModel, InvoiceAddressCollectionStore, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<AddressModel, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const createEntity = createCreateThunk<AddressModel, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<AddressModel, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<AddressModel, AddressCrudApiClient>(
    invoiceAddressCollectionStoreName,
    createApiClient
);

// Slice definition
export const invoiceAddressCollectionSlice = createCollectionSlice<AddressModel>(
    invoiceAddressCollectionStoreName,
    fetchAll,
    fetchNext,
    fetchOne,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetFetchStatus, resetActionStatus } = invoiceAddressCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<AddressModel, InvoiceAddressCollectionStore>(
    invoiceAddressCollectionStoreName
);
const selectEntity = createSelectCollectionEntity<AddressModel, InvoiceAddressCollectionStore>(
    invoiceAddressCollectionStoreName
);
const selectCreated = createSelectCreated<AddressModel, InvoiceAddressCollectionStore>(
    invoiceAddressCollectionStoreName
);

// Export the store accessors to be used by the data provider value
export const invoiceAddressCollectionStoreAccessors = {
    fetchAll,
    fetchNext,
    fetchOne,
    createEntity,
    mutateEntity,
    deleteEntity,
    resetFetchStatus,
    resetActionStatus,
    selectCollection,
    selectEntity,
    selectCreated,
    entityService,
} as CollectionStoreAccessors<AddressModel, InvoiceAddressCollectionStore>;

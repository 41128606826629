import './CardContent.scss';
import { ReactNode } from 'react';

export type CardContentProps = {
    header?: string | ReactNode;
    children: ReactNode;
};

export const CardContent = (props: CardContentProps) => {
    const { header, children } = props;

    return (
        <article className="card-content">
            {header && <article className="card-content__header">{header}</article>}
            <section className="card-content__children">{children}</section>
        </article>
    );
};

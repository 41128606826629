import { UploadFile, UploadFileFieldNames, ArrayOfUploadFileFieldNames } from '@steelbuy/domain-model-types';
import {
    PackageSellerBaseModel,
    PackageSellerBaseModelConverter,
    PrimePackageSellerBaseModel,
} from './PackageSellerBaseModel';

export type PrimePackageSellerCreateModel = PrimePackageSellerBaseModel<UploadFile>;

export type PackageSellerCreateModel = PackageSellerBaseModel & {
    pictures?: UploadFile[];
    packageDocument: UploadFile;
} & PrimePackageSellerCreateModel;

export class PackageSellerCreateModelConverter extends PackageSellerBaseModelConverter<PackageSellerCreateModel> {
    override getUploadFileFields(): Array<
        UploadFileFieldNames<PackageSellerCreateModel> | ArrayOfUploadFileFieldNames<PackageSellerCreateModel>
    > {
        return ['pictures', 'certificates', 'packageDocument'];
    }
}

import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCoatings, MaterialProperties } from '@steelbuy/domain-model';
import { Select } from '../primitive/select/Select';

interface MaterialCoatingSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    material: MaterialProperties;
    name: TName;
    control: Control<TFieldValues>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    error?: FieldError;
}

export const MaterialCoatingSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    material,
    name,
    control,
    rules,
    error,
}: MaterialCoatingSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const availableCoatings = getCoatings(material)?.slice()?.sort();

    return (
        availableCoatings.length > 0 && (
            <Select
                options={availableCoatings.map((coating) => ({
                    label: t(`domainModel:material.coating.value.${coating}`),
                    value: coating,
                }))}
                placeholder={t(`uiDomain:materialInput.placeholder`)}
                name={name}
                control={control}
                rules={rules}
                error={error?.message}
            />
        )
    );
};

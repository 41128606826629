import { Optional } from '@steelbuy/types';

export type ValueWriteConversion<From, To> = (value: From) => To;
export type ValueReadConversion<From, To> = (value: From) => To;
const buildKey = (key: string, namespace?: string): string => {
    const nameSpaceWithUnderscore = namespace ? `${namespace}_` : '';
    return `steelbuy_${nameSpaceWithUnderscore}${key}`;
};

export const readLocalStorage = <T, StorageT = T>(
    key: string,
    options?: {
        namespace?: string;
        conversion?: ValueReadConversion<StorageT, T>;
    }
): Optional<T> => {
    const { namespace, conversion } = options ?? {};
    const value = localStorage.getItem(buildKey(key, namespace)) ?? null;
    if (value === null) {
        return new Optional<T>(null);
    }
    let data = JSON.parse(value);
    if (conversion !== undefined) {
        data = conversion(data);
    }
    return new Optional<T>(data);
};

export const writeLocalStorage = <T, StorageT = T>(
    key: string,
    value: T,
    options?: {
        namespace?: string;
        conversion?: ValueWriteConversion<T, StorageT>;
    }
): void => {
    const { namespace, conversion } = options ?? {};
    let data;
    if (conversion !== undefined) {
        data = conversion(value);
    } else {
        data = value;
    }
    localStorage.setItem(buildKey(key, namespace), JSON.stringify(data));
};

export const removeLocalStorage = (key: string, options?: { namespace?: string }): void => {
    const { namespace } = options ?? {};
    localStorage.removeItem(buildKey(key, namespace));
};

export const clearLocalStorage = (): void => {
    localStorage.clear();
};

import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import './TabbedPane.scss';

export type TabbedPaneProps<TabId extends string = string> = {
    children: ReactNode;
    activeTabId: TabId;
    renderTabContent: (tabId: TabId) => ReactNode;
    tabChanged?: (tabId: TabId) => void;
};

type TabbedPaneContext<TabId extends string = string> = {
    getTabId: () => TabId;
    setTabId: (tabId: TabId) => void;
};

// eslint-disable-next-line
export const TabbedPaneContext = createContext<TabbedPaneContext<any>>({
    getTabId: (): string => {
        throw new Error('Context provider missing');
    },
    setTabId: (_tabId: string): void => {
        throw new Error('Context provider missing');
    },
});

export const TabbedPane = <TabId extends string = string>(props: TabbedPaneProps<TabId>) => {
    const { children, activeTabId, renderTabContent, tabChanged = undefined } = props;

    const [tabId, setTabId] = useState<TabId>(activeTabId);

    // propagate tab changes to the outside
    useEffect(() => {
        tabChanged?.(tabId);
    }, [tabChanged, tabId]);

    // handle tab changes from the outside
    useEffect(() => {
        setTabId(activeTabId);
    }, [activeTabId]);

    const contextValue = useMemo(
        () => ({
            getTabId: (): TabId => tabId,
            setTabId: (tab: TabId): void => {
                setTabId(tab);
            },
        }),
        [tabId, setTabId]
    );

    return (
        <TabbedPaneContext.Provider value={contextValue}>
            <div className="tabbed-pane">
                {children}
                <div className="tabbed-pane__content">{renderTabContent(tabId)}</div>
            </div>
        </TabbedPaneContext.Provider>
    );
};

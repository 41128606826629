import { useParams } from 'react-router-dom';
import { PreparedSortCriteria, SortDirection } from '@steelbuy/api-integration';
import { ListingBuyerAlertCollectionDataProvider, ListingBuyerAlertSearchDataProvider } from '@steelbuy/data-provider';
import { ListingBuyerSearchSortCriteria } from '@steelbuy/domain-model';
import { MyAlertsSearchResults } from '../../../components/my-alerts-search-results/MyAlertsSearchResults';

const defaultSortCriteria: PreparedSortCriteria<ListingBuyerSearchSortCriteria> = [
    {
        property: ListingBuyerSearchSortCriteria.RELEVANCE,
        direction: SortDirection.DESCENDING,
    },
];

export const MyAlertsSearchResultsView = () => {
    const { alertId } = useParams();

    if (!alertId) {
        throw new Error('alertId not set!');
    }

    return (
        <ListingBuyerAlertSearchDataProvider pathVariables={{ alertId }} sortCriteria={defaultSortCriteria}>
            <ListingBuyerAlertCollectionDataProvider>
                <MyAlertsSearchResults />
            </ListingBuyerAlertCollectionDataProvider>
        </ListingBuyerAlertSearchDataProvider>
    );
};

import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { FieldPath, FieldValues, PathValue, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, IconIdentifier, Tooltip } from '@steelbuy/ui-primitive';
import './NumberInput.scss';

interface NumberInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
    inputMode?: Extract<HTMLAttributes<HTMLInputElement>['inputMode'], 'numeric' | 'decimal'>;
    error?: string;
}

export const NumberInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    inputMode = 'decimal',
    rules,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    ...rest
}: NumberInputProps<TFieldValues, TName>) => {
    const { t } = useTranslation('uiDomain');
    const { field } = useController({
        control,
        rules: {
            ...(rules || {}),
            validate: (value: PathValue<TFieldValues, TName>, formValues: TFieldValues) => {
                if (typeof value === 'string' && value !== '' && Number.isNaN(Number(value))) {
                    return t('validation.numberValue');
                }
                if (rules?.validate && typeof rules.validate === 'function') {
                    return rules.validate(value, formValues);
                }
                return true;
            },
        },
        ...rest,
    });

    const formInputClassMap = {
        'form-input': true,
        'form-input--disabled': false,
    };

    const formInputFieldClassMap = {
        'form-input__field': true,
        'form-input__field--disabled': false,
        'form-input__field--error': !!error,
        'form-input__field--error--endAdornment': false,
        'form-input__field--read-only': false,
    };
    const formInputFieldInputClassMap = {
        'form-input__field__input': true,
        'form-input__field__input--disabled': false,
        'form-input__field__input--read-only': false,
        'form-input__field__input--error': !!error,
    };

    return (
        <div className={classNames(formInputClassMap)}>
            <span className={classNames(formInputFieldClassMap)}>
                <input
                    {...field}
                    type="text"
                    className={classNames(formInputFieldInputClassMap)}
                    inputMode={inputMode}
                />
                {!!error && (
                    <Tooltip content={error}>
                        <span className="icon__position">
                            <Icon name={IconIdentifier.ERROR} />
                        </span>
                    </Tooltip>
                )}
            </span>
        </div>
    );
};

/**
 * Any material
 */
export enum MaterialType {
    MILD_STEEL = 'MILD_STEEL',
    ALUMINIUM = 'ALUMINIUM',
    STAINLESS_STEEL = 'STAINLESS_STEEL',
}

export enum TradeUnit {
    TON = 'TON',
    KILOGRAM = 'KILOGRAM',
    METER = 'METER',
}

export enum TradeUnitCalculation {
    BY_WEIGHT = 'BY_WEIGHT',
    BY_ITEM = 'BY_ITEM',
}

export enum PricingUnit {
    TON = 'TON',
    KILOGRAM = 'KILOGRAM',
    SHEET = 'SHEET',
    PLATE = 'PLATE',
    TREAD = 'TREAD',
}

export enum Tolerance {
    EU_NORM = 'EU_NORM',
    THREE_QUARTERS_NORM = 'THREE_QUARTERS_NORM',
    HALF_NORM = 'HALF_NORM',
    NON_PRIME = 'NON_PRIME',
}

export enum Shape {
    FLAT = 'FLAT',
    LONG = 'LONG',
    TUBE_PIPE = 'TUBE_PIPE',
}

export enum Product {
    COIL = 'COIL',
    SHEET = 'SHEET',
    PLATE = 'PLATE',
    TREAD = 'TREAD',
    PACKAGE = 'PACKAGE',
}

export enum Definition {
    HOT_ROLLED = 'HOT_ROLLED',
    HOT_ROLLED_FLOORPLATE = 'HOT_ROLLED_FLOORPLATE',
    COLD_ROLLED = 'COLD_ROLLED',
    COATED_STEEL = 'COATED_STEEL',
    ELECTRO_ZINC = 'ELECTRO_ZINC',
}

export enum ProductType {
    WEAR_RESISTANT = 'WEAR_RESISTANT',
    HIGH_STRENGTH_STRUCTURAL = 'HIGH_STRENGTH_STRUCTURAL',
    STRUCTURAL_STEEL = 'STRUCTURAL_STEEL',
    MEDIUM_CARBON_STEEL = 'MEDIUM_CARBON_STEEL',
    OFFSHORE = 'OFFSHORE',
    BOILER_PRESSURE_VESSEL = 'BOILER_PRESSURE_VESSEL',
    ROAD_PLATE = 'ROAD_PLATE',
}

/**
 * Mild steel plate
 */
export enum Finish {
    DRY = 'DRY',
    PICKLED_OILED = 'PICKLED_OILED',
    OILED = 'OILED',
    BLACK = 'BLACK',
    SHOTBLASTED = 'SHOTBLASTED',
    SHOTBLASTED_PRIMER_PAINTED = 'SHOTBLASTED_PRIMER_PAINTED',
}

export enum Grade {
    A516_GR60 = 'A516_GR60',
    A516_GR65 = 'A516_GR65',
    A516_GR70 = 'A516_GR70',
    A537_CLASS1 = 'A537_CLASS1',
    API2H_50 = 'API2H_50',
    API2W_50 = 'API2W_50',
    C22 = 'C22',
    C25 = 'C25',
    C35 = 'C35',
    C40 = 'C40',
    C45 = 'C45',
    C45E = 'C45E',
    C50 = 'C50',
    C50E = 'C50E',
    C55 = 'C55',
    C60 = 'C60',
    C67S = 'C67S',
    DC01 = 'DC01',
    DC01EK = 'DC01EK',
    DC02 = 'DC02',
    DC03 = 'DC03',
    DC03ED = 'DC03ED',
    DC04 = 'DC04',
    DC04ED = 'DC04ED',
    DC04EK = 'DC04EK',
    DC05 = 'DC05',
    DC06 = 'DC06',
    DC06EK = 'DC06EK',
    DC07 = 'DC07',
    DD11 = 'DD11',
    DD12 = 'DD12',
    DD13 = 'DD13',
    DD14 = 'DD14',
    DX51D = 'DX51D',
    DX52D = 'DX52D',
    DX53D = 'DX53D',
    DX54D = 'DX54D',
    DX56D = 'DX56D',
    EN8 = 'EN8',
    EN8D = 'EN8D',
    G_1030 = 'G_1030',
    G_1050 = 'G_1050',
    G_1050A = 'G_1050A',
    G_1050AQ = 'G_1050AQ',
    G_1060 = 'G_1060',
    G_1070A = 'G_1070A',
    G_1080A = 'G_1080A',
    G_1100 = 'G_1100',
    G_1200 = 'G_1200',
    G_1350_BS_2897 = 'G_1350_BS_2897',
    G_201_14372 = 'G_201_14372',
    G_2014 = 'G_2014',
    G_2014A = 'G_2014A',
    G_2017A = 'G_2017A',
    G_202_14373 = 'G_202_14373',
    G_2024 = 'G_2024',
    G_2205_14462 = 'G_2205_14462',
    G_2507_14539 = 'G_2507_14539',
    G_253_SMO_14547 = 'G_253_SMO_14547',
    G_3003 = 'G_3003',
    G_3004 = 'G_3004',
    G_3005 = 'G_3005',
    G_300HB = 'G_300HB',
    G_3CR12_14003 = 'G_3CR12_14003',
    G_301_14310 = 'G_301_14310',
    G_301LN_14318 = 'G_301LN_14318',
    G_302_14310 = 'G_302_14310',
    G_304_14301 = 'G_304_14301',
    G_304L_14307 = 'G_304L_14307',
    G_304LN_14311 = 'G_304LN_14311',
    G_305_14303 = 'G_305_14303',
    G_309_14828 = 'G_309_14828',
    G_309S_14833 = 'G_309S_14833',
    G_3103 = 'G_3103',
    G_3105_BS_4300_6 = 'G_3105_BS_4300_6',
    G_310S_14845 = 'G_310S_14845',
    G_316_14401 = 'G_316_14401',
    G_316L_14335 = 'G_316L_14335',
    G_316TI_14571 = 'G_316TI_14571',
    G_317L_14438 = 'G_317L_14438',
    G_321_11541 = 'G_321_11541',
    G_347_14550 = 'G_347_14550',
    G_34CRMO4 = 'G_34CRMO4',
    G_4006 = 'G_4006',
    G_4007 = 'G_4007',
    G_400HB = 'G_400HB',
    G_4015 = 'G_4015',
    G_409_14512 = 'G_409_14512',
    G_42CRMO4 = 'G_42CRMO4',
    G_430_14016 = 'G_430_14016',
    G_430TI_14520 = 'G_430TI_14520',
    G_434_14113 = 'G_434_14113',
    G_439_14510 = 'G_439_14510',
    G_441_14509 = 'G_441_14509',
    G_450HB = 'G_450HB',
    G_4589_14589 = 'G_4589_14589',
    G_4622_14622 = 'G_4622_14622',
    G_490B_LT30 = 'G_490B_LT30',
    G_490B_LT50 = 'G_490B_LT50',
    G_5005_BS_4300_7 = 'G_5005_BS_4300_7',
    G_500HB = 'G_500HB',
    G_5040 = 'G_5040',
    G_5049 = 'G_5049',
    G_5050 = 'G_5050',
    G_5052 = 'G_5052',
    G_5083 = 'G_5083',
    G_5086 = 'G_5086',
    G_50CRMO4 = 'G_50CRMO4',
    G_5154A = 'G_5154A',
    G_5182 = 'G_5182',
    G_51CRV4 = 'G_51CRV4',
    G_5251 = 'G_5251',
    G_5454_BS_4300_8 = 'G_5454_BS_4300_8',
    G_550HB = 'G_550HB',
    G_55HX = 'G_55HX',
    G_5754 = 'G_5754',
    G_58CRV4 = 'G_58CRV4',
    G_600HB = 'G_600HB',
    G_6060 = 'G_6060',
    G_6061 = 'G_6061',
    G_6063 = 'G_6063',
    G_6082 = 'G_6082',
    G_654_SMO_14652 = 'G_654_SMO_14652',
    G_7020_BS_4300_14 = 'G_7020_BS_4300_14',
    G_7021 = 'G_7021',
    G_7022 = 'G_7022',
    G_7050 = 'G_7050',
    G_7075 = 'G_7075',
    G_8011A = 'G_8011A',
    HCT490X = 'HCT490X',
    HCT590X = 'HCT590X',
    HCT780X = 'HCT780X',
    HCT780XG = 'HCT780XG',
    HCT980X = 'HCT980X',
    HCT980XG = 'HCT980XG',
    HDT580X = 'HDT580X',
    HX180BD = 'HX180BD',
    HX180YD = 'HX180YD',
    HX220BD = 'HX220BD',
    HX220YD = 'HX220YD',
    HX260BD = 'HX260BD',
    HX260LAD = 'HX260LAD',
    HX260YD = 'HX260YD',
    HX300BD = 'HX300BD',
    HX300LAD = 'HX300LAD',
    HX340LAD = 'HX340LAD',
    HX380LAD = 'HX380LAD',
    HX420LAD = 'HX420LAD',
    HX460LAD = 'HX460LAD',
    HX500LAD = 'HX500LAD',
    J57S = 'J57S',
    J57SUP = 'J57SUP',
    P265GH = 'P265GH',
    P265S = 'P265S',
    P275NH = 'P275NH',
    P355NH = 'P355NH',
    P355NL1 = 'P355NL1',
    P355NL2 = 'P355NL2',
    S1100 = 'S1100',
    S1400 = 'S1400',
    S220GD = 'S220GD',
    S235 = 'S235',
    S250GD = 'S250GD',
    S275 = 'S275',
    S280GD = 'S280GD',
    S300 = 'S300',
    S315 = 'S315',
    S320GD = 'S320GD',
    S350GD = 'S350GD',
    S355 = 'S355',
    S390GD = 'S390GD',
    S420 = 'S420',
    S420GD = 'S420GD',
    S450GD = 'S450GD',
    S460 = 'S460',
    S500 = 'S500',
    S550 = 'S550',
    S600 = 'S600',
    S650 = 'S650',
    S690 = 'S690',
    S700 = 'S700',
    S890 = 'S890',
    S960 = 'S960',
}

export enum Specification {
    JR = 'JR',
    J0 = 'J0',
    J0W = 'J0W',
    J0WP = 'J0WP',
    J2 = 'J2',
    J2_PLUS_N = 'J2_PLUS_N',
    J2G3 = 'J2G3',
    J2G4 = 'J2G4',
    J2W = 'J2W',
    J4 = 'J4',
    JRC = 'JRC',
    E295 = 'E295',
    E335 = 'E335',
    E360 = 'E360',
    N = 'N',
    NL = 'NL',
    MC = 'MC',
    ML = 'ML',
    QL = 'QL',
    QL1 = 'QL1',
    N_A = 'N_A',
    J0_PLUS_N = 'J0_PLUS_N',
    JR_PLUS_N = 'JR_PLUS_N',
    J2_C_PLUS_N = 'J2_C_PLUS_N',
    K2_PLUS_N = 'K2_PLUS_N',
    GH = 'GH',
    NH = 'NH',
    NL1 = 'NL1',
    NL2 = 'NL2',
    PLUS_U = 'PLUS_U',
    PLUS_A = 'PLUS_A',
    PLUS_S = 'PLUS_S',
    PLUS_N = 'PLUS_N',
    K2 = 'K2',
    K2G3 = 'K2G3',
    K2G4 = 'K2G4',
    K2W = 'K2W',
    K2W_PLUS_N = 'K2W_PLUS_N',
    G1 = 'G1',
    G1_PLUS_N = 'G1_PLUS_N',
    G1_PLUS_M = 'G1_PLUS_M',
    G1_PLUS_Q = 'G1_PLUS_Q',
    G7 = 'G7',
    G7_PLUS_N = 'G7_PLUS_N',
    G7_PLUS_M = 'G7_PLUS_M',
    G8 = 'G8',
    G8_PLUS_N = 'G8_PLUS_N',
    G8_PLUS_M = 'G8_PLUS_M',
    G10 = 'G10',
    G10_PLUS_N = 'G10_PLUS_N',
    G10_PLUS_M = 'G10_PLUS_M',
    EMZ = 'EMZ',
    PLUS_M = 'PLUS_M',
}

export enum Coating {
    Z = 'Z',
    AZ = 'AZ',
    ZM = 'ZM',
    ZA = 'ZA',
    ZF = 'ZF',
    AS = 'AS',
    ONE_SIDED = 'ONE_SIDED',
    TWO_SIDED = 'TWO_SIDED',
    UNCOATED = 'UNCOATED',
    PROTECTIVE_COATING = 'PROTECTIVE_COATING',
}

export enum Surface {
    A_M = 'A_M',
    B_M = 'B_M',
    MA_C = 'MA_C',
    MA_O = 'MA_O',
    MB_C = 'MB_C',
    MB_O = 'MB_O',
    MC_O = 'MC_O',
    A_C = 'A_C',
    A_O = 'A_O',
    A_P = 'A_P',
    A_PC = 'A_PC',
    B_O = 'B_O',
}

export enum Temper {
    F = 'F',
    H = 'H',
    H111 = 'H111',
    H112 = 'H112',
    H114 = 'H114',
    H115 = 'H115',
    H116 = 'H116',
    H12 = 'H12',
    H14 = 'H14',
    H15 = 'H15',
    H16 = 'H16',
    H18 = 'H18',
    H19 = 'H19',
    H22 = 'H22',
    H232 = 'H232',
    H24 = 'H24',
    H26 = 'H26',
    H28 = 'H28',
    H32 = 'H32',
    H321 = 'H321',
    H34 = 'H34',
    H343 = 'H343',
    H36 = 'H36',
    H38 = 'H38',
    H39 = 'H39',
    O = 'O',
    T1 = 'T1',
    T10 = 'T10',
    T2 = 'T2',
    T3 = 'T3',
    T351 = 'T351',
    T3510 = 'T3510',
    T3511 = 'T3511',
    T352 = 'T352',
    T36 = 'T36',
    T361 = 'T361',
    T4 = 'T4',
    T42 = 'T42',
    T451 = 'T451',
    T4510 = 'T4510',
    T4511 = 'T4511',
    T5 = 'T5',
    T6 = 'T6',
    T62 = 'T62',
    T651 = 'T651',
    T6510 = 'T6510',
    T6511 = 'T6511',
    T7 = 'T7',
    T72 = 'T72',
    T73 = 'T73',
    T7351 = 'T7351',
    T8 = 'T8',
    T81 = 'T81',
    T851 = 'T851',
    T8510 = 'T8510',
    T8511 = 'T8511',
    T9 = 'T9',
}

export enum CoatingType {
    FIBRE = 'FIBRE',
    CO2 = 'CO2',
    FIBRE_AND_CO2 = 'FIBRE_AND_CO2',
    N_A = 'N_A',
}

export enum CoatingCoverage {
    ONE_SIDED = 'ONE_SIDED',
    TWO_SIDED = 'TWO_SIDED',
}

export type CoatingColourType = string; // appended with Type to avoid conflict with enum
export type CoatingThicknessType = string;

export enum MillFinish {
    MF_1D_HR = 'MF_1D_HR',
    MF_2B_CR = 'MF_2B_CR',
    MF_2D_CR = 'MF_2D_CR',
    MF_2H = 'MF_2H',
    MF_2Q_CR = 'MF_2Q_CR',
    MF_2R_BA_CR = 'MF_2R_BA_CR',
}

export enum Polish {
    MILL_STANDARD = 'MILL_STANDARD',
    BRIGHT_POLISH = 'BRIGHT_POLISH',
    CIRCULAR_POLISH = 'CIRCULAR_POLISH',
    DULL_POLISH = 'DULL_POLISH',
    GRIT_POLISH_240 = 'GRIT_POLISH_240',
    GRIT_POLISH_360 = 'GRIT_POLISH_360',
    MIRROR_POLISH = 'MIRROR_POLISH',
    SATIN_POLISH = 'SATIN_POLISH',
}

export enum CoatingThicknessUnit {
    MICRON = 'µm',
    GRAMS_PER_SQUARE_METRE = 'g/m²',
}

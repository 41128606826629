import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, CardContentCheckoutPurchaseOrderNumber, CardLayout } from '@steelbuy/ui-primitive';

type CheckoutPurchaseOrderNumberProps = {
    setBuyerOrderNumber: (buyerOrderNumber: string) => void;
    control: Control;
};

const CheckoutPurchaseOrderNumber = ({ setBuyerOrderNumber, control }: CheckoutPurchaseOrderNumberProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <form>
            <Card isClickable={false}>
                <CardLayout>
                    <Controller
                        name="buyerOrderNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: t('uiDomain:validation.requiredField') },
                            validate: (value) => {
                                // Trim the value for validation purposes
                                const trimmedValue = value.trim();

                                // Check if the trimmed value is empty or only consists of special characters
                                if (trimmedValue === '' || /^[!@#$%^&*(),.?":{}|<>_-]+$/.test(trimmedValue)) {
                                    return t('translation:application.checkout.purchaseOrderErrorMsg');
                                }

                                // Ensure the trimmed value contains at least one alphanumeric character
                                const containsAlphanumeric = /[a-zA-Z0-9]/.test(trimmedValue);

                                // Check for leading or trailing special characters (excluding spaces)
                                const hasLeadingTrailingSpecialChars =
                                    /^[^a-zA-Z0-9\s]/.test(trimmedValue) || /[^a-zA-Z0-9\s]$/.test(trimmedValue);

                                // If it does not contain alphanumeric or has special characters at the start/end, show error
                                if (!containsAlphanumeric || hasLeadingTrailingSpecialChars) {
                                    return t('translation:application.checkout.purchaseOrderErrorMsg');
                                }

                                return true;
                            },
                        }}
                        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                            <CardContentCheckoutPurchaseOrderNumber
                                header={t('translation:application.checkout.purchaseOrderNumber')}
                                onChangeTextInput={(val) => {
                                    onChange(val);
                                    setBuyerOrderNumber(val);
                                }}
                                buyerPurchaseOrderNumber={value}
                                placeholderText={t('translation:application.checkout.purchaseOrderPlaceholderText')}
                                error={error?.message}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </CardLayout>
            </Card>
        </form>
    );
};

export default CheckoutPurchaseOrderNumber;

import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import { ButtonLoadingSpinner } from '../button-loading-spinner/ButtonLoadingSpinner';
import { LoadingStatus } from '../button-loading-spinner/LoadingStatus.enum';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './ButtonPrimary.scss';

export type ButtonPrimaryProps = {
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonPrimary = (props: ButtonPrimaryProps) => {
    const { label, icon, loadingStatus = LoadingStatus.IDLE, type = 'button', disabled = false, ...rest } = props;

    const buttonPrimaryClassMap = {
        'button-primary': true,
        'button-primary__only-icon': !label,
    };

    const buttonPrimaryContentClassMap = {
        'button-primary__content': true,
        'button-primary__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    return (
        <button
            type={type}
            className={buildClassStringFromClassMap(buttonPrimaryClassMap)}
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonPrimaryContentClassMap)}>
                {label && <span className="button-primary__content__label">{label}</span>}
                {icon && <Icon name={icon} />}
            </span>
            <ButtonLoadingSpinner loadingStatus={loadingStatus} />
        </button>
    );
};

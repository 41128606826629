import { useTranslation } from 'react-i18next';
import { RFQDraftModel } from '@steelbuy/domain-model';
import { createDateFromTimestamp } from '@steelbuy/domain-model-types';
import { formatDate, formatTime } from '@steelbuy/util';
import { RFQDraftTeaserButtons } from './RFQDraftTeaserButtons';
import { RFQDraftTeaserStatus } from './RFQDraftTeaserStatus';
import { RFQTeaser } from './RFQTeaser';
import { RFQTeaserHeader } from './RFQTeaserHeader';
import { RFQTeaserQuality } from './RFQTeaserQuality';

import './RFQDraftTeaser.scss';

export const RFQDraftTeaser = ({
    rfq,
    onEdit,
    onDelete,
}: {
    rfq: RFQDraftModel;
    onEdit: () => void;
    onDelete: () => void;
}) => {
    const { t } = useTranslation('uiDomain');
    const {
        status: { modifiedAt },
    } = rfq;
    const modifiedDate = createDateFromTimestamp(modifiedAt);
    const modifiedAtDateWithDayName = `${formatDate(navigator.language, modifiedDate)} ${formatTime(
        navigator.language,
        modifiedDate
    )}`;

    return (
        <RFQTeaser>
            <RFQTeaserHeader quoteEntry={rfq.quoteEntry} />
            <div className="rfq-list-teaser-draft__container">
                <RFQDraftTeaserStatus
                    expiryTime={modifiedAtDateWithDayName}
                    missingFieldsCount={rfq.missingFieldsCount}
                />
                <RFQTeaserQuality label={t(`rfqDraftTeaser.draft`)} />
                <RFQDraftTeaserButtons onEdit={onEdit} onDelete={onDelete} />
            </div>
        </RFQTeaser>
    );
};

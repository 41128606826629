import { FieldConverterMap, ModelConverter, ModelConverterInterface } from '@steelbuy/domain-model-converter';
import { ApiModel, ModelPrimaryKey, Timestamp } from '@steelbuy/domain-model-types';

import { ListingBuyerModel, ListingBuyerModelConverter } from './ListingBuyerModel';
import { PackageBuyerModel, PackageBuyerModelConverter } from './PackageBuyerModel';

export enum OrderStatus {
    PROCESSING = 'PROCESSING',
    FREIGHT_BOOKED = 'FREIGHT_BOOKED',
    SHIPPING = 'SHIPPING',
    DELIVERED = 'DELIVERED',
}

export const isListingOrderModel = (order: OrderBuyerModel | ApiModel<OrderBuyerModel>): order is ListingOrderModel =>
    (order as ListingOrderModel).listing !== undefined;

export const isPackageOrderModel = (order: OrderBuyerModel | ApiModel<OrderBuyerModel>): order is PackageOrderModel =>
    (order as PackageOrderModel).packaging !== undefined;

export type ListingOrderModel = {
    readonly listing: ListingBuyerModel;
    listingId: ModelPrimaryKey;
} & BaseOrderBuyerModel;

export type PackageOrderModel = {
    readonly packaging: PackageBuyerModel;
    packagingId: ModelPrimaryKey;
} & BaseOrderBuyerModel;

export type BaseOrderBuyerModel = {
    readonly id: ModelPrimaryKey;
    readonly orderNumber: string;
    negotiationId?: string;
    invoiceAddressId: ModelPrimaryKey;
    deliveryAddressId: ModelPrimaryKey;
    additionalDeliveryInformation?: string;
    buyerOrderNumber?: string;
    haulierRequired: boolean;
    readonly status: OrderStatus;
    readonly createdDate: Timestamp;
    readonly statusDescription?: string;
};

export type OrderBuyerModel = ListingOrderModel | PackageOrderModel;

export class OrderBuyerModelConverter extends ModelConverter<OrderBuyerModel> {
    override getFieldConverterMapByModel(
        model: OrderBuyerModel | ApiModel<OrderBuyerModel>
    ): FieldConverterMap<ListingOrderModel> | FieldConverterMap<PackageOrderModel> {
        if (isListingOrderModel(model)) {
            return {
                listing: new ListingBuyerModelConverter() as ModelConverterInterface<ListingBuyerModel>,
            };
        }
        return {
            packaging: new PackageBuyerModelConverter() as ModelConverterInterface<PackageBuyerModel>,
        };
    }
}

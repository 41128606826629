import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { challengeRespond, isLoginSuccess, Tenant } from '@steelbuy/api-integration';
import { SetPasswordForm } from '../../../set-password-form/SetPasswordForm';

import './ForcePasswordChange.scss';

interface ForcePasswordChangeProps {
    apiBaseUrl: string;
    session: string;
    username: string;
    loginUrl: string;
    challengeUrl: string;
    tenant: Tenant;
}

export const ForcePasswordChange = ({
    apiBaseUrl,
    session,
    username,
    loginUrl,
    challengeUrl,
    tenant,
}: ForcePasswordChangeProps) => {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('uiDomain');

    const literal = 'login.challengeRespond.forcePasswordChange';

    const onSubmit = async (newPassword: string) => {
        try {
            setLoading(true);
            const responseBody = await challengeRespond(apiBaseUrl, {
                type: ChallengeNameType.NEW_PASSWORD_REQUIRED,
                session,
                options: {
                    newPassword,
                    username,
                },
                tenant,
            });
            if (isLoginSuccess(responseBody)) {
                navigate(loginUrl, {
                    state: {
                        defaultUsername: username,
                        defaultSuccessNotification: t(`${literal}.successNotification`),
                    },
                });
            } else {
                navigate(challengeUrl, {
                    state: {
                        session: responseBody.session,
                        username,
                        type: responseBody.challengeType,
                    },
                });
            }
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <main className="force-password-change">
            <header className="force-password-change__header">
                <div>{t(`${literal}.header`)}</div>
                <section className="force-password-change__header--subheading">
                    <p>
                        {t(`${literal}.subheading.welcome`, {
                            username,
                        })}
                    </p>
                </section>
            </header>
            <SetPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
        </main>
    );
};

import './CardContentTestCertificate.scss';
import { ReactNode } from 'react';

export type CardContentTestCertificateProps = {
    header: string;
    children: ReactNode;
};

export const CardContentTestCertificate = (props: CardContentTestCertificateProps) => {
    const { header, children } = props;

    return (
        <article className="card-content-test-certificate">
            <article className="card-content-test-certificate__header">{header}</article>
            {children}
        </article>
    );
};

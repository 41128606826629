import { CurrencyCode } from '@steelbuy/currency';
import { ApiDownloadFile, ApiModel } from '@steelbuy/domain-model-types';

import { addressInvoiceModelMock, addressWarehouseModelMock } from './AddressModel.mock';
import { ListingMaterialAge, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingSellerModel } from './ListingSellerModel';
import { ListingStatus } from './ListingSellerModel.enums';
import { materialDimensionCoilModelMock } from './MaterialDimensionModel.mock';
import { TradeUnit } from './MaterialModel.enums';
import {
    coatedCoilMaterialModelMock,
    coldRolledCoilMaterialModelMock,
    electroZincCoilMaterialModelMock,
    hotRolledCoilMaterialModelMock,
    hotRolledFloorplateCoilMaterialModelMock,
} from './MaterialModel.mock';

const apiDownloadFile: ApiDownloadFile = {
    url: {
        accessible: true,
        value: 'https://steelbuy.com',
    },
    meta: {
        mimeType: 'application/pdf',
        title: 'Test',
        fileName: 'test.pdf',
        lastModifiedDate: '2022-01-01T16:30:12.345Z',
        size: 12345,
    },
};

const listingSellerModelFoundation = {
    expiresAt: '2022-12-31T16:30:12.345Z',
    pricing: {
        netPricePerUnit: 2094,
        batchPrice: 5 * 2094,
        shippingCosts: 1040,
        total: 14864,
        currencyCode: CurrencyCode.GBP,
    },
    weight: 5,
    age: ListingMaterialAge.UP_TO_SIX_MONTHS,
    origin: ListingMaterialOrigin.WESTERN_EUROPEAN,
    pickupAddress: {
        id: addressWarehouseModelMock.id,
        original: '456',
    },
    invoiceAddress: {
        id: addressInvoiceModelMock.id,
        original: '789',
    },
    internalSellerSku: 'Pallet location 12.31',
    autoRenew: {
        enabled: true,
    },
    seller: {},
};

export const hotRolledCoilListingSellerModelMock: ApiModel<ListingSellerModel> = {
    ...listingSellerModelFoundation,
    id: 'hotRolledCoilListing',
    listingId: 'hotRolledCoilListingSellerModelMock',
    prime: true,
    testCertificate: apiDownloadFile,
    material: hotRolledCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    status: {
        value: ListingStatus.PUBLISHED,
    },
    tradeUnit: TradeUnit.TON,
};

export const coldRolledCoilListingSellerModelMock: ApiModel<ListingSellerModel> = {
    ...listingSellerModelFoundation,
    id: 'coldRolledCoilListing',
    listingId: 'coldRolledCoilListingSellerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: coldRolledCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    status: {
        value: ListingStatus.PUBLISHED,
    },
    tradeUnit: TradeUnit.TON,
};

export const hotRolledFloorplateCoilListingSellerModelMock: ApiModel<ListingSellerModel> = {
    ...listingSellerModelFoundation,
    id: 'hotRolledFloorplateCoilListing',
    listingId: 'hotRolledFloorplateCoilListingSellerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: hotRolledFloorplateCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    status: {
        value: ListingStatus.PUBLISHED,
    },
    tradeUnit: TradeUnit.TON,
};

export const electroZincCoilListingSellerModelMock: ApiModel<ListingSellerModel> = {
    ...listingSellerModelFoundation,
    id: 'electroZincCoilListing',
    listingId: 'electroZincCoilListingSellerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: electroZincCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    status: {
        value: ListingStatus.PUBLISHED,
    },
    tradeUnit: TradeUnit.TON,
};

export const coatedCoilListingSellerModelMock: ApiModel<ListingSellerModel> = {
    ...listingSellerModelFoundation,
    id: 'coatedCoilListing',
    listingId: 'coatedCoilListingSellerModelMock',
    prime: false,
    description: 'Some description why this is not prime. F.e. bended edges or rusty corners.',
    picture: apiDownloadFile,
    material: coatedCoilMaterialModelMock,
    materialDimensions: materialDimensionCoilModelMock,
    status: {
        value: ListingStatus.PUBLISHED,
    },
    tradeUnit: TradeUnit.TON,
};

export const listingSellerModelCollection: Array<ApiModel<ListingSellerModel>> = [
    hotRolledCoilListingSellerModelMock,
    coldRolledCoilListingSellerModelMock,
    hotRolledFloorplateCoilListingSellerModelMock,
    electroZincCoilListingSellerModelMock,
    coatedCoilListingSellerModelMock,
];

import { useTranslation } from 'react-i18next';
import { CurrencyCode } from '@steelbuy/currency';
import { PricingUnit } from '@steelbuy/domain-model';
import { MonetaryAmount } from '@steelbuy/domain-model-types';
import { FormItem, Validator } from '@steelbuy/ui-primitive';
import { MonetaryAmountInput } from '../monetary-amount-input/MonetaryAmountInput';
import { GenericValidationProps } from '../validation/Validation';

type MaterialPriceInputProps = {
    initialPrice?: MonetaryAmount;
    setPrice: (newPrice?: MonetaryAmount) => void;
    priceCurrencyCode: CurrencyCode;
    pricingUnit?: PricingUnit;
    header?: string;
    label?: string;
    name: string;
    description?: string;
    validators?: Validator[];
} & GenericValidationProps;

export const MaterialPriceInput = ({
    setPrice,
    forceValidation,
    required,
    priceCurrencyCode,
    initialPrice,
    pricingUnit = PricingUnit.TON,
    header,
    label,
    name,
    description,
    validators,
}: MaterialPriceInputProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    return (
        <FormItem
            header={
                header ||
                t('uiDomain:sellingPriceInput.formItemHeader', {
                    pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                })
            }
            description={description}
        >
            <MonetaryAmountInput
                label={label || t('uiDomain:sellingPriceInput.inputLabel')}
                name={name}
                updateMonetaryAmount={setPrice}
                forceValidation={forceValidation}
                required={required}
                currencyCode={priceCurrencyCode}
                initialMonetaryAmount={initialPrice}
                pricingUnit={pricingUnit}
                validators={validators}
            />
        </FormItem>
    );
};

export enum UserRole {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    SELLER = 'SELLER',
    BUYER = 'BUYER',
}

// a "fake" user role that every user has by default
// this value is only used when displaying a list of roles
export const BASIC_USER_ROLE = 'BASIC';

import classNames from 'classnames';
import { ReactNode } from 'react';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { Notification } from '../notification/Notification';
import { NotificationLevel } from '../notification/Notification.enums';
import { Tag, TagVariant } from '../tag/Tag';

import './CardContentAccountAttribute.scss';

export type CardContentAccountAttributeProps = {
    header: string;
    tagLabel?: string;
    buttonLabel?: string;
    onButtonClick?: () => void;
    children: ReactNode;
    notificationLevel?: NotificationLevel;
    notificationMessage?: string;
    userActivated?: boolean;
    isButtonDisabled?: boolean;
    isLargeButton?: boolean;
};

export const CardContentAccountAttribute = ({
    buttonLabel,
    children,
    header,
    isButtonDisabled,
    notificationLevel,
    notificationMessage,
    onButtonClick,
    tagLabel,
    userActivated,
    isLargeButton, // will wrap the button underneath header for mobile
}: CardContentAccountAttributeProps) => (
    <article className="card-content-account-attribute">
        <article
            className={classNames('card-content-account-attribute__title', {
                'card-content-account-attribute__title__large-button': isLargeButton,
            })}
        >
            <article className="card-content-account-attribute__title__text">
                <article className="card-content-account-attribute__title__text__header">{header}</article>

                {tagLabel && (
                    <Tag label={tagLabel} variant={userActivated ? TagVariant.NEUTRAL : TagVariant.NEGATIVE} />
                )}
            </article>

            {buttonLabel && (
                <ButtonTertiaryOnLightM
                    label={buttonLabel}
                    disabled={!onButtonClick || isButtonDisabled}
                    onClick={onButtonClick}
                />
            )}
        </article>
        <article className="card-content-account-attribute__children">{children}</article>
        {notificationLevel && notificationMessage && (
            <Notification level={notificationLevel} message={notificationMessage} />
        )}
    </article>
);

import { ReactElement } from 'react';
import { AuthHandler } from './auth-handler/AuthHandler';

type UnauthenticatedProps = {
    children: ReactElement;
};

export const Unauthenticated = ({ children }: UnauthenticatedProps) => {
    const authHandler = AuthHandler.get();

    if (!authHandler.isAuthenticated()) {
        return children;
    }
    return null;
};

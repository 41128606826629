import { Link, useParams } from 'react-router-dom';

import { FooEntityDataProvider } from '@steelbuy/data-provider';
import { NotFoundError } from '@steelbuy/error';

import { FooEntity } from '../components/foo-entity/FooEntity';
import { RoutePath } from '../router/Routes';

export const FooEntityView = () => {
    const { fooId = null } = useParams();

    if (fooId === null) {
        throw new NotFoundError('');
    }

    return (
        <FooEntityDataProvider apiMock entityId={fooId}>
            <Link to={RoutePath.FOOS}>Overview</Link>
            <hr />
            <FooEntity />
        </FooEntityDataProvider>
    );
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialProperties } from '@steelbuy/domain-model';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

type MaterialGenericSelectionProps<T> = {
    material: MaterialProperties;
    onSelected: (option?: T) => void;
    getOptions: (material: MaterialProperties) => Readonly<T[]>;
    field: keyof Pick<MaterialProperties, 'millFinish' | 'polish'>;
    testId?: string;
};

export const MaterialGenericSelection = <T extends string>({
    material,
    onSelected,
    getOptions,
    field,
    required,
    forceValidation,
    testId,
}: MaterialGenericSelectionProps<T> & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const options = getOptions(material).slice().sort();
    const value = material[field] as T | undefined;

    useEffect(() => {
        if (value !== undefined && !options.includes(value)) {
            onSelected(undefined);
        }
    }, [value, onSelected, options]);

    if (options.length === 0) {
        return null;
    }

    const selectOptions = options.map((option) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`domainModel:material.${field}.value.${option}`),
        value: option,
    }));

    return (
        <FormItem header={t(`domainModel:material.${field}.name`)}>
            <InputSelect
                options={selectOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={value}
                onSelect={(selectedValue) => onSelected(selectedValue)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
                testId={testId}
            />
        </FormItem>
    );
};

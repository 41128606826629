import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';
import { TrackingIndicatorCircleTextStates } from './TrackingIndicatorCircleText.enums';
import { Icon } from '../../../icon/Icon';
import { IconIdentifier } from '../../../icon/Icon.enums';

import './TrackingIndicatorCircleText.scss';

export type TrackingIndicatorCircleTextProps = {
    label: string;
    date?: string;
    state: TrackingIndicatorCircleTextStates;
};

export const TrackingIndicatorCircleText = (props: TrackingIndicatorCircleTextProps) => {
    const { state, label, date } = props;

    const trackingIndicatorCircleTextClassMap = {
        'tracking-indicator-circle-text': true,
        'tracking-indicator-circle-text--date': date,
        [classNameFromEnumValue(state, 'tracking-indicator-circle-text')]: true,
    };

    // eslint-disable-next-line consistent-return
    const renderIcon = () => {
        switch (state) {
            case TrackingIndicatorCircleTextStates.UNCOMPLETED:
                return <Icon name={IconIdentifier.WORKFLOW_EMPTY} />;
            case TrackingIndicatorCircleTextStates.COMPLETED:
                return <Icon name={IconIdentifier.WORKFLOW_SUCCESS} />;
            case TrackingIndicatorCircleTextStates.ERROR:
                return <Icon name={IconIdentifier.WORKFLOW_INCOMPLETE} />;
            default:
                return null;
        }
    };

    const renderText = () => {
        if (!date) {
            return <span>{label}</span>;
        }
        return (
            <>
                <span>{label}</span>
                <span>{date}</span>
            </>
        );
    };

    return (
        <article className={buildClassStringFromClassMap(trackingIndicatorCircleTextClassMap)}>
            <div className="tracking-indicator-circle-text__icon">{renderIcon()}</div>
            <div className="tracking-indicator-circle-text__text-wrapper">{renderText()}</div>
        </article>
    );
};

import { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem, InputTextfield, ValidationProps } from '@steelbuy/ui-primitive';
import './InputTextFieldCharacterCount.scss';

type InputTextFieldCharacterCountProps = {
    headerText: string;
    label: string;
    placeholder: string;
    value: string | undefined;
    onChange: (value: string) => void;
    maxLength: number;
} & ValidationProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputTextFieldCharacterCount = ({
    headerText,
    label,
    placeholder,
    value,
    onChange,
    maxLength,
    ...rest
}: InputTextFieldCharacterCountProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const remainingCharactersCount = maxLength - (value?.length ?? 0);

    return (
        <div className="input-textfield-character-count">
            <FormItem header={headerText}>
                <InputTextfield
                    name="materialAdditionalComment"
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                    label={label}
                    value={value}
                    {...rest}
                />
                <span className="comments-label">
                    {t('uiDomain:inputCharacterCount.inputCharacterRemaining', { remainingCharactersCount })}
                </span>
            </FormItem>
        </div>
    );
};

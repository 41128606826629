import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { reportNRError } from '@steelbuy/util';
import { GenericError } from './GenericError';
import { PageHeader } from '../page-header/PageHeader';

/**
 * This component renders the GenericError component and additionally makes sure that the error gets reset when the location changes.
 */
export const LocationAwareGenericError = ({ resetError, error }: { resetError: () => void; error: Error }) => {
    const { t } = useTranslation('translation');
    const currentLocation = useLocation();
    const [initialLocation] = useState(currentLocation);

    useEffect(() => {
        if (initialLocation !== currentLocation) {
            resetError();
        }
    }, [currentLocation]);

    useEffect(() => {
        if (error.name !== 'ChunkLoadError') {
            reportNRError(error);
        }
    }, []);

    return (
        <>
            <PageHeader pageTitle={t('application.locationAwareGenericError.pageTitle')} />
            <GenericError />
        </>
    );
};

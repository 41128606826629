import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { useRerenderFromNavbar } from '@steelbuy/util';
import { CreateListingContextProvider } from '../components/create-listing/CreateListingContext';
import { MyPackagesContextProvider } from '../components/my-packages/MyPackagesContext';

export const ListingManagementView = () => {
    const rerenderKey = useRerenderFromNavbar();

    return (
        <Fragment key={rerenderKey}>
            <CreateListingContextProvider>
                <MyPackagesContextProvider>
                    <Outlet />
                </MyPackagesContextProvider>
            </CreateListingContextProvider>
        </Fragment>
    );
};

import { Nullable } from '@steelbuy/types';
import { currencyData } from './Currency.data';

import { Currency } from './Currency.types';
import { CurrencyCode } from './CurrencyCode';

export const findCurrencyByCode = (code: CurrencyCode): Nullable<Currency> =>
    currencyData.find((currency): boolean => currency.code === code) ?? null;

export const findCurrencyByNumericCode = (numeric: number): Nullable<Currency> =>
    currencyData.find((currency): boolean => currency.numeric === numeric) ?? null;

export const listCurrencies = (): Array<Currency> => currencyData;

export const getCurrencySymbolByCurrency = (currency: Nullable<Currency>): string => {
    if (currency === null) {
        return '';
    }
    return currency.symbol ?? currency.code;
};

export const findCurrencySymbolByCode = (code: CurrencyCode): string =>
    getCurrencySymbolByCurrency(findCurrencyByCode(code));

export const findCurrencySymbolByNumericCode = (numeric: number): string =>
    getCurrencySymbolByCurrency(findCurrencyByNumericCode(numeric));

export const parseCurrencyCodeFromString = (value: string): CurrencyCode => {
    const code = value as CurrencyCode;
    return Object.values(CurrencyCode).includes(code) ? code : CurrencyCode.GBP;
};

export const calculateMajorUnitFromMinorUnit = (code: CurrencyCode, minorUnit: number): number => {
    const currency = findCurrencyByCode(code);
    if (currency === null || currency.fraction === 0) {
        return minorUnit;
    }
    return minorUnit / 10 ** currency.fraction;
};

export const calculateMinorUnitFromMajorUnit = (code: CurrencyCode, majorUnit: number): number => {
    const currency = findCurrencyByCode(code);
    if (currency === null || currency.fraction === 0) {
        return majorUnit;
    }
    return Math.ceil(majorUnit * 10 ** currency.fraction);
};

import { useTranslation } from 'react-i18next';
import { BuyingTerms } from './BuyingTerms';
import { SellingTerms } from './SellingTerms';
import { WebsiteTerms } from './WebsiteTerms';
import { LegalLayout } from '../../views/layouts/legal-layout/LegalLayout';
import { PageHeader } from '../page-header/PageHeader';

import './TermsAndConditions.scss';

export const TermsAndConditions = () => {
    const { t } = useTranslation('translation');

    return (
        <>
            <PageHeader pageTitle={t('application.termsAndConditions.pageHeader')} />

            <div className="terms-and-conditions" id="top">
                <LegalLayout>
                    <WebsiteTerms />
                    <SellingTerms />
                    <BuyingTerms />
                </LegalLayout>
            </div>
        </>
    );
};

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export type SortCriteria<Model> = Array<{
    property: keyof Model;
    direction?: SortDirection;
}>;
export enum FilterComparator {
    EQUAL = '=',
    GREATER_THAN = '>',
    LESS_THAN = '<',
}

export type FilterCriteriaEntry<Model> = {
    property: keyof Model;
    comparator: FilterComparator;
    value: string | number;
};

export type FilterCriteria<Model> = Array<FilterCriteriaEntry<Model>>;

export interface ResponseModel<Model> {
    items: Model;
    offset: number;
    limit: number;
    total: number;
}

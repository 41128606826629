import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RFQBuyerDetailsModel, RFQBuyerDetailsModelConverter, RFQBuyerModel } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';
import { mapRFQQuoteEntryForDetails } from '../../utils/rfqs/rfqMapper';

export const RFQ_DETAILS_CACHE_KEY = 'rfq-details';

interface RFQViewsOptions<Model> {
    rfqId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

const modelConverter = new RFQBuyerDetailsModelConverter();
const getRFQDetails = async (rfqId: string) => {
    const { data } = await axios.get<RFQBuyerModel>(`/api/buyer/quotes/views/${rfqId}`);
    const rfqModel = modelConverter.toViewModel(data as unknown as ApiModel<RFQBuyerDetailsModel>);
    rfqModel.quoteEntry = mapRFQQuoteEntryForDetails(rfqModel);
    return rfqModel;
};

export const useRFQDetails = ({ rfqId, queryOptions }: RFQViewsOptions<RFQBuyerDetailsModel>) =>
    useQuery({
        queryKey: [RFQ_DETAILS_CACHE_KEY, rfqId],
        queryFn: () => getRFQDetails(rfqId),
        ...queryOptions,
    });

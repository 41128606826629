import { useTranslation } from 'react-i18next';
import { ButtonColor, ButtonSize, ButtonTertiary } from '@steelbuy/ui-primitive';

export const RFQDraftTeaserButtons = ({ onEdit, onDelete }: { onEdit: () => void; onDelete: () => void }) => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="rfq-list-teaser-draft__container__buttons">
            <ButtonTertiary
                size={ButtonSize.S}
                color={ButtonColor.DARK}
                label={t(`common.edit`)}
                onClick={(e) => {
                    e.preventDefault();
                    onEdit();
                }}
            />
            <ButtonTertiary
                label={t(`common.delete`)}
                size={ButtonSize.S}
                onClick={(e) => {
                    e.preventDefault();
                    onDelete();
                }}
            />
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonGhostSystemOnLightM, IconIdentifier, Separator, SeparatorIdentifier } from '@steelbuy/ui-primitive';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { NavigationItemUser } from './navigation-item-user/NavigationItemUser';
import { RoutePath } from '../../../router/Routes';
import { NavigationButton } from '../navigation-button/NavigationButton';
import { useNavigationContext } from '../NavigationContext';

const UserInformation = ({ isCollapsed }: { isCollapsed: boolean }) =>
    !isCollapsed ? (
        <div className="navigation-footer__user-information">
            <NavigationItemUser />
        </div>
    ) : null;

const LoginButton = ({ isCollapsed, login, label }: { isCollapsed: boolean; label: string; login: () => void }) =>
    !isCollapsed ? <NavigationButton label={label} onClick={login} /> : null;

export const NavigationFooter = () => {
    const navigationContext = useNavigationContext();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    const navigationFooterClassMap = {
        'navigation-footer': true,
        'navigation-footer--is-collapsed': navigationContext.isCollapsed,
    };

    const login = () => {
        navigate(RoutePath.LOGIN);
    };

    const renderIcon = () => {
        if (!navigationContext.isCollapsed) {
            return (
                <ButtonGhostSystemOnLightM
                    icon={IconIdentifier.COLLAPSE}
                    onClick={() => {
                        navigationContext.setIsCollapsed(!navigationContext.isCollapsed);
                    }}
                />
            );
        }
        return (
            <ButtonGhostSystemOnLightM
                icon={IconIdentifier.EXPAND}
                onClick={() => {
                    navigationContext.setIsCollapsed(!navigationContext.isCollapsed);
                }}
            />
        );
    };

    return (
        <section className={buildClassStringFromClassMap(navigationFooterClassMap)}>
            {navigationContext.isAuthenticated ? (
                <UserInformation isCollapsed={navigationContext.isCollapsed} />
            ) : (
                <LoginButton
                    isCollapsed={navigationContext.isCollapsed}
                    label={t('application.navigationFooter.loginButtonLabel')}
                    login={login}
                />
            )}
            <span className="navigation-footer__login-btn">
                <Separator separatorType={SeparatorIdentifier.ON_DARK} />
                {renderIcon()}
            </span>
        </section>
    );
};

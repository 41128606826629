import { useNavigate } from 'react-router-dom';
import { useCartDetails } from '@steelbuy/data-access';
import { useIsFeatureSupported, userHasRole, useUserSelf } from '@steelbuy/data-provider';
import { Feature, UserRole } from '@steelbuy/domain-model';
import { BadgePrimary, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const CartIcon = () => {
    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;
    const { data } = useCartDetails({ queryOptions: { enabled: false } });

    const itemCount = data?.cartEntries?.length || 0;

    const navigate = useNavigate();

    if (!showCart) {
        return null;
    }
    return (
        <div className="page-header__wrapper__actions__cart-icon">
            <BadgePrimary label={`${itemCount}`} />
            <Icon name={IconIdentifier.CART} onClick={() => navigate(RoutePath.MY_CART)} />
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import {
    Coating,
    CoatingThicknessType,
    Finish,
    Grade,
    ListingMaterialAge,
    ListingMaterialOrigin,
    Product,
    MaterialType,
    Definition,
    ProductType,
    Surface,
    Specification,
    Temper,
    CoatingCoverage,
    CoatingType,
    CoatingColourType,
    isMaterialWithPrime,
    MillFinish,
    Polish,
    getCoatingThicknessLabelSuffix,
    getCoatingThicknessText,
    Shape,
    Country,
} from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';
import {
    Card,
    CardContentAttributes,
    CardContentAttributesItem,
    CardContentMaterialLocation,
    CardLayout,
} from '@steelbuy/ui-primitive';

type ListingDetailsMaterialProps = {
    sku?: Nullable<string>;
    materialType: MaterialType;
    product: Product;
    definition?: Nullable<Definition>;
    plateType?: Nullable<ProductType>;
    grade?: Nullable<Grade>;
    finish?: Nullable<Finish>;
    surface?: Nullable<Surface>;
    specification?: Nullable<Specification>;
    temper?: Nullable<Temper>;
    coating?: Nullable<Coating>;
    coatingThicknessValue?: Nullable<CoatingThicknessType>;
    millFinish?: Nullable<MillFinish>;
    polish?: Nullable<Polish>;
    age?: Nullable<ListingMaterialAge>;
    origin: Nullable<ListingMaterialOrigin[]>;
    prime: boolean;
    coatingColour?: Nullable<CoatingColourType>;
    coatingType?: Nullable<CoatingType>;
    coatingCoverage?: Nullable<CoatingCoverage>;
    coatingColours?: Readonly<string[]>;
    shape: Shape;
    countryCode?: Country;
};

export const ListingDetailsMaterial = (props: ListingDetailsMaterialProps) => {
    const {
        sku,
        materialType,
        product,
        definition,
        plateType,
        grade,
        finish,
        surface,
        specification,
        temper,
        coating,
        coatingThicknessValue,
        millFinish,
        polish,
        age,
        origin,
        prime,
        coatingColour,
        coatingType,
        coatingCoverage,
        coatingColours,
        shape,
        countryCode,
    } = props;
    const { t } = useTranslation(['domainModel', 'uiDomain']);
    let coatingColourText = '-';
    if (coatingColour) {
        coatingColourText = coatingColours?.includes(coatingColour)
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              t(`domainModel:material.coatingColour.value.${coatingColour}`)
            : coatingColour;
    }

    const coatingMaterial = {
        materialType,
        coating: coating || undefined,
        coatingThicknessValue: coatingThicknessValue || undefined,
    };
    const originLiterals: string[] | null = origin
        ? origin.map((value) => t(`domainModel:material.origin.value.${value}`))
        : null;
    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={t('uiDomain:listingDetailsMaterial.detailsTitle')}>
                    {sku !== undefined && (
                        <CardContentAttributesItem
                            label={t('uiDomain:listingDetailsMaterial.skuLabel')}
                            value={sku ?? '-'}
                        />
                    )}
                    <CardContentAttributesItem
                        label={t('domainModel:material.materialType.name')}
                        value={t(`domainModel:material.materialType.value.${materialType}`)}
                    />
                    {prime !== undefined && isMaterialWithPrime({ materialType }) && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.quality.name')}
                            value={t(`domainModel:material.quality.value.${prime ? 'prime' : 'nonPrime'}`)}
                        />
                    )}
                    <CardContentAttributesItem
                        label={t('domainModel:material.shape.name')}
                        value={t(`domainModel:material.shape.value.${shape}`)}
                    />
                    <CardContentAttributesItem
                        label={t('domainModel:material.product.name')}
                        value={t(`domainModel:material.product.value.${product}`)}
                    />
                    {definition !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.definition.name')}
                            value={definition ? t(`domainModel:material.definition.value.${definition}`) : '-'}
                        />
                    )}
                    {plateType !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.plateType.name')}
                            value={plateType ? t(`domainModel:material.plateType.value.${plateType}`) : '-'}
                        />
                    )}
                    {grade !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.grade.name')}
                            value={grade ? t(`domainModel:material.grade.value.${grade}`) : '-'}
                        />
                    )}
                    {specification !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.specification.name')}
                            value={specification ? t(`domainModel:material.specification.value.${specification}`) : '-'}
                        />
                    )}
                    {temper !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.temper.name')}
                            value={temper ? t(`domainModel:material.temper.value.${temper}`) : '-'}
                        />
                    )}
                    {finish !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.finish.name')}
                            value={finish ? t(`domainModel:material.finish.value.${finish}`) : '-'}
                        />
                    )}
                    {surface !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.surface.name')}
                            value={surface ? t(`domainModel:material.surface.value.${surface}`) : '-'}
                        />
                    )}

                    {millFinish !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.millFinish.name')}
                            value={millFinish ? t(`domainModel:material.millFinish.value.${millFinish}`) : '-'}
                        />
                    )}
                    {polish !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.polish.name')}
                            value={polish ? t(`domainModel:material.polish.value.${polish}`) : '-'}
                        />
                    )}

                    {!!age && age !== ListingMaterialAge.UNSPECIFIED && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.age.name')}
                            value={age ? t(`domainModel:material.age.value.${age}`) : '-'}
                        />
                    )}

                    {!origin?.includes(ListingMaterialOrigin.UNSPECIFIED) && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.origin.name')}
                            value={originLiterals ?? '-'}
                        />
                    )}
                    {coating !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.coating.name')}
                            value={coating ? t(`domainModel:material.coating.value.${coating}`) : '-'}
                        />
                    )}
                    {coatingThicknessValue !== undefined && (
                        <CardContentAttributesItem
                            label={`${t('domainModel:material.coatingThickness.name')}${getCoatingThicknessLabelSuffix(
                                coatingMaterial
                            )}`}
                            value={getCoatingThicknessText(coatingMaterial, false) || '-'}
                        />
                    )}
                    {coatingColour !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.coatingColour.name')}
                            value={coatingColourText}
                        />
                    )}
                    {coatingType !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.coatingType.name')}
                            value={coatingType ? t(`domainModel:material.coatingType.value.${coatingType}`) : '-'}
                        />
                    )}
                    {coatingCoverage !== undefined && (
                        <CardContentAttributesItem
                            label={t('domainModel:material.coatingCoverage.name')}
                            value={
                                coatingCoverage
                                    ? t(`domainModel:material.coatingCoverage.value.${coatingCoverage}`)
                                    : '-'
                            }
                        />
                    )}
                    {countryCode && <CardContentMaterialLocation countryCode={countryCode} />}
                </CardContentAttributes>
            </CardLayout>
        </Card>
    );
};

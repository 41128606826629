import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonTertiaryOnLightM, TabBar, TabBarItem, TabbedPane } from '@steelbuy/ui-primitive';
import { MyRFQsDraftsList } from './MyRFQsDraftsList';
import { MyRFQsList } from './MyRFQsList';
import { useTabbedPane } from '../../hooks/useTabbedPane';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { PageHeader } from '../page-header/PageHeader';
import './MyRFQs.scss';

enum MyRFQsTabId {
    SUBMITTED = 'submitted',
    DRAFTS = 'drafts',
}

const MyRFQsTabIds = Object.values(MyRFQsTabId);
const DEFAULT_TAB_ID = MyRFQsTabId.SUBMITTED;

export const MyRFQs = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { activeTabId, setActiveTabId } = useTabbedPane<MyRFQsTabId>(MyRFQsTabIds, DEFAULT_TAB_ID);
    return (
        <>
            <PageHeader pageTitle={t('application.myRFQ.header')} />
            <TableLayout>
                <TabbedPane
                    activeTabId={activeTabId}
                    renderTabContent={(tabId) => (tabId === MyRFQsTabId.DRAFTS ? <MyRFQsDraftsList /> : <MyRFQsList />)}
                    tabChanged={setActiveTabId}
                >
                    <TabBar>
                        <div>
                            <TabBarItem label={t('application.myRFQ.submitted')} tabId={MyRFQsTabId.SUBMITTED} />
                            <TabBarItem label={t('application.myRFQ.drafts')} tabId={MyRFQsTabId.DRAFTS} />
                        </div>

                        <ButtonTertiaryOnLightM
                            label={t('application.navigation.itemLabels.createRfq')}
                            onClick={() => navigate(RoutePath.CREATE_RFQ_WIZARD)}
                        />
                    </TabBar>
                </TabbedPane>
            </TableLayout>
        </>
    );
};

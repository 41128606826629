import { OrderBuyerModel, OrderBuyerModelConverter } from '@steelbuy/domain-model';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class OrderBuyerCrudApiClient extends AbstractCrudApiClient<OrderBuyerModel> {
    protected collectionControllerUri = 'api/universal/purchase-orders/';

    protected paginationControllerUri = 'api/universal/purchase-orders/';

    protected entityCreateUri = 'api/buyer/purchase-orders/';

    protected entityFetchUri = 'api/universal/purchase-orders/{id}/';

    protected entityMutateUri = 'api/buyer/purchase-orders/{id}/';

    protected entityDeleteUri = 'api/buyer/purchase-orders/{id}/';

    protected entityServiceCallUri = 'api/buyer/purchase-orders/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new OrderBuyerModelConverter();
}

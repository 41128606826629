import { useCallback, useState } from 'react';

export const useLoadingSpinnerById = () => {
    const [idsMutating, setIdsMutating] = useState<string[]>([]);

    const removeLoadingState = useCallback(
        (id: string) => {
            setIdsMutating((prev) => prev.filter((key) => key !== id));
        },
        [idsMutating]
    );

    const addLoadingState = useCallback(
        (id: string) => {
            setIdsMutating((prev) => [...prev, id]);
        },
        [idsMutating]
    );

    return {
        idsMutating,
        addLoadingState,
        removeLoadingState,
    };
};

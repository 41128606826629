import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grade, getGrades, MaterialProperties } from '@steelbuy/domain-model';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';
import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

type MaterialGradeSelectionProps = {
    material: MaterialProperties;
    gradeSelected: (grade?: Grade) => void;
} & AnyValidationProps;

export const MaterialGradeSelection = ({
    forceValidation,
    gradeSelected,
    material,
    required = false,
}: MaterialGradeSelectionProps) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const availableGrades = getGrades(material)?.slice().sort();

    useEffect(() => {
        if (material.grade !== undefined && !availableGrades.includes(material.grade)) {
            gradeSelected(undefined);
        }
    }, [material.grade, gradeSelected, availableGrades]);

    if (availableGrades.length === 0) {
        return null;
    }

    const materialGradeOptions = availableGrades.map((grade) => ({
        label: t(`domainModel:material.grade.value.${grade}`),
        value: grade,
    }));

    return (
        <FormItem header={t('domainModel:material.grade.name')}>
            <InputSelect
                options={materialGradeOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={material.grade}
                onSelect={(value) => gradeSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={required ? forceValidation : undefined}
                testId="grade-select"
            />
        </FormItem>
    );
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPricingUnit, getTradeUnit, Product } from '@steelbuy/domain-model';

import { CreateListingTeaser } from '@steelbuy/ui-domain';
import {
    Card,
    FileUploadStatus,
    Notification,
    NotificationLevel,
    Wizard,
    WizardBar,
    WizardBarItemStatus,
    WizardNavigationButtons,
    WizardSteps,
} from '@steelbuy/ui-primitive';

import { CreateListingButtons } from './CreateListingButtons';
import { useCreateListingContext } from './CreateListingContext';
import { getCurrentStepIndex } from './CreateListingContextUtil';
import { CreateListingSaveDraftButton } from './CreateListingSaveDraftButton';
import { PACKAGE_STEPS, STEPS } from './CreateListingSteps';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { APP_MAIN_LAYOUT_ID } from '../app-layout/app-main-layout/AppMainLayout';
import { CreateListingUnsavedChangesModal } from '../create-listing-unsaved-changes-modal/CreateListingUnsavedChangesModal';
import { PageHeader } from '../page-header/PageHeader';

import './CreateListing.scss';

function showSaveSuccessInitialStateFromLocationState(state?: unknown): boolean {
    if ((state ?? null) === null) {
        return false;
    }

    return (state as { draftSaveSuccess: boolean }).draftSaveSuccess;
}

export const CreateListing = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const location = useLocation();
    const topContainerRef = useRef<HTMLDivElement>(null);

    const createListingContext = useCreateListingContext();
    const {
        materialStepData,
        dimensionsStepData,
        listUploadStepData,
        weightPriceStepData,
        pickupAddressStepData,
        currentStepId,
        certificatesStatus,
        picturesStatus,
        packageStatus,
        goBack,
    } = createListingContext;

    const isPackageListing = materialStepData.product === Product.PACKAGE;
    const steps = isPackageListing ? PACKAGE_STEPS : STEPS;
    const currentStepIndex = getCurrentStepIndex(materialStepData, currentStepId);

    const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(
        showSaveSuccessInitialStateFromLocationState(location.state)
    );
    const [showSaveFail, setShowSaveFail] = useState<boolean>(false);

    useEffect(() => {
        document.getElementById(APP_MAIN_LAYOUT_ID)?.scrollTo(0, 0);
    }, [currentStepId]);

    const getStepStatus = (stepId: string): WizardBarItemStatus => {
        if (currentStepId === stepId) {
            return WizardBarItemStatus.ACTUAL;
        }
        return createListingContext.getStepStatus(stepId);
    };

    const allStepsFinished = steps
        .map((stepDefinition) => createListingContext.getStepStatus(stepDefinition.id))
        .every((getStatus) => getStatus === WizardBarItemStatus.SUCCESS);

    const areFilesUploading = Object.values({ ...certificatesStatus, ...picturesStatus, ...packageStatus }).some(
        (certStatus) => certStatus === FileUploadStatus.Uploading
    );

    const header = createListingContext.draftId
        ? t('application.createListing.headerEditDraft')
        : t('application.createListing.headerCreateNew');

    const pageHeaderProps = useMemo(
        () =>
            goBack?.link && goBack?.label
                ? {
                      onBackClick: () => {
                          navigate(goBack.link);
                      },
                      previousPageTitle: goBack.label,
                  }
                : {},
        [goBack?.link, goBack?.label]
    );

    return (
        <>
            <div ref={topContainerRef} />
            <PageHeader pageTitle={header} {...pageHeaderProps} />
            <div className="create-listing">
                <Wizard>
                    <TableLayout>
                        {createListingContext.rejectedListingId && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={t('application.createListing.rejectedListingConvertedToDraft')}
                                className="create-listing__save-draft-notification"
                                closeButton
                                stayOpen
                            />
                        )}
                        {showSaveSuccess && (
                            <Notification
                                level={NotificationLevel.SUCCESS}
                                message={t('application.createListing.draftSuccessfulNotification')}
                                className="create-listing__save-draft-notification"
                                onClose={() => setShowSaveSuccess(false)}
                                closeButton
                            />
                        )}
                        {showSaveFail && (
                            <Notification
                                level={NotificationLevel.ERROR}
                                message={
                                    isPackageListing
                                        ? t('application.createListing.packageDraftFailedNotification')
                                        : t('application.createListing.draftFailedNotification')
                                }
                                className="create-listing__save-draft-notification"
                                onClose={() => setShowSaveFail(false)}
                                closeButton
                            />
                        )}
                        <WizardBar
                            items={steps.map((step) => ({
                                label: t(`application.createListingSteps.labels.${step.id}`),
                                status: getStepStatus(step.id),
                                onClick: () => createListingContext.setCurrentStepId(step.id),
                                selected: step.id === currentStepId,
                            }))}
                            currentStepIndex={currentStepIndex}
                        />
                    </TableLayout>

                    {currentStepIndex > 0 && (
                        <div className="create-listing-teaser__wrapper">
                            <FormLayout>
                                <Card isClickable={false}>
                                    <CreateListingTeaser
                                        sku={materialStepData.sku}
                                        materialType={materialStepData.materialType}
                                        prime={materialStepData.prime}
                                        shape={materialStepData.shape}
                                        product={materialStepData.product}
                                        definition={materialStepData.definition}
                                        plateType={materialStepData.plateType}
                                        grade={materialStepData.grade}
                                        specification={materialStepData.specification}
                                        surface={materialStepData.surface}
                                        finish={materialStepData.finish}
                                        temper={materialStepData.temper}
                                        millFinish={materialStepData.millFinish}
                                        polish={materialStepData.polish}
                                        coating={materialStepData.coating}
                                        coatingThicknessValue={materialStepData.coatingThicknessValue}
                                        age={materialStepData.age}
                                        origins={materialStepData.origins}
                                        thickness={dimensionsStepData.thickness}
                                        tolerance={dimensionsStepData.tolerance}
                                        width={dimensionsStepData.width}
                                        length={dimensionsStepData.length}
                                        packageTitle={listUploadStepData.packageTitle}
                                        weight={weightPriceStepData.weight}
                                        numberOfItems={weightPriceStepData.numberOfItems}
                                        sellingPriceAmount={weightPriceStepData.sellingPriceAmount}
                                        sellingPriceCurrencyCode={weightPriceStepData.sellingPriceCurrencyCode}
                                        expirationDate={weightPriceStepData.expirationDate}
                                        pickupAddressId={pickupAddressStepData.pickupAddressId}
                                        additionalInformation={pickupAddressStepData.additionalInformation}
                                        tradeUnit={getTradeUnit(materialStepData)}
                                        pricingUnit={getPricingUnit(materialStepData)}
                                    />
                                </Card>
                            </FormLayout>
                        </div>
                    )}

                    <WizardSteps>
                        {steps.map(({ component: StepComponent, id }) => (
                            <div
                                key={`create-listing-step-${id}`}
                                data-testid={`create-listing-step-${id}`}
                                style={{ display: currentStepId === id ? 'block' : 'none' }}
                            >
                                <StepComponent stepId={id} />
                            </div>
                        ))}
                    </WizardSteps>

                    <FormLayout>
                        <WizardNavigationButtons columns>
                            <CreateListingButtons
                                isFirstStep={currentStepIndex === 0}
                                isLastStep={currentStepIndex === steps.length - 1}
                                allStepsFinished={allStepsFinished && !areFilesUploading}
                                goToNextStep={() =>
                                    createListingContext.setCurrentStepId(steps[currentStepIndex + 1].id)
                                }
                                gotToPreviousStep={() =>
                                    createListingContext.setCurrentStepId(steps[currentStepIndex - 1].id)
                                }
                                goToSummary={() =>
                                    navigate(RoutePath.CREATE_LISTING_SUMMARY, {
                                        state: {
                                            ...(location.state?.origin && { origin: location.state.origin }),
                                            ...(location.state?.alertId && { alertId: location.state?.alertId }),
                                            ...(location.state?.targetPrice && {
                                                targetPrice: location.state?.targetPrice,
                                            }),
                                            ...(location.state?.targetPricingUnit && {
                                                targetPricingUnit: location.state?.targetPricingUnit,
                                            }),
                                        },
                                    })
                                }
                            >
                                <CreateListingSaveDraftButton
                                    onSaveSuccess={(draft, newEntityCreated, isPackage = false) => {
                                        createListingContext.clearRejectedListing();
                                        if (newEntityCreated) {
                                            navigate(
                                                createRouteUrl(
                                                    RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                                                    ['draftId', draft.id],
                                                    ['listingType', isPackage ? 'package' : 'listing']
                                                ),
                                                {
                                                    replace: true,
                                                    state: {
                                                        draftSaveSuccess: true,
                                                    },
                                                }
                                            );
                                        } else {
                                            setShowSaveFail(false);
                                            setShowSaveSuccess(true);
                                            window.history.replaceState({}, '');
                                        }
                                    }}
                                    onSaveFail={() => setShowSaveFail(true)}
                                    isPackage={isPackageListing}
                                />
                            </CreateListingButtons>
                        </WizardNavigationButtons>
                    </FormLayout>
                </Wizard>
            </div>
            <CreateListingUnsavedChangesModal />
        </>
    );
};

import { useMutation } from '@tanstack/react-query';
import { PackageSellerCreateModel, PackageSellerCreateModelConverter } from '@steelbuy/domain-model';
import axios from '../../axios';

const createPackageListing = async (createPackageListingRequest: PackageSellerCreateModel) => {
    const modelConverter = new PackageSellerCreateModelConverter();
    const { data } = await axios.post<PackageSellerCreateModel>(
        '/api/seller/packages',
        modelConverter.toApiModel(createPackageListingRequest)
    );
    return data;
};

export const useCreatePackageListing = () =>
    useMutation({
        mutationFn: (createPackageListingRequest: PackageSellerCreateModel) =>
            createPackageListing(createPackageListingRequest),
    });

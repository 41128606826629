import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingMaterialAge, MaterialProperties, getMaterialAges } from '@steelbuy/domain-model';
import { FormItem, InputRadioButton, InputRadioButtonGroup, InputRadioButtonGroupAlign } from '@steelbuy/ui-primitive';
import { RequiredFieldValidationProps } from '../validation/Validation';

type MaterialAgeSelectionProps = {
    age?: ListingMaterialAge;
    ageSelected: (age?: ListingMaterialAge) => void;
    material: MaterialProperties;
} & RequiredFieldValidationProps;

export const MaterialAgeSelection = ({ age, ageSelected, forceValidation, material }: MaterialAgeSelectionProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const availableAges = getMaterialAges(material);

    useEffect(() => {
        if (
            (age !== undefined && availableAges.length && !availableAges.includes(age)) ||
            (age !== ListingMaterialAge.UNSPECIFIED && !availableAges.length)
        ) {
            // if no available ages then set to unspecified
            if (!availableAges?.length && !age) {
                return;
            }
            ageSelected(availableAges?.length ? undefined : ListingMaterialAge.UNSPECIFIED);
        }
    }, [age, ageSelected, availableAges]);

    if (availableAges.length === 0) {
        return null;
    }

    return (
        <FormItem header={t('domainModel:material.age.name')}>
            <InputRadioButtonGroup
                align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}
                value={age}
                required
                requiredMessage={t('uiDomain:validation.requiredField')}
                forceValidation={forceValidation}
            >
                {availableAges.map((materialAge) => (
                    <InputRadioButton
                        key={materialAge}
                        name="materialAge"
                        value={t(`domainModel:material.age.value.${materialAge}`)}
                        onChange={() => ageSelected(materialAge)}
                        checked={age === materialAge}
                        required
                    />
                ))}
            </InputRadioButtonGroup>
        </FormItem>
    );
};

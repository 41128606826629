import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Product } from '@steelbuy/domain-model';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import { CreateListingUnsavedChangesModal } from '../create-listing-unsaved-changes-modal/CreateListingUnsavedChangesModal';
import { PageHeader } from '../page-header/PageHeader';
import { WarehouseAddressSelection } from '../warehouse-address-selection/WarehouseAddressSelection';

export const CreateListingChoosePickupAddress = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const createListingContext = useCreateListingContext();
    const materialData = createListingContext.materialStepData;
    const isPackageListing = materialData.product === Product.PACKAGE;

    const selectedAddressId = useRef<string | undefined>(createListingContext.pickupAddressStepData.pickupAddressId);

    const goToCreateListing = () => {
        if (createListingContext.draftId) {
            navigate(
                createRouteUrl(
                    RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                    ['draftId', createListingContext.draftId],
                    ['listingType', isPackageListing ? 'package' : 'listing']
                )
            );
        } else {
            navigate(RoutePath.CREATE_LISTING_WIZARD);
        }
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.createListingChoosePickupAddress.pageTitle')}
                previousPageTitle={t('application.createListingChoosePickupAddress.previousPageTitle')}
                onBackClick={goToCreateListing}
            />
            <DetailsLayout>
                <WarehouseAddressSelection
                    selectedAddressId={selectedAddressId.current}
                    onCancel={goToCreateListing}
                    onSelect={(newAddressId) => {
                        createListingContext.updatePickupAddressStepData({
                            pickupAddressId: newAddressId,
                        });
                        goToCreateListing();
                    }}
                />
            </DetailsLayout>
            <CreateListingUnsavedChangesModal />
        </>
    );
};

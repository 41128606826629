import { useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

import './TermsAndConditions.scss';

interface LinkProps {
    text: string;
}

const Link = ({ text }: LinkProps) => (
    <CustomLink path={text} isInlineLink isAnchorLink>
        {text}
    </CustomLink>
);

const Address = () => {
    const { t } = useTranslation('translation');
    return (
        <p>
            <span>
                <span>
                    {t('constants.primaryAddress.line1')} {t('constants.primaryAddress.line2')}
                </span>
                <br />
                <span>{t('constants.primaryAddress.line3')}</span>
                <br />
                <span>{t('constants.primaryAddress.line4')}</span>
                <br />
                <span>{t('constants.primaryAddress.line5')}</span>
                <br />
                <span>{t('constants.primaryAddress.postcode')}</span>
            </span>
        </p>
    );
};

export const WebsiteTerms = () => {
    const { t } = useTranslation('translation');

    const marketplaceText = `https://${t('constants.marketplace')}`;
    const websiteText = `https://${t('constants.website')}`;

    return (
        <div className="terms-box">
            <h2
                id="please-read-these-terms-and-
                conditions-carefully-before-using-our-marketplace-and-our-website"
            >
                PLEASE READ THESE TERMS AND CONDITIONS OF USE OF OUR MARKETPLACE AND OUR WEBSITE CAREFULLY BEFORE USING
                Our Marketplace and Our Website
            </h2>

            <h3 id="whats-in-these-terms">What&apos;s in these Terms of Use</h3>
            <p>
                These terms and conditions of use of Our Marketplace and Our Website (Terms of Use) tell you the rules
                for using Our sites:
            </p>
            <ol className="numberedOl">
                <li>
                    SteelBuy online marketplace&nbsp;
                    <Link text={marketplaceText} /> (<strong>Our Marketplace</strong>)
                </li>
                <li>
                    SteelBuy marketing website&nbsp;
                    <Link text={websiteText} /> (<strong>Our Website</strong>
                    ).
                </li>
            </ol>

            <h3 id="who-we-are-and-how-to-contact-us">Who We are and how to contact Us</h3>
            <p>
                The sites&nbsp;
                <Link text={marketplaceText} /> (Our Marketplace) and&nbsp;
                <Link text={websiteText} /> (Our Website) are operated by Steelbuy Limited (<strong>We</strong> and
                <strong>Us</strong>
                ). We are registered in England and Wales under company number
                {t('constants.companyNumber')} and have Our registered office and main trading address at:
            </p>
            <Address />
            <p>
                Our VAT number is
                {t('constants.vatNumber')}.
            </p>
            <p>
                To contact Us, please email&nbsp;
                <CustomLink path={`mailto:${t('constants.emailSupport')}`} isInlineLink isAnchorLink>
                    {t('constants.emailSupport')}
                </CustomLink>{' '}
                or telephone Our customer service line on&nbsp;
                {t('constants.customerServiceTel')}.
            </p>

            <h3 id="business-users-only">Business Users only</h3>
            <p>
                By using Our Marketplace and/or Our Website you accept these Terms of Use. Please note that Our
                Marketplace is solely intended for business users. Our Marketplace is not intended for domestic or
                private use. You agree not to use Our Marketplace for any domestic or private use. By using Our
                Marketplace and/or Our Website, you confirm that you accept these Terms of Use and that you agree to
                comply with them. If you do not agree to these Terms of Use, you must not use Our Marketplace or Our
                Website.
            </p>
            <p>We recommend that you print a copy of these Terms of Use for future reference.</p>

            <h3
                id="registration-needed-how-
              to-register-for-the-use-of-our-marketplace-https-steelbuy-app-"
            >
                Registration Needed; How to register for the use of Our Marketplace ({marketplaceText})
            </h3>
            <p>
                The use of Our Marketplace is restricted to registered users only. It is therefore necessary that you
                register once before using Our Marketplace as either buyer, seller or buyer &amp; seller of goods. You
                can then simply log in with the log-in data you created during the initial registration for every
                further use of Our Marketplace.{' '}
            </p>
            <p>
                Registration is only possible for natural persons as representatives of an entrepreneur, although
                several persons can of course register as authorised users for one and the same company. In this case, a
                separate registration is required for each representative of your company who is to be able to use Our
                Marketplace.{' '}
            </p>
            <p>You can easily register to use Our Marketplace in the following steps: </p>
            <p>
                Please complete the registration form on Our Website (
                <Link text={websiteText} />
                ). You must provide, at least, the following information: Company name and registered seat of your
                company, surname, first name of your representative as user of the platform and the email address of the
                designated user. Before completing the registration, you have the opportunity to check your entries and
                correct them if necessary.
            </p>
            <p>
                Each user undertakes and assures accordingly not to provide any false information about his/her company
                and/or person. All deposited data shall be checked regularly to ensure that they are correct and up to
                date for the entire duration of the registration.{' '}
            </p>
            <p>
                After receipt of your registration application, We will activate each user as soon as possible after
                checking the data entered. The user will receive a link to his or her email address. By clicking on this
                link, they can then enter a password and complete their registration. The user can change the access
                data generated in this way at any time later.{' '}
            </p>
            <p>
                We are not obliged to activate or accept your registration application. We are entitled to reject
                registration applications at Our own discretion or to restrict the use of Our Marketplace at any time
                without giving reasons or to exclude individual users. We also reserve the right to close user accounts.
                This applies in particular in the event that a user violates applicable law or contractual agreements,
                including these Terms of Use, during or on the occasion of the use of Our Marketplace.
            </p>
            <p>
                The user can delete his user account on Our Marketplace at any time and arrange for his de-registration.
                To do so, it is sufficient to send a corresponding message to the email address provided above. We will
                delete the affected user account immediately after receipt of such a de-registration request during
                ongoing business operations. Any de-registration does not affect any rights and obligations accrued at
                the time of de-registration.
            </p>

            <h3 id="there-are-other-terms-that-may-apply-to-you">There are other terms that may apply to you</h3>
            <p>
                These Terms of Use refer to the following additional terms, which also apply to your use of Our
                Marketplace and Our Website:
            </p>
            <ul>
                <li>
                    <CustomLink path={RoutePath.PRIVACY_POLICY} isInlineLink>
                        Our Privacy Policy
                    </CustomLink>
                </li>
                <li>
                    <CustomLink path={RoutePath.ACCEPTABLE_USE_POLICY} isInlineLink>
                        Our Acceptable Use Policy
                    </CustomLink>{' '}
                    which sets out the permitted uses and prohibited uses of Our Marketplace and Our Website. When using
                    Our Marketplace and/or Our Website, you must comply with this Acceptable Use Policy.
                </li>
                <li>
                    <CustomLink path={RoutePath.COOKIE_POLICY} isInlineLink>
                        Our Cookie Policy
                    </CustomLink>
                    , which sets out information about cookies on Our Marketplace and Our Website.
                </li>
            </ul>
            <p>
                If you purchase goods or services from Our Marketplace,&nbsp;
                <CustomLink path={RoutePath.TERMS_BUYER} isInlineLink>
                    Our Buyer Terms and Conditions
                </CustomLink>{' '}
                will apply to those purchases. If you sell goods or services using Our Marketplace,&nbsp;
                <CustomLink path={RoutePath.TERMS_SELLER} isInlineLink>
                    Our Seller Terms and Conditions
                </CustomLink>{' '}
                will apply to those sales.{' '}
            </p>
            <p>
                If you order goods from Our Marketplace and if you make a pre-payment for the full price of the goods
                and We do not obtain the goods for you then We will refund the pre-payment in full at the time We notify
                you that We have not obtained the goods for you, or the date on which your order expires (whichever is
                the sooner). For details on when this happens, please see&nbsp;
                <CustomLink path={RoutePath.TERMS_BUYER} isInlineLink>
                    Our Buyer Terms and Conditions
                </CustomLink>
                . We will not pay interest on any pre-payment. You will not receive an invoice for the goods unless We
                enter into a contract with you to sell you them. This will only occur if and when We obtain the goods
                for you.{' '}
            </p>

            <h3 id="we-may-make-changes-to-these-terms">We may make changes to these Terms of Use</h3>
            <p>
                We may amend these Terms of Use from time to time. Every time you wish to use Our Marketplace and/or Our
                Website, please check these Terms of Use to ensure you understand the Terms of Use that apply at that
                time.{' '}
            </p>

            <h3 id="we-may-make-changes-to-our-marketplace">
                We may make changes to Our Marketplace and/or Our Website
            </h3>
            <p>We may update and change Our Marketplace and/or Our Website from time to time.</p>

            <h3 id="we-may-suspend-or-withdraw-our-marketplace-and-or-our-website">
                We may suspend or withdraw Our Marketplace and/or Our Website
            </h3>
            <p>
                Our Marketplace and Our Website are made available free of charge. We do not guarantee that Our
                Marketplace and/or Our Website, or any content on them, will always be available or be uninterrupted. We
                may suspend or withdraw or restrict the availability of all or any part of Our Marketplace and/or Our
                Website for business and operational reasons. We will try to give you reasonable notice of any
                suspension or withdrawal of Our Marketplace.
            </p>
            <p>
                You are also responsible for ensuring that all persons who access Our Marketplace and/or Our Website
                through your internet connection are aware of these Terms of Use and other applicable terms and
                conditions, and that they comply with them.
            </p>

            <h3 id="we-may-transfer-this-agreement-to-someone-else">We may transfer this agreement to someone else</h3>
            <p>
                We may transfer Our rights and obligations under these Terms of Use to another organisation. We will
                always tell you in writing if this happens and We will ensure that the transfer will not affect your
                rights under the contract.
            </p>

            <h3 id="our-marketplace-is-only-for-business-users-in-the-uk-and-the-other-countries-detailed-on-our-marketplace">
                Our Marketplace is only for business users in the UK and the other countries detailed on Our Marketplace
            </h3>
            <p>
                Our Marketplace is directed to people residing in the UK and the other countries detailed on Our
                Marketplace. We do not represent that content available on or through Our Marketplace is appropriate for
                use or available in other locations.
            </p>

            <h3 id="all-registered-users-must-keep-their-account-details-safe">
                All registered users must keep their account details safe
            </h3>
            <p>
                If you choose, or you are provided with, a user identification code, password or any other piece of
                information as part of Our security procedures, you must treat such information as confidential. You
                must not disclose it to any third party.
            </p>
            <p>
                We have the right to disable any user identification code or password, whether chosen by you or
                allocated by Us, at any time, if in Our reasonable opinion you have failed to comply with any of the
                provisions of these Terms of Use.
            </p>
            <p>
                If you know or suspect that anyone other than you knows your user identification code or password, you
                must promptly notify Us at the contact details provided above.
            </p>

            <h3 id="how-you-may-use-material-on-our-marketplace-and-our-website">
                How you may use material on Our Marketplace and Our Website
            </h3>
            <p>
                We are the owner or the licensee of all intellectual property rights in Our Marketplace and Our Website,
                and in the material published on it. Those works are protected by copyright laws and treaties around the
                world. All such rights are reserved.
            </p>
            <p>
                You may print off one copy, and may download extracts, of any page(s) from Our Marketplace and Our
                Website for your personal use and you may draw the attention of others within your organisation to
                content posted on Our Marketplace and Our Website.
            </p>
            <p>
                You must not modify the paper or digital copies of any materials you have printed off or downloaded in
                any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics
                separately from any accompanying text.
            </p>
            <p>
                Our status (and that of any identified contributors) as the authors of content on Our Marketplace and/or
                Our Website must always be acknowledged (except where the content is user-generated).
            </p>
            <p>
                You must not use any part of the content on Our Marketplace and/or Our Website for commercial purposes
                (other than the buying and selling of goods through Our Marketplace) without obtaining a license to do
                so from Us or Our licensors.
            </p>
            <p>
                If you print off, copy, download, share or repost any part of Our Marketplace and/or Our Website in
                breach of these Terms of Use, your right to use Our Marketplace and Our Website will cease immediately
                and you must compensate Us for any losses We incur as a consequence and, at Our option, return or
                destroy any copies of the materials you have made.
            </p>

            <h3 id="no-text-or-data-mining-or-web-scraping">No text or data mining, or web scraping</h3>
            <p>
                You shall not conduct, facilitate, authorise or permit any text or data mining or any systematic
                (automatic or not automatic) analysis of data or web scraping in relation to Our Marketplace and/or Our
                Website or any services provided via, or in relation to, Our Marketplace and/or Our Website. This
                includes, but is not limited to, using (or permitting, authorising or attempting the use of):
            </p>
            <ul>
                <li>
                    Any &quot;robot&quot;, &quot;bot&quot;, &quot;spider&quot;, &quot;scraper&quot; or other automated
                    device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or
                    republish any portion of Our Marketplace and/or Our Website or any data, content, information or
                    services accessed via the same.
                </li>
                <li>
                    Any automated or manual analytical technique aimed at analysing text and data in digital form to
                    generate information which includes but is not limited to patterns, trends and correlations.
                </li>
            </ul>
            <p>
                The provisions in this clause should be treated as an express reservation of Our rights in this regard,
                including for the purposes of Article 4(3) of Digital Copyright Directive (<em>(EU) 2019/790</em>
                ).
            </p>
            <p>
                This clause shall not apply insofar as (but only to the extent that) We are unable to exclude or limit
                text or data mining or web scraping activity by contract under the laws which are applicable to Us.
            </p>
            <p>
                Our Marketplace and Our Website, their content and any services provided in relation to the same are
                only targeted to, and intended for use by, individuals located in the UK and the other countries
                detailed on Our Marketplace and/or Our Website (each, a Permitted Territory). By continuing to access,
                view or make use of Our Marketplace and/or Our Website, and any related content and services, you hereby
                warrant and represent to Us that you are located in a Permitted Territory. If you are not located in a
                Permitted Territory, you must immediately discontinue use of Our Marketplace and Our Website and any
                related content and services.
            </p>

            <h3 id="do-not-rely-on-information-on-our-marketplace-and-our-website">
                Do not rely on information on Our Marketplace and Our Website
            </h3>
            <p>
                The content on Our Marketplace and Our Website is provided for general information only. It is not
                intended to amount to advice on which you should rely. You must obtain professional or specialist advice
                before taking, or refraining from, any action on the basis of the content on Our Marketplace and Our
                Website.
            </p>
            <p>
                Although We make reasonable efforts to update the information on Our Marketplace and Our Website, We
                make no representations, warranties or guarantees, whether express or implied, that the content on Our
                Marketplace and Our Website is accurate, complete or up to date.
            </p>

            <h3 id="we-are-not-responsible-for-websites-we-link-to">We are not responsible for sites We link to</h3>
            <p>
                Where Our Marketplace and/or Our Website contain links to other sites and resources provided by third
                parties, these links are provided for your information only. Such links should not be interpreted as
                approval by Us of those linked sites or information you may obtain from them.{' '}
            </p>
            <p>
                We have no control over the contents of those sites or resources. The operators of the respective linked
                sites or providers of other resources are responsible for the content and accuracy of the information
                provided. At the time of linking, We were not aware of any legal violations.
            </p>

            <h3 id="user-generated-content-is-not-approved-by-us">User-generated content is not approved by Us</h3>
            <p>
                Our Marketplace and Our Website may include information and materials uploaded by other users of Our
                Marketplace and/or Our Website, including goods advertised for sale. This information and these
                materials have not been verified or approved by Us. The views expressed by other users on Our
                Marketplace and Our Website do not represent Our views or values.
            </p>

            <h3 id="how-to-complain-about-content-uploaded-by-other-users">
                How to complain about content uploaded by other users
            </h3>
            <p>
                If you wish to complain about content uploaded by other users, please contact Us using the contact
                details provided above.
            </p>

            <h3 id="our-responsibility-for-loss-or-damage-suffered-by-you">
                Our responsibility for loss or damage suffered by you
            </h3>
            <p>
                References to liability in these Terms of Use includes every kind of liability arising under or in
                connection with Our Marketplace and/or Our Website including but not limited to liability in contract,
                tort (including negligence), misrepresentation, restitution or otherwise.
            </p>
            <p>
                We do not exclude or limit in any way Our liability to you where it would be unlawful to do so. This
                includes liability for death or personal injury caused by Our negligence or the negligence of Our
                employees, agents or subcontractors and for fraud or fraudulent misrepresentation, or breach of the
                terms implied by section 12 of the Sale of Goods Act 1979 (title and quiet possession).
            </p>
            <p>
                Different limitations and exclusions of liability will apply to liability arising as a result of the
                supply of any goods to you, which will be set out in&nbsp;
                <CustomLink path={RoutePath.TERMS_SELLER} isInlineLink>
                    Our Seller Terms and Conditions
                </CustomLink>
            </p>
            <p>
                Different limitations and exclusions of liability will apply to liability arising as a result of the
                purchase of any goods from you, which will be set out in&nbsp;
                <CustomLink path={RoutePath.TERMS_BUYER} isInlineLink>
                    Our Buyer Terms and Conditions
                </CustomLink>
            </p>
            <p>
                We exclude all implied conditions, warranties, representations or other terms that may apply to Our
                Marketplace and/or Our Website or any content on them.
            </p>
            <p>
                We will not be liable to you for any loss or damage, whether in contract, tort (including negligence),
                breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
            </p>
            <ul>
                <li>use of, or inability to use, Our Marketplace and/or Our Website; or</li>
                <li>use of or reliance on any content displayed on Our Marketplace and/or Our Website.</li>
            </ul>
            <p>In particular, We will not be liable for:</p>
            <ul>
                <li>loss of profits, sales, business, or revenue;</li>
                <li>loss or corruption of data, information or software;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>loss of business opportunity, goodwill or reputation; or</li>
                <li>any indirect or consequential loss or damage.</li>
            </ul>
            <p>
                If defective digital content that We have supplied, damages a device or digital content belonging to you
                and this is caused by Our failure to use reasonable care and skill, We will either repair the damage or
                pay you compensation. However, We will not be liable for damage that you could have avoided had you not
                used Our Marketplace and/or Our Website for domestic or private use.{' '}
            </p>

            <h3 id="your-responsibility-for-loss-or-damage-suffered-by-us">
                Your responsibility for loss or damage suffered by Us
            </h3>
            <p>
                You shall be liable to Us for any costs, damages or losses incurred, suffered or sustained by Us as a
                result of a breach of these Terms of Use. In addition, you are obliged to indemnify Us against claims by
                third parties arising from a breach of duty by you or any of your authorised users.
            </p>

            <h3 id="monitoring">Monitoring</h3>
            <p>
                We reserve the right, to the extent permitted by law, to analyze the usage behavior of the users of Our
                Marketplace and/or Our Website in order to monitor their compliance with these Terms of Use.
                <br />
                If We identify a breach of these Terms of Use or any other misconduct, We will request the respective
                user to comply with the regulations.
            </p>

            <h3 id="circumstances-in-which-we-will-ban-you-from-using-our-marketplace-and-our-website">
                Circumstances in which We will ban you from using Our Marketplace and/or Our Website
            </h3>
            <p>
                We reserve the right in Our absolute discretion, to exclude you from using Our Marketplace and/or Our
                Website if:
            </p>
            <ul>
                <li>you are in material or repeated breach of these Terms of Use or in breach of other law;</li>
                <li>
                    you are in material or repeated breach of the terms and conditions of any contract you enter into
                    through Our Marketplace and/or Our Website to buy or sell goods.
                </li>
            </ul>

            <h3 id="how-we-may-use-your-personal-information">How We may use your personal information</h3>
            <p>
                We will only use your personal information as set out in&nbsp;
                <CustomLink path={RoutePath.PRIVACY_POLICY} isInlineLink>
                    Our Privacy Policy
                </CustomLink>
                .
            </p>

            <h3 id="uploading-content-to-our-marketplace-and-our-website">
                Uploading content to Our Marketplace and/or Our Website
            </h3>
            <p>
                Whenever you make use of a feature that allows you to upload content to Our Marketplace and/or Our
                Website, or to make contact with other users of Our Marketplace and/or Our Website, you must comply with
                the content standards set out in&nbsp;
                <CustomLink path={RoutePath.ACCEPTABLE_USE_POLICY} isInlineLink>
                    Our Acceptable Use Policy
                </CustomLink>
                .
            </p>
            <p>
                You warrant that any such contribution does comply with those standards, and you will be liable to Us
                and indemnify Us for any breach of that warranty. This means you will be responsible for any loss or
                damage We suffer as a result of your breach of warranty.
            </p>
            <p>
                Any content you upload to Our Marketplace and/or Our Website will be considered non-confidential and
                non-proprietary. You retain all of your ownership rights in your content, but you are required to grant
                Us a limited licence in accordance with the provisions under the heading “Rights you are giving Us to
                use material you upload”.
            </p>
            <p>
                We also have the right to disclose your identity to any third party who is claiming that any content
                posted or uploaded by you to Our Marketplace and/or Our Website constitutes a violation of their
                intellectual property rights, their right to privacy, or any other legally recognised right.
            </p>
            <p>
                We have the right to remove any posting you make on Our Marketplace and/or Our Website if, in Our
                opinion, your post does not comply with the content standards set out in&nbsp;
                <CustomLink path={RoutePath.ACCEPTABLE_USE_POLICY} isInlineLink>
                    Our Acceptable Use Policy
                </CustomLink>
                .
            </p>
            <p>You are solely responsible for securing and backing up your content.</p>
            <p>We do not store terrorist content.</p>

            <h3 id="rights-you-are-giving-us-to-use-material-you-upload">
                Rights you are giving Us to use material you upload
            </h3>
            <p>
                When you upload or post content to Our Marketplace and/or Our Website, you grant Us the following rights
                to use that content:
            </p>
            <ul>
                <li>
                    a worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute,
                    prepare derivative works of, display, and perform that user-generated content in connection with the
                    service provided by Our Marketplace and across different media including to promote Our Marketplace
                    and/or Our Website or the service forever;
                </li>
                <li>
                    a worldwide, non-exclusive, royalty-free, transferable licence for other users, partners or
                    advertisers to use the content for their purposes forever.
                </li>
            </ul>

            <h3 id="we-are-not-responsible-for-viruses-and-you-must-not-introduce-them">
                We are not responsible for viruses and you must not introduce them
            </h3>
            <p>
                We do not guarantee that Our Marketplace and/or Our Website will be secure or free from bugs or viruses.
            </p>
            <p>
                You are responsible for configuring your information technology, computer programmes and platform to
                access Our Marketplace and/or Our Website. You should use your own virus protection software.
            </p>
            <p>
                You must not misuse Our Marketplace and/or Our Website by knowingly introducing viruses, trojans, worms,
                logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain
                unauthorised access to Our Marketplace and/or Our Website, the server on which Our Marketplace and/or
                Our Website are stored or any server, computer or database connected to Our Marketplace and/or Our
                Website. You must not attack Our Marketplace and/or Our Website via a denial-of-service attack or a
                distributed denial-of service attack. By breaching this provision, you would commit a criminal offence
                under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement
                authorities and We will co-operate with those authorities by disclosing your identity to them. In the
                event of such a breach, your right to use Our Marketplace and Our Website will cease immediately.
            </p>

            <h3 id="rules-about-linking-to-our-marketplace">
                Rules about linking to Our Marketplace and/or Our Website
            </h3>
            <p>
                You may link to Our Marketplace and/or Our Website, provided you do so in a way that is fair and legal
                and does not damage Our reputation or take advantage of it. You must not establish a link in such a way
                as to suggest any form of association, approval or endorsement on Our part where none exists. You may
                establish a link to the landing page of Our Marketplace and/or Our Website in any website that is owned
                by you but not otherwise. Our Marketplace and/or Our Website must not be framed on any other site. We
                reserve the right to withdraw linking permission without notice. The site in which you are linking must
                comply in all respects with the content standards set out in&nbsp;
                <CustomLink path={RoutePath.ACCEPTABLE_USE_POLICY} isInlineLink>
                    Our Acceptable Use Policy
                </CustomLink>
                . If you wish to link to or make any use of content on Our Marketplace and/or Our Website other than
                that set out above, please contact Us using the contact details provided above.
            </p>

            <h3 id="which-country-s-laws-apply-to-any-disputes-">Which country&#39;s laws apply to any disputes?</h3>
            <p>
                These Terms of Use, their subject matter and their formation (and any non-contractual disputes or
                claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of
                England and Wales subject to the Dispute Resolution process set out below.
            </p>
            <h4 id="complaints-and-dispute-resolution-rules">Complaints and Dispute Resolution Rules</h4>
            <p>
                If you have any complaint or are otherwise dissatisfied with Our Marketplace, Our Website or the
                business you conduct through Our Marketplace and/or Our Website, please raise it with Us by contacting
                Us at&nbsp;
                <CustomLink path={`mailto:${t('constants.emailSupport')}`} isInlineLink isAnchorLink>
                    {t('constants.emailSupport')}
                </CustomLink>
                . We aim to respond to all complaints within 2 business days and to resolve all complaints in no more
                than 10 business days.
            </p>
            <p>
                By continuing to use Our Marketplace and/or Our Website, you agree that any dispute arising between you
                and Us relating to (a) the use of Our Marketplace and/or Our Website; (b) these Terms of Use; and/or (c)
                any contract you enter into through Our Marketplace to buy or sell goods (including its performance,
                validity or enforceability) (<strong>Dispute</strong>
                ), shall be resolved through the use of the following process.
            </p>
            <ol type="a">
                <li>
                    either party shall give to the other written notice of the Dispute, setting out its nature and full
                    particulars (<strong>Dispute Notice</strong>
                    ), together with relevant supporting documents. On service of the Dispute Notice, duly authorised
                    person designated by you and a duly authorised person designated by Us shall attempt in good faith
                    to resolve the Dispute;
                </li>
                <li>
                    if those people are for any reason unable to resolve the Dispute within 30 days of service of the
                    Dispute Notice, the Dispute shall be referred to a director or suitable authorised senior manager of
                    your organisation and one of Our Directors who shall attempt in good faith to resolve it;
                </li>
                <li>
                    if those people are for any reason unable to resolve the Dispute within 30 days of it being referred
                    to them, the parties agree to enter into mediation in good faith to settle the Dispute in accordance
                    with the CEDR Model Mediation Procedure. Unless otherwise agreed between the parties within 90 days
                    of service of the Dispute Notice, the mediator will be nominated by CEDR. To initiate the mediation,
                    a party must give notice in writing (<strong>ADR Notice</strong>) to the other party to the Dispute,
                    referring the dispute to mediation. A copy of the ADR Notice should be sent to CEDR; and
                </li>
                <li>
                    if there is any point on the logistical arrangements of the mediation, other than nomination of the
                    mediator, upon which the parties cannot agree within 10 working days from the date of the ADR
                    Notice, where appropriate, in conjunction with the mediator, CEDR will be requested to decide that
                    point for the parties having consulted with them; and
                </li>
                <li>
                    unless otherwise agreed between the parties, the mediation will start not later than 30 days after
                    the date of the ADR Notice.
                </li>
                <li>
                    No party may commence any court proceedings in relation to the whole or part of the Dispute until it
                    has attempted to settle the Dispute by mediation and either the mediation has terminated or the
                    other party has failed to participate in the mediation, provided that the right to issue proceedings
                    is not prejudiced by a delay.
                </li>
                <li>
                    If for any reason the Dispute is not resolved within 10 working days of commencement of the
                    mediation, the Dispute shall be referred to and finally resolved by the courts of England and Wales.
                </li>
                <li>
                    Nothing in the Complaints and Dispute Resolution rules shall prevent either you or Us from applying
                    to the courts at any time for injunctive or other equitable relief.
                </li>
            </ol>

            <h3 id="our-trademarks-are-registered">Our trademarks are registered</h3>
            <p>
                [&quot;Steelbuy Exchange&quot;] is a UK registered trademark number
                {t('constants.trademarkNumber')} of
                {t('constants.tkCompanyName')}. You are not permitted to use it without Our approval.
            </p>
            <h4 id="severability">Severability</h4>
            <p>
                Should any provision of these Terms of Use be or become invalid in whole or in part, this shall not
                affect the validity of the remaining provisions.
            </p>
        </div>
    );
};

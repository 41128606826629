import { ListingBuyerAlertCollectionDataProvider, ListingBuyerSearchDataProvider } from '@steelbuy/data-provider';
import { SearchFormDataContextProvider } from '../../components/listing-search/SearchFormDataContext';
import { UrgentRequestForm } from '../../components/urgent-requests/UrgentRequestForm';

export const UrgentRequestFormView = () => (
    <ListingBuyerSearchDataProvider lazyLoad>
        <SearchFormDataContextProvider>
            <ListingBuyerAlertCollectionDataProvider lazyLoad>
                <UrgentRequestForm />
            </ListingBuyerAlertCollectionDataProvider>
        </SearchFormDataContextProvider>
    </ListingBuyerSearchDataProvider>
);

import { CurrencyCode } from '@steelbuy/currency';
import {
    MaterialType,
    Shape,
    Product,
    Definition,
    Tolerance,
    ListingSellerDraftModel,
    ListingSellerModel,
    Coating,
    CoatingColourType,
    CoatingCoverage,
    CoatingThicknessType,
    CoatingType,
    DocumentType,
    Finish,
    Grade,
    ListingMaterialAge,
    ListingMaterialOrigin,
    MillFinish,
    Polish,
    ProductType,
    Specification,
    Surface,
    Temper,
    DeliveryPromise,
    MaterialProperties,
    PackageSellerModel,
    PackageSellerDraftModel,
} from '@steelbuy/domain-model';
import { ModelPrimaryKey, MonetaryAmount, UploadFile } from '@steelbuy/domain-model-types';
import { FileUploadStatus, WizardBarItemStatus } from '@steelbuy/ui-primitive';

export enum StepVisitation {
    NEVER = 'NEVER',
    ACTIVE = 'ACTIVE',
    VISITED = 'VISITED',
}

export enum Steps {
    MATERIAL = 'MATERIAL',
    DIMENSIONS = 'DIMENSIONS',
    LIST_UPLOAD = 'LIST_UPLOAD',
    WEIGHT_PRICE = 'WEIGHT_PRICE',
    PICKUP_ADDRESS = 'PICKUP_ADDRESS',
    DELIVERY_INFO = 'DELIVERY_INFO',
}

export const DEFAULT_MATERIAL_TYPE = MaterialType.MILD_STEEL;
export const DEFAULT_PRIME = true;
export const DEFAULT_SHAPE = Shape.FLAT;
export const DEFAULT_PRODUCT = Product.COIL;
export const DEFAULT_PACKAGE_PRODUCT = Product.PACKAGE;
export const DEFAULT_DEFINITION = Definition.HOT_ROLLED;
export const stepsOrder: Steps[] = [Steps.MATERIAL, Steps.DIMENSIONS, Steps.WEIGHT_PRICE, Steps.PICKUP_ADDRESS];
export const packageStepsOrder: Steps[] = [Steps.MATERIAL, Steps.LIST_UPLOAD, Steps.WEIGHT_PRICE, Steps.DELIVERY_INFO];
export const allSteps = [...new Set([...stepsOrder, ...packageStepsOrder])];
export const getSteps = (materialProperties: MaterialProperties) =>
    materialProperties.product === Product.PACKAGE ? packageStepsOrder : stepsOrder;
export const getCurrentStepIndex = (materialProperties: MaterialProperties, stepId: Steps) =>
    getSteps(materialProperties).indexOf(stepId);

export interface MaterialStepData {
    sku?: string;
    materialType: MaterialType;
    prime: boolean;
    description?: string;
    testCertificate?: UploadFile[];
    picture?: UploadFile[];
    shape: Shape;
    product: Product;
    definition?: Definition;
    plateType?: ProductType;
    grade?: Grade;
    specification?: Specification;
    surface?: Surface;
    finish?: Finish;
    coating?: Coating;
    coatingThicknessValue?: CoatingThicknessType;
    age?: ListingMaterialAge;
    origins?: ListingMaterialOrigin[];
    temper?: Temper;
    coatingColour?: CoatingColourType;
    coatingType?: CoatingType;
    coatingCoverage?: CoatingCoverage;
    millFinish?: MillFinish;
    polish?: Polish;
    packageTitle?: string;
    packageDescription?: string;
    packageFile?: UploadFile[];
}

export interface DimensionsStepData {
    thickness?: number;
    tolerance?: Tolerance;
    width?: number;
    length?: number;
}

export interface ListUploadStepData {
    packageTitle?: string;
    packageDescription?: string;
    packageFile?: UploadFile[];
}

export interface WeightPriceStepData {
    weight?: number;
    numberOfItems?: number;
    sellingPriceAmount?: MonetaryAmount;
    sellingPriceCurrencyCode?: CurrencyCode;
    expirationDate?: Date;
    autoRenew?: boolean;
}

export interface PickupAddressStepData {
    pickupAddressId?: string;
    invoiceAddressId?: string;
    additionalInformation?: string;
    deliveryTimeframe?: DeliveryPromise;
}

export type InitialCreateListingData = {
    materialStepData: MaterialStepData;
    dimensionsStepData: DimensionsStepData;
    weightPriceStepData: WeightPriceStepData;
};

export type GoBack = {
    link: string;
    label: string;
} | null;

export interface CreateListingContextValue {
    draftId?: ModelPrimaryKey;
    rejectedListingId?: ModelPrimaryKey;
    savedDraft: (draft: ListingSellerDraftModel | PackageSellerDraftModel) => void;
    materialStepData: MaterialStepData;
    updateMaterialStepData: (partialData: Partial<MaterialStepData>) => void;
    listUploadStepData: ListUploadStepData;
    updateListUploadStepData: (partialData: Partial<ListUploadStepData>) => void;
    dimensionsStepData: DimensionsStepData;
    updateDimensionsStepData: (partialData: Partial<DimensionsStepData>) => void;
    weightPriceStepData: WeightPriceStepData;
    updateWeightPriceStepData: (partialData: Partial<WeightPriceStepData>) => void;
    pickupAddressStepData: PickupAddressStepData;
    updatePickupAddressStepData: (partialData: Partial<PickupAddressStepData>, ignoreUnsavedChanges?: boolean) => void;
    setStepStatus: (stepId: string, status: WizardBarItemStatus) => void;
    getStepStatus: (stepId: string) => WizardBarItemStatus;
    currentStepId: Steps;
    setCurrentStepId: (stepId: Steps) => void;
    reset: () => void;
    createSame: () => void;
    copyFromListing: (listing: ListingSellerModel) => void;
    copyFromPackage: (packageListing: PackageSellerModel) => void;
    setInitialFormData: (initialFormData: InitialCreateListingData) => void;
    loadFromDraft: (draft: ListingSellerDraftModel) => void;
    loadFromPackageDraft: (draft: PackageSellerDraftModel) => void;
    setGoBack: (goBack: GoBack) => void;
    goBack: GoBack;
    loadFromRejectedListing: (draft: ListingSellerDraftModel, rejectedListingId: ModelPrimaryKey) => void;
    unsavedChanges: boolean;
    clearRejectedListing: () => void;
    certificatesStatus: Record<string, FileUploadStatus>;
    picturesStatus: Record<string, FileUploadStatus>;
    packageStatus: Record<string, FileUploadStatus>;
    updateFileStatus: (type: DocumentType, filename: string, status?: FileUploadStatus, clearFiles?: boolean) => void;
    addFile: (type: DocumentType, file: UploadFile, isMulti: boolean) => void;
}

import { useTranslation } from 'react-i18next';
import { OrderStatus } from '@steelbuy/domain-model';
import {
    Card,
    CardContentTracking,
    CardLayout,
    Notification,
    NotificationLevel,
    TrackingIndicator,
    TrackingIndicatorCircleText,
    TrackingIndicatorCircleTextStates,
    TrackingIndicatorLine,
    TrackingIndicatorLineStates,
} from '@steelbuy/ui-primitive';

type MyOrderDetailsStatusProps = {
    status: OrderStatus;
    description?: string;
};

export const MyOrderDetailsStatus = ({ description, status }: MyOrderDetailsStatusProps) => {
    const { t } = useTranslation('translation');

    const processingState = [
        TrackingIndicatorCircleTextStates.COMPLETED,
        TrackingIndicatorLineStates.COMPLETED,
    ] as const;

    const getProcessingLabel = () => {
        if (status === OrderStatus.PROCESSING) {
            return t('application.myOrderDetailsStatus.processing');
        }
        return t('application.myOrderDetailsStatus.processed');
    };

    const freightBookedState =
        status !== OrderStatus.PROCESSING
            ? ([TrackingIndicatorCircleTextStates.COMPLETED, TrackingIndicatorLineStates.COMPLETED] as const)
            : ([TrackingIndicatorCircleTextStates.UNCOMPLETED, TrackingIndicatorLineStates.UNCOMPLETED] as const);

    const getFreightBookedLabel = () => t('application.myOrderDetailsStatus.freight_booked');

    const shippingState =
        status !== OrderStatus.PROCESSING && status !== OrderStatus.FREIGHT_BOOKED
            ? ([TrackingIndicatorCircleTextStates.COMPLETED, TrackingIndicatorLineStates.COMPLETED] as const)
            : ([TrackingIndicatorCircleTextStates.UNCOMPLETED, TrackingIndicatorLineStates.UNCOMPLETED] as const);

    const getShippingLabel = () => {
        if (status !== OrderStatus.PROCESSING && status !== OrderStatus.FREIGHT_BOOKED) {
            return t('application.myOrderDetailsStatus.shippingConfirmed');
        }
        return t('application.myOrderDetailsStatus.shipping');
    };

    const deliveryState =
        status === OrderStatus.DELIVERED
            ? ([TrackingIndicatorCircleTextStates.COMPLETED, TrackingIndicatorLineStates.COMPLETED] as const)
            : ([TrackingIndicatorCircleTextStates.UNCOMPLETED, TrackingIndicatorLineStates.UNCOMPLETED] as const);

    const getDeliveryLabel = () => {
        if (status === OrderStatus.DELIVERED) {
            return t('application.myOrderDetailsStatus.delivered');
        }
        return t('application.myOrderDetailsStatus.delivery');
    };

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentTracking header={t('application.myOrderDetailsStatus.cardHeader')}>
                    <TrackingIndicator>
                        <TrackingIndicatorCircleText label={getProcessingLabel()} state={processingState[0]} />
                        <TrackingIndicatorLine state={processingState[1]} />
                        <TrackingIndicatorCircleText label={getFreightBookedLabel()} state={freightBookedState[0]} />
                        <TrackingIndicatorLine state={freightBookedState[1]} />
                        <TrackingIndicatorCircleText label={getShippingLabel()} state={shippingState[0]} />
                        <TrackingIndicatorLine state={shippingState[1]} />
                        <TrackingIndicatorCircleText label={getDeliveryLabel()} state={deliveryState[0]} />
                    </TrackingIndicator>
                </CardContentTracking>
                {description && <Notification level={NotificationLevel.WARNING} message={description} />}
            </CardLayout>
        </Card>
    );
};

import { ModelConverter } from '@steelbuy/domain-model-converter';
import {
    DateRange,
    ModelPrimaryKey,
    TimestampFieldNames,
    Timestamp,
    DateRangeFieldNames,
    ArrayOfTimestampFieldNames,
    ArrayOfDateRangeFieldNames,
    UrlFieldNames,
    ArrayOfUrlFieldNames,
    Url,
} from '@steelbuy/domain-model-types';

export type FooModel = {
    readonly id: ModelPrimaryKey;
    name: string;
    dateOfBirth: Timestamp;
    dates?: Array<Timestamp>;
    readonly range: DateRange;
    url: Url;
    readonly urls: Array<Url>;
};

export class FooModelConverter extends ModelConverter<FooModel> {
    override getTimestampFields(): Array<TimestampFieldNames<FooModel> | ArrayOfTimestampFieldNames<FooModel>> {
        return ['dateOfBirth', 'dates'];
    }

    override getDateRangeFields(): Array<DateRangeFieldNames<FooModel> | ArrayOfDateRangeFieldNames<FooModel>> {
        return ['range'];
    }

    override getUrlFields(): Array<UrlFieldNames<FooModel> | ArrayOfUrlFieldNames<FooModel>> {
        return ['url', 'urls'];
    }
}

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { PageHeader } from '../page-header/PageHeader';

export const MyOrderDetailsNotFound = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                pageTitle={t('application.myOrderDetailsNotFound.pageTitle')}
                previousPageTitle={t('application.myOrderDetailsNotFound.previousPageTitle')}
                onBackClick={() => navigate(RoutePath.MY_ORDERS)}
            />

            <TableLayout>
                <Notification
                    level={NotificationLevel.WARNING}
                    message={t('application.myOrderDetailsNotFound.notFoundMessage')}
                />
            </TableLayout>
        </>
    );
};

import { ApiDateRange, DateRange } from './DateRange';

export const isDateRange = (dateRange?: unknown): dateRange is DateRange => {
    if ((dateRange ?? null) === null) {
        return false;
    }
    return (
        (dateRange as DateRange).discriminator === 'DateRange' &&
        (dateRange as DateRange).start !== undefined &&
        (dateRange as DateRange).end !== undefined
    );
};

export const isApiDateRange = (apiDateRange?: unknown): apiDateRange is ApiDateRange => {
    if ((apiDateRange ?? null) === null) {
        return false;
    }
    return (apiDateRange as ApiDateRange).start !== undefined && (apiDateRange as ApiDateRange).end !== undefined;
};

import { UserModel, UserRole, isFinancialDetailsWithBalanceModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';

export const userHasRole = (userModel: Nullable<Readonly<UserModel>>, roles: UserRole[]) =>
    !!(userModel && roles.some((role) => userModel?.roles?.includes(role)));

export const getAvailableCredit = (userModel: Nullable<Readonly<UserModel>>) => {
    const creditLimit = userModel?.organisation?.financialDetails?.erpCreditLimit.value;
    let erpBalance = 0;

    if (isFinancialDetailsWithBalanceModel(userModel?.organisation?.financialDetails)) {
        erpBalance = userModel?.organisation?.financialDetails?.erpBalance.value ?? 0;
    }

    return creditLimit ? creditLimit - erpBalance : 0;
};

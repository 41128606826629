import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PackageSellerDraftModel, PackageSellerDraftModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';

interface PackageDraftViewsOptions<Model> {
    packageId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

const modelConverter = new PackageSellerDraftModelConverter();

const getDraftPackageDetails = async (packageId: string) => {
    const { data } = await axios.get<PackageSellerDraftModel>(`/api/universal/draft-packages/${packageId}`);
    const packageModel = modelConverter.toViewModel(data as unknown as ApiModel<PackageSellerDraftModel>);
    return packageModel;
};

export const useDraftPackage = ({ packageId, queryOptions }: PackageDraftViewsOptions<PackageSellerDraftModel>) =>
    useQuery({
        queryKey: ['draft-package-search', packageId],
        queryFn: () => getDraftPackageDetails(packageId),
        ...queryOptions,
    });

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Finish, getFinishes, MaterialProperties } from '@steelbuy/domain-model';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialFinishSelection = ({
    finishSelected,
    forceValidation,
    material,
    required,
}: {
    material: MaterialProperties;
    finishSelected: (finish?: Finish) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const availableFinishes = getFinishes(material)?.slice()?.sort();
    const { finish } = material;

    useEffect(() => {
        if (finish !== undefined && !availableFinishes.includes(finish)) {
            finishSelected(undefined);
        }
    }, [finish, finishSelected, availableFinishes]);

    if (availableFinishes.length === 0) {
        return null;
    }

    const materialFinishOptions = availableFinishes.map((materialFinish) => ({
        label: t(`domainModel:material.finish.value.${materialFinish}`),
        value: materialFinish,
    }));

    return (
        <FormItem header={t('domainModel:material.finish.name')}>
            <InputSelect
                options={materialFinishOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={finish}
                onSelect={(value) => finishSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};

import { InputTextfield } from '../input-textfield/InputTextfield';
import './CardContentCheckoutPurchaseOrderNumber.scss';

export type CardContentCheckoutPurchaseOrderNumberProps = {
    buyerPurchaseOrderNumber?: string;
    onChangeTextInput?: (value: string) => void;
    header: string;
    placeholderText?: string;
    error?: string;
    onBlur?: () => void;
};

export const CardContentCheckoutPurchaseOrderNumber = ({
    buyerPurchaseOrderNumber,
    header,
    onChangeTextInput,
    placeholderText,
    error,
    onBlur,
}: CardContentCheckoutPurchaseOrderNumberProps) => (
    <article className="card-content-purchase-order-number">
        <article className="card-content-purchase-order-number__header">{header}</article>
        <article>
            <InputTextfield
                onChange={onChangeTextInput}
                value={buyerPurchaseOrderNumber}
                maxLength={35}
                placeholder={placeholderText}
                error={error}
                onBlur={onBlur}
            />
        </article>
    </article>
);

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'react-router-dom';
import { useCreateRFQDraft, useUpdateRFQDraft } from '@steelbuy/data-access';
import { useBlocker } from '@steelbuy/ui-domain';
import { ButtonPrimary, LoadingStatus } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { CreateUnsavedChangesModal } from '../common-utils/CreateUnsavedChangesModal';
import { getCreateRFQDraftModel } from '../create-rfq/createRFQUtil';
import { useRfqFormContext } from '../create-rfq/RfqFormContext';

export const CreateRFQUnsavedChangesModal = ({ onError }: { onError: () => void }) => {
    const { t } = useTranslation('translation');
    const { hasUnsavedChanges, getUpdatedFormValues, draftId } = useRfqFormContext();
    const allowNavigation = useCallback(
        (newLocation: Location) =>
            newLocation.state?.allowNavigation ||
            (newLocation?.pathname.startsWith(RoutePath.CREATE_RFQ) && !newLocation.state?.fromNavbar) ||
            !hasUnsavedChanges(),
        [hasUnsavedChanges]
    );
    const [modal, unblockAndRetry] = useBlocker(true, allowNavigation);
    const [error, setError] = useState<boolean>(false);
    const createRFQDraftMutation = useCreateRFQDraft();
    const updateRFQDraftMutation = useUpdateRFQDraft();

    const handleSubmit = () => {
        const rfqData = getUpdatedFormValues();
        const payload = getCreateRFQDraftModel(rfqData);
        const mutateOptions = {
            onSuccess: () => {
                unblockAndRetry();
            },
            onError: () => {
                modal.hide();
                onError();
            },
        };
        if (!payload) {
            onError();
            return;
        }
        if (draftId) {
            updateRFQDraftMutation.mutate({ ...payload, id: draftId }, mutateOptions);
        } else {
            createRFQDraftMutation.mutate(payload, mutateOptions);
        }
    };

    return (
        <CreateUnsavedChangesModal
            headline={t('application.createRFQUnsavedChangesModal.headline')}
            error={error}
            modal={modal}
            setError={setError}
            unblockAndRetry={unblockAndRetry}
        >
            <ButtonPrimary
                label={t('application.createListingSaveDraftButton.label')}
                onClick={handleSubmit}
                loadingStatus={
                    createRFQDraftMutation.isLoading || updateRFQDraftMutation.isLoading
                        ? LoadingStatus.PENDING
                        : LoadingStatus.IDLE
                }
            />
        </CreateUnsavedChangesModal>
    );
};

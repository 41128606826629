import { useTranslation } from 'react-i18next';
import { PageHeader } from '../page-header/PageHeader';

type SearchResultsPageHeaderProps = {
    handleBackToSearch: () => void;
};

export const SearchResultsPageHeader = ({ handleBackToSearch }: SearchResultsPageHeaderProps) => {
    const { t } = useTranslation('translation');

    return (
        <PageHeader
            pageTitle={t('application.listingSearchResults.header.title')}
            previousPageTitle={t('application.listingSearchResults.header.backLinkText')}
            onBackClick={handleBackToSearch}
        />
    );
};

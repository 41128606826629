import { useTranslation } from 'react-i18next';
import { MarketingBannerAdvantages, MarketingBannerItem } from '@steelbuy/ui-primitive';

export const MarketingBannerSteelbuyPromise = () => {
    const { t } = useTranslation('uiDomain');

    return (
        <MarketingBannerAdvantages header={t('marketingBannerSteelbuyPromise.header')}>
            <MarketingBannerItem text={t('marketingBannerSteelbuyPromise.promiseSeller')} />
            <MarketingBannerItem text={t('marketingBannerSteelbuyPromise.promiseCertificate')} />
            <MarketingBannerItem text={t('marketingBannerSteelbuyPromise.promiseProcess')} />
        </MarketingBannerAdvantages>
    );
};

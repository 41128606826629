import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useUserCollectionData, useUserEntityData, useUserSelf } from '@steelbuy/data-provider';
import { UserModel } from '@steelbuy/domain-model';
import { Mutable } from '@steelbuy/domain-model-types';
import { NotFoundError } from '@steelbuy/error';

import { Modal, useModal } from '@steelbuy/modal-dialog';
import {
    ButtonDanger,
    ButtonSecondary,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { ROUTE_ACCOUNT_USER_MANAGEMENT } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';
import { UserForm } from '../user-form/UserForm';

import './EditUser.scss';

export const EditUser = () => {
    const userEntityData = useUserEntityData();
    const userCollectionData = useUserCollectionData();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const userSelfData = useUserSelf();
    const navigate = useNavigate();
    const modal = useModal();

    const [activeSuccess, setActiveSuccess] = useState(false);
    const [activateError, setActivateError] = useState(false);

    const newUserData = useRef<Mutable<UserModel>>();

    const userModel = userEntityData.query().getOrThrow(new NotFoundError());
    userCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            userEntityData.resolveFetchStatus();
        },
        false
    );

    userEntityData.useActionStatusEffect(
        [ActionStatus.SERVICE_SUCCESS],
        () => {
            userCollectionData.resolveFetchStatus();
            setActiveSuccess(true);
            modal.hide();
            modal.setActionPending(false);
        },
        false
    );

    userEntityData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setActivateError(true);
            modal.hide();
            modal.setActionPending(false);
        },
        false
    );

    const saveUser = (user: Mutable<UserModel>) => {
        userCollectionData.mutate(userModel.id, user);
    };

    const isUserIdSame = () => userModel.id === userSelfData.get()?.id;

    const editUser = (user: Mutable<UserModel>) => {
        newUserData.current = user;
        if (user.activated !== userModel.activated) {
            modal.show();
        } else {
            saveUser(user);
        }
    };

    const onToggleActive = () => {
        if (newUserData.current) {
            saveUser(newUserData.current);
        }
    };
    return (
        <>
            <PageHeader
                pageTitle={t('application.editUser.headerTitle')}
                previousPageTitle={t('application.editUser.backLinkText')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_USER_MANAGEMENT)}
            />
            <Modal modalAccessor={modal}>
                <ModalContent
                    onCloseModal={modal.hide}
                    headline={t(
                        `application.userForm.${
                            userModel.activated ? 'deactivateUserModal' : 'activateUserModal'
                        }.modalTitle`
                    )}
                >
                    <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/DeleteItem.svg" />
                    <ModalContentActionBar>
                        <ButtonSecondary
                            disabled={modal.actionPending}
                            label={t('uiDomain:common.cancel')}
                            onClick={modal.hide}
                        />
                        <ButtonDanger
                            // Submit button for modal
                            label={t(
                                `application.userForm.${
                                    userModel.activated ? 'deactivateUserModal' : 'activateUserModal'
                                }.confirmButtonLabel`
                            )}
                            disabled={modal.actionPending}
                            onClick={() => {
                                onToggleActive();
                                modal.setActionPending(true);
                            }}
                        />
                    </ModalContentActionBar>
                </ModalContent>
            </Modal>
            {activeSuccess && (
                <div className="edit-user--notification">
                    <Notification
                        level={NotificationLevel.SUCCESS}
                        message={t(
                            `application.editUser.toggleUserSuccess.${userModel.activated ? 'enabled' : 'disabled'}`
                        )}
                        onClose={() => setActiveSuccess(false)}
                    />
                </div>
            )}
            {activateError && (
                <div className="edit-user--notification">
                    <Notification
                        level={NotificationLevel.ERROR}
                        message={t(
                            `application.editUser.toggleUserError.${userModel.activated ? 'enabled' : 'disabled'}`
                        )}
                        onClose={() => setActivateError(false)}
                    />
                </div>
            )}
            <UserForm
                userData={userModel}
                onPrimary={editUser}
                isUserIdSame={isUserIdSame()}
                saveLabelText={t('uiDomain:common.saveChanges')}
                isEmailDisabled
            />
        </>
    );
};

import { Accordion, AccordionGroup } from '@steelbuy/ui-primitive';

import './QnA.scss';

type QuestionAndAnswer = {
    collapsedQuestion: string;
    expandedQuestion?: string;
    answer: string;
};

type QnAProps = {
    qnaTitle: string;
    questionsAndAnswers: QuestionAndAnswer[];
};

export const QnA = ({ qnaTitle, questionsAndAnswers }: QnAProps) => (
    <div className="qna">
        <AccordionGroup heading={qnaTitle}>
            {questionsAndAnswers.map((qna) => (
                <Accordion
                    key={qna.collapsedQuestion}
                    collapsedLabel={qna.collapsedQuestion}
                    extendedLabel={qna.expandedQuestion || qna.collapsedQuestion}
                >
                    {qna.answer}
                </Accordion>
            ))}
        </AccordionGroup>
    </div>
);

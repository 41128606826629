import { useQuery } from '@tanstack/react-query';
import { getToken, unauthenticatedAxios } from '../axios';

type AssociateSoftwareToken = {
    session: string;
};

interface AssociateSoftwareTokenResponse {
    session: string;
    secretCode: string;
}
const associateSoftwareToken = async (options?: AssociateSoftwareToken) => {
    if (options && 'session' in options) {
        const { data } = await unauthenticatedAxios.post<AssociateSoftwareTokenResponse>(
            '/api/auth/associate-software-token',
            {
                session: options.session,
            }
        );
        return data;
    }

    const accessToken = await getToken();
    const { data } = await unauthenticatedAxios.post<AssociateSoftwareTokenResponse>(
        '/api/auth/associate-software-token',
        {
            accessToken,
        }
    );
    return data;
};

export const useAssociateSoftwareToken = (data?: AssociateSoftwareToken) =>
    useQuery({
        queryKey: ['AssociateSoftwareToken', data],
        queryFn: () => associateSoftwareToken(data),
    });

import { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { FormContext } from './FormContext';

export type FormProviderProps = {
    children: ReactNode;
};

export const FormProvider = ({ children }: FormProviderProps): ReactElement => {
    const [isValid, setIsValid] = useState(true);
    const formRef = useRef<HTMLFormElement | null>(null);
    const setFormRef = useCallback((ref: HTMLFormElement | null) => {
        formRef.current = ref;
    }, []);

    const validate = useCallback(() => {
        setIsValid(!!formRef.current?.checkValidity());
    }, [formRef.current]);

    const provider = useMemo(
        () => ({
            isValid,
            setIsValid,
            setFormRef,
            validate,
        }),
        [isValid, setIsValid, setFormRef, validate]
    );

    return <FormContext.Provider value={provider}>{children}</FormContext.Provider>;
};

import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';
import { BadgeVariant } from '../badge-state/BadgeVariant.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './BadgeSecondary.scss';

export type BadgeSecondaryProps = {
    icon?: IconIdentifier;
    label?: string;
    variant?: BadgeVariant;
};

export const BadgeSecondary = (props: BadgeSecondaryProps) => {
    const { icon, label, variant } = props;
    const badgePrimaryClassMap = {
        'badge-secondary': true,
        'badge-secondary--collapsed': !icon && !label,
        'badge-secondary--expanded-no-icon': !icon && label,
        'badge-secondary--expanded-with-icon': icon && label,
        ...(variant ? { [classNameFromEnumValue(variant, 'badge-secondary')]: true } : {}),
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="badge-secondary__label">{label}</span>;
    };

    return (
        <div className={buildClassStringFromClassMap(badgePrimaryClassMap)}>
            {renderIcon()}
            {renderLabel()}
        </div>
    );
};

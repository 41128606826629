import { OrderBuyerCollectionDataProvider } from '@steelbuy/data-provider';
import { FilterContextProvider } from '../../components/filter-context/FilterContext';
import { MyOrders } from '../../components/my-orders/MyOrders';

export const MyOrdersView = () => (
    <OrderBuyerCollectionDataProvider lazyLoad>
        <FilterContextProvider>
            <MyOrders />
        </FilterContextProvider>
    </OrderBuyerCollectionDataProvider>
);

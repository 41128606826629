import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CoatingThicknessType,
    getCoatingThickness,
    getCoatingThicknessLabelSuffix,
    getCoatingThicknessUnit,
    isTwoSidedMaterial,
    MaterialProperties,
} from '@steelbuy/domain-model';
import { FormItem, InputSelect, InputTextfieldUnit } from '@steelbuy/ui-primitive';
import { useOtherDropDown } from '../hooks/useOtherDropDown';
import { AnyValidationProps } from '../validation/Validation';
import { useNumberValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';

const OTHER = 'OTHER';

const getLabel = (thickness: string, otherLabel: string, material: MaterialProperties) => {
    if (thickness === OTHER) {
        return otherLabel;
    }
    return isTwoSidedMaterial(material) ? `${thickness}/${thickness}` : thickness;
};

export const MaterialCoatingThicknessSelection = (
    props: {
        material: MaterialProperties;
        coatingThicknessSelected: (thickness?: CoatingThicknessType) => void;
        allowOtherInput?: boolean;
    } & AnyValidationProps
) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const { material, coatingThicknessSelected, required, forceValidation, allowOtherInput = true } = props;
    const { coatingThicknessValue } = material;
    const thicknesses = getCoatingThickness(material)?.filter((thickness) => allowOtherInput || thickness !== OTHER);
    const { dropDownValue, setDropDownValue, textValue, setTextValue } = useOtherDropDown<CoatingThicknessType>(
        coatingThicknessValue,
        thicknesses,
        OTHER
    );

    const coatingThicknessUnit = getCoatingThicknessUnit(material);

    useEffect(() => {
        if (coatingThicknessValue !== undefined && !thicknesses.length) {
            coatingThicknessSelected(undefined);
        }
    }, [coatingThicknessValue, coatingThicknessSelected, thicknesses]);

    const onTextInputChange = useCallback(
        (value: string) => {
            setTextValue(value);
            coatingThicknessSelected(value);
        },
        [setTextValue, coatingThicknessSelected]
    );

    if (thicknesses.length === 0) {
        return null;
    }

    const coatingThicknessOptions = thicknesses.map((thickness) => ({
        label: getLabel(thickness, t('uiDomain:materialSelection.otherLabel'), material),
        value: thickness,
    }));

    const headerSuffix = getCoatingThicknessLabelSuffix(material);

    return (
        <FormItem header={`${t('domainModel:material.coatingThickness.name')}`} unit={headerSuffix}>
            <InputSelect
                options={coatingThicknessOptions}
                testId="coating-thickness-select"
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={dropDownValue}
                onSelect={(value) => {
                    setDropDownValue(value);
                    if (value !== OTHER) {
                        coatingThicknessSelected(value);
                    } else {
                        coatingThicknessSelected(undefined);
                    }
                }}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
            {allowOtherInput && dropDownValue === OTHER && (
                <InputTextfieldUnit
                    unit={coatingThicknessUnit}
                    label={t('uiDomain:materialSelection.otherLabel')}
                    pattern="[0-9]*\.?[0-9]*"
                    name="coatingThickness"
                    value={textValue || ''}
                    onChange={onTextInputChange}
                    validators={required ? [requiredFieldValidator, numberValidator] : undefined}
                    forceValidation={forceValidation}
                />
            )}
        </FormItem>
    );
};

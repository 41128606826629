export const errorTypes = [
    'CustomError',
    'NotFoundError',
    'StoreError',
    'TimeoutError',
    'NetworkError',
    'ApiError',
    'ValidationError',
    'AuthenticationRequiredError',
    'AuthenticationFailedError',
    'AccessDeniedError',
    'WebSocketError',
] as const;

export type ErrorDiscriminator = typeof errorTypes[number];

export type SimpleErrorObject = {
    name: string;
    message: string;
    status?: number;
    timestamp?: string;
    stack?: string;
};

export type ErrorDetailObject = {
    message: string;
};

export type DetailedErrorObject = SimpleErrorObject & {
    details?: Array<ErrorDetailObject>;
};

export type ErrorObject = SimpleErrorObject & {
    discriminator: 'CustomError';
};

export type StoreErrorObject = SimpleErrorObject & {
    discriminator: 'StoreError';
};

export type NotFoundErrorObject = SimpleErrorObject & {
    discriminator: 'NotFoundError';
};

export type TimeoutErrorObject = SimpleErrorObject & {
    discriminator: 'TimeoutError';
};

export type NetworkErrorObject = SimpleErrorObject & {
    discriminator: 'NetworkError';
};

export type ApiErrorObject = SimpleErrorObject & {
    discriminator: 'ApiError';
};

export type ValidationErrorObject = DetailedErrorObject & {
    discriminator: 'ValidationError';
};

export type AuthenticationRequiredErrorObject = SimpleErrorObject & {
    discriminator: 'AuthenticationRequiredError';
};

export type AuthenticationFailedErrorObject = SimpleErrorObject & {
    discriminator: 'AuthenticationFailedError';
};

export type AccessDeniedErrorObject = SimpleErrorObject & {
    discriminator: 'AccessDeniedError';
};

export type RateLimitErrorObject = SimpleErrorObject & {
    discriminator: 'RateLimitError';
};

export type WebSocketErrorObject = SimpleErrorObject & {
    discriminator: 'WebSocketError';
};

export type AnyErrorObject =
    | ErrorObject
    | StoreErrorObject
    | NotFoundErrorObject
    | TimeoutErrorObject
    | NetworkErrorObject
    | ApiErrorObject
    | ValidationErrorObject
    | AuthenticationRequiredErrorObject
    | AuthenticationFailedErrorObject
    | AccessDeniedErrorObject
    | WebSocketErrorObject;

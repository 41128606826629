import { useTranslation } from 'react-i18next';
import { Accordion, AccordionGroup } from '@steelbuy/ui-primitive';

import './MyAlertsQnA.scss';

export const MyAlertsQnA = () => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="my-alerts__qna">
            <AccordionGroup heading={t('myAlertsQna.header')}>
                <Accordion
                    collapsedLabel={t('myAlertsQna.items.howDoesAnAlertWork.label')}
                    extendedLabel={t('myAlertsQna.items.howDoesAnAlertWork.label')}
                >
                    {t('myAlertsQna.items.howDoesAnAlertWork.content')}
                </Accordion>

                <Accordion
                    collapsedLabel={t('myAlertsQna.items.howDoUrgentRequestsWork.label')}
                    extendedLabel={t('myAlertsQna.items.howDoUrgentRequestsWork.label')}
                >
                    {t('myAlertsQna.items.howDoUrgentRequestsWork.content')}
                </Accordion>

                <Accordion
                    collapsedLabel={t('myAlertsQna.items.howDoNotificationsWork.label')}
                    extendedLabel={t('myAlertsQna.items.howDoNotificationsWork.label')}
                >
                    {t('myAlertsQna.items.howDoNotificationsWork.content')}
                </Accordion>
                <Accordion
                    collapsedLabel={t('myAlertsQna.items.howToBestUseAlerts.label')}
                    extendedLabel={t('myAlertsQna.items.howToBestUseAlerts.label')}
                >
                    {t('myAlertsQna.items.howToBestUseAlerts.content')}
                </Accordion>
                <Accordion
                    collapsedLabel={t('myAlertsQna.items.howToMuteOrDeleteAlerts.label')}
                    extendedLabel={t('myAlertsQna.items.howToMuteOrDeleteAlerts.label')}
                >
                    {t('myAlertsQna.items.howToMuteOrDeleteAlerts.content')}
                </Accordion>
            </AccordionGroup>
        </div>
    );
};

import { ReactNode, useState } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { BadgePrimary } from '../badge-primary/BadgePrimary';
import { ButtonGhostSystemOnLightS } from '../button-ghost/button-ghost-system-on-light-s/ButtonGhostSystemOnLightS';
import { IconIdentifier } from '../icon/Icon.enums';
import { ViewHeading } from '../view-heading/ViewHeading';

import './DataHeader.scss';

type DataHeaderProps = {
    headingValue: string;
    children?: ReactNode;
    isFiltered?: boolean;
    showFilter?: boolean;
};

export const DataHeader = (props: DataHeaderProps) => {
    const { children, headingValue, isFiltered = false, showFilter = true } = props;

    // For breakpoint medium and small, we hide the section with filter and sorting by default.
    // Changing this state does not affect other breakpoints.
    const [filterAndSortCollapsed, setFilterAndSortCollapsed] = useState(true);

    const filterChildrenClassNames = {
        'data-header__filter': true,
        'data-header__filter--hidden': filterAndSortCollapsed,
    };

    return (
        <div className="data-header">
            <span className="data-header__title">
                <ViewHeading value={headingValue} />
            </span>
            {showFilter && (
                <>
                    <div className="data-header__toggle">
                        <ButtonGhostSystemOnLightS
                            icon={IconIdentifier.FILTER}
                            onClick={() => setFilterAndSortCollapsed((collapsed) => !collapsed)}
                        />
                        {isFiltered && <BadgePrimary />}
                    </div>
                    <div className={buildClassStringFromClassMap(filterChildrenClassNames)}>{children}</div>
                </>
            )}
        </div>
    );
};

import { ApiError } from '@steelbuy/error';
import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import {
    isMultiValueFilterCriteriaRule,
    isSingleValueFilterCriteriaRule,
} from '../collection-criteria/FilterCriteria.guards';
import { PreparedSortCriteria, SortCriteria, SortDirection } from '../collection-criteria/SortCriteria';

export const PARAM_DELIMITER = ';';
export const MULTI_VALUE_DELIMITER = '|';

export const createFilterString = <Model>(filter: FilterCriteria<Model>) => {
    const filterParams: Array<string> = [];
    filter.forEach((filterCriteria) => {
        filterCriteria.criteria.forEach((filterCriterion) => {
            const comparator = filterCriterion.comparator ?? '=';
            let value: string;
            if (isSingleValueFilterCriteriaRule(filterCriterion)) {
                value = filterCriterion.value.toString();
            } else if (isMultiValueFilterCriteriaRule(filterCriterion)) {
                value = filterCriterion.value.join(MULTI_VALUE_DELIMITER);
            } else {
                throw new ApiError('Invalid filter criteria');
            }
            filterParams.push((filterCriterion.property as keyof Model).toString() + comparator.toString() + value);
        });
    });

    return filterParams.join(PARAM_DELIMITER);
};

export const createSortString = <Model>(sort: SortCriteria<Model> | PreparedSortCriteria) => {
    const sortParams = sort.map<string>(
        (sortCriteria) => `${sortCriteria.property.toString()}:${sortCriteria?.direction ?? SortDirection.ASCENDING}`
    );
    return sortParams.join(PARAM_DELIMITER);
};

import { ReactNode } from 'react';

import './InputTextfieldCombination.scss';

export type InputTextfieldCombinationProps = {
    label: string;
    children: ReactNode;
};

export const InputTextfieldCombination = (props: InputTextfieldCombinationProps) => {
    const { label, children } = props;

    return (
        <article className="input-textfield-combination">
            <span className="input-textfield-combination__label">{label}</span>
            <article className="input-textfield-combination__children">{children}</article>
        </article>
    );
};

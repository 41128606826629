import { InputHTMLAttributes } from 'react';

import './InputToggleButton.scss';

export type InputToggleButtonProps = {
    value?: string;
    helperText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputToggleButton = (props: InputToggleButtonProps) => {
    const { value, helperText, ...rest } = props;

    return (
        <div>
            <label className="input-toggle-button">
                {value && <span className="input-toggle-button__value">{value}</span>}
                <div className="input-toggle-button__toggle">
                    <input className="input-toggle-button__toggle__input" type="checkbox" {...rest} />
                    <span className="input-toggle-button__toggle__slider" />
                </div>
            </label>
            {helperText && <div className="input-toggle-button-helper-text">{helperText}</div>}
        </div>
    );
};

import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { createFilterString } from '@steelbuy/api-integration';
import { ActionStatus, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';
import { AlertType, SearchFormData } from '@steelbuy/domain-model';
import { useModal } from '@steelbuy/modal-dialog';
import { MyAlertsDeletionModal } from '@steelbuy/ui-domain';
import { ButtonPrimaryDropdown, ButtonTertiaryOnLightM, IconIdentifier } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { composeFilterCriteria } from '../listing-search/FilterCriteriaHelper';

enum AlertStatus {
    NONE = 'NONE',
    CREATE_LOADING = 'CREATE_LOADING',
    DELETE_LOADING = 'DELETE_LOADING',
    CREATED = 'CREATED,',
}

interface SearchSummaryAlertButtonProps {
    searchFormData: SearchFormData;
}

export const SearchSummaryAlertButton = ({ searchFormData }: SearchSummaryAlertButtonProps) => {
    const { t } = useTranslation('translation');
    const analytics = useAnalytics();
    const listingAlertCollection = useListingBuyerAlertCollectionData();
    const deleteAlertModal = useModal();
    const navigate = useNavigate();
    const isCreatedAlertUrgent = useRef(false);

    const [status, setStatus] = useState<AlertStatus>(AlertStatus.NONE);

    const createdAlertId = listingAlertCollection.queryCreated().get()?.id;

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.CREATE_PENDING],
        () => {
            setStatus(AlertStatus.CREATE_LOADING);
        },
        false
    );

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.DELETE_PENDING],
        () => {
            setStatus(AlertStatus.DELETE_LOADING);
        },
        false
    );

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            if (isCreatedAlertUrgent.current) {
                navigate(RoutePath.MY_ALERTS, {
                    state: { successNotification: t('application.urgentRequestForm.createdAlertMessageNoMatch') },
                });
            } else {
                setStatus(AlertStatus.CREATED);
                listingAlertCollection.resolveFetchStatus();
            }
        },
        true
    );

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            setStatus(AlertStatus.NONE);
            deleteAlertModal.hide();
            listingAlertCollection.resolveFetchStatus();
        },
        true
    );

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            if (status === AlertStatus.DELETE_LOADING) {
                setStatus(AlertStatus.CREATED);
            }

            if (status === AlertStatus.CREATE_LOADING) {
                setStatus(AlertStatus.NONE);
            }
        },
        false
    );

    const createAlert = useCallback(
        (type: AlertType) => {
            isCreatedAlertUrgent.current = type === AlertType.URGENT;
            listingAlertCollection.create({
                enabled: true,
                filterQuery: createFilterString(composeFilterCriteria(searchFormData)),
                type,
                comment: searchFormData.comment,
            });
        },
        [searchFormData]
    );

    const buttonProps = {
        disabled: status === AlertStatus.CREATE_LOADING,
        icon: IconIdentifier.BELL,
        label: t('application.listingSearchResults.addAlertButtonLabel'),
    };

    const dropdownOnSelection = useCallback(
        (type: AlertType) => {
            analytics.sendEvent(TrackingEvent.CREATE_ALERT, {
                alert_type: type,
                material: searchFormData.materialType,
                created: new Date().toISOString(),
            });
            if (type === AlertType.URGENT) {
                // have to navigate to IMR form as we need weight
                navigate(RoutePath.IMMEDIATE_REQUEST, {
                    state: {
                        searchFormData,
                        forceValidation: true,
                    },
                });
                return;
            }
            createAlert(type);
        },
        [createAlert]
    );

    switch (status) {
        case AlertStatus.CREATED:
        case AlertStatus.DELETE_LOADING:
            if (!createdAlertId) {
                return null;
            }
            return (
                <>
                    <ButtonTertiaryOnLightM
                        disabled={status === AlertStatus.DELETE_LOADING}
                        label={t('application.listingSearchResults.deleteAlertButtonLabel')}
                        onClick={() => deleteAlertModal.show()}
                        icon={IconIdentifier.BIN}
                    />
                    <MyAlertsDeletionModal
                        modalAccessor={deleteAlertModal}
                        handleDelete={() => listingAlertCollection.delete(createdAlertId)}
                    />
                </>
            );
        default:
            return (
                <ButtonPrimaryDropdown<AlertType>
                    dropdownMenuProps={{
                        title: t('application.createAlert.title'),
                        options: [
                            { value: AlertType.URGENT, label: t('application.createAlert.urgentRequest') },
                            {
                                value: AlertType.REGULAR,
                                label: t('application.createAlert.nonUrgentRequest'),
                            },
                        ],
                        onSelection: dropdownOnSelection,
                    }}
                    buttonProps={buttonProps}
                />
            );
    }
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { InputValue, ValidatableElement, validate, Validator } from './InputValidation';

export const useValidation = <ElementType extends ValidatableElement>(
    currentValue?: InputValue,
    validators?: Validator[],
    forceValidation?: boolean,
    customError?: string
) => {
    const elementRef = useRef<ElementType>(null);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const doValidate = useCallback(
        (value: InputValue) => validate(validators ?? [], value, elementRef.current ?? undefined, customError),
        [validators, elementRef.current]
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (elementRef.current) {
            const input = elementRef.current;

            // This avoids "native" html error indicators and focus shift when requesting validation.
            const invalidListener = (e: Event) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
            };

            const blurListener = () => setIsTouched(true);

            elementRef.current.addEventListener('invalid', invalidListener);
            elementRef.current.addEventListener('blur', blurListener);

            return () => {
                input.removeEventListener('invalid', invalidListener);
                input.removeEventListener('blur', blurListener);
            };
        }
    }, [elementRef.current]);

    useEffect(() => {
        if (customError) {
            elementRef.current?.setCustomValidity(customError);
            elementRef.current?.reportValidity();
            return;
        }
        doValidate(currentValue);
    }, [customError, currentValue]);

    return {
        elementRef,
        validationError: forceValidation || isTouched ? doValidate(currentValue) : undefined,
        isTouched,
        setIsTouched,
        validate: doValidate,
    };
};

import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { WizardBarItemStatus } from './WizardBarItemStatus';
import { Icon } from '../../../icon/Icon';
import { IconIdentifier } from '../../../icon/Icon.enums';

import './WizardBarItem.scss';

export type WizardBarItemProps = {
    status: WizardBarItemStatus;
    label: string;
    onClick?: () => void;
    selected: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const WizardBarItem = ({
    label,
    onClick,
    selected,
    status = WizardBarItemStatus.EMPTY,
    ...rest
}: WizardBarItemProps) => {
    const itemClassMap = {
        'wizard-bar__item': true,
        [classNameFromEnumValue(status, 'wizard-bar__item')]: true,
        'wizard-bar__item--not-selected': !selected,
        'wizard-bar__item--clickable': !!onClick,
    };

    // eslint-disable-next-line consistent-return
    function renderIcon() {
        switch (status) {
            case WizardBarItemStatus.EMPTY:
                return <Icon name={IconIdentifier.WORKFLOW_EMPTY} />;

            case WizardBarItemStatus.ACTUAL:
                return <Icon name={IconIdentifier.WORKFLOW_ACTUAL} />;

            case WizardBarItemStatus.SUCCESS:
                return <Icon name={IconIdentifier.WORKFLOW_SUCCESS} />;

            case WizardBarItemStatus.INCOMPLETE:
                return <Icon name={IconIdentifier.WORKFLOW_INCOMPLETE} />;
        }
    }

    return (
        <button className={buildClassStringFromClassMap(itemClassMap)} {...rest} onClick={onClick}>
            <span className="wizard-bar__item__icon">{renderIcon()}</span>
            <span className="wizard-bar__item__text">{label}</span>
        </button>
    );
};

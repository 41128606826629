export type SearchSummaryPropertiesProps = {
    topLine: string;
    bottomLine?: string;
};

export const SearchSummaryProperties = (props: SearchSummaryPropertiesProps) => {
    const { topLine, bottomLine } = props;

    return (
        <div className="search-summary__properties">
            <div className="search-summary__properties__top-line">{topLine}</div>
            <div className="search-summary__properties__bottom-line">{bottomLine}</div>
        </div>
    );
};

import { DocumentType } from '@steelbuy/domain-model';

export const FILE_SIZE_LIMIT_IN_MEGABYTES = 5;

export const exceedsMaxFileSize = (fileSize: number) => fileSize > FILE_SIZE_LIMIT_IN_MEGABYTES * 1024 * 1024;

const isPngOrJpg = (fileExtension?: string) =>
    fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg';

const isXlsOrXlsx = (fileExtension?: string) => fileExtension === 'xls' || fileExtension === 'xlsx';

export const isValidFileType = (type: DocumentType, name: string) => {
    const fileExtension = name.split('.').pop()?.toLowerCase();
    if (type === DocumentType.PICTURE && !isPngOrJpg(fileExtension)) {
        return false;
    }

    if (type === DocumentType.ORIGINAL && !isPngOrJpg(fileExtension) && fileExtension !== 'pdf') {
        return false;
    }

    if (type === DocumentType.PACKAGE && !isXlsOrXlsx(fileExtension)) {
        return false;
    }

    return true;
};

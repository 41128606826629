import { useTranslation } from 'react-i18next';
import { Authenticated, Unauthenticated } from '@steelbuy/auth';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { PageHeader } from '../page-header/PageHeader';

import './ContactDetails.scss';

export const ContactDetails = () => {
    const { t } = useTranslation('translation');

    return (
        <>
            <Authenticated>
                <PageHeader pageTitle={t('application.contactDetails.header.title')} />
            </Authenticated>
            <Unauthenticated>
                <PageHeader pageTitle={t('application.contactDetails.header.title')} />
            </Unauthenticated>
            <div className="contact-details">
                <TableLayout>
                    <p className="contact-details__title">SteelBuy Ltd.</p>
                    <p className="contact-details__paragraph">
                        Friars Gate (Third Floor)
                        <br />
                        1011 Stratford Road
                        <br />
                        Shirley
                        <br />
                        Solihull
                        <br />
                        B90 4BN
                        <br />
                        UK
                        <br />
                        <br />
                        support@steel-buy.com
                        <br />
                        Phone: +44 (0) 121 713 7280
                        <br />
                        <br />
                        Company registration number: 01914559
                        <br />
                        VAT number: GB 695 9678 44
                        <br />
                        <br />
                        Copyright SteelBuy Ltd. 2022
                    </p>
                </TableLayout>
            </div>
        </>
    );
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonCallToAction, FormActionbar, Notification, NotificationLevel } from '@steelbuy/ui-primitive';

import './ForgotPassword.scss';

interface PasswordLinkSentProps {
    loginUrl: string;
    username: string;
}

export const PasswordLinkSent = ({ loginUrl, username }: PasswordLinkSentProps) => {
    const { t } = useTranslation('uiDomain');
    const navigate = useNavigate();

    return (
        <main className="password-link-sent">
            <header className="password-link-sent__header">
                <div>{t('passwordLinkSent.title')}</div>
                <section className="password-link-sent__header--subheading">
                    <p>{t('passwordLinkSent.subheading')}</p>
                </section>
            </header>
            <Notification
                stayOpen
                level={NotificationLevel.SUCCESS}
                message={t('passwordLinkSent.notification', { username })}
            />
            <FormActionbar>
                <ButtonCallToAction label={t('passwordLinkSent.backToSignIn')} onClick={() => navigate(loginUrl)} />
            </FormActionbar>
        </main>
    );
};

import { useMutation } from '@tanstack/react-query';
import { unauthenticatedAxios } from '../../axios';

type VerifySoftwareTokenRequest =
    | {
          session: string;
          userCode: string;
      }
    | {
          accessToken: string;
          username: string;
          userCode: string;
      };

interface VerifySoftwareTokenResponse {
    status: 'SUCCESS' | 'ERROR';
    session: string;
}

const verifySoftwareToken = async (options: VerifySoftwareTokenRequest) => {
    const { data } = await unauthenticatedAxios.post<VerifySoftwareTokenResponse>('/api/auth/verify-software-token', {
        ...options,
    });
    return data;
};

export const useVerifySoftwareToken = () =>
    useMutation({
        mutationFn: (request: VerifySoftwareTokenRequest) => verifySoftwareToken(request),
    });

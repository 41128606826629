import { useTranslation } from 'react-i18next';
import { Tenant } from '@steelbuy/api-integration';
import { LoginForm } from '../../login-form/LoginForm';
import { LoginLayout } from '../login-layout/LoginLayout';

import './Login.scss';

interface LoginProps {
    apiBaseUrl: string;
    challengeUrl: string;
    onSuccess: () => void;
    forgotPasswordUrl: string;
    tenant: Tenant;
    showRegisterLink?: boolean;
    captchaKey?: string;
}

export const Login = ({
    apiBaseUrl,
    challengeUrl,
    onSuccess,
    forgotPasswordUrl,
    tenant,
    showRegisterLink = true,
    captchaKey,
}: LoginProps) => {
    const { t } = useTranslation(['uiDomain', 'translation']);

    return (
        <LoginLayout>
            <LoginForm
                apiBaseUrl={apiBaseUrl}
                onLoginSuccess={onSuccess}
                challengeUrl={challengeUrl}
                forgotPasswordUrl={forgotPasswordUrl}
                tenant={tenant}
                captchaKey={captchaKey}
            />
            {showRegisterLink && (
                <div className="login__account">
                    {t('uiDomain:login.newAccount.label')}
                    <a href={t('translation:constants.registerLink')}>
                        &nbsp;
                        {t('uiDomain:login.newAccount.link')}
                    </a>
                </div>
            )}
        </LoginLayout>
    );
};

import { useTranslation } from 'react-i18next';
import { AddressModel } from '@steelbuy/domain-model';

import './Address.scss';

interface AddressProps {
    addressModel: AddressModel;
}

export const Address = ({
    addressModel: {
        addressLine1,
        addressLine2,
        city,
        country,
        name,
        organisation: { name: organisationName },
        postalCode,
    },
}: AddressProps) => {
    const { t } = useTranslation('domainModel');

    return (
        <article className="address">
            <span>{organisationName}</span>
            <span>{name}</span>
            <span>{addressLine1}</span>
            {addressLine2 && <span>{addressLine2}</span>}
            <span>{city}</span>
            <span>{t(`address.country.${country}`)}</span>
            <span>{postalCode}</span>
        </article>
    );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useOrganisationEntityData } from '@steelbuy/data-provider';

import {
    ButtonPrimary,
    ButtonSecondary,
    FormActionbar,
    FormItem,
    InputTextfield,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_ORGANISATION } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const EditPurchaseContact = () => {
    const organisationEntityData = useOrganisationEntityData();
    const organisationModel = organisationEntityData.query().get();
    const mutationPending = organisationEntityData.queryActionStatus() === ActionStatus.MUTATE_PENDING;

    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();

    const [nameData, setNameData] = useState(organisationModel?.purchaseContactName);
    const [emailData, setEmailData] = useState(organisationModel?.purchaseContactEmail);
    const [isMutationFailed, setIsMutationFailed] = useState(false);

    organisationEntityData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            navigate(ROUTE_ACCOUNT_ORGANISATION);
            // resolving the action status happens after navigating back to organisation tab in account view
        },
        false
    );

    organisationEntityData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setIsMutationFailed(true);
        },
        true
    );

    const handleSave = () => {
        organisationEntityData.mutate({
            purchaseContactName: nameData,
            purchaseContactEmail: emailData,
        });
    };

    const displayError = () =>
        isMutationFailed ? (
            <Notification
                level={NotificationLevel.ERROR}
                message={t('application.purchaseContactEdit.notificationErrorMessage')}
            />
        ) : null;

    const isSaveButtonDisabled = () => {
        if (!nameData || !emailData) {
            return true;
        }

        if (
            nameData === organisationModel?.purchaseContactName &&
            emailData === organisationModel?.purchaseContactEmail
        ) {
            return true;
        }

        return false;
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.purchaseContactEdit.headerTitle')}
                previousPageTitle={t('application.purchaseContactEdit.backLinkText')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
            />

            <FormLayout>
                <FormItem header="">
                    {displayError()}
                    <InputTextfield
                        name="name"
                        value={nameData}
                        onChange={setNameData}
                        autoComplete="off"
                        autoCorrect="off"
                        label={t('application.purchaseContactEdit.nameFieldLabel')}
                    />
                    <InputTextfield
                        name="email"
                        value={emailData}
                        onChange={setEmailData}
                        autoComplete="off"
                        autoCorrect="off"
                        label={t('application.purchaseContactEdit.emailFieldLabel')}
                    />
                    <FormActionbar>
                        <ButtonSecondary
                            label={t('uiDomain:common.cancel')}
                            onClick={() => {
                                navigate(ROUTE_ACCOUNT_ORGANISATION);
                            }}
                        />
                        <ButtonPrimary
                            label={t('uiDomain:common.saveChanges')}
                            onClick={handleSave}
                            disabled={isSaveButtonDisabled()}
                            loadingStatus={mutationPending ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    </FormActionbar>
                </FormItem>
            </FormLayout>
        </>
    );
};

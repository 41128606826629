import { PackageBuyerModel, PackageSellerDraftModel, PackageSellerModel } from '@steelbuy/domain-model';
import { useMaterialProperties } from '../material/Material';
import { TeaserHeader } from '../teaser-header/TeaserHeader';

export type PackageTeaserHeaderProps = {
    packages: PackageBuyerModel | PackageSellerModel | PackageSellerDraftModel;
    isNew?: boolean;
};

export const PackageTeaserHeader = ({ isNew, packages }: PackageTeaserHeaderProps) => {
    const headerProperties = useMaterialProperties(packages);

    return <TeaserHeader isNew={isNew} packages={packages} headerProperties={headerProperties} />;
};

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteDraftRFQ, useMyRFQsDrafts } from '@steelbuy/data-access';
import { useModal } from '@steelbuy/modal-dialog';
import { DeleteDraftModal, RFQDraftTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    FillHeightContainer,
    LoadingSpinner,
    LoadingStatus,
    useSuccessErrorNotification,
} from '@steelbuy/ui-primitive';
import { MyRFQsEmptyState } from './MyRFQsEmptyState';
import { RoutePath } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

import './MyRFQs.scss';

export const MyRFQsDraftsList = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, total, refetch, isRefetching } =
        useMyRFQsDrafts();
    const { mutate } = useDeleteDraftRFQ();
    const modalAccessor = useModal();
    const { Notification, showSuccessNotification, showErrorNotification, hideNotification } =
        useSuccessErrorNotification();
    const selectedDraftId = useRef<string>('');

    const editNavigateState = {
        goBackLink: RoutePath.MY_RFQS_DRAFTS,
        goBackText: t('application.myListings.drafts'),
    };

    const handleDeleteDraft = (draftId: string) => {
        modalAccessor.show();
        hideNotification();
        selectedDraftId.current = draftId;
    };

    const handleRFQDraftDelete = (draftId: string) => {
        modalAccessor.setActionPending(true);
        mutate(draftId, {
            onSuccess: () => {
                showSuccessNotification(t('application.createListingOverview.notificationSuccess'));
                modalAccessor.setActionPending(false);
                modalAccessor.hide();
                refetch();
            },
            onError: () => {
                showErrorNotification(t('application.createListingOverview.notificationError'));
                modalAccessor.setActionPending(false);
                modalAccessor.hide();
            },
        });
    };

    return (
        <div className="my-rfqs-list">
            {isLoading || isRefetching ? (
                <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                    <LoadingSpinner fillContainerHeight />
                </FillHeightContainer>
            ) : (
                <>
                    {Notification}
                    <DataHeader
                        headingValue={t('application.myRFQDraft.numberOfRFQDraftListings', {
                            count: total ?? 0,
                        })}
                        showFilter={false}
                    />
                    <div className="my-rfqs-list__listings">
                        {data?.map((rfq) => (
                            <Card
                                key={rfq.id}
                                isClickable
                                url={`${RoutePath.CREATE_RFQ_WIZARD}/${rfq.id}`}
                                linkState={{
                                    draftRFQ: rfq,
                                    ...editNavigateState,
                                }}
                            >
                                <RFQDraftTeaser
                                    rfq={rfq}
                                    onEdit={() => {
                                        navigate(`${RoutePath.CREATE_RFQ_WIZARD}/${rfq.id}`, {
                                            state: {
                                                draftRFQ: rfq,
                                                ...editNavigateState,
                                            },
                                        });
                                    }}
                                    onDelete={() => {
                                        handleDeleteDraft(rfq.id);
                                    }}
                                />
                            </Card>
                        ))}
                    </div>
                    {hasNextPage && (
                        <div className="my-rfqs-list__load-more">
                            <ButtonTertiaryOnLightM
                                onClick={() => fetchNextPage()}
                                label={t('uiDomain:commonList.loadMore')}
                                loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                            />
                        </div>
                    )}
                </>
            )}
            {data?.length === 0 && <MyRFQsEmptyState />}
            <DeleteDraftModal
                modalAccessor={modalAccessor}
                onConfirm={() => handleRFQDraftDelete(selectedDraftId.current)}
            />
        </div>
    );
};

import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useUserSelf } from '@steelbuy/data-provider';
import { UserRole } from '@steelbuy/domain-model';
import { RoutePath } from '../../router/Routes';

export const UrgentRequestsView = () => {
    const navigate = useNavigate();
    const userData = useUserSelf().get();
    const userRoleChecker = (role: UserRole) => userData?.roles?.includes(role);

    const hasUrgentRequestAccess =
        userRoleChecker(UserRole.SELLER) || userRoleChecker(UserRole.MANAGER) || userRoleChecker(UserRole.ADMIN);

    useEffect(() => {
        if (!hasUrgentRequestAccess) {
            navigate(RoutePath.DASHBOARD);
        }
    }, []);

    return <Outlet />;
};

import { ButtonPrimary } from '../../button-primary/ButtonPrimary';
import { IconIdentifier } from '../../icon/Icon.enums';

import './MarketingBannerDashboardBuy.scss';

export type MarketingBannerDashboardBuyProps = {
    header: string;
    subHeader?: string;
    descriptionText?: string[];
    buttonLabel: string;
    onClick?: () => void;
};

export const MarketingBannerDashboardBuy = (props: MarketingBannerDashboardBuyProps) => {
    const { header, subHeader, onClick, buttonLabel, descriptionText } = props;

    return (
        <article className="marketing-banner-dashboard-buy">
            <div className="marketing-banner-dashboard-buy__body">
                <div className="marketing-banner-dashboard-buy__body__content">
                    <header className="marketing-banner-dashboard-buy__body__content__header">{header}</header>
                    <div className="marketing-banner-dashboard-buy__body__content__text">{subHeader}</div>
                </div>
                {descriptionText && (
                    <div className="marketing-banner-dashboard-buy__body__subcontent">
                        <ul>
                            {descriptionText.map((text) => (
                                <li key={text}>
                                    <span className="marketing-banner-dashboard-buy__body__content__text">{text}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="marketing-banner-dashboard-buy__body__actions">
                    <ButtonPrimary label={buttonLabel} onClick={onClick} icon={IconIdentifier.SEARCH} />
                </div>
            </div>
            <img alt="A person putting an item into a box" src="/assets/lib-ui-primitive/images/MyOrders-Neutral.svg" />
        </article>
    );
};

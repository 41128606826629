import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    calculateMajorUnitFromMinorUnit,
    calculateMinorUnitFromMajorUnit,
    CurrencyCode,
    findCurrencySymbolByCode,
} from '@steelbuy/currency';
import { PricingUnit } from '@steelbuy/domain-model';
import { MonetaryAmount } from '@steelbuy/domain-model-types';
import { InputTextfieldUnit, Validator } from '@steelbuy/ui-primitive';

import { GenericValidationProps } from '../validation/Validation';
import { useNumberValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';

type MonetaryAmountInputProps = {
    name: string;
    label: string;
    initialMonetaryAmount?: MonetaryAmount;
    updateMonetaryAmount: (newMonetaryAmount?: MonetaryAmount) => void;
    currencyCode: CurrencyCode;
    pricingUnit?: PricingUnit;
    validators?: Validator[];
    showPricingUnit?: boolean;
    error?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    testId?: string;
} & GenericValidationProps;

/**
 * Input component that handles MonetaryAmount
 */
export const MonetaryAmountInput = (props: MonetaryAmountInputProps) => {
    const {
        name,
        label,
        initialMonetaryAmount,
        updateMonetaryAmount,
        currencyCode,
        required,
        forceValidation,
        pricingUnit = PricingUnit.TON,
        validators,
        showPricingUnit = true,
        error,
        onChange,
        onBlur,
        testId,
    } = props;

    const { t } = useTranslation('domainModel');
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();

    const [currentValue, setCurrentValue] = useState(
        initialMonetaryAmount ? calculateMajorUnitFromMinorUnit(currencyCode, initialMonetaryAmount?.value) : ''
    );

    const currencySymbol = findCurrencySymbolByCode(currencyCode);

    const handleOnChange = (value: string) => {
        onChange?.(value);
        setCurrentValue(value);

        const minorValue = calculateMinorUnitFromMajorUnit(currencyCode, Number.parseFloat(value));

        if (Number.isNaN(minorValue)) {
            updateMonetaryAmount?.(undefined);
        } else {
            updateMonetaryAmount?.({
                discriminator: 'MonetaryAmount',
                value: minorValue,
            });
        }
    };

    const localValidators = useMemo(() => {
        const tempValidators: Validator[] = [];
        if (required) {
            tempValidators.push(requiredFieldValidator);
        }
        tempValidators.push(numberValidator);
        if (validators?.length) {
            tempValidators.push(...validators);
        }
        return tempValidators;
    }, [required, validators]);

    return (
        <InputTextfieldUnit
            unit={
                showPricingUnit
                    ? t('material.perPricingUnit', {
                          pricingUnit: t(`material.pricingUnit.value.${pricingUnit}.label`),
                      })
                    : ''
            }
            preInput={currencySymbol}
            pattern="[0-9]*\.?[0-9]*"
            label={label}
            name={name}
            value={currentValue}
            onChange={handleOnChange}
            validators={localValidators}
            forceValidation={forceValidation}
            error={error}
            onBlur={onBlur}
            testId={testId}
        />
    );
};

import { useTranslation } from 'react-i18next';
import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';

import {
    ButtonDanger,
    ButtonSecondary,
    LoadingStatus,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
} from '@steelbuy/ui-primitive';

interface MyAlertsDeletionModalProps {
    modalAccessor: ModalAccessor;
    handleDelete: () => void;
}

export const MyAlertsDeletionModal = ({ handleDelete, modalAccessor }: MyAlertsDeletionModalProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <Modal modalAccessor={modalAccessor}>
            <ModalContent onCloseModal={modalAccessor.hide} headline={t('myAlertsDeletionModal.textContent')}>
                <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/DeleteItem.svg" />
                <ModalContentActionBar>
                    <ButtonSecondary
                        disabled={modalAccessor.actionPending}
                        label={t('common.cancel')}
                        onClick={() => modalAccessor.hide()}
                    />
                    <ButtonDanger
                        label={t('myAlertsDeletionModal.confirmButtonLabel')}
                        loadingStatus={modalAccessor.actionPending ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        onClick={() => {
                            handleDelete();
                        }}
                    />
                </ModalContentActionBar>
            </ModalContent>
        </Modal>
    );
};

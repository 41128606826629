import classnames from 'classnames';
import { AnchorHTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import { BadgeSecondary, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { useNavigationContext } from '../NavigationContext';

import './NavigationItem.scss';

export type NavigationItemProps = {
    icon: IconIdentifier;
    label: string;
    badgeLabel?: string;
    to: string;
    disabled?: boolean;
    state?: unknown;
    flipIcon?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const NavigationItem = (props: NavigationItemProps) => {
    const { icon, label, badgeLabel, to, disabled, tabIndex, flipIcon, ...rest } = props;
    const navigationContext = useNavigationContext();

    const navigationItemActiveClassMap = {
        'navigation-item': true,
        'navigation-item--is-collapsed': navigationContext.isCollapsed,
        'navigation-item--is-disabled': disabled === true,
        'navigation-item--is-active': true,
    };

    const navigationItemInactiveClassMap = {
        'navigation-item': true,
        'navigation-item--is-collapsed': navigationContext.isCollapsed,
        'navigation-item--is-disabled': disabled === true,
    };

    const navigationItemIcon = {
        'navigation-item__icon': true,
        'navigation-item__icon--flipped': flipIcon,
    };

    const renderBadge = () => {
        if (badgeLabel && !navigationContext.isCollapsed) {
            return <BadgeSecondary label={badgeLabel} />;
        }
        if (badgeLabel && navigationContext.isCollapsed) {
            return <BadgeSecondary />;
        }
        return null;
    };

    const renderContent = () => {
        if (navigationContext.isCollapsed) {
            return (
                <>
                    <span className="navigation-item__container">
                        <span className={classnames(navigationItemIcon)}>
                            <Icon name={icon} />
                        </span>
                        {renderBadge()}
                    </span>
                    <span className="navigation-item__active-marker" />
                </>
            );
        }
        return (
            <>
                <span className="navigation-item__container" tabIndex={tabIndex}>
                    <span className={classnames(navigationItemIcon)}>
                        <Icon name={icon} />
                    </span>
                    <span className="navigation-item__label">{label}</span>
                </span>
                <span className="navigation-item__container">
                    {renderBadge()}
                    <span className="navigation-item__active-marker" />
                </span>
            </>
        );
    };

    return (
        <li>
            <NavLink
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? buildClassStringFromClassMap(navigationItemActiveClassMap)
                        : buildClassStringFromClassMap(navigationItemInactiveClassMap)
                }
                {...rest}
            >
                {renderContent()}
            </NavLink>
        </li>
    );
};

import { CurrencyCode } from '@steelbuy/currency';
import { ApiModel } from '@steelbuy/domain-model-types';

import { OrganisationModel } from './OrganisationModel';
import { OrganisationMarketSector, PaymentTerms } from './OrganisationModel.enums';

export const organisationModelMock: ApiModel<OrganisationModel> = {
    id: 'd697026a-8779-4f39-8ed0-f05c51da851f',
    name: 'Steelbuy',
    email: 'hello@steel-buy.com',
    purchaseContactName: 'Steelbuy accounting',
    purchaseContactEmail: 'accounting@steel-buy.com',
    marketSector: OrganisationMarketSector.HVAC,
    financialDetails: {
        paymentTerms: PaymentTerms.NET_30_DAYS,
        currencyCode: CurrencyCode.GBP,
        erpBalance: 9500000,
        erpCreditLimit: 10000000,
    },
};

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { RFQDraftModel, RFQDraftModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';

interface RFQViewsOptions<Model> {
    rfqId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

const modelConverter = new RFQDraftModelConverter();

const getRFQDraftDetails = async (rfqId: string) => {
    const { data } = await axios.get<RFQDraftModel>(`/api/buyer/quotes/draft/${rfqId}`);
    const rfqModel = modelConverter.toViewModel(data as unknown as ApiModel<RFQDraftModel>);
    return rfqModel;
};

export const useRFQDraftDetails = ({ rfqId, queryOptions }: RFQViewsOptions<RFQDraftModel>) =>
    useQuery({
        queryKey: ['rfq-draft-details', rfqId],
        queryFn: () => getRFQDraftDetails(rfqId),
        ...queryOptions,
    });

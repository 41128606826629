import { aluminiumShape } from './AluminiumShape';
import { aluminiumTolerances } from './AluminiumTolerance';
import { aluminiumGrades, aluminiumTempers, aluminiumCoilAndSheetGrades } from './flat/AluminiumFlatMaterialModel';
import { aluminiumFlatProduct } from './flat/AluminiumFlatProduct';
import {
    aluminiumFlatSheetCoatings,
    aluminiumFlatSheetProtectiveColor,
    aluminiumFlatSheetProtectiveCoverage,
    aluminiumFlatSheetProtectiveThickness,
    aluminiumFlatSheetProtectiveType,
} from './flat/AluminiumFlatSheet';
import { aluminiumFlatTreadPlateGrades, aluminiumFlatTreadPlateTempers } from './flat/AluminiumFlatTreadPlate';
import {
    Coating,
    CoatingThicknessUnit,
    Grade,
    PricingUnit,
    Product,
    Shape,
    Temper,
    TradeUnit,
    TradeUnitCalculation,
} from '../../MaterialModel.enums';
import { DimensionsWithRanges, SearchFields, SearchFormData } from '../../SearchFormData';
import { AbstractMaterialTaxonomy, otherFilter } from '../AbstractMaterialTaxonomy';
import { getMinimumTargetPriceForAluminiumAndStainless } from '../common/MaterialUtil';
import { Dimensions, MaterialProperties } from '../MaterialProperties';

const hasCoatingProperties = (material: MaterialProperties) =>
    material.product === Product.SHEET && material.coating === Coating.PROTECTIVE_COATING;

const getTempersPerGrade = (grade: Grade, grades: Array<Readonly<Grade[]>>, tempers: Array<Readonly<Temper[]>>) => {
    const gradesIndex = grades.findIndex((g) => g.includes(grade));
    if (gradesIndex === -1 || !tempers[gradesIndex]) {
        return [];
    }
    return tempers[gradesIndex];
};

const DENSITY = 2.71 / 1000000;

export class AluminiumTaxonomy extends AbstractMaterialTaxonomy {
    override getShapes() {
        return aluminiumShape;
    }

    override getProducts(material: MaterialProperties, isPackagesSupported = false) {
        if (material.shape === Shape.FLAT) {
            if (!isPackagesSupported) {
                return super.getFilteredProducts(aluminiumFlatProduct, [Product.PACKAGE]);
            }
            return aluminiumFlatProduct;
        }
        return super.getProducts(material, isPackagesSupported);
    }

    override getGrades(material: MaterialProperties) {
        if (material.product === Product.PACKAGE) {
            return [];
        }
        if (material.product === Product.TREAD) {
            return aluminiumFlatTreadPlateGrades.flat();
        }
        if (material.product === Product.SHEET || material.product === Product.COIL) {
            return aluminiumCoilAndSheetGrades;
        }
        return aluminiumGrades;
    }

    override getTempers(material: MaterialProperties) {
        if (material.product === Product.PACKAGE) {
            return [];
        }
        if (material.product === Product.TREAD) {
            if (material.grade === undefined) {
                return super.getTempers(material);
            }
            return getTempersPerGrade(material.grade, aluminiumFlatTreadPlateGrades, aluminiumFlatTreadPlateTempers);
        }
        return aluminiumTempers;
    }

    override getTolerances() {
        return aluminiumTolerances;
    }

    override getCoatings(material: MaterialProperties) {
        if (material.product !== Product.SHEET) {
            return super.getCoatings(material);
        }
        return aluminiumFlatSheetCoatings;
    }

    override getCoatingThickness(material: MaterialProperties, excludeOther?: boolean) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingThickness(material);
        }
        if (!excludeOther) {
            return aluminiumFlatSheetProtectiveThickness;
        }
        return aluminiumFlatSheetProtectiveThickness.filter(otherFilter);
    }

    override getCoatingColours(material: MaterialProperties, excludeOther?: boolean) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingColours(material);
        }
        if (!excludeOther) {
            return aluminiumFlatSheetProtectiveColor;
        }
        return aluminiumFlatSheetProtectiveColor.filter(otherFilter);
    }

    override getCoatingTypes(material: MaterialProperties) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingTypes(material);
        }
        return aluminiumFlatSheetProtectiveType;
    }

    override getCoatingCoverages(material: MaterialProperties) {
        if (!hasCoatingProperties(material)) {
            return super.getCoatingCoverages(material);
        }
        return aluminiumFlatSheetProtectiveCoverage;
    }

    override getTradeUnit() {
        return TradeUnit.KILOGRAM;
    }

    override getTradeUnitCalculation(material: MaterialProperties) {
        return !material.product || material.product === Product.COIL || material.product === Product.PACKAGE
            ? TradeUnitCalculation.BY_WEIGHT
            : TradeUnitCalculation.BY_ITEM;
    }

    override getPricingUnit(material: MaterialProperties) {
        switch (material.product) {
            case Product.SHEET:
                return PricingUnit.SHEET;
            case Product.PLATE:
                return PricingUnit.PLATE;
            case Product.TREAD:
                return PricingUnit.TREAD;
            case Product.COIL:
            case Product.PACKAGE:
                return PricingUnit.KILOGRAM;
            default:
                return PricingUnit.KILOGRAM;
        }
    }

    override getCoatingThicknessUnit() {
        return CoatingThicknessUnit.MICRON;
    }

    override getUrgentRequestMandatoryFields(searchFormData: SearchFormData) {
        const fields: SearchFields = ['materialType', 'shape', 'product', 'thickness', 'width', 'weight', 'grade'];
        if (
            searchFormData.product === Product.SHEET ||
            searchFormData.product === Product.PLATE ||
            searchFormData.product === Product.TREAD
        ) {
            fields.push('length');
        }
        return fields;
    }

    override getTheoreticalWeight(material: MaterialProperties, dimensions: Dimensions, numberOfItems?: number) {
        if (this.getTradeUnitCalculation(material) === TradeUnitCalculation.BY_WEIGHT) {
            return undefined;
        }
        return super.getTheorericalWeightForDensity(dimensions, numberOfItems, DENSITY);
    }

    override getMaxTheoreticalWeight(
        material: MaterialProperties,
        dimensions: DimensionsWithRanges,
        numberOfItems?: number
    ): number | undefined {
        return this.getTheoreticalWeight(material, super.getMaxDimensions(dimensions), numberOfItems);
    }

    override getMinimumTargetPrice(material: MaterialProperties, weight: number, numberOfItems: number | undefined) {
        const minimumOrderValue = 41;
        const tradeUnitCalculation = this.getTradeUnitCalculation(material);
        return getMinimumTargetPriceForAluminiumAndStainless(
            tradeUnitCalculation,
            minimumOrderValue,
            weight,
            numberOfItems
        );
    }

    override showNumberOfItemsForIMR(material: MaterialProperties): boolean {
        return super.showNumberOfItemsForIMR(material);
    }
}

export interface PathVariables {
    [x: string]: string;
}

export const comparePathVariables = (left?: PathVariables, right?: PathVariables) => {
    if (left === right) {
        return true;
    }

    if (left === undefined || right === undefined) {
        return false;
    }

    if (Object.keys(left).length !== Object.keys(right).length) {
        return false;
    }

    return !Object.keys(left).some((key) => left[key] !== right[key]);
};

import { Country } from '@steelbuy/domain-model';
import country from '../../assets/lib-ui-primitive/icons/country-icon.svg';
import './CountryIcon.scss';

export type FlagSvgProps = {
    code: Country;
    onClick?: () => void;
};

export const CountryIcon = ({ code, onClick }: FlagSvgProps) => (
    <div className={`country-icon icon__${code}`} onClick={onClick} aria-hidden="true">
        <svg>
            <use href={`${country}#${code}`} />
        </svg>
    </div>
);

export default CountryIcon;

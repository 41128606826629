import { Outlet } from 'react-router-dom';
import { CheckoutContextProvider } from '../../components/checkout/CheckoutContext';
import { SearchResultsContextProvider } from '../../components/listing-search/SearchResultsContext';

export const MyAlertsView = () => (
    <CheckoutContextProvider>
        <SearchResultsContextProvider>
            <Outlet />
        </SearchResultsContextProvider>
    </CheckoutContextProvider>
);

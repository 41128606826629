import { Grade, Surface, Coating } from '../../../MaterialModel.enums';

export const mildSteelElectroZincGrade = [
    Grade.DC01,
    Grade.DC02,
    Grade.DC03,
    Grade.DC04,
    Grade.DC05,
    Grade.DC06,
    Grade.DC07,
    Grade.HX260LAD,
    Grade.HX300LAD,
    Grade.HX340LAD,
    Grade.HX380LAD,
    Grade.HX420LAD,
    Grade.HX180YD,
    Grade.HX220YD,
    Grade.HX260YD,
    Grade.HX180BD,
    Grade.HX220BD,
    Grade.HX260BD,
    Grade.HX300BD,
    Grade.HDT580X,
    Grade.HCT490X,
    Grade.HCT590X,
    Grade.HCT980XG,
] as const;

export type MildSteelElectroZincGrade = typeof mildSteelElectroZincGrade[number];

export const mildSteelElectroZincSurface = [Surface.A_C, Surface.A_O, Surface.A_P, Surface.A_PC, Surface.B_O] as const;

export type MildSteelElectroZincSurface = typeof mildSteelElectroZincSurface[number];

export const mildSteelElectroZincCoating = [Coating.ONE_SIDED, Coating.TWO_SIDED] as const;

export type MildSteelElectroZincCoating = typeof mildSteelElectroZincCoating[number];

export const mildSteelElectroZincCoatingThicknessOneSided = ['25', '50', '75'] as const;

export const mildSteelElectroZincCoatingThicknessTwoSided = ['25', '50', '75'] as const;

type MildSteelElectroZincCoatingAndThickness =
    | {
          coating: Coating.ONE_SIDED;
          coatingThicknessValue: typeof mildSteelElectroZincCoatingThicknessOneSided[number];
      }
    | {
          coating: Coating.TWO_SIDED;
          coatingThicknessValue: typeof mildSteelElectroZincCoatingThicknessTwoSided[number];
      };

export type MildSteelElectroZinc = {
    grade: MildSteelElectroZincGrade;
    surface: MildSteelElectroZincSurface;
} & MildSteelElectroZincCoatingAndThickness;

export enum ListingStatus {
    DRAFT = 'DRAFT',
    REVIEW_REQUIRED = 'REVIEW_REQUIRED',
    REVIEW_REJECTED = 'REVIEW_REJECTED',
    PUBLISHED = 'PUBLISHED',
    PURCHASED = 'PURCHASED',
    WITHDRAWN = 'WITHDRAWN',
    EXPIRED = 'EXPIRED',
}

export enum OfferListingStatus {
    AWAITING_BUYER = 'AWAITING_BUYER',
    AWAITING_SELLER = 'AWAITING_SELLER',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    WITHDRAWN = 'WITHDRAWN',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    PURCHASED = 'PURCHASED',
}

export enum OfferView {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
}

export enum CustomOfferListingStatus {
    NO_SUCCESSFUL_OFFERS = 'NO_SUCCESSFUL_OFFERS',
}

export type SellerListingOfferStatus = OfferListingStatus | CustomOfferListingStatus;

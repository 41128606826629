import { useParams } from 'react-router-dom';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { SellerListingDetailsNotFound } from '../../components/seller-listing-details/SellerListingDetailsNotFound';
import { SellerPackageDetails } from '../../components/seller-package-details/SellerPackageDetails';
import { RoutePath } from '../../router/Routes';

type MyPackagesDetailsViewProps = {
    goBackPath: RoutePath;
};

export const MyPackagesDetailsView = ({ goBackPath }: MyPackagesDetailsViewProps) => {
    const { packageId = null } = useParams();

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <SellerListingDetailsNotFound />;
        }

        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <SuccessErrorNotificationContextProvider stayOpen>
                <SellerPackageDetails packageId={packageId ?? ''} goBackPath={goBackPath} />
            </SuccessErrorNotificationContextProvider>
        </ErrorHandler>
    );
};

import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
    ListingSellerDraftCollectionDataProvider,
    ListingSellerDraftEntityDataProvider,
} from '@steelbuy/data-provider';
import { useCreateListingContext } from '../../components/create-listing/CreateListingContext';

export const CreateListingView = () => {
    const location = useLocation();
    const { createListingData } = location.state || {};
    const createListingContext = useCreateListingContext();
    const [isInitialDataSet, setIsInitialDataSet] = useState(false);

    useEffect(() => {
        if (createListingData) {
            createListingContext.copyFromListing(createListingData);
            setIsInitialDataSet(true);
        }
    }, []);

    if (createListingData && !isInitialDataSet) {
        return null;
    }

    return (
        <ListingSellerDraftCollectionDataProvider lazyLoad>
            <ListingSellerDraftEntityDataProvider entityId="" lazyLoad>
                <Outlet />
            </ListingSellerDraftEntityDataProvider>
        </ListingSellerDraftCollectionDataProvider>
    );
};

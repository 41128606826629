import { Navigate, useLocation } from 'react-router-dom';
import { AddressCollectionDataProvider } from '@steelbuy/data-provider';
import { useCheckoutContext } from './CheckoutContext';
import CheckoutPackageForm from './CheckoutPackageForm';
import { createRouteUrl, RoutePath } from '../../router/Routes';

type CheckoutPackageFormViewProps = {
    missingListingPath?: RoutePath;
    goBackPath?: RoutePath;
};

export const CheckoutPackageFormView = ({
    goBackPath = RoutePath.PACKAGE_SEARCH_DETAILS,
    missingListingPath = RoutePath.SEARCH_LISTINGS,
}: CheckoutPackageFormViewProps) => {
    const checkoutContext = useCheckoutContext();
    const location = useLocation();

    if (!checkoutContext.listingId) {
        return <Navigate to={missingListingPath} />;
    }
    const packageDetailPath = createRouteUrl(location.state ?? goBackPath, ['packageId', checkoutContext.listingId]);

    return (
        <AddressCollectionDataProvider>
            <CheckoutPackageForm packageDetailPath={packageDetailPath} packageListingId={checkoutContext.listingId} />
        </AddressCollectionDataProvider>
    );
};

import { useMutation } from '@tanstack/react-query';
import { OrderBuyerModel } from '@steelbuy/domain-model';
import { ModelPrimaryKey, Mutation } from '@steelbuy/domain-model-types';
import axios from '../../axios';

type PackageOrderModel = Omit<OrderBuyerModel, 'listingId' | 'listing'> & { packagingId: ModelPrimaryKey };

const createPackageOrder = async (createPackageOrderRequest: Mutation<PackageOrderModel>) => {
    const { data } = await axios.post<OrderBuyerModel>('/api/buyer/purchase-orders', createPackageOrderRequest);
    return data;
};

export const useCreatePackageOrder = () =>
    useMutation({
        mutationFn: (createPackageListingRequest: Mutation<PackageOrderModel>) =>
            createPackageOrder(createPackageListingRequest),
    });

import { useTranslation } from 'react-i18next';
import { Country, DeliveryPromise } from '@steelbuy/domain-model';
import { Card } from '../card/Card';
import { CardLayout } from '../card/card-layout/CardLayout';
import { CardContentAttributesItem } from '../card-content-attributes/card-content-attributes-item/CardContentAttributesItem';
import { CardContentAttributes } from '../card-content-attributes/CardContentAttributes';
import { CardContentMaterialLocation } from '../card-content-material-location/CardContentMaterialLocation';
import { InputRadioButton } from '../input-radio-button/InputRadioButton';
import { InputRadioButtonGroup, InputRadioButtonGroupAlign } from '../input-radio-button-group/InputRadioButtonGroup';

type CardContentCheckoutDeliveryProps = {
    deliveryPromise: DeliveryPromise;
    onHaulierRequiredChanged: (val: boolean) => void;
    haulierRequired: boolean;
    countryCode?: Country;
    name?: string;
};

export const CardContentCheckoutDelivery = ({
    deliveryPromise,
    onHaulierRequiredChanged,
    haulierRequired,
    countryCode,
    name = 'radio-button-group-delivery',
}: CardContentCheckoutDeliveryProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={t('translation:application.checkout.delivery.title')}>
                    <CardContentAttributesItem
                        label={t('translation:application.checkout.deliveryText')}
                        value={t(`domainModel:deliveryPromise.${deliveryPromise}`)}
                    />
                    {countryCode && <CardContentMaterialLocation countryCode={countryCode} />}
                </CardContentAttributes>
                <section className="card-content-checkout-delivery">
                    <InputRadioButtonGroup
                        align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}
                        label={t('translation:application.checkout.delivery.haulierRequiredMessage')}
                    >
                        <InputRadioButton
                            value={t('translation:application.checkout.delivery.checkboxNo')}
                            id="radio-button-delivery"
                            name={name}
                            checked={!haulierRequired}
                            onChange={() => onHaulierRequiredChanged(false)}
                        />
                        <InputRadioButton
                            value={t('translation:application.checkout.delivery.checkboxYes')}
                            id="radio-button-delivery"
                            name={name}
                            checked={haulierRequired}
                            onChange={() => onHaulierRequiredChanged(true)}
                        />
                    </InputRadioButtonGroup>
                </section>
            </CardLayout>
        </Card>
    );
};

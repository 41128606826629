import { useEffect } from 'react';
import { ListingSellerCollectionDataProvider, ListingSellerDraftCollectionDataProvider } from '@steelbuy/data-provider';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { useCreateListingContext } from '../../components/create-listing/CreateListingContext';
import { MyListings } from '../../components/my-listings/MyListings';

export const MyListingsView = () => {
    const { reset } = useCreateListingContext();

    useEffect(() => {
        reset();
    }, []);

    return (
        <ListingSellerDraftCollectionDataProvider lazyLoad>
            <ListingSellerCollectionDataProvider lazyLoad>
                <SuccessErrorNotificationContextProvider>
                    <MyListings />
                </SuccessErrorNotificationContextProvider>
            </ListingSellerCollectionDataProvider>
        </ListingSellerDraftCollectionDataProvider>
    );
};

import {
    ListingStatus,
    OrderStatus,
    PricingUnit,
    Product,
    OfferListingStatus,
    CustomOfferListingStatus,
    RFQStatus,
} from '@steelbuy/domain-model';
import { FileUploadStatus } from '@steelbuy/ui-primitive';
import { SortCriteriaOption } from '../lib/search-results/SearchResults.enums';

export default {
    addressForm: {
        primaryTagLabel: 'Primary',
        companyName: 'Company name',
        addressLine1: 'Address line 1',
        addressLine2: 'Address line 2 (optional)',
        town: 'Town / City',
        postcode: 'Postcode',
        primaryAddressNotDeletable:
            'It is not possible to delete your primary address. Set another address as the primary address first.',
        country: 'Country',
        phoneNumber: 'Telephone number (optional)',
        saveAddressButtonLabel: 'Save address',
        setAsPrimary: 'Set as primary address',
        updateAddressButtonLabel: 'Save changes',
    },
    autoRenew: {
        autoRenewHeader: 'Auto Renewal of Listing',
        autoRenewHelper:
            'The listing will be automatically renewed if unsold after expiry date, and notifications will be sent in advance for change if needed. It enhances the visibility of the listing and broadens its reach to potential buyers.',
        autoRenewLabel: 'Auto renewal of listing',
    },
    cardContentCheckoutAddresses: {
        listHeading: 'Collection Address',
        notificationMessage: 'Please select the collection address for us to pick up the material.',
    },
    common: {
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        confirm: 'Confirm',
        back: 'Back',
        saveChanges: 'Save changes',
        remove: 'Remove',
        retry: 'Retry',
    },
    commonList: {
        loadMore: 'Load more',
        noListCaption: 'No results found',
        buyNow: 'Buy now',
        addToCart: 'Add to cart',
        addedToCart: 'Added to cart',
    },
    confirmSelectionModal: {
        confirmLabel: 'Continue',
        header: 'Changes will affect your selections on the next screen. Continue?',
    },
    createListingTeaser: {
        attributeAge: 'Age',
        attributeSku: 'Material ID',
        expirationTime: 'Expiry: ',
    },
    customPrompt: {
        discardButtonLabel: 'Discard changes',
    },
    deleteDraftModal: {
        confirmButtonLabel: 'Yes, delete it!',
        textContent: 'Are you sure you want to delete this draft?',
    },
    emailAddressForm: {
        emailLabel: 'Email address',
        submitLabel: 'Update email address',
        enterValidEmail: 'Enter a valid email address',
        emailNotUpdated: 'Email address has not been updated',
    },
    fileInput: {
        showMoreLabel: 'Show More',
        showLessLabel: 'Show Less',
        viewButton: 'View',
        fileInputLabel: 'Please select files',
        filesUploaded: '{{uploadedCount}} of {{totalFiles}} uploaded',
        filesizeKB: '{{size}}KB',
        filesizeMB: '{{size}}MB',
        sizeAndStatus: '{{size}} - {{status}}',
        fileUploadStatus: {
            [FileUploadStatus.Uploading]: 'Uploading',
            [FileUploadStatus.Error]: 'Error',
            [FileUploadStatus.Success]: 'Uploaded',
        },
    },
    login: {
        headerTitle: 'Login',
        errors: {
            usernameRequired: 'Please enter your email',
            passwordRequired: 'Password is required',
            passwordFormatError: 'Password may only contain letters number and special characters not including spaces',
            passwordIncorrect: 'Your email or password is incorrect.',
            serverError: 'Internal server error',
            temporaryPasswordExpired:
                'Temporary password expired. Temporary password expires after 24 hours and can only be used once.',
            timeoutError: 'Your session has timed out. Please re-enter your username and temporary password.',
            unauthorized: 'Your are not authorized to access this page',
            rateLimit:
                "You have reached the limit for unsuccessful login attempts. As a security measure, please retry in one hour. If you've forgotten your password, you can use the 'Forgot Password' option",
            validationError: 'Error: something went wrong.',
        },
        forgotPassword: 'Forgot your password?',
        usernameLabel: 'Email',
        passwordLabel: 'Password',
        usernamePlaceholder: 'Enter email',
        passwordPlaceholder: 'Enter password',
        submitLabel: 'Login',
        challengeRespond: {
            forcePasswordChange: {
                header: 'Create new password',
                subheading: {
                    welcome: 'Welcome {{username}}, please create a new password for your account',
                },
                successNotification: 'Your password has been changed. Login to continue.',
            },
            softwareTokenMfa: {
                header: 'Two Factor Authentication',
                subheading: 'For extra security, two-factor authentication(2FA) has been enabled on your account.',
                info: 'Enter the authentication code generated by the app.',
                sixDigitCodeLabel: '6 digit code',
                submitLabel: 'Submit',
            },
            mfaSetup: {
                header: 'Two Factor Authentication',
                subheading: 'For extra security, two-factor authentication(2FA) has been enabled on your account.',
                instructions: {
                    downloadAndInstall:
                        'Download and install an app (such as Google Authenticator) on your mobile device.',
                    scanQRCode: 'Scan the QR code.',
                    verifyAuthCode: 'Enter and verify the authentication code generated by the app.',
                },
            },
        },
        tempPasswordEmailSent: 'The welcome email has been resent to {{username}}',
        newAccount: {
            label: 'Need an account?',
            link: 'Request an account',
        },
        resetPassword: {
            header: 'Reset password',
            tokenExpired:
                'The link has expired. Email Verification link expires after 24 hours and can only be used once.',
            resendEmail: 'Resend email',
            successNotification: 'Your password has been changed. Sign in again to continue.',
            expiredPassword: {
                expiredTokenError: {
                    header: 'Reset password link expired',
                    body: 'Email verification link expires after 24 hours and can only be used once',
                },
                unknownTokenError: {
                    header: 'Invalid password link',
                    body: 'The password link is invalid, please resend link and try again',
                },
                submitLabel: 'Resend reset password link',
            },
        },
        backToLogin: 'Back to Login',
        passwordChangedNotification: 'Your password has been changed.\nLogin and continue.',
    },
    listingBuyerDetailsPrice: {
        cardTitle: 'Weight & Price',
        pricePerUnit: 'Price per {{pricingUnit}} (incl. delivery)',
        totalExclVat: 'Total (excl. VAT)',
    },
    cart: {
        subtotal: 'Subtotal value',
        exclVat: '(excl. VAT)',
        item_one: '({{count}} item)',
        item_other: '({{count}} items)',
        subtotalWeight: 'Subtotal weight',
    },
    checkoutCart: {
        item_one: '{{count}} Item',
        item_other: '{{count}} Items',
    },
    cartModal: {
        pricePerUnit: 'per {{pricingUnit}}, incl. delivery',
    },
    listingDraftTeaser: {
        lastEdited: 'Last edited',
    },
    listingSellerStatus: {
        [ListingStatus.DRAFT]: 'Draft',
        [ListingStatus.REVIEW_REQUIRED]: 'Under review',
        [ListingStatus.REVIEW_REJECTED]: 'Rejected',
        [ListingStatus.PUBLISHED]: 'Live',
        [ListingStatus.PURCHASED]: 'Sold',
        [ListingStatus.WITHDRAWN]: 'Withdrawn',
        [ListingStatus.EXPIRED]: 'Expired',
    },
    listingTeaser: {
        expiryDate: 'Exp: {{expiryDate}}',
    },
    listingSellerTeaser: {
        today: 'Today',
    },
    listingDetailsStatus: {
        header: {
            [ListingStatus.DRAFT]: 'Last saved at:',
            [ListingStatus.REVIEW_REQUIRED]: 'Submitted for review at:',
            [ListingStatus.REVIEW_REJECTED]: 'Rejected at:',
            [ListingStatus.PUBLISHED]: 'Published at:',
            [ListingStatus.PURCHASED]: 'Requested to buy at:',
            [ListingStatus.WITHDRAWN]: 'Withdrawn at',
            [ListingStatus.EXPIRED]: 'Expired at:',
        },
        expiredInfo:
            'You can renew this listing if you set a new expiry date, and you can also adjust your price if required. You will then be able to publish the renewed listing directly to the marketplace.',
    },
    listingDetailsExpiration: {
        title: 'Expiry Date',
        text: 'Listing expires on',
        autoRenewLabel: 'Auto renewal of listing',
        autoRenewEnabled: 'Enabled',
        autoRenewDisabled: 'Disabled',
        autoRenewToggleHelper: 'It enhances the visibility of the listing and broadens its reach to potential buyers.',
        autoRewewExpiryLabel: 'New expiry date after auto renewal',
    },
    expiryTimeSelection: {
        formItemHeader: 'Expiry time of the listing',
        label: 'Expiry time starts when you submit the listing for review. For this period, you agree to sell your listing exclusively on SteelBuy.',
        selectDatePlaceholder: 'Choose expiry date',
        selectTimePlaceholder: 'Time',
    },
    forgotPassword: {
        title: 'Forgot your password?',
        subheading:
            'Enter your username below and we will send you an email with instructions on how to reset your password',
        submitLabel: 'Send password reset email',
        enterValidEmail: 'Please enter a valid email address',
    },
    passwordLinkSent: {
        title: 'Reset password link sent',
        subheading: 'If you encounter any problem, please contact customer support.',
        notification: "If an account exists for {{username}} we'll send instructions for resetting your password.",
        backToSignIn: 'Back to sign in',
    },
    listingDetailsViews: {
        header: 'Performance',
        numberOfViews: 'Number of Views',
    },
    listingDetailsMaterial: {
        detailsTitle: 'Details',
        skuLabel: 'Your Material ID',
    },
    listingTeaserContainer: {
        buyerPurchaseOrderNumber: 'Your PO No:',
        steelBuyPurchaseOrderNumber: 'SteelBuy PO No:',
        sellerMaterialId: 'Your Material ID:',
        negotiationReference: 'Negotiation Reference No: ',
        materialLocation: 'Material Location:',
        weight: 'Weight:',
        origin: 'Origin:',
        age: 'Age:',
    },
    listingTeaserOfferBadge: {
        status: {
            [OfferListingStatus.ACCEPTED]: 'Accepted',
            [OfferListingStatus.AWAITING_SELLER]: "Awaiting seller's response",
            [OfferListingStatus.AWAITING_BUYER]: "Awaiting buyer's response",
            [OfferListingStatus.REJECTED]: 'Rejected',
            [OfferListingStatus.EXPIRED]: 'Expired',
            [OfferListingStatus.WITHDRAWN]: 'Withdrawn',
            [OfferListingStatus.CANCELLED]: 'Cancelled',
            [CustomOfferListingStatus.NO_SUCCESSFUL_OFFERS]: 'No Successful Offers',
            [OfferListingStatus.PURCHASED]: 'Sold',
        },
        awaitingYourResponse: 'Awaiting your response',
        outbid: 'Outbid',
        numberOffersHasLabel: 'This listing has {{numberOffers}} offers',
        numberOffersHadLabel: 'This listing had {{numberOffers}} offers',
        numberOffersHasOneLabel: 'This listing has {{numberOffers}} offer',
    },
    rfqTeaserStatusBadge: {
        status: {
            [RFQStatus.REJECTED]: 'Rejected',
            [RFQStatus.LIVE]: 'Live',
            [RFQStatus.WITHDRAWN]: 'Withdrawn',
            [RFQStatus.EXPIRED]: 'Expired',
            [RFQStatus.UNDER_REVIEW]: 'Under review',
        },
    },
    rfqBuyerTeaser: {
        expiryDate: 'Exp: {{-expiryDate}}',
    },
    rfqForm: {
        definition: {
            description: 'You can select one or multiple definitions at the same time.',
        },
        plateType: {
            description: 'You can select one or multiple types at the same time.',
        },
    },
    rfqDraftTeaser: {
        lastEdited: 'Last edited',
        infoFieldsMissing_one: 'Information in {{ count }} field missing',
        infoFieldsMissing_other: 'Information in {{ count }} fields missing',
        draft: 'Draft',
    },
    listingTeaserPrice: {
        detailsButton: 'Details',
        offerRange: 'Offer Range:',
    },
    marketingBannerSteelbuyPromise: {
        header: 'The SteelBuy Promise',
        promiseSeller: 'All customers are vetted by us',
        promiseProcess: 'Fully managed order process',
        promiseCertificate: 'All materials vetted by mill certs',
    },
    inputCharacterCount: {
        inputCharacterRemaining: 'You have {{remainingCharactersCount}} characters remaining',
    },
    materialAdditionalComment: {
        description:
            'Please provide additional comments regarding the desired quality of materials for the immediate material request.',
        placeholder: 'Please enter any additional comments here e.g. the desired quality of materials',
        label: 'Comments (optional)',
        header: 'Additional Comments',
        characterCount: 'You have {{remainingCharactersCount}} characters remaining',
        maxCharacterError: 'Maximum characters limit exceeded.',
    },
    materialDimensionSelection: {
        toleranceThicknessLabel: 'Tolerance thickness',
    },
    materialQualitySelection: {
        descriptionLabel: 'Detailed description',
        descriptionPrimeLabel: 'Detailed description (optional)',
        descriptionPlaceholder: 'Please describe the exact material condition so that buyers can evaluate the quality.',
        descriptionPrimePlaceholder:
            'Please enter any additional information here e.g. mill or origin of material if a letter of conformity is being uploaded.',

        pictureUploadLabel: 'Upload picture (optional)',
        testCertificateLabel: 'Upload mill certification',
        testCertificateLabelOptional: 'Upload mill certification (optional)',
    },
    materialSelection: {
        placeholder: 'Choose an option',
        otherLabel: 'Other',
    },
    materialInput: {
        placeholder: 'Select option',
    },

    materialOriginSelection: {
        materialOriginSelection: 'Western European: EU, UK, Switzerland, Norway',
    },

    materialWeightInput: {
        formItemHeader: 'Weight',
        formItemHeaderTheoretical: 'Theoretical Weight',
        weightInputLabel: 'Total weight',
        weightInputLabelTheoretical: 'Note you will be selling this item based on theoretical weight only.',
        theoreticalWeightNotification:
            'Please enter all dimensions in the previous step and number of items to calculate total theoretical weight.',
    },
    myAlertsDeletionModal: {
        confirmButtonLabel: 'Yes, delete it!',
        textContent: 'Are you sure you want to delete this alert?',
    },
    makeAnOfferModal: {
        makeAnOffer: 'Make an offer',
        reviewOffer: 'Review offer',
        submitOffer: 'Submit offer',
        review: 'Review',
        pricelabel: 'Original price per {{pricingUnit}}',
        proposedPrice: 'Your proposed price per {{pricingUnit}}',
        creditLimitExceed: 'The amount you entered exceeds your credit limit.',
        minimumPriceError: 'Please enter a higher amount to match the market value.',
        priceExceed: 'The amount you entered is higher than original price.',
        infoMsg: 'We will send you an update from the seller within 24 hours.',
        reviewPricingUnit: {
            [PricingUnit.TON]: {
                label: 'Weight',
            },
            [PricingUnit.KILOGRAM]: {
                label: 'Weight',
            },
            [PricingUnit.SHEET]: {
                label: 'Items',
            },
            [PricingUnit.PLATE]: {
                label: 'Items',
            },
            [PricingUnit.TREAD]: {
                label: 'Items',
            },
        },
        total: 'Total',
        failedToSubmitOffer: 'Failed to submit offer. Please try again.',
        existingOffer: 'Unable to submit offer as you already have an active offer on this listing.',
    },
    myAlertsEmptyState: {
        description:
            'Create alerts with your search criteria by searching for your needed material. We will then notify you regularly about matching results.',
        searchButtonLabel: 'Start new search',
        caption: 'No active alerts',
    },
    myAlertsList: {
        alertSettingsButtonLabel: 'Settings',
        newResultsSeparatorHeader_zero: 'No New Results (Last 24 Hours)',
        newResultsSeparatorHeader_one: '1 New Result (Last 24 Hours)',
        newResultsSeparatorHeader_other: '{{count}} New Results (Last 24 Hours)',
        searchSummaryNewResults: '{{value}} new',
        urgent: 'Urgent',
    },
    myAlertsNeedMore: {
        description:
            'Need more? Create a new alert by searching for products that interest you. You can create as many alerts as you like.',
        buttonLabel: 'Start new search',
    },
    myAlertsQna: {
        header: 'Questions & Answers',
        items: {
            howDoesAnAlertWork: {
                label: 'How do alerts work?',
                content:
                    'Search for material and turn on alerts, so we can notify you via email as soon as a matching material appears on SteelBuy. In "My Alerts" you can manage your notified searches.',
            },
            howDoUrgentRequestsWork: {
                label: 'How do immediate material requests work?',
                content:
                    'Create an immediate material request to seller, and we will notify you about new matching results via email. You can find your requests under My alerts.',
            },
            howDoNotificationsWork: {
                label: 'How often will I be notified?',
                content:
                    "If a material you searched for shows up on SteelBuy, we'll send a notification email you to your account's email address.",
            },
            howToBestUseAlerts: {
                label: 'How to best use alerts?',
                content:
                    "Turn on alerts for materials you need often, or when no search results are present. That way you don't have to configure this search again and again, and we can notify you when new offers appear.",
            },
            howToMuteOrDeleteAlerts: {
                label: 'How can I mute or delete my alerts?',
                content:
                    'In "My Alerts", click the wheel on the right of a saved alert to go to the alerts settings. Here you can delete the alert or mute the email notification. This is useful if you want to keep a search for later, but don’t need the material currently. Even without notifications, the search will still work when you click it. You can always reactivate the email notifications whenever you want.',
            },
        },
    },
    numberOfItemsInput: {
        itemLabel: {
            [Product.SHEET]: 'The total number of sheets.',
            [Product.PLATE]: 'The total number of plates.',
            [Product.TREAD]: 'The total number of tread plates.',
        },
        itemLabelInPackage: {
            [Product.SHEET]: 'The total number of sheets in the package.',
            [Product.PLATE]: 'The total number of plates in the package.',
            [Product.TREAD]: 'The total number of tread plates in the package.',
        },
    },
    listingDraft: {
        attributeSku: 'Your Material ID',
        attributeWeight: 'Weight',
        attributePrice: 'Price',
        attributeExpiry: 'Expiry',
        attributeCertificate: 'Mill Certification',
    },
    numberOfItemsLabel: {
        label: {
            [Product.SHEET]: 'Number of sheets',
            [Product.PLATE]: 'Number of plates',
            [Product.TREAD]: 'Number of tread plates',
        },
        valueWithLabel: {
            SHEET_zero: '{{count}} sheets',
            SHEET_one: '1 sheet',
            SHEET_other: '{{count}} sheets',
            PLATE_zero: '{{count}} plates',
            PLATE_one: '1 plate',
            PLATE_other: '{{count}} plates',
            TREAD_zero: '{{count}} plates',
            TREAD_one: '1 plate',
            TREAD_other: '{{count}} plates',
        },
        labelShort: {
            [Product.SHEET]: 'No. of sheets:',
            [Product.PLATE]: 'No. of plates:',
            [Product.TREAD]: 'No. of tread plates:',
        },
    },
    listingSkuInput: {
        inputHeader: 'Your Material ID',
    },
    orderStatus: {
        [OrderStatus.PROCESSING]: 'Order in progress',
        [OrderStatus.FREIGHT_BOOKED]: 'Freight booked',
        [OrderStatus.SHIPPING]: 'Shipping',
        [OrderStatus.DELIVERED]: 'Delivered',
    },
    permissionInformation: {
        basic: {
            searchListing: 'Search for products',
            alerts: 'Create alerts',
            drafts: 'Create listing drafts',
        },
        seller: {
            submitListing: 'Submit a listing',
            editRejectedListing: 'Edit rejected listings',
            renewListing: 'Publish a renewed listing',
        },
        buyer: {
            purchaseProduct: 'Purchase products',
        },
        manager: {
            editOrganisation: 'Edit the organisation information',
            user: 'Add users and edit user permissions',
            creditLineInformation: 'See the organisations credit line information',
        },
    },
    searchResults: {
        dataHeader: {
            alternativeResultsLabel: 'Alternative Results',
            totalResultsLabel_zero: 'No Results',
            totalResultsLabel_one: '1 Result',
            totalResultsLabel_other: '{{count}} Results',
        },
    },
    searchResultsSorting: {
        inputTitle: 'Sort by',
        options: {
            [SortCriteriaOption.RELEVANCE]: 'Relevance',
            [SortCriteriaOption.EXPIRES_AT_DESCENDING]: 'Expiry date',
            [SortCriteriaOption.EXPIRES_AT_ASCENDING]: 'Expiry date',
            [SortCriteriaOption.WEIGHT_ASCENDING]: 'Weight',
            [SortCriteriaOption.WEIGHT_DESCENDING]: 'Weight',
            [SortCriteriaOption.PRICE_ASCENDING]: 'Price',
            [SortCriteriaOption.PRICE_DESCENDING]: 'Price',
        },
        placeholderText: 'Choose an option',
    },
    searchResultsTable: {
        newResultsSeparator_zero: 'No New Results (Last 24 Hours)',
        newResultsSeparator_one: '1 New Result (Last 24 Hours)',
        newResultsSeparator_other: '{{count}} New Results (Last 24 Hours)',
        alternativeResultsSeparator: 'Alternative Results',
    },
    sellingPriceInput: {
        formItemHeader: 'Your selling price per {{pricingUnit}}',
        inputLabel: 'The amount you will receive when your material is sold on SteelBuy. (exl. VAT)',
    },
    setPassword: {
        passwordCriteria: {
            minChars: '16 characters minimum',
            specialChars: 'One special character',
            number: 'One number',
            lowerCase: 'One lowercase character',
            upperCase: 'One uppercase character',
            matching: 'Passwords must match',
            fieldRequired: 'This field is required',
        },
        incorrectPassword: 'Incorrect password',
        passwordsMatch: 'Passwords match',
        password: 'Password',
        newPassword: 'New Password',
        currentPassword: 'Current Password',
        retypePassword: 'Retype New Password',
        submit: 'Reset Password',
        enterPassword: 'Enter password',
        enterNewPassword: 'Enter new password',
        enterRetypePassword: 'Retype new password',
        updatePassword: 'Update password',
    },
    validation: {
        requiredField: 'This field is required.',
        numberValue: 'Value is not a number.',
        numberMinValue: 'Please enter a higher number.',
        numberMaxValue: 'Please enter a lower number',
        letterValue: 'Only letters are allowed.',
        fileItemSizeError: 'File must be less than {{sizeLimitInMegabytes}}MB',
        unsupporttedFileType: 'Unsupported file type',
        numberLargerThan: 'Must be larger than {{min}}.',
    },
};

import { FooCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { FooModel } from '@steelbuy/domain-model';
import { Nullable } from '@steelbuy/types';

import { createCrudCreateEntitySlice, createSelectEntity } from '../../util/store/crud/CreateEntitySlice';
import { CreateEntityStore } from '../../util/store/crud/CreateEntityStore';
import { CreateEntityStoreAccessor } from '../../util/store/crud/CreateEntityStoreAccessor';
import { createCreateThunk, createDeleteCreatedThunk, createMutateCreatedThunk } from '../../util/store/crud/Thunks';

// Basic definition
export const fooCreateEntityStoreName = 'foo-create-entity';
export type FooCreateEntityStore = {
    [fooCreateEntityStoreName]: CreateEntityStore<FooModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new FooCrudApiClient().init(apiBaseUrl, jsonWebToken);
const mutateCreatedEntity = createMutateCreatedThunk<FooModel, FooCreateEntityStore, FooCrudApiClient>(
    fooCreateEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<FooModel, FooCrudApiClient>(fooCreateEntityStoreName, createApiClient);
const deleteCreatedEntity = createDeleteCreatedThunk<FooModel, FooCreateEntityStore, FooCrudApiClient>(
    fooCreateEntityStoreName,
    createApiClient
);

// Slice definition
export const fooCreateEntitySlice = createCrudCreateEntitySlice<FooModel>(
    fooCreateEntityStoreName,
    mutateCreatedEntity,
    createEntity,
    deleteCreatedEntity
);
const { resetActionStatus } = fooCreateEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<FooModel, FooCreateEntityStore>(fooCreateEntityStoreName);

// Export the store accessors to be used by the data provider value
export const fooCreateEntityStoreAccessors = {
    mutateCreatedEntity,
    createEntity,
    deleteCreatedEntity,
    resetActionStatus,
    select,
} as CreateEntityStoreAccessor<FooModel, FooCreateEntityStore>;

import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter } from '@steelbuy/domain-model-converter';
import {
    ArrayOfMonetaryAmountFieldNames,
    MonetaryAmount,
    MonetaryAmountFieldNames,
} from '@steelbuy/domain-model-types';

export type ListingSellerPricingModel = {
    netPricePerUnit: MonetaryAmount;
    currencyCode: CurrencyCode;
};

export class ListingSellerPricingModelConverter extends ModelConverter<ListingSellerPricingModel> {
    override getMonetaryAmountFields(): Array<
        MonetaryAmountFieldNames<ListingSellerPricingModel> | ArrayOfMonetaryAmountFieldNames<ListingSellerPricingModel>
    > {
        return ['netPricePerUnit'];
    }
}

import './ViewHeading.scss';

export type ViewHeadingProps = {
    value: string;
};

export const ViewHeading = (props: ViewHeadingProps) => {
    const { value } = props;
    return <h2 className="view-heading">{value}</h2>;
};

import { Ref } from 'react';
import './DropdownMenu.scss';

const DROPDOWN_CLASS = 'dropdown-menu';

export type DropdownMenuProp<T> = {
    title: string;
    options: { label: string; value: T }[];
    onSelection: (value: T) => void;
    dropdownRef?: Ref<HTMLDivElement>;
    show?: boolean;
};

export const DropdownMenu = <T,>({ title, options, onSelection, dropdownRef, show = true }: DropdownMenuProp<T>) => {
    if (!show) {
        return null;
    }
    return (
        <div className={DROPDOWN_CLASS}>
            <div className={`${DROPDOWN_CLASS}__inner`} ref={dropdownRef}>
                <p className={`${DROPDOWN_CLASS}__title`}>{title}</p>
                {options.map(({ label, value }) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                    <ul key={`${value}`} onClick={() => onSelection(value)} aria-hidden="true">
                        {label}
                    </ul>
                ))}
            </div>
        </div>
    );
};

import jwtDecode, { JwtHeader, JwtPayload } from 'jwt-decode';

import { AuthenticationFailedError } from '@steelbuy/error';
import { readLocalStorage, removeLocalStorage, writeLocalStorage } from '@steelbuy/local-storage';
import { Nullable, Optional } from '@steelbuy/types';

import { AccessToken, JsonWebToken, RefreshToken } from '../jwt/JsonWebToken';

type StoredJsonWebToken = Omit<JsonWebToken, 'accessTokenValidTo' | 'refreshTokenValidTo'> & {
    accessTokenValidTo?: number;
    refreshTokenValidTo: number;
};

type JwtDecoded = JwtHeader & JwtPayload & { username: string; 'cognito:groups': string[] };

const JSON_WEB_TOKEN_STORAGE_KEY = 'lib_auth_jwt';
const REFRESH_TOKEN_TTL_MS = 24 * 60 * 60 * 1000; // 24 hours
let tokenExpiryTimer: ReturnType<typeof setTimeout> | null = null;

export class AuthHandler {
    private static instance: Nullable<AuthHandler> = null;

    static get(): AuthHandler {
        if (this.instance === null) {
            this.instance = new this();
        }
        return this.instance;
    }

    authenticate(accessToken: AccessToken, refreshToken: RefreshToken): void {
        const accessTokenInfo = jwtDecode<JwtDecoded>(accessToken);
        if (accessTokenInfo?.exp === undefined) {
            throw new AuthenticationFailedError('Access token invalid');
        }
        const token = {
            accessToken,
            refreshToken,
            accessTokenValidTo: new Date(accessTokenInfo.exp * 1000),
            refreshTokenValidTo: new Date(Date.now() + REFRESH_TOKEN_TTL_MS),
            permissions: accessTokenInfo?.['cognito:groups'] || [],
            userId: accessTokenInfo?.username ?? '',
        } as JsonWebToken;

        this.writeToken(token);
    }

    update(accessToken: AccessToken): void {
        const accessTokenInfo = jwtDecode<JwtDecoded>(accessToken);
        if (accessTokenInfo?.exp === undefined) {
            throw new AuthenticationFailedError('Access token invalid');
        }

        const existingToken = this.getToken().get();
        if (existingToken === null) {
            return;
        }

        const token = {
            ...existingToken,
            accessToken,
            accessTokenValidTo: new Date(accessTokenInfo.exp * 1000),
        } as JsonWebToken;

        this.writeToken(token);
    }

    unauthenticate(): void {
        removeLocalStorage(JSON_WEB_TOKEN_STORAGE_KEY);
    }

    getToken(): Optional<JsonWebToken> {
        const token = AuthHandler.get().readTokenFromLocalStorage();
        if (!tokenExpiryTimer) {
            const expiresAt = token.get()?.accessTokenValidTo?.getTime();
            if (expiresAt) {
                AuthHandler.get().startTokenExpiryTimer(expiresAt);
            }
        }
        return token;
    }

    isAuthenticated(): boolean {
        const jsonWebToken = this.getToken().get();
        if (jsonWebToken === null) {
            return false;
        }
        const accessTokenValidToTimestamp = jsonWebToken.accessTokenValidTo?.getTime() ?? 0;
        const refreshTokenValidToTimestamp = jsonWebToken.refreshTokenValidTo?.getTime() ?? 0;
        return (
            (jsonWebToken?.accessToken !== undefined && accessTokenValidToTimestamp > Date.now()) ||
            (jsonWebToken?.refreshToken !== undefined && refreshTokenValidToTimestamp > Date.now())
        );
    }

    needsUpdate(): boolean {
        const jsonWebToken = this.getToken().get();
        if (jsonWebToken === null) {
            return false;
        }
        if (!jsonWebToken.accessTokenValidTo) {
            return true;
        }
        const validToTimestamp = jsonWebToken.accessTokenValidTo.getTime() ?? 0;
        return validToTimestamp < Date.now() + 3000;
    }

    hasPermission(permission: string): boolean {
        return this.getToken().get()?.permissions?.includes(permission) ?? false;
    }

    clearTokenExpiryTimer() {
        if (tokenExpiryTimer) {
            clearTimeout(tokenExpiryTimer);
            tokenExpiryTimer = null;
        }
    }

    private readTokenFromLocalStorage(): Optional<JsonWebToken> {
        return readLocalStorage<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, {
            conversion: (value: StoredJsonWebToken) => ({
                ...value,
                accessTokenValidTo: value.accessTokenValidTo ? new Date(value.accessTokenValidTo) : undefined,
                refreshTokenValidTo: new Date(value.refreshTokenValidTo),
            }),
        });
    }

    private writeToken(token: JsonWebToken): void {
        if (token.accessTokenValidTo) {
            this.startTokenExpiryTimer(token.accessTokenValidTo?.getTime());
        }
        writeLocalStorage<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, token, {
            conversion: (value) => ({
                ...value,
                accessTokenValidTo: value.accessTokenValidTo?.getTime(),
                refreshTokenValidTo: value.refreshTokenValidTo.getTime(),
            }),
        });
    }

    private removeTokenFromLocalStorage(): void {
        const token = this.readTokenFromLocalStorage().get();
        if (!token) {
            return;
        }
        delete token.accessToken;
        delete token.accessTokenValidTo;
        writeLocalStorage<Omit<JsonWebToken, 'accessTokenValidTo' | 'accessToken'>, StoredJsonWebToken>(
            JSON_WEB_TOKEN_STORAGE_KEY,
            token,
            {
                conversion: (value) => ({
                    ...value,
                    refreshTokenValidTo: value.refreshTokenValidTo.getTime(),
                }),
            }
        );
    }

    private startTokenExpiryTimer(expiresAt: number): void {
        this.clearTokenExpiryTimer();
        const millsecondsUntilExpires = expiresAt - Date.now();
        if (millsecondsUntilExpires > 0) {
            tokenExpiryTimer = setTimeout(() => {
                this.removeTokenFromLocalStorage();
            }, millsecondsUntilExpires);
        }
    }
}

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { AnyMaterialDimensionModel } from './MaterialDimensionModel';
import { AnyMaterialModel } from './MaterialModel';
import { StatusModel, StatusModelConverter } from './StatusModel';

export enum RFQStatus {
    UNDER_REVIEW = 'UNDER_REVIEW',
    REJECTED = 'REJECTED',
    LIVE = 'LIVE',
    WITHDRAWN = 'WITHDRAWN',
    EXPIRED = 'EXPIRED',
}

export type QuoteEntry = {
    id?: string;
    material: AnyMaterialModel;
    materialDimensions: AnyMaterialDimensionModel;
    weight: number;
};

export type RFQBuyerModel = {
    id: string;
    delivery: {
        month: number;
        year: number;
    };
    origin: ListingMaterialOrigin[];
    additionalInformation?: string;
    status: StatusModel<RFQStatus>;
    expiresAt: Timestamp;
    quoteReferenceNumber: string;
    quoteEntry: QuoteEntry[];
    approved?: Timestamp;
};

export class RFQBuyerModelConverter extends ModelConverter<RFQBuyerModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<RFQBuyerModel> | ArrayOfTimestampFieldNames<RFQBuyerModel>
    > {
        return ['expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<RFQBuyerModel> {
        return {
            status: new StatusModelConverter(),
        };
    }
}

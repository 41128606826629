import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter } from '@steelbuy/domain-model-converter';
import { MonetaryAmount, MonetaryAmountFieldNames } from '@steelbuy/domain-model-types';

export type OfferPriceModel = {
    priceIncludingDelivery: MonetaryAmount;
    currencyCode: CurrencyCode;
};

export class OfferPriceModelModelConverter extends ModelConverter<OfferPriceModel> {
    override getMonetaryAmountFields(): Array<MonetaryAmountFieldNames<OfferPriceModel>> {
        return ['priceIncludingDelivery'];
    }
}

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOrderBuyerEntityData } from '@steelbuy/data-provider';
import { isListingOrderModel, isPackageOrderModel } from '@steelbuy/domain-model';
import { NotFoundError } from '@steelbuy/error';
import { ListingBuyerOrderDetailsPrice } from '@steelbuy/ui-domain';
import {
    AddressIdentifier,
    Card,
    CardContentAttributes,
    CardContentAttributesItem,
    CardLayout,
    MarketingBannerContact,
} from '@steelbuy/ui-primitive';
import { MyOrderDetailsStatus } from './MyOrderDetailsStatus';
import { RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { CommonListingDetails } from '../common-listing-details/CommonListingDetails';
import { CommonPackageDetails } from '../common-listing-details/CommonPackageDetails';
import { HistoricalAddressDetails } from '../historical-address-details/HistoricalAddressDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';

export const MyOrderDetails = () => {
    const { t } = useTranslation('translation');
    const orderBuyerEntityData = useOrderBuyerEntityData();
    const navigate = useNavigate();

    const orderBuyer = orderBuyerEntityData.query().getOrThrow(new NotFoundError());

    const packageModel = isPackageOrderModel(orderBuyer) ? orderBuyer.packaging : undefined;
    const listingModel = isListingOrderModel(orderBuyer) ? orderBuyer.listing : undefined;

    return (
        <>
            <MaterialPageHeader
                material={listingModel?.material}
                packageModel={packageModel}
                previousPageTitle={t('application.myOrderDetails.backLinkText')}
                onBackClick={() => navigate(RoutePath.MY_ORDERS)}
            />
            <DetailsLayout>
                <MyOrderDetailsStatus status={orderBuyer.status} description={orderBuyer.statusDescription} />

                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAttributes header={t('application.myOrderDetails.purchaseOrderNumbersHeader')}>
                            <CardContentAttributesItem
                                label={t('application.myOrderDetails.steelbuyPurchaseOrderNumberLabel')}
                                value={orderBuyer.orderNumber}
                            />
                            {orderBuyer.buyerOrderNumber && (
                                <CardContentAttributesItem
                                    label={t('application.myOrderDetails.buyerPurchaseOrderNumberLabel')}
                                    value={orderBuyer.buyerOrderNumber}
                                />
                            )}
                        </CardContentAttributes>
                    </CardLayout>
                </Card>

                {listingModel && <CommonListingDetails listingModel={listingModel} />}

                {packageModel && <CommonPackageDetails packageModel={packageModel} />}
                <ListingBuyerOrderDetailsPrice orderModel={orderBuyer} />

                <Card isClickable={false}>
                    <CardLayout>
                        <HistoricalAddressDetails
                            addressId={orderBuyer.deliveryAddressId}
                            type={AddressIdentifier.DELIVERY_ADDRESS}
                            additionalText={orderBuyer.additionalDeliveryInformation}
                            labelText={t('application.addressDetails.additionalInformationLabel')}
                        />
                    </CardLayout>
                </Card>

                <MarketingBannerContact
                    header={t('application.myOrderDetails.marketingBannerHeader')}
                    text={t('application.myOrderDetails.marketingBannerText')}
                >
                    <a href="mailto:support@steel-buy.com">support@steel-buy.com</a>
                </MarketingBannerContact>
            </DetailsLayout>
        </>
    );
};

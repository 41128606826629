import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import { useEffect, useRef } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Tenant } from '@steelbuy/api-integration';
import { CustomError } from '@steelbuy/error';
import { ForcePasswordChange } from './challenges/ForcePasswordChange';
import { MfaSetup } from './challenges/MfaSetup';
import { SoftwareTokenMFA } from './challenges/SoftwareTokenMFA/SoftwareTokenMFA';
import { LoginLayout } from './login-layout/LoginLayout';
import { LoginError } from '../login-form/LoginForm';

const cognitoSessionTimeout = 300000;

type ChallengeComponentProps = {
    apiBaseUrl: string;
    challengeType: ChallengeNameType;
    username: string;
    session: string;
    challengeTypeString: string;
} & ChallengeRespondProps;

type ChallengeRespondProps = {
    apiBaseUrl: string;
    loginUrl: string;
    challengeUrl: string;
    tenant: Tenant;
};

const ChallengeComponent = ({
    challengeType,
    username,
    session,
    apiBaseUrl,
    challengeTypeString,
    loginUrl,
    challengeUrl,
    tenant,
}: ChallengeComponentProps) => {
    if (challengeType === ChallengeNameType.NEW_PASSWORD_REQUIRED) {
        return (
            <ForcePasswordChange
                challengeUrl={challengeUrl}
                apiBaseUrl={apiBaseUrl}
                username={username}
                session={session}
                loginUrl={loginUrl}
                tenant={tenant}
            />
        );
    }
    if (challengeType === ChallengeNameType.MFA_SETUP) {
        return (
            <MfaSetup
                apiBaseUrl={apiBaseUrl}
                username={username}
                session={session}
                challengeUrl={challengeUrl}
                tenant={tenant}
            />
        );
    }
    if (challengeType === ChallengeNameType.SOFTWARE_TOKEN_MFA) {
        return (
            <SoftwareTokenMFA
                apiBaseUrl={apiBaseUrl}
                session={session}
                username={username}
                challengeUrl={challengeUrl}
                tenant={tenant}
            />
        );
    }
    throw new CustomError(`Challenge Type Not Found ${challengeTypeString}`);
};

export const ChallengeRespond = ({ apiBaseUrl, loginUrl, challengeUrl, tenant }: ChallengeRespondProps) => {
    const location = useLocation();

    const navigate = useNavigate();
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>();

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            navigate(`${loginUrl}`, {
                state: { defaultErrorNotification: LoginError.TIMEOUT },
            });
        }, cognitoSessionTimeout);
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        };
    }, []);

    const { session, username } = location.state;
    const challengeTypeString: keyof typeof ChallengeNameType = location.state.type;
    const challengeType: ChallengeNameType = ChallengeNameType[challengeTypeString];
    if (!session || !username || !challengeType) return <Navigate to={loginUrl} />;

    return (
        <LoginLayout>
            <ChallengeComponent
                apiBaseUrl={apiBaseUrl}
                challengeUrl={challengeUrl}
                challengeType={challengeType}
                username={username}
                session={session}
                challengeTypeString={challengeTypeString}
                loginUrl={loginUrl}
                tenant={tenant}
            />
        </LoginLayout>
    );
};

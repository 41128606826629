import { useTranslation } from 'react-i18next';
import { Card, CardContentAttributes, CardContentAttributesItem, CardLayout } from '@steelbuy/ui-primitive';

interface ListingDetailsViewsProps {
    views: number;
}

export const ListingDetailsViews = ({ views }: ListingDetailsViewsProps) => {
    const { t } = useTranslation('uiDomain');
    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={t('listingDetailsViews.header')}>
                    <CardContentAttributesItem label={t('listingDetailsViews.numberOfViews')} value={String(views)} />
                </CardContentAttributes>
            </CardLayout>
        </Card>
    );
};

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateEmailAddress } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';

import { EmailAddressForm } from '@steelbuy/ui-domain';
import { constants } from '../../constants';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_OVERVIEW } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountEditEmail = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const location = useLocation();
    const { email = '' } = location.state || {};
    const auth = useAuth();

    const onSubmit = (userEmail: string) => {
        updateEmailAddress(auth, constants.apiBaseUrl, userEmail);
        navigate(RoutePath.ACCOUNT, {
            state: {
                successNotification: t('application.accountEditEmail.successNotification', { userEmail }),
            },
        });
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.accountEditEmail.pageTitle')}
                previousPageTitle={t('application.accountEditEmail.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_OVERVIEW)}
            />
            <FormLayout>
                <EmailAddressForm
                    onCancel={() => {
                        navigate(RoutePath.ACCOUNT);
                    }}
                    onSubmit={onSubmit}
                    defaultEmail={email}
                />
            </FormLayout>
        </>
    );
};

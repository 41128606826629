import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ListingBuyerEntityDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';

import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { ListingDetails } from '../../components/listing-details/ListingDetails';
import { ListingNotFound } from '../../components/listing-details/ListingNotFound';
import { RoutePath } from '../../router/Routes';

type ListingDetailViewProps = {
    checkoutPath: RoutePath;
    goBackPath: RoutePath;
    offerSuccessPath: RoutePath;
};

export const ListingDetailView = ({ checkoutPath, goBackPath, offerSuccessPath }: ListingDetailViewProps) => {
    const { listingId = null } = useParams();

    const { t } = useTranslation('translation');

    if (listingId === null) {
        throw new NotFoundError(t('application.listingNotFound.notFoundMessage'));
    }

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <ListingNotFound />;
        }
        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <SuccessErrorNotificationContextProvider stayOpen>
                <ListingBuyerEntityDataProvider entityId={listingId} alwaysFetchOnMount>
                    <ListingDetails
                        checkoutPath={checkoutPath}
                        goBackPath={goBackPath}
                        offerSuccessPath={offerSuccessPath}
                    />
                </ListingBuyerEntityDataProvider>
            </SuccessErrorNotificationContextProvider>
        </ErrorHandler>
    );
};

import { ButtonHTMLAttributes, ReactNode } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';

import './ButtonIcon.scss';

export type ButtonIconProps = {
    children: ReactNode;
    disabled?: boolean;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'key' | 'disabled'>;

export const ButtonIcon = (props: ButtonIconProps) => {
    const { type = 'button', children, disabled = false, ...rest } = props;

    const classMap = {
        'button-icon': true,
        'button-icon--disabled': disabled,
    };

    return (
        <button type={type} className={buildClassStringFromClassMap(classMap)} disabled={disabled} {...rest}>
            {children}
        </button>
    );
};

import { FieldConverterMap, ModelConverter } from '@steelbuy/domain-model-converter';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@steelbuy/domain-model-types';
import { ListingBaseModelFoundation, ListingBaseSellerModel } from './ListingBaseSellerModel';
import { DeliveryPromise, ListingMaterialOrigin } from './ListingBuyerModel.enums';
import { ListingSellerAutoRenewModelConverter } from './ListingSellerAutoRenewModel';
import { ListingSellerPricingModelConverter } from './ListingSellerPricingModel';
import { ListingSellerStatusModelConverter } from './ListingSellerStatusModel';
import { MaterialType, Shape } from './MaterialModel.enums';

export type PrimePackageSellerBaseModel<F> =
    | {
          prime: true;
          description?: string;
          certificates: F[];
      }
    | {
          prime: false;
          description: string;
          certificates?: F[];
      };

export type PackageSellerBaseModel = ListingBaseSellerModel &
    ListingBaseModelFoundation & {
        origin: ListingMaterialOrigin[];
        packageTitle: string;
        packageDescription: string;
        material: MaterialType;
        shape: Shape;
        deliveryTimeframe: DeliveryPromise;
    };

export class PackageSellerBaseModelConverter<T extends PackageSellerBaseModel> extends ModelConverter<T> {
    override getTimestampFields(): Array<TimestampFieldNames<T> | ArrayOfTimestampFieldNames<T>> {
        return ['expiresAt'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<T> {
        return {
            autoRenew: new ListingSellerAutoRenewModelConverter(),
            pricing: new ListingSellerPricingModelConverter(),
            status: new ListingSellerStatusModelConverter(),
        } as FieldConverterMap<T>;
    }
}

import { Navigate, useLocation, useParams } from 'react-router-dom';
import {
    AddressCollectionDataProvider,
    ListingBuyerEntityDataProvider,
    OrderBuyerCreateEntityDataProvider,
} from '@steelbuy/data-provider';
import { CheckoutStatusGuard } from '../../../components/checkout/CheckouStatusGuard';
import { useCheckoutContext } from '../../../components/checkout/CheckoutContext';
import { createRouteUrl, RoutePath } from '../../../router/Routes';

export const MyAlertsCheckoutFormView = () => {
    const { alertId = '' } = useParams();
    const checkoutContext = useCheckoutContext();
    const location = useLocation();

    if (!checkoutContext.listingId) {
        return <Navigate to={RoutePath.MY_ALERTS} />;
    }

    const listingDetailsPath = createRouteUrl(
        location.state ?? RoutePath.MY_ALERTS_LISTING_DETAILS,
        ['alertId', alertId],
        ['listingId', checkoutContext.listingId]
    );

    return (
        <AddressCollectionDataProvider>
            <ListingBuyerEntityDataProvider entityId={checkoutContext.listingId} lazyLoad>
                <OrderBuyerCreateEntityDataProvider>
                    <CheckoutStatusGuard listingDetailPath={listingDetailsPath} />
                </OrderBuyerCreateEntityDataProvider>
            </ListingBuyerEntityDataProvider>
        </AddressCollectionDataProvider>
    );
};

import { FieldConverterMap, ModelConverter, ModelConverterInterface } from '@steelbuy/domain-model-converter';

import { ListingBuyerModel, ListingBuyerModelConverter } from './ListingBuyerModel';
import { OfferListingStatus } from './ListingSellerModel.enums';
import { OfferModel, OfferModelConverter } from './OfferModel';

export type MyOffersModel = {
    id: string;
    status: OfferListingStatus;
    referenceId: string;
    readonly listing: ListingBuyerModel;
    offer: OfferModel[];
    isRead: boolean;
    numberOfOffers: number;
};

export class MyOffersModelConverter extends ModelConverter<MyOffersModel> {
    override getFieldConverterMapByModel(): FieldConverterMap<MyOffersModel> {
        return {
            // eslint-disable-next-line
            listing: new ListingBuyerModelConverter() as ModelConverterInterface<any>,
            offer: new OfferModelConverter(),
        };
    }
}

import { Product } from '../../../MaterialModel.enums';

export const aluminiumFlatProduct = [
    Product.COIL,
    Product.SHEET,
    Product.PLATE,
    Product.TREAD,
    Product.PACKAGE,
] as const;

export type AluminiumFlatProduct = typeof aluminiumFlatProduct[number];

import { useTranslation } from 'react-i18next';
import { EmptyState } from '@steelbuy/ui-primitive';

import './MyAlertsNeedMore.scss';

interface MyAlertsNeedMoreProps {
    onButtonClick: () => void;
}

export const MyAlertsNeedMore = ({ onButtonClick }: MyAlertsNeedMoreProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="my-alerts__need-more">
            <EmptyState
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateData.svg"
                description={t('myAlertsNeedMore.description')}
                button={{
                    label: t('myAlertsNeedMore.buttonLabel'),
                    onClick: () => onButtonClick(),
                }}
            />
        </div>
    );
};

import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

import { ButtonSize, ButtonColor } from '../button/Button.enum';
import { LoadingStatus } from '../button-loading-spinner/LoadingStatus.enum';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../loading-spinner/LoadingSpinner.enums';
import './ButtonTertiary.scss';

export type ButtonTertiaryProps = {
    size?: ButtonSize;
    color?: ButtonColor;
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
    className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonTertiary = (props: ButtonTertiaryProps) => {
    const {
        label,
        icon,
        disabled = false,
        loadingStatus = LoadingStatus.IDLE,
        type = 'button',
        size = ButtonSize.M,
        color = ButtonColor.LIGHT,
        ...rest
    } = props;

    const isSmall = size === ButtonSize.S;
    const isDark = color === ButtonColor.DARK;

    const buttonTertiaryClassMap = {
        'button-tertiary': true,
        'button-tertiary--small': isSmall,
        'button-tertiary--dark': isDark,
        'button-tertiary__only-icon': !label,
        'button-tertiary__only-icon--small': !label && isSmall,
    };

    const buttonTertiaryContentClassMap = {
        'button-tertiary__content': true,
        'button-tertiary__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const buttonTertiaryContentLabelClassMap = {
        'button-tertiary__content__label--small': isSmall,
    };

    return (
        <button
            type={type}
            className={classNames(buttonTertiaryClassMap)}
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={classNames(buttonTertiaryContentClassMap)}>
                {label && (
                    <span className={classNames('button-tertiary__content__label', buttonTertiaryContentLabelClassMap)}>
                        {label}
                    </span>
                )}
                {icon && <Icon name={icon} />}
            </span>
            {loadingStatus === LoadingStatus.PENDING && (
                <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />
            )}
        </button>
    );
};

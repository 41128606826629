import { ReactNode, useState } from 'react';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { Separator } from '../separator/Separator';
import { SeparatorIdentifier } from '../separator/Separator.enums';

import './Accordion.scss';

export type AccordionProps = {
    collapsedLabel: string;
    extendedLabel?: string;
    initiallyExtended?: boolean;
    children: ReactNode;
    alwaysExpanded?: boolean;
    className?: string;
};

export const Accordion = (props: AccordionProps) => {
    const {
        initiallyExtended,
        collapsedLabel,
        extendedLabel = collapsedLabel,
        alwaysExpanded = false,
        children,
        className,
    } = props;

    const [extended, setExtended] = useState<boolean>(initiallyExtended ?? false);

    return (
        <article className="accordion">
            {!alwaysExpanded && (
                <article
                    className="accordion__separator"
                    onClick={() => !alwaysExpanded && setExtended(!extended)}
                    aria-hidden="true"
                >
                    <Icon name={extended ? IconIdentifier.CHEVRONS_UP : IconIdentifier.CHEVRONS_DOWN} />

                    <Separator
                        separatorType={SeparatorIdentifier.GROUP_ON_LIGHT}
                        header={extended ? extendedLabel : collapsedLabel}
                    />
                </article>
            )}
            {extended ? <div className={className || 'accordion__children'}>{children}</div> : null}
        </article>
    );
};

/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { useAuth } from '@steelbuy/auth';

import { ListingBuyerModel } from '@steelbuy/domain-model';
import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { ListingBuyerEntityData } from './ListingBuyerEntityData';
import {
    ListingBuyerEntityStore,
    listingBuyerEntityStoreAccessors,
    listingBuyerEntityStoreName,
} from './ListingBuyerEntitySlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { EntityDataProviderProps } from '../../util/provider/DataProviderProps';
import { createEntityDataProviderValue } from '../../util/provider/EntityDataProviderValue';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';

export const ListingBuyerEntityDataProvider = (props: EntityDataProviderProps) => {
    const {
        lazyLoad = false,
        entityId,
        apiMock = false,
        interceptRendering = !lazyLoad,
        pendingComponent = null,
        alwaysFetchOnMount = false,
    } = props;

    const authConsumer = useAuth();

    const PendingComponent = pendingComponent;

    const dataProviderValue = createEntityDataProviderValue<ListingBuyerModel, ListingBuyerEntityStore>(
        listingBuyerEntityStoreName,
        listingBuyerEntityStoreAccessors,
        entityId,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        if (dataProviderIdle && !lazyLoad) {
            dataProviderValue.fetch();
        }
    }, [dataProviderIdle, lazyLoad]);

    useEffect(() => {
        if (alwaysFetchOnMount) {
            dataProviderValue.fetch();
        }
    }, []);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent !== null) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return (
        <ListingBuyerEntityData.Provider value={dataProviderValue}>{props.children}</ListingBuyerEntityData.Provider>
    );
};

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    CartProductType,
    handleCartError,
    MY_CART_DETAILS,
    updateCartCache,
    useAddItemToCart,
    useCartDetails,
    useSearchListingsByAlert,
} from '@steelbuy/data-access';
import { ActionStatus, useFeatureFlag, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';

import { CartBuyerModel, Feature } from '@steelbuy/domain-model';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';

import { useModal } from '@steelbuy/modal-dialog';
import { MyAlertsNeedMore, SearchResults, SearchSummary, toSearchFormData } from '@steelbuy/ui-domain';
import {
    ButtonGhostSystemOnLightM,
    IconIdentifier,
    LoadingSpinner,
    Notification,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
    NotificationLevel,
    SearchSummaryActions,
    SearchSummaryResults,
    Separator,
    SeparatorIdentifier,
    Tag,
    TagVariant,
} from '@steelbuy/ui-primitive';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { AddToCartConfirmModal } from '../add-to-cart-confirm-modal/AddToCartConfirmModal';
import { getScrollTopMainLayout, scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { useSearchResultsContext } from '../listing-search/SearchResultsContext';
import { PageHeader } from '../page-header/PageHeader';

import './MyAlertsSearchResults.scss';

export const MyAlertsSearchResults = () => {
    const { alertId = '' } = useParams();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const location = useLocation();
    const listingAlertCollectionData = useListingBuyerAlertCollectionData();
    const { sortCriteria, setSortCriteria, setScrollPosition, scrollPosition } = useSearchResultsContext();
    const { isFeatureSupported } = useFeatureFlag();
    const checkoutContext = useCheckoutContext();

    const listingAlert = listingAlertCollectionData.queryOne(alertId).get();

    const {
        data: searchResults,
        hasNextPage,
        isLoading,
        fetchNextPage,
        total,
        isFetchingNextPage,
    } = useSearchListingsByAlert({
        alertId,
        sortCriteria,
    });

    const navigateToAlertSettings = (alertIdentification: ModelPrimaryKey) =>
        navigate(createRouteUrl(RoutePath.MY_ALERT_SETTINGS, ['alertId', alertIdentification]));

    useEffect(() => {
        scrollMainLayout(0, scrollPosition);
        setScrollPosition(0);
    }, []);

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.IDLE],
        () => {
            if (!listingAlert) {
                listingAlertCollectionData.fetchOne(alertId);
            }
        },
        true
    );
    const handleCheckout = (id: string): void => {
        checkoutContext.initializeWithListingId(id);
        navigate(createRouteUrl(RoutePath.MY_ALERTS_CHECKOUT, ['alertId', alertId]), {
            state: RoutePath.MY_ALERTS_SEARCH,
        });
    };

    const queryClient = useQueryClient();
    const openAddToCartModal = useModal();
    const addItemToCartMutation = useAddItemToCart();
    const [cartData, setCartData] = useState<CartBuyerModel>();
    const [listingIdAddingToCart, setListingIdAddingToCart] = useState<string>('');
    const { refetch } = useCartDetails({ queryOptions: { enabled: false } });

    const handleAddToCart = (id: string): void => {
        setListingIdAddingToCart(id);
        addItemToCartMutation.mutate(
            { productId: id, productType: CartProductType.LISTING },
            {
                onSuccess: (response) => {
                    setCartData(response);
                    openAddToCartModal.show();
                    setListingIdAddingToCart('');
                    updateCartCache(queryClient, [MY_CART_DETAILS], response);
                },
                onError: (e: unknown) => {
                    setListingIdAddingToCart('');
                    handleCartError(e, refetch, t);
                },
            }
        );
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!listingAlert) {
        return <LoadingSpinner />;
    }

    const searchFormData = toSearchFormData(listingAlert.filterQuery);
    return (
        <div className="my-alerts-search-results">
            <PageHeader
                pageTitle={t('translation:application.myAlertsSearchResults.pageHeader')}
                previousPageTitle={t('translation:application.myAlertsSearchResults.previousPageTitle')}
                onBackClick={() => navigate(RoutePath.MY_ALERTS)}
            />
            <TableLayout>
                {location.state?.successNotification && (
                    <Notification
                        className="my-alerts-search-results__success-notification"
                        level={NotificationLevel.SUCCESS}
                        message={location.state.successNotification}
                    />
                )}
                {searchResults && searchResults.length > 0 && (
                    <FormLayout>
                        <NotificationIllustrated
                            alignment={NotificationIllustratedAlignment.HORIZONTAL}
                            imgSrc="/assets/lib-ui-primitive/images/SearchedMatches.svg"
                            header={t('translation:application.myAlertsSearchResults.notificationIllustratedHeader')}
                            helperText={t(
                                'translation:application.myAlertsSearchResults.notificationIlulustratedHelperText'
                            )}
                        />
                    </FormLayout>
                )}
                <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />
                <SearchSummary
                    searchFormData={searchFormData}
                    comment={listingAlert.comment}
                    showPrice={isFeatureSupported(Feature.IMR_TARGET_PRICE)}
                >
                    <SearchSummaryActions>
                        {!listingAlert.enabled && <Tag icon={IconIdentifier.MUTE} variant={TagVariant.NEUTRAL} />}
                        {/*
                        // re-enable once we have designs
                        {isFeatureEnabled(Feature.URGENT_REQUEST) && (
                            <InputToggleButton
                                value={t('uiDomain:myAlertsList.urgent')}
                                checked={listingAlert.type === AlertType.URGENT}
                                onChange={() =>
                                    listingAlertCollectionData.mutate(listingAlert.id, {
                                        ...listingAlert,
                                        type:
                                            listingAlert.type === AlertType.URGENT
                                                ? AlertType.REGULAR
                                                : AlertType.URGENT,
                                    })
                                }
                            />
                        )} */}
                        {listingAlert.numberOfNewListings > 0 && (
                            <SearchSummaryResults
                                results={t('translation:application.myAlertsSearchResults.searchSummaryNewResults', {
                                    numListings: listingAlert.numberOfNewListings.toString(),
                                })}
                            />
                        )}
                        <ButtonGhostSystemOnLightM
                            icon={IconIdentifier.SETTINGS}
                            onClick={() => navigateToAlertSettings(listingAlert.id)}
                            title={t('uiDomain:myAlertsList.alertSettingsButtonLabel')}
                        />
                    </SearchSummaryActions>
                </SearchSummary>
                <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />

                {searchResults && (
                    <SearchResults
                        results={searchResults}
                        searchFormData={searchFormData}
                        pathToListingDetails={(listingId) =>
                            createRouteUrl(
                                RoutePath.MY_ALERTS_LISTING_DETAILS,
                                ['alertId', alertId],
                                ['listingId', listingId]
                            )
                        }
                        handleSortChange={setSortCriteria}
                        hasNext={hasNextPage}
                        totalResults={total ?? 0}
                        sortCriteria={sortCriteria}
                        handleLoadMore={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        newListings={listingAlert.numberOfNewListings}
                        onClickListing={() => {
                            setScrollPosition(getScrollTopMainLayout());
                        }}
                        handleCheckout={(id) => handleCheckout(id)}
                        onClickCartButton={handleAddToCart}
                        selectedListingId={listingIdAddingToCart}
                    />
                )}
                {cartData && <AddToCartConfirmModal modalAccessor={openAddToCartModal} cartData={cartData} />}
            </TableLayout>
            {searchResults && searchResults.length <= 0 && (
                <MyAlertsNeedMore onButtonClick={() => navigate(RoutePath.SEARCH_LISTINGS)} />
            )}
        </div>
    );
};

import { ReactElement, ReactNode, useState } from 'react';

import { AuthContext } from './AuthContext';
import { AuthHandler } from '../auth-handler/AuthHandler';
import { AccessToken, RefreshToken } from '../jwt/JsonWebToken';

export type AuthProviderProps = {
    children: ReactNode;
    onAuthenticate?: () => void;
    onUpdate?: () => void;
    onReauthenticate?: () => void;
    onUnauthenticate?: (refreshToken?: RefreshToken, queryParam?: string) => void;
};

export const AuthProvider = (props: AuthProviderProps): ReactElement => {
    const {
        children,
        onAuthenticate = null,
        onUpdate = null,
        onReauthenticate = null,
        onUnauthenticate = null,
    } = props;

    const authHandler = AuthHandler.get();

    const [, setAuthorized] = useState<boolean>(authHandler.isAuthenticated());

    const authContextValue = {
        authenticate: (accessToken: AccessToken, refreshToken: RefreshToken): void => {
            authHandler.authenticate(accessToken, refreshToken);
            setAuthorized(true);
            if (onAuthenticate !== null) {
                onAuthenticate();
            }
        },
        update: (accessToken: AccessToken): void => {
            authHandler.update(accessToken);
            if (onUpdate !== null) {
                onUpdate();
            }
        },
        reauthenticate: (): void => {
            authHandler.unauthenticate();
            setAuthorized(false);
            if (onReauthenticate !== null) {
                onReauthenticate();
            }
        },
        unauthenticate: (queryParam?: string): void => {
            // need to save the token before it's remove via authHandler.unauthenticate()
            const refreshToken = authHandler.getToken()?.getOrUndefined()?.refreshToken;

            authHandler.unauthenticate();
            setAuthorized(false);
            if (onUnauthenticate !== null) {
                onUnauthenticate(refreshToken, queryParam);
            }
        },
        getToken: authHandler.getToken,
        isAuthenticated: authHandler.isAuthenticated,
        needsUpdate: authHandler.needsUpdate,
        hasPermission: authHandler.hasPermission,
    };

    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

import { PreparedSortCriteria } from '@steelbuy/api-integration';
import {
    ListingBuyerSearchResultModel,
    ListingBuyerSearchResultModelConverter,
    ListingBuyerSearchSortCriteria,
} from '@steelbuy/domain-model';

import { useSaveListingViews } from '../../mutations/saveListingViews';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

interface SearchListingOptions {
    alertId: string;
    sortCriteria?: PreparedSortCriteria<ListingBuyerSearchSortCriteria>;
}
export const SEARCH_LISTING_ALERT_KEY = 'search-listing-alert';

const modelConverter = new ListingBuyerSearchResultModelConverter();
const searchListingMapFn = (data: ListingBuyerSearchResultModel) => modelConverter.toViewModel(data);

export const useSearchListingsByAlert = (options: SearchListingOptions) => {
    const mutation = useSaveListingViews();
    return usePaginatedQuery<ListingBuyerSearchResultModel>({
        url: `/api/universal/search-listings/by-alert/${options.alertId}`,
        requestOptions: {
            sort: options.sortCriteria,
        },
        mapFn: searchListingMapFn,
        queryKey: [SEARCH_LISTING_ALERT_KEY, JSON.stringify(options)],
        queryOptions: {
            staleTime: 1000 * 60 * 5,
            onSuccess: ({ pages }) => {
                mutation.mutate({
                    listingIds: pages[pages.length - 1].items.map(({ listing: { id } }) => id),
                });
            },
        },
    });
};

import { ReactNode } from 'react';
import { BadgeState } from '../badge-state/BadgeState';

import { BadgeVariant } from '../badge-state/BadgeVariant.enums';

import './CardContentListingViewState.scss';

export type CardContentListingViewStateProps = {
    header: string;
    date: string;
    badgeVariant: BadgeVariant;
    badgeLabel: string;
    children?: ReactNode;
};

export const CardContentListingViewState = (props: CardContentListingViewStateProps) => {
    const { header, date, badgeVariant, badgeLabel, children } = props;

    const renderChildren = () => {
        if (!children) {
            return null;
        }
        return <article className="card-content-listing-view-state__header-wrapper__children">{children}</article>;
    };

    return (
        <article className="card-content-listing-view-state">
            <article className="card-content-listing-view-state__header-wrapper">
                <span className="card-content-listing-view-state__header-wrapper__header">
                    {header}
                    <BadgeState label={badgeLabel} variant={badgeVariant} />
                </span>

                <span className="card-content-listing-view-state__header-wrapper__date">{date}</span>
            </article>
            {renderChildren()}
        </article>
    );
};

import axios from '../../axios';

type UpdateListingModel = {
    autoRenew: {
        enabled: boolean;
        expiresAt?: Date;
        price?: number;
    };
};

const updateListing = async (id: string, listing: UpdateListingModel) => {
    const { data } = await axios.put<UpdateListingModel>(`/api/seller/listings/${id}`, listing);
    return data;
};

export const useUpdateListing = () => ({ updateListing });

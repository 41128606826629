import { useTranslation } from 'react-i18next';
import { useMyRFQs } from '@steelbuy/data-access';
import { RFQBuyerTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    FillHeightContainer,
    LoadingSpinner,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useMyRFQsContext } from './MyRFQsContext';
import { MyRFQsEmptyState } from './MyRFQsEmptyState';
import { MyRFQsFilter } from './MyRFQsFilter';
import { RoutePath, createRouteUrl } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

export const MyRFQsList = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { myRFQFilter } = useMyRFQsContext();
    const statusFilter = myRFQFilter.length ? { 'status.value': myRFQFilter.join('|') } : undefined;

    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, total } = useMyRFQs({
        filterCriteria: statusFilter,
    });

    return (
        <div className="my-rfqs-list">
            <Notification level={NotificationLevel.INFO} message={t('application.myRFQ.notificationMsg')} />
            {isLoading ? (
                <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                    <LoadingSpinner fillContainerHeight />
                </FillHeightContainer>
            ) : (
                <>
                    <DataHeader
                        headingValue={t('application.myRFQ.numberOfRFQListings', {
                            count: total ?? 0,
                        })}
                    >
                        <MyRFQsFilter />
                    </DataHeader>
                    <div className="my-rfqs-list__listings">
                        {data?.map((rfqListing) => (
                            <Card
                                key={rfqListing.id}
                                isClickable
                                url={createRouteUrl(RoutePath.MY_RFQS_DETAILS, ['rfqId', rfqListing.id])}
                            >
                                <RFQBuyerTeaser rfq={rfqListing} />
                            </Card>
                        ))}
                    </div>
                    {hasNextPage && (
                        <div className="my-rfqs-list__load-more">
                            <ButtonTertiaryOnLightM
                                onClick={() => fetchNextPage()}
                                label={t('uiDomain:commonList.loadMore')}
                                loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                            />
                        </div>
                    )}
                </>
            )}
            {data?.length === 0 && <MyRFQsEmptyState />}
        </div>
    );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonSecondary, IconIdentifier } from '@steelbuy/ui-primitive';

interface CreateListingButtonsProps {
    isFirstStep: boolean;
    isLastStep: boolean;
    allStepsFinished: boolean;
    goToNextStep: () => void;
    gotToPreviousStep: () => void;
    goToSummary: () => void;
    children?: ReactNode;
}

export const CreateListingButtons = ({
    isFirstStep,
    isLastStep,
    allStepsFinished,
    goToNextStep,
    gotToPreviousStep,
    goToSummary,
    children = null,
}: CreateListingButtonsProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <>
            <div>{children}</div>
            <div>
                {!isFirstStep && <ButtonSecondary label={t('uiDomain:common.back')} onClick={gotToPreviousStep} />}
                {!isLastStep && (
                    <ButtonPrimary
                        label={t('application.createListingButtons.wizardNextButtonLabel')}
                        onClick={goToNextStep}
                        icon={IconIdentifier.CHEVRON_RIGHT}
                    />
                )}
                {isLastStep && (
                    <ButtonPrimary
                        disabled={!allStepsFinished}
                        onClick={goToSummary}
                        label={t('application.createListingButtons.viewSummaryButtonLabel')}
                        icon={IconIdentifier.CHEVRON_RIGHT}
                    />
                )}
            </div>
        </>
    );
};

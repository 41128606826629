import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { useCursorPosition } from '../../hooks/useCursorPosition';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { ValidationProps } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputTextfield.scss';

export type InputTextfieldProps = {
    label?: string;
    helperText?: string;
    error?: string;
    onChange?: (value: string) => void;
    endAdornment?: ReactNode;
} & ValidationProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputTextfield = ({
    disabled,
    endAdornment,
    error,
    forceValidation,
    helperText,
    label,
    onChange,
    readOnly,
    validators,
    value,
    ...rest
}: InputTextfieldProps) => {
    const { elementRef, validationError } = useValidation<HTMLInputElement>(value, validators, forceValidation);
    const { setCursor } = useCursorPosition(elementRef);

    const combinedError = validationError ?? error;

    const inputTextClassMap = {
        'input-text': true,
        'input-text--disabled': disabled,
    };

    const inputTextFieldClassMap = {
        'input-text__field': true,
        'input-text__field--disabled': disabled,
        'input-text__field--error': combinedError,
        'input-text__field--error--endAdornment': combinedError && !!endAdornment,
        'input-text__field--read-only': readOnly,
    };

    const inputTextFieldInputClassMap = {
        'input-text__field__input': true,
        'input-text__field__input--disabled': disabled,
        'input-text__field__input--read-only': readOnly,
    };

    const helperTextClassMap = {
        'input-text__helper-text': true,
        'input-text__helper-text--error': combinedError,
    };

    const iconsClassMap = {
        'input-text__field--icons': !!endAdornment || combinedError,
        'input-text__field--icons--error': !!endAdornment || combinedError,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="input-text__label">{label}</span>;
    };

    const renderText = () => {
        const text = combinedError ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const renderIcons = () => {
        if (combinedError === undefined && endAdornment === undefined) {
            return null;
        }
        return (
            <div className={buildClassStringFromClassMap(iconsClassMap)}>
                {!!combinedError && <Icon name={IconIdentifier.ERROR} />}
                {!!endAdornment && endAdornment}
            </div>
        );
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange !== null) {
            if (onChange) {
                setCursor(event.target.selectionStart);
                onChange(event.target.value);
            }
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputTextClassMap)}>
            {renderLabel()}
            <span className={buildClassStringFromClassMap(inputTextFieldClassMap)}>
                <input
                    ref={elementRef}
                    className={buildClassStringFromClassMap(inputTextFieldInputClassMap)}
                    type="text"
                    aria-invalid={error ? 'true' : 'false'}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={handleChange}
                    value={value}
                    {...rest}
                />
                {renderIcons()}
            </span>
            {renderText()}
        </label>
    );
};

import { Location } from 'history';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@steelbuy/modal-dialog';
import {
    ModalContent,
    ModalContentMain,
    ModalContentActionBar,
    ButtonSecondary,
    ButtonTertiaryOnLightM,
    ButtonPrimary,
    LoadingStatus,
    NotificationLevel,
    Notification,
} from '@steelbuy/ui-primitive';
import { useBlocker } from '../hooks/useBlocker';

export type CustomPromptProps = {
    message: string;
    shouldPrompt: boolean;
    allowNavigation?: (newLocation: Location) => boolean;
    onDiscard: (done: () => void) => void;
    onSave?: (done: () => void, onError: (message: string) => void) => void;
    saveLabel?: string;
};

export const CustomPrompt = ({
    message,
    shouldPrompt,
    onDiscard,
    onSave,
    saveLabel,
    allowNavigation = () => false,
}: CustomPromptProps) => {
    const { t } = useTranslation('uiDomain');
    const [modal, unblockAndRetry] = useBlocker(shouldPrompt, allowNavigation);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onError = (errorMessage: string) => {
        setError(errorMessage);
    };

    return (
        <Modal modalAccessor={modal}>
            <ModalContent onCloseModal={modal.hide} headline={message}>
                <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/ListingDraft-Neutral.svg" />
                {error && (
                    <Notification
                        level={NotificationLevel.ERROR}
                        message={error}
                        onClose={() => setError(null)}
                        closeButton
                    />
                )}
                <ModalContentActionBar>
                    <ButtonSecondary label={t('common.cancel')} onClick={modal.hide} />
                    <ButtonTertiaryOnLightM
                        label={t('customPrompt.discardButtonLabel')}
                        onClick={() => {
                            onDiscard(unblockAndRetry);
                        }}
                    />
                    {onSave && (
                        <ButtonPrimary
                            label={saveLabel}
                            onClick={() => {
                                setLoading(true);
                                onSave(unblockAndRetry, onError);
                            }}
                            loadingStatus={loading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    )}
                </ModalContentActionBar>
            </ModalContent>
        </Modal>
    );
};

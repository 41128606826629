import { Coating, CoatingCoverage, CoatingType } from '../../MaterialModel.enums';

export const commonFlatSheetCoatings = [Coating.UNCOATED, Coating.PROTECTIVE_COATING] as const;

export const commonFlatSheetProtectiveThickness = ['30', '50', '65', '70', '80', '90', '100', '110', 'OTHER'];

export const commonFlatSheetProtectiveColor = ['Clear', 'White', 'Blue', 'OTHER'];

export const commonFlatSheetProtectiveType = [
    CoatingType.FIBRE,
    CoatingType.CO2,
    CoatingType.FIBRE_AND_CO2,
    CoatingType.N_A,
] as const;

export const commonFlatSheetProtectiveCoverage = [CoatingCoverage.ONE_SIDED, CoatingCoverage.TWO_SIDED] as const;

import { Tolerance } from '../../MaterialModel.enums';

export const mildSteelTolerances = [
    Tolerance.EU_NORM,
    Tolerance.THREE_QUARTERS_NORM,
    Tolerance.HALF_NORM,
    Tolerance.NON_PRIME,
];

export type MildSteelTolerance = typeof mildSteelTolerances[number];

import { useMutation } from '@tanstack/react-query';
import axios from '../../axios';

interface PasswordChangeRequest {
    oldPassword: string;
    newPassword: string;
}

const passwordChange = async (options: PasswordChangeRequest) => {
    const { data } = await axios.patch('/api/universal/users/password-change', {
        ...options,
    });
    return data;
};

export const usePasswordChange = () =>
    useMutation({
        mutationFn: (request: PasswordChangeRequest) => passwordChange(request),
    });

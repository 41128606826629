import { ApiModel } from '@steelbuy/domain-model-types';

import { AnyMaterialDimensionModel } from './MaterialDimensionModel';
import { Product } from './MaterialModel.enums';

export const materialDimensionCoilModelMock: ApiModel<AnyMaterialDimensionModel<Product.COIL>> = {
    product: Product.COIL,
    width: 800,
    thickness: 12,
};

export const materialDimensionSheetModelMock: ApiModel<AnyMaterialDimensionModel<Product.SHEET>> = {
    product: Product.SHEET,
    width: 800,
    length: 20000,
    thickness: 12,
};

export const materialDimensionPlateModelMock: ApiModel<AnyMaterialDimensionModel<Product.PLATE>> = {
    product: Product.PLATE,
    width: 800,
    length: 20000,
    thickness: 12,
};

import { ReactNode } from 'react';

import './TrackingIndicator.scss';

export type TrackingIndicatorProps = {
    children: ReactNode;
};

export const TrackingIndicator = (props: TrackingIndicatorProps) => {
    const { children } = props;

    return <article className="tracking-indicator">{children}</article>;
};

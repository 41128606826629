import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './NotificationIllustratedItem.scss';

export type NotificationIllustratedItemProps = {
    text: string;
    isChecked?: boolean;
};

export const NotificationIllustratedItem = (props: NotificationIllustratedItemProps) => {
    const { text, isChecked } = props;

    const renderOkIcon = () => {
        if (!isChecked) {
            return null;
        }
        return <Icon name={IconIdentifier.OK} />;
    };

    return (
        <article className="notification-illustrated-item">
            {renderOkIcon()}
            <span className="notification-illustrated-item__text">{text}</span>
        </article>
    );
};

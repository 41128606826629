import { ReactNode } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';

import './CardSellerSummaryExpiryTime.scss';

export type CardSellerSummaryExpiryTimeProps = {
    isEditable?: boolean;
    children: ReactNode;
};

export const CardSellerSummaryExpiryTime = (props: CardSellerSummaryExpiryTimeProps) => {
    const { isEditable, children } = props;

    const sellerSummaryClassMap = {
        'card-seller-summary-expiry-time': true,
        'card-seller-summary-expiry-time--editable': isEditable,
    };

    return <article className={buildClassStringFromClassMap(sellerSummaryClassMap)}>{children}</article>;
};

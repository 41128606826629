import { AddressModel, AddressModelConverter } from '@steelbuy/domain-model';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class AddressCrudApiClient extends AbstractCrudApiClient<AddressModel> {
    protected collectionControllerUri;

    protected paginationControllerUri;

    protected entityCreateUri;

    protected entityFetchUri;

    protected entityMutateUri;

    protected entityDeleteUri;

    protected entityServiceCallUri;

    protected override paginationSize = 100;

    protected modelConverter = new AddressModelConverter();

    constructor(uriInfix = 'universal') {
        super();

        this.collectionControllerUri = 'api/universal/addresses/';
        this.paginationControllerUri = 'api/universal/addresses/';

        this.entityCreateUri = `api/${uriInfix}/addresses/`;
        this.entityFetchUri = `api/${uriInfix}/addresses/{id}/`;
        this.entityMutateUri = `api/${uriInfix}/addresses/{id}/`;
        this.entityDeleteUri = `api/${uriInfix}/addresses/{id}/`;

        this.entityServiceCallUri = 'not_available';
    }
}

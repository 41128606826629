import { ButtonHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { LoadingStatus } from '../button-loading-spinner/LoadingStatus.enum';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../loading-spinner/LoadingSpinner.enums';

import './ButtonSecondary.scss';

export type ButtonSecondaryProps = {
    label: string;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonSecondary = (props: ButtonSecondaryProps) => {
    const { label, loadingStatus = LoadingStatus.IDLE, disabled = false, type = 'button', ...rest } = props;

    const buttonSecondaryLabelClassMap = {
        'button-secondary__label': true,
        'button-secondary__label--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const renderLoadingSpinner = () => {
        if (loadingStatus === LoadingStatus.PENDING) {
            return <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />;
        }
        return null;
    };

    return (
        <button
            type={type}
            className="button-secondary"
            disabled={disabled || loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonSecondaryLabelClassMap)}>{label}</span>
            {renderLoadingSpinner()}
        </button>
    );
};

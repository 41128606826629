import { TFunction } from 'i18next';

const REMAINING_TIME_EXPIRED_TEXT = '-';

export const getTimeLeftText = (expiresAt: string, t: TFunction<['translation', 'domainModel']>) => {
    const createAtTime = new Date(expiresAt).getTime();
    const now = new Date().getTime();
    const time = createAtTime - now;

    if (time > 0) {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

        return `${days >= 1 ? t('translation:application.time.day', { count: days }) : ''} ${hours} ${t(
            'translation:application.offers.hours'
        )} ${minutes} ${t('translation:application.offers.minutes')}`;
    }
    return REMAINING_TIME_EXPIRED_TEXT;
};

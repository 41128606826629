import { ReactNode } from 'react';

import './CardLayout.scss';

export type CardLayoutProps = {
    children: ReactNode;
};

export const CardLayout = ({ children }: CardLayoutProps) => <div className="card-layout">{children}</div>;

export default CardLayout;

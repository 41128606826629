import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRestartRFQ, useRFQDetails, useUpdateRFQ, useWithdrawRFQ } from '@steelbuy/data-access';
import { RFQStatus } from '@steelbuy/domain-model';
import { NotFoundError } from '@steelbuy/error';
import { useModal } from '@steelbuy/modal-dialog';
import { MarketingBannerSteelbuyPromise } from '@steelbuy/ui-domain';
import {
    ButtonCallToAction,
    ButtonPrimary,
    ButtonTertiaryOnLightM,
    CardContentEditable,
    ConfirmCancelModel,
    IconIdentifier,
    LoadingSpinner,
    LoadingStatus,
    useSuccessErrorNotification,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { getSimilarRFQ } from '../create-rfq/createRFQUtil';
import { RFQDeadlineSelection } from '../create-rfq/step3/inputs/RFQDeadlineSelection';
import { RFQDeliverySelection } from '../create-rfq/step3/inputs/RFQDeliverySelection';
import { PageHeader } from '../page-header/PageHeader';
import { CardContentRFQDetails } from '../rfqs/card-content-rfq-details/CardContentRFQDetails';
import { CardContentAdditionalComments } from '../rfqs/CardContentAdditionalComments';
import { CardContentDeliveryMonth } from '../rfqs/CardContentDeliveryMonth';
import { CardContentOrigins } from '../rfqs/CardContentOrigins';
import { CardContentQuoteDeadline } from '../rfqs/CardContentQuoteDeadline';
import { CardContentStatus } from '../rfqs/CardContentStatus.tsx';

import './RFQDetails.scss';
import { ListingsRejectionCard } from '../seller-listing-details/ListingsRejectionCard';

type RFQDetailsProps = {
    rfqId: string;
    goBackPath: string;
};

type RFQRenewalForm = {
    expiresAt: string;
    delivery: string;
};

const RADIX = 10;

export const RFQDetails = ({ goBackPath, rfqId }: RFQDetailsProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const modal = useModal();
    const { mutate, isLoading: isSubmitting } = useWithdrawRFQ();

    const { data, isLoading, error } = useRFQDetails({
        rfqId,
        queryOptions: {
            enabled: true,
        },
    });

    const {
        Notification: NotificationComponent,
        showSuccessNotification,
        showErrorNotification,
    } = useSuccessErrorNotification();

    const methods = useForm<RFQRenewalForm>({
        mode: 'onChange',
    });

    const { mutate: updateRFQ, isLoading: isUpdating } = useUpdateRFQ();
    const restartRFQ = useRestartRFQ();

    if (isLoading) {
        return <LoadingSpinner />;
    }
    if (error) {
        throw new NotFoundError();
    }

    const withdrawRFQ = () => {
        mutate(rfqId, {
            onSuccess: () => {
                showSuccessNotification(t('translation:application.myRFQDetails.withdrawSuccess'));
                modal.hide();
            },
            onError: () => {
                showErrorNotification(t('translation:application.myRFQDetails.withdrawFailure'));
                modal.hide();
            },
        });
    };

    const isExpired = data?.status.value === RFQStatus.EXPIRED || false;
    const isRenewable = isExpired && !!data?.approved;
    const isWithdrawn = data?.status.value === RFQStatus.WITHDRAWN || false;
    const isRejected = data?.status.value === RFQStatus.REJECTED || false;

    const ButtonComponent = isExpired ? ButtonTertiaryOnLightM : ButtonCallToAction;

    const onSubmit = methods.handleSubmit((values) => {
        const [month, year] = values.delivery.split('_');
        const deliveryObject = {
            month: parseInt(month, RADIX),
            year: parseInt(year, RADIX),
        };
        const expiresAt = new Date(parseInt(values.expiresAt, 10)).toISOString();
        if (isExpired) {
            updateRFQ(
                {
                    rfqId,
                    payload: { expiresAt, delivery: deliveryObject },
                },
                {
                    onSuccess: ({ quoteReferenceNumber }) => {
                        navigate(RoutePath.MY_RFQS_DETAILS_SUCCESS, { state: { quoteReferenceNumber } });
                    },
                    onError: () => {
                        showErrorNotification(t('translation:application.myRFQDetails.updateFailure'));
                    },
                }
            );
        }
    });

    const editRejectedRFQ = () => {
        restartRFQ.mutate(
            { rfqId },
            {
                onSuccess: (draft) => {
                    navigate(`${RoutePath.CREATE_RFQ_WIZARD}/${draft.id}`, {
                        state: {
                            draftRFQ: draft,
                            rejectedRFQId: rfqId,
                        },
                    });
                },
                onError: () => {
                    showErrorNotification(t('translation:application.myRFQDetails.restartRFQFailure'));
                },
            }
        );
    };

    return (
        <>
            <div>
                <PageHeader
                    pageTitle={data?.quoteReferenceNumber ?? ''}
                    previousPageTitle={t('application.myRFQ.header')}
                    onBackClick={() => navigate(goBackPath)}
                />
                {data && (
                    <form onSubmit={onSubmit}>
                        <div className="rfq-details">
                            {NotificationComponent}
                            {(data.status.value === RFQStatus.UNDER_REVIEW || data.status.value === RFQStatus.LIVE) && (
                                <div className="rfq-details__buttons">
                                    <ButtonTertiaryOnLightM
                                        label={t('translation:application.myRFQDetails.withdrawRfq')}
                                        onClick={() => modal.show()}
                                    />
                                </div>
                            )}
                            <CardContentStatus
                                status={data.status.value}
                                expiresAt={isExpired ? data.expiresAt.value : data.status.modifiedAt.value}
                            />
                            {isRejected && (
                                <ListingsRejectionCard
                                    reason={data.status.reason ?? ''}
                                    internalSellerSku={data.quoteReferenceNumber}
                                    subject={t('application.myRFQ.emailSubject', {
                                        quoteRef: data.quoteReferenceNumber,
                                    })}
                                />
                            )}

                            <CardContentRFQDetails
                                quoteEntries={data.quoteEntry}
                                refNumber={data?.quoteReferenceNumber ?? ''}
                                status={data.status.value}
                                id={rfqId}
                            />
                            <CardContentOrigins origin={data.origin} />

                            {isRenewable ? (
                                <>
                                    <CardContentEditable>
                                        <RFQDeliverySelection {...methods} name="delivery" />
                                    </CardContentEditable>
                                    <CardContentEditable>
                                        <RFQDeadlineSelection {...methods} name="expiresAt" />
                                    </CardContentEditable>
                                </>
                            ) : (
                                <>
                                    <CardContentDeliveryMonth
                                        delivery={`${data.delivery.month}_${data.delivery.year}`}
                                    />
                                    <CardContentQuoteDeadline
                                        deadline={`${new Date(data.expiresAt.value).getTime()}`}
                                    />
                                </>
                            )}
                            {data.additionalInformation && (
                                <div className="rfq-details__comments">
                                    <CardContentAdditionalComments comments={data.additionalInformation} />
                                </div>
                            )}
                            <MarketingBannerSteelbuyPromise />

                            <div className="rfq-details__layout__actionbar">
                                {(isExpired || isWithdrawn) && (
                                    <ButtonComponent
                                        label={t('application.myRFQ.createSimilarRFQ')}
                                        onClick={() => {
                                            navigate(`${RoutePath.CREATE_RFQ_WIZARD}`, {
                                                state: {
                                                    createRFQData: getSimilarRFQ(data),
                                                },
                                            });
                                        }}
                                    />
                                )}
                                {isRejected && (
                                    <ButtonPrimary
                                        label={t('uiDomain:common.edit')}
                                        icon={IconIdentifier.EDIT}
                                        onClick={editRejectedRFQ}
                                        loadingStatus={
                                            restartRFQ.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE
                                        }
                                    />
                                )}
                                {isRenewable && (
                                    <ButtonCallToAction
                                        name="publish rfq button"
                                        label={t('application.myRFQ.publishRfq')}
                                        onClick={() => {}}
                                        type="submit"
                                        disabled={!methods.formState.isValid}
                                        loadingStatus={isUpdating ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                    />
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
            <ConfirmCancelModel
                modalAccessor={modal}
                header={t('translation:application.myRFQDetails.withdrawModal.header')}
                bodyImage="assets/lib-ui-primitive/images/DeleteItem.svg"
                cancelLabel={t('uiDomain:common.cancel')}
                submitLabel={t('translation:application.myRFQDetails.withdrawModal.confirmLabel')}
                onSubmit={withdrawRFQ}
                isSubmitting={isSubmitting}
                isDanger
                onClose={() => modal.hide()}
                blurOnClose
            />
        </>
    );
};

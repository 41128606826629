import {
    useAddressCollectionData,
    useListingBuyerEntityData,
    useOrderBuyerCreateEntityData,
} from '@steelbuy/data-provider';
import { ListingOrderModel } from '@steelbuy/domain-model';
import { CheckoutErrorLayout } from './CheckoutErrorLayout';
import { RoutePath } from '../../router/Routes';
import { useCheckoutContext } from '../checkout/CheckoutContext';

type CheckoutErrorProps = {
    onBack: () => void;
    onRetry: () => void;
    retryCount: number;
};

export const CheckoutError = ({ onBack, onRetry, retryCount }: CheckoutErrorProps) => {
    const orderCreateData = useOrderBuyerCreateEntityData();
    const listingEntityData = useListingBuyerEntityData();
    const { invoiceAddressCollectionData } = useAddressCollectionData();
    const checkoutContext = useCheckoutContext();
    const listingModel = listingEntityData.query().get();
    const invoiceAddresses = invoiceAddressCollectionData.query();

    const handleRetry = () => {
        if (!listingModel || !checkoutContext.deliveryAddressId) {
            return;
        }
        onRetry();
        orderCreateData.create({
            listingId: listingModel.id,
            invoiceAddressId: invoiceAddresses[0]?.id ?? '',
            deliveryAddressId: checkoutContext.deliveryAddressId,
            additionalDeliveryInformation: checkoutContext.additionalDeliveryInformation,
            buyerOrderNumber: checkoutContext.buyerOrderNumber,
            haulierRequired: checkoutContext.haulierRequired,
        } as ListingOrderModel);
    };

    return (
        <CheckoutErrorLayout
            checkoutPath={RoutePath.SEARCH_LISTINGS_CHECKOUT}
            handleRetry={handleRetry}
            onBack={onBack}
            retryCount={retryCount}
        />
    );
};

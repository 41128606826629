import { useTranslation } from 'react-i18next';
import { FormItem, InputTextarea } from '@steelbuy/ui-primitive';
import './MaterialAdditionalComment.scss';

type MaterialAdditionalCommentProps = {
    initialComment: string | undefined;
    setComment: (newComment?: string) => void;
    maxLength: number;
};

export const MaterialAdditionalComment = ({
    initialComment,
    setComment,
    maxLength,
}: MaterialAdditionalCommentProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    const remainingCharactersCount = maxLength - (initialComment?.length ?? 0);

    return (
        <div className="material-additional-comment">
            <FormItem header={t('uiDomain:materialAdditionalComment.header')}>
                <span className="custom-label additional-comment">
                    {t('uiDomain:materialAdditionalComment.description')}
                </span>
                <InputTextarea
                    name="materialAdditionalComment"
                    label={t('uiDomain:materialAdditionalComment.label')}
                    placeholder={t('uiDomain:materialAdditionalComment.placeholder')}
                    onChange={setComment}
                    maxLength={maxLength}
                    value={initialComment}
                />
                <span className="comments-label remove-spacing">
                    {t('uiDomain:materialAdditionalComment.characterCount', { remainingCharactersCount })}
                </span>
            </FormItem>
        </div>
    );
};

import { PropsWithChildren } from 'react';
import { Routes, Route } from 'react-router-dom';
import { RoutePath } from './Routes';
import { ChallengeRespondView } from '../views/ChallengeRespondView';
import { ForgotPasswordView } from '../views/ForgotPasswordView';
import { LoginView } from '../views/LoginView';
import { ResetPasswordView } from '../views/ResetPasswordView';

export const LoginRouter = ({ children }: PropsWithChildren) => (
    <Routes>
        <Route path={RoutePath.LOGIN} element={<LoginView />} />
        <Route path={RoutePath.CHALLENGE_RESPOND} element={<ChallengeRespondView />} />
        <Route path={RoutePath.FORGOT_PASSWORD} element={<ForgotPasswordView />} />
        <Route path={RoutePath.RESET_PASSWORD} element={<ResetPasswordView />} />
        <Route path="*" element={children} />
    </Routes>
);

import { useTranslation } from 'react-i18next';
import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import {
    ButtonPrimary,
    ButtonSecondary,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
} from '@steelbuy/ui-primitive';

interface CheckoutCancelModalProps {
    modalAccessor: ModalAccessor;
    action: () => void;
}

export const CheckoutCancelModal = ({ action, modalAccessor }: CheckoutCancelModalProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <Modal modalAccessor={modalAccessor}>
            <ModalContent onCloseModal={modalAccessor.hide}>
                <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/DeleteItem.svg">
                    {t('application.checkout.cancel.content')}
                </ModalContentMain>
                <ModalContentActionBar>
                    <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={modalAccessor.hide} />
                    <ButtonPrimary label={t('application.checkout.cancel.confirmButtonLabel')} onClick={action} />
                </ModalContentActionBar>
            </ModalContent>
        </Modal>
    );
};

import { ReactNode } from 'react';

import { FormActionbar } from '../../form-actionbar/FormActionbar';
import { FormActionbarWithColumns } from '../../form-actionbar-with-columns/FormActionbarWithColumns';

import './WizardNavigationButtons.scss';

export type WizardNavigationButtonsProps = {
    children: ReactNode;
    columns?: boolean;
};

export const WizardNavigationButtons = (props: WizardNavigationButtonsProps) => {
    const { children, columns = false } = props;
    const Component = columns ? FormActionbarWithColumns : FormActionbar;
    return (
        <div className="wizard__navigation-buttons">
            <Component>{children}</Component>
        </div>
    );
};

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import './CardClear.scss';

export type CardClearProps = {
    children: ReactNode;
    isClickable: boolean;
    url?: string;
};

export function CardClear(props: CardClearProps) {
    const { children, isClickable = false, url } = props;

    const renderCardClear = () => {
        if (isClickable && url) {
            return (
                <Link className="card-clear-link-wrap" to={url}>
                    <section className="card-clear card-clear--clickable">{children}</section>
                </Link>
            );
        }
        return <section className="card-clear">{children}</section>;
    };
    return renderCardClear();
}

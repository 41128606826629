import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { ListingNotFound } from '../../components/listing-details/ListingNotFound';
import { PackageDetails } from '../../components/package-details/PackageDetails';
import { RoutePath } from '../../router/Routes';

type PackageDetailViewProps = {
    checkoutPath: RoutePath;
    goBackPath: RoutePath;
};

export const PackageDetailView = ({ checkoutPath, goBackPath }: PackageDetailViewProps) => {
    const { packageId = null } = useParams();

    const { t } = useTranslation('translation');

    if (packageId === null) {
        throw new NotFoundError(t('application.listingNotFound.notFoundMessage'));
    }

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <ListingNotFound />;
        }
        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <PackageDetails checkoutPath={checkoutPath} goBackPath={goBackPath} packageListingId={packageId} />
        </ErrorHandler>
    );
};

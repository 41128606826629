import { FormEvent, useRef, useState } from 'react';
import { useFooCreateEntityData } from '@steelbuy/data-provider';

import { FooModel } from '@steelbuy/domain-model';
import { createAccessableUrlFromString, createTimestampFromMaybeDate, Mutable } from '@steelbuy/domain-model-types';
import { Nullable } from '@steelbuy/types';

export const FooEntityCreation = () => {
    const fooCreatable = useFooCreateEntityData();
    const fooMutatable = useRef<Mutable<FooModel>>({} as Mutable<FooModel>);
    const [filePicked, setFilePicked] = useState<Nullable<File>>(null);

    const handleCreate = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        fooCreatable.create(fooMutatable.current);
    };

    return (
        <div>
            <form onSubmit={handleCreate}>
                <label>
                    Name
                    <input
                        type="text"
                        name="name"
                        onChange={(event) => {
                            fooMutatable.current.name = event.target.value;
                        }}
                    />
                </label>
                <br />
                <label>
                    Date of birth
                    <input
                        type="date"
                        name="dateOfBirth"
                        onChange={(event) => {
                            const dateOfBirth = createTimestampFromMaybeDate(event.target.valueAsDate).get();
                            if (dateOfBirth !== null) {
                                fooMutatable.current.dateOfBirth = dateOfBirth;
                            }
                        }}
                    />
                </label>
                <br />
                <label>
                    Some URL
                    <input
                        type="url"
                        name="url"
                        onChange={(event) => {
                            const url = createAccessableUrlFromString(event.target.value);
                            fooMutatable.current.url = url;
                        }}
                    />
                </label>
                <br />
                <label>
                    Some file
                    <input
                        type="file"
                        name="downloadFile"
                        onChange={(event) => {
                            const selectedFiles = event.target.files;
                            if (selectedFiles === null || selectedFiles.length === 0) {
                                setFilePicked(null);
                                return;
                            }
                            const selectedFile = selectedFiles[0];
                            setFilePicked(selectedFile);
                        }}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { CardContentLabelValues } from '@steelbuy/ui-primitive';

export const CardContentAdditionalComments = ({ comments }: { comments: string }) => {
    const { t } = useTranslation('translation');
    const labelValues = [
        {
            label: t('application.createRFQ.comments'),
            value: comments,
        },
    ];

    return (
        <CardContentLabelValues
            title={t('application.createRFQ.additionalComments.header')}
            labelValues={labelValues}
        />
    );
};

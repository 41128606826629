import { ReactNode } from 'react';
import './CardContentEditable.scss';
import { Card } from '../Card';

export type CardContentEditableProps = {
    children: ReactNode;
};

export const CardContentEditable = ({ children }: CardContentEditableProps) => (
    <Card isClickable={false}>
        <div className="card-content-editable">{children}</div>
    </Card>
);

export default CardContentEditable;

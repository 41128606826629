import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    ButtonPrimary,
    FormActionbar,
    MarketingBannerContact,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';

import './RenewListingSuccess.scss';

export type RenewListingSuccessProps = {
    steelbuyListingID?: string;
};

export const RenewListingSuccess = (props: RenewListingSuccessProps) => {
    const { t } = useTranslation('translation');
    const { steelbuyListingID } = props;

    const mailAddress = t('application.renewListingSuccess.bannerEmailAddress');

    return (
        <div className="renew-listing-success">
            <DetailsLayout>
                <NotificationIllustrated
                    alignment={NotificationIllustratedAlignment.VERTICAL}
                    imgSrc="/assets/lib-ui-primitive/images/ListingLive-Success.svg"
                    header={t('application.renewListingSuccess.notificationMessage')}
                    helperText={
                        steelbuyListingID !== undefined
                            ? t('application.renewListingSuccess.notificationHelperText', {
                                  steelbuyListingID,
                              })
                            : ''
                    }
                />
                <MarketingBannerContact
                    header={t('application.renewListingSuccess.bannerHeader')}
                    text={t('application.renewListingSuccess.bannerText')}
                >
                    <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
                </MarketingBannerContact>
                <FormActionbar>
                    <Link to={RoutePath.MY_LISTINGS}>
                        <ButtonPrimary label={t('application.renewListingSuccess.myListingsButtonLabel')} />
                    </Link>
                </FormActionbar>
            </DetailsLayout>
        </div>
    );
};

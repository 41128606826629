import { useTranslation } from 'react-i18next';
import { ModalAccessor } from '@steelbuy/modal-dialog';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

type ConfirmSelectionModalProps = {
    modal: ModalAccessor;
    onConfirm: () => void;
};

export const ConfirmSelectionModal = ({ modal, onConfirm }: ConfirmSelectionModalProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <ConfirmCancelModel
            modalAccessor={modal}
            header={t('confirmSelectionModal.header')}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Neutral.svg"
            cancelLabel={t('common.cancel')}
            submitLabel={t('confirmSelectionModal.confirmLabel')}
            onSubmit={onConfirm}
            onClose={modal.hide}
        />
    );
};

import { ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@steelbuy/auth';
import { ButtonGhostSystemOnLightM, IconIdentifier, Separator, SeparatorIdentifier } from '@steelbuy/ui-primitive';

import './PageHeader.scss';
import { CartIcon } from './CartIcon';

export type PageHeaderProps = { pageTitle: string; children?: ReactNode; showCartIcon?: boolean } & (
    | { previousPageTitle?: never; onBackClick?: never }
    | { previousPageTitle: string; onBackClick: () => void }
);

export const PageHeader = (props: PageHeaderProps) => {
    const { pageTitle, previousPageTitle, children, onBackClick, showCartIcon = true } = props;
    const auth = useAuth();

    const handleBackClick = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (onBackClick) {
            onBackClick();
        }
    };

    return (
        <header className="page-header">
            <article className="page-header__wrapper">
                {previousPageTitle && (
                    <Link className="page-header__wrapper__link" to="#" onClick={(e) => handleBackClick(e)}>
                        <ButtonGhostSystemOnLightM icon={IconIdentifier.CHEVRON_LEFT} />
                        <span className="page-header__wrapper__link__title">{previousPageTitle}</span>
                    </Link>
                )}
                <h1 className="page-header__wrapper__headline">{pageTitle}</h1>
                <div className="page-header__wrapper__actions">
                    {children}
                    {auth.isAuthenticated() && showCartIcon ? <CartIcon /> : null}
                </div>
            </article>
            <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
        </header>
    );
};

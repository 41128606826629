import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useFooCollectionData } from '@steelbuy/data-provider';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';

export const FooCollection = () => {
    const fooCollection = useFooCollectionData();
    const hasNext = fooCollection.hasNextPage();

    const handleLoadMore = (): void => {
        fooCollection.fetchNext();
    };

    const handleDeleteItem = (id: ModelPrimaryKey): void => {
        fooCollection.delete(id);
    };

    const renderFooCollectionItems = (): ReactNode =>
        fooCollection.query().map(
            (foo): ReactNode => (
                <li key={foo.id}>
                    <Link to={`/foo/${foo.id}/`}>{foo.name}</Link>
                    <button onClick={() => handleDeleteItem(foo.id)}>Delete</button>
                </li>
            )
        );

    return (
        <div>
            <h2>Welcome to FooCollection!</h2>
            <hr />
            <ul>{renderFooCollectionItems()}</ul>
            <button onClick={handleLoadMore} disabled={!hasNext}>
                {' '}
                Load more
            </button>
        </div>
    );
};

import { ListingSellerModel, ListingSellerModelConverter } from '@steelbuy/domain-model';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class ListingSellerCrudApiClient extends AbstractCrudApiClient<ListingSellerModel> {
    protected collectionControllerUri = 'api/universal/listings';

    protected paginationControllerUri = 'api/universal/listings';

    protected entityCreateUri = 'api/seller/listings';

    protected entityFetchUri = 'api/universal/listings/{id}/';

    protected entityMutateUri = 'api/seller/listings/{id}/';

    protected entityDeleteUri = 'api/seller/listings/{id}/';

    protected entityServiceCallUri = 'api/seller/listings/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new ListingSellerModelConverter();
}

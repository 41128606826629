import { ReactNode } from 'react';

import './MarketingBannerDeterminePrice.scss';

export type MarketingBannerDeterminePriceProps = {
    header: string;
    children: ReactNode;
};

export const MarketingBannerDeterminePrice = (props: MarketingBannerDeterminePriceProps) => {
    const { header, children } = props;

    return (
        <article className="marketing-banner-determine-price">
            <article className="marketing-banner-determine-price__header">{header}</article>
            <article className="marketing-banner-determine-price__children">{children}</article>
        </article>
    );
};

import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { challengeRespond, isLoginSuccess, Tenant } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';
import { ButtonPrimary, InputTextfield, LoadingStatus, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import './SoftwareTokenMFA.scss';

interface SoftwareTokenMFAProps {
    apiBaseUrl: string;
    session: string;
    username: string;
    challengeUrl: string;
    tenant: Tenant;
}

interface FormData {
    secretCode: string;
}

const literal = 'login.challengeRespond.softwareTokenMfa';

export const SoftwareTokenMFA = ({ apiBaseUrl, session, username, challengeUrl, tenant }: SoftwareTokenMFAProps) => {
    const { t } = useTranslation('uiDomain');
    const auth = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(LoadingStatus.IDLE);
    const { control, handleSubmit } = useForm<FormData>();
    const [error, setError] = useState('');
    const redirectUrl = localStorage.getItem('REDIRECT_URL');
    const onSubmit = handleSubmit(async ({ secretCode }) => {
        try {
            setLoading(LoadingStatus.PENDING);
            const response = await challengeRespond(apiBaseUrl, {
                type: ChallengeNameType.SOFTWARE_TOKEN_MFA,
                session,
                options: {
                    username,
                    mfaCode: secretCode,
                },
                tenant,
            });
            if (isLoginSuccess(response)) {
                auth.authenticate(response.accessToken, response.refreshToken);
                localStorage.removeItem('REDIRECT_URL');
                navigate(redirectUrl ?? '/');
            } else {
                navigate(challengeUrl, {
                    state: {
                        session: response.session,
                        username,
                        type: response.challengeType,
                    },
                });
            }
        } catch (e: unknown) {
            setLoading(LoadingStatus.IDLE);
            if (typeof e === 'object' && e && 'responseMessage' in e && typeof e.responseMessage === 'string') {
                setError(e.responseMessage);
            }
        }
    });

    return (
        <main className="software-token-mfa">
            <header className="software-token-mfa__header">
                <div>{t(`${literal}.header`)}</div>
                <section className="software-token-mfa__header--subheading">
                    <p>{t(`${literal}.subheading`)}</p>
                    <p>
                        <strong>{t(`${literal}.info`)}</strong>
                    </p>
                </section>
            </header>

            <form onSubmit={onSubmit} className="software-token-mfa__form">
                <Controller
                    render={({ field: { onChange, onBlur } }) => (
                        <InputTextfield label={t(`${literal}.sixDigitCodeLabel`)} onChange={onChange} onBlur={onBlur} />
                    )}
                    name="secretCode"
                    control={control}
                />
                <ButtonPrimary label={t(`${literal}.submitLabel`)} loadingStatus={loading} type="submit" />
            </form>
            {error && <Notification level={NotificationLevel.ERROR} message={error} stayOpen />}
        </main>
    );
};

import { TradeUnitCalculation } from '../../MaterialModel.enums';

export const getMinimumTargetPriceForAluminiumAndStainless = (
    tradeUnitCalculation: TradeUnitCalculation,
    minimumOrderValue: number,
    weight: number,
    numberOfItems: number | undefined
) => {
    let minimumPrice = 0;
    let minimumPriceWithShipping = 0;
    const defaultMinimum = 1;
    if (!weight) {
        return defaultMinimum;
    }
    if (tradeUnitCalculation === TradeUnitCalculation.BY_ITEM && numberOfItems) {
        minimumPrice = minimumOrderValue / numberOfItems;
        minimumPriceWithShipping = (weight * 0.025) / numberOfItems;
    }
    if (tradeUnitCalculation === TradeUnitCalculation.BY_WEIGHT && weight) {
        minimumPrice = minimumOrderValue / weight;
    }
    return Math.max(minimumPriceWithShipping, minimumPrice, defaultMinimum);
};

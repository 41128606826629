/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { DownloadFile, UploadFile } from '@steelbuy/domain-model-types';
import { Nullable } from '@steelbuy/types';
import {
    Card,
    CardContentProductViewDescription,
    CardContentTestCertificate,
    CardLayout,
} from '@steelbuy/ui-primitive';
import { FileMultiUploadItemView } from '../file-multi-upload-item-view/FileMultiUploadItemView';

type ListingDetailsQualityProps =
    | {
          prime: true;
          testCertificate: Array<DownloadFile | UploadFile> | null;
          description: Nullable<string> | undefined;
          apiBaseUrl: string;
      }
    | {
          prime: false;
          description: Nullable<string>;
          picture?: Array<DownloadFile | UploadFile> | null;
          testCertificate?: Array<DownloadFile | UploadFile> | null;
          apiBaseUrl: string;
      };

export const ListingDetailsQuality = (props: ListingDetailsQualityProps) => {
    const { prime, testCertificate, apiBaseUrl } = props;
    const { t } = useTranslation('translation');

    return (
        <>
            {!!testCertificate?.length && (
                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentTestCertificate header={t('application.listingDetails.testCertificateTitle')}>
                            {testCertificate !== null ? (
                                <FileMultiUploadItemView files={testCertificate} apiBaseUrl={apiBaseUrl} />
                            ) : (
                                '-'
                            )}
                        </CardContentTestCertificate>
                    </CardLayout>
                </Card>
            )}
            {(props.description || prime === false) && (
                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentProductViewDescription
                            header={t('application.listingDetails.detailedDescription')}
                            descriptionText={props.description ?? '-'}
                        >
                            {prime === false && props.picture?.length ? (
                                <FileMultiUploadItemView files={props.picture} apiBaseUrl={apiBaseUrl} isPicture />
                            ) : null}
                        </CardContentProductViewDescription>
                    </CardLayout>
                </Card>
            )}
        </>
    );
};

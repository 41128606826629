import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { MaterialType, Definition, Product, Shape, SearchFormData } from '@steelbuy/domain-model';

export interface SearchFormDataContextValue {
    setSearchFormData: (searchFormData: SearchFormData) => void;
    getSearchFormData: () => SearchFormData;
}

const defaultSearchFormData: SearchFormData = {
    materialType: MaterialType.MILD_STEEL,
    shape: Shape.FLAT,
    product: Product.COIL,
    definition: Definition.HOT_ROLLED,
};

const defaultValue: SearchFormDataContextValue = {
    setSearchFormData: () => {
        // default noop
    },
    getSearchFormData: () => defaultSearchFormData,
};

const SearchFormDataContext = createContext(defaultValue);

export const SearchFormDataContextProvider = ({ children }: { children: ReactNode }) => {
    const [searchFormData, setSearchFormData] = useState<SearchFormData>(defaultSearchFormData);

    const value = useMemo(
        () => ({
            getSearchFormData: () => searchFormData,
            setSearchFormData: (searchData: SearchFormData) => {
                if (searchData.product === Product.PACKAGE && searchFormData.product !== Product.PACKAGE) {
                    setSearchFormData({
                        ...searchData,
                        minWidth: undefined,
                        minLength: undefined,
                        maxWidth: undefined,
                        maxLength: undefined,
                        width: undefined,
                        length: undefined,
                        thickness: undefined,
                    });
                } else {
                    setSearchFormData(searchData);
                }
            },
        }),
        [searchFormData, setSearchFormData]
    );

    return <SearchFormDataContext.Provider value={value}>{children}</SearchFormDataContext.Provider>;
};

export const useSearchFormDataContext = () => useContext(SearchFormDataContext);

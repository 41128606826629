import { FieldConverterMap, ModelConverter, ModelConverterInterface } from '@steelbuy/domain-model-converter';

import { ListingBuyerModel, ListingBuyerModelConverter } from './ListingBuyerModel';

export type ListingBuyerSearchResultModel = {
    readonly listing: ListingBuyerModel;
    readonly accuracy: number;
    readonly isPublishedWithin24Hours?: boolean;
};

export class ListingBuyerSearchResultModelConverter extends ModelConverter<ListingBuyerSearchResultModel> {
    override getFieldConverterMapByModel(): FieldConverterMap<ListingBuyerSearchResultModel> {
        return {
            // eslint-disable-next-line
            listing: new ListingBuyerModelConverter() as ModelConverterInterface<any>,
        };
    }
}

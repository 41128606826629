declare global {
    interface Window {
        newrelic: {
            noticeError: (error: Error) => void;
        };
    }
}

export const reportNRError = (error: Error) => {
    window.newrelic?.noticeError?.(error);
};

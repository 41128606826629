import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrencyCode, formatCurrency } from '@steelbuy/currency';
import { useSellerPackageDetails, useUpdatePackage } from '@steelbuy/data-access';
import { useUserSelf } from '@steelbuy/data-provider';
import { ListingStatus, PackageSellerModel, Product, UserRole, getPricingUnit } from '@steelbuy/domain-model';
import { MonetaryAmount, createDateFromTimestamp, createTimestampFromDate } from '@steelbuy/domain-model-types';
import { NotFoundError } from '@steelbuy/error';
import {
    ListingDetailsExpiration,
    ListingDetailsExpirationView,
    ListingDetailsStatus,
    MarketingBannerSteelbuyPromise,
    MaterialPriceInput,
    MonetaryAmountInput,
} from '@steelbuy/ui-domain';
import {
    LoadingSpinner,
    Notification,
    useSuccessErrorNotification,
    NotificationLevel,
    Card,
    CardLayout,
    AddressIdentifier,
    CardContentAttributesItem,
    CardSellerSummaryWeightPrice,
    LoadingSpinnerOverlay,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { CommonPackageDetails } from '../common-listing-details/CommonPackageDetails';
import { AUTO_RENEW_WINDOW_MS, getMonetaryAmount } from '../common-utils/AutoRenewUtils';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import { HistoricalAddressDetails } from '../historical-address-details/HistoricalAddressDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';

import './SellerPackageDetails.scss';
import { ListingsRejectionCard } from '../seller-listing-details/ListingsRejectionCard';
import { RenewListingSuccess } from '../seller-listing-details/RenewListingSuccess';
import { SellerActionCta } from '../seller-listing-details/SellerActionCta';
import { UpdateListingCheckboxes } from '../seller-listing-details/UpdateListingCheckboxes';

interface SellerPackageDetailsProps {
    packageId: string;
    goBackPath: string;
}

type SellerPackageDetailsViewProps = {
    packageModel: PackageSellerModel;
    isLoading: boolean;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<PackageSellerModel, unknown>>;
} & Omit<SellerPackageDetailsProps, 'packageId'>;

export const SellerPackageDetailsView = ({
    goBackPath,
    packageModel,
    refetch,
    isLoading,
}: SellerPackageDetailsViewProps) => {
    const { t } = useTranslation(['translation', 'uiDomain', 'domainModel']);
    const navigate = useNavigate();
    const userData = useUserSelf().get();
    const createListingContext = useCreateListingContext();
    const hasSellerRole = userData?.roles.includes(UserRole.SELLER) ?? false;
    const [expiryDate, setExpiryDate] = useState<Date | undefined>(createDateFromTimestamp(packageModel.expiresAt));
    const [autoRenewExpiryDate, setAutoRenewExpiryDate] = useState<Date | undefined>(
        packageModel.autoRenew.expiresAt ? createDateFromTimestamp(packageModel.autoRenew.expiresAt) : undefined
    );
    const [autoRenew, setAutoRenew] = useState<boolean>(packageModel.autoRenew.enabled);
    const isExpired = packageModel.status.value === ListingStatus.EXPIRED;
    const isRejected = packageModel.status.value === ListingStatus.REVIEW_REJECTED;
    const isPurchased = packageModel.status.value === ListingStatus.PURCHASED;
    const isWithdrawn = packageModel.status.value === ListingStatus.WITHDRAWN;
    const isRenewable = isExpired && hasSellerRole;
    const pricingUnit = getPricingUnit({ materialType: packageModel.material, product: Product.PACKAGE });
    const {
        Notification: UpdateNotification,
        showSuccessNotification,
        showErrorNotification,
    } = useSuccessErrorNotification();
    const { mutate, isLoading: isUpdating } = useUpdatePackage();
    const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);

    const [sellingPrice, setSellingPrice] = useState<MonetaryAmount | undefined>(packageModel.pricing.netPricePerUnit);

    const [autoRenewPrice, setAutoRenewPrice] = useState<MonetaryAmount | undefined>(
        getMonetaryAmount(packageModel.autoRenew?.price)
    );
    const autoRenewTimestamp = autoRenewExpiryDate ? createTimestampFromDate(autoRenewExpiryDate) : undefined;
    const [clearAutoRenewFields, setClearAutoRenewFields] = useState(0); // hack to force remount of MaterialPriceInput
    const [agreedToTAndC, setAgreedToTAndC] = useState<boolean>(false);
    const [agreedAuthorised, setAgreedAuthorised] = useState<boolean>(false);
    const autoRenewTimestampChanged =
        (autoRenewTimestamp && !packageModel.autoRenew.expiresAt) ||
        (autoRenewTimestamp &&
            packageModel.autoRenew.expiresAt &&
            new Date(autoRenewTimestamp.value).getTime() !==
                new Date(packageModel.autoRenew.expiresAt.value).getTime());
    const isDirtyFields = !!(
        autoRenewPrice?.value !== packageModel.autoRenew.price ||
        autoRenew !== packageModel.autoRenew.enabled ||
        autoRenewTimestampChanged
    );

    const createSamePackageListing = () => {
        createListingContext.copyFromPackage(packageModel);
        navigate(RoutePath.CREATE_LISTING_WIZARD);
    };
    let packageDetailsExpirationViewMode: ListingDetailsExpirationView;
    if (!hasSellerRole || isPurchased || isRejected || isWithdrawn) {
        packageDetailsExpirationViewMode = ListingDetailsExpirationView.READ;
    } else if (isExpired) {
        packageDetailsExpirationViewMode = ListingDetailsExpirationView.EXPIRED;
    } else if (new Date(packageModel.expiresAt.value).getTime() - new Date().getTime() < AUTO_RENEW_WINDOW_MS) {
        packageDetailsExpirationViewMode = ListingDetailsExpirationView.LIVE_EDITABLE;
    } else {
        packageDetailsExpirationViewMode = ListingDetailsExpirationView.READ;
    }

    const resetFields = () => {
        setSellingPrice(packageModel.pricing.netPricePerUnit);
        setAutoRenewPrice(getMonetaryAmount(packageModel.autoRenew?.price));
        setAutoRenewExpiryDate(
            packageModel.autoRenew.expiresAt ? createDateFromTimestamp(packageModel.autoRenew.expiresAt) : undefined
        );
        setAutoRenew(packageModel.autoRenew.enabled);
        setClearAutoRenewFields(clearAutoRenewFields + 1);
    };
    const checkBoxChecked = agreedToTAndC && agreedAuthorised;

    const isPublishDisabled = () => {
        if (!checkBoxChecked) {
            return true;
        }
        if (sellingPrice === undefined) {
            return true;
        }
        return expiryDate === undefined;
    };
    const renewExpiredPackage = async () => {
        if (!isExpired || !expiryDate) {
            return;
        }
        mutate(
            {
                id: packageModel.id,
                isRenewal: true,
                packageListingRequest: {
                    autoRenew: { enabled: autoRenew },
                    expiresAt: createTimestampFromDate(expiryDate).value,
                    pricing: { netPricePerUnit: sellingPrice?.value, currencyCode: packageModel.pricing.currencyCode },
                },
            },
            {
                onSuccess: () => {
                    setShowSuccessPage(true);
                },
                onError: () => {
                    showErrorNotification(t('translation:application.sellerListingDetails.updateError'));
                },
            }
        );
    };

    const updateAutoRenewFields = async () => {
        if (autoRenewExpiryDate || autoRenewPrice || autoRenew !== packageModel.autoRenew.enabled) {
            try {
                mutate(
                    {
                        id: packageModel.id,
                        packageListingRequest: {
                            autoRenew: {
                                enabled: autoRenew,
                                ...(autoRenewPrice?.value && { price: autoRenewPrice.value }),
                                ...(autoRenewExpiryDate && { expiresAt: autoRenewExpiryDate }),
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            refetch();
                            showSuccessNotification(t('translation:application.sellerListingDetails.updateSuccess'));
                            setAgreedToTAndC(false);
                            setAgreedAuthorised(false);
                            scrollMainLayout(0, 0);
                        },
                        onError: () => {
                            showErrorNotification(t('translation:application.sellerListingDetails.updateError'));
                        },
                    }
                );
            } catch (e) {
                showErrorNotification(t('translation:application.sellerListingDetails.updateError'));
            }
        }
    };

    const renderButtons = () => (
        <SellerActionCta
            checkBoxChecked={checkBoxChecked}
            createSameListing={createSamePackageListing}
            detailsExpirationViewMode={packageDetailsExpirationViewMode}
            hasSellerRole={hasSellerRole}
            isDirtyFields={isDirtyFields}
            isExpired={isExpired}
            isPublishDisabled={isPublishDisabled}
            isRenewable={isRenewable}
            renewExpiredListing={renewExpiredPackage}
            resetFields={resetFields}
            updateAutoRenewFields={updateAutoRenewFields}
        />
    );

    return (
        <>
            {(isLoading || isUpdating) && <LoadingSpinnerOverlay />}
            <MaterialPageHeader
                packageModel={packageModel}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(goBackPath)}
            />
            <div className="seller-package-details">
                {showSuccessPage ? (
                    <RenewListingSuccess />
                ) : (
                    <DetailsLayout>
                        {UpdateNotification}
                        <ListingDetailsStatus status={packageModel.status}>
                            {isRenewable && (
                                <Notification
                                    level={NotificationLevel.INFO}
                                    message={t('uiDomain:listingDetailsStatus.expiredInfo')}
                                />
                            )}
                        </ListingDetailsStatus>
                        {isRejected && (
                            <ListingsRejectionCard
                                internalSellerSku={packageModel.internalSellerSku}
                                reason={packageModel.status.reason ?? ''}
                            />
                        )}

                        <CommonPackageDetails packageModel={packageModel} showSku />

                        <CardSellerSummaryWeightPrice
                            header={t(`translation:application.sellerListingDetails.weightAndSellingPrice.offerTitle`)}
                            renderPrice={(isEditable) => {
                                if (isEditable) {
                                    return (
                                        <MonetaryAmountInput
                                            initialMonetaryAmount={sellingPrice}
                                            currencyCode={packageModel.pricing.currencyCode}
                                            updateMonetaryAmount={setSellingPrice}
                                            label=""
                                            name="sellingPrice"
                                            required
                                            pricingUnit={getPricingUnit({
                                                materialType: packageModel.material,
                                                product: Product.PACKAGE,
                                            })}
                                        />
                                    );
                                }

                                if (packageModel.pricing.netPricePerUnit) {
                                    return `${formatCurrency(
                                        navigator.language,
                                        packageModel.pricing.netPricePerUnit.value,
                                        packageModel.pricing.currencyCode
                                    )} ${t('domainModel:material.perPricingUnit', {
                                        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                                    })}`;
                                }
                                return null;
                            }}
                            weight={t(`domainModel:material.tradeUnit.value.${packageModel.tradeUnit}.quantity`, {
                                count: packageModel.weight,
                            })}
                            weightLabel={t('translation:application.sellerListingDetails.weightAndSellingPrice.weight')}
                            isEditable={isRenewable}
                            isAutoRenewPriceEditable={
                                autoRenew &&
                                packageDetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE
                            }
                        >
                            {packageDetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE &&
                                autoRenew && (
                                    <MaterialPriceInput
                                        header={t(
                                            'translation:application.sellerListingDetails.weightAndSellingPrice.newSellingPriceHeader',
                                            {
                                                tradeUnit: t(
                                                    `domainModel:material.pricingUnit.value.${pricingUnit}.label`
                                                ),
                                            }
                                        )}
                                        priceCurrencyCode={CurrencyCode.GBP}
                                        initialPrice={autoRenewPrice}
                                        setPrice={setAutoRenewPrice}
                                        required
                                        pricingUnit={getPricingUnit({
                                            materialType: packageModel.material,
                                            product: Product.PACKAGE,
                                        })}
                                        key={clearAutoRenewFields}
                                        name="sellingPrice"
                                    />
                                )}
                            {!isPurchased && (
                                <Notification
                                    level={NotificationLevel.INFO}
                                    message={t(
                                        'translation:application.sellerListingDetails.weightAndSellingPrice.offerNotification'
                                    )}
                                />
                            )}
                        </CardSellerSummaryWeightPrice>

                        {!isPurchased && (
                            <ListingDetailsExpiration
                                expiryDate={expiryDate}
                                onExpiryDateChange={setExpiryDate}
                                autoRenew={autoRenew}
                                showAutoRenew={!isWithdrawn && !isRejected}
                                onAutoRenewChange={setAutoRenew}
                                autoRenewExpiryDate={autoRenewExpiryDate}
                                onAutoRenewExpiryChange={setAutoRenewExpiryDate}
                                viewMode={packageDetailsExpirationViewMode}
                                key={clearAutoRenewFields}
                            >
                                {(isRenewable ||
                                    packageDetailsExpirationViewMode ===
                                        ListingDetailsExpirationView.LIVE_EDITABLE) && (
                                    <Notification
                                        level={NotificationLevel.INFO}
                                        message={t(
                                            'translation:application.createListingSummary.expirationNotificationMessage'
                                        )}
                                    />
                                )}
                            </ListingDetailsExpiration>
                        )}

                        <Card isClickable={false}>
                            <CardLayout>
                                <HistoricalAddressDetails
                                    addressId={packageModel.pickupAddress.id}
                                    type={AddressIdentifier.DELIVERY_INFO}
                                    labelText={t('application.addressDetails.collectionInformationLabel')}
                                    additionalText={packageModel.additionalInformation}
                                    attributeLabel={t(
                                        'translation:application.addressDetails.deliveryInfoCollectionLabel'
                                    )}
                                />
                                <div className="delivery-time-frame">
                                    <CardContentAttributesItem
                                        label={t('translation:application.addressDetails.deliveryTimeframeLabel')}
                                        value={t(`domainModel:deliveryTimeframe.${packageModel.deliveryTimeframe}`)}
                                    />
                                </div>
                            </CardLayout>
                        </Card>

                        <MarketingBannerSteelbuyPromise />

                        <UpdateListingCheckboxes
                            agreedAuthorised={agreedAuthorised}
                            agreedToTAndC={agreedToTAndC}
                            setAgreedAuthorised={setAgreedAuthorised}
                            setAgreedToTAndC={setAgreedToTAndC}
                            isRenewable={isRenewable}
                            DetailsExpirationViewMode={packageDetailsExpirationViewMode}
                        />

                        {renderButtons()}
                    </DetailsLayout>
                )}
            </div>
        </>
    );
};

export const SellerPackageDetails = ({ packageId, goBackPath }: SellerPackageDetailsProps) => {
    const { data: packageModel, isLoading, error, refetch } = useSellerPackageDetails({ packageId });

    if (isLoading) {
        return <LoadingSpinner />;
    }
    if (error || !packageModel) {
        throw new NotFoundError();
    }

    return (
        <SellerPackageDetailsView
            goBackPath={goBackPath}
            packageModel={packageModel}
            refetch={refetch}
            isLoading={isLoading}
        />
    );
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCartDetails } from '@steelbuy/data-access';
import { RoutePath } from '../router/Routes';

const useCartCacheUpdater = () => {
    const location = useLocation();
    const { refetch } = useCartDetails({ queryOptions: { enabled: true } }, true);

    const pathToExclude = [RoutePath.MY_CART, RoutePath.CREATE_RFQ_STEP2, RoutePath.CREATE_RFQ_STEP3];
    useEffect(() => {
        const shouldRefetch = !pathToExclude.some((path) => location.pathname.includes(path));

        if (shouldRefetch) {
            refetch();
        }
    }, [location.pathname, refetch]);
};

export default useCartCacheUpdater;

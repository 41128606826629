import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@steelbuy/auth';
import { usePasswordChange } from '@steelbuy/data-access';
import { SetPasswordForm } from '@steelbuy/ui-domain';

import { ROUTE_ACCOUNT_OVERVIEW } from '../../components/account/Account.enum';
import { PageHeader } from '../../components/page-header/PageHeader';
import { FormLayout } from '../layouts/form-layout/FormLayout';

import './ChangePasswordView.scss';

export const ChangePasswordView = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const passwordMutation = usePasswordChange();
    const auth = useAuth();

    const onSubmit = async (newPassword: string, oldPassword: string) => {
        setLoading(true);
        setError('');
        try {
            await passwordMutation.mutateAsync({ oldPassword, newPassword });
            setLoading(false);
            auth.unauthenticate(`password_change=true`);
        } catch (e) {
            setLoading(false);
            if (e instanceof AxiosError && e.response && e.response?.data?.message?.includes('wrong password')) {
                setError(t('application.accountChangePassword.incorrectPassword'));
            } else if (
                e instanceof AxiosError &&
                e.response &&
                e.response?.data?.message?.includes('Attempt Limit Exceed')
            ) {
                setError(t('application.accountChangePassword.rateLimitError'));
            } else {
                setError(t('application.accountChangePassword.genericErrorMessage'));
            }
        }
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.accountChangePassword.pageTitle')}
                previousPageTitle={t('application.accountEditEmail.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_OVERVIEW)}
            />
            <main className="change-password-form change-password-form--center">
                <FormLayout>
                    <SetPasswordForm
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        isOldPasswordRequired
                        error={error}
                        oldPasswordError={!!error}
                    />
                </FormLayout>
            </main>
        </>
    );
};

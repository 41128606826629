import { ApiModel } from '@steelbuy/domain-model-types';

import { AddressModel } from './AddressModel';
import { AddressType, Country } from './AddressModel.enums';
import { organisationModelMock } from './OrganisationModel.mock';

export const addressWarehouseModelMock: ApiModel<AddressModel> = {
    id: '123',
    organisation: organisationModelMock,
    addressType: AddressType.WAREHOUSE,
    name: 'Chroma X',
    addressLine1: 'Ringstreet 89',
    addressLine2: 'SH',
    city: 'Kiel',
    postalCode: '24114',
    country: Country.GB,
    primary: false,
    phone: '+494312400666',
};

export const addressInvoiceModelMock: ApiModel<AddressModel> = {
    id: '456',
    organisation: organisationModelMock,
    addressType: AddressType.INVOICE,
    name: 'Chroma X',
    addressLine1: 'Ringstreet 89',
    city: 'Kiel',
    postalCode: '24114',
    country: Country.GB,
    primary: false,
    phone: '+494312400666',
};

export enum UrgentAlertFrequency {
    INSTANTLY = 'INSTANTLY',
    PER_6_HOURS = 'PER_6_HOURS',
    PER_DAY = 'PER_DAY',
}

export type UrgentAlerts = {
    enabled: boolean;
    frequency: UrgentAlertFrequency;
};

export type NotificationSettings = {
    urgentAlerts: UrgentAlerts;
};

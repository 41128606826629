import { FormEvent, useRef, useState } from 'react';

import { useFooEntityData } from '@steelbuy/data-provider';
import { FooModel } from '@steelbuy/domain-model';
import { createInputValueFromTimestamp, createTimestampFromMaybeDate, Mutation } from '@steelbuy/domain-model-types';
import { Nullable } from '@steelbuy/types';

export type FooEntityMutationProps = {
    readonly model: FooModel;
};

export const FooEntityMutation = (props: FooEntityMutationProps) => {
    const { model } = props;

    const fooEntity = useFooEntityData();
    const fooMutation = useRef<Mutation<FooModel>>({});
    const [filePicked, setFilePicked] = useState<Nullable<File>>(null);

    const handleMutate = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        fooEntity.mutate(fooMutation.current);
    };

    return (
        <div>
            <form onSubmit={handleMutate}>
                <label>
                    Name
                    <input
                        type="text"
                        name="name"
                        defaultValue={model.name}
                        onChange={(event) => {
                            fooMutation.current.name = event.target.value;
                        }}
                    />
                </label>
                <br />
                <label>
                    Date of birth
                    <input
                        type="date"
                        name="dateOfBirth"
                        defaultValue={createInputValueFromTimestamp(model.dateOfBirth).getOrDefault('')}
                        onChange={(event) => {
                            fooMutation.current.dateOfBirth = createTimestampFromMaybeDate(
                                event.target.valueAsDate
                            ).getOrUndefined();
                        }}
                    />
                </label>
                <br />
                <label>
                    Some file
                    <input
                        type="file"
                        name="downloadFile"
                        onChange={(event) => {
                            const selectedFiles = event.target.files;
                            if (selectedFiles === null || selectedFiles.length === 0) {
                                setFilePicked(null);
                                return;
                            }
                            const selectedFile = selectedFiles[0];
                            setFilePicked(selectedFile);
                        }}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

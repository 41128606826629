import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonPrimary,
    ButtonTertiaryOnLightM,
    CustomLink,
    CustomLinkProps,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

import './GenericError.scss';

type ButtonProps = {
    label: string;
    onClick: () => void;
};

type LinkProps = {
    text: string;
} & Omit<CustomLinkProps, 'children'>;

type GenericErrorProps = {
    HelperComponent?: ReactNode;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    showSecondary?: boolean;
    link?: LinkProps;
};

export const GenericError = ({
    HelperComponent,
    primaryButton,
    secondaryButton,
    showSecondary = true,
    link,
}: GenericErrorProps) => {
    const { t } = useTranslation('translation');

    return (
        <div className="generic-error">
            <NotificationIllustrated
                alignment={NotificationIllustratedAlignment.VERTICAL}
                imgSrc="/assets/lib-ui-primitive/images/CommonSystemError.svg"
                helperText={HelperComponent === undefined ? t('application.genericError.helperText') : undefined}
                HelperComponent={HelperComponent}
            >
                <div className="generic-error__buttons">
                    {showSecondary && (
                        <ButtonTertiaryOnLightM
                            label={secondaryButton?.label || t('application.genericError.dashboardButtonLabel')}
                            onClick={secondaryButton?.onClick || (() => (window.location.href = RoutePath.DASHBOARD))}
                        />
                    )}
                    <ButtonPrimary
                        label={primaryButton?.label || t('application.genericError.reloadButtonLabel')}
                        onClick={primaryButton?.onClick || (() => window.location.reload())}
                    />
                </div>
                {link && <CustomLink {...link}>{link.text}</CustomLink>}
            </NotificationIllustrated>
        </div>
    );
};

import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import {
    ButtonSecondary,
    ButtonTertiaryOnLightM,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';

export const CreateUnsavedChangesModal = ({
    modal,
    error,
    children,
    headline,
    setError,
    unblockAndRetry,
}: {
    modal: ModalAccessor;
    error: boolean;
    children: ReactNode;
    headline: string;
    setError: Dispatch<SetStateAction<boolean>>;
    unblockAndRetry: () => void;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <Modal modalAccessor={modal}>
            <ModalContent onCloseModal={modal.hide} headline={headline}>
                <ModalContentMain imgSrc="/assets/lib-ui-primitive/images/ListingDraft-Neutral.svg" />
                {error && (
                    <Notification
                        level={NotificationLevel.ERROR}
                        message={t('application.createListingUnsavedChangesModal.savingFailedNotification')}
                        onClose={() => setError(false)}
                        closeButton
                    />
                )}
                <ModalContentActionBar>
                    <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={modal.hide} />
                    <ButtonTertiaryOnLightM
                        label={t('application.createListingUnsavedChangesModal.discardButtonLabel')}
                        onClick={unblockAndRetry}
                    />
                    {children}
                </ModalContentActionBar>
            </ModalContent>
        </Modal>
    );
};

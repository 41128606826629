import { stepsOrder, Steps, packageStepsOrder } from './CreateListingContextUtil';
import { DimensionsStep } from './steps/DimensionsStep';
import { ListUploadStep } from './steps/ListUploadStep';
import { MaterialStep } from './steps/MaterialStep';
import { PickupAddressStep } from './steps/PickupAddressStep';
import { WeightPriceStep } from './steps/WeightPriceStep';

const mapSteps = (step: Steps) => {
    let component;
    switch (step) {
        case Steps.MATERIAL:
            component = MaterialStep;
            break;
        case Steps.DIMENSIONS:
            component = DimensionsStep;
            break;
        case Steps.WEIGHT_PRICE:
            component = WeightPriceStep;
            break;
        case Steps.LIST_UPLOAD:
            component = ListUploadStep;
            break;
        default:
            component = PickupAddressStep;
    }
    return {
        id: step,
        component,
    };
};

export const STEPS = stepsOrder.map(mapSteps);

export const PACKAGE_STEPS = packageStepsOrder.map(mapSteps);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelConverter } from '@steelbuy/domain-model-converter';

import { Product } from './MaterialModel.enums';

/**
 * Mild steel foundation
 */
type MildSteelMaterialDimensionModelFoundation = {
    // Note: Width unit millimeter
    width: number;
    // Note: Thickness unit millimeter
    thickness: number;
};

/**
 * Mild steel flat products foundation
 */
export type MildSteelCoilMaterialDimensionModel = {
    product: Product.COIL;
} & MildSteelMaterialDimensionModelFoundation;

export type MildSteelSheetMaterialDimensionModel = {
    product: Product.SHEET;
    // Note: Length unit millimeter
    length: number;
} & MildSteelMaterialDimensionModelFoundation;

export type MildSteelPlateDimensionMaterialModel = {
    product: Product.PLATE;
    // Note: Length unit millimeter
    length: number;
} & MildSteelMaterialDimensionModelFoundation;

// Mild steel flat material union
export type AnyMildSteelFlatMaterialDimensionModel =
    | MildSteelCoilMaterialDimensionModel
    | MildSteelSheetMaterialDimensionModel
    | MildSteelPlateDimensionMaterialModel;

export type AnyMaterialDimensionModel<Prod extends Product = any> = AnyMildSteelFlatMaterialDimensionModel & {
    product: Prod;
};

export class AnyMaterialDimensionModelConverter extends ModelConverter<AnyMaterialDimensionModel> {}

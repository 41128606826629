import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './ActFastNotification.scss';
import { IconIdentifier, Notification, NotificationLevel } from '@steelbuy/ui-primitive';

const getRemainingTime = (expiresAt: string) => {
    const expirationTime = new Date(expiresAt);
    const now = new Date();
    const timeDiff = expirationTime.getTime() - now.getTime();
    if (timeDiff <= 0) {
        return '00:00';
    }
    const minutes = Math.floor(timeDiff / 1000 / 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

const TimerNotification = ({ expiresAt }: { expiresAt?: string }) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    const [time, setTime] = useState<string | undefined>(() => (expiresAt ? getRemainingTime(expiresAt) : '00:00'));

    useEffect(() => {
        if (expiresAt) {
            const updateTime = () => {
                const newTime = getRemainingTime(expiresAt);
                setTime(newTime);
            };
            const intervalId = setInterval(() => {
                updateTime();
                if (time === '00:00') {
                    clearInterval(intervalId);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }

        return undefined;
    }, [expiresAt]);

    return (
        <div className="notification__main-content__add-to-cart-content">
            <span className="notification__main-content__message">
                {expiresAt && (
                    <Trans
                        i18nKey="application.addToCartConfirmModal.actFast"
                        components={{
                            time: <span className="notification__main-content__add-to-cart-content__time" />,
                        }}
                        t={t}
                        values={{ time }}
                    />
                )}
            </span>
            <span className="notification__main-content__message">
                {t('application.addToCartConfirmModal.itemsNotReserved')}
            </span>
        </div>
    );
};

export const ActFastNotification = ({ expiresAt }: { expiresAt?: string }) => (
    <div className="act-fast-notification">
        <Notification
            level={NotificationLevel.INFO}
            icon={IconIdentifier.TIMER}
            Component={<TimerNotification expiresAt={expiresAt} />}
            message=""
            stayOpen
        />
    </div>
);

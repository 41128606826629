import { useParams } from 'react-router-dom';
import { ListingBuyerAlertCollectionDataProvider } from '@steelbuy/data-provider';
import { MyAlertsSettings } from '../../../components/my-alerts/MyAlertsSettings';

export const MyAlertsSettingsView = () => {
    const { alertId = null } = useParams();

    if (!alertId) {
        throw new Error('alertId not given!');
    }

    return (
        <ListingBuyerAlertCollectionDataProvider lazyLoad>
            <MyAlertsSettings alertId={alertId} />
        </ListingBuyerAlertCollectionDataProvider>
    );
};

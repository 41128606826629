import { useTranslation } from 'react-i18next';
import { FormItem, InputCheckbox } from '@steelbuy/ui-primitive';

import './AutoRenewalCheckbox.scss';

type AutoRenewalCheckboxProps = {
    enabled?: boolean;
    onChange: (enabled?: boolean) => void;
};

export const AutoRenewalCheckbox = ({ enabled, onChange }: AutoRenewalCheckboxProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <FormItem header={t('autoRenew.autoRenewHeader')}>
            <div className="auto-renewal">
                <div className="auto-renewal__helper-text">{t('autoRenew.autoRenewHelper')}</div>
                <InputCheckbox label={<>{t('autoRenew.autoRenewLabel')}</>} checked={enabled} onChange={onChange} />
            </div>
        </FormItem>
    );
};

import { ReactNode } from 'react';

import { buildClassStringFromClassMap, classNameFromEnumValue } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputRadioButtonGroupIllustration.scss';

export enum InputRadioButtonGroupIllustrationAlign {
    ALIGN_VERTICAL = 'ALIGN_VERTICAL',
    ALIGN_HORIZONTAL = 'ALIGN_HORIZONTAL',
}

export type InputRadioButtonGroupIllustrationProps = {
    align: InputRadioButtonGroupIllustrationAlign;
    label?: string;
    helperText?: string;
    error?: string;
    children: ReactNode;
};

export const InputRadioButtonGroupIllustration = (props: InputRadioButtonGroupIllustrationProps) => {
    const { align = null, label, helperText, error, children } = props;

    const alignClass = ` input-radio-button-group-illustration__items--${classNameFromEnumValue(String(align))}`;

    const helperTextClassMap = {
        'input-radio-button-group-illustration__helper-text': true,
        'input-radio-button-group-illustration__helper-text--error': error,
    };

    const renderErrorIcon = () => {
        if (error === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const renderHelperText = () => {
        if (helperText === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{helperText}</span>;
    };

    const renderWrapper = () => {
        const errorIcon = renderErrorIcon();

        if (!errorIcon && !label) {
            return null;
        }

        return (
            <span className="input-radio-button-group-illustration__wrapper">
                <span className="input-radio-button-group-illustration__wrapper__label">{label}</span>
                {errorIcon}
            </span>
        );
    };

    return (
        <article className="input-radio-button-group-illustration">
            {renderWrapper()}
            <article className={`input-radio-button-group-illustration__items${alignClass}`}>{children}</article>
            {renderHelperText()}
        </article>
    );
};

import { ReactNode } from 'react';

import './FormActionbarWithColumns.scss';

export type FormActionbarWithColumnsProps = {
    children: ReactNode;
};

export const FormActionbarWithColumns = ({ children }: FormActionbarWithColumnsProps) => (
    <div className="form-actionbar-with-columns">{children}</div>
);

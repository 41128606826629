import { ChallengeNameType } from '@aws-sdk/client-cognito-identity-provider';
import { AuthContextValue, JsonWebToken, RefreshToken } from '@steelbuy/auth';
import { Nullable } from '@steelbuy/types';
import { JsonRestConnector } from './network/JsonRestConnector';

const connector = new JsonRestConnector();

export type LoginSuccessResponse = {
    accessToken: string;
    refreshToken: string;
};

export type ChallengeRequiredBody = {
    session: string;
    challengeType: ChallengeNameType;
};

export enum Tenant {
    ADMIN = 'ADMIN',
    MARKETPLACE = 'MARKETPLACE',
}

export type LoginResponse = ChallengeRequiredBody | LoginSuccessResponse;

const AUTH_PATH = 'api/auth/login';
const LOGOUT_PATH = 'api/auth/revoke-token';
const REFRESH_TOKEN_PATH = 'api/auth/refresh-token';
const CHALLENGE_RESPOND_PATH = 'api/auth/challenge-respond';
const FORGOT_PASSWORD_PATH = 'api/auth/forgot-password';
const RESET_PASSWORD_PATH = 'api/auth/forgot-password/reset-password';
const VERIFY_RESET_PASSWORD_TOKEN_PATH = 'api/auth/forgot-password/verify';
const RESEND_WELCOME_EMAIL_PATH = 'api/public/users/resend-welcome-email';
const UPDATE_EMAIL_PATH = 'api/universal/users/email-change';
const VALIDATE_EMAIL_PATH = 'api/universal/users/email-validation';

const unauthenticate = (authConsumer: AuthContextValue) => {
    authConsumer.unauthenticate();
    window.location.assign('login');
};

export const refreshToken = async (
    baseUrl: string,
    refreshTokenVal: RefreshToken,
    tenant: Tenant
): Promise<string | void> => {
    const response = await connector.post(`${baseUrl}${REFRESH_TOKEN_PATH}`, {
        refreshToken: refreshTokenVal,
        tenant,
    });
    return (response.body as LoginSuccessResponse).accessToken;
};

export const createAccessTokenLoader =
    (authConsumer: AuthContextValue, apiUrl: string): (() => Promise<Nullable<JsonWebToken>>) =>
    async () => {
        const refreshAuthToken = authConsumer.getToken().get()?.refreshToken;
        if (!refreshAuthToken) {
            unauthenticate(authConsumer);
            return Promise.resolve(null);
        }
        if (authConsumer.needsUpdate()) {
            try {
                const accessToken = await refreshToken(apiUrl, refreshAuthToken, Tenant.MARKETPLACE);
                accessToken && authConsumer.update(accessToken);
            } catch (e) {
                // TODO: maybe remove if we want another error handling on a higher level
                // (e.g. handling all authentication errors in a general error handler)
                unauthenticate(authConsumer);
            }
        }
        return authConsumer.getToken().get();
    };

type ResetPasswordResponse = {
    email: string;
};

export function isLoginSuccess(obj: LoginResponse): obj is LoginSuccessResponse {
    return (obj as LoginSuccessResponse).accessToken !== undefined;
}

const getAuthConnector = async (authConsumer: AuthContextValue, apiBaseUrl: string) => {
    const getToken = createAccessTokenLoader(authConsumer, apiBaseUrl);
    const token = await getToken();
    return new JsonRestConnector(token);
};

export const login = async (
    baseUrl: string,
    username: string,
    password: string,
    tenant: Tenant,
    recaptchaToken?: string | null
) => {
    const response = await connector.post(`${baseUrl}${AUTH_PATH}`, {
        username,
        password,
        tenant,
        recaptchaToken,
    });
    return response.body as LoginResponse;
};

export const logout = async (baseUrl: string, token: RefreshToken, tenant: Tenant) =>
    connector.post(`${baseUrl}${LOGOUT_PATH}`, {
        refreshToken: token,
        tenant,
    });

interface ChallengeRespond {
    type: ChallengeNameType;
    session: string;
    options: Record<string, string | number>;
    tenant: Tenant;
}

export const challengeRespond = async (baseUrl: string, challengeDto: ChallengeRespond) => {
    const response = await connector.post(`${baseUrl}${CHALLENGE_RESPOND_PATH}`, {
        ...challengeDto,
    });
    return response.body as LoginResponse;
};

export const sendPasswordResetEmail = async (
    baseUrl: string,
    email: string,
    tenant: Tenant,
    recaptchaToken?: string | null
) => {
    connector.post(`${baseUrl}${FORGOT_PASSWORD_PATH}`, {
        email,
        tenant,
        recaptchaToken,
    });
};

export const resetPassword = async (
    baseUrl: string,
    password: string,
    token: string,
    tenant: Tenant
): Promise<string> => {
    const response = await connector.put(`${baseUrl}${RESET_PASSWORD_PATH}`, {
        password,
        token,
        tenant,
    });
    return (response.body as ResetPasswordResponse).email;
};

export const verifyResetPasswordToken = async (baseUrl: string, token: string) => {
    await connector.post(`${baseUrl}${VERIFY_RESET_PASSWORD_TOKEN_PATH}`, {
        token,
    });
};

export const resendWelcomeEmail = async (baseUrl: string, email: string) => {
    await connector.get(`${baseUrl}${RESEND_WELCOME_EMAIL_PATH}?email=${email}`);
};

export const updateEmailAddress = async (authConsumer: AuthContextValue, apiBaseUrl: string, email: string) => {
    const authorisedConnector = await getAuthConnector(authConsumer, apiBaseUrl);
    await authorisedConnector.put(`${apiBaseUrl}${UPDATE_EMAIL_PATH}`, {
        email,
    });
};

export const validateEmailAddress = async (authConsumer: AuthContextValue, apiUrl: string, token: string) => {
    const authorisedConnector = await getAuthConnector(authConsumer, apiUrl);
    await authorisedConnector.post(`${apiUrl}${VALIDATE_EMAIL_PATH}/${token}`, {});
};

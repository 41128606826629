import { useTranslation } from 'react-i18next';
import { OfferListingStatus, OfferView } from '@steelbuy/domain-model';
import { BadgeSecondary, BadgeVariant, IconIdentifier } from '@steelbuy/ui-primitive';

export type OfferStatusBadgeProps = {
    status?: OfferListingStatus;
    showWarningIcon?: boolean;
    offerView?: OfferView;
};

export const OfferStatusBadge = ({ status, showWarningIcon, offerView }: OfferStatusBadgeProps) => {
    const { t } = useTranslation('uiDomain');

    if (!status) {
        return null;
    }

    let icon;

    if (showWarningIcon) {
        icon = IconIdentifier.INFO;
    } else {
        icon =
            status === OfferListingStatus.AWAITING_SELLER || status === OfferListingStatus.AWAITING_BUYER
                ? IconIdentifier.TIMER
                : undefined;
    }

    let statusLabel = t(`listingTeaserOfferBadge.status.${status}`);

    const isAwaiting =
        (status === OfferListingStatus.AWAITING_BUYER && offerView === OfferView.BUYER) ||
        (status === OfferListingStatus.AWAITING_SELLER && offerView === OfferView.SELLER);

    if (isAwaiting) {
        statusLabel = t('listingTeaserOfferBadge.awaitingYourResponse');
    }

    const isOutbid = status === OfferListingStatus.CANCELLED && offerView === OfferView.BUYER;

    if (isOutbid) {
        statusLabel = t('listingTeaserOfferBadge.outbid');
    }
    const getStatusVariant = () => {
        if (isAwaiting) {
            return BadgeVariant.WARNING;
        }

        switch (status) {
            case OfferListingStatus.ACCEPTED:
                return BadgeVariant.POSITIVE;
            case OfferListingStatus.EXPIRED:
                return BadgeVariant.WARNING;
            case OfferListingStatus.AWAITING_BUYER:
            case OfferListingStatus.AWAITING_SELLER:
            case OfferListingStatus.WITHDRAWN:
                return BadgeVariant.NEUTRAL;
            case OfferListingStatus.REJECTED:
            case OfferListingStatus.CANCELLED:
                return BadgeVariant.NEGATIVE;
            default:
                return BadgeVariant.NEUTRAL;
        }
    };

    return <BadgeSecondary icon={icon} label={statusLabel} variant={getStatusVariant()} />;
};

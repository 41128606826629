import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { CurrencyCode, formatCurrency } from '@steelbuy/currency';
import { StartNegotiationResponse, useBuyerOfferMutation } from '@steelbuy/data-access';

import { PricingUnit } from '@steelbuy/domain-model';
import { MonetaryAmount } from '@steelbuy/domain-model-types';
import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import {
    ButtonPrimary,
    ButtonTertiary,
    LoadingStatus,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useBlurEffect } from '@steelbuy/util';
import { MonetaryAmountInput } from '../monetary-amount-input/MonetaryAmountInput';
import './MakeAnOfferModal.scss';

interface MakeAnOfferModalProps {
    modalAccessor: ModalAccessor;
    originalPrice: number;
    currencyCode: CurrencyCode;
    pricingUnit: PricingUnit;
    weightOrItems: number;
    creditLimit?: number;
    listingId: string;
    taxPercent: number;
    minimumPrice: number;
    onSuccess: ({ referenceId }: StartNegotiationResponse) => void;
}

export const MakeAnOfferModal = ({
    modalAccessor,
    currencyCode,
    creditLimit,
    originalPrice,
    pricingUnit,
    weightOrItems,
    listingId,
    onSuccess,
    taxPercent,
    minimumPrice,
}: MakeAnOfferModalProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const { isLoading, mutate, error } = useBuyerOfferMutation();

    const pricing = formatCurrency(navigator.language, originalPrice, currencyCode);
    const [priceValue, setPriceValue] = useState<MonetaryAmount | undefined>();
    const [isReview, setIsReview] = useState<boolean>(false);
    const pricingUnitTranslation = t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`);
    let weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_other`, {
        count: weightOrItems,
    });
    if (weightOrItems === 0) {
        weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_zero`);
    } else if (weightOrItems === 1) {
        weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_one`);
    }

    const originalPriceAsPounds = originalPrice && originalPrice / 100;
    const creditLimitAsPounds = creditLimit ? creditLimit / 100 : creditLimit;
    const maxOfferPerUnitForCredit =
        creditLimitAsPounds !== undefined
            ? ((creditLimitAsPounds / (100 + taxPercent)) * 100) / weightOrItems
            : Infinity;

    useBlurEffect([isReview]);

    const schema = z.object({
        offer: z
            .string({ required_error: t('uiDomain:validation.requiredField') })
            .nonempty(t('uiDomain:validation.requiredField'))
            .pipe(
                z.coerce
                    .number({
                        invalid_type_error: t('uiDomain:validation.numberValue'),
                    })
                    .min(minimumPrice, t('makeAnOfferModal.minimumPriceError'))
                    .max(originalPriceAsPounds - 0.01, t('makeAnOfferModal.priceExceed'))
                    .max(maxOfferPerUnitForCredit, t('makeAnOfferModal.creditLimitExceed'))
            ),
    });

    const {
        control,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        reset,
    } = useForm<z.infer<typeof schema>>({
        defaultValues: {
            offer: undefined,
        },
        resolver: zodResolver(schema),
        mode: 'all',
    });

    const onClose = () => {
        modalAccessor.hide();
        setIsReview(false);
        setPriceValue(undefined);
        reset();
        if (document.activeElement instanceof HTMLElement) {
            (window.document.activeElement as HTMLElement)?.blur?.();
        }
    };

    const getErrorMessage = () => {
        if (
            error instanceof AxiosError &&
            error?.response?.data?.message === 'Cannot start new negotiation while other is still active'
        ) {
            return t('makeAnOfferModal.existingOffer');
        }
        return t('makeAnOfferModal.failedToSubmitOffer');
    };

    return (
        <Modal modalAccessor={modalAccessor}>
            <form onSubmit={handleSubmit(async () => {})}>
                <ModalContent
                    onCloseModal={onClose}
                    headline={isReview ? t('makeAnOfferModal.reviewOffer') : t('makeAnOfferModal.makeAnOffer')}
                >
                    <ModalContentMain>
                        <div className="offer-modal">
                            {isReview ? (
                                <>
                                    {error && (
                                        <Notification
                                            level={NotificationLevel.ERROR}
                                            message={getErrorMessage()}
                                            stayOpen
                                        />
                                    )}
                                    <Notification
                                        level={NotificationLevel.INFO}
                                        message={t('makeAnOfferModal.infoMsg')}
                                        stayOpen
                                    />
                                    <div className="offer-modal__container">
                                        <div className="offer-modal__container__proposed-price">
                                            <div className="offer-modal__sub-container">
                                                <p className="offer-modal__title">
                                                    {t('makeAnOfferModal.proposedPrice', {
                                                        pricingUnit: pricingUnitTranslation,
                                                    })}
                                                </p>
                                            </div>
                                            <div className="offer-modal__container__proposed-price__column">
                                                <p className="offer-modal__sub-text">
                                                    {formatCurrency(
                                                        navigator.language,
                                                        priceValue?.value || 0,
                                                        currencyCode
                                                    )}
                                                </p>
                                            </div>
                                            <div className="offer-modal__sub-container">
                                                <p className="offer-modal__title">
                                                    {t(`makeAnOfferModal.reviewPricingUnit.${pricingUnit}.label`)}
                                                </p>
                                            </div>
                                            <div className="offer-modal__container__proposed-price__column">
                                                <p className="offer-modal__sub-text">{weightOrItemsText}</p>
                                            </div>
                                        </div>
                                        <div className="offer-modal__total">
                                            <p className="offer-modal__title">{t('makeAnOfferModal.total')}</p>
                                            <p className="offer-modal__price-total">
                                                {formatCurrency(
                                                    navigator.language,
                                                    weightOrItems * (priceValue?.value || 0),
                                                    currencyCode
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="offer-modal__title">
                                        {t('makeAnOfferModal.pricelabel', {
                                            pricingUnit: pricingUnitTranslation,
                                        })}
                                    </p>
                                    <div className="offer-modal__price">{pricing}</div>

                                    <Controller
                                        name="offer"
                                        control={control}
                                        render={({ field: { onChange, onBlur } }) => (
                                            <MonetaryAmountInput
                                                initialMonetaryAmount={priceValue}
                                                currencyCode={currencyCode}
                                                updateMonetaryAmount={setPriceValue}
                                                label={t('makeAnOfferModal.proposedPrice', {
                                                    pricingUnit: pricingUnitTranslation,
                                                })}
                                                name="offerPrice"
                                                showPricingUnit={false}
                                                error={errors.offer?.message}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                testId="offer-price"
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </ModalContentMain>
                    <div className="make-an-offer-action-bar">
                        <ModalContentActionBar>
                            {isReview ? (
                                <>
                                    <ButtonTertiary
                                        disabled={isLoading}
                                        label={t('uiDomain:common.edit')}
                                        onClick={() => setIsReview(false)}
                                    />
                                    <ButtonPrimary
                                        disabled={isLoading}
                                        label={t('makeAnOfferModal.submitOffer')}
                                        onClick={() => {
                                            if (priceValue?.value) {
                                                mutate(
                                                    { listingId, buyerPrice: priceValue?.value },
                                                    { onSuccess, onError: () => null }
                                                );
                                            }
                                        }}
                                        loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                    />
                                </>
                            ) : (
                                <>
                                    <ButtonTertiary label={t('uiDomain:common.cancel')} onClick={onClose} />
                                    <ButtonPrimary
                                        disabled={!isValid || !isDirty}
                                        label={t('makeAnOfferModal.review')}
                                        onClick={() => setIsReview(true)}
                                    />
                                </>
                            )}
                        </ModalContentActionBar>
                    </div>
                </ModalContent>
            </form>
        </Modal>
    );
};
